import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { classNames } from "../common";

interface PostFeedSkeletonProps {
	wrapperClasses?: string;
	noOfItems?: number;
	showTabs?: boolean;
}

const PostFeedSkeleton = (props: PostFeedSkeletonProps) => {
	const { wrapperClasses, noOfItems, showTabs = false } = props;
	return (
		<div
			className={classNames(
				"w-full z-0",
				wrapperClasses,
			)}
		>
			<SkeletonTheme
				baseColor="var(--background-skeleton)"
				highlightColor="var(--hightlight-skeleton)"
			>
				{showTabs
					? (
						<div className="w-full my-2 flex justify-around">
							<Skeleton containerClassName="w-1/6" height={20} />
							<Skeleton containerClassName="w-1/6" height={20} />
							<Skeleton containerClassName="w-1/6" height={20} />
						</div>
					)
					: null }
				{Array.from(Array(noOfItems).keys()).map((i) => (
					<div
						key={i}
						className="w-full flex flex-col items-center mt-5"
					>
						<div className="w-full flex flex-row items-start px-5">
							<Skeleton width={40} height={40} circle />
							<div className="w-full flex flex-col ml-5">
								<Skeleton containerClassName="w-1/3" height={20} />
								<Skeleton containerClassName="w-1/3" height={15} />
							</div>
						</div>
						<div className="w-full flex flex-row items-start pt-2 px-5">
							<Skeleton containerClassName="w-full" borderRadius={15} height={300} />
						</div>
						<div
							className="w-full mx-10 flex flex-row items-center justify-between py-2 px-5"
						>
							<div className="flex flex-row w-full">
								<Skeleton borderRadius={15} containerClassName="w-4/12 mr-2.5" height={28} />
								<Skeleton borderRadius={15} containerClassName="w-4/12 rounded-15" height={28} />
							</div>
							<div className="flex flex-row w-full justify-end">
								<Skeleton borderRadius={15} containerClassName="w-4/12 rounded-15 mr-2.5" height={28} />
								<Skeleton borderRadius={15} containerClassName="w-4/12 rounded-15" height={28} />
							</div>
						</div>
						{i < ((noOfItems ?? 0) - 1) ? (
							<div
								className="w-full flex flex-row items-start h-full bg-background-tertiary"
								style={{ height: 10 }}
							/>
						) : null}
					</div>
				))}
			</SkeletonTheme>
		</div>
	);
};

PostFeedSkeleton.defaultProps = {
	wrapperClasses: "",
	noOfItems: 5,
	showTabs: false,
};

export default PostFeedSkeleton;
