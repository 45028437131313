import * as React from "react";
import { SVGAttributes } from "react";

const SelectImage = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--gray-dark)" } = props;
	return (
		<svg
			width="24px"
			height="24px"
			viewBox="0 0 24 24"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<g id="Posting---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-icons" transform="translate(-459.000000, -40.000000)" stroke={stroke}>
					<g id="newpost_selectimage" transform="translate(459.000000, 40.000000)">
						<circle id="Oval" cx="14.5" cy="10.5" r="2.5" />
						<rect id="Rectangle" x="0.5" y="4.5" width="19" height="19" rx="2" />
						<polyline id="Path-4" points="0.493315315 19 6.85391444 11 11.3441624 17.1184198 19 17.1184198" />
						<path
							d="M4.5,4 L4.5,2.54720052 C4.5,1.44263102 5.3954305,0.547200521 6.5,0.547200521 L21.5,0.547200521 C22.6045695,0.547200521 23.5,1.44263102 23.5,2.54720052 L23.5,17.4684609 C23.5,18.5609936 22.6232726,19.4513594 21.53087,19.4682227 L19.4723307,19.5 L19.4723307,19.5"
							id="Path-5"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default SelectImage;
