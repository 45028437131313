/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/require-default-props */
import React, {
	useState, useEffect, useRef,
} from "react";
import { Combobox, Listbox, Transition } from "@headlessui/react";
import { SelectorIcon, XIcon } from "@heroicons/react/solid";
import classNames from "../utils/classNames";
import "./css/Filter.css";
import { strings } from "../i18n/config";

interface FilterProps {
	data: Array<any>;
	selectedData: Array<any>;
	applyFilter: any;
	filterName?: string;
	filterTitle?: string;
	showSelectedFilters?: boolean;
	showTitle?: boolean;
	multiSelect?: boolean;
	wrapperClassName?: string;
	id?: string;
	local_storage_key?: string;
	disableInput?: boolean;
}

const Filter = ({
	data,
	applyFilter,
	selectedData,
	filterName,
	filterTitle,
	showSelectedFilters,
	showTitle,
	multiSelect = true,
	wrapperClassName = "",
	id = "",
	local_storage_key = "",
	disableInput = false,
}: FilterProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const [search, setSearch] = useState("");
	const [selectedOptions, setSelectedOptions] = useState(selectedData);
	const [items, setItems] = useState(data);
	const ref = useRef<any>(null);
	const inputRef = useRef<any>(null);

	const isSelected = (element: any) => (!!selectedOptions?.find((el: any) => el.value === element.value));

	const handleDeselect = (item: any) => {
		const selectedOptionsUpdated = selectedOptions?.filter((el: any) => el.title !== item.title).sort();
		setSelectedOptions(selectedOptionsUpdated);
		applyFilter(selectedOptionsUpdated);
	};

	useEffect(() => {
		const updatedFilters = data.filter((e) => e.title.toLowerCase().trim().includes(search.toLowerCase().trim()));
		setItems(updatedFilters);
	}, [search]);

	useEffect(() => {
		setSelectedOptions(selectedData);
	}, [selectedData]);

	const handleSearch = (e: { target: { value: React.SetStateAction<string>; }; }) => {
		const text = (e.target.value as string).trim();

		setSearch(text);
		setIsOpen(true);
	};

	useEffect(() => {
		if (local_storage_key) {
			localStorage.setItem(local_storage_key, JSON.stringify(selectedOptions));
		}
	}, [selectedOptions]);

	const handleSelect = (item: any) => {
		if (!isSelected(item)) {
			const selectedOptionsUpdated = multiSelect
				? [...selectedOptions, item].sort((a, b) => data.indexOf(a) - data.indexOf(b))
				: [item];
			setSelectedOptions(selectedOptionsUpdated);
			applyFilter(selectedOptionsUpdated);
		} else {
			handleDeselect(item);
		}
		if (!multiSelect) {
			setSearch("");
		}
		setIsOpen(multiSelect);
	};

	const removeItem = (item: any) => {
		handleDeselect(item);
		setIsOpen(false);
	};

	const clearSearch = () => {
		setSearch("");
		setIsOpen(true);
	};

	useEffect(() => {
		const handleClickOutside = () => {
			setIsOpen(false);
		};
		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	}, []);

	const getFilterName = () => {
		let placeholderName = "";
		if (selectedOptions?.length === 0) {
			placeholderName = filterName || strings("Filter.select");
		} else if (multiSelect) {
			placeholderName = `${filterName} (${
				selectedOptions?.filter((e) => !e.hideColumn)?.length
			}/${items?.filter((e) => !e.hideColumn)?.length})`.replaceAll("  ", "");
		} else {
			placeholderName = selectedOptions[0]?.title;
		}
		return placeholderName;
	};

	return (
		<div
			className={classNames(
				"flex w-full flex-row",
				wrapperClassName,
			)}
			ref={ref}
			id={id}
			data-testid={id}
		>
			<div className="w-full">
				<Listbox
					as="div"
					className="space-y-1 max-w-xs"
					value={selectedOptions}
					onChange={(value: any) => !value.isFixedColumn && handleSelect(value)}
				>
					{() => (
						<>
							{showTitle
								? (
									<Listbox.Label className={classNames("block text-sm leading-5 font-medium text-gray-700")}>
										{filterTitle || strings("Filter.title")}
									</Listbox.Label>
								)
								: null}
							<div className="relative">
								<div
									className="inline-block w-full rounded-md shadow-sm bg-red-100"
									onClick={() => { if (disableInput) setIsOpen(!isOpen); }}
								>
									<Combobox
										value={search}
										onChange={() => { }}
										disabled={disableInput}
									>
										<div>
											<Combobox.Input
												data-testid={`${id}-input`}
												ref={inputRef}
												as="input"
												type="search"
												className={classNames("cursor-default relative w-full rounded-md border border-gray-300 ",
													"bg-white pl-3 py-2 text-left focus:outline-none h-10 filter-input",
													"blockform-control focus:shadow-outline-blue border-gray-300",
													"focus:border-primary transition ease-in-out duration-150 text-sm sm:leading-5",
													search?.trim().length > 0 ? "pr-12" : "pr-8")}
												value={search}
												displayValue={(item: string) => item}
												placeholder={getFilterName()}
												onChange={handleSearch}
											/>
											{search?.trim().length > 0
												? (
													<Combobox.Button className="absolute inset-y-0 right-5 flex items-center p-2 pl-5">
														<XIcon
															className="h-4 w-4 text-primary"
															aria-hidden="true"
															onClick={clearSearch}
														/>
													</Combobox.Button>
												) : null}
											<div
												onClick={() => {
													setIsOpen(!isOpen);
												}}
											>
												<Combobox.Button
													className="absolute inset-y-0 right-0 flex items-center pr-2"
												>
													<SelectorIcon
														className="h-5 w-5 text-gray-400"
														aria-hidden="true"
													/>
												</Combobox.Button>
											</div>
										</div>
									</Combobox>
								</div>
								<Transition
									unmount={false}
									show={isOpen}
									leave="transition ease-in duration-100"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
									className={classNames(
										"absolute max-w-full rounded-md bg-white shadow-lg z-10",
										inputRef.current ? "w-full" : "",
									)}
								>
									<Listbox.Options
										static
										className={classNames("max-h-60 rounded-md py-0 text-base leading-6 z-10",
											"shadow-md overflow-auto focus:outline-none sm:text-sm sm:leading-5")}
										data-testid={`${id}-options`}
									>
										{items.map((item: any) => {
											if (item.hideColumn) {
												return null;
											}
											const selected = isSelected(item);
											return (
												<Listbox.Option key={item.title} value={item}>
													{({ active }) => (
														<div
															className={classNames(active ? "text-white bg-blue-600" : "text-gray-900",
																"cursor-default select-none text-gray-900",
																"border-gray-200 border-t py-3 pl-10 pr-4 hover:bg-gray-100 relative",
																selected ? "selected bg-gray-300" : "")}
														>
															<span
																className={classNames(selected ? "font-semibold" : "font-normal",
																	"block truncate")}
															>
																{item.title}
															</span>
															{selected && (
																<span
																	className={classNames(active ? "text-white" : "text-blue-600",
																		"absolute inset-y-0 left-0 flex items-center pl-3")}
																>
																	<svg
																		className="h-5 w-5 tick-style"
																		xmlns="http://www.w3.org/2000/svg"
																		viewBox="0 0 20 20"
																		fill="currentColor"
																	>
																		<path
																			fillRule="evenodd"
																			d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0	01-1.414 0l-4-4a1
																			1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
																			clipRule="evenodd"
																		/>
																	</svg>
																</span>
															)}
														</div>
													)}
												</Listbox.Option>
											);
										})}
									</Listbox.Options>
								</Transition>

							</div>
						</>
					)}
				</Listbox>

				{showSelectedFilters
					? (
						<div className="w-full flex flex-auto flex-wrap mt-5">
							{selectedOptions?.map(((item: any) => (
								<div className={classNames("flex w-fit justify-center items-center m-1 font-medium p-1",
									"px-2 bg-white rounded-full border bg-slate-300")}
								>
									<div className="text-xs font-normal leading-none max-w-full flex-initial text-white">{item}</div>
									<div className="flex flex-auto flex-row-reverse">
										<button type="button" onClick={() => removeItem(item)}>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="100%"
												height="100%"
												fill="none"
												viewBox="0 0 24 24"
												stroke="white"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
												className="feather feather-x cursor-pointer rounded-full w-4 h-4 ml-2"
											>
												<line x1="18" y1="6" x2="6" y2="18" />
												<line x1="6" y1="6" x2="18" y2="18" />
											</svg>
										</button>
									</div>
								</div>
							)))}
						</div>
					)
					: null}
			</div>
		</div>
	);
};

Filter.defaultProps = {
	showTitle: true,
	multiSelect: true,
	showSelectedFilters: false,
};

export default Filter;
