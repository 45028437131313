import React, {
	ForwardedRef,
	useEffect, useImperativeHandle, useRef, useState,
} from "react";
import { dbg, LogMgr } from "@credo/utilities";
import styles from "./widget-carousel.module.css";
import { classNames } from "../common";
import { SwitchIcon } from "../assets/icons";

export type CarouselWidget = {
	// unique should be unique which will help to jump directly to that widget if needed
	id: number | string,
	onEnter: () => void | undefined;
	onLeave: () => void | undefined;
	render: () => React.ReactNode;
	onClick?: (event?: any) => void;
}

export type WidgetCarouselProps = {
	widgets: CarouselWidget[];
	autoAdvance: boolean;
	canAdvance: () => boolean | undefined;
	advanceInterval: number;
}

export type WidgetCarouselRef = {
	// Advance to the next widget in the carousel array
	doAdvance: () => void,
	/**
	 * get current widget index in the carousel
	 *
	 * @returns {Object} an object consisting of keys: index, nextIndex, previousIndex and dirDelta.
	 * */
	getCurrentWidgetIndex: () => {
		index: number,
		nextIndex: number,
		previousIndex: number,
		dirDelta: number,
	},
	/**
	 * Jump to the widget by id
	 *
	 * @return void
	 * */
	advanceTo: (id: number) => void;
}

export const WidgetCarousel = React.forwardRef((
	{
		widgets,
		autoAdvance = true,
		canAdvance = () => true,
		advanceInterval = 3000,
	}: WidgetCarouselProps,
	ref: ForwardedRef<WidgetCarouselRef>,
) => {
	const [widgetIndex, setWidgetIndex] = useState({
		index: 0,
		nextIndex: 1,
		previousIndex: -1,
		dirDelta: 1,
	});
	const [isTransitioning, setIsTransitioning] = useState(false);
	const [resetInterval, setResetInterval] = useState<boolean>(false);

	const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

	const [switchButtonState, setSwitchButtonState] = useState({
		down: false,
		timer: 0,
	});

	const clearCarousalInterval = () => {
		if (intervalRef.current) {
			clearInterval(intervalRef.current);
		}
	};

	const switchMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		setSwitchButtonState({
			down: true,
			timer: window.setTimeout(toggleAutoAdvance, 1000),
		});
		clearCarousalInterval();
		setResetInterval(true);
	};
	const switchMouseUp = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		if (switchButtonState.down) {
			doAdvance();
		}
		if (switchButtonState.timer !== 0) {
			window.clearTimeout(switchButtonState.timer);
		}

		setSwitchButtonState({
			down: false,
			timer: 0,
		});
	};

	const [s_autoAdvance, setAutoAdvance] = useState(autoAdvance);
	const toggleAutoAdvance = () => {
		// d console.log(`Toggling from:${s_autoAdvance}`);
		setAutoAdvance((cur) => !cur);
		setSwitchButtonState({
			down: false,
			timer: 0,
		});
	};

	const doAdvance = () => {
		// d console.log("Advancing");
		setIsTransitioning(true);
		let ndd = 0;
		// eslint-disable-next-line no-return-assign
		setWidgetIndex((curIndex) => ({
			index: curIndex.nextIndex,
			// eslint-disable-next-line no-nested-ternary
			dirDelta: (ndd = (curIndex.nextIndex === widgets?.length - 1)
				? -1
				: (curIndex.nextIndex === 0 ? 1 : curIndex.dirDelta)),
			nextIndex: (curIndex.nextIndex + ndd) % widgets?.length,
			previousIndex: curIndex.index,
		}));
	};

	const getCurrentWidgetIndex = () => widgetIndex;

	const advanceTo = (id: number) => {
		if (dbg) {
			LogMgr.mydbg("advance to widget with id: ", id);
		}

		const indexOfWidget = widgets.findIndex((element: CarouselWidget) => element.id === id);

		if (indexOfWidget === -1) {
			if (dbg) {
				LogMgr.mydbg(`WidgetCarousel.advanceTo widget with id: ${id} not found, discarding transition`);
			}
			return;
		} else if (dbg) {
			LogMgr.mydbg(`WidgetCarousel.advanceTo widget with id: ${id} found at ${indexOfWidget}`);
		}

		if (dbg) {
			LogMgr.mydbg("WidgetCarousel.advanceTo currentWidgetIndex information:", getCurrentWidgetIndex());
		}

		if (indexOfWidget !== getCurrentWidgetIndex().index) {
			let ndd = 0;
			// eslint-disable-next-line no-return-assign
			setWidgetIndex((curIndex) => ({
				index: indexOfWidget,
				// eslint-disable-next-line no-nested-ternary
				dirDelta: (ndd = (indexOfWidget === widgets?.length - 1)
					? -1
					: (indexOfWidget === 0 ? 1 : curIndex.dirDelta)),
				nextIndex: (indexOfWidget + ndd) % widgets?.length,
				previousIndex: (indexOfWidget === widgets.length - 1 || indexOfWidget === 0) ? indexOfWidget + ndd : indexOfWidget - ndd,
			}));
		}
	};

	useImperativeHandle(ref, () => ({
		doAdvance,
		getCurrentWidgetIndex,
		advanceTo,
	}));

	/** auto advance interval setup */
	useEffect(() => {
		// d console.log(`Setting up auto advance interval ${advanceInterval}`);
		clearCarousalInterval();
		intervalRef.current = setInterval(() => {
			// d console.log(`Advance interval check: ${s_autoAdvance}`);
			if (s_autoAdvance && canAdvance()) {
				doAdvance();
				setResetInterval(false);
			}
		}, advanceInterval);
		return clearCarousalInterval;
	}, [s_autoAdvance, canAdvance, advanceInterval, resetInterval]);

	const onEndTransition = () => {
		setIsTransitioning(false);
		if (widgetIndex.previousIndex !== -1) widgets[widgetIndex.previousIndex].onLeave();
		widgets[widgetIndex.index].onEnter();
	};

	return (
		<div style={{
			position: "relative",
			width: "100%",
			height: "31px",
			overflow: "hidden",
			scrollBehavior: "smooth",
		}}
		>
			{widgets.map((widget, index) => (
				<button
					type="button"
					key={index}
					className={classNames(styles.widget,
						// eslint-disable-next-line no-nested-ternary
						widgetIndex.index === index
							? styles.current
							: styles.previous)}
					style={{
						top: `${(widgetIndex.index - index) * 31}px`,
					}}
					onTransitionEnd={widgetIndex.index === index ? onEndTransition : undefined}
					onClick={(event) => {
						if (widget.onClick && !isTransitioning) {
							widget.onClick(event);
						}
					}}
				>
					{widget.render()}
				</button>
			))}
			{widgets.length > 1 && (
				<button
					type="button"
					className={classNames(styles.switcherButton, isTransitioning ? styles.inTransition : null)}
					onMouseDown={switchMouseDown}
					onMouseUp={switchMouseUp}
				>
					<SwitchIcon
						containerStyle={{
							pointerEvents: "none",
							fill: s_autoAdvance ? "limegreen" : "black",
						}}
						pathStyle={{
							pointerEvents: "none",
							fill: s_autoAdvance ? "black" : "limegreen",
						}}
					/>
				</button>
			)}
		</div>
	);
});
