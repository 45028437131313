import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import {
	createSearchParams, useLocation, useNavigate, useSearchParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import Hero from "../../gen-comp/Hero";
import { strings } from "../../i18n/config";
import Filter from "../../gen-comp/Filter";
import Table from "../../gen-comp/table";
import { SnackBarTypeOptions, TableHeader } from "../../utils/types";
import { SuspendedUserTableHeaders } from "./constants";
import { secondsToDhms } from "../../utils/utils";
import Accordion from "../../gen-comp/Accordion";
import { AUTH_URL, USER_DETAILS_PAGE } from "../../routes/constants";
import { getSuspendedUsersRequest } from "./UserRequests";
import { successRETCD } from "../../api";
import ToastMsg from "../../gen-comp/ToastMsg";

export default function SuspendedUsersScreen() {
	const navigate = useNavigate();
	const location = useLocation();

	const [searchParams] = useSearchParams();
	const headerParams = searchParams.get("headers");

	// eslint-disable-next-line no-nested-ternary
	const [headers, setHeaders] = useState<TableHeader[]>(headerParams ? JSON.parse(headerParams)
		: localStorage.getItem("suspended-user-headers")
			? JSON.parse(localStorage.getItem("suspended-user-headers") || "")
			: SuspendedUserTableHeaders);
	const [userData, setUserData] = useState<any>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const wrapperRef = useRef(null);

	useEffect(() => {
		const paramString = `headers=${JSON.stringify(headers)}`;
		if (window) {
			window.history.replaceState(null, "", `${location.pathname}?${paramString}`);
		}
	}, [headers]);

	useEffect(() => {
		if (headerParams) {
			setHeaders(JSON.parse(headerParams));
		}
	}, []);

	const applyFilter = (items: TableHeader[]) => {
		setHeaders(items);
	};

	const navigateToUserDetails = (item: any) => {
		navigate({
			pathname: AUTH_URL + USER_DETAILS_PAGE,
			search: `?${createSearchParams({ userId: item?.user_id })}`,
		});
	};

	const getSuspendedUsers = async () => {
		const res = await getSuspendedUsersRequest();
		if (res && res.code === 200) {
			const data = res?.response;
			if (data && data.retcd === successRETCD) {
				const items: any[] = [];
				data?.items?.forEach((element: any) => {
					items.push({
						...element,
						time_left: parseInt(element.time_left, 10) === 0 || element.time_left === ""
							? strings("SuspendUserModal.forever") : secondsToDhms(element.time_left),
						suspended_date: element.suspended_date
							? moment(new Date(element.suspended_date)).format("DD MMM YYYY") : strings("UserSearchPage.not_available"),
					});
				});
				setUserData(items);
			} else {
				toast(
					<ToastMsg
						message={strings("SuspendedUserSection.error_message")}
						showButton
						type={SnackBarTypeOptions.ERROR}
					/>,
				);
			}
		} else {
			toast(
				<ToastMsg
					message={strings("SuspendedUserSection.error_message")}
					showButton
					type={SnackBarTypeOptions.ERROR}
				/>,
			);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		getSuspendedUsers();
	}, []);

	return (
		<div ref={wrapperRef} className="min-h-screen">
			<Hero title={strings("SuspendedUsersScreen.hero_title")} pageHasTable />
			<div data-testid="search-card" className="bg-white p-5 rounded-lg -mt-24 mx-5 flex flex-col mb-5">
				<Accordion
					header={(
						<h5 className="mb-1">
							{strings("SuspendedUsersScreen.filter")}
						</h5>
					)}
					content={(
						<div className="grid grid-cols-1 xs:grid-cols-2-max-20 sm:grid-cols-3-max-20 gap-2 lg:gap-4">
							<Filter
								id="column-filters"
								data={SuspendedUserTableHeaders}
								filterName={strings("Dashboard.table_title")}
								applyFilter={applyFilter}
								selectedData={headers}
								showTitle={false}
								local_storage_key="suspended-user-headers"
							/>
						</div>
					)}
					defaultOpen
				/>
			</div>
			<div className="bg-white p-5 rounded-lg my-5 mx-5">
				<div data-testid="suspended-data-table" className="my-0">
					<Table
						header={headers}
						data={userData}
						onItemClick={navigateToUserDetails}
						showHeader
						isLoading={isLoading}
					/>
				</div>
			</div>
		</div>
	);
}
