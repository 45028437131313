import React, { SVGAttributes } from "react";

// eslint-disable-next-line import/prefer-default-export
const RequestDecline = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--gray-shadow)" } = props;
	return (
		<svg
			width="28px"
			height="28px"
			viewBox="0 0 28 28"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<g id="Web-Version" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Web-Version---Settings-Ego" transform="translate(-1096.000000, -233.000000)" stroke={stroke}>
					<g id="Group" transform="translate(830.000000, 227.000000)">
						<g id="request_decline" transform="translate(267.000000, 7.000000)">
							<circle id="Oval" cx="13" cy="13" r="13" />
							<g id="Group-8" transform="translate(7.222222, 7.222222)" strokeLinecap="round">
								<line x1="0" y1="0" x2="11.5555556" y2="11.5555556" id="Line-5" />
								<line
									x1="0"
									y1="0"
									x2="11.5555556"
									y2="11.5555556"
									id="Line-5"
									transform="translate(5.777778, 5.777778) scale(-1, 1) translate(-5.777778, -5.777778) "
								/>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default RequestDecline;
