import getQueryFields from "../../utils/getQueryFields";
import { DropdownItem, SearchBarElementInterface, TableHeader } from "../../utils/types";
import { PAGINATION_LIMIT } from "../../utils/constants";
import getSearchQuery from "../../utils/getSearchQuery";
import { StreamTableHeaders, Timeframes } from "./constants";
import { getDateTimestampOfPreviousMonths } from "../../utils/utils";
import { REPORTED_STREAM_ID, REPORTED_STREAM_TYPE } from "../../routes/constants";
import { PostTableHeaders } from "../posts/constants";
import { UserTableHeaders } from "../users/constants";

export interface GetCommunityVariables {
	headers: TableHeader[];
	offset: number;
	searchData: SearchBarElementInterface[];
	otherFilterQuery: string;
	timeframeFilter?: DropdownItem[];
}

const getReportedTimeframeFilter = (timeframeFilter: DropdownItem[]) => {
	let query: any = [];
	if (timeframeFilter.length === Timeframes.length) {
		return query;
	}

	if (timeframeFilter.length < Timeframes.length && timeframeFilter.length > 0) {
		const maxVal = Math.max(...timeframeFilter.map((item) => parseInt(item.value, 10)));
		query = [
			...query,
			{
				reported_ts_gte: getDateTimestampOfPreviousMonths(maxVal),

			},
		];
		return query;
	}
	return query;
};

export const getCommunitiesQueries = (variables: GetCommunityVariables) => {
	const {
		headers, offset, searchData, otherFilterQuery, timeframeFilter,
	} = variables;

	const {
		query: filterQuery,
	} = getSearchQuery(searchData);

	const timeframeFilterQuery = timeframeFilter ? getReportedTimeframeFilter(timeframeFilter) : "";

	const filters = filterQuery !== "[]" ? [
		filterQuery.replace(/"([^(")"]+)":/g, "$1:"),
	] : [];

	if (otherFilterQuery) {
		filters.push(otherFilterQuery);
	}

	if (timeframeFilterQuery && timeframeFilterQuery?.length > 0) {
		const timeframeQuery = JSON.stringify(timeframeFilterQuery).replace(/"([^(")"]+)":/g, "$1:");
		filters.push(`{ OR: ${timeframeQuery} }`);
	}

	return `query {
			stream(
				first: ${PAGINATION_LIMIT},
 				type: "dg",
				offset: ${offset}
				${filters.length > 0 ? `, filter: { AND: [ ${filters} ]} ` : ", "}
				orderBy:[creat_ts_desc]
			) {
				${getQueryFields(headers)},
			}
		}`
		.replaceAll("\t", "")
		.replaceAll("\n", "");
};

const getTimestamp = (timestamp: any) => {
	if (timestamp && timestamp.length > 0) {
		const lastTs = parseInt(timestamp[0].value, 10);
		const ts = (Math.round(getDateTimestampOfPreviousMonths(0) / 1000) - (lastTs * 3600)) * 1000;
		return ts;
	}
	return null;
};

export const getNewCommunitiesQueries = (variables: any) => {
	const {
		headers, offset, timestamp,
	} = variables;

	const filters = [];

	const ts = getTimestamp(timestamp);
	if (ts) {
		filters.push(`{ OR:[{ creat_ts_gte: ${ts} }] }`); // Only fetching newly created stream
	}

	return `query {
			stream(
				first: ${PAGINATION_LIMIT},
 				type: "dg",
				offset: ${offset}
				${filters.length > 0 ? `, filter: { AND: [ ${filters} ]} ` : ", "}
				orderBy:[creat_ts_desc]
			) {
				${getQueryFields(headers)},
			}
		}`
		.replaceAll("\t", "")
		.replaceAll("\n", "");
};

export interface DeleteCommunityVariables {
	streamId: string;
	fields: TableHeader[];
}

export const deleteStreamQuery = (variables: DeleteCommunityVariables) => {
	const {
		fields, streamId,
	} = variables;

	return `mutation { deleteStream( streamId: "${streamId}" ){ ${getQueryFields(fields)} }\n}`
		.replaceAll("\t", "")
		.replaceAll("\n", "");
};
export interface GetReportedStreamVariables {
	community_id: string;
}

export const getReportedCommunityData = (variables: GetReportedStreamVariables) => {
	const {
		community_id,
	} = variables;

	return `query {
			stream(
				first: 1,
				streamId: "${REPORTED_STREAM_ID}",
			) {
				${getQueryFields(StreamTableHeaders)},
				posts(
					postType: "${REPORTED_STREAM_TYPE}",
					src_streamId: "${community_id}"
				)	{
					${getQueryFields(PostTableHeaders)},
					src_prof_id,
					src_streamId,
					reportReason,
					reported_user_id,
					reportMessage,
				}
			}
		}`
		.replaceAll("\t", "")
		.replaceAll("\n", "");
};

export interface GetCommunityDetailsVariable {
	communityId: string;
}

export const getCommunityDetails = (variables: GetCommunityDetailsVariable) => {
	const {
		communityId,
	} = variables;

	return `query { 
		stream(
			first: 1, 
			streamId: "${communityId}"
		)
		{ 
			${getQueryFields(StreamTableHeaders)}, 
				suAdminMode,
				followers {
					${getQueryFields(UserTableHeaders)}
				},
				joinedUsers {
					${getQueryFields(UserTableHeaders)}
				},
				adminUsers {
					${getQueryFields(UserTableHeaders)}
				},
				suAdmin {
					${getQueryFields(UserTableHeaders)}
				},
			}
	\n}`
		.replaceAll("\t", "")
		.replaceAll("\n", "");
};
