/* eslint-disable no-nested-ternary */
import React from "react";
import { AppMode, cu } from "@credo/utilities";
import { ProfilePicture } from "../profile/profile-picture";
import { classNames, ItemType } from "../common";

interface SharedActivitiesProps {
	postData: any;
	handleClickStream: (
		/**
		 * Stream's username
		 * */
		struname: string,
		/**
		 * Stream's id
		 * */
		streamId: string,
		/**
		 * Stream's complete name for meta
		 * */
		targetText: string,
	) => void;
	handleClickUser: (
		id: string,
		username: string,
		mode: string,
		egousername: string,
		targetText: string
	) => void;
	isLast: any;
}

export const SharedActivities = (props: SharedActivitiesProps) => {
	const {
		postData,
		handleClickStream,
		handleClickUser,
		isLast,
	} = props;

	const {
		src, textPrimary, dest, createTs, image, itemMode,
	} = postData;

	const redirectToUserProfile = () => {
		if (handleClickUser) {
			handleClickUser(postData.src.id, postData.src.username, postData.itemMode, postData.src.username, postData.src.title);
		}
	};

	const goToTargetPage = () => {
		if (postData.dest.entityType === ItemType.STREAM) {
			handleClickStream(postData.dest.username, postData.dest.id, postData.dest.title);
		} else {
			handleClickUser(postData.dest.id, postData.dest.username, postData.dest.mode, postData.dest.username, postData.dest.title);
		}
	};

	return (
		<div
			key={postData.id}
			className={classNames(
				"w-full flex justify-start items-center",
				itemMode === AppMode.CREDO ? "item-bg-credo" : "item-bg-ego",
			)}
		>
			<div className="h-full w-2 justify-center items-center flex pl-2.5">
				<div
					className={classNames(
						"flex relative",
						postData.read ? "h-0 w-0" : "h-2 w-2",
					)}
				>
					<span className="relative inline-flex rounded-full h-2 w-2 bg-accent" />
				</div>
			</div>
			<button
				type="button"
				className={classNames(
					"flex justify-center items-center outline-none",
					"p-3 pl-1",
					"h-16 w-16",
				)}
				title={image.title}
				onClick={redirectToUserProfile}
			>
				<ProfilePicture
					profilePicUrl={cu.buildSourceUrlImage(image.imageSrcUrl) || ""}
				/>
			</button>
			<div
				className={classNames(
					"flex flex-col items-start justify-center flex-1",
					itemMode === AppMode.CREDO ? "border-bottom-credo" : "border-bottom-ego",
					isLast ? "border-none" : "",
					"py-3 pr-4",
				)}
			>
				<p className="leading-4">
					{src?.title && (
						<button
							type="button"
							onClick={redirectToUserProfile}
							className="font-medium text-primary text-sm cursor-pointer outline-none text-start break-words"
						>
							{src?.title}
							&nbsp;
						</button>
					)}
					<span
						className={classNames(
							"font-thin text-sm leading-4",
							itemMode === AppMode.CREDO ? "item-text-credo" : "item-text-ego",
						)}
					>
						{textPrimary?.trim()}
					</span>
					&nbsp;
					{dest?.title && (
						<button
							type="button"
							onClick={goToTargetPage}
							className="font-medium text-primary text-sm cursor-pointer outline-none text-start break-words"
						>
							{dest?.title}
						</button>
					)}
				</p>
				<span className="text-xs text-gray-dark">
					{cu.calculatePostTime(createTs)}
				</span>
			</div>
		</div>
	);
};

export default SharedActivities;
