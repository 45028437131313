/* eslint-disable react/no-array-index-key */
import React, {
	SVGAttributes, useEffect, useMemo, useState,
} from "react";
import { cu } from "@credo/utilities";
import { classNames, SvgIcon } from "../common";
import { TabInterface } from "./CredoTab";
import { InfoCircleIcon } from "../assets/icons";
import Accordion from "../accordion";

export enum TabViewMode {
	// Display just tabs not the content accordion will not work
	TAB = "tab",
	// Display tabs and content both accordion will not work
	TAB_WITH_PANEL = "tab_panel",
	// Display accordion and tab both (tab will alsa work and accordion too)
	ACCORDION = "accordion",
}

// eslint-disable-next-line no-undef
interface TabPanelProps extends SVGAttributes<HTMLOrSVGElement> {
	id?: string;
	/**
	 * tab data
	 * */
	tab_categories: TabInterface;
	headerClassName?: string;
	labelClassName?: string;
	// TODO: Add these features when needed
	// /**
	//  * Custom CSS class for tab
	//  * @default ""
	//  * */
	// tabStyle?: string;
	// /**
	//  * Custom CSS class for tab container
	//  * @default ""
	//  * */
	// tabContainerStyle?: string;
	// /**
	//  * Custom CSS class for tab header
	//  * @default {}
	//  * */
	// tabHeaderStyle?: {};
	// /**
	//  * Tab container wrapper css classes
	//  * */
	// tabContainerWrapperStyle?: string;
	// /**
	//  * If tab contains any badge component we can pass that
	//  * component directly using a key value pair. Where the
	//  * key will be the same index as the categories.
	//  * */
	// badges?: {
	// 	[key: string]: any;
	// }
	/**
	 * Capture the index on which the tab is switched
	 * */
	onChangeTab?: (index: number) => void;
	/**
	 * Default active tab which will be set as active on
	 * mount
	 * */
	defaultActiveTab?: number;
	/**
	 * Control to render tab, tab with content or tabs
	 * with accordion.
	 *
	 * @see TabViewMode
	 * */
	tabViewMode: TabViewMode,
	/**
	 * Renders the active tab border above the label of
	 * tab
	 * @default false
	 * */
	activeAboveLabel?: boolean;
	/**
	 * Border displayed under an active tab label
	 * */
	activeBorderClassname?: string;
}

// eslint-disable-next-line import/prefer-default-export
export function TabPanel(props: TabPanelProps) {
	const {
		tab_categories,
		defaultActiveTab,
		onChangeTab,
		tabViewMode,
		activeAboveLabel,
		headerClassName,
		labelClassName,
		activeBorderClassname,
		id,
	} = props;

	const [activeTab, setActiveTab] = useState<number>(defaultActiveTab || 0);
	/**
	 * To render the tabs only when they are active at least once, we are maintaining
	 * an array for active tabs.
	 * */
	const [mountedContent, setMountedContent] = useState<number[]>([defaultActiveTab || 0]);

	const tabs = useMemo(() => Object.keys(tab_categories), [tab_categories]);
	const content = useMemo(() => Object.values(tab_categories), [tab_categories]);

	const isAccordion = useMemo(() => tabViewMode === TabViewMode.ACCORDION, []);
	const isTab = useMemo(() => tabViewMode === TabViewMode.TAB, []);
	const isTabWithContent = useMemo(() => tabViewMode === TabViewMode.TAB_WITH_PANEL, []);

	useEffect(() => {
		if (cu.isSet(defaultActiveTab) && defaultActiveTab !== activeTab) {
			// @ts-ignore we are already checking if prop isSet, was giving lint error can be undefined
			setActiveTab(defaultActiveTab);
			// @ts-ignore we are already checking if prop isSet, was giving lint error can be undefined
			setMountedContent([defaultActiveTab]);
		}
	}, [defaultActiveTab]);

	if (!tab_categories) {
		return null;
	}

	const handleChangeTab = (index: number) => {
		setActiveTab(index);

		if (!mountedContent.includes(index)) {
			setMountedContent((prevState) => [
				...prevState,
				index,
			]);
		}

		if (onChangeTab) {
			onChangeTab(index);
		}
	};

	const renderHeader = () => (
		<div
			className={classNames(
				"flex justify-start items-center",
				headerClassName,
			)}
		>
			{tabs.map((tabLabel, index) => (
				<div className="mr-3">
					{activeAboveLabel && (
						<div
							className={classNames(
								activeTab === index ? `bg-turquoise-400 ${activeBorderClassname}` : "bg-transparent",
								"h-0.5",
							)}
						/>
					)}
					<button
						type="button"
						onClick={(event) => {
							event.stopPropagation();
							handleChangeTab(index);
						}}
						className={classNames(
							activeTab === index ? "text-turquoise-400 !font-normal" : "text-white",
							"outline-none",
							labelClassName,
						)}
					>
						{tabLabel}
					</button>
					{!activeAboveLabel && (
						<div
							className={classNames(
								activeTab === index ? `bg-turquoise-400 ${activeBorderClassname}` : "bg-transparent",
								"h-0.5",
							)}
						/>
					)}
				</div>
			))}
			{isAccordion && (
				<SvgIcon
					icon={InfoCircleIcon}
					color="var(--primary)"
					className={classNames(
						activeAboveLabel ? "mt-0.5" : "mb-0.5",
					)}
					height={16}
					width={16}
				/>
			)}
		</div>
	);

	const renderContent = useMemo(() => {
		if (isTab) return null;
		return content.map((cont, idx) => {
			if (!mountedContent.includes(idx)) return null;
			return (
				<div
					className={classNames(
						/**
						 * While refreshing on the tab other than the first tab
						 * sets the content as display none even if the tab is active
						 * */
						mountedContent.includes(idx) && activeTab === idx ? "!block" : "!hidden",
						activeTab !== idx ? "invisible" : "",
					)}
				>
					{cont}
				</div>
			);
		});
	}, [mountedContent, activeTab, content, isTab]);

	return (
		<Accordion
			header={(
				<button
					type="button"
					className={classNames(
						"flex justify-start items-center",
						"w-full",
						(isAccordion || isTab) ? "p-2" : "",
					)}
				>
					{renderHeader()}
				</button>
			)}
			content={renderContent}
			defaultOpen={isTabWithContent}
			disabled={isTabWithContent}
			id={id}
		/>
	);
}

TabPanel.defaultProps = {
	// tabStyle: "",
	// tabContainerStyle: "",
	// tabHeaderStyle: {},
	// tabContainerWrapperStyle: "",
	// badges: {},
	onChangeTab: () => {},
	defaultActiveTab: 0,
	activeAboveLabel: false,
};
