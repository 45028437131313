/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGAttributes } from "react";

const Delete = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--gray-dark)",
	} = props;
	return (
		<svg
			width="20px"
			height="19px"
			viewBox="0 0 20 19"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<title>delete_icon</title>
			<g id="Home-/-Feed---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Home---Export" transform="translate(-81.000000, -73.000000)" stroke={stroke}>
					<g id="delete_icon" transform="translate(81.000000, 74.000000)">
						<line x1="10" y1="6" x2="10" y2="15" id="Line" strokeLinecap="round" />
						<line x1="19.5" y1="3.5" x2="0.5" y2="3.5" id="Line-Copy-3" strokeLinecap="round" />
						<line x1="6" y1="6" x2="7" y2="15" id="Line-Copy" strokeLinecap="round" />
						<line x1="13" y1="6" x2="14" y2="15" id="Line-Copy-2" strokeLinecap="round" transform="translate(13.500000, 10.500000) scale(-1, 1) translate(-13.500000, -10.500000) " />
						<path d="M17.4032713,3.5 L14.9877128,17.0875167 L5.50456843,17.5 L2.59672866,3.5 L17.4032713,3.5 Z" id="Rectangle" />
						<path d="M7,3 L7,1 C7,0.44771525 7.44771525,1.01453063e-16 8,0 L12,0 C12.5522847,-1.01453063e-16 13,0.44771525 13,1 L13,3 L13,3" id="Path-2" />
					</g>
				</g>
			</g>
		</svg>
	);
};

export default Delete;
