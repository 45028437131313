import * as React from "react"
import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => {
	const {
		stroke = "var(--primary)",
	} = props;

	return (
		<svg
			width={12}
			height={12}
			viewBox="0 0 12 12"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g id="Home-/-Feed---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Home---Export" transform="translate(-147.000000, -487.000000)">
					<g id="i_icon" transform="translate(148.000000, 488.000000)">
						<path d="M0.454545455,5 C0.454545455,2.49 2.49,0.454545455 5,0.454545455 C7.51,0.454545455 9.54545455,2.49 9.54545455,5 C9.54545455,7.51 7.51,9.54545455 5,9.54545455 C2.49,9.54545455 0.454545455,7.51 0.454545455,5 Z" id="Stroke-1" stroke={stroke}/>
						<path d="M4.25,3 C4.25,2.586 4.586,2.25 5,2.25 C5.414,2.25 5.75,2.586 5.75,3 C5.75,3.414 5.414,3.75 5,3.75 C4.586,3.75 4.25,3.414 4.25,3 Z" id="Stroke-3" fill={stroke} />
						<line x1="5" y1="7.86945455" x2="5" y2="4.34672727" id="Stroke-5" stroke={stroke} />
					</g>
				</g>
			</g>
		</svg>
	)
}

export default SvgComponent
