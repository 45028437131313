import React, { SVGAttributes } from "react";

const Info = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--primary)" } = props;
	return (
		<svg
			width="25px"
			height="19px"
			viewBox="0 0 25 19"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<title>info_icon</title>
			<g id="Profile---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Profile---Credo-Name---5-Copy" transform="translate(-334.000000, -111.000000)" stroke={stroke}>
					<g id="info_icon" transform="translate(334.000000, 111.000000)">
						<path
							d="M15.6831,9.4255 L15.6831,13.4255 C15.6831,13.9775 15.2351,14.4255 14.6831,14.4255 L14.6831,14.4255 C14.1311,14.4255 13.6831,13.9775 13.6831,13.4255 L13.6831,9.4255 C13.6831,8.8735 14.1311,8.4255 14.6831,8.4255 L14.6831,8.4255 C15.2351,8.4255 15.6831,8.8735 15.6831,9.4255 Z"
							id="Stroke-1"
						/>
						<path
							d="M15.9331,5.5144 C15.9331,4.8244 15.3731,4.2644 14.6831,4.2644 C13.9931,4.2644 13.4331,4.8244 13.4331,5.5144 C13.4331,6.2044 13.9931,6.7644 14.6831,6.7644 C15.3731,6.7644 15.9331,6.2044 15.9331,5.5144 Z"
							id="Stroke-3"
						/>
						<path
							d="M5.711,14.1513 L5.711,15.3493 C5.711,17.0893 7.314,18.5003 9.291,18.5003 L20.075,18.5003 C22.052,18.5003 23.655,17.0893 23.655,15.3493 L23.655,9.7263 L23.655,3.6503 C23.655,1.9103 22.052,0.5003 20.075,0.5003 L9.291,0.5003 C7.314,0.5003 5.711,1.9103 5.711,3.6503 L5.711,8.1793 L0.942,11.1673 L5.711,14.1513 Z"
							id="Stroke-5"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default Info;
