import React, { SVGAttributes } from "react";

const Add = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--primary)" } = props;
	return (
		<svg
			width="20px"
			height="20px"
			viewBox="0 0 20 20"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<g id="Profile---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Profile---Edit-profile-Extended" transform="translate(-15.000000, -971.000000)" stroke={stroke}>
					<g id="Group" transform="translate(0.000000, 454.000000)">
						<g id="add-new-position" transform="translate(0.000000, 498.000000)">
							<g id="add_icon" transform="translate(16.000000, 20.000000)">
								<path
									d="M17.5714286,9 C17.5714286,13.734 13.734,17.5714286 9,17.5714286 C4.266,17.5714286 0.428571429,13.734 0.428571429,9 C0.428571429,4.266 4.266,0.428571429 9,0.428571429 C13.734,0.428571429 17.5714286,4.266 17.5714286,9 Z"
									id="Stroke-1"
								/>
								<line x1="4.71428571" y1="9" x2="13.2857143" y2="9" id="Stroke-3" />
								<line x1="9" y1="4.71428571" x2="9" y2="13.2857143" id="Stroke-5" />
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default Add;
