import React, { SVGAttributes } from "react";

// eslint-disable-next-line import/prefer-default-export
const PostNoLongerAvailable = (props: SVGAttributes<SVGElement>) => (
	<svg
		width="21px"
		height="21px"
		viewBox="0 0 21 21"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		// eslint-disable-next-line react/jsx-props-no-spreading
		{...props}
	>
		<g id="Home-/-Feed---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="All-icons" transform="translate(-150.000000, -559.000000)" stroke="var(--accent)">
				<g id="postNoLongerAvailable_ego" transform="translate(150.000000, 559.000000)">
					<line x1="3.9999" y1="8.4999" x2="8.9999" y2="8.4999" id="Stroke-1" />
					<line x1="6.4999" y1="5.9999" x2="6.4999" y2="10.9999" id="Stroke-2" />
					<line x1="12.0004" y1="8.4999" x2="17.0004" y2="8.4999" id="Stroke-3" />
					<line x1="14.5004" y1="5.9999" x2="14.5004" y2="10.9999" id="Stroke-4" />
					<line x1="14.5004" y1="13.4307" x2="14.5004" y2="18.4307" id="Stroke-5" />
					<g id="Group-10">
						<path d="M3.9999,13.514 L17.0009,13.514 L17.0009,15.91 C17.0009,17.256 15.9089,18.347 14.5629,18.347 L14.5629,18.347 C13.2159,18.347 12.1249,17.256 12.1249,15.91 L12.1249,13.514" id="Stroke-6" />
						<polygon id="Stroke-8" points="20.5 20.5 0.5 20.5 0.5 0.5 11.928 0.5 20.5 9.072" />
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default PostNoLongerAvailable;
