import React, { SVGAttributes } from "react";

// eslint-disable-next-line import/prefer-default-export
const PostDeleted = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--accent)" } = props;
	return (
		<svg
			width="82px"
			height="82px"
			viewBox="0 0 82 82"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<g id="A-Post---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-Icons" transform="translate(-57.000000, -346.000000)" stroke={stroke}>
					<g id="postDeleted_ego" transform="translate(57.000000, 346.000000)">
						<path
							d="M17.3888,26.2748 C19.5558,26.2748 21.3188,23.9658 21.3188,21.7988 C21.3188,19.6308 19.5558,17.8688 17.3888,17.8688 C15.2218,17.8688 13.4588,19.6308 13.4588,21.7988 C13.4588,23.9658 15.2218,26.2748 17.3888,26.2748 Z"
							id="Stroke-1"
							strokeDasharray="2,2"
						/>
						<path
							d="M11.0451,32.6263 C11.3401,28.9073 12.9891,27.4013 13.9461,26.8313 L14.1811,26.6923 L14.4171,26.8483 L17.3891,29.0763 L20.3601,26.8483 L20.5971,26.6923 L20.8301,26.8313 C21.7871,27.4013 23.4381,28.9073 23.7321,32.6263 L23.7331,32.9383 C22.1031,34.5083 19.8631,35.4793 17.3901,35.4793 C14.9151,35.4793 12.6751,34.5073 11.0431,32.9373 L11.0451,32.6263 Z"
							id="Stroke-3"
							strokeDasharray="2,2"
						/>
						<path
							d="M29.1867,26.6918 C29.1867,20.1738 23.9027,14.8898 17.3837,14.8898 C10.8657,14.8898 5.5817,20.1738 5.5817,26.6918 C5.5817,33.2108 10.8657,38.4948 17.3837,38.4948 C23.9027,38.4948 29.1867,33.2108 29.1867,26.6918 Z"
							id="Stroke-5"
							strokeDasharray="2,2"
						/>
						<path
							d="M33.0539,74.1898 L5.5809,74.1898 C2.7759,74.1898 0.4999,71.9148 0.4999,69.1078 L0.4999,33.4958 C0.4999,30.6888 2.7759,28.4138 5.5809,28.4138"
							id="Stroke-7"
							strokeDasharray="2,2"
						/>
						<path
							d="M39.9074,81.0433 C43.6924,81.0433 46.7614,77.9753 46.7614,74.1893 C46.7614,70.4043 43.6924,67.3363 39.9074,67.3363 C36.1224,67.3363 33.0534,70.4043 33.0534,74.1893 L33.0534,81.0433 L39.9074,81.0433 Z"
							id="Stroke-9"
							strokeDasharray="2,2"
						/>
						<path
							d="M42.5881,77.0717 C40.9961,78.6637 38.4161,78.6637 36.8241,77.0717 C35.2331,75.4797 35.2331,72.8997 36.8241,71.3077 C38.4161,69.7167 40.9961,69.7167 42.5881,71.3077"
							id="Stroke-11"
							strokeDasharray="2,2"
						/>
						<line x1="40.0539" y1="74.2474" x2="41.3429" y2="68.9854" id="Stroke-13" strokeDasharray="2,2" />
						<path
							d="M75.1906,36.8138 L75.1906,39.6548 L75.1906,69.1078 C75.1906,71.9148 72.9156,74.1898 70.1086,74.1898 L46.7606,74.1898"
							id="Stroke-15"
							strokeDasharray="2,2"
						/>
						<line x1="29.1867" y1="28.4135" x2="40.5667" y2="28.4135" id="Stroke-17" strokeDasharray="2,2" />
						<path
							d="M81.468,21.5004 C81.468,33.0984 72.066,42.5004 60.468,42.5004 C48.869,42.5004 39.468,33.0984 39.468,21.5004 C39.468,9.9014 48.869,0.5004 60.468,0.5004 C72.066,0.5004 81.468,9.9014 81.468,21.5004 Z"
							id="Stroke-19"
						/>
						<path
							d="M58.0646,30.6439 C58.0646,29.7919 58.2936,29.1889 58.7506,28.8339 C59.2066,28.4799 59.7576,28.3019 60.4066,28.3019 C61.0346,28.3019 61.5786,28.4799 62.0346,28.8339 C62.4906,29.1889 62.7176,29.7919 62.7176,30.6439 C62.7176,31.4759 62.4906,32.0729 62.0346,32.4379 C61.5786,32.8029 61.0346,32.9859 60.4066,32.9859 C59.7576,32.9859 59.2066,32.8029 58.7506,32.4379 C58.2936,32.0729 58.0646,31.4759 58.0646,30.6439 Z M58.6426,25.8079 L58.6426,24.8039 C58.6426,23.7499 58.8406,22.8689 59.2366,22.1589 C59.6306,21.4499 60.3356,20.6989 61.3506,19.9079 C62.3636,19.1379 63.0786,18.4979 63.4936,17.9919 C63.9096,17.4859 64.1176,16.8569 64.1176,16.1059 C64.1176,15.3359 63.8486,14.7479 63.3116,14.3419 C62.7736,13.9369 62.0086,13.7349 61.0156,13.7349 C60.1236,13.7349 59.2716,13.8669 58.4606,14.1289 C57.6486,14.3929 56.8376,14.7279 56.0276,15.1329 L54.6596,12.2749 C55.5906,11.7679 56.6056,11.3579 57.7006,11.0419 C58.7946,10.7289 60.0016,10.5719 61.3196,10.5719 C63.3676,10.5719 64.9486,11.0629 66.0636,12.0459 C67.1776,13.0299 67.7366,14.3229 67.7366,15.9239 C67.7366,16.7959 67.5986,17.5409 67.3266,18.1599 C67.0526,18.7789 66.6466,19.3499 66.1096,19.8779 C65.5716,20.4049 64.9276,20.9629 64.1776,21.5509 C63.5496,22.0379 63.0726,22.4539 62.7486,22.7969 C62.4246,23.1419 62.2016,23.4809 62.0796,23.8159 C61.9586,24.1499 61.8966,24.5609 61.8966,25.0469 L61.8966,25.8079 L58.6426,25.8079 Z"
							id="Stroke-21"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default PostDeleted;
