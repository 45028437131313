/* eslint-disable react/require-default-props */
import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { classNames, SvgIcon } from "../common";
import { ThreeDotIcon } from "../assets/icons";

export interface MenuInterface {
	title: string;
	icon: any;
	onClick: () => void;
}
export interface MoreMenuProps {
	/**
	 * CSS style class for container
	 * */
	containerStyle?: string;
	/**
	 * CSS style class for icon
	 * */
	iconStyle?: string;
	/**
	 * CSS style class for title
	 * */
	titleStyle?: string;
	/**
	 * Array of menu items
	 * @default []
	 * */
	items: Array<MenuInterface>;
	/**
	 * icon custom component
	 * */
	iconComp?: any;
	/**
	 * CSS style class for main container
	 * */
	mainContainerStyle?: string;
}

export function MoreMenu({
	containerStyle,
	iconStyle,
	titleStyle,
	items,
	iconComp,
	mainContainerStyle,
}: MoreMenuProps) {
	return (
		<div>
			<Popover className="relative">
				<div>
					<Popover.Button
						className={classNames("group relative")}
						as="div"
					>
						{iconComp || <SvgIcon icon={ThreeDotIcon} color="var(--primary)" title="More" />}
					</Popover.Button>
					<Transition
						as={Fragment}
						enter="transition ease-out duration-200"
						enterFrom="opacity-0 translate-y-1"
						enterTo="opacity-100 translate-y-0"
						leave="transition ease-in duration-150"
						leaveFrom="opacity-100 translate-y-0"
						leaveTo="opacity-0 translate-y-1"
					>
						<Popover.Panel
							className={classNames(containerStyle,
								"absolute xs:-left-8 sm:left-2 -left-1 top-10 z-[21] mt-5 rounded-lg",
								"max-w-sm sm:px-0 w-52 xs:w-56 sm:w-64 md:w-72 bg-modalTransparentBackground",
								(!items || items.length === 0) ? "invisible" : "visible")}
						>
							{({ close }) => (
								<div className={classNames(
									"rounded-lg shadow-xl bg-background-primary m-px",
									"overflow-auto max-h-80",
									"more-menu-list-wrapper",
									mainContainerStyle,
								)}
								>
									<div className=" grid gap-1 p-1 lg:grid-cols-1">
										{items.map((item: any) => (
											<div key={item.title}>
												<button
													type="button"
													key={item.title}
													onClick={(e) => {
														e.preventDefault();
														e.stopPropagation();
														item.onClick();
														close();
													}}
													className={classNames("flex items-center rounded-lg transition w-full p-4",
														"duration-150 ease-in-out hover:bg-profile-border")}
												>
													<div className={classNames("flex items-center h-4 w-4 mr-3",
														"justify-center text-gray-dark", iconStyle)}
													>
														{item.icon}
													</div>
													<p className={
														classNames(
															"text-sm font-medium text-title-color text-left",
															titleStyle,
														)
													}
													>
														{item.title}
													</p>
												</button>
											</div>
										))}
									</div>
								</div>
							)}
						</Popover.Panel>
					</Transition>
				</div>
			</Popover>
		</div>
	);
}

MoreMenu.defaultProps = {
	containerStyle: "",
	titleStyle: "",
	iconStyle: "",
	mainContainerStyle: "",
};
