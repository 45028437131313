/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import Accordion from "../../../gen-comp/Accordion";
import { strings } from "../../../i18n/config";
import { SnackBarTypeOptions, UserDetails, YesNoOptions } from "../../../utils/types";
import SuspendUserModal from "./SuspendUserModal";
import classNames from "../../../utils/classNames";
import { suspendUserRequest } from "../UserRequests";
import { successRETCD } from "../../../api/config";
import ConfirmationModal from "../../../gen-comp/ConfirmationModal";
import { secondsToDhms } from "../../../utils/utils";
import ToastMsg from "../../../gen-comp/ToastMsg";

interface SuspendedUserSectionProps {
	userProfileData: UserDetails;
}

const SuspendedUserSection: React.FC<SuspendedUserSectionProps> = (props: SuspendedUserSectionProps) => {
	const [showSuspendModal, setShowSuspendModal] = useState<boolean>(false);
	const [isVisible, setIsVisible] = useState<boolean>(false);
	const [userDetails, setUserDetails] = useState<UserDetails>();
	const [suspensionDetails, setSuspensionDetails] = useState<any>();

	const {
		userProfileData,
	} = props;

	useEffect(() => {
		setUserDetails(userProfileData);
	}, [userProfileData]);

	const onCancelButton = () => {
		setShowSuspendModal(false);
	};

	const showConfirmationModal = () => {
		setIsVisible(true);
	};

	const showConfirmationModalForSuspend = (item: { reason: any; period: any; }) => {
		setShowSuspendModal(false);
		setIsVisible(true);
		setSuspensionDetails(item);
	};

	const showSuspendUserModal = () => {
		setShowSuspendModal(true);
	};

	const suspendUser = async () => {
		const item = suspensionDetails;
		if (item?.reason && item?.period) {
			const res = await suspendUserRequest({
				user_id: userDetails?.user_id || "",
				reason: item?.reason,
				days: parseInt(item?.period, 10),
				firstName: userDetails?.firstName || "",
				lastName: userDetails?.lastName || "",
				username: userDetails?.username || "",
			});
			if (res && res.code === 200) {
				const data = res?.response;
				if (data && data.retcd === successRETCD) {
					toast(
						<ToastMsg
							message={strings("SuspendedUserSection.suspend_success_message")}
							showButton
							type={SnackBarTypeOptions.SUCCESS}
						/>,
					);
					const date = new Date();
					date.setDate(date.getDate() + parseInt(item?.period, 10));
					const timeleft = Math.abs(new Date().getTime() - date.getTime());
					setUserDetails({
						...userProfileData,
						isSuspended: YesNoOptions.YES,
						suspension_reason: item?.reason,
						suspension_end_date: moment(new Date()).format("DD MMM YYYY"),
						time_left: parseInt(item?.period, 10) === 0 ? strings("SuspendUserModal.forever") : secondsToDhms(timeleft / 1000),
					});
				} else {
					toast(
						<ToastMsg
							message={strings("SuspendedUserSection.error_message")}
							showButton
							type={SnackBarTypeOptions.ERROR}
						/>,
					);
				}
			} else {
				toast(
					<ToastMsg
						message={strings("SuspendedUserSection.error_message")}
						showButton
						type={SnackBarTypeOptions.ERROR}
					/>,
				);
			}
		}
		setShowSuspendModal(false);
		setIsVisible(false);
		setSuspensionDetails(null);
	};

	const unsuspendUser = async () => {
		setIsVisible(false);
		const res = await suspendUserRequest({
			user_id: userDetails?.user_id || "",
			reason: "",
			days: 1 / 86400, // Setting 1 second to cancel user suspension because setting 0 gives some errors in the backend
			firstName: userDetails?.firstName || "",
			lastName: userDetails?.lastName || "",
			username: userDetails?.username || "",
		});
		if (res && res.code === 200) {
			const data = res?.response;
			if (data && data.retcd === successRETCD) {
				toast(
					<ToastMsg
						message={strings("SuspendedUserSection.unsuspend_success_message")}
						showButton
						type={SnackBarTypeOptions.SUCCESS}
					/>,
				);
				setUserDetails({
					...userProfileData,
					isSuspended: YesNoOptions.NO,
				});
			} else {
				toast(
					<ToastMsg
						message={strings("SuspendedUserSection.error_message")}
						showButton
						type={SnackBarTypeOptions.ERROR}
					/>,
				);
			}
		} else {
			toast(
				<ToastMsg
					message={strings("SuspendedUserSection.error_message")}
					showButton
					type={SnackBarTypeOptions.ERROR}
				/>,
			);
		}
	};

	const closeModal = () => {
		setIsVisible(false);
	};

	const updateUser = () => {
		if (userDetails?.isSuspended === YesNoOptions.YES) {
			unsuspendUser();
		} else {
			suspendUser();
		}
	};

	return (
		<div className="bg-white p-3 rounded-lg mx-5 xs:w-full lg:w-5/12" data-testid="suspend-user-accordion">
			<Accordion
				header={(
					<div className="flex flex-row">
						<h5 className="mb-0">
							{userDetails?.isSuspended === YesNoOptions.YES
								? strings("SuspendedUserSection.suspension_details")
								: strings("SuspendedUserSection.suspend_user")}
						</h5>
					</div>
				)}
				content={(
					<div className="flex flex-col">
						{
							userDetails?.isSuspended === YesNoOptions.YES
								? (
									<div>
										<div className="flex flex-row justify-between pb-2">
											<span>
												{strings("SuspendedUserSection.suspension_reason")}
												:&nbsp;
											</span>
											<span>
												{userDetails?.suspension_reason}
											</span>
										</div>
										<div className="flex flex-row justify-between pb-2">
											<span>
												{strings("SuspendedUserSection.suspension_period")}
												:&nbsp;
											</span>
											<span>
												{userDetails?.suspension_end_date}
											</span>
										</div>
										<div className="flex flex-row justify-between pb-2">
											<span>
												{strings("SuspendedUserSection.time_left")}
												:&nbsp;
											</span>
											<span>
												{userDetails?.time_left}
											</span>
										</div>
										<div className="mr-0 my-2">
											<button
												type="button"
												data-testid="unsuspend-user-button"
												className={classNames("w-full inline-flex justify-center rounded-md border",
													"shadow-sm px-4 py-2 bg-primary text-base hover:bg-primary ",
													"focus:outline-none border-transparent font-medium text-white ",
													"focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:ml-0 sm:w-auto sm:text-sm")}
												onClick={showConfirmationModal}
											>
												{strings("SuspendedUserSection.unsuspend")}
											</button>
										</div>
									</div>
								)
								: (
									<div className="mr-0 my-2">
										<button
											type="button"
											data-testid="suspend-user-button"
											className={classNames("w-full inline-flex justify-center rounded-md border",
												"shadow-sm px-4 py-2 bg-primary text-base hover:bg-primary ",
												"focus:outline-none border-transparent font-medium text-white ",
												"focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:ml-0 sm:w-auto sm:text-sm")}
											onClick={showSuspendUserModal}
										>
											{strings("SuspendedUserSection.suspend")}
										</button>
									</div>
								)
						}
					</div>
				)}
				defaultOpen
			/>
			<SuspendUserModal
				isVisible={showSuspendModal}
				cancel_button_function={onCancelButton}
				ok_button_function={showConfirmationModalForSuspend}
			/>
			<ConfirmationModal
				id="suspend-user"
				isVisible={isVisible}
				title={userDetails?.isSuspended === YesNoOptions.YES
					? strings("SuspendedUserSection.unsuspend_user")
					: strings("SuspendedUserSection.suspend_user")}
				message={`${userDetails?.isSuspended === YesNoOptions.YES
					? strings("SuspendedUserSection.unsuspend_user_message")
					: strings("SuspendedUserSection.suspend_user_message")}`
					+ `\`${userDetails?.firstName} ${userDetails?.lastName}\` ?`}
				ok_button_title={strings("SuspendedUserSection.yes")}
				ok_button_function={updateUser}
				cancel_button_title={strings("SuspendedUserSection.no")}
				cancel_button_function={closeModal}
				onClose={closeModal}
			/>
		</div>
	);
};

export default SuspendedUserSection;
