import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { classNames } from "../common";

interface ExploreItemsSkeletonSkeletonProps {
	wrapperClasses?: string;
	noOfItems?: number;
	itemClass?: string;
	itemWrapperClass?: string;
	hideActions?: boolean;
	showTabs?: boolean;
}

const ExploreItemsSkeleton = (props: ExploreItemsSkeletonSkeletonProps) => {
	const {
		wrapperClasses, noOfItems, itemWrapperClass, itemClass, hideActions, showTabs,
	} = props;
	return (
		<div
			className={classNames(
				"w-full z-0",
				wrapperClasses,
			)}
		>
			<SkeletonTheme
				baseColor="var(--background-skeleton)"
				highlightColor="var(--hightlight-skeleton)"
			>
				{showTabs
					? (
						<div className="w-full mt-2 mb-4 flex justify-around">
							<Skeleton containerClassName="w-1/6" height={20} />
							<Skeleton containerClassName="w-1/6" height={20} />
							<Skeleton containerClassName="w-1/6" height={20} />
							<Skeleton containerClassName="w-1/6" height={20} />
							<Skeleton containerClassName="w-1/6" height={20} />
						</div>
					)
					: null }
				{Array.from(Array(noOfItems).keys()).map((i) => (
					<div
						key={i}
						className={classNames(
							"w-full flex flex-col items-center",
							itemWrapperClass,
						)}
					>
						<div
							className={classNames(
								"w-full flex flex-row items-start justify-between p-5",
								itemClass,
							)}
						>
							<div className="w-full flex flex-row items-start">
								<Skeleton width={40} height={40} />
								<div className="w-full flex flex-col ml-5">
									<Skeleton containerClassName="w-1/3" height={20} />
									<Skeleton containerClassName="w-1/6" height={15} />
								</div>
							</div>
							{!hideActions && <Skeleton width={40} height={40} />}
						</div>
						{i < ((noOfItems ?? 0) - 1) ? (
							<div
								className="w-full flex flex-row items-start h-full bg-background-tertiary"
								style={{ height: 1 }}
							/>
						) : null}
					</div>
				))}
			</SkeletonTheme>
		</div>
	);
};

ExploreItemsSkeleton.defaultProps = {
	wrapperClasses: "",
	noOfItems: 5,
	itemClass: "",
	itemWrapperClass: "",
	hideActions: false,
	showTabs: false,
};

export default ExploreItemsSkeleton;
