/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGAttributes } from "react";

const GoogleLarge = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "#C0C9D5",
		fill = "#C0C9D5",
	} = props;
	return (
		<svg
			width="110px"
			height="110px"
			viewBox="0 0 110 110"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<g id="Settings---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Settings---Export" transform="translate(-227.000000, -44.000000)">
					<g id="googleLarge_ego" transform="translate(202.000000, 17.000000)">
						<path
							d="M82,0 C127.287352,0 164,36.7126483 164,82 C164,127.287352 127.287352,164 82,164 C36.7126483,164 0,127.287352 0,82 C0,36.7126483 36.7126483,0 82,0 Z M81.8816739,103.793778 C72.4986406,103.793778 64.5348896,97.5951852 61.6963249,89.0277037 L50.2237926,97.9505185 C56.0586201,109.558074 68.0042465,117.533333 81.8816739,117.533333 C91.4618297,117.533333 99.5044297,114.374815 105.378682,108.92637 C112.080848,102.727778 115.94445,93.6075556 115.94445,82.7896296 C115.94445,79.868 115.707903,77.736 115.195385,75.525037 L81.8816739,75.525037 L81.8816739,88.7118519 L101.436231,88.7118519 C101.041986,91.9888148 98.913062,96.924 94.1821209,100.240444 C91.1858582,102.332963 87.1645582,103.793778 81.8816739,103.793778 Z M81.8816739,46.4666667 C68.0042465,46.4666667 56.0191956,54.4419259 50.1843681,66.0494815 C47.779473,70.8662222 46.3996152,76.2751852 46.3996152,82 C46.3996152,87.7248148 47.779473,93.1337778 50.1843681,97.9505185 L61.6963249,89.0277037 C60.9472593,86.8167407 60.5135897,84.4478519 60.5135897,82 C60.5135897,79.5521481 60.9472593,77.1832593 61.6569004,74.9722963 C64.5348896,66.4048148 72.4986406,60.2062222 81.8816739,60.2062222 C88.544416,60.2062222 93.0388101,63.0883704 95.6014032,65.4967407 L105.615229,55.7053333 C99.4650052,49.9805185 91.4618297,46.4666667 81.8816739,46.4666667 Z"
							id="Combined-Shape"
							stroke={stroke}
							strokeWidth="4"
						/>
						<path
							d="M82.2825352,61.430594 C88.8534218,61.430594 93.2858543,64.3784549 95.8131184,66.8417359 L105.688889,56.8270853 C99.623455,50.9717452 91.7306148,47.3777778 82.2825352,47.3777778 C68.596428,47.3777778 56.7766082,55.5348723 51.0222222,67.4070791 L62.3365892,76.5333333 C65.1749012,67.770514 73.0288604,61.430594 82.2825352,61.430594 L82.2825352,61.430594 Z"
							id="Shape"
							fill={fill}
						/>
						<path
							d="M115.900926,76.5333333 L82,76.5333333 L82,89.0277037 L101.726563,89.0277037 C101.346933,92.2456706 98.7376764,96.9837069 94.1821209,100.240444 L106.448148,109.333333 C112.901852,103.246336 116.622222,94.2903073 116.622222,83.6671395 C116.622222,80.7981087 116.394444,78.7044917 115.900926,76.5333333 Z"
							id="Shape"
							fill={fill}
						/>
						<path
							d="M61.9555556,89.2257426 C61.2416953,86.9524752 60.8284078,84.5168317 60.8284078,82 C60.8284078,79.4831683 61.2416953,77.0475248 61.917984,74.7742574 L50.9846506,65.6 C48.6927835,70.5524752 47.3777778,76.1138614 47.3777778,82 C47.3777778,87.8861386 48.6927835,93.4475248 50.9846506,98.4 L61.9555556,89.2257426 L61.9555556,89.2257426 Z"
							id="Shape"
							fill={fill}
						/>
						<path
							d="M82.3998729,116.622222 C91.8952267,116.622222 99.8666349,113.39169 105.688889,107.819021 L94.5914383,98.9350569 C91.621698,101.075285 87.635994,102.569406 82.3998729,102.569406 C73.0998968,102.569406 65.2066397,96.229486 62.3932015,87.4666667 L51.0222222,96.5929209 C56.8054007,108.465128 68.6452863,116.622222 82.3998729,116.622222 L82.3998729,116.622222 Z"
							id="Shape"
							fill={fill}
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default GoogleLarge;
