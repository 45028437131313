import React, { SVGAttributes } from "react";
import omit from "lodash/omit";
import {
	CredoHelmetIcon, CredoLogoIcon,
} from "../assets/icons";

// eslint-disable-next-line no-undef
export interface LogoProps extends SVGAttributes<HTMLOrSVGElement> {
	/**
	 * Custom CSS class for logo image wrapper div
	 * @default ""
	 * */
	logoClassName?: string;
	/**
	 * Set true for dark logo for the light background
	 * @default false
	 * */
	isDark?: boolean;
  /**
   * Displays only icon instead of logo with text
   * @default false
   * */
  iconOnly?: boolean;
}

export function Logo(props: LogoProps) {
	const { logoClassName, isDark, iconOnly = false } = props;

	const svgProps = omit(props, ["logoClassName", "isDark", "iconOnly"]);

	const renderLight = () => <CredoLogoIcon width={250} height={180} {...svgProps} />;
	const renderDark = () => <CredoLogoIcon width={250} height={180} {...svgProps} />;

	const renderIcon = () => {
		if (iconOnly) {
			if (isDark) {
				return <CredoHelmetIcon height={48} {...svgProps} />;
			}
			return <CredoHelmetIcon height={48} {...svgProps} />;
		}
		if (isDark) {
			return renderDark();
		} else {
			return renderLight();
		}
	};

	return (
		<div className={`flex-shrink-0 items-center text-white flex justify-center py-1.5 ${logoClassName}`}>
			{renderIcon()}
		</div>
	);
}

Logo.defaultProps = {
	logoClassName: "",
	isDark: false,
	iconOnly: false,
};
