import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const PostCommentSkeleton = () => (
	<div className="w-full">
		<SkeletonTheme
			baseColor="var(--background-skeleton)"
			highlightColor="var(--hightlight-skeleton)"
		>
			{Array.from(Array(1).keys()).map((i) => (
				<div
					key={i}
				>
					<div>
						<div className="relative">
							<Skeleton
								className="ml-4"
								style={{
									width: "calc(100% - 1rem)",
									height: 70,
									borderTopLeftRadius: 0,
									borderTopRightRadius: 12,
									borderBottomRightRadius: 12,
									borderBottomLeftRadius: 12,
								}}
							/>
							<div className="w-full absolute top-2.5">
								<Skeleton circle width={30} height={30} />
							</div>
						</div>
						<div className="relative pl-6 mt-2.5">
							<Skeleton
								className="ml-4"
								style={{
									width: "calc(100% - 1rem)",
									height: 70,
									borderTopLeftRadius: 0,
									borderTopRightRadius: 12,
									borderBottomRightRadius: 12,
									borderBottomLeftRadius: 12,
								}}
							/>
							<div className="w-full absolute top-2.5">
								<Skeleton circle width={30} height={30} />
							</div>
						</div>
					</div>
				</div>
			))}
		</SkeletonTheme>
	</div>
);

export default PostCommentSkeleton;
