import * as React from "react"
import {SVGAttributes} from "react";

const SvgComponent = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { stroke = "var(--primary)" } = props;
	return (
		<svg viewBox="0 0 33 33" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
			<g id="glyph-withdraw" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="upload_FILL0_wght400_GRAD0_opsz48" transform="translate(2, 2)" fill={stroke} fillRule="nonzero">
					<path d="M2.71875,29 C1.99375,29 1.359375,28.728125 0.815625,28.184375 C0.271875,27.640625 0,27.00625 0,26.28125 L0,19.8015625 L2.71875,19.8015625 L2.71875,26.28125 L26.28125,26.28125 L26.28125,19.8015625 L29,19.8015625 L29,26.28125 C29,27.00625 28.728125,27.640625 28.184375,28.184375 C27.640625,28.728125 27.00625,29 26.28125,29 L2.71875,29 Z M13.140625,22.0671875 L13.140625,5.25625 L7.703125,10.69375 L5.7546875,8.7453125 L14.5,0 L23.2453125,8.7453125 L21.296875,10.69375 L15.859375,5.25625 L15.859375,22.0671875 L13.140625,22.0671875 Z" id="Shape"></path>
				</g>
			</g>
		</svg>
	)
}

export default SvgComponent
