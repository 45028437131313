/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGAttributes } from "react";

const Requested = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--gray-dark)",
	} = props;
	return (
		<svg
			width="18px"
			height="17px"
			viewBox="0 0 18 17"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<g id="Streams---CT---190723" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-icons" transform="translate(-375.000000, -940.000000)" stroke={stroke}>
					<g id="stream_requested" transform="translate(376.000000, 941.000000)">
						<path
							d="M3,3.63492063 L3,2.85714286 C3,2.30485811 3.44771525,1.85714286 4,1.85714286 L12,1.85714286 C12.5522847,1.85714286 13,2.30485811 13,2.85714286 L13,16.8571429 C13,17.4094276 12.5522847,17.8571429 12,17.8571429 L4,17.8571429 C3.44771525,17.8571429 3,17.4094276 3,16.8571429 L3,16.0793651 L3,16.0793651"
							id="Rectangle"
							strokeLinecap="round"
							transform="translate(8.000000, 9.857143) rotate(90.000000) translate(-8.000000, -9.857143) "
						/>
						<g id="icon-cancel-copy-2" transform="translate(1.420635, 0.000000)">
							<path
								d="M11.8979581,7.99377704 C12.1533602,7.33003116 12.2936508,6.60696006 12.2936508,5.85034217 C12.2936508,2.61928741 9.73527794,0 6.57936508,0 C3.42345222,0 0.865079365,2.61928741 0.865079365,5.85034217 C0.865079365,6.60932528 1.00624839,7.33455154 1.26317064,8"
								id="Oval"
							/>
							<g id="Group-8" transform="translate(4.865079, 3.428571)" strokeLinecap="round">
								<line x1="0" y1="0.0987654321" x2="3.55555556" y2="3.65432099" id="Line-5" />
								<line
									x1="0"
									y1="0.0987654321"
									x2="3.55555556"
									y2="3.65432099"
									id="Line-5"
									transform="translate(1.777778, 1.876543) scale(-1, 1) translate(-1.777778, -1.876543) "
								/>
							</g>
						</g>
						<polyline
							id="Path-4"
							strokeLinecap="round"
							transform="translate(8.000000, 8.857143) scale(1, -1) translate(-8.000000, -8.857143) "
							points="0.125 11.8571429 8 5.85714286 15.875 11.8571429"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default Requested;
