import React, { useMemo } from "react";
import { cu, dbg, LogMgr } from "@credo/utilities";
import { CIStyler } from "../../../credo-tag/c-i-styler";

/**
 * Bar rendering logic:
 * 1. take the top most post's level
 * 2. compare it with current post's boost level
 * 3. render the colored bars based on the resulted value of single bar of top level.
 * */

interface CurrentBoostProps {
	/**
	 * Boost level information of post.
	 * */
	totalBoost: {
		/**
		 * Total boost level which this post has in previous snap
		 * */
		prev: number,
		/**
		 * Total boost level which this post has at this time
		 * */
		current: number,
		/**
		 * Boost level of the top post to display the bars in current
		 * boost icon
		 * */
		topPost: number,
	},
	score: number;
}

// eslint-disable-next-line import/prefer-default-export
export const CurrentBoost: React.FC<CurrentBoostProps> = (props: CurrentBoostProps) => {
	const {
		totalBoost,
		score,
	} = props;

	const color = useMemo(() => CIStyler.csColor(score), [score]);

	const uniqueKey = useMemo(() => `${totalBoost.current}-${score}`, [totalBoost, score]);

	const deltaBars = useMemo(() => {
		const proportion = (totalBoost.topPost / 6);
		const deltaBarCount = totalBoost.current !== 0 ? Math.floor(Math.abs(totalBoost.current / proportion)) : 0;

		if (dbg) {
			LogMgr.mydbg(
				"CurrentBoost delta data is ",
				{
					deltaBarCount,
					proportion,
				},
			);
		}

		return {
			count: deltaBarCount,
			color,
		};
	}, [totalBoost]);

	const renderBars = () => (
		[11, 9, 7, 5, 3, 1].map((item, index) => (
			<rect
				key={`${item}-${index}-${uniqueKey}`}
				id="Rectangle"
				fill={deltaBars.count - index > 0 ? deltaBars.color : "#000000"}
				x="0"
				y={item}
				width="12"
				height="1"
				rx="0.5"
				fillOpacity="0.522372159"
			/>
		))
	);

	return (
		<svg width="100%" viewBox="0 0 16 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
			<rect id="back" stroke="#212636" fill="#212636" x="0.5" y="0.5" width="15" height="23" rx="4" />
			<defs>
				<linearGradient
					x1="8.35442826e-13%"
					y1="50%"
					x2="50%"
					y2="50%"
					id={`CB-volt-icon-linearGradient-3-${uniqueKey}`}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={color} offset="0%" />
					<stop stopColor={color} stopOpacity="0" offset="100%" />
				</linearGradient>
				<linearGradient
					x1="8.35442826e-13%"
					y1="50%"
					x2="50%"
					y2="50%"
					id={`CB-volt-icon-linearGradient-4-${uniqueKey}`}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={color} offset="0%" />
					<stop stopColor={color} stopOpacity="0" offset="100%" />
				</linearGradient>
				<linearGradient
					x1="8.35442826e-13%"
					y1="50%"
					x2="50%"
					y2="50%"
					id={`CB-volt-icon-linearGradient-5-${uniqueKey}`}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={color} offset="0%" />
					<stop stopColor={color} stopOpacity="0" offset="100%" />
				</linearGradient>
				<linearGradient
					x1="50%"
					y1="50%"
					x2="4.62130334e-12%"
					y2="50%"
					id={`CB-volt-icon-linearGradient-6-${uniqueKey}`}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={color} offset="0%" />
					<stop stopColor={color} stopOpacity="0" offset="100%" />
				</linearGradient>
				<linearGradient
					x1="50%"
					y1="50%"
					x2="4.62130334e-12%"
					y2="50%"
					id={`CB-volt-icon-linearGradient-7-${uniqueKey}`}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={color} offset="0%" />
					<stop stopColor={color} stopOpacity="0" offset="100%" />
				</linearGradient>
				<linearGradient
					x1="50%"
					y1="50%"
					x2=".62130334e-12%"
					y2="50%"
					id={`CB-volt-icon-linearGradient-8-${uniqueKey}`}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={color} offset="0%" />
					<stop stopColor={color} stopOpacity="0" offset="100%" />
				</linearGradient>
			</defs>
			<title>{totalBoost.current}</title>
			<g id={`CB-Icon-${uniqueKey}`} transform="translate(2.000000, 1.000000)">
				<rect id="Rectangle" fill="#212636" x="0" y="0" width="12" height="11" />
				{renderBars()}
			</g>
			<g id="CB-volt-icon-line-cap" strokeLinecap="round">
				<g id="CB-volt-icon" transform="translate(4.00000, 2.000000)">
					<line
						x1="4.5"
						y1="0.5"
						x2="5.5"
						y2="0.5"
						id="Line-4"
						stroke={`url(#CB-volt-icon-linearGradient-3-${uniqueKey})`}
						strokeWidth="1.5"
					/>
					<line
						x1="2.5"
						y1="2.5"
						x2="5.5"
						y2="2.5"
						id="Line-4-Copy"
						stroke={`url(#CB-volt-icon-linearGradient-4-${uniqueKey})`}
						strokeWidth="1.5"
					/>
					<line
						x1="0.5"
						y1="4.5"
						x2="7.5"
						y2="4.5"
						id="Line-4-Copy-2"
						stroke={`url(#CB-volt-icon-linearGradient-5-${uniqueKey})`}
						strokeWidth="1.5"
					/>
					<line
						x1="0.5"
						y1="6.5"
						x2="7.5"
						y2="6.5"
						id="Line-4-Copy-2"
						stroke={`url(#CB-volt-icon-linearGradient-6-${uniqueKey})`}
						strokeWidth="1.5"
					/>
					<line
						x1="2.5"
						y1="8.5"
						x2="5.5"
						y2="8.5"
						id="Line-4-Copy-4"
						stroke={`url(#CB-volt-icon-linearGradient-7-${uniqueKey})`}
						strokeWidth="1.5"
					/>
					<line
						x1="2.5"
						y1="10.5"
						x2="3.5"
						y2="10.5"
						id="Line-4-Copy-5"
						stroke={`url(#CB-volt-icon-linearGradient-8-${uniqueKey})`}
						strokeWidth="1.5"
					/>
				</g>
			</g>
			<text
				fontFamily="PTMono-Bold, PT Mono"
				fontSize={totalBoost.current > 10 ** 3 ? "5" : "7"}
				fontWeight="bold"
				letterSpacing="0.0033334"
				fill={color}
				dominantBaseline="middle"
				x="50%"
				y={19}
				textAnchor="middle"
				id={uniqueKey}
				key={uniqueKey}
			>
				{cu.formatNumberWithThousandsSep(cu.convertMSatsToSats(totalBoost.current ?? 0))}
			</text>
		</svg>
	);
};
