import React, { useEffect, useRef } from "react";
import { AppMode, cu } from "@credo/utilities";
import { classNames } from "../../common";
import ClearNewPostTitle from "../../assets/icons/ClearNewPostTitle";

const MAX_LENGTH_POST_TITLE = 100;

export interface PostTitleProps {
	postTitle: string,
	onChangePostTitle: (text: string) => void,
	setPostTitle: (text: string) => void
	placeholder: string;
}

export const PostTitle = ({
	postTitle,
	onChangePostTitle,
	setPostTitle,
	placeholder,
}: PostTitleProps) => {
	const textareaRef = useRef<any>(null);

	useEffect(() => {
		if (textareaRef) {
			textareaRef.current.style.height = "0px";
			const { scrollHeight } = textareaRef.current;
			textareaRef.current.style.height = `${scrollHeight}px`;
		}
	}, [postTitle]);

	const clearTitle = () => {
		setPostTitle("");
	};

	return (
		<div className="w-full">
			<div
				className={classNames(
					"flex flex-row w-full",
					"rounded rounded-bl-none overflow-hidden",
				)}
			>
				<textarea
					onChange={(e) => onChangePostTitle(e.target.value)}
					value={postTitle}
					ref={textareaRef}
					placeholder={placeholder}
					maxLength={MAX_LENGTH_POST_TITLE}
					onKeyPress={(e) => { if (e.key === "Enter") e.preventDefault(); }}
					className={classNames(
						"bg-transparent",
						"text-input-multiline text-basic text-lg",
						"w-full break-words",
						"outline-none",
						"px-2.5",
						"resize-none",
					)}
					style={{
						minHeight: 5,
					}}
				/>
				{postTitle && postTitle.length > 0 ? (
					<div className="flex justify-center items-center px-2.5">
						<div className="rounded-full flex cursor-pointer justify-center items-center p-1">
							{cu.getAppMode() === AppMode.CREDO ? (
								<ClearNewPostTitle onClick={clearTitle} />
							) : (
								<ClearNewPostTitle stroke="var(--gray-dark)" onClick={clearTitle} />
							)}
						</div>
					</div>
				)
					: null}
			</div>
			<span className="flex items-end justify-end text-gray-dark text-right text-sm px-2 h-5 mt-1">
				{postTitle && postTitle.length > 0 ? `${postTitle.length}/100` : " "}
			</span>
		</div>
	);
};
