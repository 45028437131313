/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import {
	createSearchParams, useLocation, useNavigate, useSearchParams,
} from "react-router-dom";
import { isValidPhoneNumber } from "libphonenumber-js";
import { usePrevious } from "react-use";
import Hero from "../../gen-comp/Hero";
import { strings } from "../../i18n/config";
import Filter from "../../gen-comp/Filter";
import Table from "../../gen-comp/table";
import {
	DropdownItem, Modes, NoOfReportedOptions, SearchBarElementInterface, TableHeader, YesNoOptions,
} from "../../utils/types";
import {
	SearchColumns, UserModesOptions, UserReportedOptions, UserTableHeaders, BotFilterList, Timeframes, SelectedReportedUserTableHeaders,
} from "./constants";
import countries from "../../utils/countries.json";
import { getUserQueries, GetUsersVariables } from "./queries";
import { PAGINATION_LIMIT } from "../../utils/constants";
import { DATE_FORMAT } from "../../utils/utils";
import Accordion from "../../gen-comp/Accordion";
import SearchBarContainer from "../../gen-comp/SearchBar/SearchBarContainer";
import { AUTH_URL, USER_DETAILS_PAGE } from "../../routes/constants";
import TooltipHelper from "../../gen-comp/TooltipHelper";
import useDimensions from "../../hooks/useDimensions";
import useOptionalSearchApi from "../../hooks/useOptionalSearchApi";
import { GetWhereClauseVariables } from "../../utils/getWhereClause";
import { getReportedTimeframeStatement, getUserRepStatement } from "./utils";
import { makeApiRequest } from "../../api";

interface BotDropdown extends DropdownItem {
	value: YesNoOptions,
}

const initialData = UserReportedOptions.filter((option) => option.value !== NoOfReportedOptions.ZERO);

export default function UserReportedPage() {
	// to skip the function call on first mount in useEffect
	const skipMountEffect = useRef<boolean>(true);
	const navigate = useNavigate();
	const location = useLocation();

	const getSearchBot = (list: BotDropdown[]) => !(list.length > 1 || list.length === 0);

	const [searchParams] = useSearchParams();
	const searchDataParams = searchParams.get("searchData");
	const isBotParams = searchParams.get("isBotParams");
	const botDropdownListParams = searchParams.get("botDropdownList");
	const headerParams = searchParams.get("headers");
	const selectedCountriesParams = searchParams.get("selectedCountries");
	const reportedTimeframeParams = searchParams.get("reportedTimeframe");

	const [isBot, setIsBot] = useState<YesNoOptions>(
		isBotParams === YesNoOptions.YES ? YesNoOptions.YES : YesNoOptions.NO,
	);
	const [searchBot, setSearchBot] = useState<boolean>(botDropdownListParams ? getSearchBot(JSON.parse(botDropdownListParams)) : true);
	const [botDropdownList, setBotDropdownList] = useState<BotDropdown[]>(
		botDropdownListParams ? JSON.parse(botDropdownListParams) : BotFilterList.filter((e) => e.value === YesNoOptions.NO),
	);
	const [headers, setHeaders] = useState<TableHeader[]>(
		headerParams ? JSON.parse(headerParams) : localStorage.getItem("reported-user-headers")
			? JSON.parse(localStorage.getItem("reported-user-headers") || "") : SelectedReportedUserTableHeaders,
	);
	const [userData, setUserData] = useState<any>([]);
	const [page, setPage] = useState<number>(1);
	const [changeInPage, setChangeInPage] = useState<boolean>(true);
	const [searchData, setSearchData] = useState<SearchBarElementInterface[]>(
		searchDataParams ? JSON.parse(searchDataParams) : [],
	);
	const [selectedCountries, setCountryData] = useState<any>(selectedCountriesParams ? JSON.parse(selectedCountriesParams) : []);
	const [reportedOptions, setReportedOptions] = useState<DropdownItem[]>(initialData);
	const [userMode, setUserMode] = useState<DropdownItem[]>(UserModesOptions);
	const [timeframeFilter, setTimeframeFilter] = useState<DropdownItem[]>(
		reportedTimeframeParams ? JSON.parse(reportedTimeframeParams) : Timeframes,
	);
	const [userIdByPhone, setUserIdByPhone] = useState<string>("");
	const [hasPhoneNumber, setHasPhoneNumber] = useState<SearchBarElementInterface | undefined>(undefined);
	const [userIdByPhoneLoading, setUserIdByPhoneLoading] = useState<boolean>(false);
	const [, width] = useDimensions();
	const prevSearchData = usePrevious(searchData);

	const wrapperRef = useRef(null);

	useEffect(() => {
		if (botDropdownList.length > 1 || botDropdownList.length === 0) {
			setSearchBot(false);
		}

		if (botDropdownList.length === 1) {
			setIsBot(botDropdownList[0].value);
			setSearchBot(true);
		}
	}, [botDropdownList]);

	useEffect(() => {
		/**
		 * Browser(Brave/Chromium) was converting "+" to space/"%20", couldn't take any chance so replaced it.
		 * */
		const paramString = `searchData=${JSON.stringify(searchData).replace("+", "%2B")}`
			+ `&isBot=${isBot}`
			+ `&botDropdownList=${JSON.stringify(botDropdownList)}`
			+ `&selectedCountries=${JSON.stringify(selectedCountries)}`
			+ `&reportedTimeframe=${JSON.stringify(timeframeFilter)}`
			+ `&headers=${JSON.stringify(headers)}`;
		if (window) {
			window.history.replaceState(null, "", `${location.pathname}?${paramString}`);
		}
	}, [isBot, searchBot, searchData, headers, botDropdownList, selectedCountries, timeframeFilter]);

	/**
	 * This is to update the child components as well since once the data
	 * is update they are not getting updated automatically
	 * */
	useEffect(() => {
		if (searchDataParams) {
			setSearchData(JSON.parse(searchDataParams));
		}
		if (botDropdownListParams) {
			setBotDropdownList(JSON.parse(botDropdownListParams));
		}
		if (headerParams) {
			setHeaders(JSON.parse(headerParams));
		}
		if (selectedCountriesParams) {
			setCountryData(JSON.parse(selectedCountriesParams));
		}
		if (reportedTimeframeParams) {
			setTimeframeFilter(JSON.parse(reportedTimeframeParams));
		}
		if (isBotParams && botDropdownListParams) {
			setIsBot(isBotParams === YesNoOptions.YES ? YesNoOptions.YES : YesNoOptions.NO);
		}
	}, []);

	const applyFilter = (items: TableHeader[]) => {
		setHeaders(items);
	};

	const applyCountryFilter = (items: any) => {
		setCountryData(items);
	};

	const applyBotDropdownSelected = (items: BotDropdown[]) => {
		setBotDropdownList(items);
	};

	const updateSearchDropdownItems = (items: SearchBarElementInterface[]) => {
		setSearchData(items);
	};

	const applyReportedFilter = (items: TableHeader[]) => {
		setReportedOptions(items);
	};

	const applyUserModeFilter = (items: DropdownItem[]) => {
		setUserMode(items);
	};

	const applyTimeframeFilter = (items: DropdownItem[]) => {
		setTimeframeFilter(items);
	};

	const navigateToUserDetails = (item: any) => {
		navigate({
			pathname: AUTH_URL + USER_DETAILS_PAGE,
			search: `?${createSearchParams({ userId: item?.user_id })}`,
		});
	};

	useEffect(() => {
		setHasPhoneNumber(searchData.find((element) => isValidPhoneNumber(element.selectedSearchValue)));
	}, [searchData]);

	const getUserByPhone = async () => {
		if (hasPhoneNumber) {
			setUserIdByPhoneLoading(true);
			const result = await makeApiRequest({
				method: "get",
				url: `api/userByPhone/${hasPhoneNumber?.selectedSearchValue}`,
			});

			const userId = await result.user_id;
			setUserIdByPhone(userId);
		} else {
			setUserIdByPhone("");
		}
	};

	const getCountryFilterQuery = () => {
		if (selectedCountries.length > 0) {
			let query = "{ loc_in: [";
			selectedCountries.forEach((item: { title: any; }) => {
				query += `"${item.title}",`;
			});
			return `${query}]}`;
		}
		return "";
	};

	const variables: GetUsersVariables = {
		headers: UserTableHeaders,
		isBot,
		searchBot,
		userId: userIdByPhone,
		searchData,
		reportedOptions,
		timeframeFilter,
		userMode,
		countryFilter: getCountryFilterQuery(),
		offset: (page - 1) * PAGINATION_LIMIT,
	};

	const getOtherFilters = () => {
		let finalFilterQuery: GetWhereClauseVariables["otherFilterQuery"] = {};

		if (reportedOptions.length !== UserReportedOptions.length) {
			const repfilter = getUserRepStatement(reportedOptions, userMode);
			if (repfilter.length > 0) {
				finalFilterQuery = {
					...finalFilterQuery,
					// ignore the keyword, since the state will contain searchQueries field,
					// the key will be used just for storage purpose
					e_nb_rep: repfilter,
				};
			}
		}

		if (timeframeFilter.length !== Timeframes.length) {
			const repfilter = getReportedTimeframeStatement(timeframeFilter, userMode);
			if (repfilter.length > 0) {
				finalFilterQuery = {
					...finalFilterQuery,
					// ignore the keyword, since the state will contain searchQueries field,
					// the key will be used just for storage purpose
					e_reported_ts: repfilter,
				};
			}
		}

		if (selectedCountries.length > 0) {
			finalFilterQuery = {
				...finalFilterQuery,
				loc: selectedCountries,
			};
		}

		return finalFilterQuery;
	};

	const variablesForSearchAPI = {
		searchData: searchData.filter((searchEl) => searchEl.selectedSearchValue),
		otherFilterQuery: getOtherFilters(),
	};

	const useSearchApi = (reportedOptions
		.some((reported) => reported.value === NoOfReportedOptions.ZERO)
		&& reportedOptions.length !== UserReportedOptions.length);

	const [getSearchApiData, { data, loading }] = useOptionalSearchApi(getUserQueries(variables), variablesForSearchAPI, {
		entity: "User",
		page: 1,
		useSearchApi,
		formatDataKey: "user",
	});

	const makeSearchRequest = async () => {
		await getUserByPhone();
	};

	useEffect(() => {
		if (userIdByPhoneLoading && !loading) {
			setUserIdByPhoneLoading(false);
		}
	}, [loading]);

	useEffect(() => {
		makeSearchRequest();
	}, [hasPhoneNumber]);

	useEffect(() => {
		setPage(1);
		setChangeInPage(true);
	}, [reportedOptions, searchBot, isBot, userMode, selectedCountries, timeframeFilter]);

	useEffect(() => {
		if (changeInPage) {
			getSearchApiData();
		}
	}, [page, changeInPage]);

	useEffect(() => {
		if (skipMountEffect.current) {
			skipMountEffect.current = false;
		} else {
			let searchChanged = false;
			if (prevSearchData && prevSearchData?.length >= searchData.length) {
				prevSearchData.forEach((singleSearch, index) => {
					if (
						singleSearch.selectedSearchValue !== searchData[index]?.selectedSearchValue
						|| singleSearch.selectedValueData.length !== searchData[index]?.selectedValueData.length
					) {
						searchChanged = true;
					}
				});
			}
			if (searchChanged) {
				setChangeInPage(true);
				setPage(1);
			}
		}
	}, [searchData]);

	useEffect(() => {
		const items: any[] = [];
		data?.forEach((element: any) => {
			items.push({
				...element,
				access_ts: element?.access_ts
					? moment(new Date(element.access_ts)).format(DATE_FORMAT) : strings("UserSearchPage.not_available"),
				uname_change_expiry_ts: element?.uname_change_expiry_ts
					? moment(new Date(element.uname_change_expiry_ts)).format(DATE_FORMAT) : strings("UserSearchPage.not_available"),
				hf_access_ts: element?.hf_access_ts
					? moment(new Date(element.hf_access_ts)).format(DATE_FORMAT) : strings("UserSearchPage.not_available"),
				cmodif_ts: element?.cmodif_ts
					? moment(new Date(element.cmodif_ts)).format(DATE_FORMAT) : strings("UserSearchPage.not_available"),
				emodif_ts: element?.emodif_ts
					? moment(new Date(element.emodif_ts)).format(DATE_FORMAT) : strings("UserSearchPage.not_available"),
				modif_egouname_ts: element?.modif_egouname_ts
					? moment(new Date(element.modif_egouname_ts)).format(DATE_FORMAT) : strings("UserSearchPage.not_available"),
				creat_ts: element?.creat_ts
					? moment(new Date(element.creat_ts)).format(DATE_FORMAT) : strings("UserSearchPage.not_available"),
				modif_ts: element?.modif_ts
					? moment(new Date(element.modif_ts)).format(DATE_FORMAT) : strings("UserSearchPage.not_available"),
				c_reported_ts: element?.c_reported_ts
					? moment(new Date(element.c_reported_ts)).format(DATE_FORMAT) : strings("UserSearchPage.not_available"),
				e_reported_ts: element?.e_reported_ts
					? moment(new Date(element.e_reported_ts)).format(DATE_FORMAT) : strings("UserSearchPage.not_available"),
			});
		});
		setUserData(items);
		setChangeInPage(false);
	}, [data]);

	const redirectToUserDetailsPageWithMode = (item: any) => {
		navigate({
			pathname: AUTH_URL + USER_DETAILS_PAGE,
			search: `?${createSearchParams({
				userId: item?.user_id,
				mode: item?.mode,
			})}`,
		});
	};

	const renderColumn = (props: { item: any; header: any; }) => {
		const { item, header } = props;
		if (!header || !item) {
			return null;
		}
		return (
			<button
				type="button"
				className="w-full truncate underline text-blue-600 hover:text-blue-800 "
				onClick={() => redirectToUserDetailsPageWithMode({
					...item,
					mode: header.value.includes("e_") ? Modes.EGO : Modes.CREDO,
				})}
			>
				{item[header.value] || ""}
			</button>
		);
	};

	return (
		<div ref={wrapperRef}>
			<Hero title={strings("UserReportedPage.hero_title")} pageHasTable />
			<div data-testid="search-card" className="bg-white p-5 rounded-lg -mt-24 mx-5 flex flex-col mb-5">
				<Accordion
					header={(
						<div className="flex flex-row justify-evenly items-center">
							<h5 className="mb-1">
								{strings("UserSearchPage.search")}
							</h5>
							<div className="pl-2">
								<TooltipHelper
									title={strings("UserSearchPage.search_helper_text")}
									placement={width < 769 ? "bottom" : "right"}
								/>
							</div>
						</div>
					)}
					content={(
						<div>
							<SearchBarContainer
								dropdownItems={SearchColumns}
								handleData={updateSearchDropdownItems}
								data={searchData}
							/>
						</div>
					)}
					defaultOpen
				/>
				<Accordion
					header={(
						<h5 className="mb-1">
							{strings("UserSearchPage.filter")}
						</h5>
					)}
					content={(
						<div className="grid grid-cols-1 xs:grid-cols-2-max-20 sm:grid-cols-3-max-20 gap-2 lg:gap-4">
							<Filter
								id="column-filters"
								data={UserTableHeaders}
								filterName={strings("Dashboard.table_title")}
								applyFilter={applyFilter}
								selectedData={headers}
								showTitle={false}
								local_storage_key="reported-user-headers"
							/>
							<Filter
								id="isbot-filter"
								data={BotFilterList}
								filterName={strings("UserSearchPage.filterBot")}
								applyFilter={applyBotDropdownSelected}
								selectedData={botDropdownList}
								showTitle={false}
							/>
							<Filter
								id="country-filter"
								data={countries}
								selectedData={selectedCountries}
								applyFilter={applyCountryFilter}
								filterName={strings("UserSearchPage.location")}
								showTitle={false}
							/>
							<Filter
								id="reported-filter"
								data={UserReportedOptions}
								filterName={strings("UserSearchPage.no_of_reported")}
								applyFilter={applyReportedFilter}
								selectedData={reportedOptions}
								showTitle={false}
							/>
							<Filter
								id="user-mode-filter"
								data={userMode}
								filterName={strings("UserSearchPage.user_mode")}
								applyFilter={applyUserModeFilter}
								selectedData={userMode}
								showTitle={false}
							/>
							<Filter
								id="timeframe-filter"
								data={Timeframes}
								filterName={strings("UserSearchPage.timeframe")}
								applyFilter={applyTimeframeFilter}
								selectedData={timeframeFilter}
								showTitle={false}
								disableInput
							/>
						</div>
					)}
					defaultOpen
				/>
			</div>
			<div className="bg-white p-5 rounded-lg my-5 mx-5">
				<div data-testid="users-data-table" className="my-0">
					<Table
						header={headers}
						data={userData}
						pagination={{
							onPageChange: (num) => {
								setChangeInPage(true);
								setPage(num);
							},
							hidePageNumber: true,
							scrollOnPageChange: true,
							page,
						}}
						onItemClick={navigateToUserDetails}
						showHeader
						showSortIcons
						isLoading={loading || userIdByPhoneLoading}
						CustomColumnComponent={renderColumn}
					/>
				</div>
			</div>
		</div>
	);
}
