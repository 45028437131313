import React, {
	ForwardedRef,
	useCallback,
	useEffect, useImperativeHandle, useMemo, useRef, useState,
} from "react";
import { CSSTransition } from "react-transition-group";
import styles from "./bottom-sheet.module.css";
import { classNames, Portal } from "../common";

interface BottomSheetProps {
	children: React.ReactNode;
	isOpen: boolean;
	onClose: () => void;
	disableClickOnOverlay?: boolean;
	contentClassName?: string;
}

export interface BottomSheetRef {
	closeSheet: () => void
}

// eslint-disable-next-line import/prefer-default-export
export const BottomSheet: React.FC<BottomSheetProps> = React.forwardRef((
	props: BottomSheetProps,
	ref: ForwardedRef<BottomSheetRef>,
) => {
	const {
		children,
		isOpen,
		onClose,
		disableClickOnOverlay = false,
		contentClassName = "",
	} = props;
	const contentRef = useRef<HTMLDivElement | null>(null);
	const [show, setShow] = useState<boolean>(false);

	useImperativeHandle(ref, () => ({
		closeSheet: handleCloseSheet,
	}));

	useEffect(() => {
		setTimeout(() => {
			setShow(isOpen);
		}, 50);
		if (document) {
			const rootElement = document.getElementsByTagName("body")[0];
			if (isOpen) {
				rootElement.classList.add("overflow-hidden");
			} else {
				rootElement.classList.remove("overflow-hidden");
			}
		}

		return () => {
			const rootElement = document.getElementsByTagName("body")[0];
			rootElement.classList.remove("overflow-hidden");
		};
	}, [isOpen]);

	const handleCloseSheet = useCallback(() => {
		if (!disableClickOnOverlay) {
			setShow(false);
		}
	}, []);

	const renderHandle = useMemo(() => (
		<button
			type="button"
			className="h-6 w-full flex justify-center items-center"
			onClick={handleCloseSheet}
		>
			<span
				className="h-2 w-14 bg-bottom-sheet-handle-knob rounded-full"
			/>
		</button>
	), []);

	if (!isOpen) return null;

	return (
		<Portal>
			<CSSTransition
				onExited={() => {
					setTimeout(() => {
						onClose();
						// Make sure this time should be more than the css --bottom-sheet-slide-animation-timer
					}, 300);
				}}
				in={show}
				timeout={0}
				nodeRef={contentRef}
				classNames={{
					enter: styles.sheetEnter,
					enterDone: styles.sheetEnterDone,
					exit: styles.sheetExit,
					exitDone: styles.sheetExitDone,
				}}
			>
				<React.Fragment>
					{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
					<div
						onClick={handleCloseSheet}
						className="fixed inset-0 bg-black bg-opacity-25"
					/>
					<div
						ref={contentRef}
						className={classNames(
							styles.base,
							styles.baseUIStyle,
							contentClassName,
						)}
					>
						{renderHandle}
						{children}
					</div>
				</React.Fragment>
			</CSSTransition>
		</Portal>
	);
});
