import {ThemeOptions} from "../types";

/**
 * Toggles theme from credo to ego and vice versa.
 *
 * @param {ThemeOptions} value The value of theme which should be set.
 * */
export const toggleTheme = (value: ThemeOptions) => {
	const rootElement = document.getElementsByTagName("body")[0];
	if (value === ThemeOptions.CREDO) {
		if (rootElement?.classList.contains(ThemeOptions.EGO)) {
			rootElement?.classList.remove(ThemeOptions.EGO);
		}
		rootElement?.classList.add(ThemeOptions.CREDO);
	} else {
		if (rootElement?.classList.contains(ThemeOptions.CREDO)) {
			rootElement?.classList.remove(ThemeOptions.CREDO);
		}
		rootElement?.classList.add(ThemeOptions.EGO);
	}
};
