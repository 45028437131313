import React, { SVGAttributes } from "react";

// eslint-disable-next-line import/prefer-default-export
const StreamMemberManagement = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--gray-dark)",
	} = props;
	return (
		<svg
			width="22px"
			height="20px"
			viewBox="0 0 22 20"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<g id="Streams---CT---190723" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-icons" transform="translate(-374.000000, -495.000000)" stroke={stroke}>
					<g id="streamMemberManagement" transform="translate(375.000000, 496.000000)">
						<path d="M8.15485714,13.9554947 L1.42819048,13.9554947 C0.90247619,13.9554947 0.475809524,13.5244421 0.475809524,12.9929684 L0.475809524,1.43696842 C0.475809524,0.904547368 0.90247619,0.473494737 1.42819048,0.473494737 L5.23771429,0.473494737 L14.7615238,0.473494737 L18.5710476,0.473494737 C19.0977143,0.473494737 19.5234286,0.904547368 19.5234286,1.43696842 L19.5234286,12.9929684 C19.5234286,13.5244421 19.0977143,13.9554947 18.5710476,13.9554947 L17.5434286,13.9554947" id="Stroke-1" strokeLinecap="round" />
						<path d="M7.22114286,7.17584211 C8.03257143,7.17584211 8.69161905,6.51078947 8.69161905,5.69321053 C8.69161905,4.87468421 8.03257143,4.20963158 7.22114286,4.20963158 C6.41066667,4.20963158 5.75066667,4.87468421 5.75066667,5.69321053 C5.75066667,6.51078947 6.41066667,7.17584211 7.22114286,7.17584211 Z" id="Stroke-3" />
						<line x1="13.8095238" y1="4.80713684" x2="17.0171429" y2="4.80713684" id="Stroke-5" strokeLinecap="round" />
						<path d="M12.9264762,14.2313684 C13.8245714,14.2313684 14.556,13.4924211 14.556,12.5838947 C14.556,11.6753684 13.8245714,10.9373684 12.9264762,10.9373684 C12.028381,10.9373684 11.2979048,11.6753684 11.2979048,12.5838947 C11.2979048,13.4924211 12.028381,14.2313684 12.9264762,14.2313684 Z" id="Stroke-7" />
						<polygon id="Stroke-9" points="17.688 11.0665895 16.6146667 9.18606316 14.6327619 9.60290526 13.9994286 7.65985263 11.8527619 7.65985263 11.2194286 9.60290526 9.23752381 9.18606316 8.16419048 11.0665895 9.51180952 12.5928 8.16419048 14.1190105 9.23752381 15.9995368 11.2194286 15.5826947 11.8527619 17.5266947 13.9994286 17.5266947 14.6337143 15.5826947 16.6146667 15.9995368 17.688 14.1190105 16.340381 12.5928" />
						<path d="M8.49457143,11.7468 C8.05838095,11.8803789 7.59647619,11.9523789 7.11742857,11.9523789 C4.5012381,11.9523789 2.3812381,9.81322105 2.3812381,7.17574737 C2.3812381,4.53827368 4.5012381,2.40006316 7.11742857,2.40006316 C9.73266667,2.40006316 11.8526667,4.53827368 11.8526667,7.17574737 L11.8526667,7.65985263" id="Stroke-11" />
						<path d="M3.85009524,10.6316526 C3.85009524,10.6316526 4.37580952,9.14523158 5.46819048,8.40817895 C5.46819048,8.40817895 6.14628571,8.87996842 7.22152381,8.87996842 C7.72152381,8.87996842 8.07485714,8.77765263 8.31009524,8.66870526 L9.23771429,9.18596842" id="Stroke-13" />
					</g>
				</g>
			</g>
		</svg>
	);
};

export default StreamMemberManagement;
