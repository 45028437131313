import { ThemeOptions } from "./types";

const toggleColorTheme = (value: ThemeOptions) => {
	const rootElement = document.getElementsByTagName("body")[0];
	if (value === ThemeOptions.DARK) {
		if (rootElement?.classList.contains(ThemeOptions.LIGHT)) {
			rootElement?.classList.remove(ThemeOptions.LIGHT);
		}
		rootElement?.classList.add(ThemeOptions.DARK);
	} else {
		if (rootElement?.classList.contains(ThemeOptions.DARK)) {
			rootElement?.classList.remove(ThemeOptions.DARK);
		}
		rootElement?.classList.add(ThemeOptions.LIGHT);
	}
};

export default toggleColorTheme;
