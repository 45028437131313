import * as React from "react"
import {SVGAttributes} from "react";

const SvgComponent = (props: SVGAttributes<SVGElement>) => {
	const {stroke = "var(--gray-dark)"} = props;
	return (<svg width={20} height={20} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="m18.322 13.82-2.55-2.02c-.744-.584-1.806-.478-2.443.24-.478.53-1.12 1.236-1.175 1.316-.158.159-.478.08-.982-.266-.664-.452-1.46-1.222-2.232-1.993-.77-.77-1.566-1.567-1.99-2.231-.32-.478-.426-.824-.266-.984.08-.079.749-.731 1.307-1.183.716-.61.822-1.7.238-2.444L6.184 1.678a1.77 1.77 0 0 0-2.098-.532c-.77.319-1.86 1.196-2.204 1.568C.846 3.75.712 5.556 1.536 7.79c.77 2.099 2.31 4.33 4.329 6.349 2.02 2.02 4.276 3.56 6.348 4.331.983.372 1.859.531 2.657.531 1.008 0 1.832-.292 2.416-.877.372-.372 1.222-1.46 1.568-2.205.318-.744.106-1.62-.532-2.098Z"
				stroke={stroke}
				fill="none"
				fillRule="evenodd"
			/>
		</svg>
	)
};

export default SvgComponent
