import React, { useCallback } from "react";
import { cu } from "@credo/utilities";
import VideoPlayer from "./VideoPlayer";
import { classNames } from "../../common";

export interface PostVideoProps {
	/**
   * Sets post data having video details
   * */
	postData: any;
	/**
   * Whether post has senstitve content or not
   * */
	isContentRestricted: boolean;
	/**
   * whethere post is shared or not, used for applying different styling
   * */
	sharedPost: boolean;
	/**
   * Has error msg to be shown if image doesn't load
   * */
	imageErrorMsg: string;
	/**
   * Has sensitive content msg
   * */
	contentSensitiveMsg: string;
	/**
	 * set true if the component is being called inside
	 * PostCompactView or need a compact version of this
	 * component
	 *
	 * @default false
	 * */
	// eslint-disable-next-line react/require-default-props
	isCompactView?: boolean;
}

const PostVideoComponent: React.FC<PostVideoProps> = (props) => {
	const {
		postData,
		isContentRestricted,
		sharedPost,
		contentSensitiveMsg,
		imageErrorMsg,
		isCompactView = false,
	} = props;

	const buildMediaObj_video = useCallback((bucketRelativeRefPath: string) => (
		<VideoPlayer
			source={bucketRelativeRefPath}
			isContentRestricted={isContentRestricted}
			contentSensitiveMsg={contentSensitiveMsg}
			imageErrorMsg={imageErrorMsg}
		/>
	), [isContentRestricted, contentSensitiveMsg, imageErrorMsg]);

	if (!cu.isSet(postData?.["p.video_bucketRelativeRefPath"])) {
		return null;
	}

	return (
		<div
			className={classNames(
				isCompactView ? "" : "px-4 pb-4",
			)}
		>
			{buildMediaObj_video(cu.buildSourceUrlImage(postData["p.video_bucketRelativeRefPath"]) || "")}
		</div>
	);
};

const compareFn = (
	nextProps: PostVideoProps,
	prevProps: PostVideoProps,
) => nextProps.sharedPost === prevProps.sharedPost
	&& nextProps.isContentRestricted === prevProps.isContentRestricted
	&& JSON.stringify(nextProps.postData) === JSON.stringify(prevProps.postData)
	&& nextProps.contentSensitiveMsg === prevProps.contentSensitiveMsg
	&& nextProps.imageErrorMsg === prevProps.imageErrorMsg
	&& nextProps.isCompactView === prevProps.isCompactView;

const PostVideo = React.memo(
	PostVideoComponent,
	compareFn,
);

export default PostVideo;
