import { isNil } from "lodash";
import CfgMgr from "../config/CfgMgr";
import { strings } from "../i18n/config";
import { deleteAllCookies } from "./cookieUtils";
import { signOutAccount } from "./Firebase";
import LogMgr from "./LogMgr";

export const dbg = CfgMgr.CFG_DEBUG_ENABLED;

export const DATE_FORMAT = "DD MMM YYYY HH:mm:ss UTC";

export function isDefined(x: any) {
	return (typeof x !== "undefined");
}
export function isSet(x: any) {
	return x != null && isDefined(x) && x !== "";
}
export function mydbg(...argument: any[]) {
	const callerThis = argument["0"];
	const args = Object.values(argument);
	let where: string;
	where = callerThis && typeof callerThis === "string" ? callerThis : "none";
	if (!where) {
		where = callerThis?.constructor?.name ? callerThis.constructor.name : "none1";
	}
	const callerName = mydbg.name ? mydbg.name : "none1";
	const txt = `DBG: ${where}:${callerName}:`;
	// TODO: Remove console in the future
	// eslint-disable-next-line no-console
	console.log(txt, ...args);
	LogMgr.log({ msg: `${txt} ${args.join(" ")}` });
	return true;
}

export function getCircularReplacer() {
	const seen = new WeakSet();
	return (key: any, value: object | null) => {
		if (typeof value === "object" && value !== null) {
			if (seen.has(value)) {
				return "";
			}
			seen.add(value);
		}
		return value;
	};
}

export function jsonfy(x: { message: string; stack: string; }) {
	return JSON.stringify(x, getCircularReplacer); // JSON.stringify(car, null, 2) for better indentation
}

export function myerr(...argument: any[]) {
	const args = Object.values(argument);
	const callerThis = argument["0"];
	const where = callerThis?.constructor?.name ? callerThis.constructor.name : "none1";
	const callerName = myerr.name ? myerr.name : "none1";
	const txt = `ERR: ${where}:${callerName}:`;
	if (dbg) {
		mydbg(txt, ...args);
	}
	LogMgr.log({ msg: `${txt} ${args.join(" ")}` });
}

export function printException(callerThis: { constructor: { name: any; }; }, txt: any, exc: { message: any; stack: any; }) {
	const where = callerThis?.constructor?.name ? callerThis.constructor.name : "none1";
	const callerName = printException?.name ? printException.name : "none1";
	const errTxt = `EXCEPTION: ${where}:${callerName}:`;
	const jsonExc = jsonfy(exc);
	if (dbg) mydbg(errTxt, txt, " : ", jsonExc, "; msg=", exc.message, "; stack=", exc.stack ? exc.stack.substring(0, 500) : "");
	LogMgr.log({ msg: `${errTxt} ${txt} ${jsonExc} ${exc.message}` });
}

export function getErrTxt(exc: { message: string; stack: string; }) {
	return (exc.message && exc.stack) ? exc.stack.substring(0, 500) : "";
}

export function isNullOrEmptyArray(data: string | any[]) {
	return !isSet(data) || (isSet(data) && data.length < 1);
}

/*
	o/p :-
	10500 -> 10.5K
	1000000 -> 1M
*/
export function formatNumberWithThousandsSep(number: any) {
	const SI_SYMBOL = ["", "K", "M", "B", "T"];
	// eslint-disable-next-line no-bitwise
	const tier = Math.log10(number) / 3 | 0;
	if (tier === 0) return number;
	const suffix = tier > 4 ? `${SI_SYMBOL[4]}+` : SI_SYMBOL[tier];
	const scale = 10 ** (tier * 3);
	let scaled = number / scale;
	scaled = scaled.toFixed(1) as unknown as number;
	const formatted_number = scaled % 1 > 0 ? scaled : scaled / 1;
	return formatted_number + suffix;
}

export function formatAsNbWithThousandsWithDefault(count: any, defaultVal: any) {
	return count ? formatNumberWithThousandsSep(count) : defaultVal || 0;
}

export async function logoutUser() {
	deleteAllCookies();
	localStorage.removeItem("roles");
	localStorage.removeItem("userDetails");
	localStorage.removeItem("timestamp");
	localStorage.clear();
	signOutAccount();
	window.location.href = `${window.location.origin}/`;
}

export function checkInvalidSession() {
	const timestamp = localStorage.getItem("timestamp");
	if (timestamp && timestamp !== "null" && timestamp !== "") {
		try {
			const expiryTS = JSON.parse(timestamp || "");
			const currentTS = new Date().getTime();
			const difference = expiryTS - currentTS;
			const daysDifference = Math.round(difference / 1000 / 60 / 60 / 24);
			if (daysDifference >= 7 || daysDifference < 0) {
				logoutUser();
			}
		} catch (error) {
			if (dbg) mydbg("error while checking invalid cookies");
		}
	} else {
		logoutUser();
	}
}

export function buildSourceUrlImage(bucketRelativeRefPath: string) {
	if (isNil(bucketRelativeRefPath) || typeof bucketRelativeRefPath !== "string") {
		return null;
	}
	const bucketRelativeRefPathURL = bucketRelativeRefPath.replace(/\//g, "%2F");
	const imageUrl = `${CfgMgr.cfg_imageStorage_path + bucketRelativeRefPathURL}?alt=media`;
	return imageUrl;
}

export function secondsToDhms(seconds: number) {
	const d = Math.floor(seconds / (3600 * 24));
	const h = Math.floor((seconds % (3600 * 24)) / 3600);
	const m = Math.floor((seconds % 3600) / 60);
	const s = Math.floor(seconds % 60);

	const dDisplay = d > 0 ? `${d}d ` : "";
	const hDisplay = h > 0 ? `${h}h ` : "";
	const mDisplay = m > 0 ? `${m}m ` : "";
	const sDisplay = s > 0 ? `${s}s ` : "";
	return dDisplay + hDisplay + mDisplay + sDisplay;
}

export function isYes(x: string) {
	return !isNil(x) && x === "y";
}

export function onlyNumbers(str: string) {
	return /^[0-9]+$/.test(str);
}

export const getDateTimestampOfPreviousMonths = (months: number) => new Date(
	new Date().getFullYear(),
	(new Date().getMonth() - months),
	new Date().getDate(),
).getTime();

// reference https://stackoverflow.com/posts/9851769/timeline
// Safari 3.0+ "[object HTMLElementConstructor]"
export const isSafari = /constructor/i.test((window as any).HTMLElement)
	// eslint-disable-next-line func-names
	|| (function (p) { return p.toString() === "[object SafariRemoteNotification]"; }(
		!(window as any).safari
		|| (typeof (window as any).safari !== "undefined" && (window as any).safari.pushNotification),
	));

export const checkForRCIMsg = (msg: string) => {
	// handling for rci report option - Revealed credo identity
	if (msg === "rci") {
		return strings("PostSearchPage.rci");
	}
	return msg;
};
