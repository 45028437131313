import {
	AppMode, PostType, UserProfile, YesNoOptions,
} from "@credo/utilities";
import { TagMessages } from "../credo-tag";
import { ReOrderItem } from "../list-animations/ReOrderAnimation.stories";
import { PostHeaderScreenType } from "../post";

export const Consts = {
	postType_followUser: "fu",
	postType_connectUser: "cu",
	postType_followStream: "fs",
	postType_joinStream: "js",
	main_wall_credo_stream_id: "mwc",
	main_wall_ego_stream_id: "mwe",
	sess: "sess",
	umas_stream_name: "UMAS",
	blast_stream_name: "Credo Blasts",
	blast_stream_id: "cb",
	blast_stream_username: "credo_blasts",
	share_link: "Share Link",
	// User is rating a for the first time
	ratingFirstTime: "ratingFT",
	proposeTag: "proposeTag",
	voltsTransaction: "voltsTransaction",
};

export enum ItemType {
  USER = "u",
  STREAM = "s",
}

export const RegEx = {
	url: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
	hashTag: /(?:\s|^)#[A-Za-z0-9\-._]+(?:|$)/gi,
	userTag: /@\[([^@[]*)]\((([\d\w,._-]*))\)/gi,
	userTagUsernameOnly: /@([^@[ ]*)/gi,
	boldTag: /\*([^*]+?)\*/gi,
	youtubeVideo: /(?:youtube(?:-nocookie)?\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/,
};

export const EventConst = {
	updateFeedData: "updateFeedData",
	ratingTutorialModal: "ratingTutorialModal",
	hintActions: "hintActions",
	getPostGraphData: "getPostGraphData",
	showCredoGraph: "showCredoGraph",
	pushPostGraphDataToComp: "pushPostGraphDataToComp",
	trayOnExpand: "trayOnExpand",
	boostPostActionUpdate: "boostPostActionUpdate",
};

export const DEFAULT_TAG_MESSAGES: TagMessages = {
	rate_this_item: "Rate this post in",
	rating_info: "Rating a post helps train your personal credibility lens and contributes to the collective credibility assessment of content and people.",
	view_tutorial: "view tutorial",
	creed_high: "Highly credible",
	creed_med: "Not sure...",
	creed_low: "Not credible at all",
	boost_this_post: "Boost this post in",
	boost_info: "Boosting a post locks up your volts in return for a higher ranking in Top Boards.",
	with_your_sats: "With your sats",
	rank: "Rank",
	your_boost: "Your boost",
	boosters: "Boosters",
	boostContentOp: {
		boosting_the_post: "Boosting the post",
		boost_amount: "Boost amount",
		cancel: "Cancel",
		confirm: "confirm",
		confirm_your_boost: "Confirm your boost",
		est_boost_div: "est boost div",
		taking_longer: "Its taking little longer we will keep the process alive in the background",
		your_new_boost: "Your new boost",
		est_boost_info_content: "Portion of your boost amount that is paid to the existing boosters of this post who have a higher collective credibility score than yours (Uccs).",
		est_boost_info_heading: "Credibility Commission (Estimated)",
	},
	current: "Current",
	boostStats: {
		pnl: "Profit / Loss",
		position: "Position",
	},
	boost: "Boost",
	add_tag: "Add Tag",
};

export const USER_PROFILE: UserProfile = {
	email_custom: "bhushan.patil@gmail.com",
	email_apple: "bhushan.patil@gmail.com",
	email_facebook: "bhushan.patil@gmail.com",
	email_google: "bhushan.patil@gmail.com",
	email_linkedin: "bhushan.patil@gmail.com",
	email_apple_ts: "bhushan.patil@gmail.com",
	email_custom_rv: "bhushan.patil@gmail.com",
	email_facebook_ts: "bhushan.patil@gmail.com",
	email_linkedin_ts: "bhushan.patil@gmail.com",
	email_twitter_ts: "bhushan.patil@gmail.com",
	email_google_ts: "bhushan.patil@gmail.com",
	id_verif_status: "n",
	timezone: "",
	email_twitter: "bhushan.patil@gmail.com",
	uxptags: [],
	uCredibilityDefault: 0,
	user_id: "a182b9e0-72cb-11ed-9947-9dbaa1ef4f3d",
	cprof_id: "291ceee2-a8c0-49c7-ab2c-e9c9b7c4f512",
	eprof_id: "dee6e242-56a1-4b43-b26f-6983d31d969d",
	firstName: "Sheldyn",
	lastName: "NS",
	edescr: "7208003415",
	eprofilePicRelUrl: "a182b9e0-72cb-11ed-9947-9dbaa1ef4f3d_e_profile_img.jpg",
	ecoverPicRelUrl: "a182b9e0-72cb-11ed-9947-9dbaa1ef4f3d_e_cover_img.jpg",
	cdescr: " ",
	cprofilePicRelUrl: "credoprofile/img/lavender_pilot_tqfr.png",
	ccoverPicRelUrl: "u_c_cp.png",
	username: "lavender-pilot_tqfr-LJ5",
	egousername: "bhushan.patil",
	nb_of_uname_change: 3,
	phone: "+917208003415",
	modif_uname_ts: 1683614271744,
	is_apn_newfol: "y",
	is_apn_newconreq: "y",
	is_apn_conapp: "y",
	is_apn_compos: "y",
	is_apn_comrep: "y",
	is_apn_comnewpos: "y",
	is_apn_comnewfol: "y",
	is_apn_comnewfolreq: "y",
	is_apn_comnewjoireq: "y",
	is_apn_comjoireqapp: "y",
	is_apn_newmsg: "y",
	is_apn_engpts: "y",
	is_apn_bdgerd: "y",
	is_apn_menpos: "y",
	is_apn_mencom: "y",
	ps_e_provis: "ee",
	ps_c_provis: "ee",
	ps_e_folyou: "ee",
	ps_c_folyou: "ee",
	ps_e_folsee: "ee",
	ps_c_folsee: "ee",
	ps_e_conyou: "ee",
	ps_c_conyou: "ee",
	ps_e_consee: "ee",
	ps_c_consee: "ee",
	ps_e_comsee: "ee",
	ps_c_comsee: "ee",
	ps_e_dirmsg: "ee",
	ps_c_dirmsg: "ee",
	ps_e_possee: "ee",
	ps_c_possee: "ee",
	ps_e_compos: "ee",
	ps_c_compos: "ee",
	ps_e_evtfoluse: "y",
	ps_c_evtfoluse: "n",
	ps_e_evtfolcom: "y",
	ps_c_evtfolcom: "n",
	ps_e_evtusecon: "y",
	ps_c_evtusecon: "n",
	ps_e_evtjoicom: "y",
	ps_c_evtjoicom: "n",
	q_rating_steps: "0,0.25,0.50,0.75,1",
	uxp_summary: " ",
	sortedUxptags: [
		{
			engagement: 0.781,
			subjectivity: 0.15,
			b_credo_score: -1,
			tag: "saint vincent and the grenadines",
			g_credo_score: 0.625,
			relevance: 1,
			s_credo_score: 1,
		},
		{
			engagement: 0.714,
			subjectivity: 0.15,
			b_credo_score: -1,
			tag: "pig",
			g_credo_score: 0.75,
			relevance: 1,
			s_credo_score: 1,
		},
		{
			engagement: 0.445,
			subjectivity: 0.15,
			b_credo_score: -1,
			tag: "germany",
			g_credo_score: 0.27,
			relevance: 1,
			s_credo_score: 1,
		},
		{
			engagement: 0.377,
			subjectivity: 0.15,
			b_credo_score: -1,
			tag: "formula 1",
			g_credo_score: 1,
			relevance: 1,
			s_credo_score: -1,
		},
		{
			engagement: 0.364,
			subjectivity: 0.15,
			b_credo_score: -1,
			tag: "forestry",
			g_credo_score: 1,
			relevance: 1,
			s_credo_score: 1,
		},
		{
			engagement: 0.283,
			subjectivity: 0.15,
			b_credo_score: -1,
			tag: "england",
			g_credo_score: 1,
			relevance: 1,
			s_credo_score: 1,
		},
		{
			engagement: 0.256,
			subjectivity: 0.15,
			b_credo_score: -1,
			tag: "dog",
			g_credo_score: 1,
			relevance: 1,
			s_credo_score: 1,
		},
		{
			engagement: 0.216,
			subjectivity: 0.85,
			b_credo_score: -1,
			tag: "crypto",
			g_credo_score: 1,
			relevance: 1,
			s_credo_score: 0.798,
		},
		{
			engagement: 0.202,
			subjectivity: 0.85,
			b_credo_score: -1,
			tag: "credo",
			g_credo_score: 0.749,
			relevance: 1,
			s_credo_score: 0.827,
		},
		{
			engagement: 0.081,
			subjectivity: 0.15,
			b_credo_score: -1,
			tag: "azerbaijan gp",
			g_credo_score: 1,
			relevance: 1,
			s_credo_score: 1,
		},
	],
};

export const DEFAULT_POST_DATA = {
	id: 1093,
	"p.nb_views": 1,
	"p.egousername": "Some.Ego.username",
	"p.shared_edit_ts": "1662966097616",
	"p.shared_creat_ts": "1662966097616",
	"s.stramId": "90975984-21AE-462D-A0C3-1214ABCE0433",
	"p.shared_egousername": "some.shared.egousername",
	"p.shared_username": "interesting",
	"p.article_date": 88834929,
	cardData: [],
	boardVersion: 1,
	boardTs: 1688623357515,
	ranking: 1,
	prevRanking: 1,
	topBoosters: [],
	rankScore: 10,
	prevTotalBoost: 9,
	totalBoost: 10,
	"p.postUuid": "90975984-21AE-462D-A0C3-1214ABCE0433",
	"p.postType": PostType.Post,
	"p.creat_ts": 1688623357515,
	"p.modif_ts": 1688623357515,
	"p.edit_ts": 1688623357515,
	"p.postText": "Testing video",
	"p.postTitle": "Testing video",
	postFlare: "[]",
	"p.created_by": PostHeaderScreenType.User,
	"p.mediaObj_Link_Url": " ",
	"p.video_bucketRelativeRefPath": "a182b9e0-72cb-11ed-9947-9dbaa1ef4f3d_90975984-21AE-462D-A0C3-1214ABCE0433_v.mp4",
	isAdmin: true,
	"s.name": "18maycryptocomm",
	"s.struname": "18maycryptocomm",
	"s.streamId": "d4d522a6-35f8-5373-97ad-28013fb1c094",
	"p.prof_id": "291ceee2-a8c0-49c7-ab2c-e9c9b7c4f512",
	"p.username": "lavender-pilot_tqfr-LJ5",
	"p.mode": AppMode.CREDO,
	"p.profilePicRelUrl": "credoprofile/img/lavender_pilot_tqfr.png",
	p_edit: YesNoOptions.YES,
	emoCode: [
		[
			null,
			null,
		],
	],
	emoCountA: [
		[
			null,
			0,
		],
	],
	nb_of_emo: 0,
	postTags: [
		"crypto.com",
		"crypto",
	],
	sortedPostTags: {},
	authorTags: [],
	comments: [
		{
			c_emoCode4Comments: [],
			p_commentAuthorTags: [],
			s_commentAuthorTags: [],
			p_emoCode4ParentComments: [],
			c_commentAuthorTags: [],
			s_emoCnt: [],
			c_nb_of_c_emo: 0,
			c_emoCnt: [],
			p_emoCnt: [],
			s_edit: "n",
			p_edit: "n",
			s_nb_of_sc_emo: 0,
			p_no_of_subco: 0,
			c_no_of_subco: 0,
			p_nb_of_pc_emo: 0,
			c_edit: "n",
			s_emoCode4SubComments: [],
		},
	],
	"p.firstName": "Sheldyn",
	"p.lastName": "NS",
	"p.img_bucketRelativeRefPath0": "https://i.imgur.com/CzXTtJV.jpg",
	"p.img_bucketRelativeRefPath1": "https://i.imgur.com/OB0y6MR.jpg",
	"p.img_bucketRelativeRefPath2": "https://farm2.staticflickr.com/1533/26541536141_41abe98db3_z_d.jpg",
	"p.img_bucketRelativeRefPath3": "https://farm4.staticflickr.com/3075/3168662394_7d7103de7d_z_d.jpg",
	"p.nb_rep": 3,
	"p.nb_of_co": 103,
	"p.shared_cnt": 100,
	"p.mediaObj_Link_ImgUrl": "https://media.cnn.com/api/v1/images/stellar/prod/230725095700-ukraine-zaporizhzhia-071323.jpg?c=16x9&q=h_720,w_1280,c_fill",
	"p.src_s_name": "Credo Finance",
	"p.shared_postUuid": "6fdb0f6f-7f77-4c4a-ac3b-edb0b12a4c8f",
	"p.shared_created_by": PostHeaderScreenType.Bot,
	"p.shared_mode": "e",
	"p.shared_prof_id": "ecredo_news_bot_finance",
	"p.shared_firstName": "Credo Finance",
	"p.shared_lastName": "Bot",
	"p.shared_profilePicRelUrl": "https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659652_1280.png",
	"p.shared_post_streamId": "credo_news_bot_finance",
	"p.is_shared": YesNoOptions.YES,
	nb_ratings: 0,
	"p.src_s_struname": "credo_blasts",
};

export const REORDER_INIT_DATA: ReOrderItem[] = [
	{
		id: 1,
		title: "this is first",
		value: 1,
		backgroundColor: "#ff99c8",
		height: 50,
	},
	{
		id: 2,
		title: "this is second",
		value: 2,
		backgroundColor: "#fcf6bd",
		height: 80,
	},
	{
		id: 3,
		title: "this is third",
		value: 3,
		backgroundColor: "#d0f4de",
		height: 50,
	},
	{
		id: 4,
		title: "this is fourth",
		value: 4,
		backgroundColor: "#a9def9",
		height: 100,
	},
	{
		id: 5,
		title: "this is fifth",
		value: 5,
		backgroundColor: "#e4c1f9",
		height: 120,
	},
];

export const TABLET_WIDTH = 968;

export const MOBILE_COMPACT_WIDTH = 540;
// Web size proportion
export const WEB_SIZE_PROP = 1.5;
// Web size proportion for button
export const WEB_SIZE_PROP_BUTTON = 1.2;
// web size proportion for tray
export const WEB_SIZE_PROP_TRAY = 1.2;

export const VOLTS_TXN_ANIMATION_TIMER_MS = 2000;

export const SIDE_NAV_MIN_SCREEN_WIDTH = 968;
// Since the content in modal are all static height, the height should not increase.
export const BOOST_MODAL_HEIGHT = 352;
export const BOOST_MODAL_WIDTH = 352;
