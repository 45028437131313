import React, { SVGAttributes } from "react";

const Connections = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--gray-dark)" } = props;
	return (
		<svg
			width="22px"
			height="16px"
			viewBox="0 0 22 16"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<g id="Profile---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Profile---Ego-view---more" transform="translate(-37.000000, -400.000000)" stroke={stroke}>
					<g id="connections" transform="translate(38.000000, 401.000000)">
						<path d="M3,2 L3,-2 C3,-2.55228475 3.44771525,-3 4,-3 L16,-3 C16.5522847,-3 17,-2.55228475 17,-2 L17,16 C17,16.5522847 16.5522847,17 16,17 L4,17 C3.44771525,17 3,16.5522847 3,16 L3,12 L3,12 L3,2 Z" id="Rectangle" strokeLinecap="round" transform="translate(10.000000, 7.000000) rotate(90.000000) translate(-10.000000, -7.000000) " />
						<g id="Group-6" transform="translate(2.000000, 2.000000)">
							<path
								d="M9.66243386,4.81861111 C9.66243386,7.47966667 7.4994709,9.63722222 4.83174603,9.63722222 C2.16296296,9.63722222 -4.08562073e-14,7.47966667 -4.08562073e-14,4.81861111 C-4.08562073e-14,2.15755556 2.16296296,5.06261699e-14 4.83174603,5.06261699e-14 C7.4994709,5.06261699e-14 9.66243386,2.15755556 9.66243386,4.81861111 Z"
								id="Stroke-1"
							/>
							<path
								d="M4.93756614,4.9925 C5.76515234,4.9925 6.43756614,4.32176724 6.43756614,3.49625 C6.43756614,2.67073276 5.76515234,2 4.93756614,2 C4.11101441,2 3.43756614,2.67073276 3.43756614,3.49625 C3.43756614,4.32176724 4.11101441,4.9925 4.93756614,4.9925 Z"
								id="Stroke-3"
							/>
							<path
								d="M2,8.51222222 C2.13227513,6.93733333 2.87619048,6.30083333 3.30687831,6.05911111 L3.41269841,6 L3.51851852,6.06544444 L4.85714286,7.01966667 L6.19470899,6.06544444 L6.3015873,6 L6.40634921,6.05911111 C6.83703704,6.30083333 7.58095238,6.93838889 7.71322751,8.51327778"
								id="Stroke-5"
							/>
						</g>
						<line
							x1="14"
							y1="4.5"
							x2="17.3683449"
							y2="4.5"
							id="Line"
							strokeLinecap="round"
						/>
						<line
							x1="14"
							y1="7.5"
							x2="17.3683449"
							y2="7.5"
							id="Line-Copy"
							strokeLinecap="round"
						/>
						<line
							x1="14"
							y1="10.5"
							x2="17.3683449"
							y2="10.5"
							id="Line-Copy-2"
							strokeLinecap="round"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default Connections;
