import React, { SVGAttributes } from "react";

const VoltsNav = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { fill = "var(--gray-dark)" } = props;
	return (
		<svg
			width="20px"
			height="20px"
			viewBox="0 0 20 20"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<title>Volts</title>
			<defs>
				<circle id="path-volts-1" cx="10" cy="10" r="8" />
				<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-volts-3">
					<stop stopColor="#DFDFDF" offset="0%" />
					<stop stopColor="#FFFFFF" offset="33.1321023%" />
					<stop stopColor="#000000" offset="100%" />
				</linearGradient>
				<polygon id="path-volts-4" points="8.50044523 -1.47937218e-14 1.03153098 6.5 4.79379943 6.5 3.54781804 13 11.031531 6.5 7.24180787 6.5" />
			</defs>
			<g id="Icon---Volts---Inactive" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="CB-Icon-Oval">
					<circle id="Oval" stroke="#212636" fill={fill} cx="10" cy="10" r="9.5" />
					<mask id="mask-volts-2" fill="white">
						<use xlinkHref="#path-volts-1" />
					</mask>
					<use id="Icon-Back" fill="#212636" xlinkHref="#path-volts-1" />
					<g id="CB-Icon-LB" mask="url(#mask-volts-2)">
						<g transform="translate(4, 3.5)">
							<mask id="mask-volts-5" fill="white">
								<use xlinkHref="#path-volts-4" />
							</mask>
							<use id="path-volts-2" stroke="#888888" strokeWidth="0.5" fill="url(#linearGradient-volts-3)" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" xlinkHref="#path-volts-4" />
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default VoltsNav;
