import * as React from "react";

export type SpinnerLeafShape = {
	r: number, // radius
	height: number,
	width: number,
}

export const SpinnerLeafType = {
	CIRCLES: { r: 1.5, height: 3, width: 3 },
	SHORT_BARS: { r: 1.5, height: 6, width: 3 },
	LONG_BARS: { r: 1.5, height: 8, width: 3 },
};

export type SpinnerProps = {
	props?: any,
	width?: string,
	height?: string,
	ani_loop_s?: number,
	leaf_padding?: number,
	leaf_count?: number,
	leaf_shape?: SpinnerLeafShape,
	leaf_fill?: string,
}
/**
 * Renders an SVG spinner - to make your wait more enjoyable
 * @param props
 * @param width
 * @param height
 * @param ani_loop_s
 * @param leaf_padding
 * @param leaf_count
 * @param leaf_shape
 * @param leaf_fill
 * @constructor
 */
export const Spinner = ({
	props = {},
	width = "100%",
	height = "100%",
	ani_loop_s = 1,
	leaf_padding = 1,
	leaf_count = 12,
	leaf_shape = SpinnerLeafType.SHORT_BARS,
	leaf_fill = "blue",
}: SpinnerProps) => {
	const leaves = [];
	const angle_delta = 360 / leaf_count;
	const ani_delay_delta = (1) / leaf_count;

	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < leaf_count; i++) {
		leaves.push(
			<rect
				key={i}
				x={20 - leaf_shape.height - leaf_padding}
				y={10 - leaf_shape.height / 2}
				ry={leaf_shape.r}
				rx={leaf_shape.r}
				height={leaf_shape.width}
				width={leaf_shape.height} /* its not a typo - we swap h/w */
				fill={leaf_fill}
				transform={`rotate(${i * angle_delta} 10 10)`}
			>
				<animate
					attributeName="opacity"
					values="1;0"
					keyTimes="0;1"
					dur={`${ani_loop_s}s`}
					begin={`${-ani_loop_s + (i * ani_delay_delta)}s`}
					repeatCount="indefinite"
				/>
			</rect>,
		);
	}

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			style={{
				width,
				height,
				background: "transparent",
				margin: 0,
			}}
			viewBox="0 0 20 20"
			preserveAspectRatio="xMidYMid"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			{leaves}
		</svg>
	);
};

Spinner.defaultProps = {
	props: {},
	width: "100%",
	height: "100%",
	ani_loop_s: 1,
	leaf_padding: 1,
	leaf_count: 12,
	leaf_shape: SpinnerLeafType.SHORT_BARS,
	leaf_fill: "blue",
};
