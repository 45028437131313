import * as React from "react"
import { SVGAttributes } from "react";

const SvgComponent = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const {stroke = "var(--gray-dark)", fill } = props;
	return (
		<svg width={16} height={16} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g stroke={fill || stroke} fill="none" fillRule="evenodd" strokeLinecap="round">
				<path d="m1 1 13.821 13.821M15 1 1.179 14.821" />
			</g>
		</svg>
	)
};

export default SvgComponent
