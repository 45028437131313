import React, {SVGAttributes} from "react";

const CredoLogo = (props: SVGAttributes<HTMLOrSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={1076}
		height={230}
		viewBox="20 20 1076 230"
		{...props}
	>
		<defs>
			<linearGradient id="a" x1="0%" x2="100%" y1="50%" y2="50%">
				<stop offset="18.723%" stopColor="#00FF25" />
				<stop offset="50.934%" stopColor="#00FFF5" />
				<stop offset="84.315%" stopColor="#FF7000" />
			</linearGradient>
		</defs>
		<g fill="none" fillRule="evenodd">
			<g stroke="#0BE0D8">
				<g strokeLinecap="round" strokeWidth={21}>
					<path
						fill="#10121B"
						d="M732 29h140v140c0 38.66-31.34 70-70 70h-70c-38.66 0-70-31.34-70-70V99c0-38.66 31.34-70 70-70Z"
					/>
					<path d="M767 81.5v105M1029.5 133.5H925" />
				</g>
				<g strokeLinecap="round" strokeWidth={21}>
					<path
						fill="#10121B"
						d="M592 29c38.66 0 70 31.34 70 70v35H557v105h-35c-38.66 0-70-31.34-70-70V99c0-38.66 31.34-70 70-70h70Z"
					/>
					<path d="M661.5 134.5H557M557 81.5v147" />
				</g>
				<g strokeLinecap="round" transform="translate(557 134)">
					<rect
						width={105}
						height={105}
						fill="#10121B"
						strokeWidth={21}
						rx={35}
					/>
					<path strokeWidth={10.5} d="M104.75 52.5H52.5M52.5 26.25l.25 7.5" />
				</g>
				<g strokeLinecap="round" strokeWidth={21} transform="translate(242 29)">
					<rect width={210} height={210} fill="#10121B" rx={70} />
					<path d="M209.5 105H105M105 52.5v105" />
				</g>
				<g strokeWidth={21} transform="translate(874 29)">
					<rect width={210} height={210} fill="#10121B" rx={70} />
					<path strokeLinecap="round" d="M104.5 52.5v105" />
				</g>
			</g>
			<path
				stroke="#0BE0D8"
				strokeWidth={21}
				d="M102 29h70c38.66 0 70 31.34 70 70v140H102c-38.66 0-70-31.34-70-70V99c0-38.66 31.34-70 70-70Z"
			/>
			<path
				fill="#10121B"
				fillRule="nonzero"
				d="M166.728 224.825h61.097V104.086c0-33.743-27.354-61.097-61.097-61.097h-59.642c-33.743 0-61.097 27.354-61.097 61.097v59.642c0 33.743 27.354 61.097 61.097 61.097h59.642Z"
			/>
			<path
				fill="#10121B"
				fillRule="nonzero"
				d="M107.086 39C71.14 39 42 68.14 42 104.086v59.642c0 35.946 29.14 65.085 65.086 65.085h124.727V104.086c0-35.946-29.14-65.086-65.085-65.086h-59.642Zm0 7.977h59.642c31.54 0 57.108 25.569 57.108 57.109v116.75h-116.75c-31.225 0-56.597-25.06-57.1-56.164l-.009-.944v-59.642c0-31.54 25.569-57.109 57.109-57.109Z"
			/>
			<path
				fill="#0E575A"
				d="M65.037 110.635c0-26.462 21.451-47.914 47.913-47.914h47.913c26.462 0 47.914 21.452 47.914 47.914v47.913c0 26.462-21.452 47.913-47.914 47.913H112.95c-26.462 0-47.913-21.451-47.913-47.913v-47.913Z"
			/>
			<path
				fill="#10121B"
				fillRule="nonzero"
				d="M158.546 56.813c30.935 0 56.013 25.077 56.013 56.012v43.092c0 30.935-25.078 56.013-56.013 56.013h-43.092c-30.935 0-56.013-25.078-56.013-56.013v-43.092c0-30.935 25.078-56.013 56.013-56.013h43.092Zm0 25.842h-43.092c-16.663 0-30.17 13.507-30.17 30.17v43.092c0 16.663 13.507 30.17 30.17 30.17h43.092c16.663 0 30.17-13.507 30.17-30.17v-43.092c0-16.663-13.507-30.17-30.17-30.17Z"
			/>
			<g fillRule="nonzero">
				<path
					fill="url(#a)"
					d="M8.415 36.005c0-15.238 12.352-27.59 27.59-27.59h82.623c15.237 0 27.59 12.352 27.59 27.59 0 9.873 0 0 0 0 0 13.201-10.41 27.59-27.59 27.59H36.005c-18.698 0-27.59-14.758-27.59-27.59 0 0 0 8.023 0 0Z"
					transform="translate(59.59 97.218)"
				/>
				<path
					fill="#10121B"
					d="M178.218 97.218c19.885 0 36.005 16.12 36.005 36.004v4.388h-.284l-.006.052c-.935 7.191-4.102 14.188-9.048 19.74l-.191.213c-6.657 7.354-15.944 11.612-26.476 11.612H95.595c-11.211 0-20.641-4.314-27.182-11.905-4.91-5.7-7.885-12.952-8.634-20.378l-.015-.156h-.174v-3.566l.005-.595c.318-19.61 16.314-35.41 36-35.41h82.623Zm0 16.83H95.595c-10.59 0-19.175 8.584-19.175 19.174l.002.238c.055 4.592 1.704 9.256 4.592 12.7l.15.177c3.34 3.877 8.113 6.06 14.431 6.06h82.623c5.7 0 10.483-2.193 13.999-6.077 3.288-3.632 5.176-8.495 5.176-13.098l-.002-.317c-.17-10.444-8.689-18.858-19.173-18.858Z"
				/>
			</g>
		</g>
	</svg>
)

export default CredoLogo;
