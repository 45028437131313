/* eslint-disable no-underscore-dangle */
/* eslint-disable max-classes-per-file */
import * as React from "react";
import { useCallback, useRef, useState } from "react";
import { DeviceDetectMgr } from "@credo/utilities";
import {
	Bounds, BoundsUtils, CIProps, CIStyler, classNames, Scaler, ScalerSize, SubScaler, TAG_COLOR,
} from "../common";
import { ModalEvent, ModalPopup, ModalState } from "./modal-popup";
import styles from "./indicator-html.module.css";

export declare interface SvgRatingSelectorProps extends CIProps {
	rating: number;
	// eslint-disable-next-line react/no-unused-prop-types
	anchor: HTMLElement;

	onCancel(): boolean;

	onSelected(new_rating: number | null): boolean;

	onRatingHover(new_rating: number): void;

	showEgoModal: boolean;

	// eslint-disable-next-line react/require-default-props
	egoInfoProps?: {
		onPressTagInfoBubble: () => void;
		onPressCredoGraphText: () => void,
		switchComp: React.ReactNode;
	}
}

/**
 * This is a styler for the selector, its job is to encapsulate and thus make pluggable the styling of the selector
 */
class SelectorStyler extends CIStyler<SvgRatingSelectorProps> {
	// eslint-disable-next-line class-methods-use-this
	modeClass = (base: string, mode: ModalState) => {
		switch (mode) {
			case ModalState.Active:
				return base;
			case ModalState.Inactive:
				return `${base} ${styles.inactive}`;
			case ModalState.Closing:
				return `${base} ${styles.closing}`;
			default:
				return base;
		}
	};

	// eslint-disable-next-line class-methods-use-this
	ribbonStroke = (_rating: number, rating_snapped: boolean) => (rating_snapped ? "black" : "none");

	ribbonFill = (cs: number | null) => this._csColor(cs === null ? 0 : cs);

	panelByCs(cs: number, alpha: number) {
		return this._csColor(cs, alpha);
	}

	// eslint-disable-next-line class-methods-use-this
	ribbonLabelFontWeight(_rating: number, rating_snapped: boolean) {
		return rating_snapped ? "500" : "normal";
	}

	getClassesAsPerSize(name: string, size: ScalerSize) {
		return `${styles[name]} ${styles[size]}`;
	}
}

interface OuterContainerBounds extends Bounds {
	width: number;
	border_radius: number;
	padding: number;
	background_color: string;
}
interface ContainerBounds extends Bounds {
	dynamic: {
		x: () => number;
		y: () => number;
	}
}
interface PanelBounds extends Bounds {
	dynamic: {
		rx: number
	}
}
interface MarkerBounds extends Bounds {
	dynamic: {
		cx: number,
		cy: number,
		cx_delta: number,
		cy_delta: number,
		r: number
	}
}
interface BubbleBounds extends Bounds {
	dynamic: {
		cx: number,
		cy: number,
		r: number
	}
}
interface RibbonBounds extends Bounds {
	dynamic: {
		rx: number,
		range_top: number,
		range_height: number,

		yOffsetByRating: (rating: number) => number,
		labelYOffsetByRating: (rating: number) => number,

		label_x: number,
		label_font_size: number
		stroke_width: number,
		marker_font_size: number,
	}
}
/**
 * This is a scaler, it encapsulates scaling logic for the selector, basically knows how to adjust dimensions, etc
 * given a TShirt size
 */
class SelectorScaler extends Scaler<SvgRatingSelectorProps> {
	outerContainer: SubScaler<OuterContainerBounds>;

	container: SubScaler<ContainerBounds>;

	panel: SubScaler<PanelBounds>;

	markers: SubScaler<MarkerBounds>;

	ribbon: SubScaler<RibbonBounds>;

	bubble: SubScaler<BubbleBounds>;

	constructor(props: SvgRatingSelectorProps) {
		/**
		 * Resize this and everything will rescale automatically
		 * This should be the right size for "M" size
		 */
		super({
			width: 100, // 72
			border_width: 8,
			height: 100 * 2.24, // 150
			static: {
				anchor: props.anchor,
				ribbon_r: (100 * 2.15) / 10,
				marker_r: (100 * 2.15) / 15,
				bubble_stroke_width: 4,	// TODO: make in dynamic
			},
		}, ScalerSize.M, props);

		const { showEgoModal } = props;

		this.outerContainer = this.initBounds<OuterContainerBounds>(
			"outerContainer",
			{
				...this.base,
				width: this.base.width * 2.5,
				border_radius: 16,
				padding: this.base.width / 10,
				background_color: "rgba(23,28,45, 0.7)",
			},
		);

		this.container = this.initBounds<ContainerBounds>("container",
			BoundsUtils.product<ContainerBounds>(
				{
					...this.base,
					dynamic: { x: () => { throw new Error("uninitialized"); }, y: () => { throw new Error("uninitialized"); } },
				},
				{
					height: 1, width: 1, x: 0, y: 0, border_width: 1,
				},
			),
			(base, factor): ContainerBounds => {
				const tempWidth = base.width + (showEgoModal ? this.outerContainer.bounds.width : 0);
				const tempFactor = showEgoModal ? factor * 1.2 : factor;
				const res: ContainerBounds = {
					height: base.height * factor,
					width: base.width * tempFactor,
					border_width: base.border_width * factor,
					dynamic: { x: () => { throw new Error("uninitialized"); }, y: () => { throw new Error("uninitialized"); } },
				};
				res.dynamic = {
					x: () => {
						if (showEgoModal && (window.innerWidth - this.base.static.anchor.getBoundingClientRect().x) < tempWidth / 1.5) {
							// this is to shift tag modal when tag is at extreme right
							return this.base.static.anchor.getBoundingClientRect().x - tempWidth;
						}
						return 0;
					},
					// Math.max(0,ScalerUtils.viewY(this.base.static.anchor) - res.height / 2)
					y: () => 0 - Math.min(this.base.static.anchor.offsetParent?.offsetTop, res.height / 2),
				};
				res.x = res.dynamic.x();
				res.y = res.dynamic.y();
				return res;
			});

		this.panel = this.initBounds<PanelBounds>("panel",
			BoundsUtils.product<PanelBounds>(
				{ ...this.base, dynamic: { rx: 25 } },
				{
					height: 1, width: 1, x: 0, y: 0, border_width: 1,
				},
			));

		this.ribbon = this.initBounds<RibbonBounds>("ribbon",
			{
				...this.base,
				// x: ribbon height / 4 i.e diameter of bubble
				x: (showEgoModal ? (((this.base.static.ribbon_r * 2.4) / 4 + (this.container.bounds.border_width / 4)) * 2) - (this.container.bounds.border_width / 2) : 10),
				y: 4,
				height: this.base.static.ribbon_r * 2.4,
				width: this.base.width,
				dynamic: {
					// radius of corner curving
					rx: this.base.static.ribbon_r, // height / 2
					// start of the range
					range_top: this.base.static.ribbon_r * 2.4, // rx / 2 + height / 2
					// total height of the range
					// start with the height and take out two halves (ie 1) full diameter of the ribbon, including
					// the stroke width
					range_height: this.base.height - this.base.static.ribbon_r * (2.8), // -range_top*2

					// how much to offset ribbon based on the selected rating
					yOffsetByRating: (rating: number) => this.ribbon.bounds?.dynamic?.range_top + this.ribbon.bounds.dynamic.range_height
						- ((rating ?? 0) * this.ribbon.bounds.dynamic.range_height) - this.ribbon.bounds.height / 2,
					// label_x: because it's center anchored + half border width of pill + border width of circle
					label_x: this.base.width / 2 + (this.base.border_width * 1.5),
					label_font_size: this.base.static.ribbon_r * 2 * 0.3,
					marker_font_size: this.base.static.marker_r * 2 * 0.35,
					stroke_width: this.base.static.ribbon_r / 2.7, // if u change this, change range_height calcs
					labelYOffsetByRating: (rating: number) => this.ribbon.bounds.dynamic.yOffsetByRating(rating)
						+ (this.ribbon.bounds.dynamic.label_font_size * 0.41),
				},
			});

		this.bubble = this.initBounds<BubbleBounds>(
			"bubble",
			{
				...this.base,
				width: (this.ribbon.bounds.height / 2) + this.base.static.bubble_stroke_width * 2,
				dynamic: {
					cx: this.ribbon.bounds.height / 4 + this.container.bounds.border_width / 4,
					// @ts-ignore
					cy: this.ribbon.bounds.y,
					r: this.ribbon.bounds.height / 4,
				},
			},
		);

		this.markers = this.initBounds<MarkerBounds>("markers",
			{
				...this.base,
				x: this.base.width / 5,
				y: this.base.static.marker_r / 2,
				dynamic: {
					cx: this.base.static.marker_r + (showEgoModal ? this.bubble.bounds.dynamic.cx : 0),
					cy: this.base.width / 4,
					cx_delta: 0,
					cy_delta: this.base.static.marker_r * 3,
					r: this.base.static.marker_r,
				},
			});
	}
}

/**
 * Renders a modal popup that allows a user to select a new rating
 *
 */
const SvgRatingSelector = (props: SvgRatingSelectorProps) => {
	const {
		onRatingHover, onSelected, onCancel, showEgoModal, egoInfoProps, size,
	} = props;
	const [styler] = useState(new SelectorStyler(""));
	const [scaler] = useState(new SelectorScaler(props));

	// eslint-disable-next-line react/destructuring-assignment
	const [rating, setRating] = useState<number | null>(props.rating < 0 ? 1 : props.rating);
	const [rating_snapped, setRatingSnapped] = useState(true);

	const rating_ref = useRef<number | null>(rating); // this is used in a callback
	rating_ref.current = rating; // need to store on every render, so the callback can pick it up

	/**
	 * this is interim rating setting
	 * @param new_rating
	 */
	const setRatingAndNotify = useCallback((new_rating: number | null) => {
		if (rating_ref.current === new_rating) return;

		setRating(new_rating);

		if (new_rating !== null && onRatingHover) {
			onRatingHover(new_rating);
		}
	}, [onRatingHover]);

	/**
	 * This is the 'normal' onMouseMove that knows how to figure out a rating based on the mouse location
	 * @param evt
	 */
	const onMouseMove = (evt: any) => {
		let new_rating = Math.min(1,
			Math.max(0,
				1 - (evt.clientY - scaler.ribbon.bounds.height / 2 - evt.target.getBoundingClientRect().y)
				// @ts-ignore
				/ scaler.ribbon.bounds.dynamic.range_height));

		// snappy behavior - snap to the closest bubble TODO make configurable
		const step = 1 / 4;
		const snap_range = step / 3;
		const stepped_nr = (Math.round((new_rating * 100) / (step * 100)) * (step * 100)) / 100;

		if (Math.abs(stepped_nr - new_rating) <= snap_range) {
			new_rating = stepped_nr;
			setRatingSnapped(true);
		} else {
			// we don't need decimals
			new_rating = Math.round(new_rating * 100) / 100;
			setRatingSnapped(false);
		}

		setRatingAndNotify(new_rating);
	};

	const [popupState, setPopupState] = useState<ModalState>(ModalState.Active);

	const closeDown = (success: boolean = false) => {
		setPopupState(ModalState.Closing);

		if (success) {
			onSelected(rating_ref.current);
		} else {
			onCancel();
		}
	};
	const onModalMouseUp = useCallback((evt: any) => {
		/**
		 * On mobile we need a timeout to access the latest ref
		 * without that it was just closing off with zero and
		 * the latest rating was not being accessed.
		 * */
		if (DeviceDetectMgr.isMobile()) {
			setTimeout(() => closeDown(true), 100);
		} else {
			closeDown(true);
		}
	}, [popupState, rating_ref]);

	// const modalContainerRef = useRef<HTMLDivElement>(null);
	const [modalFocus, setModalFocus] = useState(true);

	/**
	 * This is called by the modal, on state change
	 * @param state
	 */
	const onModalStateChange = (state: ModalState) => {
		if (popupState === state) {
			return;
		}

		// Logger.debug("ModalStateChange:" + state);
		setPopupState(state);

		switch (state) {
			case ModalState.Active:
				setModalFocus(true);
				break;
			case ModalState.Inactive:
				setModalFocus(false);
				setRatingAndNotify(null); // we don't want accidental ratings, so if not in focus, void it
				break;
			default:
				break;
		}
	};

	const onCredoTagInfoClick = (evt: any) => {
		evt.stopPropagation();
		evt.preventDefault();
		if (egoInfoProps?.onPressTagInfoBubble) {
			egoInfoProps.onPressTagInfoBubble();
			closeDown();
		}
	};

	const onCredoGraphTextClick = () => {
		if (egoInfoProps?.onPressCredoGraphText) {
			egoInfoProps.onPressCredoGraphText();
			closeDown();
		}
	};

	const renderSVG = () => (
		<svg
			className={styler.modeClass(styles.container, popupState)}
			xmlns="http://www.w3.org/2000/svg"
			width={scaler.container.bounds.width}
			height={scaler.container.bounds.height}
			onMouseMove={onMouseMove}
			onClick={onModalMouseUp}
		>
			<defs>
				<linearGradient
					id="panel-gradient"
					x2={0}
					y2={1}
					gradientUnits="objectBoundingBox"
				>
					<stop offset={0} stopColor={styler.panelByCs(1, 1)} />
					<stop offset={0.5} stopColor={styler.panelByCs(0.5, 1)} />
					<stop offset={1} stopColor={styler.panelByCs(0, 1)} />
				</linearGradient>
			</defs>
			<rect /* panel */
				x={showEgoModal ? (scaler.bubble.bounds.dynamic.cx * 2) - (scaler.container.bounds.border_width / 2) : scaler.ribbon.bounds.x}
				y={scaler.ribbon.bounds.y}
				className={styler.modeClass(styles.panel, popupState)}
				width={scaler.panel.bounds.width / 2}
				height={scaler.panel.bounds.height - scaler.container.bounds.border_width}
				rx={scaler.panel.bounds.dynamic.rx}
				fill="url(#panel-gradient)"
				strokeWidth={scaler.container.bounds.border_width}
				stroke={TAG_COLOR.black}
			/>
			{/* marker texts */}
			<g
				transform={`translate(${scaler.markers.bounds.x} ${scaler.markers.bounds.y})`}
				pointerEvents="none"
				className={styler.modeClass(styles.markers, popupState)}
			>
				<text
					x={scaler.markers.bounds.dynamic.cx + scaler.markers.bounds.dynamic.cx_delta * 0}
					y={scaler.markers.bounds.dynamic.cy + scaler.markers.bounds.dynamic.cy_delta * 0}
					fontSize={scaler.ribbon.bounds.dynamic.marker_font_size}
					color={TAG_COLOR.marker}
					fontWeight="400"
					fontFamily="PT Sans"
					alignmentBaseline="alphabetic"
					textAnchor="middle"
					pointerEvents="none"
				>
					{rating !== 1 ? "100" : ""}
				</text>
				<text
					x={scaler.markers.bounds.dynamic.cx + scaler.markers.bounds.dynamic.cx_delta * 1}
					y={scaler.markers.bounds.dynamic.cy + scaler.markers.bounds.dynamic.cy_delta * 1}
					fontSize={scaler.ribbon.bounds.dynamic.marker_font_size}
					color={TAG_COLOR.marker}
					fontWeight="400"
					fontFamily="PT Sans"
					alignmentBaseline="alphabetic"
					textAnchor="middle"
					pointerEvents="none"
				>
					{rating !== 0.75 ? "75" : ""}
				</text>
				<text
					x={scaler.markers.bounds.dynamic.cx + scaler.markers.bounds.dynamic.cx_delta * 2}
					y={scaler.markers.bounds.dynamic.cy + scaler.markers.bounds.dynamic.cy_delta * 2}
					fontSize={scaler.ribbon.bounds.dynamic.marker_font_size}
					color={TAG_COLOR.marker}
					fontWeight="400"
					fontFamily="PT Sans"
					alignmentBaseline="alphabetic"
					textAnchor="middle"
					pointerEvents="none"
				>
					{rating !== 0.5 ? "50" : ""}
				</text>
				<text
					x={scaler.markers.bounds.dynamic.cx + scaler.markers.bounds.dynamic.cx_delta * 3}
					y={scaler.markers.bounds.dynamic.cy + scaler.markers.bounds.dynamic.cy_delta * 3}
					fontSize={scaler.ribbon.bounds.dynamic.marker_font_size}
					color={TAG_COLOR.marker}
					fontWeight="400"
					fontFamily="PT Sans"
					alignmentBaseline="alphabetic"
					textAnchor="middle"
					pointerEvents="none"
				>
					{rating !== 0.25 ? "25" : ""}
				</text>
				<text
					x={scaler.markers.bounds.dynamic.cx + scaler.markers.bounds.dynamic.cx_delta * 4}
					y={scaler.markers.bounds.dynamic.cy + scaler.markers.bounds.dynamic.cy_delta * 4}
					fontSize={scaler.ribbon.bounds.dynamic.marker_font_size}
					color={TAG_COLOR.marker}
					fontWeight="400"
					fontFamily="PT Sans"
					alignmentBaseline="alphabetic"
					textAnchor="middle"
					pointerEvents="none"
				>
					{rating !== 0 ? "0" : ""}
				</text>
			</g>
			{showEgoModal
				&& (
					<g cursor="pointer" pointerEvents="auto" onClick={onCredoTagInfoClick}>
						<circle
							cx={scaler.bubble.bounds.dynamic.cx}
							cy={(scaler.bubble.bounds.dynamic.cy ?? 0) + scaler.ribbon.bounds.dynamic.yOffsetByRating(1.09)}
							r={scaler.bubble.bounds.dynamic.r}
							fill={styler.ribbonFill(1)}
							strokeWidth={scaler.container.bounds.border_width / 2}
							stroke={TAG_COLOR.black}
						/>
						<text
							x={scaler.bubble.bounds.dynamic.cx}
							y={(scaler.bubble.bounds.dynamic.cy ?? 0) + scaler.ribbon.bounds.dynamic.labelYOffsetByRating(1.095)}
							fontWeight="bold"
							fontSize={scaler.ribbon.bounds.dynamic.label_font_size}
							alignmentBaseline="alphabetic"
							textAnchor="middle"
							fill={TAG_COLOR.marker}
						>
							?
						</text>
					</g>
				)}
			{modalFocus
				&& (
					<circle
						pointerEvents="none"
						className={styler.modeClass("ribbon", popupState)}
						cx={(scaler.ribbon.bounds.width / 1.6) + (showEgoModal ? scaler.bubble.bounds.dynamic.cx : 0)} /* FIXME better solution than hardcoded values */
						cy={(scaler.ribbon.bounds.y ?? 0) + scaler.ribbon.bounds.dynamic.yOffsetByRating(rating ?? 0)}
						r={scaler.ribbon.bounds.height / 2}
						fill={styler.ribbonFill(rating)}
						strokeWidth={scaler.ribbon.bounds.dynamic.stroke_width}
						stroke={TAG_COLOR.black}
					/>
				)}
			{modalFocus && (
				<text
					className={styler.modeClass("label", popupState)}
					x={scaler.ribbon.bounds.dynamic.label_x + (showEgoModal ? scaler.bubble.bounds.dynamic.cx : 0)}
					y={(scaler.ribbon.bounds.y ?? 0) + scaler.ribbon.bounds.dynamic.labelYOffsetByRating(rating ?? 0)}
					width={scaler.ribbon.bounds.width}
					height={scaler.ribbon.bounds.height}
					fontWeight={styler.ribbonLabelFontWeight(rating ?? 0, rating_snapped)}
					fontSize={scaler.ribbon.bounds.dynamic.label_font_size}
					/**
				* alphabetic Matches the box's alphabetic baseline to that of its parent.
				* on Safari baseline is not working to align the text vertically middle
				* middle | central was also not working
				* */
					alignmentBaseline="alphabetic"
					textAnchor="middle"
					pointerEvents="none"
					color={TAG_COLOR.marker}
				>
					{`${Math.round((rating ?? 0) * 100)}%`}
				</text>
			)}

		</svg>
	);

	const renderSVGInEgo = () => (
		<div className="flex flex-row">
			<div className="flex-1">
				{renderSVG()}
			</div>
			<div className="flex-1">
				{egoInfoProps?.switchComp
					&& (
						<div className="flex justify-end mr-2">
							{egoInfoProps.switchComp}
						</div>
					)}
				<div className="mt-1.5">
					<p className={styler.getClassesAsPerSize("ego-info-text", size)}>
						{"You are \n"}
						<span className="font-bold">
							{"creeding "}
						</span>
						{"this post in "}
						<span>
							{"credo "}
						</span>
						mode.
					</p>
					<p className={classNames(
						styler.getClassesAsPerSize("ego-info-text", size),
						"mt-2.5",
					)}
					>
						Your creeds
						<span className="font-bold">
							{" aren't visible "}
						</span>
						{"to anyone, they build your own "}
						<span
							className={styler.getClassesAsPerSize("ego-info-text-active", size)}
							onClick={onCredoGraphTextClick}
							aria-hidden
						>
							credograph
						</span>
						.
					</p>
				</div>
			</div>
		</div>
	);

	const indicatorContainerStyle = React.useMemo(() => {
		let defaultStyle: object = {
			top: scaler.container.bounds.y ?? 0,
			left: scaler.container.bounds.x ?? 0,
			width: scaler.container.bounds.width
				? `${scaler.container.bounds.width}px` : "100%",
		};
		if (showEgoModal) {
			defaultStyle = {
				...defaultStyle,
				padding: scaler.outerContainer.bounds.padding,
				backgroundColor: scaler.outerContainer.bounds.background_color,
				borderRadius: scaler.outerContainer.bounds.border_radius,
				width: scaler.outerContainer.bounds.width,
			};
		}
		return defaultStyle;
	}, [showEgoModal]);

	return renderSVG();

	// return (
	// 	<ModalPopup
	// 		className={styler.modeClass(styles["tag-rating-selector"], popupState)}
	// 		style={indicatorContainerStyle}
	// 		onModalStateChange={onModalStateChange}
	// 		onMouseUp={onModalMouseUp}
	// 		onClose={closeDown}
	// 	>
	// 		{showEgoModal ? renderSVGInEgo() : renderSVG()}
	// 	</ModalPopup>
	// );
};

export default SvgRatingSelector;
