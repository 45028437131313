import React from "react";

interface HeroProps {
	/**
	 * Main Title which will be displayed
	 * */
	title: string;
	/**
	 * Set true if page contains table with this Hero
	 * for different CSS Styling
	 * @default false
	 * */
	pageHasTable?: boolean;
}

export default function Hero(props: HeroProps) {
	const { title, pageHasTable } = props;

	if (pageHasTable) {
		return (
			<div className="w-100 bg-background-dark flex h-48 py-4 px-5">
				<h4 className="hero-heading text-[2.25rem]">
					{title}
				</h4>
			</div>
		);
	}

	return (
		<div className="w-100 bg-background-dark flex h-96 py-14 px-5">
			<h3 className="hero-heading">
				{title}
			</h3>
		</div>
	);
}

Hero.defaultProps = {
	pageHasTable: false,
};
