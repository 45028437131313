import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { isLoggedIn } from "../utils";
import { AUTH_URL, DASHBOARD_PAGE } from "./constants";

function Auth() {
	return isLoggedIn() ? (
		<Navigate to={AUTH_URL + DASHBOARD_PAGE} />
	) : (
		<Outlet />
	);
}

export default Auth;
