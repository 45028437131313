let msgIndex = 0;

export default class LogMgr {
	static loggedData = [{ key: msgIndex + 1, msg: "sample text log" }];

	static log = (obj: any) => {
		if (msgIndex > 1000) {
			LogMgr.loggedData = [];
			msgIndex = 0;
		}
		const newObj = {
			...obj,
			key: msgIndex + 1,
		};
		LogMgr.loggedData.unshift(newObj);
	};

	static getData = () => LogMgr.loggedData;
}
