import React, { useCallback, useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { ExploreCommunitiesCard } from "./ExploreCommunitiesCard";
import { ArrowType, ExploreCommCardMessages } from "../types";
import { classNames, SvgIcon } from "../../common";
import { LeftArrowIcon, RightArrowIcon } from "../../assets/icons";

interface ExploreItem {
	name: string;
	descr: string;
	struname: string;
	streamId: string;
	profilePicRelUrl: string;
	ac_alwpos: string;
	ac_folcom: string;
	ac_joiaut: string;
	ac_joicom: string;
	rank: number,
	nb_posts: number,
	rank_type: string;
	isFollowed?: boolean;
	joinModes: string;
}

interface StreamParams {
	streamId: string,
	struname: string,
	meta: {
		title: string;
	},
}

interface OwnProps {
	/**
	 * the width of the container needed to initialise
	 * this container
	 * */
	width: number;
	/**
	 * Number of posts in the response. Based on which
	 * we can render the card respectively.
	 * */
	items: ExploreItem[];
	onRemoveCard: (stream: ExploreItem) => void;
	handleClickStream: (params: StreamParams) => void;
	messages: ExploreCommCardMessages,
	onFollowClick: (streamId: string, isFollowed: boolean) => void
}

// eslint-disable-next-line import/prefer-default-export
const ExploreCommunitiesComponent: React.FC<OwnProps> = (props:OwnProps) => {
	const {
		items,
		width = 600,
		onRemoveCard,
		handleClickStream,
		messages,
		onFollowClick,
	} = props;

	const [cards, setCards] = useState<any>(items || []);

	useEffect(() => {
		setCards(items);
	}, [items]);

	const removeCard = (card: ExploreItem) => {
		if (onRemoveCard) {
			onRemoveCard(card);
		}
	};

	const updateCardItems = useCallback((stream: ExploreItem, isFollowed: boolean) => {
		const updatedCards = cards.map((s: ExploreItem) => (s.streamId === stream.streamId ? { ...s, isFollowed } : s));
		onFollowClick(stream.streamId, isFollowed);
		setCards(updatedCards);
	}, [cards]);

	const renderArrow = useCallback((direction: ArrowType) => (onClickHandler: () => void, shouldBeEnabled: boolean) => {
		if (!shouldBeEnabled) {
			return null;
		}
		return (
			<button
				className={classNames(
					"absolute top-0 z-10 h-full p-2 outline-none",
					direction === ArrowType.Previous ? "left-1" : "right-1",
				)}
				type="button"
				onClick={onClickHandler}
			>
				{direction === ArrowType.Previous
					? (
						<SvgIcon
							icon={LeftArrowIcon}
						/>
					) : (
						<SvgIcon
							icon={RightArrowIcon}
						/>
					)}
			</button>
		);
	}, []);

	const renderCard = useCallback((item: ExploreItem) => (
		<ExploreCommunitiesCard
			key={item.streamId}
			card={item}
			handleClickStream={handleClickStream}
			messages={messages}
			removeCard={() => removeCard(item)}
			updateCard={(isFollowed: boolean) => updateCardItems(item, isFollowed)}
		/>
	), [cards]);

	return (
		<div className="flex w-full py-4 justify-center bg-background-tertiary">
			<Carousel
				width={width}
				autoPlay={false}
				showStatus={false}
				showIndicators={false}
				showThumbs={false}
				preventMovementUntilSwipeScrollTolerance
				infiniteLoop
				renderArrowNext={renderArrow(ArrowType.Next)}
				renderArrowPrev={renderArrow(ArrowType.Previous)}
			>
				{cards.map((item: ExploreItem) => renderCard(item))}
			</Carousel>
		</div>
	);
};

const compareFn = (nextProps: OwnProps, prevProps: OwnProps) => JSON.stringify(nextProps.items) === JSON.stringify(prevProps.items)
	&& nextProps.onFollowClick === prevProps.onFollowClick
	&& nextProps.width === prevProps.width
	&& JSON.stringify(nextProps.messages) === JSON.stringify(prevProps.messages)
	&& nextProps.onRemoveCard === prevProps.onRemoveCard
	&& nextProps.handleClickStream === prevProps.handleClickStream;

// eslint-disable-next-line import/prefer-default-export
export const ExploreCommunities = React.memo(
	ExploreCommunitiesComponent,
	compareFn,
);
