import React, { SVGAttributes } from "react";

const Followers = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--gray-dark)" } = props;
	return (
		<svg
			width="22px"
			height="18px"
			viewBox="0 0 22 18"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<title>followers_icon</title>
			<g id="Profile---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-icons" transform="translate(-373.000000, -354.000000)" stroke={stroke}>
					<g id="followers_icon" transform="translate(374.000000, 355.000000)">
						<path
							d="M10,0.470588235 C8.56571429,0.470588235 7.39904762,1.62352941 7.39904762,3.04094118 C7.39904762,4.45835294 8.56571429,5.61129412 10,5.61129412 C11.4342857,5.61129412 12.6009524,4.45835294 12.6009524,3.04094118 C12.6009524,1.62352941 11.4342857,0.470588235 10,0.470588235 Z"
							id="Stroke-1"
						/>
						<path
							d="M15.1367619,13.1834353 C14.9215238,10.3664941 13.2100952,7.83849412 12.512,7.40649412 L12.3415238,7.30014118 L12.1691429,7.41872941 L9.99961905,9.12508235 L7.83104762,7.41872941 L7.65866667,7.30014118 L7.48819048,7.40649412 C6.78914286,7.83849412 5.07771429,10.3664941 4.86342857,13.1834353 C4.86342857,13.1834353 6.29485714,15.5627294 9.99961905,15.5627294 C13.7053333,15.5627294 15.1367619,13.1834353 15.1367619,13.1834353 Z"
							id="Stroke-3"
						/>
						<path
							d="M3.78771429,6.29543529 C2.83819048,6.29543529 2.06580952,5.53214118 2.06580952,4.59378824 C2.06580952,3.65543529 2.83819048,2.89214118 3.78771429,2.89214118 C4.7372381,2.89214118 5.50961905,3.65543529 5.50961905,4.59378824 C5.50961905,5.53214118 4.7372381,6.29543529 3.78771429,6.29543529 Z"
							id="Stroke-5"
						/>
						<path
							d="M6.13514286,9.14654118 C5.84466667,8.46512941 5.558,8.01618824 5.38942857,7.91171765 L3.78752381,9.17665882 L2.18561905,7.91171765 C1.74085714,8.18748235 0.476095238,10.8632471 0.476095238,13.8750118 C0.476095238,13.8750118 3.31514286,15.7441882 5.70752381,14.2543059"
							id="Stroke-7"
						/>
						<path
							d="M16.2122857,6.29543529 C17.1618095,6.29543529 17.9341905,5.53214118 17.9341905,4.59378824 C17.9341905,3.65543529 17.1618095,2.89214118 16.2122857,2.89214118 C15.2627619,2.89214118 14.490381,3.65543529 14.490381,4.59378824 C14.490381,5.53214118 15.2627619,6.29543529 16.2122857,6.29543529 Z"
							id="Stroke-9"
						/>
						<path
							d="M13.8648571,9.14654118 C14.1553333,8.46512941 14.442,8.01618824 14.6105714,7.91171765 L16.2124762,9.17665882 L17.814381,7.91171765 C18.2591429,8.18748235 19.5239048,10.8632471 19.5239048,13.8750118 C19.5239048,13.8750118 16.6848571,15.7441882 14.2924762,14.2543059"
							id="Stroke-11"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default Followers;
