import React, {
	useCallback, useEffect,
	useLayoutEffect, useMemo, useRef, useState,
} from "react";

export const calculateBoundingBoxes = (children: React.DetailedHTMLProps<any, any>) => {
	const boundingBoxes: any = {};

	React.Children.forEach(children, (child) => {
		const domNode = child.ref.current;
		boundingBoxes[child.key] = domNode?.getBoundingClientRect();
	});

	return boundingBoxes;
};

const ANIMATION_TIME = 1000;

interface ReOrderAnimationWrapperProps {
	/**
	 * Data which needs to be rendered and animated when the
	 * position of the item is changed. Preferring an array of
	 * string. If array of object make sure to have id key in
	 * it for unique mapping
	 * */
	data: any[],
	/**
	 * gap between two items optional.
	 *
	 * @default 10
	 * */
	gap?: number,
	/**
	 * Renders a single item in the list which is in form of
	 * function which should return a component. That
	 * component should have custom style set to the div
	 * which will come from the param named as computedStyle.
	 *
	 * @param item {any} can be an object consisting id key for
	 * unique mapping or can be just string
	 * @param computedStyle {Object} style of the item in the
	 * list which will make the animation happen. This object
	 * must be used in style prop of the dom node element.
	 * */
	renderItem: (item: any, computedStyle: Object) => any;
}

const itemId = (item: any) => (typeof item === "string" ? item : item.id);

/**
 * 1. Calculate the current dimensions of a single item
 * 2. Save them in an object.
 * 3. Get new positions
 * 4. Calculate new dimensions one by one.
 * 5. Once calculation is done. Animate.
 * */

/**
 * Renders an animation of list item slides up when the
 * position of the items change in the list.
 *
 * Pass the list of item in the data prop and the component
 * which needs to render that item in renderItem.
 * */
// eslint-disable-next-line import/prefer-default-export
export const ReOrderAnimationWrapper = (props: ReOrderAnimationWrapperProps) => {
	const {
		data,
		gap = 10,
		renderItem,
	} = props;
	const [items, setItem] = useState<any[]>(data);
	const [buffer, setBuffer] = useState<any[]>([]);
	const [boundingBox, setBoundingBox] = useState<{ [key: number]: DOMRect }>({});
	const timerRef = useRef<any>(null);

	const newBoundingBox = useMemo(() => {
		const newYCoords: number[] = [];
		if (buffer.length > 0) {
			const bufferTemp = buffer;
			bufferTemp.reduce((
				previousValue,
				currentValue,
			) => {
				newYCoords.push(previousValue);
				// TODO: Measure the height of the newly added component to have a smooth addition
				return previousValue + (boundingBox[itemId(currentValue)]?.height ?? 0) + gap;
			}, boundingBox[itemId(items[0])].y ?? 0);
		}
		const newBoundingBox: any = {};
		buffer.forEach((item, index) => {
			newBoundingBox[itemId(item)] = newYCoords[index];
		});
		return newBoundingBox;
	}, [buffer, boundingBox]);

	const computeTranslation = useCallback((item: any) => {
		const currentBox = boundingBox[itemId(item)].y;
		const prevBox = newBoundingBox[itemId(item)];
		const translateY = prevBox - currentBox;

		return {
			transform: `translateY(${translateY}px)`,
			transition: `all ${ANIMATION_TIME}ms ease`,
		};
	}, [boundingBox, items, buffer, newBoundingBox]);

	const renderContent = useMemo(() => items.map((item) => {
		const computedStyle = buffer.length ? computeTranslation(item) : {};
		return renderItem(item, computedStyle);
	}), [buffer]);

	useLayoutEffect(() => {
		if (buffer.length > 0) {
			timerRef.current = setTimeout(() => {
				setItem([...buffer]);
				setBuffer([]);
			}, ANIMATION_TIME);
		}
	}, [buffer]);

	useLayoutEffect(() => {
		const newBoundingBox = calculateBoundingBoxes(renderContent);
		setBoundingBox(newBoundingBox);
	}, [items]);

	useEffect(() => {
		if (timerRef.current) {
			clearTimeout(timerRef.current);
		}
		setBuffer(data);
	}, [data]);

	return (
		<React.Fragment>
			{renderContent}
		</React.Fragment>
	);
};
