import React, { SVGAttributes } from "react";

// eslint-disable-next-line import/prefer-default-export
const StreamAccessControl = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--gray-dark)",
	} = props;
	return (
		<svg
			width="19px"
			height="19px"
			viewBox="0 0 19 19"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<g id="Streams---CT---190723" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-icons" transform="translate(-326.000000, -580.000000)" stroke={stroke}>
					<g id="streamAccessControl" transform="translate(326.000000, 580.000000)">
						<path d="M3.5001,12.5005 C5.1541,12.5005 6.5001,11.1545 6.5001,9.5005 C6.5001,7.8455 5.1541,6.4995 3.5001,6.4995 C1.8461,6.4995 0.5001,7.8455 0.5001,9.5005 C0.5001,11.1545 1.8461,12.5005 3.5001,12.5005 Z" id="Stroke-1" />
						<path d="M3.1359,3.1362 C6.6509,-0.3788 12.3489,-0.3788 15.8639,3.1362 C19.3789,6.6512 19.3789,12.3492 15.8639,15.8642 C12.3489,19.3792 6.6509,19.3792 3.1359,15.8642" id="Stroke-3" />
						<polyline id="Stroke-5" points="6.5001 9.5 13.0001 9.5 13.0001 6.775" />
						<line x1="10.8175" y1="9.623" x2="10.8175" y2="6.775" id="Stroke-7" />
					</g>
				</g>
			</g>
		</svg>
	);
};

export default StreamAccessControl;
