import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { classNames } from "../common";

interface WalletTransactionsSkeletonProps {
	wrapperClasses?: string;
}

// eslint-disable-next-line import/prefer-default-export
const WalletTransactionsSkeleton: React.FC<WalletTransactionsSkeletonProps> = (props: WalletTransactionsSkeletonProps) => {
	const {
		wrapperClasses,
	} = props;

	return (
		<div
			className={classNames(
				"w-full z-0",
				wrapperClasses,
			)}
		>
			<SkeletonTheme
				baseColor="var(--background-dark-skeleton)"
				highlightColor="var(--hightlight-dark-skeleton)"
			>
				{[1, 2].map(() => (
					<div className="flex flex-col">
						<Skeleton containerClassName="w-full" height={10} />
						<Skeleton containerClassName="w-1/4" height={10} />
					</div>
				))}
			</SkeletonTheme>
		</div>
	);
};

WalletTransactionsSkeleton.defaultProps = {
	wrapperClasses: "",
};

export default WalletTransactionsSkeleton;
