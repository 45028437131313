export const LOGIN_URL = "api/login";
export const LOGOUT_URL = "api/logout";
export const DASHBOARD_STATS_URL = "api/dashboard";
export const DASHBOARD_REALTIME_DATA_URL = "api/realtime/dashboard";
export const RESTORE_ITEM_URL = "api/restoreItem";
export const SUSPEND_USER_URL = "api/suspend";
export const GET_SUSPENDED_USERS_URL = "/api/suspendedUsers";
export const USER_DETAILS_URL = "";
export const SEARCH = "api/search";
export const UPDATE_USER_DETAILS_URL = "/api/updateUserDetails";
