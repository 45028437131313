import { strings } from "../../i18n/config";
import {
	Modes, NoOfReportedOptions, PostMadeBy, YesNoOptions,
} from "../../utils/types";
import { getDateTimestampOfPreviousMonths } from "../../utils/utils";

export const ModeFilterList = [
	{
		title: strings("PostSearchPage.credo"),
		value: Modes.CREDO,
	},
	{
		title: strings("PostSearchPage.ego"),
		value: Modes.EGO,
	},
];

export const ReportedReasonOptions = [
	{
		title: strings("PostSearchPage.spam"),
		value: strings("PostSearchPage.spam"),
	},
	{
		title: strings("PostSearchPage.sexual"),
		value: strings("PostSearchPage.sexual"),
	},
	{
		title: strings("PostSearchPage.offensive"),
		value: strings("PostSearchPage.offensive"),
	},
	{
		title: strings("PostSearchPage.voilent"),
		value: strings("PostSearchPage.voilent"),
	},
	{
		title: strings("PostSearchPage.rci"),
		value: strings("PostSearchPage.rci"),
	},
	{
		title: strings("PostSearchPage.other"),
		value: strings("PostSearchPage.other"),
	},
];

export const IsSharedFilterList = [
	{
		title: strings("PostSearchPage.yes"),
		value: YesNoOptions.YES,
		searchQueries: [
			{
				operator: "OR",
				propName: "is_shared",
				propVal: YesNoOptions.YES,
				condition: "=",
			},
		],
	},
	{
		title: strings("PostSearchPage.no"),
		value: null,
		searchQueries: [
			{
				operator: "OR",
				propName: "is_shared",
				propVal: null,
				condition: " IS NULL ",
			},
		],
	},
];

export const PostMadeByFilterList = [
	{
		title: strings("PostSearchPage.user"),
		value: PostMadeBy.USER,
	},
	{
		title: strings("PostSearchPage.bot"),
		value: PostMadeBy.BOT,
	},
	{
		title: strings("PostSearchPage.custom_bot"),
		value: PostMadeBy.CUSTOM_BOT,
	},
];

export const PostReportedOptions = [
	{
		title: strings("CommunitySearchPage.not_reported"),
		value: NoOfReportedOptions.ZERO,
		searchQueries: [
			{
				operator: "OR",
				propName: "nb_rep",
				propVal: null,
				condition: " IS NULL ",
			},
		],
	},
	{
		title: strings("CommunitySearchPage.one_to_ten"),
		value: NoOfReportedOptions.ONE_TO_TEN,
		searchQueries: [
			{
				operator: "OR",
				propName: "nb_rep",
				propVal: "1",
				condition: ">=",
			},
			{
				operator: "OR",
				propName: "nb_rep",
				propVal: "10",
				condition: "<=",
			},
		],
	},
	{
		title: strings("CommunitySearchPage.greater_than_ten"),
		value: NoOfReportedOptions.GREATER_THAN_TEN,
		searchQueries: [
			{
				operator: "OR",
				propName: "nb_rep",
				propVal: "10",
				condition: ">=",
			},
		],
	},
];

export const PostTableHeaders = [
	{
		title: strings("PostSearchPage.postUuid"),
		value: "postUuid",
		hideColumn: true,
		isFixedColumn: true,
		isClickable: true,
		isDefault: true,
	},
	{
		title: strings("PostSearchPage.postText"),
		value: "postText",
		isDefault: true,
	},
	{
		title: strings("PostSearchPage.postTitle"),
		value: "postTitle",
		isDefault: true,
	},
	{
		title: strings("PostSearchPage.postType"),
		value: "postType",
	},
	{
		title: strings("PostSearchPage.mode"),
		value: "mode",
		isDefault: true,
	},
	{
		title: strings("PostSearchPage.prof_id"),
		value: "prof_id",
		isDefault: true,
		isClickable: true,
	},
	{
		title: strings("PostSearchPage.username"),
		value: "username",
		isDefault: true,
	},
	{
		title: strings("PostSearchPage.firstName"),
		value: "firstName",
		isDefault: true,
	},
	{
		title: strings("PostSearchPage.lastName"),
		value: "lastName",
		isDefault: true,
	},
	{
		title: strings("PostSearchPage.profilePicRelUrl"),
		value: "profilePicRelUrl",
	},
	{
		title: strings("PostSearchPage.creat_ts"),
		value: "creat_ts",
	},
	{
		title: strings("PostSearchPage.created_by"),
		value: "created_by",
	},
	{
		title: strings("PostSearchPage.edit_ts"),
		value: "edit_ts",
	},
	{
		title: strings("PostSearchPage.nb_rep"),
		value: "nb_rep",
		showCustomColumnComponent: true,
	},
	{
		title: strings("PostSearchPage.reported_ts"),
		value: "reported_ts",
	},
	{
		title: strings("PostSearchPage.mediaObj_Link_Title"),
		value: "mediaObj_Link_Title",
	},
	{
		title: strings("PostSearchPage.mediaObj_Link_ImgUrl"),
		value: "mediaObj_Link_ImgUrl",
		isClickable: true,
	},
	{
		title: strings("PostSearchPage.mediaObj_Link_Text"),
		value: "mediaObj_Link_Text",
	},
	{
		title: strings("PostSearchPage.mediaObj_Link_Url"),
		value: "mediaObj_Link_Url",
		isClickable: true,
	},
	{
		title: strings("PostSearchPage.mediaObj_hostname"),
		value: "mediaObj_hostname",
	},
	{
		title: strings("PostSearchPage.mediaObj_channel_name"),
		value: "mediaObj_channel_name",
	},
	{
		title: strings("PostSearchPage.img_bucketRelativeRefPath0"),
		value: "img_bucketRelativeRefPath0",
		isClickable: true,
	},
	{
		title: strings("PostSearchPage.img_bucketRelativeRefPath1"),
		value: "img_bucketRelativeRefPath1",
		isClickable: true,
	},
	{
		title: strings("PostSearchPage.img_bucketRelativeRefPath2"),
		value: "img_bucketRelativeRefPath2",
		isClickable: true,
	},
	{
		title: strings("PostSearchPage.img_bucketRelativeRefPath3"),
		value: "img_bucketRelativeRefPath3",
		isClickable: true,
	},
	{
		title: strings("PostSearchPage.video_bucketRelativeRefPath"),
		value: "video_bucketRelativeRefPath",
		isClickable: true,
	},
	{
		title: strings("PostSearchPage.article_date"),
		value: "article_date",
	},
	{
		title: strings("PostSearchPage.shared_postUuid"),
		value: "shared_postUuid",
		isDefault: true,
		isClickable: true,
	},
	{
		title: strings("PostSearchPage.shared_creat_ts"),
		value: "shared_creat_ts",
		isDefault: true,
	},
	{
		title: strings("PostSearchPage.shared_modif_ts"),
		value: "shared_modif_ts",
	},
	{
		title: strings("PostSearchPage.shared_edit_ts"),
		value: "shared_edit_ts",
	},
	{
		title: strings("PostSearchPage.shared_postText"),
		value: "shared_postText",
		isDefault: true,
	},
	{
		title: strings("PostSearchPage.shared_postTitle"),
		value: "shared_postTitle",
		isDefault: true,
	},
	{
		title: strings("PostSearchPage.shared_mode"),
		value: "shared_mode",
	},
	{
		title: strings("PostSearchPage.shared_prof_id"),
		value: "shared_prof_id",
		isClickable: true,
		isDefault: true,
	},
	{
		title: strings("PostSearchPage.shared_username"),
		value: "shared_username",
		isDefault: true,
	},
	{
		title: strings("PostSearchPage.shared_firstName"),
		value: "shared_firstName",
		isDefault: true,
	},
	{
		title: strings("PostSearchPage.shared_lastName"),
		value: "shared_lastName",
		isDefault: true,
	},
	{
		title: strings("PostSearchPage.sharedContentRating"),
		value: "sharedContentRating",
	},
	{
		title: strings("PostSearchPage.shared_profilePicRelUrl"),
		value: "shared_profilePicRelUrl",
	},
	{
		title: strings("PostSearchPage.shared_created_by"),
		value: "shared_created_by",
	},
	{
		title: strings("PostSearchPage.shared_post_streamId"),
		value: "shared_post_streamId",
		isClickable: true,
	},
	{
		title: strings("PostSearchPage.is_shared"),
		value: "is_shared",
	},
	{
		title: strings("PostSearchPage.shared_cnt"),
		value: "shared_cnt",
		isDefault: true,
	},
	{
		title: strings("PostSearchPage.purpose"),
		value: "purpose",
	},
	// {
	// 	title: strings("PostSearchPage.user_id"),
	// 	value: "user_id",
	// },
	// {
	// 	title: strings("PostSearchPage.name"),
	// 	value: "name",
	// },
];

export const SelectedReportedPostTableHeaders = [
	{
		title: strings("PostSearchPage.postUuid"),
		value: "postUuid",
		hideColumn: true,
		isFixedColumn: true,
		isClickable: true,
	},
	{
		title: strings("PostSearchPage.postText"),
		value: "postText",
	},
	{
		title: strings("PostSearchPage.postTitle"),
		value: "postTitle",
	},
	{
		title: strings("PostSearchPage.username"),
		value: "username",
	},
	{
		title: strings("PostSearchPage.firstName"),
		value: "firstName",
	},
	{
		title: strings("PostSearchPage.lastName"),
		value: "lastName",
	},
	{
		title: strings("PostSearchPage.nb_rep"),
		value: "nb_rep",
		showCustomColumnComponent: true,
	},
	{
		title: strings("PostSearchPage.reported_ts"),
		value: "reported_ts",
	},
];

export const SearchColumns = [
	{
		title: strings("PostSearchPage.postUuid"),
		value: "postUuid",
		type: "number",
	},
	{
		title: strings("PostSearchPage.postText"),
		value: "postText",
		searchQueries: [
			{
				operator: "OR",
				propName: "postText",
				propVal: null,
				condition: "=~",
			},
		],
	},
	{
		title: strings("PostSearchPage.postTitle"),
		value: "postTitle",
		searchQueries: [
			{
				operator: "OR",
				propName: "postTitle",
				propVal: null,
				condition: "=~",
			},
		],
	},
	{
		title: strings("PostSearchPage.firstName"),
		value: "lc_firstName",
	},
	{
		title: strings("PostSearchPage.lastName"),
		value: "lc_lastName",
	},
	{
		title: strings("PostSearchPage.username"),
		value: "lc_username",
	},
];

export const Timeframes = [
	{
		title: strings("PostReportedPage.one_month"),
		value: "1",
		searchQueries: [
			{
				operator: "OR",
				propName: "reported_ts",
				propVal: `${getDateTimestampOfPreviousMonths(1)}`,
				condition: ">=",
			},
		],
	},
	{
		title: strings("PostReportedPage.three_months"),
		value: "3",
		searchQueries: [
			{
				operator: "OR",
				propName: "reported_ts",
				propVal: `${getDateTimestampOfPreviousMonths(3)}`,
				condition: ">=",
			},
		],
	},
	{
		title: strings("PostReportedPage.six_months"),
		value: "6",
		searchQueries: [
			{
				operator: "OR",
				propName: "reported_ts",
				propVal: `${getDateTimestampOfPreviousMonths(6)}`,
				condition: ">=",
			},
		],
	},
	{
		title: strings("PostReportedPage.one_year"),
		value: "12",
		searchQueries: [
			{
				operator: "OR",
				propName: "reported_ts",
				propVal: `${getDateTimestampOfPreviousMonths(12)}`,
				condition: ">=",
			},
		],
	},
];

export const PostReportedTableHeaders = [
	{
		title: strings("UserSearchPage.user_id"),
		value: "reported_user_id",
		isClickable: true,
	},
	{
		title: strings("UserSearchPage.firstName"),
		value: "firstName",
	},
	{
		title: strings("UserSearchPage.lastName"),
		value: "lastName",
	},
	{
		title: strings("UserSearchPage.username"),
		value: "username",
	},
	{
		title: strings("PostReportedList.reported_mode"),
		value: "mode",
	},
	{
		title: strings("PostReportedList.reportReason"),
		value: "reportReason",
	},
	{
		title: strings("PostReportedList.reportMessage"),
		value: "reportMessage",
	},
	{
		title: strings("PostReportedList.reported_timestamp"),
		value: "reported_timestamp",
	},
];

export const DisabledPostTableHeaders = [
	{
		title: strings("PostSearchPage.postUuid"),
		value: "postUuid",
		isFixedColumn: true,
		isClickable: true,
		hideColumn: true,
	},
	{
		title: strings("PostSearchPage.postTitle"),
		value: "postTitle",
	},
	{
		title: strings("PostSearchPage.postText"),
		value: "postText",
	},
	{
		title: strings("PostSearchPage.username"),
		value: "username",
	},
	{
		title: strings("PostSearchPage.firstName"),
		value: "firstName",
	},
	{
		title: strings("PostSearchPage.lastName"),
		value: "lastName",
	},
	{
		title: strings("PostSearchPage.prof_id"),
		value: "prof_id",
		isFixedColumn: true,
		isClickable: true,
		hideColumn: true,
	},
	{
		title: strings("PostSearchPage.nb_rep"),
		value: "nb_rep",
		isClickable: true,
	},
	{
		title: strings("PostSearchPage.reported_ts"),
		value: "reported_ts",
	},
	// {
	// 	title: strings("PostSearchPage.purpose"),
	// 	value: "purpose",
	// },
	{
		title: strings("PostSearchPage.created_by"),
		value: "created_by",
	},
];
