import React, { SVGAttributes } from "react";

const Request = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { fill = "var(--gray-dark)" } = props;
	return (
		<svg
			width="20px"
			height="20px"
			viewBox="0 0 20 20"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<title>Requests</title>
			<g id="Icon---Person---Add---Inactive" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="person_add_FILL1_wght400_GRAD0_opsz48-Copy" transform="translate(2, 5)" fill={fill} fillRule="nonzero">
					<path d="M12.5454545,6.74879227 L12.5454545,4.44605475 L10.1818182,4.44605475 L10.1818182,3.38325282 L12.5454545,3.38325282 L12.5454545,1.0805153 L13.6363636,1.0805153 L13.6363636,3.38325282 L16,3.38325282 L16,4.44605475 L13.6363636,4.44605475 L13.6363636,6.74879227 L12.5454545,6.74879227 Z M5.81818182,5.31400966 C5.01818182,5.31400966 4.36363636,5.06602254 3.85454545,4.57004831 C3.34545455,4.07407407 3.09090909,3.43639291 3.09090909,2.65700483 C3.09090909,1.87761675 3.34545455,1.23993559 3.85454545,0.743961353 C4.36363636,0.247987118 5.01818182,0 5.81818182,0 C6.61818182,0 7.27272727,0.247987118 7.78181818,0.743961353 C8.29090909,1.23993559 8.54545455,1.87761675 8.54545455,2.65700483 C8.54545455,3.43639291 8.29090909,4.07407407 7.78181818,4.57004831 C7.27272727,5.06602254 6.61818182,5.31400966 5.81818182,5.31400966 Z M0,11 L0,9.33494364 C0,8.92163178 0.106060606,8.54669887 0.318181818,8.21014493 C0.53030303,7.87359098 0.836363636,7.62265164 1.23636364,7.45732689 C2.14545455,7.06763285 2.95454545,6.79307568 3.66363636,6.63365539 C4.37272727,6.4742351 5.09090909,6.39452496 5.81818182,6.39452496 C6.54545455,6.39452496 7.26060606,6.4742351 7.96363636,6.63365539 C8.66666667,6.79307568 9.47272727,7.06763285 10.3818182,7.45732689 C10.7818182,7.63446055 11.0909091,7.88835212 11.3090909,8.21900161 C11.5272727,8.5496511 11.6363636,8.92163178 11.6363636,9.33494364 L11.6363636,11 L0,11 Z" id="Shape" />
				</g>
			</g>
		</svg>
	);
};

export default Request;
