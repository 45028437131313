import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { classNames } from "../common";

interface BoostBoardInfoSkeletonProps {
	wrapperClasses?: string;
}

// eslint-disable-next-line import/prefer-default-export
const BoostBoardInfoSkeleton: React.FC<BoostBoardInfoSkeletonProps> = (props: BoostBoardInfoSkeletonProps) => {
	const {
		wrapperClasses,
	} = props;

	return (
		<div
			className={classNames(
				"w-full z-0",
				wrapperClasses,
			)}
		>
			<SkeletonTheme
				baseColor="var(--accent-2)"
				highlightColor="var(--accent)"
			>
				<div className="flex flex-col px-1 ">
					<Skeleton containerClassName="w-full h-2.5" height={10} inline />
					<div className="w-full flex justify-around mt-1">
						<Skeleton containerClassName="w-1/6 h-2.5" height={10} />
						<Skeleton containerClassName="w-1/6 h-2.5" height={10} />
						<Skeleton containerClassName="w-1/6 h-2.5" height={10} />
					</div>
				</div>
			</SkeletonTheme>
		</div>
	);
};

BoostBoardInfoSkeleton.defaultProps = {
	wrapperClasses: "",
};

export default BoostBoardInfoSkeleton;
