/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-classes-per-file */
import * as React from "react";
import { ReactNode } from "react";
import debounce from "lodash/debounce";
import styles from "./indicator-html.module.css";
import {
	CIProps, CIStyler, ScalerSize, TABLET_WIDTH, TAG_COLOR,
} from "../common";
import { Spinner } from "../spinner";
import { ModalPopup, ModalState } from "./modal-popup";
import { CreedSelectorModal } from "./creed-selector/creed-selector-modal";
import { CreedSelectorSheet } from "./creed-selector/creed-selector-sheet";
import { BoostTrayProps } from "../boost";
import { TagMessages } from "./credo-tag";

class TagStyler extends CIStyler<TagIndicatorProps> {
	constructor(base: string) {
		super(`${styles.tag} ${base}`);
	}

	// override how size class is defined based on our styles module
	// eslint-disable-next-line class-methods-use-this
	sizeClass = (size: ScalerSize) => styles[size.toString()];

	isDataNotAvailable = (data: TagIndicatorProps) => {
		const {
			rating, s_credo_score, g_credo_score, engagement,
		} = data;
		return (rating ?? -1) < 0 && s_credo_score < 0 && g_credo_score < 0 && engagement < 0;
	};
}
class OutlineStyler extends TagStyler {
	constructor() {
		super(styles.outline);
	}

	classes(data: TagIndicatorProps, state: any | undefined) {
		return super.classes(data, state,
			(state.pill_pressed
				? state.pill_pressed_long
					? styles.pressed_long
					: styles.pressed
				: ""));
	}

	dynamicStyles(props: TagIndicatorProps, state?: any): {} {
		const { has_rating } = props;
		const getBorderColor = () => {
			if (has_rating) {
				return this._csColor(props.rating ?? 0);
			} else if (props.relevance === 1) {
				return TAG_COLOR.relevance;
			} else {
				return "transparent";
			}
		};
		return {
			...super.dynamicStyles(props, state),
			border: CIStyler._lineStyle(
				props.size === ScalerSize.XXS ? 0 : has_rating || props.relevance === 1 ? 1.5 : 1,
				has_rating ? "solid" : props.relevance === 1 ? "dashed" : "solid",
				getBorderColor(),
			),
			boxShadow: state.selector_hover_rating !== null
				? `0px 0px 5px 5px ${this._csColor(state.selector_hover_rating, 0.5)}`
				: "none",
			transition: state.selector_hover_rating !== null ? "box-shadow 0.3s" : "none",
			zIndex: state.show_selector || (props.children && !state.popup_cleared) ? 12 : 11, // more than 10 because post comment profile pic zIndex is 10
		};
	}
}

class PillStyler extends TagStyler {
	constructor() {
		super(styles.pill);
	}

	leftColor = (data: TagIndicatorProps) => (this.isDataNotAvailable(data) ? "transparent" : this._csColor(data.s_credo_score));

	rightColor = (data: TagIndicatorProps) => (this.isDataNotAvailable(data) ? "transparent" : this._csColor(data.g_credo_score));

	midColor = (data: TagIndicatorProps) => (this.isDataNotAvailable(data) ? "transparent" : this._engagementColor(data.engagement));

	// eslint-disable-next-line class-methods-use-this
	midPoint = (data: TagIndicatorProps) => data.subjectivity;

	// background(_data: TagIndicatorProps) {
	// 	return;
	// }

	dynamicStyles(props: TagIndicatorProps, _state: any) {
		const getBorderStyle = () => {
			if (this.isDataNotAvailable(props)) {
				return "solid";
			} else {
				return "";
			}
		};

		const getBorderColor = () => {
			if (props.has_rating) {
				return this._csColor(props.rating ?? 0);
			} else {
				return TAG_COLOR.gray;
			}
		};

		return {
			...super.dynamicStyles(props),
			background: `linear-gradient(90deg, ${this.leftColor(props)} 0%, ${this.midColor(props)} ${(this.midPoint(props) * 100)}%, ${this.rightColor(props)}) 100%`,
			border: CIStyler._lineStyle(
				1,
				getBorderStyle(),
				getBorderColor(),
			),
			// border: this._lineStyle(1,
			// 	props.is_interest ? "solid" : "dashed",
			// 	props.is_interest ? this._engagementColor(props.engagement) : "white"),
		};
	}
}

class LabelStyler extends TagStyler {
	constructor() {
		super(styles.label);
	}

	textColor(props: TagIndicatorProps) {
		if (this.isDataNotAvailable(props)) {
			return TAG_COLOR.gray;
		}
		return this._isLight(props.s_credo_score, props.g_credo_score, props.subjectivity, props.engagement)
			? TAG_COLOR.dark
			: TAG_COLOR.light;
	}

	dynamicStyles(props: TagIndicatorProps) {
		return {
			...super.dynamicStyles(props),
			color: this.textColor(props),
			opacity: props.size === ScalerSize.XXS ? 0 : 1,
		};
	}
}

class IconStyler extends TagStyler {
	constructor() {
		super(styles.icon);
	}

	// eslint-disable-next-line class-methods-use-this
	defaultIcon = (props: any) => <Spinner {...props} />;
}

interface TagIndicatorProps extends CIProps {
	/* eslint-disable react/no-unused-prop-types */
	id: string,
	tag: string,
	size: ScalerSize,
	s_credo_score: number,
	g_credo_score: number,
	engagement: number,
	subjectivity: number,
	is_interest: boolean,
	has_rating: boolean,
	show_icon: boolean,
	// eslint-disable-next-line react/require-default-props
	icon?: (props: any) => ReactNode,
	rating: number | null,
	// eslint-disable-next-line react/require-default-props
	default_rating?: number,
	relevance: number,

	onRate(tag: string, new_rating: number): void,

	// eslint-disable-next-line react/require-default-props
	children?: ReactNode[] | ReactNode,
	// eslint-disable-next-line react/require-default-props
	isRatingAllowed?: boolean,
	// eslint-disable-next-line react/require-default-props
	onRatingBlocked?: () => void,
	showEgoModal: boolean,
	// eslint-disable-next-line react/require-default-props
	egoInfoProps?: {
		onPressTagInfoBubble: () => void,
		onPressCredoGraphText: () => void,
		switchComp: React.ReactNode,
	};
	boostTrayProps?: BoostTrayProps;
	disable?: boolean;
	messages: TagMessages;
}

class TagIndicator extends React.Component<TagIndicatorProps, any> {
	stylers: {
		pill: PillStyler,
		outline: OutlineStyler
		label: LabelStyler,
		icon: IconStyler
	};

	_default_rating = 1;

	doubleClick = false;

	debouncedOnMouseDown = debounce((evt: React.MouseEvent<HTMLElement>) => {
		if (!this.doubleClick) {
			this.onMouseDown(evt);
		}
	}, 300);

	debouncedOnMouseUp = debounce((evt: React.MouseEvent<HTMLElement>) => {
		if (this.doubleClick) {
			this.doubleClick = false;
		} else {
			this.onMouseUp(evt);
		}
	}, 300);

	debouncedOnMouseMove = debounce((evt: React.MouseEvent<HTMLElement>) => {
		if (!this.doubleClick) {
			this.onMouseMove(evt);
		}
	}, 300);

	constructor(props: TagIndicatorProps) {
		super(props);
		this.stylers = {
			pill: new PillStyler(),
			outline: new OutlineStyler(),
			label: new LabelStyler(),
			icon: new IconStyler(),
		};

		this._default_rating = props.default_rating != null ? props.default_rating : this._default_rating;

		this.onMouseDown = this.onMouseDown.bind(this);
		this.onMouseUp = this.onMouseUp.bind(this);
		this.onMouseMove = this.onMouseMove.bind(this);
		this.onSelectorCancel = this.onSelectorCancel.bind(this);
		this.onSelectorSelected = this.onSelectorSelected.bind(this);
		this.onSelectorRatingHover = this.onSelectorRatingHover.bind(this);
		this.showSelector = this.showSelector.bind(this);
		this.onDoubleClick = this.onDoubleClick.bind(this);
		this.handleWidthChange = this.handleWidthChange.bind(this);

		this.state = {
			pill_pressed: false,
			pill_pressed_long: false,
			pill_pressed_coords: { x: -1, y: -1 },
			new_rating: props.rating,
			show_selector: false,
			rating_allowed: props.isRatingAllowed,
			selector_hover_rating: null,
			isMobile: false,
		};
	}

	componentDidMount() {
		window.addEventListener("resize", this.handleWidthChange);
		this.setState({
			isMobile: window.innerWidth < TABLET_WIDTH,
		});
	}

	componentDidUpdate(prevProps: any) {
		const { show_selector } = this.state;
		const { isRatingAllowed } = this.props;
		if (!isRatingAllowed && show_selector) {
			this.hideSelector();
		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.handleWidthChange);
	}

	handleWidthChange() {
		this.setState({
			isMobile: window.innerWidth < TABLET_WIDTH,
		});
	}

	/**
	 * Gets called when a normal (ie not long) press, when mouse is released before this can be
	 * considered a long press
	 * This should set the default rating if no rating is provided, if provided, activates the selector
	 * @param evt
	 */
	onPress(evt: React.MouseEvent<HTMLElement>) {
		this.showSelector(evt.target as Element);
	}

	onMouseDown(evt: React.MouseEvent<HTMLElement>) {
		if (evt.button !== 0) return; // we don't care about right click
		// TODO: remove if not needed
		// const { pill_pressed } = this.state;
		// if (pill_pressed) {
		// 	// toggle out of this mode, since we really shouldn't be getting this
		// 	this.onMouseUp(evt);
		// 	return;
		// }
		evt.preventDefault();
		this.setState({
			pill_pressed: true,
			pill_pressed_coords: {
				x: evt.pageX,
				y: evt.pageY,
				elem_x: (evt.target as Element).getBoundingClientRect().x,
				elem_y: (evt.target as Element).getBoundingClientRect().y,
			},
		}, () => {
			const { pill_pressed } = this.state;
			if (pill_pressed) {
				this.setState({
					pill_pressed_timer: setTimeout(() => {
						this.setState({
							pill_pressed_long: true,
							pill_pressed_timer: null,
						});
						this.onLongPress(evt);
					}, 500),
				});
			}
		});
	}

	onMouseMove(evt: React.MouseEvent<HTMLElement>) {
		const { pill_pressed } = this.state;
		if (pill_pressed) {
			// looks like trying to drag, lets just show the selector
			this.showSelector(evt.target as Element);
		}
	}

	onSelectorCancel(): boolean {
		this.hideSelector();
		return true;
	}

	onSelectorSelected(new_rating: number): boolean {
		const { isRatingAllowed } = this.props;
		if (isRatingAllowed) {
			const { onRate, tag } = this.props;
			onRate(tag, new_rating);
			this.setState({
				new_rating,
			});
		}
		this.hideSelector();
		return true;
	}

	onSelectorRatingHover(new_rating: any): void {
		this.setState({
			selector_hover_rating: new_rating,
		});
	}

	onMouseUp(evt: any) {
		const { pill_pressed_long, pill_pressed_timer } = this.state;
		this.resetState();
		if (!pill_pressed_long) { // means it's a regular press
			if (pill_pressed_timer != null) {
				clearTimeout(pill_pressed_timer);
			}
			this.onPress(evt);
		}
	}

	/**
	 * Gets called when a long press - basically a mousedown event + some time passed
	 * @param evt
	 */
	onLongPress(evt: React.MouseEvent) {
		// set rating to 0 on long press
		this.doRating(0);
	}

	onDoubleClick() {
		// set rating to 1 on long press
		this.doubleClick = true;
		this.doRating(1);
	}

	doRating(value: number) {
		const { isRatingAllowed, onRate, tag } = this.props;
		if (isRatingAllowed) {
			onRate(tag, value);
			this.setState({
				new_rating: value,
			});
		}
		this.setState({
			pill_pressed: false,
			show_selector: false,
			selector_hover_rating: null,
		});
	}

	showSelector(anchor: { getBoundingClientRect: () => { x: number, y: number } } | null) {
		const { isRatingAllowed, onRatingBlocked } = this.props;

		if (isRatingAllowed) {
			this.setState({
				show_selector: true,
				popup_anchor: anchor,
			});
		} else {
			if (onRatingBlocked) onRatingBlocked();
			this.resetState({ popup_anchor: anchor, popup_cleared: false });
		}

		// this.setState({
		// 	show_selector: true,
		// 	popup_anchor: anchor,
		// });
	}

	hideSelector() {
		this.resetState();
	}

	private resetState(state: any = {}) {
		this.setState({
			...state,
			pill_pressed: false,
			pill_pressed_long: false,
			pill_pressed_timer: null,
			show_selector: false,
			selector_hover_rating: null,
		});
	}

	private renderSelector() {
		const {
			tag, rating, showEgoModal, egoInfoProps, boostTrayProps, messages,
			show_icon, engagement, g_credo_score, s_credo_score, is_interest, relevance, subjectivity, icon,
		} = this.props;
		const {
			popup_anchor, isMobile, pill_pressed_coords,
		} = this.state;

		const propsForCreedSelector = {
			tag,
			messages,
			rating: rating ?? 0,
			onCancel: this.onSelectorCancel,
			onSelected: this.onSelectorSelected,
			onRatingHover: this.onSelectorRatingHover,
			anchor: popup_anchor,
			showEgoModal,
			egoInfoProps,
			boostTrayProps: boostTrayProps && {
				...boostTrayProps,
				currentTagScore: g_credo_score ?? 0,
			},
			closeWebModal: this.onSelectorCancel,
			tagDataProvider: {
				engagement,
				hasCampaign: show_icon,
				objScore: g_credo_score,
				ownInterest: is_interest,
				ownRating: rating,
				relevance,
				subjScore: s_credo_score,
				subjectivity,
				campaignIcon: icon,
			},
		};

		if (isMobile) {
			return (
				<CreedSelectorSheet
					{...propsForCreedSelector}
				/>
			);
		} else {
			return (
				<CreedSelectorModal
					{...propsForCreedSelector}
					pillPosition={pill_pressed_coords}
				/>
			);
		}
	}

	render() {
		const {
			tag, show_icon, icon, children, id, disable,
		} = this.props;
		const {
			show_selector, popup_anchor, popup_cleared,
		} = this.state;
		return (
			<div
				id={id}
				className={this.stylers.outline.classes(this.props, this.state)}
				style={this.stylers.outline.dynamicStyles(this.props, this.state)}
			>
				{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
				<button
					type="button"
					ref={this.stylers.pill.ref()}
					className={this.stylers.pill.classes(
						this.props, this.state,
						disable ? "!max-w-[120px] pointer-events-none" : "",
					)}
					style={this.stylers.pill.dynamicStyles(this.props, this.state)}
					onMouseDown={this.debouncedOnMouseDown}
					onMouseUp={this.debouncedOnMouseUp}
					onMouseMove={this.debouncedOnMouseMove}
					onDoubleClick={this.onDoubleClick}
					disabled={disable}
					title={tag}
				>
					<div
						className={this.stylers.label.classes(this.props)}
						style={this.stylers.label.dynamicStyles(this.props)}
					>
						{tag}
					</div>
					{show_icon
						&& (
							<div
								className={this.stylers.icon.classes(this.props)}
								style={this.stylers.icon.dynamicStyles(this.props)}
							>
								{icon ? icon({}) : this.stylers.icon.defaultIcon({})}
							</div>
						)}

				</button>
				{children && !popup_cleared
					? (
						<ModalPopup
							style={{
								left: popup_anchor.left,
								top: popup_anchor.top,
							}}
							onModalStateChange={(state) => {
								if (state === ModalState.Closing) this.setState({ popup_cleared: true });
							}}
						>
							{children}
						</ModalPopup>
					)
					: show_selector
					&& this.renderSelector()}
			</div>
		);
	}
}

export default TagIndicator;
