import * as React from "react"
import {SVGAttributes} from "react";

const SvgComponent = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { stroke = "var(--gray-dark)" } = props;
	return (
		<svg width={18} height={14} viewBox="0 0 18 14" xmlns="http://www.w3.org/2000/svg" {...props} stroke="none">
			<g stroke={stroke} fill="none" fillRule="evenodd">
				<path strokeLinecap="square" d="M1 12.5 6.5 7M16.75 12.5 11.25 7" />
				<rect x={0.5} y={0.5} width={17} height={13} rx={1} />
				<path d="m1 1 7.293 7.293a1 1 0 0 0 1.414 0L17 1" />
			</g>
		</svg>
	)
}

export default SvgComponent
