import * as React from "react"
import {SVGAttributes} from "react";

const SvgComponent = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { stroke = "var(--primary)" } = props;
	return (
		<svg width={102} height={107} viewBox="0 0 102 107" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g stroke={stroke} fill="none" fillRule="evenodd">
				<path
					d="M101.312 32c0 17.398-14.102 31.5-31.5 31.5s-31.5-14.102-31.5-31.5S52.414.5 69.812.5s31.5 14.102 31.5 31.5Z"
					strokeDasharray="3,3"
				/>
				<path d="M69.81 28.5c5.24 0 9.502-4.262 9.502-9.5S75.05 9.5 69.81 9.5c-5.237 0-9.5 4.262-9.5 9.5s4.263 9.5 9.5 9.5ZM52.316 49.384c.789-10.582 5.209-14.865 7.773-16.486l.628-.398.633.443 7.96 6.41 7.964-6.41.633-.443.626.398c2.565 1.62 6.984 5.904 7.773 16.486l.006.888c-4.37 4.465-10.37 7.228-17 7.228-6.628 0-12.632-2.763-17-7.232l.004-.884Z" />
				<path
					d="M32.312 19c0 7.18-5.821 13-13 13-7.18 0-13-5.82-13-13 0-7.179 5.82-13 13-13 7.178 0 13 5.821 13 13Z"
					strokeDasharray="3,3"
				/>
				<path d="M13.312 19h11.999M19.311 13v12M1.613 106.453 22.09 86.262M38.533 86.262l20.478 20.191M32.845 79.516a2.533 2.533 0 1 1-5.066 0 2.533 2.533 0 0 1 5.066 0ZM32.845 70.244a2.533 2.533 0 1 1-5.066 0 2.533 2.533 0 0 1 5.066 0Z" />
				<path d="M7.92 62.562.5 68.624v36.722a1.11 1.11 0 0 0 1.113 1.107H59.01a1.11 1.11 0 0 0 1.112-1.107V68.624l-7.42-6.062" />
				<path d="m60.123 68.624-21.59 17.638c-1.858 1.518-5.04 3.276-8.222 3.276-3.18 0-6.363-1.758-8.22-3.276L.5 68.624M52.703 58.447v16.028" />
				<path d="M7.92 74.475v-35.98h31.063" />
				<path d="M35.312 54.752a5 5 0 1 1-10.001-.001 5 5 0 0 1 10 .001Z" />
				<path d="m35.312 54.752-.028 3.341a1.66 1.66 0 0 0 3.318 0v-3.341a8.291 8.291 0 1 0-3.83 6.99" />
			</g>
		</svg>
	)
}

export default SvgComponent
