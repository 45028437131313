// eslint-disable-next-line import/prefer-default-export
import {
	FaChartLine, FaExclamationCircle, FaSearch, FaUserAlt, FaUsersCog, FaUserShield, FaUserClock, FaUsersSlash, FaUserPlus,
} from "react-icons/fa";
import { BsPencilSquare } from "react-icons/bs";
import { GiShieldDisabled } from "react-icons/gi";
import { MdReportProblem, MdGroupAdd } from "react-icons/md";
import { Roles } from "../config";
import {
	COMMUNITIES_PAGE,
	DASHBOARD_PAGE,
	POSTS_PAGE,
	REPORTED_PAGE,
	SEARCH_PAGE,
	USERS_PAGE,
	COMMENTS_PAGE,
	AUTH_URL,
	SUSPENDED_USERS_PAGE,
	NOT_REGISTERED_USERS_PAGE,
	VIRTUAL_USERS_PAGE,
	DISABLED_PAGE,
	REPORTED_PROBLEMS_PAGE,
	NEW_USERS_PAGE,
	NEW_COMMUNITY_PAGE,
} from "../routes/constants";
import { LoggedInHeader } from "./types";
import { strings } from "../i18n/config";

export const NO_HEADER_PAGES = ["login"];
export const PAGINATION_LIMIT = 50;
export const DEBOUNCE_TIME = 700;
export const LOGGED_IN_HEADER: LoggedInHeader[] = [
	{
		id: 1,
		testId: "dashboard",
		name: strings("SideBar.dashboard"),
		href: `${AUTH_URL}${DASHBOARD_PAGE}`,
		icon: FaChartLine,
		userData: {
			permissions: [
				Roles.SUPER_ADMIN,
				Roles.ADMIN,
				Roles.USER_MODERATOR,
				Roles.STREAM_MODERATOR,
				Roles.POST_MODERATOR,
				Roles.COMMENT_MODERATOR,
				Roles.CUSTOMER_SUPPORT,
			],
		},
	},
	{
		id: 2,
		name: strings("SideBar.users"),
		href: `${AUTH_URL}${USERS_PAGE}`,
		icon: FaUserAlt,
		testId: "users",
		userData: {
			permissions: [
				Roles.SUPER_ADMIN,
				Roles.ADMIN,
				Roles.USER_MODERATOR,
			],
		},
		items: [
			{
				id: 21,
				testId: "users-search",
				name: strings("SideBar.search"),
				href: SEARCH_PAGE,
				icon: FaSearch,
				userData: {
					permissions: [
						Roles.SUPER_ADMIN,
						Roles.ADMIN,
						Roles.USER_MODERATOR,
					],
				},
			},
			{
				id: 22,
				testId: "new-users",
				name: strings("SideBar.new_users"),
				href: NEW_USERS_PAGE,
				icon: FaUserPlus,
				userData: {
					permissions: [
						Roles.SUPER_ADMIN,
						Roles.ADMIN,
						Roles.USER_MODERATOR,
					],
				},
			},
			{
				id: 23,
				testId: "users-reported",
				name: strings("SideBar.reported"),
				href: REPORTED_PAGE,
				icon: FaExclamationCircle,
				userData: {
					permissions: [
						Roles.SUPER_ADMIN,
						Roles.ADMIN,
						Roles.USER_MODERATOR,
					],
				},
			},
			{
				id: 24,
				testId: "users-suspended",
				name: strings("SideBar.suspended_users"),
				href: SUSPENDED_USERS_PAGE,
				icon: FaUserShield,
				userData: {
					permissions: [
						Roles.SUPER_ADMIN,
						Roles.ADMIN,
						Roles.USER_MODERATOR,
					],
				},
			},
			{
				id: 25,
				testId: "not-registered-users",
				name: strings("SideBar.not_registered_users"),
				href: NOT_REGISTERED_USERS_PAGE,
				icon: FaUserClock,
				userData: {
					permissions: [
						Roles.SUPER_ADMIN,
						Roles.ADMIN,
						Roles.USER_MODERATOR,
					],
				},
			},
			{
				id: 26,
				testId: "virtual-users",
				name: strings("SideBar.virtual_users"),
				href: VIRTUAL_USERS_PAGE,
				icon: FaUsersSlash,
				userData: {
					permissions: [
						Roles.SUPER_ADMIN,
						Roles.ADMIN,
						Roles.USER_MODERATOR,
					],
				},
			},
		],
	},
	{
		id: 3,
		testId: "communities",
		name: strings("SideBar.communities"),
		href: `${AUTH_URL}${COMMUNITIES_PAGE}`,
		icon: FaUsersCog,
		userData: {
			permissions: [
				Roles.SUPER_ADMIN,
				Roles.ADMIN,
				Roles.STREAM_MODERATOR,
			],
		},
		items: [
			{
				id: 31,
				testId: "communities-search",
				name: strings("SideBar.search"),
				href: SEARCH_PAGE,
				icon: FaSearch,
				userData: {
					permissions: [
						Roles.SUPER_ADMIN,
						Roles.ADMIN,
						Roles.STREAM_MODERATOR,
					],
				},
			},
			{
				id: 32,
				testId: "new-communities",
				name: strings("SideBar.new_communities"),
				href: NEW_COMMUNITY_PAGE,
				icon: MdGroupAdd,
				userData: {
					permissions: [
						Roles.SUPER_ADMIN,
						Roles.ADMIN,
						Roles.STREAM_MODERATOR,
					],
				},
			},
			{
				id: 33,
				testId: "communities-reported",
				name: strings("SideBar.reported"),
				href: REPORTED_PAGE,
				icon: FaExclamationCircle,
				userData: {
					permissions: [
						Roles.SUPER_ADMIN,
						Roles.ADMIN,
						Roles.STREAM_MODERATOR,
					],
				},
			},
		],
	},
	{
		id: 4,
		testId: "posts",
		name: strings("SideBar.posts"),
		href: `${AUTH_URL}${POSTS_PAGE}`,
		icon: BsPencilSquare,
		userData: {
			permissions: [
				Roles.SUPER_ADMIN,
				Roles.ADMIN,
				Roles.POST_MODERATOR,
			],
		},
		items: [
			{
				id: 41,
				testId: "posts-search",
				name: strings("SideBar.search"),
				href: SEARCH_PAGE,
				icon: FaSearch,
				userData: {
					permissions: [
						Roles.SUPER_ADMIN,
						Roles.ADMIN,
						Roles.POST_MODERATOR,
					],
				},
			},
			{
				id: 42,
				testId: "posts-reported",
				name: strings("SideBar.reported"),
				href: REPORTED_PAGE,
				icon: FaExclamationCircle,
				userData: {
					permissions: [
						Roles.SUPER_ADMIN,
						Roles.ADMIN,
						Roles.POST_MODERATOR,
					],
				},
			},
			{
				id: 43,
				testId: "posts-disabled",
				name: strings("SideBar.disabled"),
				href: DISABLED_PAGE,
				icon: GiShieldDisabled,
				userData: {
					permissions: [
						Roles.SUPER_ADMIN,
						Roles.ADMIN,
						Roles.POST_MODERATOR,
					],
				},
			},
		],
	},
	{
		id: 5,
		testId: "comments",
		name: strings("SideBar.comments"),
		href: `${AUTH_URL}${COMMENTS_PAGE}`,
		icon: BsPencilSquare,
		userData: {
			permissions: [
				Roles.SUPER_ADMIN,
				Roles.ADMIN,
				Roles.COMMENT_MODERATOR,
			],
		},
		items: [
			{
				id: 51,
				testId: "comments-search",
				name: strings("SideBar.search"),
				href: SEARCH_PAGE,
				icon: FaSearch,
				userData: {
					permissions: [
						Roles.SUPER_ADMIN,
						Roles.ADMIN,
						Roles.COMMENT_MODERATOR,
					],
				},
			},
			{
				id: 52,
				testId: "comments-reported",
				name: strings("SideBar.reported"),
				href: REPORTED_PAGE,
				icon: FaExclamationCircle,
				userData: {
					permissions: [
						Roles.SUPER_ADMIN,
						Roles.ADMIN,
						Roles.COMMENT_MODERATOR,
					],
				},
			},
			{
				id: 53,
				testId: "comments-disabled",
				name: strings("SideBar.disabled"),
				href: DISABLED_PAGE,
				icon: GiShieldDisabled,
				userData: {
					permissions: [
						Roles.SUPER_ADMIN,
						Roles.ADMIN,
						Roles.COMMENT_MODERATOR,
					],
				},
			},
		],
	},
	{
		id: 6,
		testId: "reported-problems",
		name: strings("SideBar.reported_problems"),
		href: `${AUTH_URL}${REPORTED_PROBLEMS_PAGE}`,
		icon: MdReportProblem,
		userData: {
			permissions: [
				Roles.SUPER_ADMIN,
				Roles.ADMIN,
				Roles.USER_MODERATOR,
				Roles.STREAM_MODERATOR,
				Roles.POST_MODERATOR,
				Roles.COMMENT_MODERATOR,
				Roles.CUSTOMER_SUPPORT,
			],
		},
	},
];
