import React, { useEffect, useRef, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import moment from "moment";
import { AppMode, cu } from "@credo/utilities";
import { PencilIcon, SvgIcon } from "@credo/ui-components";
import CoverPicture from "../../../gen-comp/CoverPicture";
import ProfilePicture from "../../../gen-comp/ProfilePicture";
import { useQuery } from "../../../hooks/graphql/useQuery";
import { strings } from "../../../i18n/config";
import classNames from "../../../utils/classNames";
import {
	isSet, secondsToDhms, isYes,
} from "../../../utils/utils";
import "react-loading-skeleton/dist/skeleton.css";
import { SingleUserFields } from "../constants";
import {
	getReportedUsersData, GetReportedUsersVariables, getSingleUserDetails, GetSingleUserDetailsVariable,
} from "../queries";
import { ReportingUserDetails, UserDetails, YesNoOptions } from "../../../utils/types";
import Assets from "../../../assets/images/Assets";
import UserCredoProfile from "./UserCredoProfile";
import UserExpertise from "./UserExpertise";
import UserExperience from "./UserExperience";
import UserQualification from "./UserQualification";
import SuspendedUserSection from "./SuspendedUserSection";
import { getSuspendedUsersRequest } from "../UserRequests";
import { successRETCD } from "../../../api";
import UserCommunities from "./UserCommunities";
import UserReportedList from "./UserReportedList";
import EditUserDetailsModal from "./EditUserDetailsModal";
import UserActivities from "./UserActivities";

export default function UserDetailsPage() {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const userId = searchParams.get("userId");
	const prof_id = searchParams.get("prof_id");
	const mode = searchParams.get("mode");
	const [userSuspensionDetails, setUserSuspensionDetails] = useState<any>({ isSuspended: YesNoOptions.NO });
	const isNewTab = searchParams.get("isNewTab") ?? "";
	const [userReportedData, setUserReportedData] = useState<ReportingUserDetails[] | []>([]);
	const bottomRef = useRef<any>(null);
	const [showEditModal, setShowEditModal] = useState<boolean>(false);
	const [height, setHeight] = useState(0);
	const [userData, setUserData] = useState<UserDetails>();
	const ref = useRef<any>(null);

	const variables: GetSingleUserDetailsVariable = {
		userId: userId || "",
		prof_id: prof_id || "",
		mode: mode as AppMode,
		fields: SingleUserFields,
		offset: 0,
	};

	const scrollToBottom = () => {
		if (bottomRef) {
			bottomRef?.current?.scrollIntoView({
				behavior: "smooth", block: "end", inline: "nearest",
			});
		}
	};

	useEffect(() => {
		if (ref) {
			setHeight(ref?.current?.clientHeight || 0);
		}
	});

	const [getUserDetails, { data, loading }] = useQuery<UserDetails>(getSingleUserDetails(variables), {
		formatDataKey: "user",
		hasSingleData: true,
	});

	const getUserSuspensionDetails = async () => {
		const res = await getSuspendedUsersRequest(userId);
		if (res && res.code === 200) {
			const data = res?.response;
			if (data && data?.retcd === successRETCD && typeof data?.items === "object" && data?.items.status === "-1") {
				setUserSuspensionDetails({
					isSuspended: YesNoOptions.YES,
					suspension_reason: data.items.suspended_reason,
					suspension_end_date: data.items.suspended_date
						? moment(new Date(data.items.suspended_date)).format("DD MMM YYYY") : strings("UserSearchPage.not_available"),
					time_left: parseInt(data.items.time_left, 10) === 0 || data.items.time_left === ""
						? strings("SuspendUserModal.forever") : secondsToDhms(data.items.time_left),
				});
			}
		}
	};

	const reported_variables: GetReportedUsersVariables = {
		prof_id_array: [userData?.cprof_id || "", userData?.eprof_id || ""],
	};

	const [getReportedList, { data: reportedData, loading: isReportedLoading }] = useQuery<[{ posts: [ReportingUserDetails] }]>(
		getReportedUsersData(reported_variables),
		{
			formatDataKey: "stream",
		},
	);

	useEffect(() => {
		if (reportedData && reportedData?.length > 0 && reportedData[0] && reportedData[0]?.posts) {
			setUserReportedData(reportedData[0]?.posts);
		}
	}, [reportedData]);

	useEffect(() => {
		if (data && (data?.cprof_id || data?.eprof_id)
			&& ((data.e_nb_rep && data?.e_nb_rep > 0) || (data.c_nb_rep && data?.c_nb_rep > 0))) {
			setTimeout(() => { scrollToBottom(); }, 500);
		}
	}, [height]);

	useEffect(() => {
		setUserData(data as UserDetails);
	}, [data]);

	useEffect(() => {
		getUserDetails();
		getUserSuspensionDetails();
	}, [userId, prof_id]);

	useEffect(() => {
		if (data && (data?.cprof_id || data?.eprof_id)
			&& ((data.e_nb_rep && data?.e_nb_rep > 0) || (data.c_nb_rep && data?.c_nb_rep > 0))) {
			getReportedList();
		}
	}, [userData]);

	const resetUserDetails = (newData: UserDetails) => {
		setUserData(newData);
	};

	if (loading || (isReportedLoading && (userData?.cprof_id || userData?.eprof_id))) {
		return (
			<div className="w-auto h-full" data-testid="user-details-loading-wrapper">
				<SkeletonTheme>
					<div className="px-3">
						<Skeleton className={classNames("metric-card border rounded-lg p-4 h-36")} />
					</div>
					<div className="flex flex-col w-full justify-center items-center">
						<Skeleton className={classNames("-top-10 rounded-full")} borderRadius="50%" width={120} height={120} />
						<Skeleton count={3} width={200} height={20} className={classNames("relative mb-1 -top-4")} />
					</div>
					<div className="px-3">
						<Skeleton className={classNames("metric-card border rounded-lg p-4 h-40")} borderRadius={10} />
					</div>
				</SkeletonTheme>
			</div>
		);
	}

	if (!userData) {
		return (
			<div className="w-full h-full" data-testid="user-details-not-found-wrapper">
				<CoverPicture coverPicUrl={Assets.UserProfileCoverEgo} />
				<div className="relative -top-28 left-10 w-fit bg-white rounded-xl opacity-80 cursor-pointer">
					<IoIosArrowBack
						className={classNames("flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 inactive-text-style p-1")}
						onClick={() => navigate(-1)}
						size={25}
					/>
				</div>
				<div className="flex flex-col w-full justify-center items-center">
					<ProfilePicture
						profilePicUrl={Assets.UserProfileEgoDefault}
						profilePicStyle="rounded-full bg-white"
					/>
					<div className="relative -top-6 py-1 px-1 w-8/12">
						<h5 className="text-center">
							{strings("UserDetailsPage.user_not_found")}
						</h5>
					</div>
				</div>
			</div>
		);
	}

	const onCancelButton = () => {
		setShowEditModal(false);
	};

	return (
		<div ref={ref}>
			<CoverPicture
				coverPicUrl={userData?.ecoverPicRelUrl
					? (cu.buildSourceUrlImage(userData.ecoverPicRelUrl) ?? Assets.UserProfileCoverEgo)
					: Assets.UserProfileCoverEgo}
			/>
			{!isYes(isNewTab)
				? (
					<div className="relative -top-28 left-10 w-fit bg-white rounded-xl opacity-80 cursor-pointer">
						<IoIosArrowBack
							className={classNames("flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 inactive-text-style p-1")}
							onClick={() => navigate(-1)}
							size={25}
						/>
					</div>
				) : null}
			<div className="flex flex-col w-full justify-center items-center">
				<ProfilePicture
					profilePicUrl={userData?.eprofilePicRelUrl ? (cu.buildSourceUrlImage(userData.eprofilePicRelUrl) || "") : ""}
					profilePicStyle="rounded-full"
				/>
				<div className="relative -top-12 py-1 px-1 w-8/12 text-center">
					<div className="flex justify-center items-center">
						<p
							className="text-black font-bold text-heading_2 whitespace-pre-wrap break-words"
							data-testid="user-name"
						>
							{userData.firstName ? userData.firstName : "--"}
							&nbsp;
							{userData.lastName ? userData.lastName : "--"}
						</p>
						{userData.isbot === YesNoOptions.YES ? (
							<>
								&nbsp;
								<div className="bot-badge ml-1" data-testid="bot-badge">
									{strings("UserDetailsPage.bot")}
								</div>
							</>
						) : null}
						{userData.isbot !== YesNoOptions.YES && (
							<SvgIcon
								icon={PencilIcon}
								title={strings("UserDetailsPage.edit")}
								className="cursor-pointer hover:bg-hover-1 p-1 rounded-full"
								onClick={() => { setShowEditModal(true); }}
							/>
						)}
					</div>
					{userData.egousername ? (
						<p
							className="text-sm whitespace-pre-wrap"
							data-testid="user-id"
						>
							{`@${userData.egousername}`}
						</p>
					) : null}
					{isSet(userData.edescr) && userData.edescr?.trim() ? (
						<p className="text-gray-dark text-sm whitespace-pre-wrap break-words" data-testid="user-description">
							{userData.edescr}
						</p>
					) : (
						<p className="text-gray-dark text-sm whitespace-pre-wrap" data-testid="user-description">
							{strings("UserDetailsPage.short_desc_about_user")}
						</p>
					)}
				</div>
			</div>
			{userData?.isbot !== YesNoOptions.YES && (
				<div className="flex w-full mb-4 flex-wrap gap-y-2.5">
					<UserCredoProfile userProfileData={userData} />
					<SuspendedUserSection
						userProfileData={{ ...userData, ...userSuspensionDetails }}
					/>
					<EditUserDetailsModal
						isVisible={showEditModal}
						userDetails={userData}
						cancel_button_function={onCancelButton}
						resetUserDetails={resetUserDetails}
					/>
				</div>
			)}
			<UserExpertise data={userData} />
			<UserExperience data={userData} />
			<UserQualification data={userData} />
			<UserCommunities data={userData} />
			{userData?.isbot !== YesNoOptions.YES && (
				<UserActivities
					userId={userId || ""}
					mode={mode as AppMode}
					prof_id={prof_id || ""}
				/>
			)}
			<UserReportedList
				data={userReportedData}
				userData={userData}
				mode={mode}
			/>
			<div ref={bottomRef} />
		</div>
	);
}
