import React, { SVGAttributes } from "react";

const FilterAdd = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { fill = "var(--boost-tag-accent)" } = props;
	return (
		<svg
			width="12px"
			height="12px"
			viewBox="0 0 12 12"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<g id="Comp---New-Nav" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="New-Nav---For-You-Configure-Finance-Aded" transform="translate(-42, -292)">
					<g id="Header" transform="translate(0, -24)">
						<g id="Page-Configurer-Modal" transform="translate(4, 65)">
							<g id="Filter-Tags" transform="translate(11, 40)">
								<g id="select-ctag---health-+-select-ctag---health-copy-+-select-ctag---crypto-+-select-ctag---finance-+-select-ctag---finance-copy-Mask" transform="translate(0, 125)">
									<g id="select-ctag---finance-copy" transform="translate(2, 80)">
										<g id="CB-Icon-Oval" transform="translate(25, 6)">
											<rect id="Background" fill={fill} x="0" y="0" width="12" height="12" rx="6" />
											<path d="M6.00013333,3.0668 C6.27735862,3.0668 6.50518175,3.27831576 6.53102522,3.54876977 L6.53346667,3.60013333 L6.53333333,5.46653333 L8.40013333,5.4668 C8.6946852,5.4668 8.93346667,5.70558147 8.93346667,6.00013333 C8.93346667,6.27735862 8.7219509,6.50518175 8.45149689,6.53102522 L8.40013333,6.53346667 L6.53333333,6.53333333 L6.53346667,8.40013333 C6.53346667,8.6946852 6.2946852,8.93346667 6.00013333,8.93346667 C5.72290805,8.93346667 5.49508491,8.7219509 5.46924145,8.45149689 L5.4668,8.40013333 L5.46653333,6.53333333 L3.60013333,6.53346667 C3.30558147,6.53346667 3.0668,6.2946852 3.0668,6.00013333 C3.0668,5.72290805 3.27831576,5.49508491 3.54876977,5.46924145 L3.60013333,5.4668 L5.46653333,5.46653333 L5.4668,3.60013333 C5.4668,3.30558147 5.70558147,3.0668 6.00013333,3.0668 Z" id="Path" fill="#212636" fillRule="nonzero" />
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default FilterAdd;
