import React, { SVGAttributes } from "react";

const Engage = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { stroke = "var(--gray-dark)", fill } = props;
	return (
		<svg width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
			<g id="Web-Version" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Web-Version---Home-Ego" transform="translate(-30.000000, -236.000000)" stroke={stroke} fill={fill}>
					<g id="pop-up-copy-3" transform="translate(30.000000, 171.000000)">
						<g id="menu_engage" transform="translate(0.000000, 65.000000)">
							<path d="M8.4978,13.1208 C8.4978,12.0158 9.3928,11.1208 10.4978,11.1208 C11.6028,11.1208 12.4978,12.0158 12.4978,13.1208 C12.4978,14.2258 11.6028,15.1208 10.4978,15.1208 C9.3928,15.1208 8.4978,14.2258 8.4978,13.1208 Z" id="Stroke-1"/>
							<path d="M10.4976,17.4517 C8.7516,17.4517 8.2936,16.7117 6.9626,15.7167 C5.9476,14.9587 4.6236,14.5877 3.8886,15.1147 C4.5866,18.1987 7.2776,20.4997 10.4976,20.4997 C13.7186,20.4997 16.4096,18.1987 17.1066,15.1147 C16.3716,14.5877 15.0486,14.9587 14.0326,15.7167 C12.7016,16.7117 12.2446,17.4517 10.4976,17.4517 Z" id="Stroke-3"/>
							<path d="M8.6422,5.9345 C9.5982,6.4865 9.9262,7.7095 9.3742,8.6665 C8.8222,9.6225 7.5992,9.9505 6.6422,9.3985 C5.6862,8.8455 5.3582,7.6225 5.9102,6.6665 C6.4632,5.7095 7.6862,5.3825 8.6422,5.9345 Z" id="Stroke-5"/>
							<path d="M14.3572,9.3976 C13.4012,9.9496 12.1782,9.6216 11.6252,8.6656 C11.0732,7.7096 11.4012,6.4866 12.3572,5.9336 C13.3142,5.3816 14.5372,5.7096 15.0892,6.6656 C15.6412,7.6226 15.3132,8.8456 14.3572,9.3976 Z" id="Stroke-7"/>
							<path d="M17.0158,5.5006 C17.8888,7.0516 17.4918,7.8276 17.3178,9.5066 C17.1848,10.7876 17.5338,12.1476 18.3468,12.5366 C20.6028,10.3756 21.2008,6.8356 19.5908,3.9766 C17.9808,1.1166 14.6918,-0.1214 11.7368,0.8006 C11.6598,1.7176 12.6348,2.7066 13.7828,3.2286 C15.2888,3.9136 16.1428,3.9496 17.0158,5.5006 Z" id="Stroke-9"/>
							<path d="M3.9842,5.5006 C3.1112,7.0516 3.5082,7.8276 3.6822,9.5066 C3.8152,10.7876 3.4662,12.1476 2.6532,12.5366 C0.3972,10.3756 -0.2008,6.8356 1.4092,3.9766 C3.0192,1.1166 6.3082,-0.1214 9.2632,0.8006 C9.3402,1.7176 8.3652,2.7066 7.2172,3.2286 C5.7112,3.9136 4.8572,3.9496 3.9842,5.5006 Z" id="Stroke-11"/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}

export default Engage;
