import React from "react";
import { IconBaseProps } from "react-icons/lib/cjs/iconBase";

interface SvgIconProps extends IconBaseProps {
	icon: any;
}

export default function SvgIcon({ icon, ...props }: SvgIconProps) {
	const IconComponent = icon;
	return (
		<IconComponent {...props} />
	);
}
