import React from "react";
import Logo from "./Logo";
import "./css/PageNotFound.css";
import { strings } from "../i18n/config";

export default function PageNotFound() {
	return (
		<div className="bg-background-dark flex flex-col items-center justify-center inset-0 absolute">
			<div className="flex items-center justify-center flex-col items-center">

				<div className="pb-8">
					<Logo
						height={150}
						width={175}
						logoClassName="logo-style__page-not-found"
					/>
				</div>

				<h1 className="active-text text-primary">{strings("PageNotFound.404_error")}</h1>

				<h6 className="large-text text-neutral-100">
					{strings("PageNotFound.page_not_found")}
				</h6>

				<p className="small-text">
					{strings("PageNotFound.message")}
				</p>

			</div>
		</div>
	);
}
