import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { classNames } from "../common";

const StreamProfileSkeleton = () => (
	<SkeletonTheme
		baseColor="var(--background-skeleton)"
		highlightColor="var(--hightlight-skeleton)"
	>
		<div className="w-full">
			<Skeleton className={classNames("metric-card rounded-lg p-4 h-32 w-full")} />
		</div>
		<div className="flex flex-row w-full">
			<Skeleton className={classNames("left-5 -top-14")} borderRadius={10} width={100} height={100} />
			<Skeleton count={1} width={150} height={10} className={classNames("left-14 relative my-2")} />
		</div>
	</SkeletonTheme>
);

export default StreamProfileSkeleton;
