/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGAttributes } from "react";

const ProfileNoNetwork = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--accent)",
	} = props;
	return (
		<svg
			width="74px"
			height="93px"
			viewBox="0 0 74 93"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<g id="Profile---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-icons" transform="translate(-95.000000, -572.000000)" stroke={stroke}>
					<g id="profile_no_network" transform="translate(95.000000, 572.000000)">
						<path
							d="M71.6161,41.9688 C71.6161,50.1218 65.0061,56.7318 56.8531,56.7318 C48.7001,56.7318 42.0911,50.1218 42.0911,41.9688 C42.0911,33.8158 48.7001,27.2058 56.8531,27.2058 C65.0061,27.2058 71.6161,33.8158 71.6161,41.9688 Z"
							id="Stroke-1"
						/>
						<polygon id="Stroke-3" points="58.2107 45.6523 55.4957 45.6523 54.2627 33.2233 59.4447 33.2233" />
						<path
							d="M58.6781,49.8556 C58.6781,50.8636 57.8611,51.6806 56.8531,51.6806 C55.8461,51.6806 55.0291,50.8636 55.0291,49.8556 C55.0291,48.8476 55.8461,48.0306 56.8531,48.0306 C57.8611,48.0306 58.6781,48.8476 58.6781,49.8556 Z"
							id="Stroke-5"
						/>
						<path
							d="M42.8226,46.5615 C40.9486,47.0455 38.9836,47.3025 36.9576,47.3025 C24.0336,47.3025 13.5566,36.8255 13.5566,23.9015 C13.5566,10.9775 24.0336,0.4995 36.9576,0.4995 C49.8816,0.4995 60.3586,10.9775 60.3586,23.9015 C60.3586,25.1465 60.2616,26.3695 60.0746,27.5625"
							id="Stroke-7"
							strokeDasharray="2,2"
						/>
						<path
							d="M36.4945,20.7364 C40.3235,20.7364 43.4375,17.6214 43.4375,13.7934 C43.4375,9.9644 40.3235,6.8504 36.4945,6.8504 C32.6675,6.8504 29.5525,9.9644 29.5525,13.7934 C29.5525,17.6214 32.6675,20.7364 36.4945,20.7364 Z"
							id="Stroke-9"
							strokeDasharray="2,2"
						/>
						<path
							d="M42.0918,41.1 C40.3298,41.674 38.4498,41.984 36.4968,41.984 C31.5408,41.984 27.0538,39.987 23.7868,36.758 L23.7908,36.119 C24.3808,28.473 27.6848,25.378 29.6018,24.206 L30.0698,23.919 L30.5428,24.239 L36.4948,28.871 L42.4458,24.239 L42.9188,23.919 L43.3878,24.206 C44.7018,25.009 46.6678,26.717 47.9638,30.175"
							id="Stroke-11"
							strokeDasharray="2,2"
						/>
						<path
							d="M41.6368,87.6183 C41.6368,90.2023 39.5418,92.2973 36.9578,92.2973 C34.3738,92.2973 32.2788,90.2023 32.2788,87.6183 C32.2788,85.0343 34.3738,82.9393 36.9578,82.9393 C39.5418,82.9393 41.6368,85.0343 41.6368,87.6183 Z"
							id="Stroke-13"
						/>
						<path
							d="M15.873,76.0436 C24.363,57.7786 49.552,57.7776 58.043,76.0436 C59.628,79.4536 64.671,76.4866 63.093,73.0916 C52.83,51.0126 21.087,51.0086 10.823,73.0916 C9.246,76.4836 14.286,79.4576 15.873,76.0436 Z"
							id="Stroke-15"
						/>
						<path
							d="M56.8466,56.7349 C61.4596,60.0419 65.2996,64.4869 68.0116,69.7429 C69.7386,73.0889 74.7876,70.1339 73.0616,66.7909 C70.7236,62.2599 67.6066,58.2519 63.9186,54.9279"
							id="Stroke-17"
						/>
						<path
							d="M27.9353,45.4981 C16.3673,48.2091 6.4113,56.0211 0.8533,66.7911 C-0.8697,70.1301 4.1753,73.0921 5.9043,69.7431 C11.9373,58.0511 23.6323,50.2791 36.9583,50.3031 C39.9013,50.3081 42.7633,50.6871 45.5013,51.4001"
							id="Stroke-19"
						/>
						<path
							d="M53.2678,80.1863 C47.8988,64.2023 26.0168,64.2013 20.6478,80.1863 C19.4468,83.7623 25.0938,85.2963 26.2878,81.7413 C29.7878,71.3203 44.1248,71.3103 47.6278,81.7413 C48.8198,85.2913 54.4708,83.7683 53.2678,80.1863 Z"
							id="Stroke-21"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default ProfileNoNetwork;
