import React, { SVGAttributes } from "react";

const Explore = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { stroke = "var(--gray-dark)", fill = "var(--gray-dark)" } = props;
	return (
		<svg
			width="20px"
			height="20px"
			viewBox="0 0 20 20"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<title>Bots</title>
			<g id="Icon---Explore---Inactive" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<path d="M6.32841299,13.671587 L11.4275039,11.4275039 L13.671587,6.32841299 L8.57249608,8.57249608 L6.32841299,13.671587 Z M10,10.9230795 C9.74358904,10.9359 9.52563972,10.8493613 9.34615205,10.6634634 C9.16666438,10.4775654 9.07692054,10.256411 9.07692054,10 C9.07692054,9.74358904 9.16538232,9.52563972 9.34230589,9.34615205 C9.51922945,9.16666438 9.73846082,9.07692054 10,9.07692054 C10.2615392,9.07692054 10.4807706,9.16538232 10.6576941,9.34230589 C10.8346177,9.51922945 10.9230795,9.73846082 10.9230795,10 C10.9230795,10.2615392 10.8346177,10.4807706 10.6576941,10.6576941 C10.4807706,10.8346177 10.2615392,10.9230795 10,10.9230795 Z M10.0065962,17 C9.04361917,17 8.1366936,16.81782 7.28581947,16.45346 C6.43493251,16.0891001 5.69017406,15.5877525 5.0515441,14.9494174 C4.41290132,14.3110695 3.91131661,13.5669201 3.54678996,12.716969 C3.18226332,11.8670179 3,10.9588552 3,9.99248075 C3,9.02609348 3.18217999,8.12066791 3.54653996,7.27620406 C3.91089994,6.43172738 4.41224747,5.69017406 5.05058256,5.0515441 C5.68893046,4.41290132 6.43307994,3.91131661 7.283031,3.54678996 C8.13298205,3.18226332 9.0411448,3 10.0075193,3 C10.9739065,3 11.8793321,3.18217999 12.7237959,3.54653996 C13.5682726,3.91089994 14.3098259,4.41224747 14.9484559,5.05058256 C15.5870987,5.68893046 16.0886834,6.43157994 16.45321,7.27853098 C16.8177367,8.12548203 17,9.03043964 17,9.99340383 C17,10.9563808 16.81782,11.8633064 16.45346,12.7141805 C16.0891001,13.5650675 15.5877525,14.3098259 14.9494174,14.9484559 C14.3110695,15.5870987 13.5684201,16.0886834 12.721469,16.45321 C11.874518,16.8177367 10.9695604,17 10.0065962,17 Z" id="Shape" fill={fill} fillRule="nonzero" />
				<circle id="Oval" stroke={fill} cx="10" cy="10" r="8.5" />
			</g>
		</svg>
	);
};

export default Explore;
