import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import {
	createSearchParams, useLocation, useNavigate, useSearchParams,
} from "react-router-dom";
import { usePrevious } from "react-use";
import Filter from "../../gen-comp/Filter";
import Hero from "../../gen-comp/Hero";
import Table from "../../gen-comp/table";
import { useQuery } from "../../hooks/graphql/useQuery";
import { strings } from "../../i18n/config";
import { TableHeader, NoOfReportedOptions, SearchBarElementInterface } from "../../utils/types";
import { deleteStreamQuery, getCommunitiesQueries, GetCommunityVariables } from "./queries";
import {
	SearchColumns, StreamTableHeaders, DiscoveryOptions, JoinModesOptions, CommunityReportedOptions,
} from "./constants";
import { DATE_FORMAT } from "../../utils/utils";
import { AUTH_URL, COMMUNITIES_DETAILS_PAGE } from "../../routes/constants";
import ConfirmationModal from "../../gen-comp/ConfirmationModal";
import { PAGINATION_LIMIT } from "../../utils/constants";
import Accordion from "../../gen-comp/Accordion";
import TooltipHelper from "../../gen-comp/TooltipHelper";
import useDimensions from "../../hooks/useDimensions";
import SearchBarContainer from "../../gen-comp/SearchBar/SearchBarContainer";
import { GetWhereClauseVariables } from "../../utils/getWhereClause";
import useOptionalSearchApi from "../../hooks/useOptionalSearchApi";

export default function CommunitySearchPage() {
	const navigate = useNavigate();
	const location = useLocation();
	const wrapperRef = useRef(null);
	const [, width] = useDimensions();
	// to skip the function call on first mount in useEffect
	const skipMountEffect = useRef<boolean>(true);

	const [searchParams] = useSearchParams();
	const searchDataParams = searchParams.get("searchData");
	const headerParams = searchParams.get("headers");
	const joinModesParams = searchParams.get("joinModes");
	const discoveryParams = searchParams.get("discovery");
	const reportedParams = searchParams.get("reported");

	// eslint-disable-next-line no-nested-ternary
	const [headers, setHeaders] = useState<TableHeader[]>(headerParams ? JSON.parse(headerParams)
		: localStorage.getItem("community-headers")
			? JSON.parse(localStorage.getItem("community-headers") || "") : StreamTableHeaders.filter((e) => e.isDefault));
	const [selectedCommunity, setSelectedCommunity] = useState<any>(null);
	const [isVisible, setIsVisible] = useState<boolean>(false);
	const [joinModes, setJoinModes] = useState<TableHeader[]>(joinModesParams ? JSON.parse(joinModesParams) : JoinModesOptions);
	const [discoveryOptions, setDiscoveryOptions] = useState<TableHeader[]>(
		discoveryParams ? JSON.parse(discoveryParams) : DiscoveryOptions,
	);
	const [reportedOptions, setReportedOptions] = useState<TableHeader[]>(
		reportedParams ? JSON.parse(reportedParams) : CommunityReportedOptions,
	);
	const [communitiesData, setCommunitiesData] = useState<any>([]);
	const [searchData, setSearchData] = useState<SearchBarElementInterface[]>(searchDataParams ? JSON.parse(searchDataParams) : []);
	const [page, setPage] = useState(1);
	const [changeInPage, setChangeInPage] = useState<boolean>(true);
	const prevSearchData = usePrevious(searchData);

	useEffect(() => {
		const paramString = `searchData=${JSON.stringify(searchData)}`
			+ `&headers=${JSON.stringify(headers)}`
			+ `&joinModes=${JSON.stringify(joinModes)}`
			+ `&discovery=${JSON.stringify(discoveryOptions)}`
			+ `&reported=${JSON.stringify(reportedOptions)}`;
		if (window) {
			window.history.replaceState(null, "", `${location.pathname}?${paramString}`);
		}
	}, [searchData, headers, joinModes, reportedOptions, discoveryOptions]);

	useEffect(() => {
		if (searchDataParams) {
			setSearchData(JSON.parse(searchDataParams));
		}
		if (headerParams) {
			setHeaders(JSON.parse(headerParams));
		}
		if (joinModesParams) {
			setJoinModes(JSON.parse(joinModesParams));
		}
		if (discoveryParams) {
			setDiscoveryOptions(JSON.parse(discoveryParams));
		}
		if (reportedParams) {
			setReportedOptions(JSON.parse(reportedParams));
		}
	}, []);

	const getJoinModeQuery = () => {
		if (joinModes.length !== JoinModesOptions.length && joinModes.length !== 0) {
			let query = " joinModes_in: [";
			joinModes.forEach((item) => {
				query += `"${item.value}",`;
			});
			return `${query}],`;
		}
		return "";
	};

	const getDiscoveryQuery = () => {
		if (discoveryOptions.length !== DiscoveryOptions.length && discoveryOptions.length !== 0) {
			let query = " disc_in: [";
			discoveryOptions.forEach((item) => {
				query += `"${item.value}",`;
			});
			return `${query}],`;
		}
		return "";
	};

	const getNoOfReportedQuery = () => {
		if (reportedOptions.length !== CommunityReportedOptions.length && reportedOptions.length !== 0) {
			let query = "";
			if (reportedOptions.length === 2) {
				const filteredArray = CommunityReportedOptions.filter((value: TableHeader) => !reportedOptions.includes(value));
				filteredArray.forEach((item: { value: NoOfReportedOptions; }) => {
					if (item.value === NoOfReportedOptions.ZERO) {
						query += " nb_rep_gte:1, ";
					} else if (item.value === NoOfReportedOptions.ONE_TO_TEN) {
						query += " OR: [{ nb_rep_lte:1 }, { nb_rep_gt:10 }], ";
					} else {
						query += "nb_rep_lte:10, ";
					}
				});
			} else {
				reportedOptions.forEach((item) => {
					if (item.value === NoOfReportedOptions.ZERO) {
						query += " nb_rep_lte:0, ";
					} else if (item.value === NoOfReportedOptions.ONE_TO_TEN) {
						query += "nb_rep_lte:10, nb_rep_gte:1, ";
					} else {
						query += "nb_rep_gte:10, ";
					}
				});
			}
			return `${query}`;
		}
		return "";
	};

	const variables: GetCommunityVariables = {
		headers,
		searchData,
		otherFilterQuery: getDiscoveryQuery() || getJoinModeQuery() || getNoOfReportedQuery()
			? `{${getDiscoveryQuery()} ${getJoinModeQuery()} ${getNoOfReportedQuery()}}` : "",
		offset: (page - 1) * PAGINATION_LIMIT,
	};

	const getOtherFilters = () => {
		let finalFilterQuery: GetWhereClauseVariables["otherFilterQuery"] = {
			type: [
				{
					title: "dg",
					value: "dg",
				},
			],
		};

		if (joinModes.length !== JoinModesOptions.length) {
			finalFilterQuery = {
				...finalFilterQuery,
				joinModes,
			};
		}

		if (discoveryOptions.length !== DiscoveryOptions.length) {
			finalFilterQuery = {
				...finalFilterQuery,
				disc: discoveryOptions,
			};
		}

		if (reportedOptions.length !== CommunityReportedOptions.length) {
			finalFilterQuery = {
				...finalFilterQuery,
				reportedOptions,
			};
		}

		return finalFilterQuery;
	};

	const variablesForSearchAPI = {
		searchData: searchData.filter((searchEl) => searchEl.selectedSearchValue),
		otherFilterQuery: getOtherFilters(),
	};

	const useSearchApi = reportedOptions
		.some((reported) => reported.value === NoOfReportedOptions.ZERO)
		&& reportedOptions.length !== CommunityReportedOptions.length;

	const [getSearchApiData, { data, loading }] = useOptionalSearchApi(getCommunitiesQueries(variables), variablesForSearchAPI, {
		entity: "Stream",
		page: 1,
		useSearchApi,
	});

	useEffect(() => {
		setPage(1);
		setChangeInPage(true);
	}, [joinModes, discoveryOptions, reportedOptions, headers]);

	useEffect(() => {
		if (changeInPage) {
			getSearchApiData();
		}
	}, [page, changeInPage]);

	useEffect(() => {
		if (skipMountEffect.current) {
			skipMountEffect.current = false;
		} else {
			let searchChanged = false;
			if (prevSearchData?.length === searchData.length) {
				prevSearchData.forEach((singleSearch, index) => {
					if (
						singleSearch.selectedSearchValue !== searchData[index].selectedSearchValue
						|| singleSearch.selectedValueData.length !== searchData[index].selectedValueData.length
					) {
						searchChanged = true;
					}
				});
			}
			if (searchChanged) {
				setChangeInPage(true);
				setPage(1);
			}
		}
	}, [searchData]);

	useEffect(() => {
		const items: any[] = [];
		data?.forEach((element: any) => {
			items.push({
				...element,
				creat_ts: element?.creat_ts
					? moment(new Date(element.creat_ts)).format(DATE_FORMAT) : strings("CommunitySearchPage.not_available"),
				modif_ts: element?.modif_ts
					? moment(new Date(element.modif_ts)).format(DATE_FORMAT) : strings("CommunitySearchPage.not_available"),
				reported_ts: element?.reported_ts
					? moment(new Date(element.reported_ts)).format(DATE_FORMAT) : strings("CommunitySearchPage.not_available"),
			});
		});
		setCommunitiesData(items);
		setChangeInPage(false);
	}, [data]);

	const applyFilter = (items: TableHeader[]) => {
		setHeaders(items);
	};

	const applyDiscoveryFilter = (items: TableHeader[]) => {
		setDiscoveryOptions(items);
	};

	const applyJoinModeFilter = (items: TableHeader[]) => {
		setJoinModes(items);
	};

	const goToCommunityDetailScreen = (item: any) => {
		navigate({
			pathname: AUTH_URL + COMMUNITIES_DETAILS_PAGE,
			search: `?${createSearchParams({ communityId: item?.streamId })}`,
		});
	};

	const applyReportedFilter = (items: TableHeader[]) => {
		setReportedOptions(items);
	};

	const showConfirmationModal = (item: any) => {
		setSelectedCommunity(item);
		setIsVisible(true);
	};

	const closeModal = () => {
		setIsVisible(false);
		setSelectedCommunity(null);
	};

	const onCancelButton = () => {
		setIsVisible(false);
	};

	const updateSearchDropdownItems = (items: SearchBarElementInterface[]) => {
		setSearchData(items);
	};

	const [deleteCommunityQuery, { loading: isCommunityDeleteLoading }] = useQuery<Array<Object>>("", {
		formatDataKey: "stream",
		isMutation: true,
	});

	const deleteCommunity = () => {
		deleteCommunityQuery({
			mutation: deleteStreamQuery({ fields: StreamTableHeaders, streamId: selectedCommunity?.streamId }),
		});
		onCancelButton();
		getSearchApiData();
	};

	const goToCommunityDetailScreenWithReportedCount = (item: any) => {
		navigate({
			pathname: AUTH_URL + COMMUNITIES_DETAILS_PAGE,
			search: `?${createSearchParams({ communityId: item?.streamId, reportedCount: item.nb_rep })}`,
		});
	};

	const renderColumn = (props: { item: any; header: any; }) => {
		const { item, header } = props;
		if (!header || !item) {
			return null;
		}
		return (
			<button
				type="button"
				className="w-full truncate underline text-blue-600 hover:text-blue-800 "
				onClick={() => goToCommunityDetailScreenWithReportedCount(item)}
			>
				{item[header.value] || ""}
			</button>
		);
	};

	return (
		<div ref={wrapperRef}>
			<Hero title={strings("CommunitySearchPage.hero_title")} pageHasTable />
			<div data-testid="search-card" className="bg-white p-5 rounded-lg -mt-24 mx-5 flex flex-col mb-5">
				<Accordion
					header={(
						<div className="flex flex-row justify-evenly items-center">
							<h5 className="mb-1">
								{strings("CommunitySearchPage.search")}
							</h5>
							<div className="pl-2">
								<TooltipHelper
									title={strings("UserSearchPage.search_helper_text")}
									placement={width < 769 ? "bottom" : "right"}
								/>
							</div>
						</div>
					)}
					content={(
						<div>
							<SearchBarContainer
								dropdownItems={SearchColumns}
								handleData={updateSearchDropdownItems}
								data={searchData}
							/>
						</div>
					)}
					defaultOpen
				/>
				<Accordion
					header={(
						<h5 className="mb-1">
							{strings("UserSearchPage.filter")}
						</h5>
					)}
					content={(
						<div
							data-testid="community-filter"
							className="grid grid-cols-1 xs:grid-cols-2-max-20 sm:grid-cols-3-max-20 lg:grid-cols-4-max-20 gap-2 lg:gap-4"
						>
							<Filter
								id="column-filters"
								data={StreamTableHeaders}
								filterName={strings("CommunitySearchPage.table_title")}
								applyFilter={applyFilter}
								selectedData={headers}
								showTitle={false}
								local_storage_key="community-headers"
							/>
							<Filter
								id="joinmodes-filter"
								data={JoinModesOptions}
								filterName={strings("CommunitySearchPage.joinModes")}
								applyFilter={applyJoinModeFilter}
								selectedData={joinModes}
								showTitle={false}
							/>
							<Filter
								id="disc-filter"
								data={DiscoveryOptions}
								filterName={strings("CommunitySearchPage.disc")}
								applyFilter={applyDiscoveryFilter}
								selectedData={discoveryOptions}
								showTitle={false}
							/>
							<Filter
								id="reported-filter"
								data={CommunityReportedOptions}
								filterName={strings("CommunitySearchPage.no_of_reported")}
								applyFilter={applyReportedFilter}
								selectedData={reportedOptions}
								showTitle={false}
							/>
						</div>
					)}
					defaultOpen
				/>
			</div>
			<div className="bg-white p-5 rounded-lg my-5 mx-5">
				<div data-testid="community-data-table" className="my-0">
					<Table
						header={headers}
						data={communitiesData}
						showHeader
						isLoading={loading}
						onItemClick={goToCommunityDetailScreen}
						onClickDelete={showConfirmationModal}
						showActionButtons
						pagination={{
							onPageChange: (num) => {
								setChangeInPage(true);
								setPage(num);
							},
							hidePageNumber: true,
							scrollOnPageChange: true,
							page,
						}}
						CustomColumnComponent={renderColumn}
					/>
				</div>
				<div data-testid="delete-modal">
					<ConfirmationModal
						id="community-delete"
						isVisible={isVisible}
						title={strings("CommunitySearchPage.delete_community")}
						message={`${strings("CommunitySearchPage.delete_community_message")}\`${selectedCommunity?.name}\`?`}
						ok_button_title={strings("CommunitySearchPage.yes")}
						ok_button_function={deleteCommunity}
						cancel_button_title={strings("CommunitySearchPage.no")}
						cancel_button_function={onCancelButton}
						onClose={closeModal}
						isOkLoading={isCommunityDeleteLoading}
					/>
				</div>
			</div>
		</div>
	);
}
