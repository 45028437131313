import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { usePrevious } from "react-use";
import {
	createSearchParams, useLocation, useNavigate, useSearchParams,
} from "react-router-dom";
import { cu } from "@credo/utilities";
import Filter from "../../gen-comp/Filter";
import Hero from "../../gen-comp/Hero";
import Table from "../../gen-comp/table";
import { useQuery } from "../../hooks/graphql/useQuery";
import { strings } from "../../i18n/config";
import {
	DatabaseFields,
	NoOfReportedOptions, SearchBarElementInterface, SnackBarTypeOptions, TableHeader,
} from "../../utils/types";
import { DATE_FORMAT } from "../../utils/utils";
import {
	IsSharedFilterList,
	ModeFilterList,
	PostMadeByFilterList,
	PostReportedOptions,
	PostTableHeaders,
	SearchColumns,
} from "./constants";
import ConfirmationModal from "../../gen-comp/ConfirmationModal";
import { successRETCD } from "../../api";
import Accordion from "../../gen-comp/Accordion";
import SearchBarContainer from "../../gen-comp/SearchBar/SearchBarContainer";
import TooltipHelper from "../../gen-comp/TooltipHelper";
import useDimensions from "../../hooks/useDimensions";
import { deletePostQuery, getPostsQueries, GetPostVariables } from "./queries";
import { PAGINATION_LIMIT } from "../../utils/constants";
import { getPostMadeQuery, getSomeFilters } from "./queryBuilders";
import useOptionalSearchApi from "../../hooks/useOptionalSearchApi";
import { GetWhereClauseVariables } from "../../utils/getWhereClause";
import ToastMsg from "../../gen-comp/ToastMsg";
import {
	AUTH_URL, COMMUNITIES_DETAILS_PAGE, POST_DETAILS_PAGE, USER_DETAILS_PAGE,
} from "../../routes/constants";

export default function PostSearchPage() {
	const location = useLocation();
	const navigate = useNavigate();
	// to skip the function call on first mount in useEffect
	const skipMountEffect = useRef<boolean>(true);

	const [searchParams] = useSearchParams();
	const searchDataParams = searchParams.get("searchData");
	const headerParams = searchParams.get("headers");
	const modesParams = searchParams.get("mode");
	const isSharedParams = searchParams.get("is_shared");
	const postMadeByParams = searchParams.get("post_made_by");
	const reportedParams = searchParams.get("reported");

	// eslint-disable-next-line no-nested-ternary
	const [headers, setHeaders] = useState<TableHeader[]>(headerParams ? JSON.parse(headerParams)
		: localStorage.getItem("post-headers") ? JSON.parse(localStorage.getItem("post-headers") || "")
			: PostTableHeaders.filter((e) => e.isDefault));
	const [modeFilterList, setModeFilterList] = useState<TableHeader[]>(modesParams ? JSON.parse(modesParams) : ModeFilterList);
	const [sharedFilterList, setSharedFilterList] = useState<any>(isSharedParams ? JSON.parse(isSharedParams) : IsSharedFilterList);
	const [postMadeFilterList, setPostMadeFilterList] = useState<TableHeader[]>(
		postMadeByParams ? JSON.parse(postMadeByParams) : PostMadeByFilterList,
	);
	const [postData, setPostData] = useState<any>([]);
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const [searchData, setSearchData] = useState<SearchBarElementInterface[]>(searchDataParams ? JSON.parse(searchDataParams) : []);
	const [selectedPost, setSelectedPost] = useState<any>(null);
	const [reportedOptions, setReportedOptions] = useState<TableHeader[]>(
		reportedParams ? JSON.parse(reportedParams) : PostReportedOptions,
	);
	const [, width] = useDimensions();
	const prevSearchData = usePrevious(searchData);
	const [page, setPage] = useState(1);
	const [changeInPage, setChangeInPage] = useState<boolean>(true);

	useEffect(() => {
		const paramString = `searchData=${JSON.stringify(searchData)}`
			+ `&headers=${JSON.stringify(headers)}`
			+ `&mode=${JSON.stringify(modeFilterList)}`
			+ `&is_shared=${JSON.stringify(sharedFilterList)}`
			+ `&post_made_by=${JSON.stringify(postMadeFilterList)}`
			+ `&reported=${JSON.stringify(reportedOptions)}`;
		if (window) {
			window.history.replaceState(null, "", `${location.pathname}?${paramString}`);
		}
	}, [searchData, headers, modeFilterList, reportedOptions, sharedFilterList, postMadeFilterList]);

	useEffect(() => {
		if (searchDataParams) {
			setSearchData(JSON.parse(searchDataParams));
		}
		if (headerParams) {
			setHeaders(JSON.parse(headerParams));
		}
		if (modesParams) {
			setModeFilterList(JSON.parse(modesParams));
		}
		if (isSharedParams) {
			setSharedFilterList(JSON.parse(isSharedParams));
		}
		if (postMadeByParams) {
			setPostMadeFilterList(JSON.parse(postMadeByParams));
		}
		if (reportedParams) {
			setReportedOptions(JSON.parse(reportedParams));
		}
	}, []);

	const applyFilter = (items: TableHeader[]) => {
		setHeaders(items);
	};

	const applyPostSharedFilter = (items: TableHeader[]) => {
		setSharedFilterList(items);
	};

	const applyModeFilter = (items: TableHeader[]) => {
		setModeFilterList(items);
	};

	const applyPostMadeFilter = (items: TableHeader[]) => {
		setPostMadeFilterList(items);
	};

	const applyReportedFilter = (items: TableHeader[]) => {
		setReportedOptions(items);
	};

	const variables: GetPostVariables = {
		headers,
		searchData,
		reportedOptions,
		postMadeFilter: getPostMadeQuery(postMadeFilterList),
		otherFilters: getSomeFilters(modeFilterList, sharedFilterList),
		offset: (page - 1) * PAGINATION_LIMIT,
	};

	const updateSearchDropdownItems = (items: SearchBarElementInterface[]) => {
		setSearchData(items);
	};

	const getOtherFilters = () => {
		let finalFilterQuery: GetWhereClauseVariables["otherFilterQuery"] = {
			postType: [
				{
					title: "p",
					value: "p",
				},
			],
		};

		if (modeFilterList.length !== ModeFilterList.length) {
			finalFilterQuery = {
				...finalFilterQuery,
				mode: modeFilterList,
			};
		}

		if (reportedOptions.length !== PostReportedOptions.length) {
			finalFilterQuery = {
				...finalFilterQuery,
				nb_rep: reportedOptions,
			};
		}

		if (sharedFilterList.length !== IsSharedFilterList.length) {
			finalFilterQuery = {
				...finalFilterQuery,
				is_shared: sharedFilterList,
			};
		}

		if (postMadeFilterList.length !== PostMadeByFilterList.length) {
			finalFilterQuery = {
				...finalFilterQuery,
				created_by: postMadeFilterList,
			};
		}

		return finalFilterQuery;
	};

	const variablesForSearchAPI = {
		searchData: searchData.filter((searchEl) => searchEl.selectedSearchValue),
		otherFilterQuery: getOtherFilters(),
	};

	const checkItemPresentInSeachField = (item: string) => searchData.findIndex(
		(e) => e.selectedValueData.find((ele) => ele.value === item),
	) !== -1;

	const isSearchValueChanged = () => searchData.findIndex((e) => e.selectedSearchValue !== "") !== -1;

	const useSearchApi = (reportedOptions
		.some((reported) => reported.value === NoOfReportedOptions.ZERO)
		&& reportedOptions.length !== PostReportedOptions.length)
		|| (sharedFilterList.some((shared: any) => shared.value === null)
			&& sharedFilterList.length !== IsSharedFilterList.length)
		|| (checkItemPresentInSeachField("postText") && isSearchValueChanged())
		|| (checkItemPresentInSeachField("postTitle") && isSearchValueChanged());

	const [getSearchApiData, { data, loading }] = useOptionalSearchApi(getPostsQueries(variables), variablesForSearchAPI, {
		entity: "Post",
		page: 1,
		useSearchApi,
		formatDataKey: "post",
	});

	useEffect(() => {
		setPage(1);
		setChangeInPage(true);
	}, [modeFilterList, postMadeFilterList, reportedOptions, sharedFilterList, headers]);

	useEffect(() => {
		if (changeInPage) {
			getSearchApiData();
		}
	}, [page, changeInPage]);

	useEffect(() => {
		if (skipMountEffect.current) {
			skipMountEffect.current = false;
		} else {
			let searchChanged = false;
			if (prevSearchData && prevSearchData?.length >= searchData.length) {
				prevSearchData.forEach((singleSearch, index) => {
					if (
						singleSearch.selectedSearchValue !== searchData[index]?.selectedSearchValue
						|| singleSearch.selectedValueData.length !== searchData[index]?.selectedValueData.length
					) {
						searchChanged = true;
					}
				});
			}
			if (searchChanged) {
				setChangeInPage(true);
				setPage(1);
			}
		}
	}, [searchData]);

	useEffect(() => {
		const items: any[] = [];
		data?.forEach((element: any) => {
			items.push({
				...element,
				creat_ts: element.creat_ts
					? moment(new Date(element.creat_ts)).format(DATE_FORMAT) : strings("PostSearchPage.not_available"),
				shared_modif_ts: element.shared_modif_ts
					? moment(new Date(element.shared_modif_ts)).format(DATE_FORMAT) : strings("PostSearchPage.not_available"),
				edit_ts: element.edit_ts
					? moment(new Date(element.edit_ts)).format(DATE_FORMAT) : strings("PostSearchPage.not_available"),
				article_date: element.article_date
					? moment(new Date(element.article_date)).format(DATE_FORMAT) : strings("PostSearchPage.not_available"),
				shared_creat_ts: element.shared_creat_ts
					? moment(new Date(element.shared_creat_ts)).format(DATE_FORMAT) : strings("PostSearchPage.not_available"),
				shared_edit_ts: element.shared_edit_ts
					? moment(new Date(element.shared_edit_ts)).format(DATE_FORMAT) : strings("PostSearchPage.not_available"),
				reported_ts: element?.reported_ts
					? moment(new Date(element.reported_ts)).format(DATE_FORMAT) : strings("PostSearchPage.not_available"),
			});
		});
		setPostData(items);
		setChangeInPage(false);
	}, [data]);

	const [deletePostMutation, { loading: isPostDeleteLoading }] = useQuery<Array<Object>>("", {
		formatDataKey: "post",
		isMutation: true,
	});

	const onCancelButton = () => {
		setShowDeleteModal(false);
	};

	const handleDeletePost = async () => {
		await deletePostMutation({
			mutation: deletePostQuery({ postUuid: selectedPost?.postUuid, fields: PostTableHeaders }),
		}).then((response) => {
			if (response.retcd === successRETCD) {
				toast(
					<ToastMsg
						message={strings("PostSearchPage.delete_success")}
						showButton
						type={SnackBarTypeOptions.SUCCESS}
					/>,
				);
			} else {
				toast(
					<ToastMsg
						message={strings("PostSearchPage.something_went_wrong")}
						showButton
						type={SnackBarTypeOptions.ERROR}
					/>,
				);
			}
		}).catch((error) => {
			toast(
				<ToastMsg
					message={strings("PostSearchPage.something_went_wrong", { error })}
					showButton
					type={SnackBarTypeOptions.ERROR}
				/>,
			);
		});
		onCancelButton();
		getSearchApiData();
	};

	const closeModal = () => {
		setShowDeleteModal(false);
		setSelectedPost(null);
	};

	const showConfirmationModal = (item: any) => {
		setSelectedPost(item);
		setShowDeleteModal(true);
	};

	const goToPostDetailScreen = (postUuid: string, nb_rep: any) => {
		navigate({
			pathname: AUTH_URL + POST_DETAILS_PAGE,
			search: `?${createSearchParams({ postUuid, reportedCount: nb_rep })}`,
		});
	};

	const redirectToUserDetailsPage = (item: any) => {
		window.open(`${AUTH_URL}${USER_DETAILS_PAGE}?${createSearchParams({
			prof_id: item?.prof_id,
			mode: item?.mode,
			isNewTab: "y",
		})}`, "_blank");
	};

	const onClickColumnItem = (item: any) => {
		switch (item?.value) {
			case DatabaseFields.PROFID:
			case DatabaseFields.SHARED_PROFID:
				redirectToUserDetailsPage({ prof_id: item?.[item?.value], mode: item?.mode });
				break;
			case DatabaseFields.SHARED_POST_STREAMID:
				window.open(`${AUTH_URL}${COMMUNITIES_DETAILS_PAGE}?${createSearchParams({
					communityId: item?.shared_post_streamId,
					isNewTab: "y",
				})}`, "_blank");
				break;
			case DatabaseFields.MEDIAOBJ_LINK_IMGURL:
			case DatabaseFields.MEDIAOBJ_LINK_URL:
				window.open(item?.[item?.value], "_blank");
				break;
			case DatabaseFields.IMG_BUCKETRELATIVEREFPATH0:
			case DatabaseFields.IMG_BUCKETRELATIVEREFPATH1:
			case DatabaseFields.IMG_BUCKETRELATIVEREFPATH2:
			case DatabaseFields.IMG_BUCKETRELATIVEREFPATH3:
			case DatabaseFields.VIDEO_BUCKETRELATIVEREFPATH1:
				window.open(cu.buildSourceUrlImage(item?.[item?.value]) || "", "_blank");
				break;
			case DatabaseFields.SHARED_POSTUUID:
			case DatabaseFields.POSTUUID:
				goToPostDetailScreen(item?.[item?.value], item?.nb_rep);
				break;
			default:
				break;
		}
	};

	const onClickOnReportedCount = (item: any) => {
		if (item) {
			goToPostDetailScreen(item?.postUuid, item?.nb_rep);
		}
	};

	const renderColumn = (props: { item: any; header: any; }) => {
		const { item, header } = props;
		if (!header || !item) {
			return null;
		}
		return (
			<button
				type="button"
				className="w-full truncate underline text-blue-600 hover:text-blue-800 "
				onClick={() => onClickOnReportedCount(item)}
			>
				{item[header.value] || ""}
			</button>
		);
	};

	return (
		<div>
			<Hero title={strings("PostSearchPage.hero_title")} pageHasTable />
			<div data-testid="search-card" className="bg-white p-5 rounded-lg -mt-24 mx-5 flex flex-col mb-5">
				<Accordion
					header={(
						<div className="flex flex-row justify-evenly items-center">
							<h5 className="mb-1">
								{strings("PostSearchPage.search")}
							</h5>
							<div className="pl-2">
								<TooltipHelper
									title={strings("UserSearchPage.search_helper_text")}
									placement={width < 769 ? "bottom" : "right"}
								/>
							</div>
						</div>
					)}
					content={(
						<div>
							<SearchBarContainer
								dropdownItems={SearchColumns}
								handleData={updateSearchDropdownItems}
								data={searchData}
							/>
						</div>
					)}
					defaultOpen
				/>
				<Accordion
					header={(
						<h5 className="mb-1">
							{strings("PostSearchPage.filter")}
						</h5>
					)}
					content={(
						<div className="grid grid-cols-1 xs:grid-cols-2-max-20 sm:grid-cols-3-max-20 lg:grid-cols-4-max-20 gap-2 lg:gap-4">
							<Filter
								data={PostTableHeaders}
								filterName={strings("PostSearchPage.table_title")}
								applyFilter={applyFilter}
								selectedData={headers}
								showTitle={false}
								local_storage_key="post-headers"
								id="column-filters"
							/>
							<Filter
								data={IsSharedFilterList}
								filterName={strings("PostSearchPage.post_shared")}
								applyFilter={applyPostSharedFilter}
								selectedData={sharedFilterList}
								showTitle={false}
							/>
							<Filter
								data={ModeFilterList}
								filterName={strings("PostSearchPage.post_created_mode")}
								applyFilter={applyModeFilter}
								selectedData={modeFilterList}
								showTitle={false}
							/>
							<Filter
								data={PostMadeByFilterList}
								filterName={strings("PostSearchPage.post_made_by")}
								applyFilter={applyPostMadeFilter}
								selectedData={postMadeFilterList}
								showTitle={false}
							/>
							<Filter
								id="reported-filter"
								data={PostReportedOptions}
								filterName={strings("PostSearchPage.no_of_reported")}
								applyFilter={applyReportedFilter}
								selectedData={reportedOptions}
								showTitle={false}
							/>
						</div>
					)}
					defaultOpen
				/>
			</div>
			<div className="bg-white p-5 rounded-lg my-5 mx-5">
				<div data-testid="post-search-page__wrapper" className="my-0">
					<Table
						header={headers}
						data={postData}
						showHeader
						isLoading={loading}
						onClickDelete={showConfirmationModal}
						onItemClick={onClickColumnItem}
						showActionButtons
						pagination={{
							onPageChange: (num) => {
								setChangeInPage(true);
								setPage(num);
							},
							hidePageNumber: true,
							scrollOnPageChange: true,
							page,
						}}
						CustomColumnComponent={renderColumn}
					/>
				</div>
				<ConfirmationModal
					id="post-delete"
					isVisible={showDeleteModal}
					title={strings("PostSearchPage.delete_post")}
					message={`${strings("PostSearchPage.delete_post_message", { postUuid: selectedPost?.postUuid })}`}
					ok_button_title={strings("PostSearchPage.yes")}
					ok_button_function={handleDeletePost}
					cancel_button_title={strings("PostSearchPage.no")}
					cancel_button_function={onCancelButton}
					onClose={closeModal}
					isOkLoading={isPostDeleteLoading}
					okLoadingText={strings("PostSearchPage.deleting")}
				/>
			</div>
		</div>
	);
}
