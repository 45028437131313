/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGAttributes } from "react";

const Unfollow = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--primary)",
	} = props;
	return (
		<svg
			width="16px"
			height="18px"
			viewBox="0 0 16 18"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<g id="Streams---CT---190723" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-icons" transform="translate(-376.000000, -897.000000)" stroke={stroke}>
					<g id="stream_unfollow" transform="translate(376.000000, 897.000000)">
						<path
							d="M6.5341125,0.5625 C4.96501122,0.5625 3.68872397,1.82393436 3.68872397,3.375 C3.68872397,4.92606564 4.96501122,6.1875 6.5341125,6.1875 C8.10321378,6.1875 9.37950103,4.92606564 9.37950103,3.375 C9.37950103,1.82393436 8.10321378,0.5625 6.5341125,0.5625 Z"
							id="Stroke-1"
						/>
						<path
							d="M15.4501875,11.8209375 C15.4501875,13.6220625 13.9899375,15.0811875 12.1899375,15.0811875 C10.3888125,15.0811875 8.9296875,13.6220625 8.9296875,11.8209375 C8.9296875,10.0198125 10.3888125,8.5606875 12.1899375,8.5606875 C13.9899375,8.5606875 15.4501875,10.0198125 15.4501875,11.8209375 Z"
							id="Stroke-3"
						/>
						<line x1="10.525275" y1="11.8209375" x2="13.85415" y2="11.8209375" id="Stroke-5" strokeLinecap="round" />
						<path
							d="M10.4931,9.135675 C10.0881,8.54505 9.70785,8.134425 9.4491,7.974675 L9.2511,7.850925 L9.05085,7.988175 L6.5331,9.968175 L4.0176,7.988175 L3.81735,7.850925 L3.61935,7.974675 C2.808225,8.4753 0.8226,11.408175 0.573975,14.677425 C0.573975,14.677425 2.2356,17.43705 6.5331,17.43705 C9.758475,17.43705 11.49885,15.88455 12.1671,15.1083"
							id="Stroke-7"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default Unfollow;
