import * as React from "react"
import {SVGAttributes} from "react";

const SvgComponent = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { stroke = "var(--primary)" } = props;
	return (
		<svg viewBox="0 0 33 33" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
			<g id="glyph-deposit" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="download_FILL0_wght400_GRAD0_opsz48" transform="translate(2, 2)" fill={stroke} fillRule="nonzero">
					<path d="M2.71875,29 C1.99375,29 1.359375,28.728125 0.815625,28.184375 C0.271875,27.640625 0,27.00625 0,26.28125 L0,19.8015625 L2.71875,19.8015625 L2.71875,26.28125 L26.28125,26.28125 L26.28125,19.8015625 L29,19.8015625 L29,26.28125 C29,27.00625 28.728125,27.640625 28.184375,28.184375 C27.640625,28.728125 27.00625,29 26.28125,29 L2.71875,29 Z M14.5,22.0671875 L5.7546875,13.321875 L7.703125,11.3734375 L13.140625,16.8109375 L13.140625,0 L15.859375,0 L15.859375,16.8109375 L21.296875,11.3734375 L23.2453125,13.321875 L14.5,22.0671875 Z" id="glyph-desposit-Shape"></path>
				</g>
			</g>
		</svg>
	)
}

export default SvgComponent
