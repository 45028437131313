import React, { useEffect, useMemo, useState } from "react";
import { dbg, LogMgr, useEvtMgrListener } from "@credo/utilities";
import { useAtom } from "jotai";
import { emittedHintsAtom, removedHintsAtom } from "@credo/store";
import {
	HintActions, HintsData, HintsDataParsed, HintsEventMessage,
} from "./types";
import { Hint } from "./hint";
import { HintMgr } from "./HintMgr";
import { EventConst } from "../common";

interface HintWrapperProps {
	data: HintsData[];
}

/**
 * The idea is to map the hints based on the
 * availability of the target components if the
 * target components are present ie we get their
 * DOMRect data, then we can use the same to
 * position the Hint component in the UI.
 * */
// eslint-disable-next-line import/prefer-default-export
export const HintWrapper: React.FC<HintWrapperProps> = (props: HintWrapperProps) => {
	// Maintaining the state of current hints which are already triggered
	const [emittedHints, setEmittedHints] = useAtom(emittedHintsAtom);
	// Already removed hints, suppose there are hints user has already seen and removed
	const [removedHints, setRemovedHints] = useAtom(removedHintsAtom);
	// Just to re-render the component when the body updates to re-calculate the dimensions
	const [updateId, setUpdateId] = useState<string>("");

	const {
		data,
	} = props;

	const parsedData: HintsDataParsed = useMemo(() => HintMgr.getParsedData(data), [data]);

	/**
	 * Suppose there are some elements which are updated when hints are
	 * being rendered and then the target get shifted then we need to
	 * get the new location of the targets and instead of adding the
	 * observer to Hint component, many observers will be added to the
	 * dom. So we have to add it here in the wrapper and only single
	 * observer will be added for hints.
	 * */
	useEffect(() => {
		if (document && emittedHints.length > 0) {
			const observer = new MutationObserver(HintMgr.domUpdated);
			// Options for the observer (which mutations to observe)
			const config = { attributes: true, childList: true, subtree: true };
			observer.observe(document.body, config);
		}
	}, [emittedHints]);

	// Get the triggered hint event messages
	const handleTriggeredEvent = (msg: HintsEventMessage) => {
		switch (msg.action) {
			case HintActions.SHOW:
				if (msg.trigger) {
					setEmittedHints((prevState) => {
						if (!prevState.includes(msg.trigger)) {
							return [...prevState, msg.trigger];
						}
						return prevState;
					});
				}
				break;
			case HintActions.REMOVE:
				if (msg?.id) {
					// saving removed hints in client metaData in string format and '&' as a separator
					// we are spilitting the string using '&' and then checking whether hint has already
					// been seen or not by the user
					const updatedRemovedHints = `${removedHints}&${msg.id}`;
					setRemovedHints(updatedRemovedHints);
				}
				break;
			case HintActions.REMOVE_TRIGGER:
				if (msg?.trigger) {
					setEmittedHints((prevState) => prevState.filter((element) => element !== msg.trigger));
				}
				break;
			case HintActions.BODY_UPDATED:
				setUpdateId(String(Math.random()));
				break;
			default: break;
		}
	};

	useEvtMgrListener(EventConst.hintActions, handleTriggeredEvent);

	const renderHints = () => {
		if (dbg) {
			LogMgr.mydbg("HintWrapper: emittedHints ", emittedHints);
		}
		if (emittedHints.length < 1) return null;
		return emittedHints.map((hintString) => {
			if (dbg) {
				LogMgr.mydbg("HintWrapper: hints data ", parsedData);
			}
			if (!(parsedData.get(hintString)) && ((parsedData.get(hintString)?.length ?? 0) < 1)) return null;
			const removedHintsA = removedHints?.split("&");
			if (dbg) {
				LogMgr.mydbg("HintWrapper: removedHints array ", removedHintsA);
			}
			return parsedData.get(hintString)?.map((hint: HintsData) => {
				if (dbg) {
					LogMgr.mydbg(`HintWrapper: is the hint with ${hint.id} removed? `, removedHintsA.includes(hint.id));
				}
				// if the hint is removed already no need to render it
				if (removedHintsA.includes(hint.id)) return null;
				return (
					<Hint
						key={`${hintString}-${hint.id}`}
						data={hint}
						updateId={updateId}
					/>
				);
			});
		});
	};

	return (
		<React.Fragment>
			{renderHints()}
		</React.Fragment>
	);
};
