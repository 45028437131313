/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGAttributes } from "react";

const StreamEgoOnlyLarge = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--primary)",
	} = props;
	return (
		<svg
			width="66px"
			height="59px"
			viewBox="0 0 66 59"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<g id="Streams---CT---190723" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-icons" transform="translate(-247.000000, -369.000000)" stroke={stroke}>
					<g id="stream_ego_only_large" transform="translate(247.000000, 369.000000)">
						<path
							d="M48.8806,11.1961 C49.1626,8.9401 51.0876,7.1941 53.4206,7.1941 C55.9476,7.1941 57.9956,9.2421 57.9956,11.7691 C57.9956,14.2961 55.9476,16.3451 53.4206,16.3451 C53.2256,16.3451 53.0346,16.3331 52.8466,16.3091"
							id="Stroke-1"
						/>
						<line x1="53.4203" y1="4.4119" x2="53.4203" y2="-0.0001" id="Stroke-3" />
						<line x1="60.7737" y1="11.7652" x2="65.1857" y2="11.7652" id="Stroke-5" />
						<line x1="58.6199" y1="6.5656" x2="61.7399" y2="3.4456" id="Stroke-7" />
						<line x1="58.6199" y1="16.9648" x2="61.7399" y2="20.0848" id="Stroke-9" />
						<line x1="45.1011" y1="3.446" x2="48.2211" y2="6.566" id="Stroke-11" />
						<path
							d="M32.4287,20.6347 L12.4187,20.6347 C11.1437,20.6347 10.1097,21.6687 10.1097,22.9447 L10.1097,33.7667 L10.0497,33.7987 L0.9887,39.1107 L10.1097,44.4507 L10.1097,55.2777 C10.1097,56.5537 11.1437,57.5877 12.4187,57.5877 L49.4897,57.5877 C50.7657,57.5877 51.7997,56.5537 51.7997,55.2777 L51.7997,25.7937"
							id="Stroke-13"
						/>
						<path
							d="M53.5517,19.9695 C53.5517,25.8115 48.8157,30.5475 42.9737,30.5475 C37.1317,30.5475 32.3957,25.8115 32.3957,19.9695 C32.3957,14.1275 37.1317,9.3915 42.9737,9.3915 C48.8157,9.3915 53.5517,14.1275 53.5517,19.9695 Z"
							id="Stroke-15"
						/>
						<path
							d="M42.9776,18.9795 C44.8386,18.9795 46.3526,16.9975 46.3526,15.1355 C46.3526,13.2745 44.8386,11.7605 42.9776,11.7605 C41.1176,11.7605 39.6036,13.2745 39.6036,15.1355 C39.6036,16.9975 41.1176,18.9795 42.9776,18.9795 Z"
							id="Stroke-17"
						/>
						<path
							d="M37.0764,25.4899 C37.3504,22.0299 38.8854,20.6299 39.7754,20.0989 L39.9934,19.9699 L40.2134,20.1139 L42.9774,22.1879 L45.7424,20.1139 L45.9624,19.9699 L46.1794,20.0989 C47.0704,20.6299 48.6044,22.0299 48.8784,25.4899 L48.8804,25.7799 C47.3634,27.2409 45.2794,28.1439 42.9784,28.1439 C40.6764,28.1439 38.5924,27.2399 37.0744,25.7789 L37.0764,25.4899 Z"
							id="Stroke-19"
						/>
						<path
							d="M33.3219,39.7996 C33.3219,41.4366 31.9949,42.7636 30.3579,42.7636 C28.7199,42.7636 27.3929,41.4366 27.3929,39.7996 C27.3929,38.1626 28.7199,36.8356 30.3579,36.8356 C31.9949,36.8356 33.3219,38.1626 33.3219,39.7996 Z"
							id="Stroke-21"
						/>
						<path
							d="M23.4397,39.7996 C23.4397,41.4366 22.1127,42.7636 20.4757,42.7636 C18.8377,42.7636 17.5107,41.4366 17.5107,39.7996 C17.5107,38.1626 18.8377,36.8356 20.4757,36.8356 C22.1127,36.8356 23.4397,38.1626 23.4397,39.7996 Z"
							id="Stroke-23"
						/>
						<path
							d="M43.2042,39.7996 C43.2042,41.4366 41.8772,42.7636 40.2402,42.7636 C38.6022,42.7636 37.2752,41.4366 37.2752,39.7996 C37.2752,38.1626 38.6022,36.8356 40.2402,36.8356 C41.8772,36.8356 43.2042,38.1626 43.2042,39.7996 Z"
							id="Stroke-25"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default StreamEgoOnlyLarge;
