/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { classNames, Consts, ProfilePicture } from "@credo/ui-components";
import {
	AppMode, cu, PostType, RequestType, YesNoOptions,
} from "@credo/utilities";
import React from "react";
import { createSearchParams } from "react-router-dom";
import { strings } from "../../../i18n/config";
import {
	AUTH_URL, COMMENT_DETAILS_PAGE, COMMUNITIES_DETAILS_PAGE, POST_DETAILS_PAGE, USER_DETAILS_PAGE,
} from "../../../routes/constants";
import { UserActivitiesText } from "../../../utils/types";

interface UserActivityItemProps {
	item: any;
}

const enum ItemType {
	Post = "Post",
	Comment = "Comment",
	Stream = "Stream",
	User = "User"
}

export default function UserActivityItem({
	item,
}: UserActivityItemProps) {
	const handleStreamName = (streamName: string) => {
		if (streamName === Consts.main_wall_credo_stream_id) {
			return strings("AppUtils.credo_wall");
		}
		if (streamName === Consts.main_wall_ego_stream_id) {
			return strings("AppUtils.ego_wall");
		}
		return streamName;
	};

	const redirectUser = (itemType: ItemType) => {
		switch (itemType) {
			case ItemType.Post:
				window.open(`${AUTH_URL}${POST_DETAILS_PAGE}?${createSearchParams({
					postUuid: item?.srcPostUuid,
				})}`, "_blank");
				break;
			case ItemType.Comment:
				window.open(`${AUTH_URL}${COMMENT_DETAILS_PAGE}?${createSearchParams({
					commentUuid: item?.commentUuid,
					postUuid: item?.src_postUuid,
					isNewTab: YesNoOptions.YES,
				})}`, "_blank");
				break;
			case ItemType.Stream:
				window.open(`${AUTH_URL}${COMMUNITIES_DETAILS_PAGE}?${createSearchParams({
					communityId: item?.streamId || "",
					isNewTab: YesNoOptions.YES,
				})}`, "_blank");
				break;
			default:
				window.open(`${AUTH_URL}${USER_DETAILS_PAGE}?${createSearchParams({
					prof_id: item?.profId || "",
					mode: item?.mode || AppMode.EGO,
					isNewTab: YesNoOptions.YES,
				})}`, "_blank");
		}
	};

	const redirectAsPerLinks = (item: any) => {
		if (!cu.isSet(item)) {
			return;
		}
		if (!item.entityId) {
			if (item?.postType === PostType.CreatePost
				|| item?.postType === PostType.NewPost
				|| item?.postType === PostType.EmojiReactionPost) {
				redirectUser(ItemType.Post);
			} else if (item?.postType === PostType.CreateComment
				|| item?.postType === PostType.EmojiReactionComment) {
				redirectUser(ItemType.Comment);
			} else if (item?.postType === PostType.NewStream
				|| item?.postType === PostType.FollowStream
				|| item?.postType === PostType.DeleteStream
				|| item?.postType === PostType.FollowStream
				|| item?.postType === PostType.JoinStream
				|| item?.postText === UserActivitiesText.Left_Community
				|| item?.postText === UserActivitiesText.Cancelled_Join_Request) {
				redirectUser(ItemType.Stream);
			} else {
				redirectUser(ItemType.User);
			}
		} else if (item?.streamNm && !item?.username && !item?.firstName) {
			redirectUser(ItemType.Stream);
		} else {
			redirectUser(ItemType.User);
		}
	};

	const renderUserName = () => (
		<p
			className="font-medium text-primary text-sm cursor-pointer outline-none text-start break-all px-1"
			onClick={() => redirectUser(ItemType.User)}
		>
			{`${item?.username || `${item?.firstName} ${item?.lastName}`}`}
		</p>
	);

	const renderStreamName = () => (
		<p
			className="font-medium text-primary text-sm cursor-pointer outline-none text-start break-all px-1"
			onClick={() => redirectUser(ItemType.Stream)}
		>
			{handleStreamName(item?.streamNm)}
		</p>
	);

	const renderPostText = () => (
		<p className="text-basic font-thin text-sm leading-4">
			{item?.postText}
		</p>
	);

	const renderCommentPost = () => (
		<div className="flex flex-row justify-start items-center flex-1 flex-wrap">
			<p className="text-basic font-thin text-sm leading-4">
				{item?.postType === PostType.CreatePost ? strings("UserActivityItem.made_a_comment_text") : `${item?.postText} `}
			</p>
			{renderUserName()}
			<p className="text-basic font-thin text-sm leading-4 -pl-1">
				{strings("UserActivityItem.s")}
			</p>
			<p
				className="font-medium text-primary text-sm cursor-pointer outline-none text-start pl-1"
				onClick={() => redirectUser(ItemType.Comment)}
			>
				{
					item?.postType === PostType.CreatePost
						? strings("UserActivityItem.post") : strings("UserActivityItem.comment")
				}
			</p>
		</div>
	);

	const renderCreatedNewPost = () => (
		<div className="flex flex-row justify-start items-center flex-1 flex-wrap">
			{renderPostText()}
			<p
				className="font-medium text-primary text-sm cursor-pointer outline-none text-start break-all px-1"
				onClick={() => redirectUser(ItemType.Post)}
			>
				{strings("UserActivityItem.post")}
			</p>
		</div>
	);

	const renderEmojiReaction = () => {
		const emoCode = JSON.parse(localStorage.getItem("EmojiData") ?? "[]")
			?.find((emo: { code: any; }) => emo.code === item?.emoCode)
			?.emoji ?? strings("UserActivityItem.emoji");
		return (
			<div className="flex flex-row justify-start items-center flex-1 flex-wrap">
				<p className="text-basic font-thin text-sm leading-4">
					{`${item?.postText} ${emoCode} `}
					{strings("UserActivityItem.on")}
				</p>
				{item?.postType === PostType.EmojiReactionPost
					? (
						<p
							className="font-medium text-primary text-sm cursor-pointer outline-none text-start break-all px-1"
							onClick={() => redirectUser(ItemType.Post)}
						>
							{strings("UserActivityItem.post")}
						</p>
					)
					: (
						<p
							className="font-medium text-primary text-sm cursor-pointer outline-none text-start break-all px-1"
							onClick={() => redirectUser(ItemType.Comment)}
						>
							{strings("UserActivityItem.comment")}
						</p>
					)}
			</div>
		);
	};

	const renderAcceptConnectionRequest = () => (
		<div className="flex flex-row justify-start items-center flex-1 flex-wrap">
			{renderPostText()}
			{renderUserName()}
			<p className="text-basic font-thin text-sm leading-4 -pl-1">
				{`${strings("UserActivityItem.s")}${strings("UserActivityItem.conn_req")}`}
			</p>
		</div>
	);

	const renderRejectedConnectionRequest = () => (
		<div className="flex flex-row justify-start items-center flex-1 flex-wrap">
			<p className="text-basic font-thin text-sm leading-4">
				{strings("UserActivityItem.reject")}
			</p>
			{renderUserName()}
			<p className="text-basic font-thin text-sm leading-4 -pl-1">
				{`${strings("UserActivityItem.s")}${strings("UserActivityItem.conn_req")}`}
			</p>
		</div>
	);

	const renderConnectedUser = () => (
		<div className="flex flex-row justify-start items-center flex-1 flex-wrap">
			{renderPostText()}
			{renderUserName()}
		</div>
	);

	const renderFollowedNewStream = () => (
		<div className="flex flex-row justify-start items-center flex-1 flex-wrap">
			{renderPostText()}
			{renderStreamName()}
			<p className="text-basic font-thin text-sm leading-4">
				{strings("UserActivityItem.community")}
			</p>
		</div>
	);

	const renderJoinedNewStream = () => (
		<div className="flex flex-row justify-start items-center flex-1 flex-wrap">
			{renderPostText()}
			{renderStreamName()}
		</div>
	);

	const renderRateExpertise = () => (
		<div className="flex flex-row justify-start items-center flex-1 flex-wrap">
			{renderPostText()}
			{renderUserName()}
			<p className="text-basic font-thin text-sm leading-4">
				{item?.uxpCode ? `\n${item?.uxpCode}: ${item?.rating ? Math.round(item?.rating * 100) : 0}%` : ""}
			</p>
		</div>
	);

	const renderCancelConnectionRequest = () => (
		<div className="flex flex-row justify-start items-center flex-1 flex-wrap">
			<p className="text-basic font-thin text-sm leading-4">
				{`${item?.postText} ${item?.uxpCode ? `[: ${item?.uxpCode} ${Math.round((item?.rating ?? 0) * 100)} ]` : ""}`}
			</p>
			{item?.streamNm && !item?.username && !item?.firstName
				? renderStreamName()
				: renderUserName()}
		</div>
	);

	const renderProfileActivityBasedOnPostType = () => {
		if (!item?.postType) { return null; }
		switch (item?.postType) {
			case PostType.FollowUser:
			case PostType.UnfollowUser:
			case PostType.BlockedUser:
			case PostType.UnconnectUser:
				return renderConnectedUser();

			case PostType.NewStream:
			case PostType.FollowStream:
				return renderFollowedNewStream();

			case PostType.DeleteStream:
				return renderJoinedNewStream();

			case PostType.RateExpertise:
				return renderRateExpertise();

			case PostType.CancelConnectUser:
				return renderCancelConnectionRequest();

			case PostType.CreatePost:
			case PostType.CreateComment:
				return renderCommentPost();

			case PostType.NewPost:
				return renderCreatedNewPost();

			case PostType.EmojiReactionPost:
			case PostType.EmojiReactionComment:
				return renderEmojiReaction();

			default:
				return null;
		}
	};

	const renderOtherContent = () => (
		item?.postType === PostType.ConnectUser
			&& item?.postText === UserActivitiesText.Accepted_Connection_Request
			? renderAcceptConnectionRequest()
			: item?.postType === PostType.ConnectUser
				&& item?.postText === UserActivitiesText.Declined_Connection_Request
				? renderRejectedConnectionRequest()
				: item?.postType === PostType.ConnectUser
					&& item?.reqType === RequestType.ConnectUser
					? renderConnectedUser()
					: (item?.postType === PostType.JoinStream && item?.reqType === RequestType.JoinStream)
						|| item?.postText === UserActivitiesText.Left_Community
						? renderJoinedNewStream()
						: null);

	/**
	 * To handle new types of postType added in the backend
	 * but not integrated in frontend, so we will not display
	 * the item at all if it does not have any content.
	 * */
	if (!(renderOtherContent() || renderProfileActivityBasedOnPostType())) {
		return null;
	}

	if (!item) {
		return null;
	}

	return (
		<div
			key={item?.postUuid}
			className={classNames(
				"w-full flex justify-start items-center",
			)}
		>
			<button
				type="button"
				className={classNames(
					"flex justify-center items-center outline-none",
					"p-3",
					"h-16 w-16",
				)}
				onClick={() => redirectAsPerLinks(item)}
			>
				{item?.streamPicUrl ? (
					<ProfilePicture
						profilePicUrl={item?.streamPicUrl ? (cu.buildSourceUrlImage(item?.streamPicUrl) || "") : ""}
						isStream
					/>
				) : (
					<ProfilePicture
						profilePicUrl={item?.profilePicUrl ? (cu.buildSourceUrlImage(item?.profilePicUrl) || "") : ""}
					/>
				)}
			</button>
			<div
				className={classNames(
					"flex flex-col items-start justify-center flex-1",
					"border-b border-post-bar-border",
					"py-3 pr-4",
				)}
			>
				<span className="leading-4">
					{renderProfileActivityBasedOnPostType()}
					{renderOtherContent()}
				</span>
				{item?.createTs && (
					<span className="text-xs text-gray-dark">
						{cu.calculatePostTime(item?.createTs || "")}
					</span>
				)}
			</div>
		</div>
	);
}
