import * as React from "react"
import {SVGAttributes} from "react";

const SvgComponent = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { stroke = "var(--gray-dark)" } = props;
	return (
		<svg width={19} height={19} viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g stroke={stroke} fill="none" fillRule="evenodd">
				<path d="m18.05 10.159-7.892 7.89L.524 8.416.501.502l7.914.022z"/>
				<path d="M5.872 3.777a1.482 1.482 0 1 1-2.097 2.095 1.482 1.482 0 0 1 2.097-2.095Z"/>
			</g>
		</svg>
	);
};

export default SvgComponent
