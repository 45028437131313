import * as React from "react"
import {SVGAttributes} from "react";

const SvgComponent = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--gray-dark)" } = props;
	return (
		<svg width={20} height={15} viewBox="0 0 20 15" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g stroke={stroke} fill="none" fillRule="evenodd" strokeLinecap="round">
				<path d="M2.88 3 10 9l7.12-6" />
				<path d="M14.5 1H18a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12.5Z" />
			</g>
		</svg>
	)
}

export default SvgComponent
