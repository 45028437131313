import * as React from "react"
import {SVGAttributes} from "react";

const SvgComponent = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--primary)" } = props;
	return (
		<svg width={114} height={108} viewBox="0 0 114 108" xmlns="http://www.w3.org/2000/svg" {...props}>
			<title>{"phoneNumberRegisteredIcon_ego"}</title>
			<g stroke={stroke} fill="none" fillRule="evenodd">
				<path d="M113 32.5C113 49.898 98.898 64 81.5 64S50 49.898 50 32.5 64.102 1 81.5 1 113 15.102 113 32.5Z" />
				<path d="M81.499 29C86.738 29 91 24.738 91 19.5S86.738 10 81.499 10C76.262 10 72 14.262 72 19.5s4.262 9.5 9.499 9.5ZM64.004 49.884c.79-10.582 5.209-14.866 7.773-16.486l.628-.398.633.443L81 39.853l7.963-6.41.633-.443.626.398c2.564 1.62 6.984 5.904 7.773 16.486l.006.887C93.63 55.237 87.63 58 81.001 58c-6.63 0-12.633-2.763-17.001-7.233l.004-.883ZM107 82c0 7.179-5.821 13-13 13s-13-5.821-13-13 5.821-13 13-13 13 5.821 13 13Z" />
				<path d="M89 81.63 93.704 86 100 77M32.935 97.543l-4.551-4.417c-1.328-1.28-3.406-1.276-4.777-.004-1.029.942-2.408 2.19-2.525 2.335-.34.278-.94.062-1.852-.707-1.201-1.005-2.596-2.657-3.94-4.303-1.345-1.646-2.734-3.348-3.431-4.72-.525-.992-.664-1.685-.325-1.963.168-.142 1.589-1.28 2.756-2.052 1.506-1.05 1.92-3.147.931-4.705L11.754 71.6a3.437 3.437 0 0 0-3.963-1.435c-1.552.472-3.828 1.966-4.568 2.623-2.205 1.817-2.807 5.304-1.638 9.802 1.09 4.23 3.646 8.865 7.169 13.178 3.523 4.315 7.599 7.745 11.462 9.641 1.833.912 3.5 1.39 5.043 1.543 1.954.195 3.604-.215 4.847-1.24.792-.653 2.645-2.607 3.457-3.987.76-1.385.516-3.13-.628-4.183ZM34 52a4 4 0 1 1-8 0 4 4 0 0 1 8 0ZM46 52a4 4 0 1 1-8 0 4 4 0 0 1 8 0ZM59 54.424c-.82.96-2.091 1.576-3.514 1.576C53.008 56 51 54.136 51 51.836c0-1.724 1.128-3.204 2.738-3.836M34 65a4 4 0 1 1-8 0 4 4 0 0 1 8 0ZM46 65a4 4 0 1 1-8 0 4 4 0 0 1 8 0ZM59 65a4 4 0 1 1-8 0 4 4 0 0 1 8 0ZM34 77a4 4 0 1 1-8 0 4 4 0 0 1 8 0ZM46 77a4 4 0 1 1-8 0 4 4 0 0 1 8 0ZM46 90a4 4 0 1 1-8 0 4 4 0 0 1 8 0ZM59 77a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" />
				<path d="M74.319 63A33.2 33.2 0 0 1 75 69.708C75 88.095 59.965 103 41.418 103A33.87 33.87 0 0 1 33 101.945M8 70c.23-18.264 15.287-33 33.826-33 2.819 0 5.556.34 8.174.981" />
			</g>
		</svg>
	)
}

export default SvgComponent
