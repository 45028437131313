export class CookieMgr {
	static setCookie(name: string, value: string, expireDays: number) {
		const d = new Date();
		d.setTime(d.getTime() + (expireDays * 24 * 60 * 60 * 1000));
		let expires = "expires="+d.toUTCString();
		document.cookie = name + "=" + value + ";" + expires + ";";
	}

	static getCookie(cookieName: string) {
		let name = cookieName + "=";
		let ca = document.cookie.split(';');
		for(let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}
}
