/* eslint-disable react/require-default-props */
import React from "react";
import { Icons } from "react-toastify";
import { classNames } from "../common";
import { SnackBarTypeOptions } from "./types";
import "react-toastify/dist/ReactToastify.css";

export interface SnackbarProps {
	/**
	 * message displayed on the snackbar
	 * @default ""
	 * */
	message: string;
	/**
	 * Perform action on button click
	 * @default ""
	 * */
	onClickButton?: () => void;
	/**
	 * Show button on snackbar
	 * @default true
	 * */
	showButton?: boolean;
	/**
	 * title for button
	 * @default ""
	 * */
	buttonTitle?: string;
	/**
	 * Custom CSS class for snackbar container
	 * @default ""
	 * */
	containerStyle?: string;
	/**
	 * Custom CSS class for snackbar message
	 * @default ""
	 * */
	textStyle?: string;
	/**
	 * Custom CSS class for button
	 * @default SnackBarTypeOptions.INFO
	 * */
	buttonStyle?: string;
	/**
	 * Type of snackbar msg
	 * @default SnackBarTypeOptions.INFO
	 * */
	type?: SnackBarTypeOptions;
}

const Snackbar = ({
	onClickButton,
	showButton,
	buttonTitle,
	message,
	containerStyle,
	textStyle,
	buttonStyle,
	type,
}: SnackbarProps) => (
	<div className={classNames("flex flex-col", containerStyle!)}>
		<div className={classNames("flex flex-row", containerStyle!)}>
			<div className={classNames("flex shrink-0 w-5 mr-2.5")}>
				{Icons[type || SnackBarTypeOptions.INFO]({ theme: "light", type: type || SnackBarTypeOptions.INFO })}
			</div>
			<div className={classNames("flex flex-row w-11/12")}>
				<p className={classNames("text-sm text-gray-dark break-words", textStyle!)}>{message}</p>
			</div>
		</div>
		{showButton
			? (
				<button
					type="button"
					className={classNames("flex flex-row flex-start text-support text-primary font-normal pt-2 ml-6 pl-1", buttonStyle!)}
					onClick={onClickButton}
				>
					{buttonTitle}
				</button>
			)
			: null}
	</div>
);

Snackbar.defaultProps = {
	onClickButton: () => window.location.reload(),
	containerStyle: "",
	textStyle: "",
	buttonStyle: "",
	showButton: false,
	buttonTitle: "",
	type: SnackBarTypeOptions.INFO,
};

export default Snackbar;
