import * as React from "react"
import {SVGAttributes} from "react";

const SvgComponent = (props: SVGAttributes<SVGElement>) => (
	<svg width="18px" height="17px" viewBox="0 0 18 17" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
		<defs>
			<linearGradient x1="8.35442826e-13%" y1="50%" x2="50%" y2="50%" id="boost-expenses-linearGradient-1" gradientUnits="userSpaceOnUse">
				<stop stopColor="#00FFA5" offset="0%"></stop>
				<stop stopColor="#00FF9E" stopOpacity="0" offset="100%"></stop>
			</linearGradient>
			<linearGradient x1="8.35442826e-13%" y1="50%" x2="50%" y2="50%" id="boost-expenses-linearGradient-2" gradientUnits="userSpaceOnUse">
				<stop stopColor="#00FFA5" offset="0%"></stop>
				<stop stopColor="#00FF9E" stopOpacity="0" offset="100%"></stop>
			</linearGradient>
			<linearGradient x1="8.35442826e-13%" y1="50%" x2="50%" y2="50%" id="boost-expenses-linearGradient-3" gradientUnits="userSpaceOnUse">
				<stop stopColor="#00FFA5" offset="0%"></stop>
				<stop stopColor="#00FF9E" stopOpacity="0" offset="100%"></stop>
			</linearGradient>
			<linearGradient x1="50%" y1="50%" x2="4.62130334e-12%" y2="50%" id="boost-expenses-linearGradient-4" gradientUnits="userSpaceOnUse">
				<stop stopColor="#00FFA5" offset="0%"></stop>
				<stop stopColor="#00FF9E" stopOpacity="0" offset="100%"></stop>
			</linearGradient>
			<linearGradient x1="50%" y1="50%" x2="4.62130334e-12%" y2="50%" id="boost-expenses-linearGradient-5" gradientUnits="userSpaceOnUse">
				<stop stopColor="#00FFA5" offset="0%"></stop>
				<stop stopColor="#00FF9E" stopOpacity="0" offset="100%"></stop>
			</linearGradient>
			<linearGradient x1="50%" y1="50%" x2="4.62130334e-12%" y2="50%" id="boost-expenses-linearGradient-6" gradientUnits="userSpaceOnUse">
				<stop stopColor="#00FFA5" offset="0%"></stop>
				<stop stopColor="#00FF9E" stopOpacity="0" offset="100%"></stop>
			</linearGradient>
			<path d="M9.5,8.5 L15.5,8.5" id="boost-expenses-path-7"></path>
			<filter x="-57.1%" y="-57.1%" width="214.3%" height="214.3%" filterUnits="objectBoundingBox" id="filter-8">
				<feMorphology radius="2" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
				<feOffset dx="0" dy="0" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
				<feMorphology radius="2" operator="erode" in="SourceAlpha" result="shadowInner"></feMorphology>
				<feOffset dx="0" dy="0" in="shadowInner" result="shadowInner"></feOffset>
				<feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1"></feComposite>
				<feGaussianBlur stdDeviation="0.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
				<feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
			</filter>
		</defs>
		<g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Boost-Revenue">
				<g id="CB-Icon---Default" transform="translate(-0.000000, 0.000000)" strokeLinecap="round" strokeWidth="1.91808">
					<g id="l-bolt" transform="translate(2.666667, 1.230769)">
						<line x1="6" y1="0.615384615" x2="7.33333333" y2="0.615384615" id="Line-4" stroke="url(#boost-expenses-linearGradient-1)"></line>
						<line x1="3.33333333" y1="3.07692308" x2="7.33333333" y2="3.07692308" id="Line-4-Copy" stroke="url(#boost-expenses-linearGradient-2)"></line>
						<line x1="0.666666667" y1="5.53846154" x2="10" y2="5.53846154" id="Line-4-Copy-2" stroke="url(#boost-expenses-linearGradient-3)"></line>
						<line x1="0.666666667" y1="8" x2="10" y2="8" id="Line-4-Copy-2" stroke="url(#boost-expenses-linearGradient-4)"></line>
						<line x1="3.33333333" y1="10.4615385" x2="7.33333333" y2="10.4615385" id="Line-4-Copy-4" stroke="url(#boost-expenses-linearGradient-5)"></line>
						<line x1="3.33333333" y1="12.9230769" x2="4.66666667" y2="12.9230769" id="Line-4-Copy-5" stroke="url(#boost-expenses-linearGradient-6)"></line>
					</g>
				</g>
				<g id="Combined-Shape" strokeLinecap="square">
					<use fill="black" fillOpacity="1" filter="url(#filter-8)" xlinkHref="#boosted-expenses-path-7"></use>
					<use stroke="var(--error)" strokeWidth="2" xlinkHref="#boost-expenses-path-7"></use>
				</g>
			</g>
		</g>
	</svg>
)
export default SvgComponent
