import getQueryFields from "../../utils/getQueryFields";
import { DropdownItem, SearchBarElementInterface, TableHeader } from "../../utils/types";
import { PAGINATION_LIMIT } from "../../utils/constants";
import getSearchQuery from "../../utils/getSearchQuery";
import { PostTableHeaders } from "../posts/constants";
import { StreamTableHeaders } from "../communities/constants";
import { REPORTED_COMMENT_TYPE, REPORTED_STREAM_ID } from "../../routes/constants";
import { CommentTableHeaders, Timeframes } from "./constants";
import { getDateTimestampOfPreviousMonths } from "../../utils/utils";

export interface GetCommentsVariables {
	headers: TableHeader[];
	offset: number;
	searchData: SearchBarElementInterface[];
	otherFilterQuery: string;
	timeframeFilter?: DropdownItem[];
}
export interface GetDisabledCommentsVariables {
	headers: TableHeader[];
	offset: number;
}

const getReportedTimeframeFilter = (timeframeFilter: DropdownItem[]) => {
	let query: any = [];

	if (timeframeFilter.length === Timeframes.length) {
		return query;
	}

	if (timeframeFilter.length < Timeframes.length && timeframeFilter.length > 0) {
		const maxVal = Math.max(...timeframeFilter.map((item) => parseInt(item.value, 10)));
		query = [
			...query,
			{
				reported_ts_gte: getDateTimestampOfPreviousMonths(maxVal),
			},
			{
				reported_ts_gte: getDateTimestampOfPreviousMonths(maxVal),
			},
		];
		return query;
	}

	return query;
};

export const getCommentsQueries = (variables: GetCommentsVariables) => {
	const {
		headers, offset, searchData, otherFilterQuery, timeframeFilter,
	} = variables;

	const timeframeFilterQuery = timeframeFilter ? getReportedTimeframeFilter(timeframeFilter) : "";

	const {
		query: filterQuery,
	} = getSearchQuery(searchData);

	const filters = filterQuery !== "[]" ? [
		filterQuery.replace(/"([^(")"]+)":/g, "$1:"),
	] : [];

	if (otherFilterQuery) {
		filters.push(otherFilterQuery);
	}

	if (timeframeFilterQuery && timeframeFilterQuery?.length > 0) {
		const timeframeQuery = JSON.stringify(timeframeFilterQuery).replace(/"([^(")"]+)":/g, "$1:");
		filters.push(`{ OR: ${timeframeQuery} }`);
	}

	return `query {
			comment(
				first: ${PAGINATION_LIMIT},
				offset: ${offset}
				${filters.length > 0 ? `, filter: { AND: [ ${filters} ]} ` : ", "}
				orderBy:[creat_ts_desc]
			) {
				${getQueryFields(headers)},
				childComments{
					${getQueryFields(headers)},
				}
				parentComment{
					${getQueryFields(headers)},
				}
				post {
			 		${getQueryFields(PostTableHeaders)},
					stream { 
						${getQueryFields(StreamTableHeaders)}
					},
				},
			}
		}`
		.replaceAll("\t", "")
		.replaceAll("\n", "");
};

export const getDisabledCommentsQueries = (variables: GetDisabledCommentsVariables) => {
	const {
		headers, offset,
	} = variables;

	return `query {
			comment(
				first: ${PAGINATION_LIMIT},
				offset: ${offset},
				admin_review: "y",
				orderBy:[creat_ts_desc]
			) {
				${getQueryFields(headers)},
				childComments{
					${getQueryFields(headers)},
				}
				parentComment{
					${getQueryFields(headers)},
				}
				post {
			 		${getQueryFields(PostTableHeaders)},
					stream { 
						${getQueryFields(StreamTableHeaders)}
					},
				},
			}
		}`
		.replaceAll("\t", "")
		.replaceAll("\n", "");
};

export interface DeleteCommentVariables {
	commentUuid: string | null;
	fields: TableHeader[];
}

export const deleteCommentQuery = (variables: DeleteCommentVariables) => {
	const {
		fields, commentUuid,
	} = variables;

	if (!commentUuid) { return ""; }

	return `mutation { deleteComment( commentUuid: "${commentUuid}" ){ ${getQueryFields(fields)} }\n}`
		.replaceAll("\t", "")
		.replaceAll("\n", "");
};

export interface GetReportedCommentsVariables {
	commentUuid: string;
}

export const getReportedCommentsData = (variables: GetReportedCommentsVariables) => {
	const {
		commentUuid,
	} = variables;

	return `query {
			stream(
				first: 1,
				streamId: "${REPORTED_STREAM_ID}",
			) {
				${getQueryFields(StreamTableHeaders)},
				posts(
					postType: "${REPORTED_COMMENT_TYPE}",
					evt_commentUuid: "${commentUuid}"
				)	{
					${getQueryFields(PostTableHeaders)},
					src_prof_id,
					evt_commentUuid,
					reportReason,
					reported_user_id,
					reportMessage,
				}
			}
		}`
		.replaceAll("\t", "")
		.replaceAll("\n", "");
};

export interface GetCommentDetailsVariables {
	commentUuid: string;
	offset: number;
}

export const subcomment_pagination_limit = 6;

export const getCommentDetails = (variables: GetCommentDetailsVariables) => {
	const {
		commentUuid, offset,
	} = variables;

	return `query {
			comment(
				first: 1,
				commentUuid: "${commentUuid}",
			) {
				${getQueryFields(CommentTableHeaders)},
				profilePicRelUrl,
				admin_review,
				childComments(
					first: ${subcomment_pagination_limit},
					offset: ${offset}
				){
					${getQueryFields(CommentTableHeaders)},
					profilePicRelUrl
				}
				parentComment{
					${getQueryFields(CommentTableHeaders)},
					profilePicRelUrl
				}
				post {
			 		${getQueryFields(PostTableHeaders)},
					stream { 
						${getQueryFields(StreamTableHeaders)}
					},
				},
			}
		}`
		.replaceAll("\t", "")
		.replaceAll("\n", "");
};
