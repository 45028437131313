import React, { SVGAttributes } from "react";

// eslint-disable-next-line import/prefer-default-export
const MoreComments = (props: SVGAttributes<SVGElement>) => {
	const { fill = "var(--primary)" } = props;
	return (
		<svg
			width="20px"
			height="4px"
			viewBox="0 0 20 4"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<g id="Home-/-Feed---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-icons" transform="translate(-147.000000, -633.000000)" fill={fill}>
					<g id="moreComments_ego" transform="translate(147.000000, 633.000000)">
						<circle id="Oval" cx="10" cy="2" r="2" />
						<circle id="Oval-Copy" cx="2" cy="2" r="2" />
						<circle id="Oval-Copy-2" cx="18" cy="2" r="2" />
					</g>
				</g>
			</g>
		</svg>
	);
};

export default MoreComments;
