import React from "react";
import { CgOrganisation } from "react-icons/cg";
import Accordion from "../../../gen-comp/Accordion";
import { strings } from "../../../i18n/config";
import {
	UserDetails, UserExperienceInterface, UserExperiencePosition,
} from "../../../utils/types";
import classNames from "../../../utils/classNames";
import convertDateInDisplayFormat from "../../../utils/convertDateInDisplayFormat";
import ReadMore from "../../../gen-comp/ReadMore";
import { isNullOrEmptyArray } from "../../../utils/utils";

interface UserExperienceProps {
	/**
	 * User details which also contains user's experience data
	 * */
	data: UserDetails;
}

const UserExperience: React.FC<UserExperienceProps> = (props: UserExperienceProps) => {
	const {
		data,
	} = props;

	const renderSinglePosition = (position: UserExperiencePosition, userExpUuid: string, idx: any) => {
		const formattedDate = convertDateInDisplayFormat(
			// @ts-ignore,
			position.startDate,
			position.endDate,
			position.isCrtPos,
		);
		return (
			<div className="flex flex-col mb-3" key={`${position.startDate}-${idx}-${userExpUuid}`}>
				<div className="flex flex-row items-center justify-between mb-3">
					<div className="flex flex-col">
						<h5 data-testid="user-experience__role capitalize">
							{position.posRole}
							&nbsp;
							{position.posRole && position.locDescr && (
								<span className="text-gray-dark text-xs capitalize hidden xs:inline">
									-&nbsp;
								</span>
							)}
							{position.locDescr && (
								<span
									className="text-gray-dark text-xs capitalize hidden xs:inline"
									data-testid="user-experience__location"
								>
									{position.locDescr}
								</span>
							)}
						</h5>
						{position.locDescr && (
							<span
								className="text-gray-dark text-xs capitalize xs:hidden"
								data-testid="user-experience__location"
							>
								{position.locDescr}
							</span>
						)}
					</div>
					<span className="text-gray-dark text-xs capitalize text-right" data-testid="user-experience__experience-range">
						{formattedDate}
					</span>
				</div>
				{position.posDescr && (
					<ReadMore className="text-sm font-thin" id="user-experience__description">
						{position.posDescr}
					</ReadMore>
				)}
			</div>
		);
	};

	const renderSingleUserExp = (userExp: UserExperienceInterface, index: number) => (
		<div
			className={classNames(
				"flex flex-col mb-3",
				data?.exp_stream?.exp_post && data?.exp_stream?.exp_post?.length - 1 !== index ? "border-b border-gray-light" : "",
			)}
			key={`${userExp.postUuid}-${index}`}
		>
			{/* Company name */}
			<div className="flex flex-row mb-3 items-center" data-testid="user-experience__company-name__wrapper">
				<div className="rounded-full bg-gray-dark p-3 mr-2">
					<CgOrganisation color="white" size={28} />
				</div>
				<div className="flex flex-col capitalize" data-testid="user-experience__company-name">
					<h5>
						{userExp.company[0].name}
					</h5>
					{!isNullOrEmptyArray(userExp.industry) && (
						<span className="text-gray-dark text-xs capitalize" data-testid="user-experience__industry-name">
							{userExp.industry[0].name}
						</span>
					)}
				</div>
			</div>
			{/* Position with date */}
			{userExp.exp_position?.map((userPos, i) => renderSinglePosition(userPos, userExp.postUuid, i))}
		</div>
	);

	// @ts-ignore
	if (!(data?.exp_stream?.exp_post?.length > 0)) {
		return null;
	}

	return (
		<div className="bg-white p-3 rounded-lg mx-5 mb-4 min-h-fit max-h-full" data-testid="user-experience-accordion">
			<Accordion
				header={(
					<h5 className="mb-0">
						{strings("UserDetailsPage.user_experience")}
					</h5>
				)}
				content={(
					<div className="flex flex-col" data-testid="user-experience-content">
						{data?.exp_stream?.exp_post
							?.map((userExp: UserExperienceInterface, index: number) => renderSingleUserExp(userExp, index))}
					</div>
				)}
			/>
		</div>
	);
};

export default UserExperience;
