// eslint-disable-next-line max-classes-per-file
import { Logger } from "../common";

export class Rectangle {
	x: number;

	y: number;

	width: number;

	height: number;

	constructor(x: number, y: number, width: number, height: number) {
		this.x = x;
		this.y = y;
		this.width = width;
		this.height = height;
	}

	static fromBoundsObj(r: { x: number, y: number, x2: number, y2: number }): Rectangle {
		return new Rectangle(r.x, r.y, r.x2 - r.x, r.y2 - r.y);
	}

	static fromBounds(x: number, y: number, x2: number, y2: number): Rectangle {
		return new Rectangle(x, y, x2 - x, y2 - y);
	}

	get x2(): number {
		return this.x + this.width;
	}

	get y2(): number {
		return this.y + this.height;
	}

	contains(x: number, y: number): boolean {
		return (
			x >= this.x
			&& y >= this.y
			&& x <= this.x + this.width
			&& y <= this.y + this.height
		);
	}

	intersects(rect2: Rectangle): boolean {
		return (this.x < rect2.x2 && this.x2 >= rect2.x
			&& this.y < rect2.y2 && this.y2 >= rect2.y);
	}

	update(ro: { x: number; y: number; w: number; h: number }): Rectangle {
		this.x = ro.x;
		this.y = ro.y;
		this.width = ro.w;
		this.height = ro.h;
		return this;
	}
}

export enum AdjustDirection {
	X = "x",
	Y = "y",
	BOTH = "both"
}

export class RectangleAdjuster {
	rectangles: Rectangle[];

	rectMapById: Map<string, Rectangle>;

	viewport: Rectangle; // viewport to restrict movement to

	limitLayoutIterations: number = 10; // # of iterations to try and layout

	spacing: number;

	direction: AdjustDirection;

	constructor(viewport: Rectangle, spacing = 0, direction: AdjustDirection = AdjustDirection.BOTH) {
		this.rectMapById = new Map();
		this.rectangles = [];
		this.viewport = viewport;
		this.spacing = spacing;
		this.direction = direction;
	}

	add(rect: Rectangle): Rectangle {
		if (!this.rectangles.find((r) => r === rect)) {
			this.rectangles.push(rect);
		}
		return rect;
	}

	/**
	 * Tries to look up the rectangle in the internal map, then update it with rect, then perform Adjustment
	 * @param id
	 * @param rect
	 */
	adjustRectangleById(id: string, ro: { x: number, y: number, w: number, h: number }): Rectangle {
		// @ts-ignore
		const rect = !this.rectMapById.has(id) ? new Rectangle(ro.x, ro.y, ro.w, ro.h) : this.rectMapById.get(id).update(ro);
		this.rectMapById.set(id, rect);
		return this.adjustRectangle(rect);
	}

	adjustRectangle(rect: Rectangle): Rectangle {
		const adjustedRect = rect;
		let adjusted = true;
		let limitIters = 0;
		// eslint-disable-next-line no-plusplus
		while (adjusted && limitIters++ < (this.limitLayoutIterations * this.rectangles.length + 1)) {
			adjusted = false;
			// eslint-disable-next-line no-restricted-syntax
			for (const existingRect of this.rectangles) {
				if (adjustedRect !== existingRect && existingRect.intersects(adjustedRect)) {
					adjusted = true;
					if (this.direction === AdjustDirection.X || this.direction === AdjustDirection.BOTH) {
						adjustedRect.x = existingRect.x2 + this.spacing;
					}
					if (this.direction === AdjustDirection.Y || this.direction === AdjustDirection.BOTH) {
						adjustedRect.y = existingRect.y2 + this.spacing;
					}
					break;
				}
			}
			if (!adjusted) {
				if (adjustedRect.x2 > this.viewport.x2 - this.spacing) {
					adjusted = true;
					adjustedRect.x = this.viewport.x2 - adjustedRect.width - this.spacing;
				} else if (adjustedRect.x < 0 + this.spacing) {
					adjusted = true;
					adjustedRect.x = 0 + this.spacing;
				}
				if (adjustedRect.y2 > this.viewport.y2 - this.spacing) {
					adjusted = true;
					adjustedRect.y = this.viewport.y2 - adjustedRect.height - this.spacing;
				} else if (adjustedRect.y < 0 + this.spacing) {
					adjusted = true;
					adjustedRect.y = 0 + this.spacing;
				}
			}
		}
		if (adjusted) {
			Logger.error("could not finish layout fully", limitIters, adjustedRect);
		}

		this.add(adjustedRect);

		return adjustedRect;
	}
}
