import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { CredoSwitchProps } from "../../credo-switch";
import { ArrowType, TagInfoProps, UxpRatingCardMessages } from "../types";
import UxpRatingCardInfo from "./UxpRatingCardInfo";
import { classNames, SvgIcon } from "../../common";
import { LeftArrowIcon, RightArrowIcon } from "../../assets/icons";

interface UxpRatingCardProps {
	item: {
		"p.postUuid": string,
		"p.postType": string,
		cardData: any[];
	};
	width: number;
	handleClickUser: (
		id: string,
		username: string,
		mode: string,
		egousername: string,
		targetText: string,
	) => void;
	onRemoveCard: (data: any) => void;
	switchProps: CredoSwitchProps;
	messages: UxpRatingCardMessages;
	tagInfoProps: TagInfoProps;
}

export const UxpRatingCard = (props: UxpRatingCardProps) => {
	const {
		item,
		handleClickUser,
		onRemoveCard,
		width = 600,
		switchProps,
		messages,
		tagInfoProps,
	} = props;

	const [cards, setCards] = useState(item?.cardData || []);

	useEffect(() => {
		setCards(item.cardData);
	}, [item]);

	const removeCard = (data: any) => {
		if (!data.isCardRated) {
			// removed card by clicking x icon
			const newCards = cards.filter((u: any) => !(u["p.postUuid"] === data.card["p.postUuid"] && u.pcodes === data.card.pcodes));
			setCards(newCards);
		}
		if (onRemoveCard) {
			onRemoveCard(data);
		}
	};

	const onRateCard = (tag: string, rating: any, card: any) => {
		removeCard({
			isCardRated: true,
			rating,
			tag,
			profId: card["p.dest_prof_id"],
			card,
		});
	};

	const renderCard = (card: any, idx: number) => (
		<UxpRatingCardInfo
			key={idx}
			id={idx.toString()}
			card={card}
			handleClickUser={handleClickUser}
			removeCard={removeCard}
			switchProps={switchProps}
			messages={messages}
			onRateCard={onRateCard}
			tagInfoProps={tagInfoProps}
		/>
	);

	const renderArrow = (direction: ArrowType) => (onClickHandler: () => void, shouldBeEnabled: boolean, label: string) => {
		if (!shouldBeEnabled) {
			return null;
		}
		return (
			<button
				className={classNames(
					"absolute top-0 z-10 h-full p-2 outline-none",
					direction === ArrowType.Previous ? "left-1" : "right-1",
				)}
				type="button"
				onClick={onClickHandler}
			>
				{direction === ArrowType.Previous
					? (
						<SvgIcon
							icon={LeftArrowIcon}
						/>
					) : (
						<SvgIcon
							icon={RightArrowIcon}
						/>
					)}
			</button>
		);
	};

	if (cards && cards.length === 0) {
		return null;
	}

	return (
		<div
			/**
			 * minimum height because when user goes back to home feed the ui shifts little because carousel renders late
			 * */
			style={{ minHeight: "222px", zIndex: 11 }}
			className="flex w-full py-4 justify-center bg-background-tertiary relative"
		>
			<Carousel
				width={width}
				autoPlay={false}
				showStatus={false}
				showIndicators={false}
				showThumbs={false}
				preventMovementUntilSwipeScrollTolerance
				infiniteLoop
				renderArrowNext={renderArrow(ArrowType.Next)}
				renderArrowPrev={renderArrow(ArrowType.Previous)}
			>
				{cards.map((card, idx) => renderCard(card, idx))}
			</Carousel>
		</div>
	);
};

export default UxpRatingCard;
