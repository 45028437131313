import CPPuserEgo from "./user/CPPuserEgo.png";
import CPPuserCredo from "./user/CPPuserCredo.png";
import UserEgoDefault from "./user/userEgoDefault.png";
import StreamDefaultCover from "./stream/StreamDefaultCover.png";
import StreamDefaultProfile from "./stream/StreamDefaultProfile.png";

const Assets = {
	UserProfileCoverEgo: CPPuserEgo,
	UserProfileCoverCredo: CPPuserCredo,
	UserProfileEgoDefault: UserEgoDefault,
	StreamDefaultCover,
	StreamDefaultProfile,
};

export default Assets;
