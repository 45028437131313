import React, { useEffect, useMemo, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { AppMode, cu } from "@credo/utilities";
import { ProfilePicture } from "../../profile/profile-picture";
import { classNames } from "../../common";
import { CredoSwitch, CredoSwitchProps } from "../../credo-switch";
import { SuggestionConnCardMessages } from "../types";
import { SecondaryButton } from "../../button/SecondaryButton";
import { ButtonSize } from "../../button/types";
import { Tooltip } from "../../tooltip";

interface SuggestedConnInfoProps {
	card: any;
	handleClickUser: (
		id: string,
		username: string,
		mode: string,
		egousername: string,
		targetText: string,
	) => void;
	removeCard: () => void;
	switchProps: CredoSwitchProps;
	messages: SuggestionConnCardMessages;
	updateCard: () => void;
}

export const SuggestedConnInfo = (props: SuggestedConnInfoProps) => {
	const {
		card,
		handleClickUser,
		removeCard,
		switchProps,
		messages,
		updateCard,
	} = props;

	const [item, setItem] = useState(card);
	const [showSwitchToolTip, setShowSwitchToolTip] = useState(false);
	const isRequested = !!item["p.isRequested"];
	const targetText = useMemo(() => {
		if (item && item["p.firstName"]) {
			const username = `${item["p.egousername"] ? `@${item["p.egousername"]}` : ""}`;
			return `${item["p.firstName"]} ${item["p.lastName"]} ${username}`;
		}

		return "";
	}, [item]);

	useEffect(() => {
		setItem(card);
		setShowSwitchToolTip(false);
	}, [card]);

	const onBeforeValueChange = (handleMode: any) => {
		setTimeout(() => {
			setShowSwitchToolTip(false);
		}, 500);
		handleMode();
	};

	const renderToolTip = () => (
		<div
			className="flex w-28 h-24 justify-center items-center rounded-lg opacity-95 bg-white shadow-no-rate-tooltip"
		>
			<div className="flex flex-col justify-center items-center">
				<CredoSwitch
					size={switchProps.size}
					uCredibility={switchProps.uCredibility}
					userProfile={switchProps.userProfile}
					isCredo={switchProps.isCredo}
					doBeforeValueChange
					onBeforeValueChange={onBeforeValueChange}
					onModeChange={switchProps.onModeChange}
				/>
				<p className="w-24 mt-2 text-gray-dark text-xs text-center">
					{messages.SwitchModeToConnect}
				</p>
			</div>
		</div>
	);

	const onNamePress = () => {
		handleClickUser(
			item["p.dest_prof_id"],
			item["p.username"],
			AppMode.EGO,
			item["p.egousername"],
			targetText,
		);
	};

	const onPressConnectUser = () => {
		if (switchProps.isCredo) {
			setShowSwitchToolTip(true);
		} else {
			updateCard();
		}
	};

	if (!item["p.firstName"] || !item["p.lastName"]) {
		return null;
	}

	return (
		<div>
			<div
				className={classNames(
					"px-8 py-1",
					"flex justify-center items-center",
				)}
			>
				<div className="shadow-rating-cards rounded-15 sugg-conn-card w-full relative">
					<div
						className="flex flex-col w-full justify-start bg-background
								rounded-t-15 p-3"
					>
						<div className="flex flex-row items-center">
							<ProfilePicture
								profilePicUrl={cu.buildSourceUrlImage(item["p.profilePicRelUrl"]) ?? ""}
							/>
							<div className="flex flex-col flex-1 mx-2 w-[70%]">
								<p
									className="text-start text-base text-primary font-medium cursor-pointer break-words"
									onClick={onNamePress}
									aria-hidden
									style={{
										lineClamp: 2,
										maxWidth: "100%",
										display: "-webkit-box",
										WebkitBoxOrient: "vertical",
										WebkitLineClamp: 2,
										overflow: "hidden",
										textOverflow: "ellipsis",
									}}
								>
									{`${item["p.firstName"]} ${item["p.lastName"]}`}
								</p>
								<p className="text-start text-sm text-gray-dark">{messages.SuggestedConnection}</p>
							</div>
						</div>
					</div>
					<div
						className="flex px-4 py-10 rounded-b-15 justify-between items-center flex-wrap bg-userRatingOverlayBackground"
					>
						<SecondaryButton
							disabled={false}
							size={ButtonSize.MEDIUM}
							label={messages.Dismiss}
							dimensionsClasses="w-[48%] h-9"
							buttonClassNames="rounded-full justify-center items-center capitalize"
							handleClick={removeCard}
						/>
						<Tooltip
							content={renderToolTip()}
							placement="top"
							showArrow={false}
							toolTipAction="click"
							visible={showSwitchToolTip}
						>
							<div className="flex w-1/2">
								<SecondaryButton
									disabled={isRequested}
									size={ButtonSize.MEDIUM}
									dimensionsClasses="w-full h-9 !text-background-tertiary"
									label={isRequested ? messages.Requested : messages.Connect}
									buttonClassNames={classNames("rounded-full justify-center items-center capitalize",
										isRequested ? "bg-gray-dark !border-gray-dark" : "!bg-primary")}
									handleClick={onPressConnectUser}
								/>
							</div>
						</Tooltip>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SuggestedConnInfo;
