import { initializeApp } from "firebase/app";
import {
	getAuth, GoogleAuthProvider, getRedirectResult, signInWithRedirect, signOut,
} from "firebase/auth";
import CfgMgr from "../config/CfgMgr";

const firebaseConfig = {
	apiKey: CfgMgr.cfg_firebase_js_sdk_apiKey,
	authDomain: CfgMgr.cfg_firebase_js_sdk_authDomain,
	databaseURL: CfgMgr.cfg_firebase_js_sdk_databaseURL,
	projectId: CfgMgr.cfg_firebase_js_sdk_projectId,
	storageBucket: CfgMgr.cfg_firebase_js_sdk_storageBucket,
	messagingSenderId: CfgMgr.cfg_firebase_js_sdk_messagingSenderId,
	appId: CfgMgr.cfg_firebase_js_sdk_appId,
};

const app = initializeApp(firebaseConfig);

export const provider = new GoogleAuthProvider();
// Added scope to get user email
provider.addScope("https://www.googleapis.com/auth/userinfo.email");

export const auth = getAuth(app);

export async function signInWIthGoogle() {
	return getRedirectResult(auth);
}

export async function signInWithGoogleRedirect() {
	await signInWithRedirect(auth, provider);
}

export async function signOutAccount() {
	await signOut(auth);
}
