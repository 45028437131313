import React from "react";
import { classNames } from "../../../common";

interface CurrentItemProps {
	title: string;
	item: React.ReactNode;
	classNames?: {
		wrapperClassName?: string;
		titleClassName?: string;
	}
}

// eslint-disable-next-line import/prefer-default-export
export const CurrentItem: React.FC<CurrentItemProps> = (props: CurrentItemProps) => {
	const {
		title,
		item,
		classNames: classNameProps,
	} = props;

	const {
		wrapperClassName,
		titleClassName,
	} = classNameProps || {};

	return (
		<div
			key={title}
			className={classNames(
				"flex flex-col",
				wrapperClassName,
			)}
		>
			<span
				className={classNames(
					"text-xs font-pt-sans text-boost-board-tag mb-2.5",
					titleClassName,
				)}
			>
				{title}
			</span>
			{item}
		</div>
	);
};
