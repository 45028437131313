import React, { useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import {
	createSearchParams, useNavigate,
} from "react-router-dom";
import Hero from "../../gen-comp/Hero";
import Table from "../../gen-comp/table";
import { useQuery } from "../../hooks/graphql/useQuery";
import { strings } from "../../i18n/config";
import {
	DisabledPostType,
	SnackBarTypeOptions, TableHeader,
} from "../../utils/types";
import { DATE_FORMAT } from "../../utils/utils";
import {
	DisabledPostTableHeaders,
} from "./constants";
import ConfirmationModal from "../../gen-comp/ConfirmationModal";
import { successRETCD } from "../../api";
import {
	deletePostQuery, getDisabledPostsQueries, GetDisabledPostVariables, restoreDisabledItem,
} from "./queries";
import { PAGINATION_LIMIT } from "../../utils/constants";
import useOptionalSearchApi from "../../hooks/useOptionalSearchApi";
import ToastMsg from "../../gen-comp/ToastMsg";
import { AUTH_URL, POST_DETAILS_PAGE, USER_DETAILS_PAGE } from "../../routes/constants";

export default function DisabledPostPage() {
	const navigate = useNavigate();

	const [postData, setPostData] = useState<any>([]);
	const [headers] = useState<TableHeader[]>(DisabledPostTableHeaders);
	const [confirmationObj, setConfirmationObj] = useState<any>(null);
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	const [page, setPage] = useState(1);
	const [changeInPage, setChangeInPage] = useState<boolean>(true);

	const variables: GetDisabledPostVariables = {
		headers: DisabledPostTableHeaders,
		offset: (page - 1) * PAGINATION_LIMIT,
	};

	const variablesForSearchAPI = {
		searchData: [],
		otherFilterQuery: "",
	};

	const [getSearchApiData, { data, loading }] = useOptionalSearchApi(getDisabledPostsQueries(variables), variablesForSearchAPI as any, {
		entity: "Post",
		page: 1,
		useSearchApi: false,
		formatDataKey: "post",
	});

	useEffect(() => {
		if (changeInPage) {
			getSearchApiData();
		}
	}, [page, changeInPage]);

	useEffect(() => {
		const items: any[] = [];
		data?.forEach((element: any) => {
			items.push({
				...element,
				creat_ts: element.creat_ts
					? moment(new Date(element.creat_ts)).format(DATE_FORMAT) : strings("PostSearchPage.not_available"),
				shared_modif_ts: element.shared_modif_ts
					? moment(new Date(element.shared_modif_ts)).format(DATE_FORMAT) : strings("PostSearchPage.not_available"),
				edit_ts: element.edit_ts
					? moment(new Date(element.edit_ts)).format(DATE_FORMAT) : strings("PostSearchPage.not_available"),
				article_date: element.article_date
					? moment(new Date(element.article_date)).format(DATE_FORMAT) : strings("PostSearchPage.not_available"),
				shared_creat_ts: element.shared_creat_ts
					? moment(new Date(element.shared_creat_ts)).format(DATE_FORMAT) : strings("PostSearchPage.not_available"),
				shared_edit_ts: element.shared_edit_ts
					? moment(new Date(element.shared_edit_ts)).format(DATE_FORMAT) : strings("PostSearchPage.not_available"),
				reported_ts: element?.reported_ts
					? moment(new Date(element.reported_ts)).format(DATE_FORMAT) : strings("PostSearchPage.not_available"),
			});
		});
		setPostData(items);
		setChangeInPage(false);
	}, [data]);

	const [deletePostMutation] = useQuery<Array<Object>>("", {
		formatDataKey: "post",
		isMutation: true,
	});

	const closeConfirmationModal = () => {
		setOpenConfirmationModal(false);
		setConfirmationObj(null);
	};

	const handleDeletePost = async (item: any) => {
		await deletePostMutation({
			mutation: deletePostQuery({ postUuid: item?.postUuid, fields: DisabledPostTableHeaders }),
		}).then((response) => {
			if (response.retcd === successRETCD) {
				toast(
					<ToastMsg
						message={strings("PostSearchPage.delete_success")}
						showButton
						type={SnackBarTypeOptions.SUCCESS}
					/>,
				);
				getSearchApiData();
			} else {
				toast(
					<ToastMsg
						message={strings("PostSearchPage.something_went_wrong")}
						showButton
						type={SnackBarTypeOptions.ERROR}
					/>,
				);
			}
		}).catch((error) => {
			toast(
				<ToastMsg
					message={strings("PostSearchPage.something_went_wrong", { error })}
					showButton
					type={SnackBarTypeOptions.ERROR}
				/>,
			);
		});
		closeConfirmationModal();
	};

	const restoreDisabledPost = async (item: any) => {
		await restoreDisabledItem({
			postUuid: item?.postUuid,
			itemType: DisabledPostType.POST,
		}).then((res: any) => {
			if (res?.response?.retcd === successRETCD) {
				toast(
					<ToastMsg
						message={strings("DisabledPostPage.restore_post_success")}
						showButton
						type={SnackBarTypeOptions.SUCCESS}
					/>,
				);
			} else {
				toast(
					<ToastMsg
						message={strings("PostSearchPage.something_went_wrong")}
						showButton
						type={SnackBarTypeOptions.ERROR}
					/>,
				);
			}
		}).catch((error) => {
			toast(
				<ToastMsg
					message={strings("PostSearchPage.something_went_wrong", { error })}
					showButton
					type={SnackBarTypeOptions.ERROR}
				/>,
			);
		});
		closeConfirmationModal();
	};

	const showRestoreConfirmationPopup = (item: any) => {
		const title = strings("DisabledPostPage.restore_post");
		const message = `${strings("DisabledPostPage.restore_post_message", { postUuid: item?.postUuid })}`;
		setOpenConfirmationModal(true);
		setConfirmationObj({
			ok_button_function: () => {
				restoreDisabledPost(item);
			},
			title,
			message,
		});
	};

	const showDeleteConfirmationPopup = (item: any) => {
		const title = strings("PostSearchPage.delete_post");
		const message = `${strings("PostSearchPage.delete_post_message", { postUuid: item?.postUuid })}`;
		setOpenConfirmationModal(true);
		setConfirmationObj({
			ok_button_function: () => handleDeletePost(item),
			title,
			message,
		});
	};

	const goToPostDetailScreen = (item: any) => {
		if (item?.value === "prof_id") {
			window.open(`${AUTH_URL}${USER_DETAILS_PAGE}?${createSearchParams({
				prof_id: item?.prof_id,
				mode: item?.mode,
				isNewTab: "y",
			})}`, "_blank");
		} else {
			navigate({
				pathname: AUTH_URL + POST_DETAILS_PAGE,
				search: `?${createSearchParams({ postUuid: item?.postUuid, reportedCount: item?.nb_rep })}`,
			});
		}
	};

	const renderColumn = (props: { item: any; header: any; }) => {
		const { item, header } = props;
		if (!header || !item) {
			return null;
		}
		return (
			<div className="flex flex-row justify-center items-center">
				<button
					type="button"
					className="button"
					onClick={() => showRestoreConfirmationPopup(item)}
				>
					{strings("DisabledPostPage.restore")}
				</button>
				<button
					type="button"
					className="button"
					onClick={() => showDeleteConfirmationPopup(item)}
				>
					{strings("DisabledPostPage.delete")}
				</button>
			</div>
		);
	};

	return (
		<div>
			<Hero title={strings("DisabledPostPage.hero_title")} pageHasTable />
			<div className="bg-white p-5 rounded-lg my-5 mx-5 -mt-24">
				<div data-testid="disabled-post-search-page__wrapper" className="my-0">
					<Table
						header={[
							...headers,
							{
								title: strings("Table.actions"),
								value: "actions",
								showCustomColumnComponent: true,
							},
						]}
						data={postData}
						showHeader
						isLoading={loading}
						onItemClick={goToPostDetailScreen}
						showActionButtons={false}
						pagination={{
							onPageChange: (num) => {
								setChangeInPage(true);
								setPage(num);
							},
							hidePageNumber: true,
							scrollOnPageChange: true,
							page,
						}}
						CustomColumnComponent={renderColumn}
					/>
				</div>
				<ConfirmationModal
					isVisible={openConfirmationModal}
					onClose={closeConfirmationModal}
					cancel_button_function={closeConfirmationModal}
					ok_button_title={strings("PostSearchPage.yes")}
					cancel_button_title={strings("PostSearchPage.no")}
					ok_button_function={confirmationObj?.ok_button_function}
					title={confirmationObj?.title}
					message={confirmationObj?.message}
					okButtonStyle=""
					cancelButtonStyle=""
				/>
			</div>
		</div>
	);
}
