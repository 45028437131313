import * as React from "react"
import {SVGAttributes} from "react";
// TODO: Remove this icon once the animation and dotted thing is done using RefreshSingleArrow in web-app
const AutoRefresh = (props: SVGAttributes<HTMLOrSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 15 15"
		{...props}
	>
		<defs>
			<path id="auto-refresh-dotted-icon-a" d="M6.364 10.864a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Z" />
			<mask
				id="auto-refresh-dotted-icon-b"
				width={9}
				height={9}
				x={0}
				y={0}
				fill="#fff"
				maskContentUnits="userSpaceOnUse"
				maskUnits="objectBoundingBox"
			>
				<use xlinkHref="#auto-refresh-dotted-icon-a" />
			</mask>
		</defs>
		<g fill="none" fillRule="evenodd">
			<rect width={15} height={15} fill="#10121B" rx={3} />
			<path
				stroke="#0BE0D8"
				strokeDasharray={1}
				strokeLinejoin="round"
				d="M7.5 14a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13Z"
			/>
			<use
				xlinkHref="#auto-refresh-dotted-icon-a"
				stroke="#0BE0D8"
				strokeDasharray="23,8"
				strokeWidth={2}
				mask="url(#auto-refresh-dotted-icon-b)"
				transform="rotate(-135 7.167 6.697)"
			/>
			<path fill="#0BE0D8" d="M11.207 5.707V2.88L8.38 5.707z" />
		</g>
	</svg>
)
export default AutoRefresh;
