import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

interface TableSkeletonProps {
	noOfColumns?: number;
	noOfRows?: number;
	width?: number
}

const TableSkeleton = ({
	noOfColumns,
	noOfRows,
	width,
}: TableSkeletonProps) => {
	const rowsArray = Array.from({ length: noOfRows || 10 }, (x, i) => i);
	const colomnsArray = Array.from({ length: noOfColumns || 6 }, (x, i) => i);
	return (
		<SkeletonTheme
			baseColor="var(--background-skeleton)"
			highlightColor="var(--hightlight-skeleton)"
		>
			<table data-testid="table-skeleton" id="user">
				<thead>
					<tr>
						{colomnsArray?.map((x: any) => (
							<td key={x}><Skeleton key={x} width={width ?? 80} height={30} /></td>
						))}
					</tr>
				</thead>
				<tbody>
					{rowsArray.map((item: any) => (
						<tr key={item}>
							{colomnsArray?.map((x: any) => (
								<td key={x}><Skeleton key={x} width={width ?? 80} height={20} /></td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</SkeletonTheme>
	);
};

export default TableSkeleton;

TableSkeleton.defaultProps = {
	noOfColumns: 6,
	noOfRows: 10,
};
