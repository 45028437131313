import React, { ChangeEvent, useState } from "react";
import { useDebounce } from "react-use";
import { DropdownItem } from "../../utils/types";
import Input from "../Input";
import Filter from "../Filter";
import { strings } from "../../i18n/config";
import { DEBOUNCE_TIME } from "../../utils/constants";

export interface SearchBarProps {
	/**
	 * Index of the searchbar.
	 * Accepts string.
	 * Generated v4 uuid format.
	 * */
	index: string;
	/**
	 * To change the value typed in the input field of the searchbar container.
	 * Accepts index of the searchbar and value typed in the input field.
	 * */
	handleChangeSearchValue: (index: string, value: string) => void;
	/**
	 * To change the checked dropdown items in the search bar.
	 * Accepts index of the searchbar and array of checked dropdown items.
	 * */
	handleChangeDropdownItems: (index: string, value: DropdownItem[]) => void;
	/**
	 * Data for the dropdown items.
	 * */
	data: DropdownItem[]
	/**
	 * Initial selected data set in the dropdown.
	 * */
	initialSelectedData: DropdownItem[]
	/**
	 * Optional initial search data
	 * @default ""
	 * */
	initialSearchData?: string
}

const SearchBar: React.FC<SearchBarProps> = (props: SearchBarProps) => {
	const {
		index,
		handleChangeSearchValue,
		handleChangeDropdownItems,
		data,
		initialSelectedData,
		initialSearchData,
	} = props;

	const [searchFields, setSearchFields] = useState<DropdownItem[]>(data);
	const [search, setSearch] = useState<string>(initialSearchData || "");
	const [debounceVal, setDebounceVal] = useState<string>("");

	useDebounce(
		() => {
			setDebounceVal(search);
			handleChangeSearchValue(index, search);
		},
		DEBOUNCE_TIME,
		[search],
	);

	const applySearchFieldSelected = (items: DropdownItem[]) => {
		handleChangeDropdownItems(index, items);
		setSearchFields(items);
	};

	const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
		setSearch(event.target.value);
	};

	return (
		<div className="grid grid-cols-1 xs:grid-cols-2-max-13 gap-2 lg:gap-4 auto-rows-min">
			<Input
				wrapperClassName="max-w-xs mb-0"
				type="text"
				name="search"
				value={search}
				onChange={handleChangeSearch}
				placeholder={strings("Search.search")}
				id={`searchbar-input-${index}`}
			/>
			<Filter
				data={searchFields}
				filterName={strings("UserSearchPage.searchColumns")}
				filterTitle={strings("UserSearchPage.search")}
				applyFilter={applySearchFieldSelected}
				selectedData={initialSelectedData}
				showTitle={false}
				id={`searchbar-filter-${index}`}
			/>
			{/* Just to check if the debounce is working while testing */}
			<div className="hidden">
				{debounceVal}
			</div>
		</div>
	);
};

SearchBar.defaultProps = {
	initialSearchData: "",
};

export default SearchBar;
