import React, { SVGAttributes } from "react";

// eslint-disable-next-line no-undef
const Copy = (props: SVGAttributes<SVGElement>) => {
	const {
		fill = "var(--boost-tag-accent)",
		stroke = "var(--background-dark-main)"
	} = props;

	return (
		<svg viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
			<g id="Comp---Credoboosted" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="comp-cboost-sats_widget-dashboard---wallet---deposit---qr-inoice" transform="translate(-280.5, -468.5)">
					<g id="deposit-panel;" transform="translate(81, 218)">
						<g id="Group" transform="translate(46, 248)">
							<g id="icon_circle_filled" transform="translate(153.5, 2.5)">
								<circle id="Oval" stroke={fill} fill={fill} cx="10" cy="10" r="9.5"></circle>
								<g id="icon_s_i---lock" transform="translate(5.0125, 4.3)" fill={stroke} fillRule="nonzero">
									<g id="content_copy_FILL1_wght400_GRAD0_opsz20">
										<path d="M1.06875,11.4 C0.77484375,11.4 0.523242187,11.2953516 0.313945313,11.0860547 C0.104648438,10.8767578 0,10.6251562 0,10.33125 L0,2.1375 L1.06875,2.1375 L1.06875,10.33125 L7.8375,10.33125 L7.8375,11.4 L1.06875,11.4 Z M3.20625,9.2625 C2.91234375,9.2625 2.66074219,9.15785156 2.45144531,8.94855469 C2.24214844,8.73925781 2.1375,8.48765625 2.1375,8.19375 L2.1375,1.06875 C2.1375,0.77484375 2.24214844,0.523242187 2.45144531,0.313945312 C2.66074219,0.104648437 2.91234375,0 3.20625,0 L8.90625,0 C9.20015625,0 9.45175781,0.104648437 9.66105469,0.313945312 C9.87035156,0.523242187 9.975,0.77484375 9.975,1.06875 L9.975,8.19375 C9.975,8.48765625 9.87035156,8.73925781 9.66105469,8.94855469 C9.45175781,9.15785156 9.20015625,9.2625 8.90625,9.2625 L3.20625,9.2625 Z" id="Shape"></path>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default Copy;
