import React, { SVGAttributes } from "react";

const Blasts = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { stroke = "var(--gray-dark)", fill } = props;
	return (
		<svg
			width="20px"
			height="20px"
			viewBox="0 0 20 20"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<title>Blasts</title>
			<defs>
				<rect id="path-blasts-1" x="0" y="0" width="20" height="20" />
				<circle id="path-blasts-3" cx="6" cy="6" r="4.8" />
				<linearGradient x1="8.35442826e-13%" y1="50%" x2="50%" y2="50%" id="linearGradient-Blasts-5" gradientUnits="userSpaceOnUse">
					<stop stopColor="#00FFA5" offset="0%" />
					<stop stopColor="#00FF9E" stopOpacity="0" offset="100%" />
				</linearGradient>
				<linearGradient x1="8.35442826e-13%" y1="50%" x2="50%" y2="50%" id="linearGradient-Blasts-6" gradientUnits="userSpaceOnUse">
					<stop stopColor="#00FFA5" offset="0%" />
					<stop stopColor="#00FF9E" stopOpacity="0" offset="100%" />
				</linearGradient>
				<linearGradient x1="8.35442826e-13%" y1="50%" x2="50%" y2="50%" id="linearGradient-Blasts-7" gradientUnits="userSpaceOnUse">
					<stop stopColor="#00FFA5" offset="0%" />
					<stop stopColor="#00FF9E" stopOpacity="0" offset="100%" />
				</linearGradient>
				<linearGradient x1="25%" y1="50%" x2="4.62130334e-12%" y2="50%" id="linearGradient-Blasts-8" gradientUnits="userSpaceOnUse">
					<stop stopColor="#00FFA5" offset="0%" />
					<stop stopColor="#00FF9E" stopOpacity="0" offset="100%" />
				</linearGradient>
				<linearGradient x1="25%" y1="50%" x2="4.62130334e-12%" y2="50%" id="linearGradient-Blasts-9" gradientUnits="userSpaceOnUse">
					<stop stopColor="#00FFA5" offset="0%" />
					<stop stopColor="#00FF9E" stopOpacity="0" offset="100%" />
				</linearGradient>
				<linearGradient x1="25%" y1="50%" x2="4.62130334e-12%" y2="50%" id="linearGradient-Blasts-10" gradientUnits="userSpaceOnUse">
					<stop stopColor="#00FFA5" offset="0%" />
					<stop stopColor="#00FF9E" stopOpacity="0" offset="100%" />
				</linearGradient>
			</defs>
			<g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Icon---Blasts---Inactive">
					<g id="Oval-+-Oval-+-Oval-Mask">
						<mask id="mask-blasts-2" fill="white">
							<use xlinkHref="#path-blasts-1" />
						</mask>
						<g id="Mask" />
						<circle id="Oval" stroke={stroke} mask="url(#mask-blasts-2)" cx="6" cy="14" r="7.5" />
						<circle id="Oval" stroke={stroke} mask="url(#mask-blasts-2)" cx="6" cy="14" r="9.5" />
						<circle id="Oval" stroke={stroke} mask="url(#mask-blasts-2)" cx="6" cy="14" r="11.5" />
						<circle id="Oval" stroke={stroke} mask="url(#mask-blasts-2)" cx="6.5" cy="13.5" r="13" />
					</g>
					<g id="CB-Icon-Oval-Copy" transform="translate(0.000000, 8.000000)">
						<circle id="Oval" stroke="#212636" strokeWidth="0.6" fill={fill} cx="6" cy="6" r="5.7" />
						<mask id="mask-blasts-4" fill="white">
							<use xlinkHref="#path-blasts-3" />
						</mask>
						<use id="Icon-Back" fill="#212636" xlinkHref="#path-blasts-3" />
						<g id="CB-Icon-LB" mask="url(#mask-blasts-4)" strokeLinecap="round">
							<g transform="translate(2.400000, 2.100000)" id="l-bolt">
								<g transform="translate(1.200000, 0.600000)">
									<line x1="2.7" y1="0.3" x2="3.3" y2="0.3" id="Line-4" stroke="url(#linearGradient-Blasts-5)" strokeWidth="0.9" />
									<line x1="1.5" y1="1.5" x2="3.3" y2="1.5" id="Line-4-Copy" stroke="url(#linearGradient-Blasts-6)" strokeWidth="0.9" />
									<line x1="0.3" y1="2.7" x2="4.5" y2="2.7" id="Line-4-Copy-2" stroke="url(#linearGradient-Blasts-7)" strokeWidth="0.9" />
									<line x1="0.3" y1="3.9" x2="4.5" y2="3.9" id="Line-4-Copy-2" stroke="url(#linearGradient-Blasts-8)" strokeWidth="0.9" />
									<line x1="1.5" y1="5.1" x2="3.3" y2="5.1" id="Line-4-Copy-4" stroke="url(#linearGradient-Blasts-9)" strokeWidth="0.9" />
									<line x1="1.5" y1="6.3" x2="2.1" y2="6.3" id="Line-4-Copy-5" stroke="url(#linearGradient-Blasts-10)" strokeWidth="0.9" />
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default Blasts;
