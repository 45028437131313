import React, { useEffect, useState } from "react";
import { cu } from "@credo/utilities";
import isNil from "lodash/isNil";
import Linkify from "react-linkify";
import { classNames, RegEx } from "../common";
import { PostHeaderScreenType } from "./types";
import styles from "./ReadMoreText.module.css";

export interface ReadMoreTextProps {
	text: string;
	textLineHeight?: number;
	numberOfLines: number;
	textClasses?: string;
	textId?: string;
	readMoresTextStyle?: string;
	navigateToMentionedUser?: (data: any) => void;
}

export const ReadMoreText = (props: ReadMoreTextProps) => {
	const {
		text,
		textLineHeight = 20,
		numberOfLines = 7,
		textClasses,
		textId,
		readMoresTextStyle,
		navigateToMentionedUser,
	} = props;
	const [showAllText, setShowAllText] = useState(false);
	const [shouldShowReadMore, setShouldShowReadMore] = useState(false);

	const onClickMention = (text: string, href: string, event?: React.MouseEvent<HTMLElement>) => {
		if (navigateToMentionedUser) {
			event?.stopPropagation();
			const { userName, profId, mode } = cu.getUserNameAndProfId(href);
			navigateToMentionedUser({
				type: PostHeaderScreenType.User,
				mode,
				profId,
				egousername: userName || text,
				userName: userName || text,
			});
		}
	};

	const mentionComponentDecorator = (href: string, text: string, key: number) => (
		<span
			key={key}
			className="text-primary font-bold cursor-pointer"
			onClick={(event) => onClickMention(text, href, event)}
			aria-hidden
		>
			{`@${text}`}
		</span>
	);

	const boldTextComponentDecorator = (href: string, text: string, key: number) => (
		<span
			key={key}
			className="text-basic font-bold"
		>
			{text}
		</span>
	);

	const hashTagComponentDecorator = (href: string, text: string, key: number) => (
		<span
			key={key}
			className="text-primary font-bold"
		>
			{text}
		</span>
	);

	const urlComponentDecorator = (href: string, text: string, key: number) => (
		<a
			className="text-primary font-bold"
			href={href}
			target="_blank"
			rel="noreferrer"
			key={key}
			onClick={(event: React.MouseEvent<HTMLElement>) => {
				event.stopPropagation();
			}}
		>
			{text}
		</a>
	);

	/**
	 * for backward compatibility for existing old mentions
	 * they were like this @[name](prof_id, username, mode)
	 * */
	const mentionMatchDecoratorV1 = (value: string) => {
		const matches = Array.from(value.matchAll(RegEx.userTag), (m) => m);
		return matches.map((match) => ({
			schema: "",
			index: match.index || 0,
			lastIndex: (match.index || 0) + (match[0]?.length ?? 0),
			text: match[1],
			url: match[2],
		}));
	};

	const mentionMatchDecorator = (value: string) => {
		const matches = Array.from(value.matchAll(RegEx.userTagUsernameOnly), (m) => m);
		return matches.map((match) => ({
			schema: "",
			index: match.index || 0,
			lastIndex: (match.index || 0) + (match[0]?.length ?? 0),
			text: match[1],
			url: match[2],
		}));
	};

	const hashTagMatchDecorator = (value: string) => {
		const matches = Array.from(value.matchAll(RegEx.hashTag), (m) => m);
		return matches.map((match) => ({
			schema: "",
			index: match.index || 0,
			lastIndex: (match.index || 0) + (match[0]?.length ?? 0),
			text: match[0],
			url: "",	// TODO: add URL for navigating user to tagged hashtag page
		}));
	};

	const boldTextMatchDecorator = (value: string) => {
		const matches = Array.from(value.matchAll(RegEx.boldTag), (m) => m);
		return matches.map((match) => ({
			schema: "",
			index: match.index || 0,
			lastIndex: (match.index || 0) + (match[0]?.length ?? 0),
			text: match[1],
			url: "",
		}));
	};

	const countLines = () => {
		const el = document.getElementById(`${textId}-para`);
		if (el) {
			const divHeight = el.offsetHeight;
			const lines = divHeight / textLineHeight;
			setShouldShowReadMore(numberOfLines < lines);
		}
	};

	const handlePressReadLess = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		setShowAllText(false);
	};

	const handlePressReadMore = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		setShowAllText(true);
	};

	const renderShowMore = () => (
		<span
			className={classNames("cursor-pointer text-primary text-sm font-normal", readMoresTextStyle)}
			onClick={handlePressReadMore}
			aria-hidden
		>
			see more
		</span>
	);

	const renderShowLess = () => (
		<span
			className={classNames("cursor-pointer text-primary text-sm font-normal", readMoresTextStyle)}
			onClick={handlePressReadLess}
			aria-hidden
		>
			see less
		</span>
	);

	const renderReadMoreButton = () => {
		if (shouldShowReadMore && !showAllText) {
			return renderShowMore();
		} if (shouldShowReadMore && showAllText) {
			return renderShowLess();
		}
		return null;
	};

	useEffect(() => {
		countLines();
	}, [text]);

	useEffect(() => {
		window.addEventListener("resize", countLines);
		return () => {
			window.removeEventListener("resize", countLines);
		};
	}, []);

	if (isNil(text)) {
		return null;
	}
	return (
		<div
			id={textId ?? "post-text"}
			className={classNames(
				"text-base font-light pt-2",
				textClasses || "text-basic",
			)}
		>
			<Linkify
				componentDecorator={mentionComponentDecorator}
				matchDecorator={mentionMatchDecoratorV1}
			>
				<Linkify
					componentDecorator={mentionComponentDecorator}
					matchDecorator={mentionMatchDecorator}
				>
					<Linkify
						componentDecorator={urlComponentDecorator}
					>
						<Linkify
							componentDecorator={hashTagComponentDecorator}
							matchDecorator={hashTagMatchDecorator}
						>
							<Linkify
								componentDecorator={boldTextComponentDecorator}
								matchDecorator={boldTextMatchDecorator}
							>
								<p
									id={`${textId}-para`}
									style={{
										overflowWrap: "break-word",
										wordBreak: "break-word",
										msWordBreak: "break-word",
										wordWrap: "break-word",
									}}
									className={classNames(
										"leading-6 whitespace-pre-wrap break-all invisible absolute",
									)}
								>
									{text}
								</p>
								<div
									/**
									* If the number of lines are more than 7 make sure to
									* add class for it in the css file
									* */
									className={classNames(
										shouldShowReadMore && !showAllText ? styles.baseClamp : "",
										shouldShowReadMore && !showAllText ? styles[`readMoreLineClamp${numberOfLines}`] : "",
									)}
								>
									<p
										style={{
											overflowWrap: "break-word",
											wordBreak: "break-word",
											msWordBreak: "break-word",
											wordWrap: "break-word",
										}}
										className={classNames(
											"leading-6 whitespace-pre-wrap break-all",
										)}
									>
										{text}
									</p>
								</div>
							</Linkify>
						</Linkify>
					</Linkify>
				</Linkify>
			</Linkify>
			{renderReadMoreButton()}
		</div>
	);
};

ReadMoreText.defaultProps = {
	textLineHeight: 20,
	textClasses: "",
	textId: "post-text",
	readMoresTextStyle: "",
	navigateToMentionedUser: () => { },
};
