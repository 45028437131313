import React, { SVGAttributes } from "react";

// eslint-disable-next-line import/prefer-default-export
const StreamRoleManagement = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--gray-dark)",
	} = props;
	return (
		<svg
			width="22px"
			height="20px"
			viewBox="0 0 22 20"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<g id="Streams---CT---190723" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-icons" transform="translate(-374.000000, -452.000000)" stroke={stroke}>
					<g id="streamRoleManagement" transform="translate(375.000000, 453.000000)">
						<path
							d="M6.5105,7.578 C8.5995,7.578 10.2995,5.878 10.2995,3.789 C10.2995,1.699 8.5995,-1.24344979e-14 6.5105,-1.24344979e-14 C4.4215,-1.24344979e-14 2.7225,1.699 2.7225,3.789 C2.7225,5.878 4.4215,7.578 6.5105,7.578 Z"
							id="Stroke-1"
						/>
						<path
							d="M15.0005,12.0988 C15.9435,12.0988 16.7115,11.3318 16.7115,10.3878 C16.7115,9.4448 15.9435,8.6778 15.0005,8.6778 C14.0575,8.6778 13.2905,9.4448 13.2905,10.3878 C13.2905,11.3318 14.0575,12.0988 15.0005,12.0988 Z"
							id="Stroke-3"
						/>
						<path
							d="M9.802,9.0718 L9.56,9.2358 L6.51,11.6088 L3.461,9.2358 L3.219,9.0718 L2.979,9.2188 C1.997,9.8188 0.302,11.4048 5.41788836e-14,15.3218 C1.674,16.9768 3.972,17.9998 6.511,17.9998 C9.05,17.9998 11.348,16.9778 13.021,15.3238 L13.63,14.7828"
							id="Stroke-5"
						/>
						<polygon
							id="Stroke-7"
							points="20.0002 8.8124 18.8732 6.8594 16.7922 7.2924 16.1272 5.2744 13.8732 5.2744 13.2082 7.2924 11.1272 6.8594 10.0002 8.8124 11.4152 10.3974 10.0002 11.9824 11.1272 13.9354 13.2082 13.5024 13.8732 15.5204 16.1272 15.5204 16.7932 13.5024 18.8732 13.9354 20.0002 11.9824 18.5852 10.3974"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default StreamRoleManagement;
