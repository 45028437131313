import React, { useRef, useState } from "react";
import "./Login.css";
import AuthCode, { AuthCodeRef } from "react-auth-code-input";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import packageJson from "../../../package.json";
import { strings } from "../../i18n/config";
import { googleLoginRequest } from "./LoginRequests";
import { ERROR_CODE, successRETCD } from "../../api";
import { AUTH_URL, DASHBOARD_PAGE, QR_CODE_2FA_SCREEN } from "../../routes/constants";
import { sessionAtom, userRolesAtom } from "../../utils/atoms";
import Roles from "../../config/Roles";
import { signOutAccount } from "../../utils/Firebase";
import Logo from "../../gen-comp/Logo";

export default function TwoFactorAuthCodeScreen() {
	const navigate = useNavigate();
	const AuthInputRef = useRef<AuthCodeRef>(null);
	const [, setResult] = useState("");
	const [, setDisabled] = useState<boolean>(true);
	const [errorMessage, setErrorMessage] = useState("");
	const [, setUserRoles] = useAtom(userRolesAtom);
	const [, setSessionData] = useAtom(sessionAtom);

	const onCodeEnter = async (code: string) => {
		if (code && code.length === 6) {
			const userDetails = localStorage.getItem("userDetails");
			const userDetailsObj = userDetails && JSON.parse(userDetails);
			const res = await googleLoginRequest({ email: userDetailsObj?.email || "", uuid: userDetailsObj?.uuid, otp: code }) as any;
			if (res && res.code === 200) {
				if (res.response.retcd === successRETCD && res.response.item) {
					const role = Object.keys(Roles)
						.find((attName) => res.response.item[0].role_cd === Roles[attName as keyof typeof Roles].value);
					const roleDetails: any = Roles[role as keyof typeof Roles];
					setUserRoles(roleDetails);
					setSessionData(res.response?.item[0]);
					localStorage.setItem("roles", JSON.stringify(roleDetails));
					localStorage.setItem("timestamp", JSON.stringify(new Date().getTime()));
					localStorage.setItem("userDetails", JSON.stringify({ ...userDetailsObj, ...res.response?.item[0] }));
					localStorage.removeItem("authURL");
					navigate(`${AUTH_URL}${DASHBOARD_PAGE}`);
				} else if (res.response.retcd === ERROR_CODE) {
					setErrorMessage(strings("TwoFactorAuthCodeScreen.invalid_code_message"));
				} else {
					setErrorMessage(strings("LoginScreen.unauthorized_user_message"));
				}
			} else if (res?.error?.data?.retcd === ERROR_CODE) {
				setErrorMessage(strings("TwoFactorAuthCodeScreen.invalid_code_message"));
			} else setErrorMessage(strings("LoginScreen.error_message"));
			setResult("");
			AuthInputRef.current?.clear();
			setDisabled(true);
		}
	};

	const handleOnChange = (res: string) => {
		setResult(res);
		setDisabled(res.length !== 6);
		if (res.length === 6) {
			onCodeEnter(res);
		}
		if (res.length > 0) {
			setErrorMessage("");
		}
	};

	const logoutUser = () => {
		localStorage.removeItem("userDetails");
		localStorage.removeItem("authURL");
		localStorage.clear();
		navigate("/");
		signOutAccount();
	};

	return (
		<>
			<div
				data-testid="2FA-code"
				className="bg-background-dark flex flex-col items-center justify-center inset-0 absolute"
			>
				<div className="flex flex-col pt-5 px-3 bg-white shadow-xl items-center google-login-container">
					<a href={DASHBOARD_PAGE} className="items-center text-white flex justify-center pb-5">
						<Logo />
					</a>
					<div className="items-center px-10">
						<h1 className="active-text text-primary text-center">{strings("TwoFactorAuthCodeScreen.enter_message")}</h1>
					</div>
					<div className="items-center py-5" data-testid="auth-code-input">
						<AuthCode
							ref={AuthInputRef}
							onChange={handleOnChange}
							containerClassName="container"
							inputClassName="input"
						/>
					</div>
					<div className="flex items-center w-full justify-center">
						<div className="flex flex-col items-center w-full justify-center">
							{localStorage.getItem("authURL") ? (
								<button
									type="button"
									className="uppercase support-text-primary px-4"
									onClick={() => { navigate(QR_CODE_2FA_SCREEN); }}
								>
									{strings("TwoFactorAuthCodeScreen.back_to_qr")}
								</button>
							)
								: null}
							<button
								type="button"
								className="uppercase text-primary px-4 rounded-full border active-button w-3/5 h-10 mt-5"
								onClick={logoutUser}
								data-testid="logout-button"
							>
								{strings("TwoFactorAuthCodeScreen.logout")}
							</button>
						</div>
					</div>
					<div className="px-2 h-5 item-center justify-center pb-10">
						<span className="text-sm font-normal text-center text-red-600">
							{errorMessage}
						</span>
					</div>
				</div>
				<span className="absolute items-center bottom-5 xs-text">
					{strings("TwoFactorAuthCodeScreen.credo_version")}
					{" "}
					{packageJson.version}
				</span>
			</div>
		</>
	);
}
