import * as React from "react"
import {SVGAttributes} from "react";
import omit from "lodash/omit";

interface WalletIconInterface extends SVGAttributes<SVGElement> {
	walletColor: string;
	satsIcon?: boolean;
}

const SvgComponent = (props: WalletIconInterface) => {
	const {
		walletColor,
		satsIcon = false,
	} = props;

	const svgProps = omit(props, "walletColor", "satsIcon");

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={18}
			height={17}
			viewBox="0 0 18 17"
			{...svgProps}
		>
			<defs>
				<linearGradient x1="8.35442826e-13%" y1="50%" x2="50%" y2="50%" id="boost-wallet-linearGradient-3" gradientUnits="userSpaceOnUse">
					<stop stopColor="#00FFA5" offset="0%" />
					<stop stopColor="#00FF9E" stopOpacity="0" offset="100%" />
				</linearGradient>
				<linearGradient x1="8.35442826e-13%" y1="50%" x2="50%" y2="50%" id="boost-wallet-linearGradient-4" gradientUnits="userSpaceOnUse">
					<stop stopColor="#00FFA5" offset="0%" />
					<stop stopColor="#00FF9E" stopOpacity="0" offset="100%" />
				</linearGradient>
				<linearGradient x1="8.35442826e-13%" y1="50%" x2="50%" y2="50%" id="boost-wallet-linearGradient-5" gradientUnits="userSpaceOnUse">
					<stop stopColor="#00FFA5" offset="0%" />
					<stop stopColor="#00FF9E" stopOpacity="0" offset="100%" />
				</linearGradient>
				<linearGradient x1="50%" y1="50%" x2="4.62130334e-12%" y2="50%" id="boost-wallet-linearGradient-6" gradientUnits="userSpaceOnUse">
					<stop stopColor="#00FFA5" offset="0%" />
					<stop stopColor="#00FF9E" stopOpacity="0" offset="100%" />
				</linearGradient>
				<linearGradient x1="50%" y1="50%" x2="4.62130334e-12%" y2="50%" id="boost-wallet-linearGradient-7" gradientUnits="userSpaceOnUse">
					<stop stopColor="#00FFA5" offset="0%" />
					<stop stopColor="#00FF9E" stopOpacity="0" offset="100%" />
				</linearGradient>
				<linearGradient x1="50%" y1="50%" x2=".62130334e-12%" y2="50%" id="boost-wallet-linearGradient-8" gradientUnits="userSpaceOnUse">
					<stop stopColor="#00FFA5" offset="0%" />
					<stop stopColor="#00FF9E" stopOpacity="0" offset="100%" />
				</linearGradient>
			</defs>
			<g fill="none" fillRule="evenodd">
				<path
					fill="#212636"
					stroke={walletColor ?? "#0FF077"}
					strokeWidth={0.728}
					d="m3.429 2.064 9.435-1.13a2.54 2.54 0 0 1 1.872.522c.512.4.872.995.96 1.69l.8 6.371a2.54 2.54 0 0 1-.515 1.88c-.4.516-.997.878-1.693.966l-9.435 1.13a2.54 2.54 0 0 1-1.872-.522 2.54 2.54 0 0 1-.96-1.69l-.8-6.371a2.54 2.54 0 0 1 .515-1.88c.4-.516.997-.878 1.693-.966Z"
				/>
				<rect
					width={16.111}
					height={13.087}
					x={0.4}
					y={3.621}
					fill="#212636"
					stroke={walletColor ?? "#0BE0D8"}
					strokeWidth={0.8}
					rx={3.2}
				/>
				{satsIcon ? (
					<g transform="translate(3, 1)">
						<svg width="8px" viewBox="0 0 12 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
							<defs>
								<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="sats-linearGradient-1">
									<stop stopColor="#00FF44" offset="0%"></stop>
									<stop stopColor="#FFAD00" offset="38.4478802%"></stop>
									<stop stopColor="#422200" offset="100%"></stop>
								</linearGradient>
								<polygon id="sats-path-2" points="8.50044523 -1.47937218e-14 1.03153098 6.5 4.79379943 6.5 3.54781804 13 11.031531 6.5 7.24180787 6.5"></polygon>
							</defs>
							<g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
								<g id="icon---sats">
									<mask id="mask-3" fill="white">
										<use xlinkHref="#sats-path-2"></use>
									</mask>
									<use id="sats-Path-2" stroke="#FF8C01" strokeWidth="0.5" fill="url(#sats-linearGradient-1)" strokeLinecap="round" strokeLinejoin="round" xlinkHref="#sats-path-2"></use>
								</g>
							</g>
						</svg>
					</g>
				) : (
					<g strokeLinecap="round" strokeWidth={1.2}>
						<path
							stroke="url(#boost-wallet-linearGradient-3)"
							d="M3.75.385h.833"
							transform="translate(2.667 5.77)"
						/>
						<path
							stroke="url(#boost-wallet-linearGradient-4)"
							d="M2.083 1.923h2.5"
							transform="translate(2.667 5.77)"
						/>
						<path
							stroke="url(#boost-wallet-linearGradient-5)"
							d="M.417 3.462H6.25"
							transform="translate(2.667 5.77)"
						/>
						<path
							stroke="url(#boost-wallet-linearGradient-6)"
							d="M.417 5H6.25"
							transform="translate(2.667 5.77)"
						/>
						<path
							stroke="url(#boost-wallet-linearGradient-7)"
							d="M2.083 6.538h2.5"
							transform="translate(2.667 5.77)"
						/>
						<path
							stroke="url(#boost-wallet-linearGradient-8)"
							d="M2.083 8.077h.834"
							transform="translate(2.667 5.77)"
						/>
					</g>
				)}
				<path
					fill="#212636"
					stroke={walletColor ?? "#0FF077"}
					strokeWidth={0.8}
					d="M16.72 7.705v4.2h-2.2a1.994 1.994 0 0 1-1.415-.586 1.994 1.994 0 0 1-.586-1.414c0-.64.217-1.189.578-1.58.361-.39.865-.62 1.422-.62h2.2Z"
				/>
			</g>
		</svg>
	)
}
export default SvgComponent
