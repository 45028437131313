/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGAttributes } from "react";

const ClearNewPostTitle = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--off-white)",
	} = props;
	return (
		<svg
			width="10px"
			height="10px"
			viewBox="0 0 10 10"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<g id="Posting---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.9" strokeLinecap="round">
				<g id="Post---Image---Credo" transform="translate(-161.000000, -192.000000)" stroke={stroke}>
					<g id="close-icons" transform="translate(157.000000, 188.000000)">
						<g id="Group" transform="translate(5.000000, 5.000000)">
							<line x1="0" y1="0" x2="7.89777778" y2="7.89777778" id="Line" />
							<line
								x1="0"
								y1="0"
								x2="7.89777778"
								y2="7.89777778"
								id="Line"
								transform="translate(4.000000, 4.000000) scale(-1, 1) translate(-4.000000, -4.000000) "
							/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default ClearNewPostTitle;
