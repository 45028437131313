export enum PostHeaderReducerCases {
	SetItem = "POST_HEADER/set_item",
	SetSharedItem = "POST_HEADER/set_shared_item",
	SetUMASSStreamItem = "POST_HEADER/set_UMASS_stream_item",
}

export enum DefaultStreamNames {
	MainWallCredoStreamId = "mwc",
	MainWallEgoStreamId = "mwe",
	UmasStreamName = "UMAS",
	CredoBlastStreamId = "cb",
}

export enum PostHeaderConst {
	In = "in",
	Edited = " [Edited]",
	E = "[E]",
	TAGS_IN_CONTEXT = "Tags in context"
}

export interface PostMsgs {
	contentSensitiveMsg: string;
	imageErrorMsg: string;
	cannotRateOwnPostMsg: string;
	switchToCredoModeToRate: string;
	sharedPostDeleted: string;
}

export enum PostHeaderScreenType {
	Stream = "stream",
	User = "user",
	Bot = "b"
}

export interface MoreMenuInterface {
	title: string;
	icon: any;
	onClick: () => void;
}

export enum CredoTagSource {
	POST = "post",
	AUTHOR_POST = "author_post",
	AUTHOR_COMMENT = "author_comment"
}
