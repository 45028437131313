import React, { SVGAttributes } from "react";

// eslint-disable-next-line import/prefer-default-export
const MoreSubCommentEgo = (props: SVGAttributes<SVGElement>) => {
	const { fill = "#EEEEEE", stroke = "#DEE0EC" } = props;
	return (
		<svg
			width="40px"
			height="10px"
			viewBox="0 0 40 10"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<g id="ind-more-ego" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Group-2">
					<rect id="Rectangle" stroke={stroke} fill={fill} x="0.5" y="0.5" width="39" height="9" rx="4.5" />
					<g id="Group" transform="translate(10, 3)" fill="#8CD4FF">
						<circle id="Oval" cx="10" cy="2" r="2" />
						<circle id="Oval-Copy-2" cx="18" cy="2" r="2" />
						<circle id="Oval-Copy" cx="2" cy="2" r="2" />
					</g>
				</g>
			</g>
		</svg>
	);
};

export default MoreSubCommentEgo;
