import React, { useRef } from "react";
import { classNames } from "./twui-utils";

interface LazyWrapperProps {
	/**
	 * can pass a condition when the component
	 * must be visible.
	 * @default false
	 * */
	visible: boolean;
	/**
	 * Pass the component in the child node which
	 * can be anything, can be a text or a JSX
	 * Element.
	 * */
	children: any;
}

/**
 * Helpful for rendering content/panels in tabs if
 * we don't want the unselected tabs to render on
 * the first mount. Once the other unselected tabs'
 * content are mounted they will always be mounted
 * without remounting when tabs are switched.
 * */
// eslint-disable-next-line import/prefer-default-export
export const LazyWrapper: React.FC<LazyWrapperProps> = (props: LazyWrapperProps) => {
	const {
		visible,
		children,
	} = props;
	const rendered = useRef<boolean>(visible);

	if (visible && !rendered.current) {
		rendered.current = true;
	}

	if (!rendered.current) return null;

	return (
		<div className={classNames(
			visible ? "block" : "hidden",
		)}
		>
			{children}
		</div>
	);
};
