// eslint-disable-next-line import/prefer-default-export
export enum RankDelta {
	POSITIVE = "+ve",
	NEGATIVE = "-ve",
	SAME = "==",
}

export enum BoostIncrementStatus {
	INITIAL = "i",
	PENDING = "p",
	SUCCESS = "s",
	ERROR = "e"
}
