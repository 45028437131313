import React from "react";
import { TagIconType } from "@credo/utilities";

interface BoostIconsProps {
	/**
	 * Based on these iconType the icon will be rendered
	 * */
	iconType: TagIconType;
}

const BoostIconsComponent: React.FC<BoostIconsProps> = (props: BoostIconsProps) => {
	const {
		iconType,
	} = props;

	switch (iconType) {
		case TagIconType.BOOSTABLE:
			return (
				<img
					height="100%"
					width="100%"
					alt="campaignIcon"
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
					// To make this possible the icon should be in icons folder
					// inside public folder of root of the project
					src="/icons/boostBoostable.png"
				/>
			);
		case TagIconType.BOOSTED:
			return (
				<img
					height="100%"
					width="100%"
					alt="campaignIcon"
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
					// To make this possible the icon should be in icons folder
					// inside public folder of root of the project
					src="/icons/boostBoosted.png"
				/>
			);
		case TagIconType.USER_BOOSTED:
			return (
				<img
					height="100%"
					width="100%"
					alt="campaignIcon"
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
					// To make this possible the icon should be in icons folder
					// inside public folder of root of the project
					src="/icons/boostUserBoosted.png"
				/>
			);

		default: return null;
	}
};

const compareFn = (prev: BoostIconsProps, next: BoostIconsProps) => prev.iconType === next.iconType;

/**
 * To render the boost icon we have 3 types of icons
 * to render. Based on each TagIconType an icon is
 * rendered.
 * */
// eslint-disable-next-line import/prefer-default-export
export const BoostIcons = React.memo(
	BoostIconsComponent,
	compareFn,
);
