/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGAttributes } from "react";

const ReportPost = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--gray-dark)",
	} = props;
	return (
		<svg
			width="16px"
			height="20px"
			viewBox="0 0 16 20"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<g id="Streams---CT---190723" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-icons" transform="translate(-377.000000, -286.000000)" stroke={stroke}>
					<g id="report_icon" transform="translate(377.000000, 287.000000)">
						<line x1="0.5" y1="0" x2="0.5" y2="18" id="Line-4" strokeLinecap="round" />
						<polyline id="Rectangle" points="0.5 2 14.5 2 11.5 6 14.5 10 0.5 10" />
					</g>
				</g>
			</g>
		</svg>
	);
};

export default ReportPost;
