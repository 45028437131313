import React, {
	useCallback, useEffect, useMemo, useRef, useState,
} from "react";
import {
	cu, dbg, LogMgr, useMeasure,
} from "@credo/utilities";
import { useAtomValue } from "jotai";
import { isCredoModeAtom } from "@credo/store";
import { classNames, SvgIcon } from "../common";
import { XThinIcon, CircularProgressBarTimebaseIcon } from "../assets/icons";
import {
	DisplayModes, DOMRectExtended, HintsData,
} from "./types";
import { HintMgr } from "./HintMgr";
import styles from "./hint.module.css";
import { HintStyler } from "./HintStyler";
import { SecondaryButton, ButtonSize } from "../button";

interface HintProps {
	/**
	 * Single Hint data
	 * @see HintsData
	 * */
	data: HintsData;
	/**
	 * To re-render the component when dom is updated
	 * or UI gets shifted to calculate the new position
	 * of the target component and render the hint
	 * near the target component.
	 * */
	updateId?: string;
}

type HintRenderPosition = { xAxis: number, yAxis: number, zIndex: number | string } | undefined

/**
 * Hint Isolated hint component which will take the props
 * and check if the target exists on the dom or not and then
 * render the hint over that target. To check with some other
 * functionality like if the UI shifts when hints are being
 * rendered please use HintWrapper component with the data
 * since it adds a observer on the dom if dom changes.
 * */
const HintComponent: React.FC<HintProps> = (props: HintProps) => {
	const {
		data,
		updateId,
	} = props;

	const {
		content,
		anchor,
		present,
		id,
	} = data;

	const {
		title,
		body,
		buttonTitle,
		onClickButton,
		onCloseHint,
	} = content;

	const [hintRenderPosition, setHintRenderPosition] = useState<HintRenderPosition>(undefined);
	const [visible, setVisible] = useState<boolean>(false);
	const [contentChildRef, { height, width }] = useMeasure();
	const isCredoMode = useAtomValue(isCredoModeAtom);

	// eslint-disable-next-line no-undef
	const autoHideIntervalRef = useRef<NodeJS.Timeout | null>(null);
	// eslint-disable-next-line no-undef
	const visibilityTimeoutRef = useRef<NodeJS.Timeout | null>(null);

	const alignmentStyler = new HintStyler(styles.mountScale);

	const autoHide = useCallback(() => {
		if (dbg) {
			LogMgr.mydbg("Hint Component: auto hide active for hint with id => ", data.id);
		}
		if (present?.autoHide && typeof present.autoHide === "number") {
			autoHideIntervalRef.current = setTimeout(() => {
				HintMgr.removeHintById(id);
			}, present.autoHide);
		}
	}, []);

	/**
	 * Positioning of the component will be determined by the
	 * position of anchor provided in the props.
	 *
	 * based on anchor.alignment component position will be determined:
	 * top-left: the component will be shown in the top right
	 * with respect to the component on which hint will be shown
	 *
	 * top-right: the component will be shown in the bottom left
	 * with respect to the component on which hint will be shown
	 * */
	const getParentDimensions = (dimensions: DOMRectExtended) => {
		if (dimensions && dimensions.height > 0) {
			let yAxis: number;
			let xAxis: number;
			switch (anchor.alignment) {
				case "bottom-left":
					// it will in center of component's yAxis
					yAxis = dimensions.top + (dimensions.height / 2) - height;
					// it will in center of component's xAxis
					xAxis = dimensions.left + (dimensions.width / 2);
					break;
				case "bottom-right":
					// it will in center of component's yAxis subtract the height of own hint component
					yAxis = dimensions.top + (dimensions.height / 2) - height;
					// it will in center of component's xAxis subtract the width of own hint component
					xAxis = dimensions.left + (dimensions.width / 2) - width;
					break;
				case "top-right":
					// it will in center of component's yAxis
					yAxis = dimensions.top + (dimensions.height / 2);
					// it will in center of component's xAxis subtract the width of own hint component
					xAxis = dimensions.left + (dimensions.width / 2) - width;
					break;
				case "top-left":
				default:
					// it will in center of component's yAxis
					yAxis = dimensions.top + (dimensions.height / 2);
					// it will in center of component's xAxis
					xAxis = dimensions.left + (dimensions.width / 2);
					break;
			}

			/**
			 * finally adding the offsets coming from the backend to the calculated offsets
			 * */
			setHintRenderPosition({
				yAxis: yAxis + (anchor?.yOffset || 0),
				xAxis: xAxis + (anchor?.xOffset || 0),
				zIndex: dimensions?.zIndex,
			});

			/**
			 * Some kind of debounce you could say so until the width and the position is
			 * not set finally the hint component will not be visible. On first render if
			 * the alignment is set as top-right then the UI was shifting due to we need
			 * width to calculate the position, and we don't have width initially.
			 * */
			if (width > 0 && present.delay === 0) {
				if (visibilityTimeoutRef.current) {
					clearTimeout(visibilityTimeoutRef.current);
				}
				visibilityTimeoutRef.current = setTimeout(() => {
					setVisible(true);
				}, 50);
			}

			if (width > 0 && present.delay > 0) {
				if (visibilityTimeoutRef.current) {
					clearTimeout(visibilityTimeoutRef.current);
				}
				visibilityTimeoutRef.current = setTimeout(() => {
					setVisible(true);
				}, present.delay);
			}
		} else {
			setHintRenderPosition(undefined);
		}
	};

	const findTargetElement = () => {
		if (document) {
			try {
				// Extracting the dimensions of the target element using locatorProc
				// Works in storybook with 'new' keyword that is 'new ElementLoc.getCredoSwitch();'
				// eslint-disable-next-line no-eval
				const dimensions: DOMRectExtended = eval(`${anchor.locatorProc};`);
				getParentDimensions(dimensions);
			} catch (error) {
				LogMgr.mydbg(`Error rendering hint for ${present.trigger} trigger and ${id}`, error);
			}
		}
	};

	/**
	 * When we resize the window our UI shifts so the coordinates
	 * of the target component may change, so we need to calculate
	 * the dimensions again.
	 * */
	useEffect(() => {
		if (window) {
			window.addEventListener("resize", findTargetElement);
		}
		return () => {
			window.removeEventListener("resize", findTargetElement);
		};
	}, [width]);

	useEffect(() => {
		findTargetElement();
	}, [width, anchor?.alignment, updateId]);

	useEffect(() => {
		if (present?.autoHide && typeof present.autoHide === "number" && visible) {
			autoHide();
		}
	}, [visible]);

	useEffect(() => () => {
		if (autoHideIntervalRef.current) {
			clearTimeout(autoHideIntervalRef.current);
		}
	}, []);

	/**
	 * Positioning of the ping animation will determine the
	 * position of the hint component with respect to the
	 * component on which we are showing the hint.
	 *
	 * top-left: Anchor will be top left
	 *
	 * top-right: Anchor will be top right
	 * */
	const anchorAlignmentClass: string = useMemo(() => {
		switch (anchor?.alignment) {
			case "bottom-right":
				return "bottom-0 right-0 -mb-1 -mr-1";
			case "bottom-left":
				return "bottom-0 left-0 -mb-1 -ml-1";
			case "top-right":
				return "top-0 right-0 -mt-1 -mr-1";
			case "top-left":
			default:
				return "top-0 left-0 -mt-1 -ml-1";
		}
	}, [anchor]);

	const handleCloseButtonClick = () => {
		if (autoHideIntervalRef.current) {
			clearTimeout(autoHideIntervalRef.current);
		}
		HintMgr.removeHintById(id);
		if (onCloseHint) {
			onCloseHint();
		}
	};

	const renderDismissButton = () => {
		const autoHideDuration = typeof present.autoHide === "number" ? (present.autoHide / 1000) : 0;
		return (
			<button
				className="flex absolute top-1/2 -right-4 z-[203] -translate-y-1/2 p-2 cursor-pointer pointer-events-auto"
				type="button"
				onClick={handleCloseButtonClick}
			>
				<div
					className="relative"
					style={{
						transform: "translate(18px, -1px)",
					}}
				>
					<CircularProgressBarTimebaseIcon
						percentage={0}
						intervalInSeconds={autoHideDuration}
						color="red"
						size={19}
						strokeWidth={2}
					/>
				</div>
				<div
					className="p-1 rounded-full bg-black cursor-pointer"
				>
					<SvgIcon
						height={8}
						width={8}
						icon={XThinIcon}
						color="var(--accent)"
					/>
				</div>
			</button>
		);
	};

	if (!hintRenderPosition) {
		return null;
	}

	if (cu.isSet(present.mode)) {
		switch (present.mode) {
			case DisplayModes.EGO:
				if (isCredoMode) {
					return null;
				} else {
					break;
				}
			case DisplayModes.CREDO:
				if (!isCredoMode) {
					return null;
				} else {
					break;
				}
			case DisplayModes.BOTH:
			default:
				break;
		}
	}

	const onHintAction = () => {
		handleCloseButtonClick();
		if (onClickButton) {
			onClickButton();
		}
	};

	return (
		<div
			// @ts-ignore
			ref={contentChildRef}
			style={{
				top: hintRenderPosition.yAxis,
				left: hintRenderPosition.xAxis,
				// 203 because header has the same zIndex, we can always pass customZIndex from ElementLocator class method
				zIndex: typeof hintRenderPosition.zIndex === "string" ? 203 : hintRenderPosition.zIndex,
			}}
			className={classNames(
				"inline-flex",
				anchor.fixed ? "fixed" : "absolute",
				/**
				 * On first render if the alignment is set as
				 * top-right then the UI was shifting due to we
				 * need width to calculate the position, and
				 * we don't have width initially.
				 * */
				visible ? "visible" : "invisible",
				visible && anchor.animate ? alignmentStyler.getAlignmentClass(anchor.alignment) : "",
				buttonTitle ? "" : "pointer-events-none",
				"border border-black rounded-md",
			)}
		>
			<span
				className={classNames(
					"flex absolute h-3 w-3 z-[203]",
					anchorAlignmentClass,
				)}
			>
				<span
					className={classNames(
						"absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75",
						styles.pingAnimation,
					)}
				/>
				<span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500" />
			</span>
			{visible && renderDismissButton()}
			<div
				className={classNames(
					"border-background-tertiary",
					"rounded-md",
					"bg-accent",
					"p-2",
					"max-w-[200px]",
					"text-sm",
					"relative",
					"flex flex-col",
				)}
			>
				<span className="text-md font-bold">
					{title}
				</span>
				<span className="text-xs whitespace-pre-wrap">
					{body}
				</span>
				{buttonTitle && (
					<div className="w-full flex justify-center items-center">
						<SecondaryButton
							size={ButtonSize.SMALL}
							label={buttonTitle}
							dimensionsClasses="!text-background-tertiary mt-2 w-[48%]"
							buttonClassNames={classNames("rounded-full justify-center items-center capitalize !bg-primary")}
							handleClick={onHintAction}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

const compareFunction = (nextProps: HintProps, prevProps: HintProps) => JSON.stringify(nextProps.data) === JSON.stringify(prevProps.data)
		&& nextProps.updateId === prevProps.updateId;

// eslint-disable-next-line import/prefer-default-export
export const Hint = React.memo(
	HintComponent,
	compareFunction,
);

HintComponent.defaultProps = {
	updateId: "",
};
