export enum YesNoOptions {
  YES = "y",
  NO = "n",
}

export enum StreamModes {
  CREDO = "c",
  EGO = "e",
  BOTH = "ec"
}

export enum ThemeOptions {
  CREDO = "credo",
  EGO = "ego"
}

export enum AppMode {
  CREDO = "c",
  EGO = "e",
}

export enum ConnInfoState {
  OPEN = "open",
  CLOSE = "close",
  ERROR = "error",
}

export enum PostType {
  Post = "p",
  RewardsPost = "rp",
  SugConPost = "sugcon",
  FollowUser = "fu",
  UnfollowUser = "dfu",
  ConnectUser = "cu",
  BlockedUser = "bu",
  CancelConnectUser = "ccu",
  UnconnectUser = "dcu",
  FollowStream = "fs",
  JoinStream = "js",
  NewStream = "ns",
  UxpUserRatingTask = "xpur",
  UserRatingCard = "xp_rating_card",
  NoInternet = "NoInternet",
  ExploreComm = "exploreComm",
  SugCom = "sugcomms",
  ExplainPost = "explain_post",
  RateExpertise = "rx",
  MadeStreamAdmin = "jsa",
  LeftStream = "du",
  NewPost = "np",
  CreatePost = "cp",
  CreateComment = "cc",
  DeleteStream = "ds",
  DiscoverySetting = "ds",
  PrivacySettingFollower = "psf",
  PrivacySettingMember = "psm",
  AccessControlAutoJoin = "acaj",
  AccessControlFollowCommunity = "acf",
  AccessControlJoinCommunity = "acj",
  AccessControlAllowPost = "acap",
  RemovedFromCommunity = "ku",
  RejectedRequest = "rj",
  RemovedAsAdmin = "dsa",
  AddedAsAdmin = "nsa",
  Tag = "tag",
  EmojiReactionPost = "erp",
  EmojiReactionComment = "erc",
}

export enum RequestType {
  ConnectUser = "cu",
  JoinStream = "js",
}

export enum PostTime {
  PostedNow = "posted now",
  MinAgo = "min ago",
  HourAgo = "hour ago",
  HoursAgo = "hours ago",
  Yesterday = "Yesterday",
  M = "m",
  H = "h",
  Ytd = "Ytd",
  Now = "Now"
}

// TODO: Add info for what each status denotes in comment doc
export enum ResponseCode {
  /**
   * Response success code
   * */
  OK = "ok",
  /**
   * Response success code
   * */
  SUCCESS = "success",
  /**
   * Type to check if the response code starts with OK_ since
   * it will be success response.
   * */
  OK_WITH = "OK_",
  /**
   * Success Response code when user successfully logs in
   * using QR code.
   * */
  OK_LOGIN_QR = "OK_LOQR",
  /**
   * Success Response code when user successfully authenticates
   * using QR code.
   * */
  OK_QR_AUTH = "OK_QR_AUTH",
  /**
   * Success Response code when user's email for verification is
   * found in the system.
   * */
  OK_EMFO = "OK_EMFO",
  /**
   * Success Response code when user is successfully authenticated
   * but the user also has another social account added to this
   * number, so we should ask user if user wants to continue with
   * the same account or create a new account.
   * */
  OK_REQ_ELNA = "OK_REQ_ELNA",
  /**
   * Success Response code when user is successfully verified,
   * but the account is not present in the db, i.e, the mobile
   * number is not registered with any account.
   * */
  OK_REQ_CCNA = "OK_REQ_CCNA",
  /**
   * Success response code when user successfully updates the
   * email.
   * */
  OK_EMUP = "OK_EMUP",
  /**
   * Success response code when user successfully updates the
   * custom email.
   * */
  OK_CEUP = "OK_CEUP",
  OK_DELE = "OK_DELE",
  /**
   * Success Response code when user successfully logs in but
   * user is suspended.
   * */
  OK_USR_SUSP = "OK_USR_SUSP",
  /**
   * Success Response of new user has been created, and his
   * session is also been created.
   * */
  OK_NUCR = "OK_NUCR",
  OK_NUVU = "OK_NUVU",
  OK_LOBP = "OK_LOBP",
  /**
   * Success response with a redirect url. We are using it
   * for findOrCreateUser response where if we get this code
   * we will redirect to the url which is there in the info field.
   * */
  OK_FWD_URL = "OK_FWD_URL",
  /**
   * Error Response code: Email sent not found in the
   * db associated with any user.
   * */
  ERR_EMNF = "ERR_EMNF",
  /**
   * Error Response code: Bad Phone User Combination
   * Also thrown when user tries to authenticate using
   * phone number 1 which is not associated with email 2,
   * email 2 is associated with phone number 2.
   * */
  ERR_BADPUC = "ERR_BADPUC",
  ERR_MAIL = "ERR_MAIL",
  /**
   * Response codes while fetching stream info
   * */
  ERR_STNA = "ERR_STNA", // Stream Not Available
  /**
   * Error Response code: Required Re-authentication
   * Session is invalid user needs to log in again
   * */
  REQ_REAU = "REQ_REAU",
  /**
   * Error Response code: Error while creating post
   * */
  ERROR_POST = "ERROR_POST",
  /**
   * Error Response code: Error while creating post for adult content.
   * */
  ERR_ADCH = "ERR_ADCH",
  /**
   * Error Response code: Shared post deleted.
   * */
  ERR_SIIS = "ERR_SIIS",
  /**
   * Error Response code: Invalid parameters sent
   * */
  ERR_INVS = "ERR_INVS",
  /**
   * Error Response code: QR code invalid. When checking with server
   * To make it valid scan it from mobile application.
   * */
  ERR_QR_NV = "ERR_QR_NV",
  /**
   * ok response from the server as 200
   * */
  OK_200 = "200",
  /**
    * Error Response code: Error sending a connection request
    * when a connection request is already pending.
    * */
  ERR_MUCP = "ERR_MUCP",
  /**
   * Error Response code: Error accepting connection request
   * When the user is already connected.
   * */
  ERR_MUCU = "ERR_MUCU",
  /**
   * Success response code when user successfully updates the
   * phone number.
   * */
  OK_PHUP = "OK_PHUP"
}

export interface GenericResponse<ItemsType> {
  addr: string;
  msg_id: string;
  procTs: number;
  replyAddress: string;
  retcd: ResponseCode;
  sendTs: number
  sid: string;
  wrap_id: string;
  items: ItemsType;
  egousername?: string;
  username?: string;
}

export enum DiscoveryOptions {
  owener_connections = "owco",
  private = "priv",
  participant_connections = "paco",
  connections_of_connections = "coco",
  public = "publ",
  blocked = "bl",
}

export const RegEx = {
	url: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
	hashTag: /(?:\s|^)#[A-Za-z0-9\-._]+(?:|$)/gi,
	userTag: /@\[([^@[]*)]\((([\d\w,._-]*))\)/gi,
	userTagUsernameOnly: /@([^@[ ]*)/gi,
	boldTag: /\*([^*]+?)\*/gi,
	youtubeVideo: /(?:youtube(?:-nocookie)?\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/,
  emailValidation: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
};

export enum StatusType {
  PENDING = "pending",
  APPROVED = "approved",
  /**
   * Error status while checking the code.
   * Mostly when sent code is invalid.
   * */
  CODE_CHECK_ERROR = "code check error: ",
}

export enum ActionType {
  /**
   * Action to verify user's phone number
   * */
  VERIFY_PHONE_NUMBER = "verifyPhoneNb",
  /**
   * To check the code sending by the user
   * */
  CHECK_CONF_CODE = "checkConfCode",
  /**
   * Set while registering a new account
   * with phone number
   * */
  REGISTER_NEW_USER = "uwnareg",
  /**
   * User want to create a new account using the
   * passed phone number. This will remove the
   * entered number with any existing account
   * and will mapped with the new account which
   * is going to be created now.
   * */
  REGISTER_NEW_USER_WITH_PHONE = "uwnasp",
  /**
   * Mainly user while creating a new user using
   * an email.
   * */
  CREATE = "c",
  /**
   * Action to get the items
   * Used for global Search history
   * */
  GET = "g",
  /**
   * Action to add any item
   * */
  ADD = "a",
  /**
   * Action to delete any item
   * */
  DELETE = "d",
  /**
  * Action to update any item
  * */
  UPDATE = "u",
  /**
   * Action to share any item
   * */
  SHARE = "sh",
  /**
   * Remove item mainly used for
   * remove recommendation from the explore page
   * */
  REMOVE = "x",
  /**
   * Check if the QR code which being sent has authenticated by
   * the server or not.
   * */
  CHECK_QR = "checkqr"
}

export interface FeedItem {
	comments: any[];
	emoCode: (string | null)[][];
	emoCountA: (number | null)[][];
	postTags: (string)[];
	id: number;
	nb_of_emo: number;
	nb_ratings: number;
	"p.creat_ts": number;
	"p.created_by": any; // FIXME: Type needs to be updated
	"p.edit_ts": number;
	"p.mode": AppMode;
	"p.modif_ts": number;
	"p.nb_views": number;
	"p.postText": string;
	"p.postTitle": string;
	"p.postType": PostType;
	"p.postUuid": string;
	"p.prof_id": string;
	"p.profilePicRelUrl": string;
	"p.username": string;
	"p.firstName": string;
	"p.lastName": string;
	"p.shared_cnt"?: number;
	"p.egousername": string;
	"s.struname": string;
	"p.nb_of_co": number;
	"s.stramId": string;
	"p.shared_postUuid": string;
	"p.shared_firstName": string;
	"p.shared_lastName": string;
	"p.shared_egousername": string;
	"p.shared_username": string;
	"p.article_date": number;
	p_dc?: string;
	p_edit: YesNoOptions;
	"s.name": string;
	"s.streamId": string;
	cardData: any[]; // FIXME make sub-interfaces for different types of feed items
	postFlare: string;
	sortedPostTags: any;
	authorTags: any;
	"isAdmin"?: boolean;
}

export enum TagRankType {
  // TODO: Get some information about the tags and add it here
  IMPLIED = "i",
  CLAIMED = "c",
  PRIMARY = "p"
}

export enum TagIconType {
	BOOSTABLE = "boostable",
	BOOSTED = "boosted",
	USER_BOOSTED = "userBoosted",
}

export interface PostTag {
  subjectivity: number,
  engagement: number,
  tag: string,
  relevance: number,
  g_credo_score: number

  has_campaign?: boolean,
  is_interest?: boolean,
  b_credo_score?: number,
  s_credo_score?: number,
  xpRank?: TagRankType,
	icon?: TagIconType;
}

export enum NoRateTooltipFor {
  ModeSwitch = "modeSwitch",
  OwnPost = "ownPost",
  ShowTutorial = "showTutorial",
}

export enum EmotionsType {
  POST = "post",
  COMMENT = "comment",
}
export enum EmoActionType {
  CREATE = "c",
  UPDATE = "up",
  DELETE = "d",
}

export interface EmojiItem {
  code: string;
  selected?: boolean;
  short_name?: string;
  // is this correct?
  value: any;
  emoji?: string;
}

// Used for graph data
export type LabelledData = {
	value: number;
	label: any;
}

// statuses for loading data
export enum DataStatus {
	LOADING = "loading",
	REFRESHING = "refreshing",
	LOADED = "loaded",
	ERROR = "error",
	APPENDING = "appending",
	INIT = "init",
}

export interface WalletSummaryForGraph {
	boost_value_history: LabelledData[];
	wallet_balance_history: LabelledData[];
	hasError: boolean;
	dataStatus: DataStatus;
	wallet_balance: number;
	wallet_24h: string;
	boost_24h: string;
	boost_balance: number,
}
