import React, { ReactNode, useRef } from "react";
import omit from "lodash/omit";
import { classNames } from "../../common";

type FormControlElement = HTMLInputElement | HTMLTextAreaElement;

// TODO: Add loading param to display checking under the input field
interface InputFieldInterface extends React.InputHTMLAttributes<FormControlElement>{
	/**
	 * Unique id for the input fields also can be used for test-id
	 * @default ""
	 * */
	id?: string;
	/**
	 * Value which needs to be set in the input field
	 * @default ""
	 * */
	value: string | number
	/**
	 * Takes a function with the parameter event, gives access to the event parameter.
	 * Inside the event we get the value typed in the input field by event.target,value.
	 *
	 * @param {any} event - Event has all the attributes of html input
	 * */
	onChange: (event: any) => void;
	/**
	 * Set string value to display that string as an error under the input field.
	 * @default ""
	 * */
	error?: string;
	/**
	 * Takes the css style classname for input inside the wrapper.
	 * @default ""
	 * @see wrapperClassName
	 * */
	inputClassName?: string;
	/**
	 * Takes the css style classname for the wrapper around the input.
	 * @default ""
	 *
	 * @see inputClassName
	 * */
	wrapperClassName?: string;
	/**
	 * Takes Html element or direct svg icon to be rendered on the
	 * left side of the input field inside the wrapper. Due to this
	 * no ui will be disturbed. Icon should be added with the correct size
	 * which can be fit inside the input field wrapper.
	 *
	 * @default null
	 *
	 * @see rightIcon
	 * */
	leftIcon?: ReactNode;
	/**
	 * Takes Html element or direct svg icon to be rendered on the
	 * right side of the input field inside the wrapper. Due to this
	 * no ui will be disturbed. Icon should be added with the correct size
	 * which can be fit inside the input field wrapper.
	 *
	 * @default null
	 *
	 * @see leftIcon
	 * */
	rightIcon?: ReactNode;
	/**
	 * Set true to display a multiline input field. It will convert
	 * normal html input to text area, with which we can also pass
	 * the number of rows.
	 *
	 * @default false
	 * */
	isMultiline?: boolean;
	/**
	 * Below interfaces are extended from textarea
	 * */
	/**
	 * Sets or retrieves the width of the object.
	 * */
	cols?: number;
	/**
	 * Sets or retrieves the number of horizontal rows contained in the object.
	 * */
	rows?: number,
	// eslint-disable-next-line react/require-default-props
	readonly textLength?: number;
	/**
	* Sets or retrieves how to handle word wrapping in the object.
	* */
	wrap?: string;
	/**
	 * sets error css classes when error is rendered under the input field
	 * */
	errorClassName?: string;
}

/**
 * This is default minimum height for the textarea of the rows are
 * not added in the field
 * */
const MIN_TEXTAREA_HEIGHT = 32;

/**
 * A customised input field for Credo UI which matches the Credo UI
 * design, with all the existing input field props readily available
 * in it.
 * */
// eslint-disable-next-line import/prefer-default-export
export const InputField: React.FC<InputFieldInterface> = (props: InputFieldInterface) => {
	const textareaRef = useRef<any>(null);
	const {
		onChange,
		value,
		id,
		error = "",
		inputClassName = "",
		wrapperClassName = "",
		leftIcon = null,
		rightIcon = null,
		isMultiline = false,
		errorClassName = "",
	} = props;

	/**
	 * omit the props which we are adding for our custom input
	 * field wrapper and pass the new filtered props to the input
	 * field so that we will not get any errors in the console.
	 * */
	const inputProps = omit(
		props,
		[
			"id",
			"error",
			"inputClassName",
			"wrapperClassName",
			"leftIcon",
			"rightIcon",
			"onChange",
			"value",
			"rows",
			"cols",
			"wrap",
			"textLength",
			"isMultiline",
			"errorClassName",
		],
	);

	/**
	 * omit the props which we are adding for our custom input
	 * field wrapper and pass the new filtered props to the text
	 * area field so that we will not get any errors in the console.
	 * */
	const textAreaProps = omit(
		props,
		[
			"id",
			"error",
			"inputClassName",
			"wrapperClassName",
			"leftIcon",
			"rightIcon",
			"onChange",
			"value",
			"ref",
		],
	);

	React.useLayoutEffect(() => {
		if (textareaRef.current) {
			// Reset height - important to shrink on delete
			textareaRef.current.style.height = "inherit";
			// Set height
			textareaRef.current.style.height = `${Math.max(
				textareaRef.current.scrollHeight,
				MIN_TEXTAREA_HEIGHT,
			)}px`;
		}
	}, [value]);

	/**
	 * Handles the type of input field which needs to be
	 * displayed if textarea is needed instead of normal
	 * input. We could have passed the component directly
	 * 'as?: Component = input' and pass as from the
	 * component, but it might get complex. Can be added
	 * in the future.
	 * */
	const renderInput = () => {
		if (isMultiline) {
			return (
				<textarea
					onChange={(event) => onChange(event)}
					ref={textareaRef}
					value={value}
					className={classNames(
						"bg-transparent",
						"text-input-multiline text-basic font-thin",
						"w-full h-10",
						"outline-none",
						"px-2.5 mt-3 pb-2",
						"resize-none",
						inputClassName,
					)}
					style={{
						minHeight: MIN_TEXTAREA_HEIGHT,
					}}
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...textAreaProps}
				/>
			);
		} else {
			return (
				<input
					key={id}
					data-testid={id}
					value={value}
					onChange={(event) => onChange(event)}
					className={classNames(
						"bg-transparent",
						"text-input-base text-basic font-thin",
						"w-full h-10",
						"outline-none",
						rightIcon ? "pl-4" : "px-4",
						"py-2",
						inputClassName,
					)}
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...inputProps}
				/>
			);
		}
	};

	return (
		<div className="relative w-full">
			<div
				className={classNames(
					"flex flex-row",
					"bg-input-bg relative",
					wrapperClassName,
					isMultiline ? "rounded-2xl" : "rounded-full",
				)}
			>
				{leftIcon && (
					<div className="flex justify-center items-center pl-4">
						{leftIcon}
					</div>
				)}
				{renderInput()}
				{rightIcon && (
					<div className="flex justify-center items-center px-2.5">
						{rightIcon}
					</div>
				)}
			</div>
			{error && (
				<span
					className={classNames(
						"text-error text-left text-support absolute mt-1 left-4",
						errorClassName,
					)}
				>
					{error}
				</span>
			)}
		</div>
	);
};

InputField.defaultProps = {
	id: "",
	error: "",
	inputClassName: "",
	wrapperClassName: "",
	leftIcon: null,
	rightIcon: null,
	isMultiline: false,
	cols: 5,
	rows: 1,
	wrap: "wrap",
};
