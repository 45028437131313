import React, { SVGAttributes, useCallback, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Lightbox from "react-image-lightbox";
import { classNames } from "../../common";
import "react-image-lightbox/style.css";

// eslint-disable-next-line no-undef
export interface CoverPictureProps extends SVGAttributes<HTMLOrSVGElement> {
	/**
	 * cover picture source url
	 * @default ""
	 * */
	coverPicUrl: string;
	/**
	 * Custom CSS class for cover picture
	 * @default ""
	 * */
	coverPicStyle?: string;
	showImagePreview?: boolean;
}

export function CoverPicture(props: CoverPictureProps) {
	const { coverPicUrl, coverPicStyle, showImagePreview } = props;
	const [loading, setLoading] = useState<boolean>(true);
	const [viewerIsOpen, setViewerIsOpen] = useState<boolean>(false);

	const onLoad = () => {
		setLoading(false);
	};

	const onCoverPictureClick = (event: React.MouseEvent<HTMLElement>) => {
		if (showImagePreview) {
			setViewerIsOpen(true);
		}
	};

	const closeLightbox = useCallback(() => {
		setViewerIsOpen(false);
	}, []);

	const renderImagePreviewModal = () => {
		if (viewerIsOpen) {
			return (
				<Lightbox
					mainSrc={coverPicUrl}
					onCloseRequest={closeLightbox}
					imageCaption=""
					onImageLoad={() => {
						window.dispatchEvent(new Event("resize"));
					}}
					wrapperClassName="bg-modal"
					reactModalStyle={{ backgroundColor: "var(--bg-modal)" }}
				/>
			);
		}
		return null;
	};

	return (
		<div className="flex flex-wrap justify-center" data-testid="cover-picture">
			{loading && (
				<SkeletonTheme
					baseColor="var(--background-skeleton)"
					highlightColor="var(--hightlight-skeleton)"
				>
					<div className="w-full">
						<Skeleton className={classNames("metric-card rounded-lg p-4 h-32 w-full cursor-pointer")} />
					</div>
				</SkeletonTheme>
			)}
			<img
				src={coverPicUrl}
				onClick={onCoverPictureClick}
				onLoad={onLoad}
				className={classNames(
					"w-full h-32 object-cover cursor-pointer",
					coverPicStyle!,
					loading ? "hidden" : "block",
				)}
				alt={coverPicUrl}
				aria-hidden
			/>
			{renderImagePreviewModal()}
		</div>
	);
}

CoverPicture.defaultProps = {
	coverPicStyle: "",
	showImagePreview: false,
};
