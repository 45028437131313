import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { classNames } from "../common";

interface VoltsSummarySkeletonProps {
	wrapperClasses?: string;
}

// eslint-disable-next-line import/prefer-default-export
const VoltsSummarySkeleton: React.FC<VoltsSummarySkeletonProps> = (props: VoltsSummarySkeletonProps) => {
	const {
		wrapperClasses,
	} = props;

	return (
		<div
			className={classNames(
				"w-full z-0",
				wrapperClasses,
			)}
		>
			<SkeletonTheme
				baseColor="var(--background-skeleton)"
				highlightColor="var(--hightlight-skeleton)"
			>
				<div className="grid grid-rows-3 grid-flow-col gap-4 text-white pb-4">
					<div />
					<Skeleton containerClassName="w-2/3" height={20} />
					<Skeleton containerClassName="w-2/3" height={20} />
					<Skeleton containerClassName="w-2/3" height={20} />
					<Skeleton containerClassName="w-2/3" height={20} />
					<Skeleton containerClassName="w-2/3" height={20} />
					<Skeleton containerClassName="w-2/3" height={20} />
					<Skeleton containerClassName="w-2/3" height={20} />
					<Skeleton containerClassName="w-2/3" height={20} />
					<div />
					<Skeleton containerClassName="w-2/3" height={20} />
					<Skeleton containerClassName="w-2/3" height={20} />
				</div>
				<Skeleton
					className="!rounded-3xl"
					containerClassName="w-full"
					height={200}
				/>
			</SkeletonTheme>
		</div>
	);
};

VoltsSummarySkeleton.defaultProps = {
	wrapperClasses: "",
};

export default VoltsSummarySkeleton;
