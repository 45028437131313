import * as React from "react"
import {SVGAttributes} from "react";
import omit from "lodash/omit";

interface SvgComponentProps extends SVGAttributes<SVGElement> {
	borderStroke?: string;
}

const SvgComponent = (props: SvgComponentProps) => {
	const {
		borderStroke = "var(--tag-green)",
	} = props;
	let svgProps = omit(props, ["borderStroke"]);
	return (
		<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
			<defs>
				<circle id="boost-path-1" cx="10" cy="10" r="8" />
				<linearGradient x1="8.35442826e-13%" y1="50%" x2="50%" y2="50%" id="boost-linearGradient-3" gradientUnits="userSpaceOnUse">
					<stop stopColor="#00FFA5" offset="0%" />
					<stop stopColor="#00FF9E" stopOpacity="0" offset="100%" />
				</linearGradient>
				<linearGradient x1="8.35442826e-13%" y1="50%" x2="50%" y2="50%" id="boost-linearGradient-4" gradientUnits="userSpaceOnUse">
					<stop stopColor="#00FFA5" offset="0%" />
					<stop stopColor="#00FF9E" stopOpacity="0" offset="100%" />
				</linearGradient>
				<linearGradient x1="8.35442826e-13%" y1="50%" x2="50%" y2="50%" id="boost-linearGradient-5" gradientUnits="userSpaceOnUse">
					<stop stopColor="#00FFA5" offset="0%" />
					<stop stopColor="#00FF9E" stopOpacity="0" offset="100%" />
				</linearGradient>
				<linearGradient x1="50%" y1="50%" x2="4.62130334e-12%" y2="50%" id="boost-linearGradient-6" gradientUnits="userSpaceOnUse">
					<stop stopColor="#00FFA5" offset="0%" />
					<stop stopColor="#00FF9E" stopOpacity="0" offset="100%" />
				</linearGradient>
				<linearGradient x1="50%" y1="50%" x2="4.62130334e-12%" y2="50%" id="boost-linearGradient-7" gradientUnits="userSpaceOnUse">
					<stop stopColor="#00FFA5" offset="0%" />
					<stop stopColor="#00FF9E" stopOpacity="0" offset="100%" />
				</linearGradient>
				<linearGradient x1="50%" y1="50%" x2=".62130334e-12%" y2="50%" id="boost-linearGradient-8" gradientUnits="userSpaceOnUse">
					<stop stopColor="#00FFA5" offset="0%" />
					<stop stopColor="#00FF9E" stopOpacity="0" offset="100%" />
				</linearGradient>
			</defs>
			<g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="CB-Icon-Oval">
					<circle id="Oval" stroke="#212636" fill={borderStroke} cx="10" cy="10" r="9.5" />
					<mask id="mask-2" fill="white">
						<use xlinkHref="#boost-path-1" />
					</mask>
					<use id="Icon-Back" fill="#212636" xlinkHref="#boost-path-1"/>
					<g id="CB-Icon-LB" mask="url(#mask-2)" strokeLinecap="round">
						<g transform="translate(4.000000, 3.500000)" id="l-bolt">
							<g transform="translate(2.000000, 1.000000)">
								<line x1="4.5" y1="0.5" x2="5.5" y2="0.5" id="Line-4" stroke="url(#boost-linearGradient-3)" strokeWidth="1.5" />
								<line x1="2.5" y1="2.5" x2="5.5" y2="2.5" id="Line-4-Copy" stroke="url(#boost-linearGradient-4)" strokeWidth="1.5"/>
								<line x1="0.5" y1="4.5" x2="7.5" y2="4.5" id="Line-4-Copy-2" stroke="url(#boost-linearGradient-5)" strokeWidth="1.5"/>
								<line x1="0.5" y1="6.5" x2="7.5" y2="6.5" id="Line-4-Copy-2" stroke="url(#boost-linearGradient-6)" strokeWidth="1.5"/>
								<line x1="2.5" y1="8.5" x2="5.5" y2="8.5" id="Line-4-Copy-4" stroke="url(#boost-linearGradient-7)" strokeWidth="1.5"/>
								<line x1="2.5" y1="10.5" x2="3.5" y2="10.5" id="Line-4-Copy-5" stroke="url(#boost-linearGradient-8)" strokeWidth="1.5"/>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	)
}

export default SvgComponent
