/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGAttributes } from "react";

const ProVersion = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--primary)" } = props;
	return (
		<svg
			width="14px"
			height="14px"
			viewBox="0 0 14 14"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<g id="Streams---CT---190723" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Stream---Credo---Community-Settings" transform="translate(-189.000000, -234.000000)" stroke={stroke}>
					<g id="Group-7" transform="translate(189.000000, 234.000000)">
						<polygon id="Stroke-1" points="1.567 13.5 12.245 13.5 12.245 11.364 1.567 11.364" />
						<polyline
							id="Stroke-3"
							points="12.2453 11.3645 12.2453 7.6435 9.7523 9.0195 6.9063 4.9575 4.0603 9.0195 1.5673 7.6435 1.5673 11.3645"
						/>
						<path
							d="M7.9743,1.5677 C7.9743,2.1577 7.4963,2.6357 6.9063,2.6357 C6.3173,2.6357 5.8383,2.1577 5.8383,1.5677 C5.8383,0.9777 6.3173,0.4997 6.9063,0.4997 C7.4963,0.4997 7.9743,0.9777 7.9743,1.5677 Z"
							id="Stroke-4"
						/>
						<path
							d="M2.6355,4.3147 C2.6355,4.9047 2.1575,5.3827 1.5675,5.3827 C0.9785,5.3827 0.4995,4.9047 0.4995,4.3147 C0.4995,3.7247 0.9785,3.2467 1.5675,3.2467 C2.1575,3.2467 2.6355,3.7247 2.6355,4.3147 Z"
							id="Stroke-5"
						/>
						<path
							d="M13.3131,4.3147 C13.3131,4.9047 12.8351,5.3827 12.2451,5.3827 C11.6561,5.3827 11.1771,4.9047 11.1771,4.3147 C11.1771,3.7247 11.6561,3.2467 12.2451,3.2467 C12.8351,3.2467 13.3131,3.7247 13.3131,4.3147 Z"
							id="Stroke-6"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default ProVersion;
