import React, { SVGAttributes } from "react";

const ThreeDot = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { stroke = "var(--gray-dark)", fill } = props;
	return (
		<svg width="20px" height="4px" viewBox="0 0 20 4" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
			<g id="Web-Version" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Web-Version-Mobile---Home-Credo" transform="translate(-20.000000, -447.000000)" fill={fill || stroke}>
					<g id="pop-up-copy-3" transform="translate(20.000000, 85.000000)">
						<g id="dot-dot-dot" transform="translate(0.000000, 362.000000)">
							<path d="M18,2.81038331 C17.5401786,2.81038331 17.1651786,2.43861607 17.1651786,1.98275862 C17.1651786,1.52690117 17.5401786,1.15513393 18,1.15513393 C18.4598214,1.15513393 18.8348214,1.52690117 18.8348214,1.98275862 C18.8348214,2.43861607 18.4598214,2.81038331 18,2.81038331 M18,0 C16.8973214,0 16,0.889585899 16,1.98275862 C16,3.07593134 16.8973214,3.96551724 18,3.96551724 C19.1026786,3.96551724 20,3.07593134 20,1.98275862 C20,0.889585899 19.1026786,0 18,0" id="Fill-1"/>
							<path d="M10,2.81038331 C9.54017857,2.81038331 9.16517857,2.43861607 9.16517857,1.98275862 C9.16517857,1.52690117 9.54017857,1.15513393 10,1.15513393 C10.4598214,1.15513393 10.8348214,1.52690117 10.8348214,1.98275862 C10.8348214,2.43861607 10.4598214,2.81038331 10,2.81038331 M10,0 C8.89732143,0 8,0.889585899 8,1.98275862 C8,3.07593134 8.89732143,3.96551724 10,3.96551724 C11.1026786,3.96551724 12,3.07593134 12,1.98275862 C12,0.889585899 11.1026786,0 10,0" id="Fill-1"/>
							<path d="M2,2.81038331 C1.54017857,2.81038331 1.16517857,2.43861607 1.16517857,1.98275862 C1.16517857,1.52690117 1.54017857,1.15513393 2,1.15513393 C2.45982143,1.15513393 2.83482143,1.52690117 2.83482143,1.98275862 C2.83482143,2.43861607 2.45982143,2.81038331 2,2.81038331 M2,0 C0.897321429,0 0,0.889585899 0,1.98275862 C0,3.07593134 0.897321429,3.96551724 2,3.96551724 C3.10267857,3.96551724 4,3.07593134 4,1.98275862 C4,0.889585899 3.10267857,0 2,0" id="Fill-1"/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}

export default ThreeDot;
