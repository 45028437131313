import React, { SVGAttributes } from "react";

// eslint-disable-next-line import/prefer-default-export
const PostDropdown = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--gray-dark)" } = props;
	return (
		<svg
			width="11px"
			height="5px"
			viewBox="0 0 11 5"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<g id="Posting---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
				<g id="All-icons" transform="translate(-358.000000, -657.000000)" stroke={stroke}>
					<polyline id="postDropdown" points="368 657 363.536492 662 359 657" />
				</g>
			</g>
		</svg>
	);
};

export default PostDropdown;
