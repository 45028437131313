import { Factory } from "./Factory";

export interface Configurator<T> {
	configure(obj: T): T;
}

export abstract class Manager<PropsT extends object> {
	private props: PropsT | undefined;

	constructor(props?: PropsT) {
		if (props) this.setProps(props);
	}

	public setProps(props: PropsT) {
		this.props = props;
	}
}

export class AppManager {
	static _singleton: AppManager;

	static instance(force?: boolean): AppManager {
		if (force || this._singleton == null) {
			this._singleton = new AppManager();
		}
		return this._singleton;
	}

	/** like a true singleton */
	private constructor() {
	}

	private instanceMap = new Map<string, Manager<any>>();

	/**
	 * this should be called by any object that is 'managed' by appManager
	 * Generics are provided for convenience in typescript
	 * @param factoryFn
	 */
	get<T extends Manager<any>>(className: string, forceCreate?: boolean, params?: any): T {
		if (!forceCreate && this.instanceMap.has(className)) return this.instanceMap.get(className) as T;

		const fc = this.configMap.get(className);
		if (!fc) throw new Error("Don't know how to instantiate:" + className);
		let res: T = fc[0].create(params);
		// apply default configurator
		res = fc[1].configure(res);
		// if res becomes something else, lets try configure again
		res = this.configure(res);

		this.instanceMap.set(className, res);
		return res;
	}

	private configMap = new Map<string, [Factory<any>, Configurator<any>]>();

	addManager<T>(className: string, factory: Factory<T>, configurator: Configurator<T>) {
		this.configMap.set(className, [factory, configurator]);
	}

	configure<T extends Manager<any>>(object: T): T {
		let res = object;
		const fc = this.configMap.get(object.constructor.name);
		if (fc) {
			res = fc[1].configure(res);
		}
		return res;
	}
}

export class PropsConfigurator<PropsT extends object, ManagerT extends Manager<PropsT>> implements Configurator<ManagerT> {
	#props: PropsT;

	constructor(props: PropsT) {
		this.#props = props;
	}

	configure(obj: ManagerT): ManagerT {
		obj.setProps(this.#props);
		return obj;
	}

}