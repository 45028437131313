/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
import React, {
	useState, useMemo, useRef, useEffect,
} from "react";
import "./css/table.css";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import Pagination from "./Pagination";
import { strings } from "../i18n/config";
import TableSkeleton from "./TableSkeleton";
import { TableHeader } from "../utils/types";
import { PAGINATION_LIMIT } from "../utils/constants";
import TooltipHelper from "./TooltipHelper";

interface TableProps {
	data: Array<Object> | [] | null | undefined;
	header: Array<TableHeader>;
	showActionButtons?: boolean;
	onClickDelete?: (arg: any) => void;
	showHeader?: boolean | true;
	showSortIcons?: boolean | true;
	isLoading: boolean | undefined;
	pagination?: {
		onPageChange: (currentPage: number) => void;
		hidePageNumber: boolean;
		scrollOnPageChange: boolean;
		page: number;
	},
	onItemClick?: (arg: any) => void;
	CustomColumnComponent?: any;
}

const Table = ({
	data,
	header,
	showActionButtons,
	onClickDelete,
	showHeader,
	showSortIcons,
	pagination,
	isLoading = true,
	onItemClick,
	CustomColumnComponent,
}: TableProps) => {
	const [sortFor, setSortFor] = useState("id");
	const [sort, setSort] = useState("asc");

	const pageRef = useRef<number>(1);

	const {
		hidePageNumber,
		scrollOnPageChange,
		onPageChange,
		page,
	} = pagination || {};

	useEffect(() => {
		if (pageRef.current && page === 1) { pageRef.current = page || 1; }
	}, [page]);

	const headers = showActionButtons && header?.length > 0 ? [...header, { title: strings("Table.actions"), value: "actions" }] : header;

	const sorting = () => data?.sort((a: any, b: any) => {
		if (a[sortFor] < b[sortFor]) {
			return sort === "asc" ? -1 : 1;
		}
		if (a[sortFor] > b[sortFor]) {
			return sort === "asc" ? 1 : -1;
		}
		return 0;
	});

	const sortedData = useMemo(() => sorting(), [sort, data]);

	const hasData = (sortedData?.length ?? 0) > 0;

	const renderHeader = () => headers.map((singleHeader: TableHeader, index: any) => {
		const item = sortedData && sortedData.length > 0 ? sortedData[0] : headers[index];
		return (
			<th key={index}>
				<div className="flex flex-row text-center place-content-start md:place-content-center">
					{singleHeader.title.toUpperCase()}
					{"  "}
					{singleHeader.helperText
						&& <div className="pl-2 mt-1"><TooltipHelper title={singleHeader.helperText} /></div>}
					<div key={index} className="place-content-start md:place-content-center">
						{showSortIcons && singleHeader.title !== strings("Table.actions")
							? (sortFor === Object.keys(item)[index] && sort === "desc"
								? (
									<FaSortDown
										className="sort-icon-style"
										onClick={() => {
											setSortFor(Object.keys(item)[index]);
											setSort("asc");
										}}
									/>
								)
								: (
									<FaSortUp
										className="sort-icon-style"
										onClick={() => {
											setSortFor(Object.keys(item)[index]);
											setSort("desc");
										}}
									/>
								))
							: null}
					</div>
				</div>
			</th>
		);
	});

	const renderBody = () => sortedData?.map((element: { [key: string]: any }, idx) => (
		<tr
			key={idx}
			className="hover:bg-gray-light"
		>
			{headers.map((attributeName: TableHeader, i) => {
				if (attributeName.value.toLowerCase() === "actions" && showActionButtons) {
					return (
						<td className="opration" key={`${i}-${attributeName.value}`} data-testid={`${idx}-${i}-${attributeName.value}`}>
							<button
								type="button"
								className="button"
								data-testid="table-action-button"
								onClick={() => onClickDelete && onClickDelete(element)}
							>
								{strings("Table.delete")}
							</button>
						</td>
					);
				}
				if (attributeName.showCustomColumnComponent && CustomColumnComponent) {
					return (
						<td
							key={`${i}-${attributeName.value}`}
							title={element[attributeName.value] || ""}
						>
							<CustomColumnComponent item={element} header={attributeName} />
						</td>
					);
				}
				return attributeName.isClickable
					? (
						<td
							key={`${i}-${attributeName.value}`}
							title={element[attributeName.value] || ""}
						>
							<button
								type="button"
								className="w-full truncate underline text-blue-600 hover:text-blue-800 "
								onClick={() => onItemClick && onItemClick({
									...element,
									...attributeName,
								})}
							>
								{element[attributeName.value] || ""}
							</button>
						</td>
					)
					: (
						<td
							key={`${i}-${attributeName.value}`}
							title={element[attributeName.value] || ""}
							className="truncate"
						>
							{element[attributeName.value] || ""}
						</td>
					);
			})}
		</tr>
	));

	const renderPagination = () => (
		<div className="block">
			{hasData && headers?.length > 0
				? (
					<Pagination
						currentPage={pageRef.current}
						totalCount={((hidePageNumber ? (data?.length ?? 0) + 1 : data?.length) ?? 0)}
						lastItemIndex={((pageRef.current - 1) * PAGINATION_LIMIT) + (data?.length || 0)}
						pageSize={PAGINATION_LIMIT}
						onPageChange={(currentPage: number) => {
							// setPage(currentPage);
							if (onPageChange) {
								onPageChange(currentPage);
							}
							pageRef.current = currentPage;
							if (scrollOnPageChange) {
								document.getElementById("main-content")?.scroll({
									top: 0,
									// behavior: "smooth",
								});
							}
						}}
						firstItemIndex={((pageRef.current - 1) * PAGINATION_LIMIT) + 1}
						hidePageNumber={hidePageNumber}
					/>
				)
				: null}
		</div>
	);

	return (
		<>
			{pagination && renderPagination()}
			<div id="tableContainer" data-testid="tableContainer">
				{!isLoading ? (
					<table id="user">
						{showHeader && hasData ? (
							<thead>
								<tr>{renderHeader()}</tr>
							</thead>
						) : null}
						{hasData && headers?.length > 0
							? (
								<tbody>
									{renderBody()}
								</tbody>
							)
							: (
								<tbody>
									<tr>
										<td colSpan={headers.length + 1}>
											<div className="grid place-content-center md:place-content-center h-24 w-full">
												<p className="place-self-center normal-text-style">
													{strings("Table.no_data_message")}
												</p>
											</div>
										</td>
									</tr>
								</tbody>
							)}
					</table>
				) : (
					<div className="z-0">
						<TableSkeleton />
					</div>
				)}
			</div>
			{pagination && renderPagination()}
		</>
	);
};

export default Table;
