import * as React from "react"
import { SVGAttributes } from "react";

const SvgComponent = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { stroke = "var(--send-comment-button)", fill } = props;
	return (
		<svg width={14} height={16} viewBox="0 0 14 16" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="m0 0 4.928 8L0 16l14-8L0 0Zm5.779 8.525L6.102 8l-.323-.525-2.872-4.662L11.984 8l-9.077 5.187 2.872-4.662Z"
				fill={fill || stroke}
				fillRule="evenodd"
			/>
		</svg>
	);
}

export default SvgComponent
