import React, { ChangeEvent, useMemo } from "react";
import Style from "./Slider.module.css";
import { classNames } from "../common";

interface SliderProps {
	/**
	 * Should be set as decimal as it will handle
	 * from 0-1
	 * */
	value: number;
	onActions: (value: number) => void;
	/**
	 * Set to the maximum value of slider, so it will
	 * adjust the steps of it and percentage will be
	 * displayed accordingly
	 * */
	maxValue?: number;
	/**
	 * Set displayed value's fixed decimal digits of
	 * slider
	 *
	 * @default 2
	 * */
	sliderPercentFixed?: number;
}

// eslint-disable-next-line import/prefer-default-export
const SliderComponent: React.FC<SliderProps> = (props: SliderProps) => {
	const {
		value,
		onActions,
		maxValue,
		sliderPercentFixed = 2,
	} = props;

	const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
		onActions(Number(event.currentTarget.value));
	};

	const positionValue = useMemo(() => {
		if (maxValue) {
			return (value / maxValue) * 100;
		}
		return value;
	}, [value, maxValue]);

	const positionValueFixed = useMemo(() => {
		if (Number(positionValue.toString().split(".")[1]) > 0) {
			return (positionValue).toFixed(sliderPercentFixed);
		}
		return positionValue;
	}, [positionValue]);

	return (
		<div className="relative">
			<div
				className={classNames(
					"absolute flex justify-center items-center",
					"pointer-events-none h-[18px] rounded-[9px] w-[40px]",
					"text-[10px] font-bold",
				)}
				style={{
					left: `${positionValue}%`,
					transform: `translate(${(positionValue * -1)}%, 6px)`,
				}}
			>
				{positionValueFixed}
				%
			</div>
			<input
				type="range"
				className={classNames(
					"w-full",
					Style.sliderRange,
				)}
				min={0}
				max={maxValue ?? 100}
				value={value}
				onChange={handleOnChange}
			/>
		</div>
	);
};

const compareFn = (
	nextProps: SliderProps,
	prevProps: SliderProps,
) => nextProps.value === prevProps.value
	&& JSON.stringify(nextProps.onActions) === JSON.stringify(prevProps.onActions)
	&& nextProps.maxValue === prevProps.maxValue;

// eslint-disable-next-line import/prefer-default-export
export const Slider = React.memo(
	SliderComponent,
	compareFn,
);
