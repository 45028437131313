import React from "react";
import { cu } from "@credo/utilities";
import { classNames, SvgIcon } from "../../common";
import { ClearNewPostTitleIcon, PostNoLongerAvailableIcon } from "../../assets/icons";
import { PostHeader } from "../post-header/PostHeader";
import { PostText } from "../post-text/PostText";
import PostLinkPreview from "../post-link-preview/PostLinkPreview";
import PostVideo from "../post-video/PostVideo";
import PostImages from "../post-image/PostImages";

export interface PostSharedProps {
	postData: any;
	isContentRestricted: boolean
	navigateToDetailsScreen: (isShared: boolean) => void;
	onLinkShareIconPress: (url: string) => void;
	imageErrorMsg: string;
	contentSensitiveMsg: string;
	sharedPostDeleted: string;
	navigateToUserOrStream: (data: any) => void;
	showRemoveIcon?: boolean;
	removeSharedPost?: (data: any) => void;
	showQuickLinkShareIcon?: boolean;
	showHandIcon?: boolean;
	/**
	 * set true if the component is being called inside
	 * PostCompactView or need a compact version of this
	 * component
	 *
	 * @default false
	 * */
	// eslint-disable-next-line react/require-default-props
	isCompactView?: boolean;
}

const PostShared: React.FC<PostSharedProps> = (props) => {
	const {
		postData,
		isContentRestricted,
		navigateToDetailsScreen,
		onLinkShareIconPress,
		imageErrorMsg,
		contentSensitiveMsg,
		sharedPostDeleted,
		navigateToUserOrStream,
		showRemoveIcon,
		removeSharedPost,
		showHandIcon,
		isCompactView,
	} = props;

	const renderDeletedSharedPost = () => {
		if (postData["p.origDeleted"] && cu.isYes(postData["p.origDeleted"])) {
			return (
				<div className="flex w-full h-40 flex-col bg-content-L1 border rounded-15
					border-content-L2 items-center justify-center"
				>
					<SvgIcon icon={PostNoLongerAvailableIcon} />
					<span className="mt-1 text-xs text-gray-dark font-regular text-center px-4">{sharedPostDeleted}</span>
				</div>
			);
		}
		return null;
	};

	const renderSharedPostTitle = () => {
		if (cu.isSet(postData["p.shared_postTitle"]) || cu.isSet(postData["p.shared_postText"])) {
			return (
				<PostText
					item={postData}
					isSharedPost
					navigateToMentionedUser={navigateToUserOrStream}
					showHandIcon={showHandIcon}
					isCompactView={isCompactView}
				/>
			);
		}
		return null;
	};

	const renderSharedPostMedia = () => (
		<div>
			<PostImages
				postData={postData}
				sharedPost
				isContentRestricted={isContentRestricted}
				imageErrorMsg={imageErrorMsg}
				contentSensitiveMsg={contentSensitiveMsg}
				isCompactView={isCompactView}
			/>
			<PostVideo
				postData={postData}
				isContentRestricted={isContentRestricted}
				sharedPost
				imageErrorMsg={imageErrorMsg}
				contentSensitiveMsg={contentSensitiveMsg}
			/>
			<PostLinkPreview
				linkData={postData}
				isContentRestricted={isContentRestricted}
				sharedPost
				onLinkShareIconPress={onLinkShareIconPress}
				imageErrorMsg={imageErrorMsg}
				contentSensitiveMsg={contentSensitiveMsg}
				isCompactView={isCompactView}
			/>
		</div>
	);

	const handleOnClickPost = () => {
		if (window) {
			/**
			 * When user selects any text, user should not be navigated
			 * so to check that the selected string should be 0 only then
			 * we will navigate user to other screen.
			 * */
			const textSelectionLength = window.getSelection()?.toString();
			if (!(cu.isSet(textSelectionLength) && textSelectionLength && textSelectionLength?.length > 0)) {
				if (navigateToDetailsScreen) {
					navigateToDetailsScreen(true);
				}
			}
		}
	};

	const renderSharedPost = () => (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
		<div
			className="relative flex w-full flex-col bg-content-L1 border rounded-15 border-content-L2"
			onClick={handleOnClickPost}
		>
			<div
				className={classNames(
					"w-full",
					isCompactView ? "px-1.5" : "px-4",
				)}
			>
				<PostHeader
					item={postData}
					isShared
					navigateToDetailsScreen={() => { }}
					navigateToUserOrStream={navigateToUserOrStream}
					showHandIcon={showHandIcon}
					isCompactView={isCompactView}
				/>
			</div>
			{renderSharedPostTitle()}
			{renderSharedPostMedia()}
			{showRemoveIcon && (
				<button
					type="button"
					className="z-1 absolute right-1.5 top-1.5"
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						if (removeSharedPost) removeSharedPost(postData);
					}}
				>
					<ClearNewPostTitleIcon
						stroke="var(--tag-remove)"
						fill="#ffffff"
						width={20}
						height={20}
						className="bg-modalTransparentBackground rounded-full p-1"
					/>
				</button>
			)}
		</div>
	);

	const buildMediaObj_share_post = () => {
		if (!cu.isSet(postData)) {
			return null;
		}

		return (
			<div
				className={classNames(
					"flex flex-row w-full justify-center rounded-lg",
					isCompactView ? "" : "px-4 pb-4",
				)}
			>
				<div className="flex w-full justify-start">
					{postData["p.origDeleted"] && cu.isYes(postData["p.origDeleted"])
						? renderDeletedSharedPost() : renderSharedPost()}
				</div>
			</div>
		);
	};

	return (
		buildMediaObj_share_post()
	);
};

export default PostShared;

PostShared.defaultProps = {
	showRemoveIcon: false,
	removeSharedPost: () => { },
	showQuickLinkShareIcon: true,
};
