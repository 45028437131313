import isNil from "lodash/isNil";
import { Consts } from "./consts";
import { dbg } from "./cu";
import { LogMgr } from "./LogMgr";
import { EvtMgr } from "./EvtMgr";
import { OptionalUserSession, UserSession } from "../types";

export class SessMgr {
	/**
	 * Gets the field value from the session.
	 *
	 * @param {string} field The name of the field which needs to be extracted.
	 * @param {Object} sessionObject The optional session object which can be passed
	 * to extract the field.
	 * @returns {any} Returns the value of the field which is present in the object.
	 * */
	static getFromSession(field: string, sessionObject?: any) {
		if (isNil(field)) { return null; }
		const localStorageSession = localStorage.getItem(Consts.sess) || ""
		let sessObj = localStorageSession ? JSON.parse(localStorageSession) : null;
		if (sessionObject) {
			sessObj = sessionObject;
		}
		if (isNil(sessObj)) { return null; }
		return sessObj[field];
	}

	/**
	 * Save and update the session.
	 *
	 * NOTE: Make sure to catch the event when
	 * it notifies in the project where this function is called.
	 *
	 * @param {UserSession} session Session which needs to be updated
	 * */
	static async setInSessionAndPersistSync(session: OptionalUserSession) {
		await EvtMgr.getInstance(Consts.updateUserSess).notifyListeners(session);
	};

	/**
	 * check session is auth when user verified his phone or logged in with email&pass
	 *
	 * @return boolean
	 * */
	static isSessionAuth(): boolean {
		const sessAuth = SessMgr.getFromSession(Consts.sessAuth);
		const sessInProgress = SessMgr.getFromSession(Consts.sessInProgress);
		if (dbg) LogMgr.mydbg(`sessisSessionAuthAuth:${sessAuth}`);
		return sessAuth === true && !(sessInProgress === true);
	};

	/**
	 * Gets the user session object from the localstorage
	 * */
	static getSession(): string {
		return localStorage.getItem(Consts.sess) || JSON.stringify({})
	}
}
