import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useRef } from "react";
import { classNames, SvgIcon } from "../common";
import { XIcon } from "../assets/icons";
import { CloseIconSideType } from "./types";
import { HintMgr } from "../hint";

interface ModalProps {
	/**
	 * When set true modal will be displayed.
	 * */
	show: boolean;
	/**
	 * Body which needs to be rendered inside the modal under the header.
	 * */
	children: any;
	/**
	 * Callback executed when the close button is pressed.
	 * */
	onClose: () => void;
	/**
	 * Optional Modal title, can be a HTMLElement or a string.
	 * */
	title?: React.ReactNode
	/**
	 * css style class for title wrapper
	 * */
	titleClassName?: string;
	/**
	 * When set true hides default closeIcon
	 * */
	hideCloseIcon?: boolean;
	/**
	 * When set true hides default closeIcon
	 * */
	closeIconSide?: CloseIconSideType;
	/**
	 * css style class for Modal dialog.
	 */
	dialogClassName?: string;
	/**
	 * css style class for Modal dialog wrapper.
	 */
	dialogWrapperClassName?: string;
	/**
	 * css style class for Modal dialog wrapper parent.
	 */
	dialogParentClassName?: string;
	/**
	 * css style class for Modal close icon.
	 */
	closeIconClassName?: string;
	/**
	 * css style class for background overlay which is displayed
	 * under modal
	 * @default ""
	 * */
	overlayClass?: string;
	/**
	 * Set Initial focus on any element inside the modal
	 * when modal renders.
	 * */
	initialFocus?: React.MutableRefObject<HTMLElement | null> | undefined;
	/**
	 * Set Modal id for defining the element as unique
	 * */
	id?: string;
	/**
	 * Sets the locale title for close button on hover.
	 * */
	closeTitle?: string
	/**
	 * Sets the color of close icon.
	 * */
	closeIconStroke?: string
}

const Modal: React.FC<ModalProps> = (props: ModalProps) => {
	const {
		show = false,
		children = null,
		onClose,
		title,
		titleClassName,
		hideCloseIcon,
		closeIconSide,
		dialogClassName,
		dialogWrapperClassName,
		dialogParentClassName,
		initialFocus,
		id,
		closeTitle,
		overlayClass,
		closeIconClassName,
		closeIconStroke,
	} = props;

	// eslint-disable-next-line no-undef
	const hintEmitTimeout = useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		/**
		 * When modal opens the overflow property is hidden
		 * but tailwind's dialog adds right padding of scroll
		 * width so removing that padding when modal is open
		 * */
		if (show && document) {
			document.getElementsByTagName("html")[0].style.paddingRight = "0px";
		}
		/**
		 * emit hint when this modal is opened
		 * */
		if (id) {
			if (hintEmitTimeout.current) {
				clearTimeout(hintEmitTimeout.current);
			}
			hintEmitTimeout.current = setTimeout(() => {
				HintMgr.emitHintsByTrigger(id);
			}, HintMgr.HINT_TRIGGER_TIMEOUT_MODAL);
		}

		return () => {
			if (hintEmitTimeout.current) {
				clearTimeout(hintEmitTimeout.current);
			}
		};
	}, [show]);

	return (
		<Transition appear show={show} as={Fragment} key={id}>
			<Dialog
				as="div"
				className={classNames(
					"relative z-[205]",
					dialogParentClassName,
				)}
				onClose={(value) => onClose()}
				initialFocus={initialFocus}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div
						className={classNames(
							"fixed inset-0 bg-black bg-opacity-25",
							overlayClass,
						)}
					/>
				</Transition.Child>
				<div className="fixed inset-0 overflow-y-auto z-[205]">
					<div
						className={classNames(
							"flex min-h-full justify-center items-center p-4 text-center",
							dialogWrapperClassName,
						)}
					>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel
								className={classNames(
									"w-full max-w-md transform overflow-hidden rounded-lg bg-white",
									"p-3 text-left align-middle shadow-xl transition-all",
									dialogClassName,
								)}
							>
								<Dialog.Title
									as="h3"
									className={classNames(
										"text-lg font-medium leading-6 text-gray-900",
										titleClassName,
									)}
								>
									{!hideCloseIcon && (
										<button
											type="button"
											className={classNames(
												"cursor-pointer absolute top-1 p-2",
												"rounded-full",
												"focus:outline-none",
												"flex justify-center items-center",
												`${closeIconSide}-1`,
												closeIconClassName,
											)}
											onClick={onClose}
										>
											<SvgIcon
												icon={XIcon}
												stroke={closeIconStroke ?? "var(--text-base)"}
												height="16px"
												width="16px"
												data-testid={`${id}-close-button`}
												title={closeTitle || "Close"}
											/>
										</button>
									)}
									{title}
								</Dialog.Title>
								{children}
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};

Modal.defaultProps = {
	title: "",
	titleClassName: "",
	hideCloseIcon: false,
	closeIconSide: CloseIconSideType.RIGHT,
	dialogClassName: "",
	dialogWrapperClassName: "",
	dialogParentClassName: "",
	initialFocus: undefined,
	id: "basic-modal",
	closeTitle: "",
	overlayClass: "",
	closeIconClassName: "hover:bg-hover-1",
	closeIconStroke: "var(--text-base)",
};

export default Modal;

export interface ModalPosition {
	top: number,
	left: number,
	right: number,
}
