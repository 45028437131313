import React from "react";
import omit from "lodash/omit";
import { classNames } from "../common";
import { ButtonSize } from "./types";
import { Spinner } from "../spinner";

export interface SecondaryButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>{
	/**
	 * Set size for the Primary Button
	 * @default M
	 * */
	size: ButtonSize;
	/**
	 * Set whether button is disabled or not
	 * @default false
	 * */
	disabled?: boolean;
	/**
	 * Set whether button is alert type or not
	 * @default false
	 * */
	isDanger?: boolean;
	/**
	 * Set label for the Primary Button
	 * @default ""
	 * */
	label: string;
	/**
	 * Set classnames for the Primary Button
	 * */
	buttonClassNames?: string;
	/**
	 * onClick handler for button press
	* */
	handleClick?: () => void;
	/**
	 * Set dimensionsClasses classnames for the Primary Button
	 * */
	dimensionsClasses?: string;
	/**
	 * set true to display the loading spinner inside the button
	 * @default false
	 * */
	isLoading?: boolean;
	/**
	 * set true to display the image inside the button
	 * @default false
	 * */
	showImage?: boolean;
/**
	 * image component to be displayed inside the button
	 * @default null
	 * */
	imageComp?: any;
	/**
	 * Set an icon to render in the right of the label any
	 * padding must be added to the icon itself since this
	 * will directly render in the right of the label.
	 * */
	rightIcon?: any;
	/**
	 * Set an icon to render in the left of the label any
	 * padding must be added to the icon itself since this
	 * will directly render in the left of the label.
	 * */
	leftIcon?: any;
}

export const SecondaryButton = (props: SecondaryButtonProps) => {
	const {
		size,
		label,
		buttonClassNames,
		handleClick,
		disabled,
		isDanger,
		isLoading,
		dimensionsClasses,
		showImage,
		imageComp,
		rightIcon,
		leftIcon,
	} = props;

	const class_S = classNames(dimensionsClasses || "w-36 h-7", "rounded-[16px] text-xs font-light uppercase");
	const class_M = classNames(dimensionsClasses || "w-48 h-9", "rounded-[18px] text-sm font-normal");
	const class_L = classNames(dimensionsClasses || "w-60 h-10", "rounded-[20px] text-base font-medium");

	const pureProps = omit(props, [
		"size",
		"label",
		"buttonClassNames",
		"handleClick",
		"isDanger",
		"isLoading",
		"dimensionsClasses",
		"rightIcon",
		"leftIcon",
		"showImage",
		"imageComp",
	]);

	const onPress = () => {
		if (handleClick) {
			handleClick();
		}
	};

	const getButtonClassNames = () => {
		let classes = "bg-transparent border overflow-hidden text-ellipsis ";
		classes += disabled ? "border-gray-dark text-gray-dark grayscale " : "border-primary text-primary ";
		classes += isDanger ? "!border-error !text-error " : "";
		switch (size) {
			case ButtonSize.SMALL:
				classes += class_S;
				break;
			case ButtonSize.MEDIUM:
				classes += class_M;
				break;
			case ButtonSize.LARGE:
				classes += class_L;
				break;
			default:
				classes += class_M;
				break;
		}
		classes += ` ${buttonClassNames}` ?? "";
		return classes;
	};

	const getSpinnerSize = () => {
		switch (size) {
			case ButtonSize.LARGE:
				return "28px";
			case ButtonSize.MEDIUM:
				return "24px";
			case ButtonSize.SMALL:
				return "20px";
			default: return "24px";
		}
	};

	const renderBody = () => {
		if (isLoading) {
			return (
				<div className="flex justify-center items-center">
					<Spinner
						height={getSpinnerSize()}
						width={getSpinnerSize()}
						leaf_fill={disabled ? "var(--gray-dark)" : "var(--primary)"}
					/>
				</div>
			);
		} else if (showImage && imageComp) {
			return imageComp;
		}
		return (
			<span className="flex justify-center items-center">
				{leftIcon}
				{label}
				{rightIcon}
			</span>
		);
	};

	return (
		<button
			type="button"
			className={getButtonClassNames()}
			onClick={onPress}
			disabled={disabled}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...pureProps}
		>
			{renderBody()}
		</button>
	);
};

SecondaryButton.defaultProps = {
	buttonClassNames: "",
	dimensionsClasses: "",
	disabled: false,
	isDanger: false,
	isLoading: false,
	handleClick: () => {},
	showImage: false,
	imageComp: null,
	leftIcon: null,
	rightIcon: null,
};
