import React, { SVGAttributes } from "react";

// eslint-disable-next-line import/prefer-default-export
const RequestNoData = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--accent)" } = props;
	return (
		<svg
			width="100px"
			height="101px"
			viewBox="0 0 100 101"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<g id="Notifications" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Requests---Credo---No-Requests" transform="translate(-139.000000, -146.000000)" stroke={stroke}>
					<g id="requests_read" transform="translate(139.000000, 146.000000)">
						<path d="M59.5283,55.0848 L51.0253,55.0848 C48.6273,55.0848 46.2893,53.8238 46.1163,51.3738 L46.0963,51.0848 L46.3673,49.1938 C47.0583,44.4038 46.3483,39.5448 44.3143,35.1448 L43.8393,34.1078 C43.1963,32.7048 42.0903,31.5188 40.6513,30.9288 C38.9643,30.2358 37.0813,30.3498 35.4753,31.2558 C33.5023,32.3688 32.4283,34.5778 32.5603,36.8208 L32.9173,42.8928 C32.9683,43.8458 32.7443,44.7778 32.2683,45.5978 L23.0553,61.5388 C17.6683,68.3038 8.5503,67.0898 4.5743,67.1188 L4.5743,67.1188 C2.3333,67.1188 0.5003,68.9318 0.5003,71.1488 L0.5003,89.9988 C0.5003,92.2158 2.3333,94.0298 4.5743,94.0298 L20.4153,94.0298 L20.5043,94.0298 C24.1653,94.0298 27.7283,94.7658 31.0893,96.2188 L34.2023,97.5578 C37.6523,99.0428 41.3743,99.8088 45.1373,99.8088 L45.1503,99.8088 L56.3403,99.8088" id="Stroke-1" />
						<path d="M76.1602,66.6841 L60.1172,66.6841 C56.9142,66.6841 54.3182,64.0871 54.3182,60.8841 L54.3182,60.8841 C54.3182,57.6811 56.9142,55.0851 60.1172,55.0851 L76.1602,55.0851 C79.3632,55.0851 81.9602,57.6811 81.9602,60.8841 L81.9602,60.8841 C81.9602,64.0871 79.3632,66.6841 76.1602,66.6841 Z" id="Stroke-3" />
						<path d="M75.0435,78.2832 L59.0005,78.2832 C55.7975,78.2832 53.2015,75.6862 53.2015,72.4832 L53.2015,72.4832 C53.2015,69.2802 55.7975,66.6842 59.0005,66.6842 L75.0435,66.6842 C78.2465,66.6842 80.8435,69.2802 80.8435,72.4832 L80.8435,72.4832 C80.8435,75.6862 78.2465,78.2832 75.0435,78.2832 Z" id="Stroke-5" />
						<path d="M70.607,99.809 L56.284,99.809 C53.424,99.809 51.106,97.491 51.106,94.631 L51.106,94.631 C51.106,91.772 53.424,89.453 56.284,89.453 L70.607,89.453 C73.467,89.453 75.785,91.772 75.785,94.631 L75.785,94.631 C75.785,97.491 73.467,99.809 70.607,99.809 Z" id="Stroke-7" />
						<path d="M72.2762,89.4533 L56.8272,89.4533 C53.7422,89.4533 51.2422,86.9523 51.2422,83.8683 L51.2422,83.8683 C51.2422,80.7833 53.7422,78.2833 56.8272,78.2833 L72.2762,78.2833 C75.3612,78.2833 77.8612,80.7833 77.8612,83.8683 L77.8612,83.8683 C77.8612,86.9523 75.3612,89.4533 72.2762,89.4533 Z" id="Stroke-9" />
						<path d="M51.2419,83.8683 C51.2419,83.8683 45.0339,75.5113 31.3809,78.2833" id="Stroke-11" strokeLinecap="round" />
						<path d="M62.0068,13.9112 C62.0068,18.3292 58.4248,21.9112 54.0068,21.9112 C49.5888,21.9112 46.0068,18.3292 46.0068,13.9112 C46.0068,9.4932 49.5888,5.9112 54.0068,5.9112 C58.4248,5.9112 62.0068,9.4932 62.0068,13.9112 Z" id="Stroke-13" />
						<path d="M57.7781,6.8562 C62.1321,2.9062 67.9121,0.4992 74.2541,0.4992 C87.8011,0.4992 98.7841,11.4822 98.7841,25.0292 C98.7841,38.5772 87.8011,49.5592 74.2541,49.5592 C60.7061,49.5592 49.7241,38.5772 49.7241,25.0292 C49.7241,23.6132 49.8441,22.2252 50.0741,20.8742" id="Stroke-15" />
						<path d="M60.0605,8.6911 C63.8605,5.3871 68.8235,3.3871 74.2535,3.3871 C86.2065,3.3871 95.8965,13.0771 95.8965,25.0291 C95.8965,36.9821 86.2065,46.6721 74.2535,46.6721 C62.3015,46.6721 52.6115,36.9821 52.6115,25.0291 C52.6115,23.9381 52.6925,22.8661 52.8485,21.8181" id="Stroke-17" />
						<path d="M74.2623,24.2353 C78.3873,24.2353 81.7433,19.8413 81.7433,15.7163 C81.7433,11.5913 78.3873,8.2353 74.2623,8.2353 C70.1383,8.2353 66.7833,11.5913 66.7833,15.7163 C66.7833,19.8413 70.1383,24.2353 74.2623,24.2353 Z" id="Stroke-19" />
						<path d="M62.189,36.3239 C62.75,29.2459 65.89,26.3799 67.711,25.2949 L68.157,25.0299 L68.606,25.3259 L74.262,29.5669 L79.919,25.3259 L80.368,25.0299 L80.814,25.2949 C82.635,26.3799 85.775,29.2459 86.336,36.3239 L86.339,36.9179 C83.235,39.9059 78.973,41.7529 74.264,41.7529 C69.554,41.7529 65.29,39.9039 62.186,36.9149 L62.189,36.3239 Z" id="Stroke-21" />
						<path d="M66.8016,16.055 C68.8336,15.819 69.7876,12.641 72.7266,13.197 C75.6666,13.754 76.6986,11.767 76.6986,11.767 C76.6986,11.767 78.0926,15.894 81.7256,16.055" id="Stroke-23" />
						<polyline id="Stroke-25" points="66.3506 26.5369 74.2626 32.4699 82.1746 26.5369" />
						<polyline id="Stroke-27" strokeLinecap="round" points="50.2793 14.7665 52.9793 17.5715 58.3563 10.4415" />
					</g>
				</g>
			</g>
		</svg>
	);
};

export default RequestNoData;
