import * as React from "react"
import {SVGAttributes} from "react";

const SvgComponent = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { fill = "var(--gray-dark)" } = props;
	return (
		<svg width={40} height={40} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props} stroke="none">
			<path
				d="M22.916 37V20.011h4.361l.774-5.518h-5.135V11.18c0-1.44.413-1.217 2.564-1.217h2.571V4h-5.135c-5.526 0-7.471 2.677-7.471 7.18v3.313H12v5.519h3.445V37h7.471Z"
				fill={fill}
				fillRule="evenodd"
			/>
		</svg>
	)
}

export default SvgComponent
