/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGAttributes } from "react";

const Connect = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--primary)",
	} = props;
	return (
		<svg
			width="15px"
			height="16px"
			viewBox="0 0 15 16"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<g id="Web-Version" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Web-Version---User-Profile-Ego" transform="translate(-681.000000, -211.000000)" stroke={stroke}>
					<g id="mini-menu-copy" transform="translate(568.000000, 209.000000)">
						<g id="Connect-button" transform="translate(80.000000, 0.000000)">
							<g id="stream_connect" transform="translate(33.000000, 2.000000)">
								<path
									d="M6.6589,7.7496 C6.6589,9.4506 5.2799,10.8286 3.5799,10.8286 C1.8789,10.8286 0.4999,9.4506 0.4999,7.7496 C0.4999,6.0486 1.8789,4.6706 3.5799,4.6706 C5.2799,4.6706 6.6589,6.0486 6.6589,7.7496 Z"
									id="Stroke-1"
								/>
								<path
									d="M14.5,2.9097 C14.5,4.2407 13.421,5.3197 12.09,5.3197 C10.759,5.3197 9.681,4.2407 9.681,2.9097 C9.681,1.5787 10.759,0.4997 12.09,0.4997 C13.421,0.4997 14.5,1.5787 14.5,2.9097 Z"
									id="Stroke-3"
								/>
								<path
									d="M14.5,12.5896 C14.5,13.9206 13.421,14.9996 12.09,14.9996 C10.759,14.9996 9.681,13.9206 9.681,12.5896 C9.681,11.2586 10.759,10.1796 12.09,10.1796 C13.421,10.1796 14.5,11.2586 14.5,12.5896 Z"
									id="Stroke-5"
								/>
								<line x1="6.2448" y1="6.2339" x2="10.0048" y2="4.0959" id="Stroke-7" />
								<line x1="6.2091" y1="9.3312" x2="10.0051" y2="11.4032" id="Stroke-9" />
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default Connect;
