import React, { SVGAttributes } from "react";

// eslint-disable-next-line import/prefer-default-export
const NoInternetAddProfile = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--primary)" } = props;
	return (
		<svg
			width="20px"
			height="21px"
			viewBox="0 0 20 21"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<g id="Profile---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-icons" transform="translate(-97.000000, -961.000000)" stroke={stroke}>
					<g id="no_internet_add_profile" transform="translate(97.000000, 961.000000)">
						<g id="Group-11" transform="translate(0.000000, 0.265000)">
							<path
								d="M18.7449,5.235 C18.7449,7.996 16.5059,10.235 13.7449,10.235 C10.9839,10.235 8.7449,7.996 8.7449,5.235 C8.7449,2.474 10.9839,0.235 13.7449,0.235 C16.5059,0.235 18.7449,2.474 18.7449,5.235 Z"
								id="Stroke-3"
							/>
							<path
								d="M13.9949,7.6938 C13.9949,7.8318 13.8829,7.9438 13.7449,7.9438 C13.6069,7.9438 13.4949,7.8318 13.4949,7.6938 C13.4949,7.5558 13.6069,7.4438 13.7449,7.4438 C13.8829,7.4438 13.9949,7.5558 13.9949,7.6938 Z"
								id="Stroke-5"
								fill={stroke}
							/>
							<path
								d="M17.1904297,8.66371094 C17.4594297,9.49071094 17.5002,10.0828 17.5002,10.9998 C17.5002,15.6948 13.6942,19.4998 9.0002,19.4998 C4.3052,19.4998 0.5002,15.6948 0.5002,10.9998 C0.5002,6.3058 4.3052,2.49976446 9.0002,2.49976446 C9.9072,2.49976446 8.68,2.51287012 9.5,2.77687012"
								id="Stroke-9"
							/>
							<line x1="13.7449" y1="2.0786" x2="13.7449" y2="5.9536" id="Stroke-7" />
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default NoInternetAddProfile;
