import { strings } from "../../i18n/config";
import { NoOfReportedOptions } from "../../utils/types";
import { getDateTimestampOfPreviousMonths } from "../../utils/utils";

export const SubCommFilterList = [
	{
		title: strings("CommentSearchPage.yes"),
		value: "p",
	},
	{
		title: strings("CommentSearchPage.no"),
		value: null,
	},
];

export const CommentReportedOptions = [
	{
		title: strings("CommentSearchPage.not_reported"),
		value: NoOfReportedOptions.ZERO,
		searchQueries: [
			{
				operator: "OR",
				propName: "nb_rep",
				propVal: null,
				condition: " IS NULL ",
			},
		],
	},
	{
		title: strings("CommentSearchPage.one_to_ten"),
		value: NoOfReportedOptions.ONE_TO_TEN,
		searchQueries: [
			{
				operator: "OR",
				propName: "nb_rep",
				propVal: "2",
				condition: ">=",
			},
			{
				operator: "OR",
				propName: "nb_rep",
				propVal: "10",
				condition: "<=",
			},
		],
	},
	{
		title: strings("CommentSearchPage.greater_than_ten"),
		value: NoOfReportedOptions.GREATER_THAN_TEN,
		searchQueries: [
			{
				operator: "OR",
				propName: "nb_rep",
				propVal: "10",
				condition: ">=",
			},
		],
	},
];

export const SearchColumns = [
	{
		title: strings("CommentSearchPage.commentUuid"),
		value: "commentUuid",
		type: "number",
	},
	{
		title: strings("CommentSearchPage.commentText"),
		value: "commentText",
	},
	{
		title: strings("CommentSearchPage.firstName"),
		value: "lc_firstName",
	},
	{
		title: strings("CommentSearchPage.lastName"),
		value: "lc_lastName",
	},
	{
		title: strings("CommentSearchPage.username"),
		value: "lc_username",
	},
];

export const CommentTableHeaders = [
	{
		title: strings("CommentSearchPage.commentUuid"),
		value: "commentUuid",
		isFixedColumn: true,
		hideColumn: true,
		isClickable: true,
		isDefault: true,
	},
	{
		title: strings("CommentSearchPage.commentText"),
		value: "commentText",
		isFixedColumn: true,
		hideColumn: true,
		isDefault: true,
	},
	{
		title: strings("CommentSearchPage.mode"),
		value: "mode",
		isDefault: true,
	},
	{
		title: strings("CommentSearchPage.hidden"),
		value: "hidden",
	},
	{
		title: strings("CommentSearchPage.firstName"),
		value: "firstName",
		isFixedColumn: true,
		hideColumn: true,
		isDefault: true,
	},
	{
		title: strings("CommentSearchPage.lastName"),
		value: "lastName",
		isFixedColumn: true,
		hideColumn: true,
		isDefault: true,
	},
	{
		title: strings("CommentSearchPage.username"),
		value: "username",
		isFixedColumn: true,
		hideColumn: true,
		isDefault: true,
	},
	{
		title: strings("CommentSearchPage.user_id"),
		value: "user_id",
		isClickable: true,
		isDefault: true,
	},
	{
		title: strings("CommentSearchPage.prof_id"),
		value: "prof_id",
		isClickable: true,
		isDefault: true,
	},
	{
		title: strings("CommentSearchPage.nb_rep"),
		value: "nb_rep",
		showCustomColumnComponent: true,
	},
	{
		title: strings("CommentReportedPage.reported_ts"),
		value: "reported_ts",
	},
	{
		title: strings("CommentSearchPage.creat_ts"),
		value: "creat_ts",
		isDefault: true,
	},
	{
		title: strings("CommentSearchPage.modif_ts"),
		value: "modif_ts",
	},
];

export const Timeframes = [
	{
		title: strings("CommentReportedPage.one_month"),
		value: "1",
		searchQueries: [
			{
				operator: "OR",
				propName: "reported_ts",
				propVal: `${getDateTimestampOfPreviousMonths(1)}`,
				condition: ">=",
			},
		],
	},
	{
		title: strings("CommentReportedPage.three_months"),
		value: "3",
		searchQueries: [
			{
				operator: "OR",
				propName: "reported_ts",
				propVal: `${getDateTimestampOfPreviousMonths(3)}`,
				condition: ">=",
			},
		],
	},
	{
		title: strings("CommentReportedPage.six_months"),
		value: "6",
		searchQueries: [
			{
				operator: "OR",
				propName: "reported_ts",
				propVal: `${getDateTimestampOfPreviousMonths(6)}`,
				condition: ">=",
			},
		],
	},
	{
		title: strings("CommentReportedPage.one_year"),
		value: "12",
		searchQueries: [
			{
				operator: "OR",
				propName: "reported_ts",
				propVal: `${getDateTimestampOfPreviousMonths(12)}`,
				condition: ">=",
			},
		],
	},
];

export const CommentReportedTableHeaders = [
	{
		title: strings("UserSearchPage.user_id"),
		value: "reported_user_id",
		isClickable: true,
	},
	{
		title: strings("UserSearchPage.firstName"),
		value: "firstName",
	},
	{
		title: strings("UserSearchPage.lastName"),
		value: "lastName",
	},
	{
		title: strings("UserSearchPage.username"),
		value: "username",
	},
	{
		title: strings("CommentReportedList.reported_mode"),
		value: "mode",
	},
	{
		title: strings("CommentReportedList.reporting_mode"),
		value: "src_mode",
	},
	{
		title: strings("CommentReportedList.reportReason"),
		value: "reportReason",
	},
	{
		title: strings("CommentReportedList.reportMessage"),
		value: "reportMessage",
	},
	{
		title: strings("UserReportedList.reported_timestamp"),
		value: "reported_timestamp",
	},
];

export const SelectedReportedCommentTableHeaders = [
	{
		title: strings("CommentSearchPage.commentUuid"),
		value: "commentUuid",
		isFixedColumn: true,
		hideColumn: true,
		isClickable: true,
	},
	{
		title: strings("CommentSearchPage.commentText"),
		value: "commentText",
		isFixedColumn: true,
		hideColumn: true,
	},
	{
		title: strings("CommentSearchPage.firstName"),
		value: "firstName",
		isFixedColumn: true,
		hideColumn: true,
	},
	{
		title: strings("CommentSearchPage.lastName"),
		value: "lastName",
		isFixedColumn: true,
		hideColumn: true,
	},
	{
		title: strings("CommentSearchPage.username"),
		value: "username",
		isFixedColumn: true,
		hideColumn: true,
	},
	{
		title: strings("CommentSearchPage.user_id"),
		value: "user_id",
	},
	{
		title: strings("CommentSearchPage.nb_rep"),
		value: "nb_rep",
		showCustomColumnComponent: true,
	},
	{
		title: strings("CommentReportedPage.reported_ts"),
		value: "reported_ts",
	},
];

export const CommentStreamHeaders = [
	{
		title: strings("CommentSearchPage.streamId"),
		value: "streamId",
		isClickable: true,
	},
	{
		title: strings("CommentSearchPage.streamName"),
		value: "streamName",
	},
	{
		title: strings("CommentSearchPage.is_sub_comment"),
		value: "parentComment",
	},
];

export const DisabledCommentTableHeaders = [
	{
		title: strings("CommentSearchPage.commentUuid"),
		value: "commentUuid",
		isFixedColumn: true,
		hideColumn: true,
		isClickable: true,
	},
	{
		title: strings("CommentSearchPage.commentText"),
		value: "commentText",
		isFixedColumn: true,
		hideColumn: true,
	},
	{
		title: strings("CommentSearchPage.firstName"),
		value: "firstName",
		isFixedColumn: true,
		hideColumn: true,
	},
	{
		title: strings("CommentSearchPage.lastName"),
		value: "lastName",
		isFixedColumn: true,
		hideColumn: true,
	},
	{
		title: strings("CommentSearchPage.username"),
		value: "username",
		isFixedColumn: true,
		hideColumn: true,
	},
	{
		title: strings("CommentSearchPage.user_id"),
		value: "user_id",
		isFixedColumn: true,
		hideColumn: true,
		isClickable: true,
	},
	{
		title: strings("CommentSearchPage.nb_rep"),
		value: "nb_rep",
		isClickable: true,
	},
	{
		title: strings("CommentReportedPage.reported_ts"),
		value: "reported_ts",
	},
];
