import React, { SVGAttributes } from "react";

// eslint-disable-next-line import/prefer-default-export
const ReplyComment = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--primary)" } = props;
	return (
		<svg
			width="24px"
			height="24px"
			viewBox="0 0 24 24"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<title>Reply Comment</title>
			<g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinejoin="round">
				<g id="glyph-reply" fillRule="nonzero" stroke={stroke} strokeWidth="0.7">
					<g id="reply_FILL0_wght100_GRAD0_opsz24" transform="translate(3.000000, 6.000000)">
						<path d="M5.1,-0.494974747 L6.09497475,0.5 L2.19497475,4.4 L12.1,4.4 C13.3965517,4.4 14.5040762,4.85910148 15.4224874,5.77751263 C16.3408985,6.69592377 16.8,7.80344828 16.8,9.1 L16.8,12.8 L15.4,12.8 L15.4,9.1 C15.4,8.18082192 15.0805958,7.40057054 14.4400126,6.75998737 C13.7994295,6.11940421 13.0191781,5.8 12.1,5.8 L2.19497475,5.8 L6.09497475,9.7 L5.1,10.6949747 L-0.494974747,5.1 L5.1,-0.494974747 Z" id="Path" />
					</g>
				</g>
			</g>
		</svg>
	);
};

export default ReplyComment;
