/* eslint-disable no-param-reassign */
import { CIProps } from "../credo-tag/c-i-styler";

export const ScalerUtils = {
	pageX: (el: Element): number => el.getBoundingClientRect().x + window.scrollX,
	pageY: (el: Element): number => el.getBoundingClientRect().y + window.scrollY,
	viewX: (el: Element): number => el.getBoundingClientRect().x,
	viewY: (el: Element): number => el.getBoundingClientRect().y,
};

export enum ScalerSize {
	XXS = "XXS",
	XS = "XS",
	S = "S",
	M = "M",
	L = "L",
	XL = "XL"
}
type FactorMap = Map<ScalerSize, number>;

const DefaultFactors: FactorMap = new Map<ScalerSize, number>([
	[ScalerSize.XXS, 0.2],
	[ScalerSize.XS, 0.5],
	[ScalerSize.S, 0.75],
	[ScalerSize.M, 1],
	[ScalerSize.L, 1.25],
	[ScalerSize.XL, 2],
]);

export interface Bounds {
	x?: number;
	y?: number;
	width: number;
	height: number;
	border_width: number;
	static?: any, // this is where u can add some other non-scaled stuff u may need
	dynamic?: {
		[key: string]: any
	} // this is where u can add numbers which will get scaled or functions which wont

}
type ScaleProc<BoundsType extends Bounds> = (base: BoundsType, factor: number) => BoundsType;
export const BoundsUtils = {
	/**
	 * Modifies a *= b for each measure
	 * @param a
	 * @param b
	 */
	applyProduct: (a: Bounds, b: Bounds) => {
		if (a.x && b.x) a.x *= b.x;
		if (a.y && b.y) a.y *= b.y;
		a.height *= b.height;
		a.width *= b.width;
	},
	/**
	 * Returns a new bounds which is a product of the two
	 * @param a
	 * @param b
	 */
	product: <T extends Bounds>(a: T, b: Bounds) => {
		const res: T = { ...a };
		BoundsUtils.applyProduct(res, b);
		return res;
	},
};

function DefaultScaleProc<BoundsType extends Bounds>(base: BoundsType, factor: number) {
	const res = {
		...base,
		x: (base.x ?? 0) * factor,
		y: (base.y ?? 0) * factor,
		height: base.height * factor,
		width: base.width * factor,
	};

	if (res.dynamic) {
		Object.keys(res.dynamic).forEach((key) => {
			if (res.dynamic?.[key] && typeof (res.dynamic[key]) === "number") res.dynamic[key] *= factor;
		});
	}

	return res;
}
export interface SubScaler<BoundsType extends Bounds> {
	base: BoundsType,
	doScale: ScaleProc<BoundsType>,
	bounds: BoundsType
}
/**
 * You can ask it to scale stuff
 */
export class Scaler<Props extends CIProps> {
	base: Bounds;

	baseSize: ScalerSize;

	size: ScalerSize = ScalerSize.M;

	factors: FactorMap = DefaultFactors;

	elementBounds: Map<any, SubScaler<any>> = new Map();

	constructor(baseBounds: Bounds, baseSize: ScalerSize, props?: Props, factors: FactorMap = DefaultFactors) {
		this.base = baseBounds;
		this.factors = factors;
		this.baseSize = baseSize;

		if (props) {
			this.scale(props.size);
		}
	}

	getFactor(baseSize: ScalerSize, size: ScalerSize) {
		// @ts-ignore
		return this.factors.get(size) / this.factors.get(baseSize);
	}

	/**
	 * initialises bounds for this scaler, returns a
	 * @param element
	 * @param baseBounds
	 * @param doScale
	 */
	initBounds<BoundsType extends Bounds>(element: any, baseBounds: BoundsType, doScale: ScaleProc<BoundsType> = DefaultScaleProc)
		: SubScaler<BoundsType> {
		const res: SubScaler<BoundsType> = {
			base: baseBounds,
			doScale,
			bounds: doScale(baseBounds, this.getFactor(this.baseSize, this.size)),
		};

		this.elementBounds.set(element, res);

		return res;
	}

	/**
	 *
	 * @param size
	 */
	scale(size: ScalerSize) {
		if (this.size !== size) {
			// eslint-disable-next-line no-restricted-syntax, guard-for-in
			for (const elem in this.elementBounds) {
				const boundsParams = this.elementBounds.get(elem);
				if (boundsParams) {
					boundsParams.bounds = boundsParams.doScale(boundsParams.base, this.getFactor(this.baseSize, size));
				}
			}
		}
		this.size = size;
	}

	bounds(elem: any) {
		return this.elementBounds.get(elem)?.bounds;
	}
}
