import React, { SVGAttributes } from "react";

const IM = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { stroke = "var(--gray-dark)", fill } = props;
	return (
		<svg width="21px" height="22px" viewBox="0 0 21 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
			<g id="Web-Version" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Web-Version-Wide---Home-Credo" transform="translate(-120.000000, -337.000000)" stroke={stroke} fill={fill}>
					<g id="pop-up-copy-3" transform="translate(120.000000, 171.000000)">
						<g id="IM-icon" transform="translate(0.000000, 166.000000)">
							<polyline id="Stroke-1" points="15.4512 5.5488 15.4512 0.4998 0.5002 0.4998 0.5002 11.2338 5.5482 11.2338"/>
							<path d="M15.5767,13.1526 C14.74795,13.1526 14.0767,12.48135 14.0767,11.6526 C14.0767,10.82385 14.74795,10.1526 15.5767,10.1526 C16.40545,10.1526 17.0767,10.82385 17.0767,11.6526 C17.0767,12.48135 16.40545,13.1526 15.5767,13.1526 Z M10.4737,13.1526 C9.6457,13.1526 8.9737,12.48135 8.9737,11.6526 C8.9737,10.82385 9.6457,10.1526 10.4737,10.1526 C11.30245,10.1526 11.9737,10.82385 11.9737,11.6526 C11.9737,12.48135 11.30245,13.1526 10.4737,13.1526 Z M5.5487,5.5486 L5.5487,17.2036 L10.5387,17.2036 L13.0247,20.4996 L15.5097,17.2036 L20.4997,17.2036 L20.4997,5.5486 L5.5487,5.5486 Z" id="Stroke-3"/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}

export default IM;
