import * as React from "react"
import {SVGAttributes} from "react";

const SvgComponent = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { stroke = "var(--primary)" } = props;
	return (
		<svg width={56} height={56} viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g stroke={stroke} fill="none" fillRule="evenodd">
				<path d="M29.337 31.286c1.846 0 3.342-1.5 3.342-3.35a3.345 3.345 0 0 0-3.342-3.348 3.345 3.345 0 0 0-3.342 3.349 3.345 3.345 0 0 0 3.342 3.349ZM9.951 39.714l9.164-11.107 6.002 8.196H36.69" />
				<path d="M15.968 19.23v-1.947c0-1.48 1.196-2.68 2.673-2.68h20.055c1.476 0 2.673 1.2 2.673 2.68v19.989a2.676 2.676 0 0 1-2.632 2.678l-2.047.044" />
				<path d="M20.647 14.604v-1.946c0-1.48 1.197-2.68 2.673-2.68h20.055c1.477 0 2.673 1.2 2.673 2.68v19.989a2.676 2.676 0 0 1-2.632 2.678l-2.047.043" />
				<path d="M34.016 46.022c1.477 0 2.674-1.2 2.674-2.68V21.908c0-1.48-1.197-2.679-2.674-2.679h-21.39a2.677 2.677 0 0 0-2.675 2.68v21.433c0 1.48 1.198 2.68 2.674 2.68h21.391ZM1.49 11.309V1.49h9.82M44.691 1.49h9.818v9.82M23.091 1.491h9.818M54.51 44.691v9.818h-9.82M11.309 54.51H1.49v-9.82M32.909 54.509h-9.818M54.509 23.091v9.818M1.491 32.909v-9.818" />
			</g>
		</svg>
	)
}

export default SvgComponent
