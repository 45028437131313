import getQueryFields from "../../utils/getQueryFields";
import {
	DisabledPostType,
	DropdownItem, NoOfReportedOptions, SearchBarElementInterface, TableHeader,
} from "../../utils/types";
import { PAGINATION_LIMIT } from "../../utils/constants";
import getSearchQuery from "../../utils/getSearchQuery";
import { PostReportedOptions, PostTableHeaders, Timeframes } from "./constants";
import { getDateTimestampOfPreviousMonths } from "../../utils/utils";
import { REPORTED_POST_TYPE, REPORTED_STREAM_ID } from "../../routes/constants";
import { StreamTableHeaders } from "../communities/constants";
import { makeApiRequest } from "../../api";
import { RESTORE_ITEM_URL } from "../../api/requestsUrl";
import { CommentTableHeaders } from "../comments/constants";

export interface GetPostVariables {
	headers: TableHeader[];
	offset: number;
	reportedOptions: DropdownItem[];
	searchData: SearchBarElementInterface[];
	otherFilters: string;
	timeframeFilter?: DropdownItem[];
	postMadeFilter: string;
}

export interface GetDisabledPostVariables {
	headers: TableHeader[];
	offset: number;
}

const getReportedQuery = (reportedOptions: DropdownItem[]) => {
	let query: any = [];
	if (reportedOptions.length === PostReportedOptions.length) {
		return query;
	}

	if (reportedOptions.length !== PostReportedOptions.length) {
		reportedOptions.forEach((option) => {
			switch (option.value) {
				case NoOfReportedOptions.ONE_TO_TEN:
					query = [
						...query,
						{
							AND: [
								{ nb_rep_gte: 1 },
								{ nb_rep_lte: 10 },
							],
						},
					];
					break;
				case NoOfReportedOptions.GREATER_THAN_TEN:
					query = [
						...query,
						{
							nb_rep_gte: 10,
						},
					];
					break;
				default: break;
			}
		});
	}

	return query;
};

const getReportedTimeframeFilter = (timeframeFilter: DropdownItem[]) => {
	let query: any = [];
	if (timeframeFilter.length === Timeframes.length) {
		return query;
	}

	if (timeframeFilter.length < Timeframes.length && timeframeFilter.length > 0) {
		const maxVal = Math.max(...timeframeFilter.map((item) => parseInt(item.value, 10)));
		query = [
			...query,
			{
				reported_ts_gte: getDateTimestampOfPreviousMonths(maxVal),

			},
		];
		return query;
	}
	return query;
};

export const getPostsQueries = (variables: GetPostVariables) => {
	const {
		headers, offset, searchData, otherFilters, reportedOptions, timeframeFilter, postMadeFilter,
	} = variables;

	const reportedQuery = getReportedQuery(reportedOptions);

	const {
		query: filterQuery,
	} = getSearchQuery(searchData, reportedQuery.length > 0 ? reportedQuery : null);

	const timeframeFilterQuery = timeframeFilter ? getReportedTimeframeFilter(timeframeFilter) : "";

	const filters = filterQuery !== "[]" ? [
		filterQuery.replace(/"([^(")"]+)":/g, "$1:"),
	] : [];

	if (timeframeFilterQuery && timeframeFilterQuery?.length > 0) {
		const timeframeQuery = JSON.stringify(timeframeFilterQuery).replace(/"([^(")"]+)":/g, "$1:");
		filters.push(`{ OR: ${timeframeQuery} }`);
	}

	if (postMadeFilter) {
		filters.push(`{ ${postMadeFilter} }`);
	}

	return `query {
			post(
				first: ${PAGINATION_LIMIT},
 				postType: "p",
				offset: ${offset}
				${otherFilters ? `, ${otherFilters} ` : ", "}
				${filters.length > 0 ? `, filter: { AND: [ ${filters} ]} ` : ", "}
				orderBy:[creat_ts_desc]
			) {
				${getQueryFields(headers)},
			}
		}`
		.replaceAll("\t", "")
		.replaceAll("\n", "");
};

export const getDisabledPostsQueries = (variables: GetDisabledPostVariables) => {
	const {
		headers, offset,
	} = variables;

	return `query {
			post(
				first: ${PAGINATION_LIMIT},
 				postType: "p",
				admin_review: "y",
				offset: ${offset},
				orderBy:[creat_ts_desc]
			) {
				${getQueryFields(headers)},
				mode,
			}
		}`
		.replaceAll("\t", "")
		.replaceAll("\n", "");
};

export interface DeletePostVariables {
	postUuid: string;
	fields: TableHeader[];
}

export const deletePostQuery = (variables: DeletePostVariables) => {
	const {
		fields, postUuid,
	} = variables;

	return `mutation { deletePost( postUuid: "${postUuid}" ){ ${getQueryFields(fields)} }\n}`
		.replaceAll("\t", "")
		.replaceAll("\n", "");
};

export interface GetReportedPostsVariables {
	post_id: string;
}

export const getReportedPostsData = (variables: GetReportedPostsVariables) => {
	const {
		post_id,
	} = variables;

	return `query {
			stream(
				first: 1,
				streamId: "${REPORTED_STREAM_ID}",
			) {
				${getQueryFields(StreamTableHeaders)},
				posts(
					postType: "${REPORTED_POST_TYPE}",
					evt_postUuid: "${post_id}"
				)	{
					${getQueryFields(PostTableHeaders)},
					src_prof_id,
					evt_postUuid,
					src_streamId,
					reportReason,
					reported_user_id,
					reportMessage,
				}
			}
		}`
		.replaceAll("\t", "")
		.replaceAll("\n", "");
};

export interface RestoreDisabledItemRequest {
	itemType: DisabledPostType;
	postUuid?: string;
	commentUuid?: string;
}

export async function restoreDisabledItem(request: RestoreDisabledItemRequest) {
	try {
		const res = await makeApiRequest({
			method: "post",
			url: RESTORE_ITEM_URL,
			body: {
				itemType: request?.itemType,
				postUuid: request?.postUuid,
				commentUuid: request?.commentUuid,
			},
		});
		return {
			code: 200,
			response: res,
		};
	} catch (err) {
		return {
			code: 500,
			error: err,
		};
	}
}
export const post_comments_pagination_limit = 11;

export const subcomment_pagination_limit = 4;
export interface GetPostCommentsVariables {
	offset: number;
	postUuid: string;
}

export const getPostCommentsDetails = (variables: GetPostCommentsVariables) => {
	const {
		postUuid, offset,
	} = variables;

	return `query {
			post(
				first: 1,
				postUuid: "${postUuid}"
			) {
				${getQueryFields(PostTableHeaders)},
			stream{
				${getQueryFields(StreamTableHeaders)},
			},
			comments(
				first: ${post_comments_pagination_limit},
				offset: ${offset}
			){
				${getQueryFields(CommentTableHeaders)},
				childComments(
					first: ${subcomment_pagination_limit},
					offset: 0
					){
					${getQueryFields(CommentTableHeaders)},
				}	
			}
			}
		}`
		.replaceAll("\t", "")
		.replaceAll("\n", "");
};
