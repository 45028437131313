import { useEffect, useRef, useState } from "react";
import { makeApiRequest, successRETCD } from "../../api";
import { formatQueryData } from "../../utils/formatQueryData";

interface UseQuery {
	query?: string;
	config?: {
		mediaType?: string
		headers?: {}
		formatError?: (error: any) => any
		formatErrors?: (errors: any) => any
		onSuccess?: (data?: any) => any;
		onError?: (error?: any) => any;
		fetchOptions?: {}
		initialData?: any;
		formatDataKey?: string
		isMutation?: boolean,
		hasSingleData?: boolean;
		notQuery?: boolean;
		body?: any;
		url?: string;
		avoidUpdateWithQuery?: boolean;
	}
}

type StringMap = { [key: string]: any }

interface IResult<TData = StringMap | StringMap[]> {
	data?: TData
	meta?: StringMap
	links?: StringMap
	error?: StringMap
	errors?: StringMap[]
	refetch?: () => void
	loading?: boolean
	isFetching?: boolean
	setData?: (data?: any) => void
	setErrors?: (data?: any) => void
}

interface InternalState {
	data: any
	loading: boolean;
	errors: any;
	error: any;
	promise: any;
}

interface MutateQuery {
	mutation: string;
}

// eslint-disable-next-line import/prefer-default-export
export const useQuery = <TData = StringMap | StringMap[]>(
	query: UseQuery["query"],
	config?: UseQuery["config"],
): [
	mutate: (mutationQuery?: MutateQuery) => Promise<any>,
	result: IResult<TData>
] => {
	const {
		initialData,
		onSuccess,
		onError,
		formatDataKey,
		isMutation,
		hasSingleData,
		notQuery,
		body,
		url,
		avoidUpdateWithQuery,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		...options
	} = config || {};

	const mountedRef = useRef<boolean | null>(false);
	// to skip the function call on first mount in useEffect
	const skipMountEffect = useRef<boolean>(true);

	const [state, setState] = useState<InternalState>({
		data: initialData,
		loading: !isMutation,
		errors: undefined,
		error: undefined,
		promise: undefined,
	});

	const setData = (data: any) => {
		setState((prev) => ({
			...prev,
			data: typeof data === "function" ? data(state.data) : data,
		}));
	};

	const setErrors = (errors: any) => {
		setState((prev) => ({
			...prev,
			errors: typeof errors === "function" ? errors(state.errors) : errors,
		}));
	};

	useEffect(() => {
		setData(initialData);
	}, [query]);

	useEffect(() => {
		mountedRef.current = true;
		return () => { mountedRef.current = null; };
	}, []);

	const mutate = async (mutationQuery?: MutateQuery) => {
		if (state.promise) {
			state.promise.catch((val: any) => {
				setState({
					loading: false,
					...val?.data,
				});
			});
			return state.promise;
		}

		const updatedBody = notQuery ? body : {
			query: isMutation ? mutationQuery?.mutation : query,
		};

		const promise = makeApiRequest({
			method: "post",
			url: url || "",
			body: updatedBody,
		}, {
			apiVersion: url ? "auth" : "applogic",
		});

		setState((prev) => ({
			...prev,
			loading: true,
			promise,
		}));

		const result = await promise;

		if (mountedRef.current) {
			if (result.items && result.retcd === successRETCD) {
				let formattedData = result;

				if (formatDataKey) {
					formattedData = formatQueryData(result.items, formatDataKey);
					if (hasSingleData) {
						if (formattedData?.length > 0) {
							formattedData = { ...formattedData[0] };
						} else {
							formattedData = undefined;
						}
					}
				}

				if (onSuccess) {
					onSuccess(formattedData);
				}
				setState({
					loading: false,
					data: formattedData,
					...result,
				});
			} else {
				if (onError) {
					onError(result);
				}
				setState(({
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					promise, error, errors, ...prev
				}) => ({
					...prev,
					...result,
					data: result.error,
					loading: false,
				}));
			}
		}

		return result;
	};

	useEffect(() => {
		if (!isMutation && !avoidUpdateWithQuery) {
			if (skipMountEffect.current) {
				skipMountEffect.current = false;
			} else {
				mutate();
			}
		}
	}, [query]);

	return [mutate, {
		...state, setData, setErrors,
	}];
};

useQuery.defaulProps = {
	query: "",
	config: {},
};
