import * as React from "react";
import {
	ReactNode, useEffect, useMemo, useRef, useState,
} from "react";
import { cu } from "@credo/utilities";
import CredoGraphSVG from "./credo-graph-svg";
import styles from "./credo-graph-svg.module.css";
import { CIStyler } from "../credo-tag/c-i-styler";
import { CredoGraphData, CredoGraphDataSet } from "./types";
import { CredoGraphDataComponent } from "./credo-graph-data";

function compareCGTD(a: CredoGraphDataSet, b: CredoGraphDataSet, asc = false): number {
	const calcVal = (data: CredoGraphDataSet) => {
		let val = 0;
		if (data) {
			data.data[0].forEach((dataAtLevel) => {
				val += dataAtLevel.score;
			});
		}
		return val;
	};

	return asc ? calcVal(a) - calcVal(b)
		: calcVal(b) - calcVal(a);
}
/**
 * Props for the credo graph
 * Note: defaults are in the credoGraph declaration
 */
export type CredoGraphProps = {
	data: CredoGraphData,
	// eslint-disable-next-line react/require-default-props
	avatar?: ReactNode,
	/**
	 * if true, will not allow each level (ring) to be scaled individually
	 * */
	// eslint-disable-next-line react/require-default-props
	useSingleDataScale?: boolean
	/**
	 * the control is square, so it'll always fit into the smallest of w/h
	 * */
	// eslint-disable-next-line react/require-default-props
	width?: number,
	// eslint-disable-next-line react/require-default-props
	height?: number,
	/**
	 * enables interactive features
	 * */
	// eslint-disable-next-line react/require-default-props
	interactive?: boolean,
	/**
	 * radically simplifies the component for icon mode
	 * */
	// eslint-disable-next-line react/require-default-props
	iconMode?: boolean
	/**
	 * Show circle colors based on aggregated ratings of the
	 * user. This should be used to show influence graph
	 * of the user based on the data passed.
	 * */
	// eslint-disable-next-line react/require-default-props
	aggRatingMode?: boolean
	/**
	 * set tru to hide summary table in the graph
	 * */
	// eslint-disable-next-line react/require-default-props
	hideSummary?: boolean;
	/**
	 * pass in the dbg value from the env to set in dev mode
	 * or not.
	 * We have to pass it by prop because cannot access env
	 * in component while running storybook
	 * */
	// eslint-disable-next-line react/require-default-props
	dbg?: boolean;
};
/**
 * This component renders a credo graph as a pepperoni grapgh.
 * @author maxnamstorm
 */
const MAX_LEVELS = 4;
const MAX_TAGS = 8;
const DEFAULT_DOS_PLOT_RATIOS = [0.1, 0.2, 0.3, 0.4];
const DEFAULT_NUMBER_FORMATTER = (n: number, dp: number = 1) => Math.round(n * 10 ** dp) / 10 ** dp;
const LONG_PRESS_DELAY = 800;

export const CredoGraph = ({
	data,
	avatar = null,
	useSingleDataScale = true,
	width = 360,
	height = 360,
	interactive = true,
	iconMode = false,
	aggRatingMode = false,
	hideSummary = false,
	dbg = false,
}: CredoGraphProps) => {
	const [singleDataScale, setSingleDataScale] = useState<boolean>(useSingleDataScale);
	// eslint-disable-next-line no-undef
	const timerRef = useRef<NodeJS.Timeout | null>(null);

	// Instead of any id being passed from outside, we can generate the id and use it for longpress
	const uniqueId = useMemo(() => Math.random().toString(), []);

	useEffect(() => {
		const handleLongPress = () => {
			setSingleDataScale((prevState) => !prevState);
		};

		if (document) {
			const element = document.getElementById(uniqueId);
			if (element) {
				element?.addEventListener("mousedown", () => {
					timerRef.current = setTimeout(() => {
						handleLongPress();
					}, LONG_PRESS_DELAY);
				});

				element?.addEventListener("mouseup", () => {
					if (timerRef.current) {
						clearTimeout(timerRef.current);
					}
				});
			}
		}
	}, []);

	// prepare data
	const sortedTagData = data.tagData.length > MAX_TAGS
		? data.tagData.sort(compareCGTD).slice(0, MAX_TAGS)
		: data.tagData.sort(compareCGTD);

	const tags = sortedTagData.map((td) => td.label);

	// d console.log(sortedTagData);

	let maxVals = new Array<number>(MAX_LEVELS).fill(0);
	// figure out max levels so we can work out the ratios later
	// each circle is sized as proportion of max of the level, since they're all on the same orbit
	// so we need to first figure out max at each level
	let maxLevels = 0; // maximum levels ever seen
	let maxOfMax = 0;
	sortedTagData.forEach((tagData) => {
		maxLevels = Math.max(tagData.data[0].length, maxLevels);

		for (let l = 0; l < tagData.data[0].length; l += 1) {
			maxVals[l] = Math.max(tagData.data[0][l].score, maxVals[l]);
			maxOfMax = Math.max(maxVals[l], maxOfMax);
			// d console.log("MaxVal at Level", l, tagData, maxVals[l]);
		}
	});
	maxVals = maxVals.slice(0, maxLevels);
	// d console.log("All MaxVals", maxVals, "maxOfMax", maxOfMax);
	let dosAxisRatios: number[];

	if (singleDataScale) {
		// if using single data scale, set all to the same max val
		maxVals.fill(maxOfMax);

		// find the total of radii if max is the area
		const r_of_area = (area: number) => Math.sqrt(area / Math.PI);
		const totalOfMaxRadii = maxVals.length > 0 ? maxVals.map((max) => r_of_area(max)).reduce((prevmax, max) => prevmax + max) : 0;

		// d console.log("prevmax", prevmax, "r_of_area(max)=", max, ")");
		// d console.log("totalOfMaxRadii", totalOfMaxRadii);
		// IMPORTANT: use Area instead of simple Radius calcs
		// plot rings will be divided in ratios of total max
		dosAxisRatios = maxVals.map((levelMax) => r_of_area(levelMax) / totalOfMaxRadii);
		// d console.log("r_of_area(levelMax)", r_of_area(levelMax));
		// // d console.log("maxVals", maxVals);
	} else {
		dosAxisRatios = DEFAULT_DOS_PLOT_RATIOS.slice(0, maxVals.length);
		const totalRelevant = dosAxisRatios.reduce((pr, r) => pr + r);
		dosAxisRatios = dosAxisRatios.map((r) => r / totalRelevant);
	}
	// d console.log("dosAxisRatios", dosAxisRatios);

	// figure out ratios as val / max val of that level
	// we use series index 0 because it should be the total
	// result is [tag][level aggregate]
	const ratios = sortedTagData.map((td, ti) => td.data[0].map((dal, l) => (dal.score <= 0 || maxVals[l] <= 0 ? 0.03 : dal.score / maxVals[l])));
	// d console.log("ratios", ratios);
	// simple labels for now

	// we go over all the ratios which is [tag][level aggregate] and create subratios at [tag][level ratio][subratio]
	// the logic is a bit funky, since we need to figure out ratios as [1,delta,delta]
	// so we iterate over ratios, as it gives us [tag][level] and then iterate over series def to generate subratios
	const subratios = ratios.map((td, ti) => td.map((levelData, l) => {
		// series [0] is aggregate, so totCredifiers should be here
		const totCredifiers = sortedTagData[ti].data[0][l].score;
		// result should be sized according to the # of series def
		const res = new Array<number>(sortedTagData[ti].data.length);

		let deltas = 0;
		// eslint-disable-next-line no-plusplus
		for (let ix = res.length - 1; ix > 0; ix--) {
			// if at this level we have the series data
			// eslint-disable-next-line no-nested-ternary
			res[ix] = sortedTagData[ti].data[ix].length > l ? sortedTagData[ti].data[ix][l].score > 0
				? sortedTagData[ti].data[ix][l].score / totCredifiers : 0 : 0;
			deltas += res[ix];
		}
		res[0] = 1 - deltas;

		return res;
	}));

	const ciStyler = new CIStyler("");

	const aggRatingByRatios = ratios.map((td, ti) => td.map((levelData, l) => {
		// result should be sized according to the # of series def
		const res = new Array<string>(sortedTagData[ti].data.length);
		// eslint-disable-next-line no-plusplus
		for (let ix = res.length - 1; ix >= 0; ix--) {
			if (cu.isSet(sortedTagData[ti].data[ix][l]?.aggRating)) {
				// if at this level we have the series data
				// eslint-disable-next-line no-nested-ternary,no-underscore-dangle
				res[ix] = ciStyler._csColor(sortedTagData[ti].data[ix][l].aggRating);
			}
		}

		return res;
	}));

	// d console.log("subratios", subratios);

	// remember this is now in the form of [tag][series][level]
	// we also calculate some totals while we're at it
	const seriesTotals = new Array<number>(data.seriesLabels.length).fill(0);

	if (data.overallScoreData) {
		// collect labels from the overall series [series][level] of items
		data.overallScoreData.data.forEach((os_series, os_ix) => {
			os_series.forEach((itemAtLevel) => {
				seriesTotals[os_ix] += _notNan(itemAtLevel.score);
			});
		});
	}

	const dataLabels = subratios
		.map((tagData, tagIx) => tagData
			.map((levelRatios, levelIx) => levelRatios
				.map((levelSubRatios, seriesIx) => {
					// need to compute sersials totals?
					if (!data.overallScoreData) seriesTotals[seriesIx] += _notNan(data.tagData[tagIx].data[seriesIx][levelIx]?.score);

					// means we actually have data at this level for this series
					if (sortedTagData[tagIx].data[seriesIx].length > levelIx) {
						const res = `${DEFAULT_NUMBER_FORMATTER(sortedTagData[tagIx].data[seriesIx][levelIx].score)}`;
						// `${data.seriesLabels[seriesIx]} at ${levelIx + 1}°: ${DEFAULT_NUMBER_FORMATTER(sortedTagData[tagIx].data[seriesIx][levelIx].score)}`;

						// // d console.log("label", tagIx, levelIx, seriesIx, res);
						return res;
					} else {
						// // d console.log("no label", tagIx, levelIx, seriesIx);
						return ""; // no label
					}
				})));

	const renderChild = () => {
		if (hideSummary) return null;
		return (
			<table className={styles.summaryTotals}>
				<tbody>
					{data.overallScore
					&& (
						<tr>
							<td className={styles.summaryTotalsValue}>
								{data.overallScoreData ? data.overallScoreData.label : "Overall Score"}
							</td>
							<td className={styles.summaryTotalsValue}>
								{DEFAULT_NUMBER_FORMATTER(data.overallScore)}
							</td>
						</tr>
					)}
					{data.seriesLabels.map((seriesLabel, seriesIx) => {
						if (!data.overallScore && seriesIx === 0) {
							return (
								<tr key={`tr-${seriesIx}`}>
									<td className={styles.summaryTotalsValue}>
										{seriesLabel}
										:
									</td>
									<td className={styles.summaryTotalsValue}>
										{DEFAULT_NUMBER_FORMATTER(seriesTotals[seriesIx])}
									</td>
								</tr>
							);
						}

						return (
						// eslint-disable-next-line react/no-array-index-key
							<tr key={`tr-${seriesIx}`}>
								<td>
									{seriesLabel}
									:
								</td>
								<td className={styles.summaryTotalsValue}>
									{DEFAULT_NUMBER_FORMATTER(seriesTotals[seriesIx])}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		);
	};

	const renderGraph = () => (
		<CredoGraphSVG
			height={height}
			width={width}
			ratios={ratios}
			subratios={subratios}
			dataLabels={dataLabels}
			seriesLabels={data.seriesLabels}
			levelAxisRatios={dosAxisRatios}
			tags={tags}
			avatar={avatar}
			onTagLabelPress={interactive && !iconMode}
			totalsPanelHeight={(seriesTotals.length + 1) * 12}
			iconMode={iconMode}
			aggRatings={aggRatingByRatios}
			aggRatingMode={aggRatingMode}
			id={uniqueId}
		>
			{renderChild()}
		</CredoGraphSVG>
	);

	if (dbg && !iconMode) {
		return (
			<div className="relative">
				{renderGraph()}
				<CredoGraphDataComponent
					data={data}
				/>
			</div>
		);
	}

	return renderGraph();
};

// eslint-disable-next-line no-underscore-dangle
function _notNan(val: number | null | undefined): number {
	return val === undefined || val == null || Number.isNaN(val) ? 0 : val;
}
