/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGAttributes } from "react";

const RightArrow = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--gray-dark)",
	} = props;
	return (
		<svg
			width="7px"
			height="14px"
			viewBox="0 0 7 14"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<g id="Web-Version" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
				<g
					id="Web-Version-Wide---Credo---Auto-scroll-up-&amp;-arrows"
					transform="translate(-875.000000, -310.000000)"
					stroke={stroke}
				>
					<polyline id="ratingCard_right" points="875 311 882 317 875 323" />
				</g>
			</g>
		</svg>
	);
};

export default RightArrow;
