import React, { useEffect, useState } from "react";
import {
	cu, EvtMgr, useEvtMgrListener,
} from "@credo/utilities";
import { CombinedGraphData, CredoGraph, ModalViewMode } from "../../credo-graph";
import { EventConst } from "../../common";
import { Spinner } from "../../spinner";

interface AuthorTagsCredoGraphProps {
	id: string;
	prof_id: string;
	postUuid: string;
	profilePicUrl: string;
	onPressGraphIcon: any;
}

// eslint-disable-next-line import/prefer-default-export
export const AuthorTagsGraphIcon: React.FC<AuthorTagsCredoGraphProps> = (props: AuthorTagsCredoGraphProps) => {
	const {
		id,
		prof_id,
		postUuid,
		profilePicUrl,
		onPressGraphIcon,
	} = props;

	const [data, setData] = useState<CombinedGraphData | undefined>(undefined);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const fetchData = () => {
		const request = {
			prof_id,
			post_id: postUuid,
		};

		EvtMgr
			.getInstance(EventConst.getPostGraphData)
			.notifyListeners({
				request,
			});
	};

	useEffect(() => {
		// Should we check for connection here? We are checking it in GraphDataProvider.
		fetchData();
	}, []);

	const handleGraphData = (
		message: {
			data: CombinedGraphData,
			error: string,
		},
	) => {
		if (!cu.isSet(message.error) && message.data?.credoGraph) {
			setData(message.data);
		}
		setIsLoading(false);
	};

	const handleClickIcon = () => {
		onPressGraphIcon();
		EvtMgr
			.getInstance(EventConst.showCredoGraph)
			.notifyListeners({
				show: true,
				graphProps: {
					id,
					data,
					isLoading,
					userProfilePicUrl: profilePicUrl,
					viewMode: ModalViewMode.MODAL_WITHOUT_INFO,
					graphDimensions: {
						height: 360,
						width: 360,
					},
				},
			});
	};

	useEvtMgrListener(EventConst.pushPostGraphDataToComp, handleGraphData);

	if (isLoading) {
		return (
			<Spinner
				width="32"
				height="32"
				leaf_fill="var(--primary)"
			/>
		);
	}

	if (data?.credoGraph) {
		return (
			<button
				type="button"
				onClick={handleClickIcon}
				className="outline-none"
			>
				<CredoGraph
					// @ts-ignore
					data={data.credoGraph}
					height={32}
					width={32}
					hideSummary
					iconMode
				/>
			</button>
		);
	}

	return null;
};
