import { useMeasureOnResize } from "@credo/utilities";
import React, { useRef } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { classNames } from "../common";

interface CredoGraphSkeletonProps {
	wrapperClasses?: string;
}

// eslint-disable-next-line import/prefer-default-export
const CredoGraphSkeleton: React.FC<CredoGraphSkeletonProps> = (props: CredoGraphSkeletonProps) => {
	const {
		wrapperClasses,
	} = props;
	const wrapperRef = useRef<HTMLDivElement>(null);
	const dimensions = useMeasureOnResize(wrapperRef);

	return (
		<div
			ref={wrapperRef}
			className={classNames(
				"w-full z-0",
				wrapperClasses,
			)}
		>
			<SkeletonTheme
				baseColor="var(--background-skeleton)"
				highlightColor="var(--hightlight-skeleton)"
			>
				<Skeleton
					className="!rounded-3xl"
					containerClassName="w-full"
					height={dimensions?.width || 240}
				/>
			</SkeletonTheme>
		</div>
	);
};

CredoGraphSkeleton.defaultProps = {
	wrapperClasses: "",
};

export default CredoGraphSkeleton;
