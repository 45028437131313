import React, { useEffect, useState } from "react";
import "./Login.css";
import { QRCode } from "react-qrcode-logo";
import { useNavigate } from "react-router-dom";
import { FaRegCopy } from "react-icons/fa";
import { toast } from "react-toastify";
import packageJson from "../../../package.json";
import { strings } from "../../i18n/config";
import { AUTH_2FA_SCREEN } from "../../routes/constants";
import ToastMsg from "../../gen-comp/ToastMsg";

export default function TwoFactorAuthQRCodeScreen() {
	const navigate = useNavigate();
	const [authURl, setAuthURL] = useState("");
	const [secretKey, setSecretKey] = useState("");

	useEffect(() => {
		const confURL = localStorage.getItem("authURL");
		if (confURL) {
			setAuthURL(JSON.parse(confURL));
			const secret = (JSON.parse(confURL))?.match(/secret=(\w+)/i)[1];
			setSecretKey(secret);
		} else {
			navigate("/");
		}
	}, [localStorage.getItem("authURL")]);

	return (
		<>
			<div
				data-testid="2FA-qr-code"
				className="bg-background-dark flex flex-col items-center justify-center inset-0 absolute"
			>
				<div className="flex flex-col pt-10 px-3 bg-white shadow-xl items-center google-login-container">
					<div className="items-center px-10 pb-5">
						<h1 className="active-text text-primary text-center">{strings("TwoFactorAuthQRCodeScreen.capture_message")}</h1>
					</div>
					<div className="items-center pb-1" data-testid="qr-code-container">
						{authURl
							? (
								<QRCode
									value={authURl}
									size={120}
									ecLevel="L"
									bgColor="#FFFFFF"
									fgColor="#212636"
								/>
							)
							: null}
					</div>
					<div className="flex flex-row px-5 py-3 items-center justify-center w-full">
						<span className="text-sm font-normal text-center support-text break-words w-auto">
							{secretKey}
						</span>
						<div className="w-auto">
							<FaRegCopy
								className="mx-2 text-primary"
								data-testid="copy-button"
								onClick={() => {
									toast(
										<ToastMsg
											message={strings("TwoFactorAuthQRCodeScreen.copied_to_clipboard")}
										/>,
									);
									navigator?.clipboard?.writeText("secretKey");
								}}
							/>
						</div>
					</div>
					<div className="flex items-center pt-4 pb-8 w-full justify-center">
						<button
							type="button"
							className="uppercase text-primary px-4 rounded-full border active-button w-3/5 h-10"
							onClick={() => { navigate(AUTH_2FA_SCREEN); }}
						>
							{strings("TwoFactorAuthQRCodeScreen.next")}
						</button>
					</div>
				</div>
				<span className="absolute items-center bottom-5 xs-text">
					{strings("TwoFactorAuthQRCodeScreen.credo_version")}
					{" "}
					{packageJson?.version}
				</span>
			</div>
		</>
	);
}
