import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { isLoggedIn } from "../utils";

function PrivateRoutes() {
	if (isLoggedIn()) { return <Outlet />; }
	return <Navigate to="/" />;
}

export default PrivateRoutes;
