import * as React from "react"
import {SVGAttributes} from "react";

const SvgComponent = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { fill = "var(--gray-dark)" } = props;
	return (
		<svg
			width={40}
			height={40}
			viewBox="-2 0 22 20"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
			stroke="none"
		>
			<g fill={fill} fillRule="evenodd">
				<path d="M17.056 6.934c-3.157 2.13-3.148 6.529.591 8.596-.542 1.631-1.446 3.047-2.597 4.314-.938 1.03-2.079 1.449-3.39.86-1.66-.743-3.251-.705-4.896.033-.975.44-1.987.347-2.772-.431C.944 17.287-.605 13.678.22 9.369.85 6.082 4.183 4.157 7.13 5.425c1.301.56 2.484.46 3.778-.026 2.063-.777 4.016-.504 5.706 1.016.148.134.263.307.442.52" />
				<path d="M13.184 0C13.393 2.39 11.2 4.87 9.06 4.74 8.649 2.57 10.614.285 13.184 0" />
			</g>
		</svg>
	);
}

export default SvgComponent;
