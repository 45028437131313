import React from "react";
import { FaInfoCircle } from "react-icons/fa";
import { Placement } from "@floating-ui/react-dom-interactions";
import Tooltip from "./Tooltip";

interface TooltipHelperProps {
	title: string;
	placement?: Placement;
}

export default function TooltipHelper(props: TooltipHelperProps) {
	const { title, placement } = props;

	return (
		<Tooltip
			content={(
				<span>
					{title}
				</span>
			)}
			placement={placement}
		>
			<div>
				<FaInfoCircle />
			</div>
		</Tooltip>
	);
}

TooltipHelper.defaultProps = {
	placement: "right",
};
