import React from "react";
import { v4 } from "uuid";
import { strings } from "../../../i18n/config";
import Accordion from "../../../gen-comp/Accordion";
import { ExpertiseTag, UserDetails } from "../../../utils/types";

interface UserExpertiseProps {
	/**
	 * User details which also contains user's expertise data
	 * */
	data: UserDetails;
}

const UserExpertise: React.FC<UserExpertiseProps> = (props: UserExpertiseProps) => {
	const {
		data,
	} = props;

	if (data && ((data.xpTag && !(data.xpTag?.length > 0)) || !data.xpTag)) {
		return null;
	}

	return (
		<div
			className="bg-white p-3 rounded-lg mx-5 mb-4 min-h-fit max-h-full max-w-[calc(100%-2.5rem)]"
			data-testid="user-expertise-accordion"
		>
			<Accordion
				// contentHeight={contentHeight}
				header={(
					<h5 className="mb-0">
						{strings("UserDetailsPage.user_expertise")}
					</h5>
				)}
				content={(
					<div className="flex flex-wrap pb-4" data-testid="user-details__expertise-tag__wrapper">
						{
							data.xpTag?.map((expertise: ExpertiseTag) => {
								const elementIndex = v4();
								return (
									<div className="expertise-tag capitalize" key={elementIndex} data-testid="user-details__expertise-tag">
										{expertise.code}
									</div>
								);
							})
						}
					</div>
				)}
			/>
		</div>
	);
};

export default UserExpertise;
