/**
 * This class should replace the device detect library which we
 * are using in the web app currently
 * All the implementations should be added here like
 * isTable
 * isDesktop
 * userAgent details
 *
 * Check the library how they are implementing things
 * which we need and implement it here. for us.
 * */
export class DeviceDetectMgr {
	/**
	 * returns true if app is being accessed on mobile browser
	 * returns true for
	 * iPad, webOs, iPod, iPhone, BlackBerry, Windows Phone, Android
	 * */
	static isMobile(): boolean {
		const toMatch = [
			/Android/i,
			/webOS/i,
			/iPhone/i,
			/iPad/i,
			/iPod/i,
			/BlackBerry/i,
			/Windows Phone/i,
		];

		return toMatch.some((toMatchItem) => navigator.userAgent.match(toMatchItem));
	}
}