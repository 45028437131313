import React, { SVGAttributes } from "react";

const Boosts = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { stroke = "var(--gray-dark)", fill } = props;
	return (
		<svg
			width="20px"
			height="20px"
			viewBox="0 0 20 20"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<title>Boosts</title>
			<defs>
				<circle id="path-boosts-1" cx="10" cy="10" r="8" />
				<linearGradient x1="8.35442826e-13%" y1="50%" x2="50%" y2="50%" id="linearGradient-boosts-3" gradientUnits="userSpaceOnUse">
					<stop stopColor="#00FFA5" offset="0%" />
					<stop stopColor="#00FF9E" stopOpacity="0" offset="100%" />
				</linearGradient>
				<linearGradient x1="8.35442826e-13%" y1="50%" x2="50%" y2="50%" id="linearGradient-boosts-4" gradientUnits="userSpaceOnUse">
					<stop stopColor="#00FFA5" offset="0%" />
					<stop stopColor="#00FF9E" stopOpacity="0" offset="100%" />
				</linearGradient>
				<linearGradient x1="8.35442826e-13%" y1="50%" x2="50%" y2="50%" id="linearGradient-boosts-5" gradientUnits="userSpaceOnUse">
					<stop stopColor="#00FFA5" offset="0%" />
					<stop stopColor="#00FF9E" stopOpacity="0" offset="100%" />
				</linearGradient>
				<linearGradient x1="25%" y1="50%" x2="4.62130334e-12%" y2="50%" id="linearGradient-boosts-6" gradientUnits="userSpaceOnUse">
					<stop stopColor="#00FFA5" offset="0%" />
					<stop stopColor="#00FF9E" stopOpacity="0" offset="100%" />
				</linearGradient>
				<linearGradient x1="25%" y1="50%" x2="4.62130334e-12%" y2="50%" id="linearGradient-boosts-7" gradientUnits="userSpaceOnUse">
					<stop stopColor="#00FFA5" offset="0%" />
					<stop stopColor="#00FF9E" stopOpacity="0" offset="100%" />
				</linearGradient>
				<linearGradient x1="25%" y1="50%" x2="4.62130334e-12%" y2="50%" id="linearGradient-boosts-8" gradientUnits="userSpaceOnUse">
					<stop stopColor="#00FFA5" offset="0%" />
					<stop stopColor="#00FF9E" stopOpacity="0" offset="100%" />
				</linearGradient>
			</defs>
			<g id="Icon---Boosts---Inactive" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" gradientUnits="userSpaceOnUse">
				<g id="CB-Icon-Oval">
					<circle id="Oval" stroke="#212636" fill={fill} cx="10" cy="10" r="9.5" />
					<mask id="mask-boosts-2" fill="white">
						<use xlinkHref="#path-boosts-1" />
					</mask>
					<use id="Icon-Back" fill="#212636" xlinkHref="#path-boosts-1" />
					<g id="CB-Icon-LB" mask="url(#mask-boosts-2)" strokeLinecap="round">
						<g transform="translate(4, 3.5)" id="l-bolt">
							<g transform="translate(2, 1)">
								<line x1="4.5" y1="0.5" x2="5.5" y2="0.5" id="Line-4" stroke="url(#linearGradient-boosts-3)" strokeWidth="1.5" />
								<line x1="2.5" y1="2.5" x2="5.5" y2="2.5" id="Line-4-Copy" stroke="url(#linearGradient-boosts-4)" strokeWidth="1.5" />
								<line x1="0.5" y1="4.5" x2="7.5" y2="4.5" id="Line-4-Copy-2" stroke="url(#linearGradient-boosts-5)" strokeWidth="1.5" />
								<line x1="0.5" y1="6.5" x2="7.5" y2="6.5" id="Line-4-Copy-2" stroke="url(#linearGradient-boosts-6)" strokeWidth="1.5" />
								<line x1="2.5" y1="8.5" x2="5.5" y2="8.5" id="Line-4-Copy-4" stroke="url(#linearGradient-boosts-7)" strokeWidth="1.5" />
								<line x1="2.5" y1="10.5" x2="3.5" y2="10.5" id="Line-4-Copy-5" stroke="url(#linearGradient-boosts-8)" strokeWidth="1.5" />
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default Boosts;
