/**
 * This is the so called "self-contained" or next generation version of credo tag
 *
 * It's intended to replace the existing credo-tag quickly, only exists as a special version
 * due to the complexity of the rewrite and to showcase how to code "Self-Contained Tags"
 */
import * as React from "react";
import { ReactNode, useState } from "react";
import { AuthorisationFailResponderManager, ResponseTarget } from "@credo/utilities";
import {
	DEFAULT_TAG_MESSAGES, Logger, LogLevel, ScalerSize,
} from "../common";
import TagIndicator from "./indicator-html";
import { Spinner } from "../spinner";
import { AuthorisationCheckOutcomeContext } from "../common/authorisation/AuthorisationEngineContext";

// TODO remove
Logger.level = LogLevel.DEBUG;

export const TagIndicatorImpl = TagIndicator;

export type CredoTagScoreData = {
	subjScore: number,
	objScore: number,
	engagement: number,
	relevance: number,
	subjectivity: number,
	hasCampaign: boolean,
	campaignIcon?: (props: any) => React.ReactNode,
	ownRating: number | null,
	ownInterest: boolean,
};

export const NullCredoTagScoreData: CredoTagScoreData = {
	engagement: -1,
	hasCampaign: false,
	objScore: -1,
	ownInterest: false,
	ownRating: -1,
	relevance: -1,
	subjScore: -1,
	subjectivity: 0.5,

};
export type CredoTagDataConsumer = (data: CredoTagScoreData) => void;

/**
 * Data provider
 */
export type CredoTagDataProvider = {
	get(id: string): Promise<CredoTagScoreData>;

	subscribe(id: string, onUpdate: CredoTagDataConsumer): void;

	unsubscribe(id: string, onUpdate: CredoTagDataConsumer): void;

}

export type CredoTagSCProps = {
	id: string,
	tag: string,
	size?: ScalerSize,
	dataProvider: CredoTagDataProvider,
	onRate: (id: string, rating: number) => void,
	disable?: boolean,
}

const loadingIcon: React.ReactNode = <Spinner />;

/**
 *
 * @param id
 * @param tag
 * @param size
 * @param dataProvider
 * @param onRate
 * deleted @param isUserOwnPost - externalised into cu.postUtils function
 * deleted @param isCredoMode - moved into CredoModeContextProvider
 * deleted @param tooltip - internalised, this required UserSessionContextProvider
 * @param disable
 * deleted @param showLoginModal - removed, internalised
 * @constructor
 */
export const CredoTagSC = ({
	id,
	tag,
	size = ScalerSize.M,
	dataProvider,
	onRate,
	disable = false, // TODO rename to disabled
}: CredoTagSCProps) => {
	const [data, setData] = React.useState<CredoTagScoreData>(NullCredoTagScoreData);
	const [loading, setLoading] = React.useState(true);

	// this will allow us to control rate logic
	const authorisationCheckOutcome = React.useContext(AuthorisationCheckOutcomeContext);
	Logger.debug(`Got outcome from context: ${authorisationCheckOutcome.result()}`);

	const onDataUpdate: CredoTagDataConsumer = (data) => {
		setData(data);
	};
	React.useEffect(() => {
		setLoading(true);
		dataProvider.get(id)
			.then(
				(result) => {
					onDataUpdate(result);

					setLoading(false);
				},
				(error) => Logger.error(`Failed to get data for tag id:${id}`, error),
			);

		dataProvider.subscribe(id, onDataUpdate);
	}, [dataProvider]);

	const renderTagIcon = (props: any) => {
		if (loading) {
			return loadingIcon;
		}
		if (data.campaignIcon) {
			return data.campaignIcon(props);
		}
		return null;
	};

	const onTagRate = (tag: string, rating: number) => {
		if (disable) return;
		setData({ ...data, ownRating: rating });
		Logger.debug(`tag rated:${tag}:${rating}`);
		onRate(tag, rating);
	};

	const [tagAlert, setTagAlert] = useState<ReactNode | null>(null);

	const onRatingBlocked = () => {
		// TODO improve by moving out to some pre-defined response decorators
		let alert: ReactNode;

		if (disable) {
			alert = null;
		} else if (!authorisationCheckOutcome.result()) {
			alert = AuthorisationFailResponderManager
				.instance()
				.respond(ResponseTarget.ComponentModal, authorisationCheckOutcome);

			setTagAlert(alert);
		}
	};

	const isAllowedToRate = () => !disable && authorisationCheckOutcome.result();

	return (
		<TagIndicator
			id={id}
			tag={tag}
			size={size}
			icon={renderTagIcon}
			s_credo_score={data.subjScore}
			g_credo_score={data.objScore}
			engagement={data.engagement}
			subjectivity={data.subjectivity}
			is_interest={data.ownInterest}
			has_rating={(data.ownRating ?? -1) !== -1}
			show_icon={loading || data.hasCampaign}
			rating={data.ownRating}
			relevance={data.relevance}
			onRate={onTagRate}
			isRatingAllowed={isAllowedToRate()}
			onRatingBlocked={onRatingBlocked}
			messages={DEFAULT_TAG_MESSAGES}
			// TODO: pick mode from CredoModeContextProvider
			showEgoModal
		>
			{!isAllowedToRate() ? tagAlert : null}
		</TagIndicator>
	);
};

CredoTagSC.defaultProps = {
	disable: false,
	size: ScalerSize.M,
};
