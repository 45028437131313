import React from "react";
import { strings } from "../i18n/config";
import classNames from "../utils/classNames";
import { formatAsNbWithThousandsWithDefault } from "../utils/utils";

interface dataObj {
	period: number;
	value: number;
	percentage: number;
}
interface itemObj {
	title: string;
	data: Array<dataObj>;
}

interface statCardProps {
	item: itemObj;
	containerStyle?: string;
	titleStyle?: string;
	filterData: any;
}

export default function DashboardStatCard({
	item,
	containerStyle,
	titleStyle,
	filterData,
}: statCardProps) {
	return (
		<div key={item.title} className={classNames("metric-card bg-white border border-gray-200 rounded-lg p-4", containerStyle!)}>
			<p className={classNames("text-heading_2 font-bold spacing-sm text-black uppercase", titleStyle!)}>
				{item.title}
			</p>
			{item.data?.map((element) => filterData.find((e: any) => e.value === element.period)
				&& (
					<div key={`${element.period}-${item.title}`} className="mt-2">
						<p className={classNames("mt-1 support-text")}>
							{element.period === 0 ? strings("Dashboard.all") : null}
							{element.period === 30 ? strings("Dashboard.30_days") : null}
							{element.period === 7 ? strings("Dashboard.7_days") : null}
							{element.period === 1 ? strings("Dashboard.24_hours") : null}
						</p>
						<div className={classNames("flex flex-row w-full item-center")}>
							<p className={classNames("text-heading_2 font-bold spacing-sm text-black pr-3")}>
								{`${formatAsNbWithThousandsWithDefault(element.value, 0)}`}
							</p>
							<p
								className={classNames("mt-0.5 text-black",
									// eslint-disable-next-line no-nested-ternary
									element.percentage ? element.percentage > 0 ? "text-accent" : "text-error"
										: "text-gray-dark")}
							>
								{element.percentage || element.percentage === 0
									? `${formatAsNbWithThousandsWithDefault(element.percentage, 0)}%`
									: strings("Dashboard.not_available")}
							</p>
						</div>
					</div>
				))}
		</div>
	);
}

DashboardStatCard.defaultProps = {
	containerStyle: "",
	titleStyle: "",
};
