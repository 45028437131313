import React, { SVGAttributes } from "react";

const Contacts = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { fill = "var(--gray-dark)" } = props;
	return (
		<svg
			width="20px"
			height="20px"
			viewBox="0 0 20 20"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<title>Contacts</title>
			<g id="Icon---Contacts---Inactive" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<path d="M3.89011508,19 L3.89011508,17.8235207 L16.1098849,17.8235207 L16.1098849,19 L3.89011508,19 Z M3.89011508,3.17647928 L3.89011508,2 L16.1098849,2 L16.1098849,3.17647928 L3.89011508,3.17647928 L3.89011508,3.17647928 Z M10,12.0385251 C10.6706099,12.0385251 11.2411902,11.7963241 11.711741,11.3119221 C12.1822917,10.82752 12.4175671,10.2401439 12.4175671,9.54979371 C12.4175671,8.85944351 12.1822917,8.2720674 11.711741,7.78766536 C11.2411902,7.30326333 10.6706099,7.06106231 10,7.06106231 C9.3293901,7.06106231 8.75880978,7.30326333 8.28825903,7.78766536 C7.81770829,8.2720674 7.58243292,8.85944351 7.58243292,9.54979371 C7.58243292,10.2401439 7.81770829,10.82752 8.28825903,11.3119221 C8.75880978,11.7963241 9.3293901,12.0385251 10,12.0385251 Z M3.41336652,16.5634872 C3.01764382,16.5634872 2.68315206,16.4227444 2.40989124,16.1412588 C2.13663041,15.8597731 2,15.515224 2,15.1076113 L2,5.88505816 C2,5.4774455 2.13663041,5.13411811 2.40989124,4.85507599 C2.68315206,4.57603388 3.01764382,4.43651282 3.41336652,4.43651282 L16.5866335,4.43651282 C16.9823562,4.43651282 17.3168479,4.57725563 17.5901088,4.85874125 C17.8633696,5.14022687 18,5.48477601 18,5.89238867 L18,15.1149418 C18,15.5225545 17.8633696,15.8658819 17.5901088,16.144924 C17.3168479,16.4239661 16.9823562,16.5634872 16.5866335,16.5634872 L3.41336652,16.5634872 L3.41336652,16.5634872 Z M4.92649156,15.3870079 L15.0735084,15.3870079 C14.3702098,14.6282094 13.5812553,14.0614296 12.7066448,13.6866685 C11.832049,13.3119074 10.9298341,13.1245269 10,13.1245269 C9.07016594,13.1245269 8.16795101,13.3156783 7.2933552,13.697981 C6.41874474,14.0802838 5.62979019,14.6432928 4.92649156,15.3870079 Z" id="Shape" fill={fill} fillRule="nonzero" />
			</g>
		</svg>
	);
};

export default Contacts;
