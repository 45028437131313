/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGAttributes } from "react";

const Requests = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--primary)",
	} = props;
	return (
		<svg
			width="14px"
			height="19px"
			viewBox="0 0 14 19"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<g id="Profile---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-icons" transform="translate(-379.000000, -1052.000000)" stroke={stroke}>
					<g id="stream_requests" transform="translate(379.000000, 1052.000000)">
						<path
							d="M6.0129,0.5 C4.5069,0.5 3.2819,1.725 3.2819,3.231 C3.2819,4.737 4.5069,5.962 6.0129,5.962 C7.5189,5.962 8.7439,4.737 8.7439,3.231 C8.7439,1.725 7.5189,0.5 6.0129,0.5 Z"
							id="Stroke-1"
						/>
						<path
							d="M9.4075,10.0142 C7.2015,10.0142 5.4075,11.8082 5.4075,14.0142 C5.4075,16.2202 7.2015,18.0142 9.4075,18.0142 C11.6135,18.0142 13.4075,16.2202 13.4075,14.0142 C13.4075,11.8082 11.6135,10.0142 9.4075,10.0142 Z"
							id="Stroke-3"
						/>
						<path
							d="M10.7443,10.2468 C10.0833,8.7998 9.1753,7.7228 8.7023,7.4578 L8.5193,7.3558 L8.3353,7.4698 L6.0133,9.1228 L3.6903,7.4698 L3.5063,7.3558 L3.3233,7.4578 C2.5753,7.8758 0.7433,10.3238 0.5133,13.0518 C0.5133,13.0518 1.9483,15.2088 5.6353,15.3488"
							id="Stroke-5"
						/>
						<line x1="7.4084" y1="14.0142" x2="11.4064" y2="14.0142" id="Stroke-7" strokeLinecap="round" />
						<line x1="9.4075" y1="12.0152" x2="9.4075" y2="16.0132" id="Stroke-9" strokeLinecap="round" />
					</g>
				</g>
			</g>
		</svg>
	);
};

export default Requests;
