export enum CredoSwitchSize {
  SMALL = "S",
  MEDIUM = "M",
  X_LARGE = "XL",
}

export enum CredoSwitchDimensions {
  S = 24,
  M = 30,
  XL = 118,
}

export interface UserProfile {
	cprofilePicRelUrl?: string;
	eprofilePicRelUrl?: string;
	firstName: string;
	lastName: string;
  profilePictureChangeCount?: number | 0;
}
