/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGAttributes } from "react";

const RadioButtonDisabled = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--gray-dark)",
	} = props;
	return (
		<svg
			width="24px"
			height="24px"
			viewBox="0 0 24 24"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<g id="Symbols---CT---190712" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Cells/Editing/Select-Disabled" transform="translate(-11.000000, -10.000000)">
					<g id="radioButtonDisabled_ego" transform="translate(12.000000, 11.000000)">
						<circle id="Circle" stroke={stroke} cx="11" cy="11" r="11" />
						<circle id="Circle" fill={stroke} cx="11" cy="11" r="7" />
					</g>
				</g>
			</g>
		</svg>
	);
};

export default RadioButtonDisabled;
