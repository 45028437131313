import React, { SVGAttributes } from "react";

const Search = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { stroke = "var(--gray-dark)", fill = "var(--dark-main)" } = props;
	return (
		<svg width="19px" height="19px" viewBox="0 0 19 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
			<g id="search_icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<path d="M7.48724446,0 C11.6151776,0 14.9734789,3.35751488 14.9734789,7.48448129 C14.9734789,8.76084184 14.6421938,10.025085 14.0159843,11.1408907 L13.996794,11.1742134 L18.1641177,15.3395514 C19.1276724,16.3028805 19.2811948,17.7206101 18.5044929,18.4981399 C18.1802779,18.8222789 17.725771,19 17.2268234,19 C17.1288519,19 17.0258303,18.9929315 16.9207887,18.9777849 C16.35215,18.8990221 15.7926014,18.6082058 15.3431446,18.1588542 L11.2041013,14.0207802 L11.1768309,13.9925064 L11.1435004,14.0116922 C10.0274333,14.6387649 8.76289397,14.9699724 7.48724446,14.9699724 C3.35830134,14.9699724 0,11.6124575 0,7.48448129 C0,3.35751488 3.35830134,0 7.48724446,0 Z M7.48734546,2.66955251 C4.83201487,2.66955251 2.67058183,4.8294696 2.67058183,7.48417836 C2.67058183,10.1388871 4.83201487,12.299814 7.48734546,12.299814 C10.1426761,12.299814 12.3041091,10.1388871 12.3041091,7.48417836 C12.3041091,4.8294696 10.1426761,2.66955251 7.48734546,2.66955251 Z" id="Fill-1" fill={fill} />
				<path d="M7.48734546,11.2456048 C5.41277375,11.2456048 3.72503795,9.55826424 3.72503795,7.48417836 C3.72503795,5.41110225 5.41277375,3.72376169 7.48734546,3.72376169 C9.56191718,3.72376169 11.249653,5.41110225 11.249653,7.48417836 C11.249653,9.55826424 9.56191718,11.2456048 7.48734546,11.2456048" id="Path" />
			</g>
		</svg>
	);
};

export default Search;
