import React, { SVGAttributes } from "react";

// eslint-disable-next-line import/prefer-default-export
const StreamBotMngmt = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--gray-dark)",
	} = props;
	return (
		<svg
			width="20px"
			height="19px"
			viewBox="0 0 20 19"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<g id="Engage---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Explore---Icons" transform="translate(-48.000000, -491.000000)" stroke={stroke}>
					<g id="streamBotMngmt" transform="translate(48.000000, 491.000000)">
						<path d="M8.1599,13.9486 L1.4499,13.9486 C0.9259,13.9486 0.4999,13.5176 0.4999,12.9886 L0.4999,1.4616 C0.4999,0.9296 0.9259,0.5006 1.4499,0.5006 L5.2499,0.5006 L14.7499,0.5006 L18.5499,0.5006 C19.0759,0.5006 19.4999,0.9296 19.4999,1.4616 L19.4999,12.9886 C19.4999,13.5176 19.0759,13.9486 18.5499,13.9486 L17.5249,13.9486" id="Stroke-1" strokeLinecap="round" />
						<line x1="13.8005" y1="4.8226" x2="17.0005" y2="4.8226" id="Stroke-3" strokeLinecap="round" />
						<path d="M12.9196,14.2239 C13.8156,14.2239 14.5446,13.4869 14.5446,12.5799 C14.5446,11.6739 13.8156,10.9379 12.9196,10.9379 C12.0236,10.9379 11.2946,11.6739 11.2946,12.5799 C11.2946,13.4869 12.0236,14.2239 12.9196,14.2239 Z" id="Stroke-5" />
						<polygon id="Stroke-7" points="17.6691 11.0667 16.5981 9.1907 14.6221 9.6067 13.9901 7.6687 11.8481 7.6687 11.2171 9.6067 9.2401 9.1907 8.1691 11.0667 9.5131 12.5887 8.1691 14.1117 9.2401 15.9877 11.2171 15.5717 11.8481 17.5107 13.9901 17.5107 14.6231 15.5717 16.5981 15.9877 17.6691 14.1117 16.3251 12.5887" />
						<path d="M4.655,10.4027 L3.089,10.4027 C2.709,10.4027 2.401,10.0937 2.401,9.7147 L2.401,7.6317 C2.401,7.2527 2.709,6.9437 3.089,6.9437 L4.655,6.9437" id="Stroke-9" strokeLinecap="round" />
						<path d="M8.4743,11.4124 C7.3403,11.9614 5.9363,11.7664 4.9953,10.8244 C3.8073,9.6364 3.8073,7.7094 4.9953,6.5214 C6.1843,5.3334 8.1103,5.3334 9.2993,6.5214" id="Stroke-11" strokeLinecap="round" />
						<line x1="7.1472" y1="8.6732" x2="10.6582" y2="6.1842" id="Stroke-13" strokeLinecap="round" />
						<path d="M6.4494,3.3616 C6.4494,3.8936 6.0174,4.3246 5.4854,4.3246 C4.9534,4.3246 4.5224,3.8936 4.5224,3.3616 C4.5224,2.8296 4.9534,2.3976 5.4854,2.3976 C6.0174,2.3976 6.4494,2.8296 6.4494,3.3616 Z" id="Stroke-15" strokeLinecap="round" />
						<line x1="5.4855" y1="4.3245" x2="5.4855" y2="6.1035" id="Stroke-17" strokeLinecap="round" />
						<path d="M9.7727,3.3616 C9.7727,3.8936 9.3417,4.3246 8.8097,4.3246 C8.2767,4.3246 7.8457,3.8936 7.8457,3.3616 C7.8457,2.8296 8.2767,2.3976 8.8097,2.3976 C9.3417,2.3976 9.7727,2.8296 9.7727,3.3616 Z" id="Stroke-19" strokeLinecap="round" />
						<line x1="8.8093" y1="4.3245" x2="8.8093" y2="6.1035" id="Stroke-21" strokeLinecap="round" />
					</g>
				</g>
			</g>
		</svg>
	);
};

export default StreamBotMngmt;
