import { intersectionWith, isEqual } from "lodash";

export function isLoggedIn() {
	return !!localStorage.getItem("roles");
}

export function isArrayWithLength(arr: string | any[]) {
	return (Array.isArray(arr) && arr.length);
}

export function getAllowedRoutes(routes: any[]) {
	const roles = JSON.parse(localStorage.getItem("roles") || "{}");
	return routes.filter(({ permission }) => {
		if (!permission) return true;
		if (!isArrayWithLength(permission)) return true;
		return intersectionWith(permission, [roles], isEqual).length > 0;
	});
}
