import React from "react";
import { RefreshIcon, XThinIcon } from "../../assets/icons";
import { ProgressBar } from "../../progress-bar";
import { ProfilePicture } from "../../profile/profile-picture";
import { ProfileSize } from "../../profile/types";
import { SvgIcon } from "../../common";

export interface PostProgressBarProps {
	item: any;
	isFailed: boolean;
	percent: number;
	showRemoveIcon: boolean;
	removeProgressBar: () => void;
	retryPost: () => void;
	message: string;
	profilePicture: string;
	progressColor: string;
}

export const PostProgressBar = (props: PostProgressBarProps) => {
	const {
		item, isFailed, percent, showRemoveIcon, removeProgressBar, retryPost,
		message, profilePicture, progressColor,
	} = props;
	if (!item) {
		return null;
	}
	return (
		<div key={item.postUuid} className="w-full flex-col">
			<div className="flex flex-row justify-center items-center px-4 pt-2">
				<div className="flex justify-center items-center">
					<ProfilePicture
						profilePicUrl={profilePicture}
						size={ProfileSize.SMALL}
					/>
				</div>
				<div className="flex flex-wrap justify-start items-center mx-2 flex-1">
					<span className="text-base text-basic font-light words-break">
						{message}
					</span>
				</div>
				{isFailed && (
					<div className="flex justify-center items-end">
						<button
							type="button"
							className="ml-3"
							onClick={retryPost}
						>
							<RefreshIcon stroke="var(--primary)" />
						</button>
					</div>
				)}
				{showRemoveIcon && (
					<div className="flex justify-center items-end ml-3">
						<SvgIcon
							icon={XThinIcon}
							stroke="var(--gray-dark)"
							onClick={removeProgressBar}
						/>
					</div>
				)}
			</div>
			<div className="mt-2">
				<ProgressBar
					percent={percent}
					progressColor={progressColor}
					containerStyle="bg-background"
				/>
			</div>
		</div>
	);
};

PostProgressBar.defaultProps = {
};
