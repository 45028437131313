/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGAttributes } from "react";

const AFSemailSent_ego = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--primary)",
	} = props;
	return (
		<svg
			width="79px"
			height="101px"
			viewBox="0 0 79 101"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<g id="Settings---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Settings---Email:-Added-Email" transform="translate(-149.000000, -126.000000)" stroke={stroke}>
					<g id="AFSemailSent_ego" transform="translate(149.000000, 126.000000)">
						<path d="M70.5,36.6077 L70.5,2.4547 C70.5,1.3757 69.646,0.4997 68.593,0.4997 L2.407,0.4997 C1.354,0.4997 0.5,1.3757 0.5,2.4547 L0.5,37.8247 C0.5,38.9047 1.354,39.7797 2.407,39.7797 L31.086,39.7797 L31.113,39.8307 L35.5,47.5007 L39.91,39.7797 L60.139,39.7797" id="Stroke-1" />
						<path d="M62.5,61.8333 L41.539,78.9563 C37.932,81.9033 33.068,81.9033 29.461,78.9563 L8.5,61.8333" id="Stroke-3" />
						<line x1="8.5" y1="99.4259" x2="29.461" y2="78.9569" id="Stroke-5" />
						<line x1="41.5394" y1="78.9565" x2="62.5004" y2="99.4255" id="Stroke-7" />
						<path d="M47.5,19.9896 C47.5,26.6166 42.127,31.9896 35.5,31.9896 C28.873,31.9896 23.5,26.6166 23.5,19.9896 C23.5,13.3626 28.873,7.9896 35.5,7.9896 C42.127,7.9896 47.5,13.3626 47.5,19.9896 Z" id="Stroke-9" />
						<path d="M37.9591,72.4067 C37.9591,73.7647 36.8581,74.8657 35.5001,74.8657 C34.1421,74.8657 33.0411,73.7647 33.0411,72.4067 C33.0411,71.0487 34.1421,69.9477 35.5001,69.9477 C36.8581,69.9477 37.9591,71.0487 37.9591,72.4067 Z" id="Stroke-11" />
						<path d="M37.9591,63.4061 C37.9591,64.7641 36.8581,65.8651 35.5001,65.8651 C34.1421,65.8651 33.0411,64.7641 33.0411,63.4061 C33.0411,62.0481 34.1421,60.9471 35.5001,60.9471 C36.8581,60.9471 37.9591,62.0481 37.9591,63.4061 Z" id="Stroke-13" />
						<path d="M37.9591,54.4055 C37.9591,55.7635 36.8581,56.8645 35.5001,56.8645 C34.1421,56.8645 33.0411,55.7635 33.0411,54.4055 C33.0411,53.0475 34.1421,51.9465 35.5001,51.9465 C36.8581,51.9465 37.9591,53.0475 37.9591,54.4055 Z" id="Stroke-15" />
						<polygon id="Stroke-17" points="34.6479 26.4895 28.8169 21.2365 31.0629 18.7425 34.1149 21.4905 39.6899 13.7845 42.4129 15.7495" />
						<polygon id="Stroke-19" points="77.369 41.0191 57.747 28.4171 62.551 51.2371 66.455 44.2161 72.16 52.4901 75.124 50.4461 69.419 42.1731" />
						<path d="M32.8626,42.8875 C31.6636,43.2465 30.5086,43.8535 29.4606,44.7105 L8.4996,61.8335 L8.4996,99.4255 C8.4996,100.0195 8.9836,100.4995 9.5796,100.4995 L61.4196,100.4995 C62.0166,100.4995 62.4996,100.0195 62.4996,99.4255 L62.4996,61.8335 L41.5396,44.7105 C40.4906,43.8535 39.3356,43.2455 38.1356,42.8865" id="Stroke-21" />
					</g>
				</g>
			</g>
		</svg>

	);
};

export default AFSemailSent_ego;
