import { Factory } from "../utils/Factory";
import { Consumer, Publisher } from "../utils/Notifier";

export interface AuthoorisationEngineFactory<T extends AuthorisationEngine> extends Factory<T> {
	reset?: () => void;
}

export abstract class AuthorisationEngine {

	abstract checkAction(action: string, onObject: any, addContext?: Context): AuthorisationCheckOutcome;

	abstract isActionAllowed(action: string, onObject: any, addContext?: Context, onResult?: (result: boolean, p: Permission) => void): boolean;

}

/**
 * This is returned by checkAction method of DobeEngine
 * Usage:
 * if(checkAction(...)
 * 	.onPermssionChange(..)
 * 	.onBlocked(..)
 * 	.result) {
 * 	    // do the allowed thing
 * 	}else {
 * 	    // do something else
 * 	}
 *
 */
export interface AuthorisationCheckOutcome {
	/**
	 * Result of the check, same as returned by isActionAllowed
	 * @see DobeEngine.isActionAllowed
	 */
	result(): boolean,

	/**
	 * Which, if any, permissions matched but failed
	 */
	failed(): Permission[];

	/**
	 * Which, if any, permission blocked
	 */
	blocker(): Permission;

	/**
	 * Provide a handler for when permissions which were involved
	 * in the checking of this action are changing
	 *
	 * @param fn - (p:Permission, deleted:boolean) - called when the p is changed or deleted
	 * @return this - for chaining of other actions
	 */
	onPermissionChange(fn: (p: Permission, deleted: boolean) => void): AuthorisationCheckOutcome,

	/**
	 * Provide a handler for when a 'blocking' permission is encountered
	 * This is useful for UX - so UI can present a friendly recovery action (ie login)
	 * @param fn
	 */
	onBlocked(fn: (p: Permission) => void): AuthorisationCheckOutcome

	/**
	 * Provide a handler for when permission's conditions are not met
	 * This is useful for UX - so UI can present a friendly recovery action (ie login)
	 * @param fn - return false if further processing not needed
	 */
	onFailed(fn: (p: Permission) => boolean): AuthorisationCheckOutcome

}

/**
 * Context of the permission checking
 */
export class Context extends Map<string, any> {
	constructor() {
		super();
	}

	public add(key: string, value: any): Context {
		super.set(key, value);
		return this;
	}

	public addFrom(context: Context): Context {
		context.forEach((value, key) => {
			this.add(key, value);
		});
		return this;
	}

	/**
	 * Returns the context in a form of a struct(object)
	 */
	public toObject(): any {
		return Object.fromEntries(this);
	}
}

/**
 * Interface that can provide a context
 */
export interface ContextProvider {
	createContext(): Context;
}

/**
 * Simply creates a copy of the provided context
 */
export class StaticContextProvider implements ContextProvider {
	private _context: Context;

	constructor(context: Context) {
		this._context = context;
	}

	createContext(): Context {
		return new Context().addFrom(this._context);
	}

}

/**
 * Simply creates a copy of the provided context
 */
export class StaticContextFactory implements ContextFactory {
	private _context: Context;

	constructor(context: Context) {
		this._context = context;
	}

	createContext(): Context {
		return new Context().addFrom(this._context);
	}

	get context() {
		return this._context;
	}

}

/**
 * Interface that can provide a context
 */
export interface ContextFactory {
	createContext(): Context;
}

export interface PermissionsConsumer extends Consumer<Set<Permission>> {
	consume(what: Set<Permission>): void;
	reset?: () => void;
}

export interface PermissionsPublisher extends Publisher<Set<Permission>> {
	start(c: Consumer<Set<Permission>>): void;

	stop(c: Consumer<Set<Permission>>): void;
}

export interface ObjectTypeResolver {
	/**
	 * Should return a string version of the object type
	 * This is used in the specification of object types in permissions
	 * @param obj
	 */
	resolve(obj: any): string;
}

/**
 * Resolved objects by matching them in a dictionary
 */
export class DictObjectTypeResolver implements ObjectTypeResolver {
	private _dict = new Map<string, string>;

	/**
	 *
	 * @param dictionaryObject - a string:string mapper, ie
	 * {
	 *     "post":Post
	 * }
	 */
	constructor(dictionaryObject: any) {
		let name: string = "";
		for (name in dictionaryObject) {
			if (dictionaryObject.hasOwnProperty(name)) {
				this.add(dictionaryObject[name], name);
			}
		}
	}

	resolve(obj: string): string {
		// TODO: Remove ts-ignore from here for correct type handling
		// @ts-ignore
		return this._dict.get(obj.constructor.resolveIdentifier) ?? "";
	}

	public add(key: string, value: string): DictObjectTypeResolver {
		this._dict.set(key, value);
		return this;
	}
}

export enum Rule {
	Allow = "allow",
	Block = "block"
}

export class Permission {
	get id(): string {
		return this._id;
	}

	get actions(): string[] {
		return this._actions;
	}

	get withConditions(): Function {
		return this._withConditions;
	}

	get onObjects(): string[] {
		return this._onObjects;
	}

	get rule(): Rule {
		return this._rule;
	}

	private _id: string;
	private _rule: Rule;
	private _actions: string[];
	private _withConditions: Function;
	private _onObjects: string[];

	constructor(id: string, rule: Rule, actions: string [], onObjects: string [], withConditions: string) {
		this._id = id;
		this._rule = rule;
		this._actions = actions;
		this._withConditions = new Function(
			"if(!arguments.length) return; const context=arguments[0].toObject(); const {actor," + onObjects.join(",") + "} = context; return (" + withConditions + ")");
		try {
			this._withConditions();
		} catch (e) {
			throw "Failed to compile withConditions:" + e;
		}
		this._onObjects = onObjects;
	}

}

/**
 * Interface for listeners
 */
export interface PermissionChangedListener {
	onChange(changed: Permission): void;

	onDelete(deleted: Permission): void;
}
