// prepare random numbers
import { CredoGraphData, CredoGraphDataItem, CredoGraphDataSet } from "./types";

export const dummyTags = ["philosophy", "finance", "crypto", "home decoration", "design", "ai", "running", "philanthropy"];
export const dummyNocAtLevel = [8, 16, 32];

export function generateData(): CredoGraphData {
	const tagCount = 1 + Math.round(7 * Math.random());
	const data = {
		overallScore: 0,
		overallScoreData: {
			label: "Overall",
			data: new Array<Array<CredoGraphDataItem>>(3),
		} as CredoGraphDataSet,
		seriesLabels: ["All time", "30 days", "7 days"],
		tagData: new Array<CredoGraphDataSet>(tagCount),
	} as CredoGraphData;

	const osData: CredoGraphDataSet = data.overallScoreData as CredoGraphDataSet;
	data.overallScore = 0;

	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < tagCount; i++) {
		const tlCount = 2 + Math.round(Math.random());
		data.tagData[i] = {
			label: dummyTags[i],
			// eslint-disable-next-line no-array-constructor
			data: new Array<Array<CredoGraphDataItem>>(data.seriesLabels.length),
		} as CredoGraphDataSet;

		// eslint-disable-next-line no-plusplus
		for (let s = 0; s < data.seriesLabels.length; s++) {
			data.tagData[i].data[s] = new Array<CredoGraphDataItem>(tlCount);
			if (!osData.data[s]) {
				osData.data[s] = new Array<CredoGraphDataItem>(tlCount);
			}
			// eslint-disable-next-line no-plusplus
			let noc = 0;
			// eslint-disable-next-line no-plusplus
			for (let j = 0; j < tlCount; j++) {
				if (s === 0) {
					// set to max possible at this level
					noc = dummyNocAtLevel[j];
				} else {
					// get the 'all' at index 0
					noc = data.tagData[i].data[0][j].score / 2;
					// reduce by all other deltas before this one
					// eslint-disable-next-line no-plusplus
					for (let ss = 1; ss < s - 1; ss++) {
						noc -= data.tagData[i].data[ss][j].score;
					}
				}

				data.tagData[i].data[s][j] = {
					aggRating: Math.random(),
					score: Math.round(Math.max(0, (Math.random() * noc))),
				} as CredoGraphDataItem;

				data.overallScore += data.tagData[i].data[s][j].score;

				if (osData.data[s][j]) {
					osData.data[s][j].score += data.tagData[i].data[s][j].score;
					osData.data[s][j].aggRating = data.tagData[i].data[s][j].aggRating / 2;
				} else {
					osData.data[s][j] = { ...data.tagData[i].data[s][j] };
				}
			}
		}
	}
	return data;
}

export const dummyDataStore = new Map<string, CredoGraphData>();

dummyDataStore.set("bhushan/broken",
	{
		overallScoreData: {
			label: "All Tags",
			data: [
				[
					{
						score: -2.8382040265200885,
					},
					{
						score: 115.0504296088946,
					},
					{
						score: 12.714569298168819,
					},
					{
						score: 1.792367385987721,
					},
				],
				[
					{
						score: 4.038897721729388,
					},
					{
						score: 20.993693914768656,
					},
					{
						score: 4.480315980609608,
					},
					{
						score: 0.3727112010723893,
					},
				],
				[
					{
						score: 4.706555712126261,
					},
					{
						score: 10.756038847352158,
					},
					{
						score: 2.135567370498255,
					},
					{
						score: 0.3727112010723893,
					},
				],
			],
		},
		seriesLabels: [
			"All time",
			"Last 30 days",
			"Last 7 days",
		],
		overallScore: 126.71916226653104,
		tagData: [
			{
				label: "blast",
				data: [
					[
						{
							score: 0.48085734845389827,
						},
						{
							score: 8.167706470927893,
						},
						{
							score: 2.6008312905185798,
						},
					],
					[
						{
							score: 0.48085734845389827,
						},
						{
							score: 8.174151708883198,
						},
						{
							score: 2.088665929704125,
						},
					],
					[
						{
							score: 0.4848870352558798,
						},
						{
							score: 4.459989628501087,
						},
						{
							score: 0.906290503036872,
						},
					],
				],
			},
			{
				label: "credo",
				data: [
					[
						{
							score: 0.8539788282684759,
						},
						{
							score: 4.304364910450929,
						},
						{
							score: 3.1799003353503177,
						},
						{
							score: 0.3727112010723893,
						},
					],
					[
						{
							score: 0.8539788282684759,
						},
						{
							score: 4.129883951894994,
						},
						{
							score: 2.135567370498255,
						},
						{
							score: 0.3727112010723893,
						},
					],
					[
						{
							score: 0.800489414134238,
						},
						{
							score: 2.417380202416891,
						},
						{
							score: 1.2292768674613832,
						},
						{
							score: 0.3727112010723893,
						},
					],
				],
			},
			{
				label: "argentina",
				data: [
					[
						{
							score: 0.3877499577908381,
						},
						{
							score: 6.754584989568558,
						},
						{
							score: 0.2560826804072271,
						},
					],
					[
						{
							score: 0.4415055679964218,
						},
						{
							score: 1.5302184356095747,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0.4415055679964218,
						},
						{
							score: 0.7336687759440925,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "news",
				data: [
					[
						{
							score: -0.03072930833730388,
						},
						{
							score: 7.067881979239472,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "singapore",
				data: [
					[
						{
							score: 0.3245821562209759,
						},
						{
							score: 4.552414104864194,
						},
						{
							score: 0.5121653608144542,
						},
					],
					[
						{
							score: 0.3902723699246058,
						},
						{
							score: 0.6115755134005699,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0.3902723699246058,
						},
						{
							score: 0.47295017066690137,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "business",
				data: [
					[
						{
							score: -0.07074330706544757,
						},
						{
							score: 4.302189030841417,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "guinea pig",
				data: [
					[
						{
							score: -0.07346027644056388,
						},
						{
							score: 3.994889814352744,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "life coaching",
				data: [
					[
						{
							score: -0.07817566369251476,
						},
						{
							score: 3.3802913813753985,
						},
					],
					[
						{
							score: -0.007106878517501342,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "saudi arabia",
				data: [
					[
						{
							score: 0.30430429155489935,
						},
						{
							score: 0.8720398157115292,
						},
						{
							score: 2.0486614432578167,
						},
					],
					[
						{
							score: 0.30430429155489935,
						},
						{
							score: 0.9294868911955196,
						},
						{
							score: 0.2560826804072271,
						},
					],
					[
						{
							score: 0.3498148774206614,
						},
						{
							score: 0.47295017066690137,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "sciences",
				data: [
					[
						{
							score: 0.3953254632864235,
						},
						{
							score: 0.7091336605746595,
						},
						{
							score: 1.1824644771250485,
						},
						{
							score: 0.7367690371627262,
						},
					],
					[
						{
							score: 0.3953254632864235,
						},
						{
							score: 0.1656509541782288,
						},
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0.3953254632864235,
						},
						{
							score: 0.1656509541782288,
						},
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "senegal",
				data: [
					[
						{
							score: 0.31157689814902384,
						},
						{
							score: 2.707013266196677,
						},
					],
					[
						{
							score: 0.38485689264424855,
						},
						{
							score: 0.5559187507759693,
						},
					],
					[
						{
							score: 0.38485689264424855,
						},
						{
							score: 0.47295017066690137,
						},
					],
				],
			},
			{
				label: "cinema",
				data: [
					[
						{
							score: -0.08021516904169929,
						},
						{
							score: 3.0729921648867258,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "gaming",
				data: [
					[
						{
							score: 0.3953254632864235,
						},
						{
							score: 0.6851309687068745,
						},
						{
							score: 1.1418849478447908,
						},
						{
							score: 0.6828871477526056,
						},
					],
					[
						{
							score: 0.3953254632864235,
						},
						{
							score: 0.30729921648867253,
						},
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0.3953254632864235,
						},
						{
							score: 0.30729921648867253,
						},
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "crypto",
				data: [
					[
						{
							score: 0.6457478654312021,
						},
						{
							score: 1.2206164239579032,
						},
						{
							score: 0.5121653608144542,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "germany",
				data: [
					[
						{
							score: -0.08527439038956874,
						},
						{
							score: 2.1510945154207075,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "geopolitics",
				data: [
					[
						{
							score: -0.08527439038956874,
						},
						{
							score: 2.1510945154207075,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "makeup artistry",
				data: [
					[
						{
							score: 0.3052400918045561,
						},
						{
							score: 1.1721227234887945,
						},
						{
							score: 0.5121653608144542,
						},
					],
					[
						{
							score: 0.365297006125801,
						},
						{
							score: 0.6115755134005699,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0.365297006125801,
						},
						{
							score: 0.47295017066690137,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "portugal",
				data: [
					[
						{
							score: -0.08665872151729832,
						},
						{
							score: 1.8437952989320352,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "uruguay",
				data: [
					[
						{
							score: -0.08665872151729832,
						},
						{
							score: 1.8437952989320352,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "environmental",
				data: [
					[
						{
							score: 0.3953254632864235,
						},
						{
							score: 0.6851309687068745,
						},
						{
							score: 0.5121653608144542,
						},
					],
					[
						{
							score: 0.3953254632864235,
						},
						{
							score: 0.30729921648867253,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0.3953254632864235,
						},
						{
							score: 0.30729921648867253,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "twitter",
				data: [
					[
						{
							score: -0.0879143631063475,
						},
						{
							score: 1.5364960824433627,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "funding events",
				data: [
					[
						{
							score: -0.0879143631063475,
						},
						{
							score: 1.5364960824433627,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "fashion",
				data: [
					[
						{
							score: -0.0879143631063475,
						},
						{
							score: 1.5364960824433627,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "xi jinping",
				data: [
					[
						{
							score: -0.0879143631063475,
						},
						{
							score: 1.5364960824433627,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "movies",
				data: [
					[
						{
							score: -0.08905301794640526,
						},
						{
							score: 1.2291968659546901,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "entrepreneurship",
				data: [
					[
						{
							score: -0.08905301794640526,
						},
						{
							score: 1.2291968659546901,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "anti-ageing & longevity",
				data: [
					[
						{
							score: -0.08905301794640526,
						},
						{
							score: 1.2291968659546901,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "culture",
				data: [
					[
						{
							score: -0.08905301794640526,
						},
						{
							score: 1.2291968659546901,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "microsoft",
				data: [
					[
						{
							score: -0.08905301794640526,
						},
						{
							score: 1.2291968659546901,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "belgium",
				data: [
					[
						{
							score: -0.08905301794640526,
						},
						{
							score: 1.2291968659546901,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "parenting",
				data: [
					[
						{
							score: -0.08905301794640526,
						},
						{
							score: 1.2291968659546901,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "england",
				data: [
					[
						{
							score: -0.08905301794640526,
						},
						{
							score: 1.2291968659546901,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "film",
				data: [
					[
						{
							score: -0.08905301794640526,
						},
						{
							score: 1.2291968659546901,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "folk music",
				data: [
					[
						{
							score: -0.08905301794640526,
						},
						{
							score: 1.2291968659546901,
						},
					],
					[
						{
							score: -0.022263254486601314,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "wales",
				data: [
					[
						{
							score: 0.30345615876513365,
						},
						{
							score: 0.5566820702867584,
						},
						{
							score: 0.2560826804072271,
						},
					],
					[
						{
							score: 0.30345615876513365,
						},
						{
							score: 0.5976415975659444,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0.30345615876513365,
						},
						{
							score: 0.47295017066690137,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "journalism",
				data: [
					[
						{
							score: 0.30345615876513365,
						},
						{
							score: 0.6145984329773451,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "netflix",
				data: [
					[
						{
							score: -0.0900853714818674,
						},
						{
							score: 0.9218976494660176,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "politics",
				data: [
					[
						{
							score: -0.0900853714818674,
						},
						{
							score: 0.9218976494660176,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "apple",
				data: [
					[
						{
							score: -0.0900853714818674,
						},
						{
							score: 0.9218976494660176,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "graphic design",
				data: [
					[
						{
							score: -0.0900853714818674,
						},
						{
							score: 0.9218976494660176,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "philosophy",
				data: [
					[
						{
							score: 0.3953254632864235,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "home & garden",
				data: [
					[
						{
							score: 0.3953254632864235,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "health",
				data: [
					[
						{
							score: 0.3953254632864235,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "growth hacking",
				data: [
					[
						{
							score: -0.09102117173152413,
						},
						{
							score: 0.6145984329773451,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "north korea",
				data: [
					[
						{
							score: -0.09102117173152413,
						},
						{
							score: 0.6145984329773451,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "investing",
				data: [
					[
						{
							score: -0.09102117173152413,
						},
						{
							score: 0.6145984329773451,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "hardware",
				data: [
					[
						{
							score: -0.09102117173152413,
						},
						{
							score: 0.6145984329773451,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "eggs",
				data: [
					[
						{
							score: -0.09102117173152413,
						},
						{
							score: 0.6145984329773451,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "hamster",
				data: [
					[
						{
							score: -0.09102117173152413,
						},
						{
							score: 0.6145984329773451,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "google",
				data: [
					[
						{
							score: -0.09102117173152413,
						},
						{
							score: 0.6145984329773451,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "government",
				data: [
					[
						{
							score: -0.09102117173152413,
						},
						{
							score: 0.6145984329773451,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "ecommerce",
				data: [
					[
						{
							score: -0.09102117173152413,
						},
						{
							score: 0.6145984329773451,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "elon musk",
				data: [
					[
						{
							score: -0.09102117173152413,
						},
						{
							score: 0.6145984329773451,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "amazon",
				data: [
					[
						{
							score: -0.09102117173152413,
						},
						{
							score: 0.6145984329773451,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "vr",
				data: [
					[
						{
							score: -0.09102117173152413,
						},
						{
							score: 0.6145984329773451,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "faith",
				data: [
					[
						{
							score: -0.09102117173152413,
						},
						{
							score: 0.6145984329773451,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "metaverse",
				data: [
					[
						{
							score: -0.09102117173152413,
						},
						{
							score: 0.6145984329773451,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "china",
				data: [
					[
						{
							score: -0.09102117173152413,
						},
						{
							score: 0.6145984329773451,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "product market fit",
				data: [
					[
						{
							score: -0.09102117173152413,
						},
						{
							score: 0.6145984329773451,
						},
					],
					[
						{
							score: -0.09102117173152413,
						},
						{
							score: 0.6145984329773451,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "funk",
				data: [
					[
						{
							score: -0.09102117173152413,
						},
						{
							score: 0.6145984329773451,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "education",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "japan",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "ergonomics",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "advertising",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "layoffs",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "ios",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "fintech",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "music",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "russia",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "metal",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "hong kong",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "japanese gp",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "iphone",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "co-founder",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "spain",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "bungo stray dogs",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "retail",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "fashion design",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "saudi arabian gp",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "heavy metal",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "luxury companies",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "television",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "founder",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "techno",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "jpmorgan chase",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "ftx",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "religion",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "transportation",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "mexico",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "food & drink",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "fish",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "pfizer",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "pharma",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "travel",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "country",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "south korea",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "pig",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "hacking",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "startup",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "energy efficiency",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "infections",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "france",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "law",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "netherlands",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "mentoring",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "mining",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "azerbaijan gp",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "careers",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "taiwan",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "toyota",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "ipad",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "vladimir putin",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "wounds and injuries",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "social network",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "tortoise",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "pneumonia",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "soccer",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "covid-19",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "lifestyle",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "forestry",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
			{
				label: "anime",
				data: [
					[
						{
							score: -0.09186930452128983,
						},
						{
							score: 0.30729921648867253,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
					[
						{
							score: 0,
						},
						{
							score: 0,
						},
					],
				],
			},
		],
	} as CredoGraphData);
