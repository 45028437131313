import React, { useEffect, useRef, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { ProfilePicture } from "@credo/ui-components";
import { YesNoOptions } from "@credo/utilities";
import moment from "moment";
import { useQuery } from "../../hooks/graphql/useQuery";
import { strings } from "../../i18n/config";
import classNames from "../../utils/classNames";
import { buildSourceUrlImage, DATE_FORMAT, isYes } from "../../utils/utils";
import "react-loading-skeleton/dist/skeleton.css";
import {
	AUTH_URL, COMMENT_DETAILS_PAGE, COMMUNITIES_DETAILS_PAGE, USER_DETAILS_PAGE,
} from "../../routes/constants";
import Assets from "../../assets/images/Assets";
import {
	getPostCommentsDetails, GetPostCommentsVariables, post_comments_pagination_limit, subcomment_pagination_limit,
} from "./queries";

export default function PostCommentsPage() {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const postUuid = searchParams.get("postUuid");
	const [, setPostDetails] = useState<any>({});
	const [allComments, setAllComments] = useState<any>([]);
	const [page, setPage] = useState(1);
	const isNewTab = searchParams.get("isNewTab") ?? "";
	const ref = useRef<any>(null);

	const navigateToUserDetails = (item: any) => {
		window.open(`${AUTH_URL}${USER_DETAILS_PAGE}?${createSearchParams({
			prof_id: item?.prof_id,
			mode: item?.mode,
			isNewTab: "y",
		})}`, "_blank");
	};

	const goToCommunityDetailScreen = (item: any) => {
		navigate({
			pathname: AUTH_URL + COMMUNITIES_DETAILS_PAGE,
			search: `?${createSearchParams({ communityId: item?.streamId })}`,
		});
	};

	const variables: GetPostCommentsVariables = {
		postUuid: postUuid || "",
		offset: (page - 1) * post_comments_pagination_limit,
	};

	const [getPostCommentsDetailsUsingQuery, { data, loading }] = useQuery<any[]>(getPostCommentsDetails(variables), {
		formatDataKey: "post",
	});

	useEffect(() => {
		if (data && data.length > 0) {
			setPostDetails(data[0]);
			if (page === 1) {
				setAllComments(data[0].comments);
			} else {
				const uniqueDataArray = Array.from(new Set([...allComments, ...data[0].comments]));
				setAllComments(uniqueDataArray);
			}
		}
	}, [data]);

	useEffect(() => {
		getPostCommentsDetailsUsingQuery();
	}, []);

	if (loading) {
		return (
			<div className="w-auto h-full" data-testid="comment-details-loading-wrapper">
				<SkeletonTheme>
					<div className="px-3">
						<Skeleton className={classNames("metric-card border rounded-lg p-4 h-60")} />
					</div>
				</SkeletonTheme>
			</div>
		);
	}

	if (!(data && data.length > 0 && data[0] && data[0]?.comments.length > 0) && !loading) {
		return (
			<div className="w-full h-full" data-testid="comment-details-not-found-wrapper">
				<h5 className="text-center py-5">
					{strings("CommentDetailsScreen.comment_not_found")}
				</h5>
			</div>
		);
	}

	const renderCommentHeader = (item: any) => (
		<div className="flex flex-row flex-wrap">
			<button type="button" className="mr-2" onClick={() => navigateToUserDetails(item)}>
				{item?.username ? (
					<p
						className="text-primary font-semibold text-base whitespace-pre-wrap"
						data-testid="user-name"
					>
						{item?.username}
					</p>
				)
					: (
						<p
							className="text-primary font-semibold text-base whitespace-pre-wrap"
							data-testid="user-name"
						>
							{item?.firstName}
							{" "}
							{item?.lastName}
						</p>
					)}
			</button>
			<span className="text-xs text-gray-400 my-1">
				{moment(new Date(item?.creat_ts)).format(DATE_FORMAT)}
			</span>
		</div>
	);

	const renderSubComments = (commentsDetails: any) => {
		const subComments = commentsDetails?.childComments?.slice(0, subcomment_pagination_limit - 1);
		return subComments && subComments?.length > 0 ? (
			<div className="space-y-4 pt-5">
				{subComments.map((element: any) => (
					<div className="flex" key={element.commentUuid}>
						<div className="flex-shrink-0 mr-3">
							<ProfilePicture
								profilePicUrl={buildSourceUrlImage(element?.profilePicRelUrl) || Assets.UserProfileEgoDefault}
								profilePicStyle={classNames(
									"mt-3 rounded-full w-8 h-8",
								)}
								profilePicWrapperStyle="top-0"
							/>
						</div>
						<div className={classNames(
							"flex-1 bg-gray-100 rounded-lg px-4 py-2 sm:px-6 sm:py-4 leading-relaxed",
						)}
						>
							{renderCommentHeader(element)}
							<p className="text-xs sm:text-sm">
								{element?.commentText}
							</p>
						</div>
					</div>
				))}
				{commentsDetails?.childComments?.length === subcomment_pagination_limit
					? (
						<button
							type="button"
							className="my-5 uppercase tracking-wide text-primary font-bold text-xs flex items-center justify-center w-full"
							onClick={() => {
								window.open(`${AUTH_URL}${COMMENT_DETAILS_PAGE}?${createSearchParams({
									commentUuid: commentsDetails.commentUuid,
									postUuid: commentsDetails?.postUuid,
								})}`, "_blank");
							}}
						>
							{strings("CommentDetailsScreen.load_more")}
						</button>
					) : null}
			</div>
		)
			: null;
	};

	return (
		<div className="w-full h-full" ref={ref} data-testid="comment-details">
			<div className="mx-5">
				<div className="antialiased mx-auto max-w-screen bg-white p-5 my-5 rounded">
					<div className="flex flex-row item-center">
						{!isYes(isNewTab)
							? (
								<div className="w-fit bg-gray-200 rounded-xl opacity-90 cursor-pointer mb-10 mr-5">
									<IoIosArrowBack
										className={classNames("flex-shrink-0 w-6 h-6 text-white transition duration-75 p-1")}
										onClick={() => navigate(-1)}
										size={25}
									/>
								</div>
							) : <div className="relative -top-28 left-10 w-fit opacity-80" style={{ height: 24 }} />}
						<h3 className="mb-4 text-lg font-semibold text-gray-900 mt-[-2px]">
							{strings("PostView.post_details")}
							{": "}
							{strings("PostView.post_id")}
							{" - "}
							{postUuid}
						</h3>
					</div>
					<div className="space-y-4">
						{allComments && allComments.length > 0 && allComments
							.slice(0, (page * (post_comments_pagination_limit - 1)))
							.map((commentsDetails: any) => (
								<div className="flex" key={commentsDetails.commentUuid}>
									<div className="flex-shrink-0 mr-3">
										<ProfilePicture
											profilePicUrl={buildSourceUrlImage(commentsDetails?.isSubComment === YesNoOptions.YES
												? commentsDetails?.parentComment[0].profilePicRelUrl : commentsDetails?.profilePicRelUrl)
												|| Assets.UserProfileEgoDefault}
											profilePicStyle={classNames("mt-2 rounded-full w-8 h-8 sm:w-10 sm:h-10",
												commentsDetails?.isSubComment === YesNoOptions.NO ? "border-primary" : "")}
											profilePicWrapperStyle="top-0"
										/>
									</div>
									<div className={classNames("flex-1 border rounded-lg px-4 py-2 sm:px-6 sm:py-4 leading-relaxed",
										commentsDetails?.isSubComment === YesNoOptions.NO ? "border-primary" : "")}
									>
										{renderCommentHeader({
											...commentsDetails,
											username: commentsDetails?.isSubComment === YesNoOptions.YES
												? commentsDetails?.parentComment[0].username : commentsDetails?.username,
											firstName: commentsDetails?.isSubComment === YesNoOptions.YES
												? commentsDetails?.parentComment[0].firstName : commentsDetails?.firstName,
											lastName: commentsDetails?.isSubComment === YesNoOptions.YES
												? commentsDetails?.parentComment[0].lastName : commentsDetails?.lastName,
											creat_ts: commentsDetails?.isSubComment === YesNoOptions.YES
												? commentsDetails?.parentComment[0].creat_ts : commentsDetails?.creat_ts,
										})}
										<p className="text-sm">
											{commentsDetails?.isSubComment === YesNoOptions.YES
												? commentsDetails?.parentComment[0].commentText : commentsDetails?.commentText}
										</p>
										{renderSubComments(commentsDetails)}
									</div>
								</div>
							))}
						{allComments?.length === (page * post_comments_pagination_limit)
							? (
								<button
									type="button"
									className={classNames("my-5 uppercase tracking-wide text-primary",
										"font-bold text-xs flex items-center justify-center w-full")}
									onClick={() => {
										setPage(page + 1);
									}}
								>
									{strings("CommentDetailsScreen.load_more")}
								</button>
							) : null}
					</div>
				</div>
			</div>
		</div>
	);
}
