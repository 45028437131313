import { Config } from "./types";
import { dbg } from "../utils/utils";
import CfgMgr from "../config/CfgMgr";

export const defaultConfig: Config = {
	api: {
		// In development mode we will use localhost:3000
		authUrl:
			dbg && CfgMgr.CFG_FLAVOR === "DEV" && window.location.hostname === "localhost" ? "http://localhost:3000" : CfgMgr.SERVER_URL,
		applogicUrl: dbg && CfgMgr.CFG_FLAVOR === "DEV" && window.location.hostname === "localhost"
			? "/api/graphql" : `${CfgMgr.SERVER_URL}/api/graphql`,
	},
	minutesUntilAutoLogout: "5",
	rangerReconnectPeriod: "1",
	languages: [],
	sessionCheckInterval: "",
	brandName: "Credo Admin Panel",
	resendCodeInterval: 60,
};

export const Credo = {
	config: defaultConfig,
};

Credo.config = { ...defaultConfig };

export const authUrl = () => Credo.config.api.authUrl;
export const applogicUrl = () => Credo.config.api.applogicUrl;
export const languages = Credo.config.languages && Credo.config.languages.length > 0
	? Credo.config.languages
	: ["en"];
export const sessionCheckInterval = (): string => Credo.config.sessionCheckInterval || "15000";
export const brandName = (): string => Credo.config.brandName;
export const resendCodeInterval = (): number => Credo.config.resendCodeInterval;
export const successResponse = "SUCCESS";
export const successRETCD = 200;
export const RE_AUTH_CODE = "REAUTH";
export const ERROR_CODE = 401;
export const REQ_OTP_AUTH = "RETCD_REQ_OTPAUTH";
