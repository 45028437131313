import React, { useEffect, useState } from "react";
import Hero from "../../gen-comp/Hero";
import { strings } from "../../i18n/config";
import classNames from "../../utils/classNames";

export default function ProfilePage() {
	const [userDetails, setUserDetails] = useState<any>({});

	useEffect(() => {
		const userDetails = localStorage.getItem("userDetails");
		if (userDetails) {
			setUserDetails(JSON.parse(userDetails));
		}
	}, [localStorage.getItem("userDetails")]);

	return (
		<div className="min-h-[calc(100vh-4.1rem)]">
			<Hero title={strings("ProfilePage.hero_title")} pageHasTable />
			<div
				data-testid="profile-container"
				className={classNames("bg-white p-5 rounded-lg -mt-24 mx-5 flex flex-col mb-5 h-40")}
			>
				<div className="flex flex-col">
					<h5 data-testid="user-name">
						{userDetails?.firstname}
						{" "}
						{userDetails?.lastname}
					</h5>
					<h5 data-testid="user-email">
						{userDetails?.email}
					</h5>
				</div>
			</div>
		</div>
	);
}
