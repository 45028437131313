import * as React from "react"
import {SVGAttributes} from "react";

const SvgComponent = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--gray-dark)" } = props;
	return (
		<svg
			width={21}
			height={18}
			viewBox="0 0 21 18"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g stroke={stroke} fill="none" fillRule="evenodd">
				<path d="M11.548.5C6.778.5 2.91 4.133 2.91 8.616c0 1.746.593 3.359 1.591 4.683-.829 1.265-1.889 2.496-3.233 3.422 2.192.421 4.352-.095 6.172-.965a9.04 9.04 0 0 0 4.11.975c4.77 0 8.638-3.633 8.638-8.115C20.187 4.133 16.32.5 11.548.5Z" />
				<path d="M16.65 8.647a5 5 0 1 1-10-.001 5 5 0 0 1 10 0Z" />
				<path d="M7.05 10.275s1.675.736 4.6.736 4.6-.736 4.6-.736M7.138 6.923s1.624.607 4.46.607c2.837 0 4.46-.607 4.46-.607" />
				<path d="M10.499 3.901s-1.705 5.11 0 9.49M13.077 3.901s1.705 5.11 0 9.49" />
			</g>
		</svg>
	)

}
export default SvgComponent
