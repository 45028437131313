import React from "react";
import { cu } from "@credo/utilities";
import LinkPreview from "./LinkPreview";
import { classNames } from "../../common";

export interface PostLinkPreviewProps {
	/**
	 * Sets post data having all link preview details
	 * */
	linkData: any;
	/**
	 * Whether post has senstitve content or not
	 * */
	isContentRestricted: boolean;
	/**
	 * whethere post is shared or not, used for applying different styling
	 * */
	sharedPost: boolean;
	/**
	 * Callback executed when user click on quick share icon
	 * */
	onLinkShareIconPress: (url: string) => void;
	/**
	 * Has error msg to be shown if image doesn't load
	 * */
	imageErrorMsg: string;
	/**
	 * Has sensitive content msg
	 * */
	contentSensitiveMsg: string;
	/**
	 * set true if the component is being called inside
	 * PostCompactView or need a compact version of this
	 * component
	 *
	 * @default false
	 * */
	// eslint-disable-next-line react/require-default-props
	isCompactView?: boolean;
}

const PostLinkPreviewComponent: React.FC<PostLinkPreviewProps> = (props) => {
	const {
		linkData,
		isContentRestricted,
		sharedPost,
		contentSensitiveMsg,
		imageErrorMsg,
		isCompactView = false,
	} = props;

	const buildMediaObj_link = () => {
		const linkUrl = linkData["p.mediaObj_Link_Url"];
		if (!cu.isSet(linkUrl) || (linkUrl && typeof linkUrl === "string" && !cu.isSet(linkUrl.trim()))) {
			return null;
		}

		return (
			<div
				className={classNames(
					"flex flex-row justify-center w-full",
					isCompactView ? "h-[118px] mobile-compact:h-auto" : "px-4 pb-4",
				)}
			>
				<LinkPreview
					linkUrl={linkData["p.mediaObj_Link_Url"]}
					imageUrl={linkData["p.mediaObj_Link_ImgUrl"]}
					linkTitle={linkData["p.mediaObj_Link_Title"]}
					linkHostname={linkData["p.mediaObj_hostname"]}
					linkChannelName={linkData["p.mediaObj_channel_name"]}
					isSharedPost={sharedPost}
					isContentRestricted={isContentRestricted}
					contentSensitiveMsg={contentSensitiveMsg}
					imageErrorMsg={imageErrorMsg}
					isCompactView={isCompactView}
				/>
			</div>
		);
	};

	return (
		buildMediaObj_link()
	);
};

const compareFn = (
	nextProps: PostLinkPreviewProps,
	prevProps: PostLinkPreviewProps,
) => nextProps.isCompactView === prevProps.isCompactView
	&& nextProps.isContentRestricted === prevProps.isContentRestricted
	&& nextProps.sharedPost === prevProps.sharedPost
	&& nextProps.contentSensitiveMsg === prevProps.contentSensitiveMsg
	&& JSON.stringify(nextProps.linkData) === JSON.stringify(prevProps.linkData)
	&& nextProps.imageErrorMsg === prevProps.imageErrorMsg
	&& nextProps.onLinkShareIconPress === prevProps.onLinkShareIconPress;

const PostLinkPreview = React.memo(
	PostLinkPreviewComponent,
	compareFn,
);

export default PostLinkPreview;
