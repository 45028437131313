import React, {
	useEffect, useMemo, useRef, useState,
} from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import moment from "moment";
import {
	ConfirmationModal, ProfilePicture, SnackBarTypeOptions, Spinner, SpinnerLeafType,
} from "@credo/ui-components";
import { toast } from "react-toastify";
import { cu } from "@credo/utilities";
import { useQuery } from "../../hooks/graphql/useQuery";
import { strings } from "../../i18n/config";
import classNames from "../../utils/classNames";
import { buildSourceUrlImage, isYes } from "../../utils/utils";
import "react-loading-skeleton/dist/skeleton.css";
import {
	deleteCommentQuery,
	getCommentDetails, GetCommentDetailsVariables, getReportedCommentsData, GetReportedCommentsVariables, subcomment_pagination_limit,
} from "./queries";
import {
	CommentDetails, DisabledPostType, ReportingUserDetails, YesNoOptions,
} from "../../utils/types";
import CommentReportedList from "./CommentReportedList";
import Assets from "../../assets/images/Assets";
import { AUTH_URL, USER_DETAILS_PAGE } from "../../routes/constants";
import Accordion from "../../gen-comp/Accordion";
import ToastMsg from "../../gen-comp/ToastMsg";
import { successRETCD } from "../../api";
import { restoreDisabledItem } from "../posts/queries";
import { DisabledCommentTableHeaders } from "./constants";

const DATE_FORMAT = "ddd DD-MMM-YYYY, hh:mm A";

export default function CommentDetailsScreen() {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const commentUuid = searchParams.get("commentUuid");
	const postUuid = searchParams.get("postUuid");
	const reportedCount = parseInt(searchParams.get("reportedCount") || "0", 10);
	const [confirmationObj, setConfirmationObj] = useState<any>(null);
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	const [commentsDetails, setCommentDetails] = useState<any>(null);
	const isNewTab = searchParams.get("isNewTab") ?? "";
	const [commentReportedData, setCommentReportedData] = useState<[ReportingUserDetails] | []>([]);
	const bottomRef = useRef<any>(null);
	const [height, setHeight] = useState(0);
	const [page, setPage] = useState(1);
	const [subcomments_page, setSubcommentsPage] = useState(1);
	const [subCommentsData, setSubCommentsData] = useState<CommentDetails[]>([]);
	const [otherSubCommentsData, setOtherSubCommentsData] = useState<CommentDetails[]>([]);
	const ref = useRef<any>(null);

	const variables: GetCommentDetailsVariables = {
		commentUuid: commentUuid || "",
		offset: (page - 1) * subcomment_pagination_limit,
	};

	const [getCommentDetailsUsingQuery, { data, loading }] = useQuery<Array<CommentDetails>>(getCommentDetails(variables), {
		formatDataKey: "comment",
	});

	const scrollToBottom = () => {
		if (bottomRef) {
			bottomRef?.current?.scrollIntoView({
				behavior: "smooth", block: "end", inline: "nearest",
			});
		}
	};

	useEffect(() => {
		if (ref) {
			setHeight(ref?.current?.clientHeight || 0);
		}
	});

	const [getSubCommentsUsingQuery, { data: subCommentData, loading: isSubCommentLoading }] = useQuery<Array<CommentDetails>>("", {
		formatDataKey: "comment",
		isMutation: true,
	});

	const getSubCommentsOfParentComment = (details: CommentDetails) => {
		getSubCommentsUsingQuery({
			mutation: getCommentDetails({
				commentUuid: details?.parentComment[0].commentUuid || "",
				offset: (subcomments_page - 1) * subcomment_pagination_limit,
			}),
		});
	};

	useEffect(() => {
		if (data && data.length > 0) {
			setCommentDetails({
				...data[0],
				isSubComment: data[0]?.parentComment?.length > 0 ? YesNoOptions.YES : YesNoOptions.NO,
			});
			const uniqueDataArray = Array.from(new Set([...subCommentsData, ...data[0].childComments]));
			setSubCommentsData(uniqueDataArray);
			if (data[0]?.parentComment?.length > 0) {
				getSubCommentsOfParentComment(data[0]);
			}
		}
	}, [data]);

	useEffect(() => {
		if (data && data.length > 0 && subcomments_page > 1) {
			if (data[0]?.parentComment?.length > 0) {
				getSubCommentsOfParentComment(data[0]);
			}
		}
	}, [subcomments_page]);

	useEffect(() => {
		if (subCommentData && subCommentData.length > 0) {
			const uniqueDataArray = Array.from(new Set([...otherSubCommentsData, ...subCommentData[0].childComments]));
			setOtherSubCommentsData(uniqueDataArray?.filter((e) => e.commentUuid !== commentUuid));
		}
	}, [subCommentData]);

	const reported_variables: GetReportedCommentsVariables = {
		commentUuid: commentUuid || "",
	};

	const [getReportedList, { data: reportedData, loading: isReportedLoading }] = useQuery<[{ posts: [ReportingUserDetails] }]>(
		getReportedCommentsData(reported_variables),
		{
			formatDataKey: "stream",
		},
	);

	const [deleteUserComment] = useQuery<Array<Object>>("", {
		formatDataKey: "comment",
		isMutation: true,
	});

	const closeConfirmationModal = () => {
		setOpenConfirmationModal(false);
		setConfirmationObj(null);
	};

	const deleteComment = () => {
		deleteUserComment({
			mutation: deleteCommentQuery({ fields: DisabledCommentTableHeaders, commentUuid: commentUuid || null }),
		});
		closeConfirmationModal();
		navigate(-1);
	};

	const restoreDisabledComment = async () => {
		await restoreDisabledItem({
			commentUuid: commentUuid || "",
			itemType: DisabledPostType.COMMENT,
		}).then((res: any) => {
			if (res?.response?.retcd === successRETCD) {
				toast(
					<ToastMsg
						message={strings("DisabledCommentsPage.restore_comment_success")}
						showButton
						type={SnackBarTypeOptions.SUCCESS}
					/>,
				);
				getCommentDetailsUsingQuery();
			} else {
				toast(
					<ToastMsg
						message={strings("PostSearchPage.something_went_wrong")}
						showButton
						type={SnackBarTypeOptions.ERROR}
					/>,
				);
			}
		}).catch((error: any) => {
			toast(
				<ToastMsg
					message={strings("PostSearchPage.something_went_wrong", { error })}
					showButton
					type={SnackBarTypeOptions.ERROR}
				/>,
			);
		});
		closeConfirmationModal();
	};

	const showRestoreConfirmationPopup = () => {
		const title = strings("DisabledCommentsPage.restore_comment");
		const message = `${strings("DisabledCommentsPage.restore_comment_message", { commentUuid })}`;
		setOpenConfirmationModal(true);
		setConfirmationObj({
			ok_button_function: restoreDisabledComment,
			title,
			message,
		});
	};

	const showDeleteConfirmationPopup = () => {
		const title = strings("CommentSearchPage.delete_comment");
		const message = `${strings("CommentSearchPage.delete_comment_message", { commentUuid })}`;
		setOpenConfirmationModal(true);
		setConfirmationObj({
			ok_button_function: deleteComment,
			title,
			message,
		});
	};

	useEffect(() => {
		if (reportedData && reportedData?.length > 0 && reportedData[0] && reportedData[0]?.posts) {
			setCommentReportedData(reportedData[0]?.posts);
		}
	}, [reportedData]);

	useEffect(() => {
		if (reportedCount > 0) {
			setTimeout(() => { scrollToBottom(); }, 500);
		}
	}, [height]);

	useEffect(() => {
		getCommentDetailsUsingQuery();
		getReportedList();
	}, []);

	const navigateToUserDetails = (item: any) => {
		window.open(`${AUTH_URL}${USER_DETAILS_PAGE}?${createSearchParams({
			userId: item?.user_id,
			isNewTab: "y",
		})}`, "_blank");
	};

	const renderCommentHeader = (item: any) => (
		<div className="flex flex-row flex-wrap">
			<button type="button" className="mr-2" onClick={() => navigateToUserDetails(item)}>
				{item?.username ? (
					<p
						className="text-primary font-semibold text-base whitespace-pre-wrap"
						data-testid="user-name"
					>
						{item?.username}
					</p>
				)
					: (
						<p
							className="text-primary font-semibold text-base whitespace-pre-wrap"
							data-testid="user-name"
						>
							{item?.firstName}
							{" "}
							{item?.lastName}
						</p>
					)}
			</button>
			<span className="text-xs text-gray-400 my-1">
				{moment(new Date(item?.creat_ts)).format(DATE_FORMAT)}
			</span>
		</div>
	);

	const renderSubComments = useMemo(() => {
		const commDetails = { ...commentsDetails };
		const otherSubComments = [commentsDetails, ...otherSubCommentsData];
		const subComments = commDetails?.isSubComment === YesNoOptions.YES
			? otherSubComments.slice(0, (subcomments_page * (subcomment_pagination_limit - 1)))
			: subCommentsData?.slice(0, (page * (subcomment_pagination_limit - 1)));
		return subComments && subComments?.length > 0 ? (
			<div className="space-y-4 pt-5">
				{subComments.map((element: any) => (
					<div className="flex" key={element.commentUuid}>
						<div className="flex-shrink-0 mr-3">
							<ProfilePicture
								profilePicUrl={buildSourceUrlImage(element?.profilePicRelUrl) || Assets.UserProfileEgoDefault}
								profilePicStyle={classNames("mt-3 rounded-full w-8 h-8",
									element.commentUuid === commentUuid ? "border border-primary" : "")}
								profilePicWrapperStyle="top-0"
							/>
						</div>
						<div className={classNames("flex-1 bg-gray-100 rounded-lg px-4 py-2 sm:px-6 sm:py-4 leading-relaxed",
							element.commentUuid === commentUuid ? "border border-primary" : "")}
						>
							{renderCommentHeader(element)}
							<p className="text-xs sm:text-sm">
								{element?.commentText}
							</p>
						</div>
					</div>
				))}
				{(otherSubComments?.length > subComments.length && commDetails?.isSubComment === YesNoOptions.YES)
					|| (subCommentsData?.length > subComments.length)
					? (
						<button
							type="button"
							className="my-5 uppercase tracking-wide text-primary font-bold text-xs flex items-center justify-center w-full"
							onClick={() => {
								if (otherSubComments?.length > subComments.length && commDetails?.isSubComment === YesNoOptions.YES) {
									setSubcommentsPage(subcomments_page + 1);
								} else {
									setPage(page + 1);
								}
							}}
						>
							{strings("CommentDetailsScreen.load_more")}
						</button>
					) : null}
				{(loading || isSubCommentLoading) && (
					<div className="flex items-center justify-center py-2">
						<Spinner
							leaf_shape={SpinnerLeafType.CIRCLES}
							width="20px"
							height="20px"
							leaf_fill="var(--primary)"
						/>
					</div>
				)}
			</div>
		)
			: null;
	}, [subCommentData, data, otherSubCommentsData]);

	if (loading || (isReportedLoading && reportedCount > 0)) {
		return (
			<div className="w-auto h-full" data-testid="comment-details-loading-wrapper">
				<SkeletonTheme>
					<div className="px-3">
						<Skeleton className={classNames("metric-card border rounded-lg p-4 h-60")} />
					</div>
				</SkeletonTheme>
			</div>
		);
	}

	if (!(data && data?.length > 0) && !loading) {
		return (
			<div className="w-full h-full bg-transparent py-10" data-testid="comment-details-not-found-wrapper">
				<h5 className="text-center p-10">
					{strings("CommentDetailsScreen.comment_not_found")}
				</h5>
			</div>
		);
	}

	return (
		<div className="w-full h-full" ref={ref} data-testid="comment-details">
			<div className="mx-5">
				<div className="antialiased mx-auto max-w-screen bg-white p-5 my-5 rounded">
					<div className="flex flex-row item-center">
						{!isYes(isNewTab)
							? (
								<div className="w-fit bg-gray-200 rounded-xl opacity-90 cursor-pointer mb-10 mr-5">
									<IoIosArrowBack
										className={classNames("flex-shrink-0 w-6 h-6 text-white transition duration-75 p-1")}
										onClick={() => navigate(-1)}
										size={25}
									/>
								</div>
							) : <div className="relative -top-28 left-10 w-fit opacity-80" style={{ height: 24 }} />}
						<h3 className="mb-4 text-lg font-semibold text-gray-900 mt-[-2px]" data-testid="comment-title">
							{strings("CommentDetailsScreen.comment_details")}
							{": "}
							{strings("CommentDetailsScreen.comment_id")}
							{" - "}
							{commentUuid}
						</h3>
					</div>
					{cu.isSet(postUuid) && postUuid !== "undefined" && (
						<div className="flex flex-row item-center">
							<h3 className={classNames("mb-4 text-lg font-semibold text-gray-900 mt-[-2px]",
								!isYes(isNewTab) ? "ml-10 pl-1 -mt-5" : "")}
							>
								{strings("PostView.post_details")}
								{": "}
								{strings("PostView.post_id")}
								{" - "}
								{postUuid}
							</h3>
						</div>
					)}
					<div className="space-y-4">
						<div className="flex">
							<div className="flex-shrink-0 mr-3">
								<ProfilePicture
									profilePicUrl={buildSourceUrlImage(commentsDetails?.isSubComment === YesNoOptions.YES
										? commentsDetails?.parentComment[0].profilePicRelUrl : commentsDetails?.profilePicRelUrl)
										|| Assets.UserProfileEgoDefault}
									profilePicStyle={classNames("mt-2 rounded-full w-8 h-8 sm:w-10 sm:h-10",
										commentsDetails?.isSubComment === YesNoOptions.NO ? "border-primary" : "")}
									profilePicWrapperStyle="top-0"
								/>
							</div>
							<div className={classNames("flex-1 border rounded-lg px-4 py-2 sm:px-6 sm:py-4 leading-relaxed",
								commentsDetails?.isSubComment === YesNoOptions.NO ? "border-primary" : "")}
							>
								{renderCommentHeader({
									...commentsDetails,
									username: commentsDetails?.isSubComment === YesNoOptions.YES
										? commentsDetails?.parentComment[0].username : commentsDetails?.username,
									firstName: commentsDetails?.isSubComment === YesNoOptions.YES
										? commentsDetails?.parentComment[0].firstName : commentsDetails?.firstName,
									lastName: commentsDetails?.isSubComment === YesNoOptions.YES
										? commentsDetails?.parentComment[0].lastName : commentsDetails?.lastName,
									creat_ts: commentsDetails?.isSubComment === YesNoOptions.YES
										? commentsDetails?.parentComment[0].creat_ts : commentsDetails?.creat_ts,
								})}
								<p className="text-sm">
									{commentsDetails?.isSubComment === YesNoOptions.YES
										? commentsDetails?.parentComment[0].commentText : commentsDetails?.commentText}
								</p>
								{renderSubComments}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="bg-white p-3 rounded-lg m-5 mb-4 min-h-fit max-h-full max-w-[calc(100%-2.5rem)]"
				data-testid="comment-actions-accordion"
			>
				<Accordion
					header={(
						<h5 className="mb-1">
							{strings("CommentDetailsScreen.disable_comment_actions")}
						</h5>
					)}
					content={(
						<div className={classNames("-ml-2")}>
							{commentsDetails?.admin_review === YesNoOptions.YES
								&& (
									<button
										type="button"
										className={classNames("w-full inline-flex justify-center rounded-md border",
											"shadow-sm px-4 py-2 bg-primary text-base hover:bg-blue-700 mb-5",
											"focus:outline-none border-transparent font-medium text-white ",
											"focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:ml-3 sm:w-auto sm:text-sm")}
										onClick={showRestoreConfirmationPopup}
									>
										{strings("DisabledPostPage.restore")}
									</button>
								)}
							<button
								type="button"
								className={classNames("w-full inline-flex justify-center rounded-md border",
									"shadow-sm px-4 py-2 bg-red-600 text-base hover:bg-red-700 mb-5",
									"focus:outline-none border-transparent font-medium text-white ",
									"focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm")}
								onClick={showDeleteConfirmationPopup}
							>
								{strings("DisabledPostPage.delete")}
							</button>
						</div>
					)}
					defaultOpen
				/>
			</div>
			<CommentReportedList
				data={commentReportedData}
				reportedCount={reportedCount}
			/>
			<ConfirmationModal
				isVisible={openConfirmationModal}
				onClose={closeConfirmationModal}
				cancel_button_function={closeConfirmationModal}
				ok_button_title={strings("PostSearchPage.yes")}
				cancel_button_title={strings("PostSearchPage.no")}
				ok_button_function={confirmationObj?.ok_button_function}
				title={confirmationObj?.title}
				message={confirmationObj?.message}
				okButtonStyle=""
				cancelButtonStyle=""
			/>
			<div ref={bottomRef} />
		</div>
	);
}
