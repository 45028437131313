/**
 * Data the graph needs to render
 */
export type CredoGraphDataItem = {
	/* number of credifiers */
	score: number,
	/* aggregated rating */
	aggRating: number,
}
export type CredoGraphDataSet = {
	label: string,
	/* [series][level] this needs to be aggregate at [0], then layers */
	data: CredoGraphDataItem[][],
}

export type CredoGraphData = {
	seriesLabels: string[],
	overallScore?: number,
	overallScoreData?: CredoGraphDataSet,
	tagData: CredoGraphDataSet[]
}

export interface CombinedGraphData {
	influenceGraph: CredoGraphData
	credoGraph: CredoGraphData
}

export enum GraphTypes {
	INFLUENCE = "influence_graph",
	CREDO = "credograph",
}

export enum ModalViewMode {
	/**
	 * It will render the tabs inside the component
	 * with info text as well.
	 * */
	MODAL = "modal",
	/**
	 * It will render the tabs inside the component
	 * without info.
	 * */
	MODAL_WITHOUT_INFO = "modal-without-info",
	/**
	 * It will not render the tabs in the component
	 * and will not render the content text as well.
	 * The tabs should be handled from outside the
	 * component.
	 * */
	EMBED = "embed",
}
