import * as React from "react"
import {SVGAttributes} from "react";

const SvgComponent = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { stroke = "var(--primary)" } = props;
	return (
		<svg
			width="20px"
			height="20px"
			viewBox="0 0 20 20"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M17.83 7.831h-5.661V2.169A2.171 2.171 0 0010 0C8.803 0 7.83.973 7.83 2.169v5.662H2.169A2.172 2.172 0 000 10c0 1.196.974 2.169 2.169 2.169H7.83v5.662C7.83 19.027 8.803 20 10 20a2.171 2.171 0 002.169-2.169v-5.662h5.661c1.197 0 2.17-.973 2.17-2.169a2.172 2.172 0 00-2.17-2.169z"
				stroke={stroke}
				strokeWidth={1}
				fill="none"
				fillRule="evenodd"
			/>
		</svg>
	)
}

export default SvgComponent
