import React, {
	CSSProperties, useEffect, useMemo, useRef, useState,
} from "react";
import * as d3 from "d3";
import { LabelledData, cu } from "@credo/utilities";
import styles from "../charts.module.css";
import Axis, {
	AxisOptions, AxisOrientation, AxisType, DEFAULT_AXIS_OPTIONS,
} from "./axis";

export type Dimensions = {
	height: number;
	width: number;
	axisWidth: number;
	axisHeight: number;
	marginTop: number;
	marginBottom: number;
	marginLeft: number;
	marginRight: number;
	innerHeight: number;
	innerWidth: number;
	boundedHeight: number;
}

export const DEFAULT_CHART_DIMENSIONS: Dimensions = {
	marginTop: 10,
	marginLeft: 10,
	marginRight: 10,
	marginBottom: 10,
	axisWidth: 40,
	axisHeight: 40,
	height: 500,
	width: 700,
	innerHeight: 0,
	innerWidth: 0,
	boundedHeight: 0,
};
export type SimpleGradient = {
	start: { stopColor: string, offset: string, stopOpacity: string },
	end: { stopColor: string, offset: string, stopOpacity: string },
}
export const DEFAULT_GRADIENT: SimpleGradient = {
	start: {
		stopColor: "#00B4FF",
		offset: "0%",
		stopOpacity: "0.4",
	},
	end: {
		stopColor: "#00B4FF",
		offset: "100%",
		stopOpacity: "0",
	},
};
export type TooltipOptions = {
	type: "intersect" | "axis";
	dotStyle: CSSProperties | undefined;
	containerStyle: CSSProperties | undefined;
	valueFormatter: ((value: number) => string) | "as-axis";
	labelFormatter: ((label: any) => string) | "as-axis";
	valueStyle: CSSProperties | undefined;
	labelStyle: CSSProperties | undefined;
};
export const DEFAULT_TOOLTIP_OPTIONS: TooltipOptions = {
	type: "intersect",
	dotStyle: undefined,
	containerStyle: undefined,
	valueFormatter: d3.format(",d"),
	labelFormatter: d3.timeFormat("%b %d, %Y"),
	valueStyle: undefined,
	labelStyle: undefined,
};
export type RangeOptions = {
	min: number | "auto";
	max: number | "auto";
	autoMargin: number;
}
export const DEFAULT_RANGE_OPTIONS: RangeOptions = {
	min: "auto",
	max: "auto",
	autoMargin: 0.1,
};

export interface LineChartProps {
	data: LabelledData[];
	chartDimensions: Dimensions;
	verticalAxis?: "left" | "right" | "none";
	horizontalAxis?: "top" | "bottom" | "none";
	showTooltip: boolean;
	rangeOptions: RangeOptions;
	verticalAxisOptions: AxisOptions,
	horizontalAxisOptions: AxisOptions,
	tooltipOptions?: TooltipOptions;
	containerStyle: CSSProperties | undefined,
	plotStyle: CSSProperties | undefined,
	areaStyle: CSSProperties | undefined,
	areaGradient: SimpleGradient | undefined,
	lineStyle: CSSProperties | undefined,
	tooltipDotStyle: CSSProperties | undefined,
}

export const DEFAULT_VERTICAL_AXIS_OPTIONS = DEFAULT_AXIS_OPTIONS.THE_WORKS;
export const DEFAULT_HORIZONTAL_AXIS_OPTIONS = DEFAULT_AXIS_OPTIONS.THE_WORKS;

export const LineChart = ({
	data,
	chartDimensions = DEFAULT_CHART_DIMENSIONS,
	verticalAxis = "left",
	horizontalAxis = "bottom",
	verticalAxisOptions = DEFAULT_VERTICAL_AXIS_OPTIONS,
	horizontalAxisOptions = DEFAULT_HORIZONTAL_AXIS_OPTIONS,
	showTooltip = true,
	tooltipOptions = DEFAULT_TOOLTIP_OPTIONS,
	rangeOptions = DEFAULT_RANGE_OPTIONS,
	containerStyle = undefined,
	plotStyle = undefined,
	areaStyle = undefined,
	areaGradient = DEFAULT_GRADIENT,
	lineStyle = undefined,

}: LineChartProps) => {
	// Element References
	const tooltipRef = useRef<HTMLDivElement>(null);

	// adjust dimensions for axis
	const adjustedDims = useMemo(() => ({
		...chartDimensions,
		marginLeft: verticalAxis === "left" ? chartDimensions.marginLeft + chartDimensions.axisWidth : chartDimensions.marginLeft,
		marginRight: verticalAxis === "right" ? chartDimensions.marginRight + chartDimensions.axisWidth : chartDimensions.marginRight,
		marginBottom: horizontalAxis === "bottom"
			? chartDimensions.marginBottom + chartDimensions.axisHeight : chartDimensions.marginBottom,
		marginTop: horizontalAxis === "top" ? chartDimensions.marginTop + chartDimensions.axisHeight : chartDimensions.marginTop,
	}), [chartDimensions, verticalAxis, horizontalAxis]);

	const { chartRef, dimensions } = useChartDimensions(adjustedDims);

	// Data
	const dataset = data;
	// console.log(dataset);

	// Accessors
	const xAccessor = (d: any) => d.label as Date;
	const yAccessor = (d: any) => d.value as number;

	// Scales
	const yScale = useMemo(() => d3
		.scaleLinear()
		.domain(rangeOptions.max === "auto" && rangeOptions.min === "auto"
			? d3.extent(dataset, yAccessor) as [number, number]
			: [
					// @ts-ignore
					(rangeOptions.min === "auto" ? d3.min(dataset, yAccessor) * (1 + rangeOptions.autoMargin) : rangeOptions.min) as number,
					// @ts-ignore
					(rangeOptions.max === "auto" ? d3.max(dataset, yAccessor) * (1 + rangeOptions.autoMargin) : rangeOptions.max) as number,
			])
		.range([dimensions.boundedHeight, 0])
		.nice(),
	[dimensions.boundedHeight, yAccessor, rangeOptions.max, rangeOptions.min, rangeOptions.autoMargin, dataset]);

	const xScale = useMemo(() => d3
		.scaleTime()
		.domain(d3.extent(dataset, xAccessor) as [Date, Date])
		.range([0, dimensions.boundedWidth]), [dimensions.boundedWidth, xAccessor]);

	const [lineGenerator, areaGenerator] = useMemo(() => {
		const curve = d3.curveCatmullRom.alpha(0.5);
		return [
			d3
				.line()
				.x((d) => xScale(xAccessor(d)))
				.y((d) => yScale(yAccessor(d)))
				.curve(curve),
			d3
				.area()
				.x((d) => xScale(xAccessor(d)))
				.y1((d) => yScale(yAccessor(d)))
				.y0(dimensions.boundedHeight)
				.curve(curve),
		];
	},
	[dimensions.boundedHeight, xScale, yScale, xAccessor, yAccessor]);

	const [tooltipDotState, setTooltipDotState] = useState({
		cx: 0,
		cy: 0,
		opacity: 0,
	});

	const [tooltipState, setTooltipState] = useState({
		left: 0,
		top: 0,
		display: "none",
	});
	const [tooltipData, setTooltipData] = useState({
		label: "",
		value: "",

	});

	const onMouseMove = showTooltip ? (event: any) => {
		const mousePos = d3.pointer(event);

		// x coordinate stored in mousePos index 0
		const date = xScale.invert(mousePos[0]);

		// Custom Bisector - left, center, right
		const dateBisector = d3.bisector(xAccessor).center;

		const bisectionIndex = dateBisector(dataset, date);
		// console.log(date, bisectionIndex);
		// math.max prevents negative index reference error
		const hoveredIndexData = dataset[Math.max(0, bisectionIndex)];

		const tooltipDotPos = {
			cx: xScale(xAccessor(hoveredIndexData)),
			cy: yScale(yAccessor(hoveredIndexData)),
		};

		let tooltipPos: {
			left: number,
			top: number,
			marginLeft?: number,
			marginTop?: number,
		} = {
			left: dimensions.marginLeft + tooltipDotPos.cx,
			top: dimensions.marginTop + tooltipDotPos.cy,
		};

		const tooltipX = tooltipPos.left + (tooltipRef.current?.clientWidth ?? 0);
		const chartWidth = (chartRef.current?.children[0]?.clientWidth ?? 0)
			- Number(tooltipOptions?.containerStyle?.marginLeft ?? 0);

		const tooltipY = tooltipPos.top + (tooltipRef.current?.clientHeight ?? 0);
		const chartHeight = (chartRef.current?.children[0]?.clientHeight ?? 0)
			- Number(tooltipOptions?.containerStyle?.marginTop ?? 0);

		if (tooltipY > chartHeight) {
			tooltipPos = {
				...tooltipPos,
				top: tooltipPos.top - (tooltipRef.current?.clientHeight ?? 0) - Number(tooltipOptions.containerStyle?.marginTop ?? 0),
				marginTop: 0,
			};
		}

		if (tooltipX > chartWidth) {
			tooltipPos = {
				...tooltipPos,
				left: tooltipPos.left - (tooltipRef.current?.clientWidth ?? 0) - Number(tooltipOptions.containerStyle?.marginLeft ?? 0),
				marginLeft: 0,
			};
		}

		setTooltipDotState({
			...tooltipDotPos,
			opacity: 1,
		});

		setTooltipState({
			...tooltipPos,
			display: "block",

		});

		let valueFormatter = tooltipOptions?.valueFormatter === "as-axis"
			? verticalAxisOptions.format
			: tooltipOptions?.valueFormatter;

		if (!valueFormatter) {
			valueFormatter = d3.format(",d");
		}

		let labelFormatter = tooltipOptions?.labelFormatter === "as-axis"
			? horizontalAxisOptions.format
			: tooltipOptions?.labelFormatter;

		if (!labelFormatter) {
			labelFormatter = d3.timeFormat("%B %-d, %Y");
		}

		setTooltipData({
			value: valueFormatter(yAccessor(hoveredIndexData) as number),
			label: labelFormatter(xAccessor(hoveredIndexData)),
		});
	} : () => {
	};

	const onMouseleave = showTooltip ? () => {
		setTooltipDotState({
			cx: 0,
			cy: 0,
			opacity: 0,
		});
		setTooltipState({
			left: 0,
			top: 0,
			display: "none",
		});
	} : () => {
	};

	const uniqueId = useMemo(() => Math.random(), []);

	return (
		<div
			className={styles.lineChartContainer}
			ref={chartRef}
			style={{
				width: chartDimensions.width,
				height: chartDimensions.height,
			}}
		>
			<svg
				className={styles.lineChartSvg}
				height={dimensions.height}
				width={dimensions.width}
				style={containerStyle}
			>
				<defs>
					{areaGradient && (
						<linearGradient id={`verticalGradient-${uniqueId}`} x1="0" y1="0" x2="0" y2="1">
							{/* eslint-disable-next-line react/jsx-props-no-spreading */}
							<stop {...areaGradient.start} />
							{/* eslint-disable-next-line react/jsx-props-no-spreading */}
							<stop {...areaGradient.end} />
						</linearGradient>
					)}
				</defs>
				<g
					className="container"
					width={dimensions.width - dimensions.marginLeft - dimensions.marginRight}
					height={dimensions.height - dimensions.marginTop - dimensions.marginBottom}
					transform={`translate(${dimensions.marginLeft}, ${dimensions.marginTop})`}
				>
					<rect
						className={styles.plotArea}
						width={dimensions.boundedWidth}
						height={dimensions.boundedHeight}
						style={plotStyle}
					/>

					{verticalAxis !== "none" && (
						<g
							className={styles.yAxis}
							transform={`translate(-${verticalAxis === "left" ? dimensions.axisWidth : 0} 0)`}
						>
							<Axis
								width={dimensions.axisWidth}
								domain={yScale.domain()}
								range={yScale.range()}
								type={AxisType.Linear}
								orientation={verticalAxis === "left" ? AxisOrientation.Left : AxisOrientation.Right}
								options={verticalAxisOptions}
								plotRange={xScale.range()}
							/>
						</g>
					)}

					{horizontalAxis !== "none" && (
						<g
							className={styles.xAxis}
							transform={`translate(0 -${horizontalAxis === "top" ? dimensions.axisWidth : 0})`}
						>
							<Axis
								width={dimensions.axisWidth}
								domain={xScale.domain()}
								range={xScale.range()}
								type={AxisType.Time}
								orientation={horizontalAxis === "top" ? AxisOrientation.Top : AxisOrientation.Bottom}
								options={horizontalAxisOptions}
								plotRange={yScale.range()}
								dataLength={dataset.length}
							/>
						</g>
					)}

					<path
						className={styles.area}
						d={areaGenerator(data as any) ?? undefined}
						style={areaStyle}
						fill={`url(#verticalGradient-${uniqueId})`}
					/>
					<path
						className={styles.line}
						d={lineGenerator(data as any) ?? undefined}
						style={lineStyle}
					/>

					{showTooltip && (
						<circle
							className={styles.toolTipDot}
							r={5}
							{...tooltipDotState}
							style={tooltipOptions?.dotStyle}
						/>
					)}

					<rect
						className={styles.mouseTracker}
						width={dimensions.boundedWidth}
						height={dimensions.boundedHeight}
						style={{ opacity: 0 }}
						onMouseMove={onMouseMove}
						onMouseLeave={onMouseleave}
					/>

				</g>

			</svg>
			{showTooltip && (
				<div
					ref={tooltipRef}
					className={styles.lcTooltip}
					style={{
						...tooltipOptions?.containerStyle,
						...tooltipState,
					}}
				>
					<div className={styles.label} style={tooltipOptions?.labelStyle}>{tooltipData.label}</div>
					<div className={styles.value} style={tooltipOptions?.valueStyle}>{cu.convertMSatsToSats(tooltipData.value)}</div>
				</div>
			)}

		</div>

	);
};
const combineChartDimensions = (dimensions: Dimensions) => {
	const parsedDimensions = {
		...dimensions,
		marginTop: dimensions.marginTop || 10,
		marginRight: dimensions.marginRight || 10,
		marginBottom: dimensions.marginBottom || 10,
		marginLeft: dimensions.marginLeft || 10,
	};
	return {
		...parsedDimensions,
		boundedHeight: Math.max(
			parsedDimensions.height
			- parsedDimensions.marginTop
			- parsedDimensions.marginBottom,
			0,
		),
		boundedWidth: Math.max(
			parsedDimensions.width
			- parsedDimensions.marginLeft
			- parsedDimensions.marginRight,
			0,
		),
	};
};
const useChartDimensions = (
	passedSettings: Dimensions,
): {
	chartRef: React.MutableRefObject<HTMLDivElement | null>;
	dimensions: {
		marginRight: number;
		innerWidth: number;
		innerHeight: number;
		axisWidth: number;
		boundedWidth: number;
		axisHeight: number;
		boundedHeight: number;
		width: number;
		marginBottom: number;
		marginTop: number;
		height: number;
		marginLeft: number;
	}
} => {
	const ref = useRef<HTMLDivElement>(null);
	const dimensions = combineChartDimensions(
		passedSettings,
	);

	const [width, setWidth] = useState(0);
	const [height, setHeight] = useState(0);

	if (dimensions.width && dimensions.height) return { chartRef: ref, dimensions };

	useEffect(() => {
		const element = ref.current;
		if (!element) return;

		const resizeObserver = new ResizeObserver(
			(entries) => {
				if (!Array.isArray(entries)) return;
				if (!entries.length) return;

				const entry = entries[0];

				if (width !== entry.contentRect.width) setWidth(entry.contentRect.width);
				if (height !== entry.contentRect.height) setHeight(entry.contentRect.height);
			},
		);
		resizeObserver.observe(element);

		// eslint-disable-next-line consistent-return
		return () => {
			resizeObserver.unobserve(element);
		};
	}, []);

	const newSettings = combineChartDimensions({
		...dimensions,
		width: dimensions.width || width,
		height: dimensions.height || height,
	});

	return { chartRef: ref, dimensions: newSettings };
};
export default LineChart;
