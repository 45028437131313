import React, { SVGAttributes } from "react";

const ViewConnections = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--primary)" } = props;
	return (
		<svg
			width="21px"
			height="22px"
			viewBox="0 0 21 22"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<title>connections_icon</title>
			<g id="Profile---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-icons" transform="translate(-373.000000, -393.000000)" stroke={stroke}>
					<g id="connections_icon" transform="translate(374.000000, 394.000000)">
						<path
							d="M13.7122857,12.9162857 C13.7122857,16.5715238 10.7494286,19.534381 7.09419048,19.534381 C3.43895238,19.534381 0.476095238,16.5715238 0.476095238,12.9162857 C0.476095238,9.26104762 3.43895238,6.29819048 7.09419048,6.29819048 C10.7494286,6.29819048 13.7122857,9.26104762 13.7122857,12.9162857 Z"
							id="Stroke-1"
						/>
						<path
							d="M13.4681905,2.38047619 C13.4681905,3.43190476 12.6158095,4.2852381 11.5634286,4.2852381 C10.512,4.2852381 9.65961905,3.43190476 9.65961905,2.38047619 C9.65961905,1.32904762 10.512,0.475714286 11.5634286,0.475714286 C12.6158095,0.475714286 13.4681905,1.32904762 13.4681905,2.38047619 Z"
							id="Stroke-3"
						/>
						<path
							d="M19.1810476,8.09342857 C19.1810476,9.14485714 18.3286667,9.99819048 17.2762857,9.99819048 C16.2248571,9.99819048 15.3724762,9.14485714 15.3724762,8.09342857 C15.3724762,7.042 16.2248571,6.18866667 17.2762857,6.18866667 C18.3286667,6.18866667 19.1810476,7.042 19.1810476,8.09342857 Z"
							id="Stroke-5"
						/>
						<path
							d="M7.09361905,13.0559048 C8.34980952,13.0559048 9.37171429,12.034 9.37171429,10.7778095 C9.37171429,9.52161905 8.34980952,8.50066667 7.09361905,8.50066667 C5.83838095,8.50066667 4.81647619,9.52161905 4.81647619,10.7778095 C4.81647619,12.034 5.83838095,13.0559048 7.09361905,13.0559048 Z"
							id="Stroke-7"
						/>
						<path
							d="M3.18009524,17.9248571 C3.362,15.569619 4.38009524,14.6162857 4.97057143,14.2553333 L5.11533333,14.1677143 L5.26104762,14.2658095 L7.09342857,15.6924762 L8.9267619,14.2658095 L9.07247619,14.1677143 L9.21628571,14.2553333 C9.8067619,14.6162857 10.8258095,15.5715238 11.0077143,17.9258095"
							id="Stroke-9"
						/>
						<line x1="10.7647619" y1="4.10714286" x2="9.50666667" y2="6.82428571" id="Stroke-11" />
						<line x1="12.910381" y1="10" x2="15.5332381" y2="8.8552381" id="Stroke-13" />
						<line x1="12.910381" y1="3.72704762" x2="15.930381" y2="6.74704762" id="Stroke-15" />
					</g>
				</g>
			</g>
		</svg>
	);
};

export default ViewConnections;
