import * as React from "react"
import {SVGAttributes} from "react";

const SvgComponent = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { stroke = "var(--primary)" } = props;
	return (
		<svg width="33px" height="33px" viewBox="0 0 33 33" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
			<g id="glyph-plus-down" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinejoin="round">
				<g id="Group-4" transform="translate(5, 5)" stroke={stroke} strokeWidth="3">
					<polygon id="Triangle" transform="translate(11.5, 13.3593) scale(1, -1) translate(-11.5, -13.3593)" points="11.5 7.35931429 23 19.3593143 0 19.3593143"></polygon>
				</g>
			</g>
		</svg>
	)
}

export default SvgComponent
