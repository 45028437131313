/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGAttributes } from "react";

const RemoveConnection = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--gray-dark)",
	} = props;
	return (
		<svg
			width="20px"
			height="20px"
			viewBox="0 0 20 20"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<title>remove_connection</title>
			<g id="Profile---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-icons" transform="translate(-237.000000, -1100.000000)" stroke={stroke}>
					<g id="remove_connection" transform="translate(238.000000, 1101.000000)">
						<path
							d="M11.5425,2.0001 C12.7735,0.7691 14.7685,0.7691 15.9995,2.0001 C17.2305,3.2311 17.2305,5.2261 15.9995,6.4571"
							id="Stroke-1"
						/>
						<line x1="15.9998" y1="6.4574" x2="13.5098" y2="8.9474" id="Stroke-3" strokeLinecap="round" />
						<line x1="11.5425" y1="2.0001" x2="9.0525" y2="4.4901" id="Stroke-5" strokeLinecap="round" />
						<line x1="1.7034" y1="1.7033" x2="4.0534" y2="4.0533" id="Stroke-7" strokeLinecap="round" />
						<line x1="5.8157" y1="0" x2="5.8157" y2="3.323" id="Stroke-9" strokeLinecap="round" />
						<line x1="0" y1="5.8157" x2="3.323" y2="5.8157" id="Stroke-12" strokeLinecap="round" />
						<line x1="15.9998" y1="15.9997" x2="13.6498" y2="13.6497" id="Stroke-14" strokeLinecap="round" />
						<line x1="11.8874" y1="17.7031" x2="11.8874" y2="14.3801" id="Stroke-16" strokeLinecap="round" />
						<line x1="17.7031" y1="11.8874" x2="14.3801" y2="11.8874" id="Stroke-18" strokeLinecap="round" />
						<path
							d="M1.7938,11.7488 L5.3438,8.1988 C6.5748,6.9678 8.5698,6.9678 9.8008,8.1988 C11.0318,9.4298 11.0318,11.4258 9.8008,12.6558 L6.2508,16.2058 C5.0198,17.4368 3.0248,17.4368 1.7938,16.2058 C0.5628,14.9748 0.5628,12.9798 1.7938,11.7488 Z"
							id="Stroke-21"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default RemoveConnection;
