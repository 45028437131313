import React from "react";
import { classNames } from "../common";
import styles from "./credo-badge.module.css";

export enum CredoBadgeType {
	BUBBLE = "bubble",
	DOT = "dot",
	TRIANGLE = "triangle",
}

interface CredoBadgeProps {
	/**
	 * Badge Value
	 * */
	badgeValue: number | string,
	/**
	 * CSS style class for badge container
	 * */
	containerStyle?: any;
	/**
	 * CSS style class for badge text
	 * */
	badgeValueStyle?: any;
	/**
	 * to render if the badge is a triangle or circle
	 *
	 * @default CredoBadgeType.BUBBLE
	 * */
	badgeType?: CredoBadgeType;
}

export function CredoBadge({
	badgeValue,
	containerStyle,
	badgeValueStyle,
	badgeType = CredoBadgeType.BUBBLE,
}: CredoBadgeProps) {
	if (badgeType === CredoBadgeType.TRIANGLE) {
		return (
			<span
				className={classNames(
					"block",
					styles.upArrow,
					containerStyle,
				)}
			/>
		);
	}

	if (badgeType === CredoBadgeType.DOT) {
		return (
			<span
				className={classNames(
					"block",
					"h-[7px] w-[7px] bg-control-freshness",
					"border border-control-freshness-border rounded-full",
					containerStyle,
				)}
			/>
		);
	}

	if (!badgeValue) {
		return null;
	}

	return (
		<div
			className={
				classNames(
					"flex min-w-[18px] max-w-min h-[18px] justify-center items-center px-1",
					"border border-control-freshness bg-control-freshness text-badge-text rounded-full",
					containerStyle,
				)
			}
		>
			<p
				className={
					classNames(
						"text-center text-xs text-badge-text align-middle px-px",
						badgeValueStyle,
					)
				}
			>
				{Number(badgeValue) > 9 ? "9+" : badgeValue}
			</p>
		</div>
	);
}

CredoBadge.defaultProps = {
	containerStyle: "",
	badgeValueStyle: "",
};
