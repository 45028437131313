import React, { SVGAttributes } from "react";

const CommunitiesCredo = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--gray-dark)" } = props;
	return (
		<svg
			width="22px"
			height="21px"
			viewBox="0 0 22 21"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<title>communities_credo</title>
			<g id="Profile---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-icons" transform="translate(-498.000000, -924.000000)">
					<g id="communities_credo" transform="translate(499.000000, 925.000000)">
						<polygon id="Stroke-1" stroke="var(--gray-dark)" points="5.34285714 16.7978095 2.4647619 7.93971429 10 2.46447619 17.5352381 7.93971429 14.6571429 16.7978095" />
						<g id="Group-28" transform="translate(0.000000, 0.214762)">
							<path
								d="M19.5238095,7.72457143 C19.5238095,8.82266667 18.6333333,9.71314286 17.5352381,9.71314286 C16.4371429,9.71314286 15.5466667,8.82266667 15.5466667,7.72457143 C15.5466667,6.62647619 16.4371429,5.736 17.5352381,5.736 C18.6333333,5.736 19.5238095,6.62647619 19.5238095,7.72457143"
								id="Fill-2"
								fill="#212636"
							/>
							<path
								d="M19.5238095,7.72457143 C19.5238095,8.82266667 18.6333333,9.71314286 17.5352381,9.71314286 C16.4371429,9.71314286 15.5466667,8.82266667 15.5466667,7.72457143 C15.5466667,6.62647619 16.4371429,5.736 17.5352381,5.736 C18.6333333,5.736 19.5238095,6.62647619 19.5238095,7.72457143 Z"
								id="Stroke-4"
								stroke={stroke}
							/>
							<path
								d="M11.988381,2.24980952 C11.988381,3.34790476 11.0979048,4.23838095 9.99980952,4.23838095 C8.90171429,4.23838095 8.0112381,3.34790476 8.0112381,2.24980952 C8.0112381,1.15171429 8.90171429,0.261238095 9.99980952,0.261238095 C11.0979048,0.261238095 11.988381,1.15171429 11.988381,2.24980952"
								id="Fill-6"
								fill="#212636"
							/>
							<path
								d="M11.988381,2.24980952 C11.988381,3.34790476 11.0979048,4.23838095 9.99980952,4.23838095 C8.90171429,4.23838095 8.0112381,3.34790476 8.0112381,2.24980952 C8.0112381,1.15171429 8.90171429,0.261238095 9.99980952,0.261238095 C11.0979048,0.261238095 11.988381,1.15171429 11.988381,2.24980952 Z"
								id="Stroke-8"
								stroke={stroke}
							/>
							<path
								d="M16.6455238,16.5830476 C16.6455238,17.6811429 15.7550476,18.571619 14.6569524,18.571619 C13.5588571,18.571619 12.668381,17.6811429 12.668381,16.5830476 C12.668381,15.4849524 13.5588571,14.5944762 14.6569524,14.5944762 C15.7550476,14.5944762 16.6455238,15.4849524 16.6455238,16.5830476"
								id="Fill-10"
								fill="#212636"
							/>
							<path
								d="M16.6455238,16.5830476 C16.6455238,17.6811429 15.7550476,18.571619 14.6569524,18.571619 C13.5588571,18.571619 12.668381,17.6811429 12.668381,16.5830476 C12.668381,15.4849524 13.5588571,14.5944762 14.6569524,14.5944762 C15.7550476,14.5944762 16.6455238,15.4849524 16.6455238,16.5830476 Z"
								id="Stroke-12"
								stroke={stroke}
							/>
							<path
								d="M7.33114286,16.5830476 C7.33114286,17.6811429 6.44066667,18.571619 5.34257143,18.571619 C4.24447619,18.571619 3.354,17.6811429 3.354,16.5830476 C3.354,15.4849524 4.24447619,14.5944762 5.34257143,14.5944762 C6.44066667,14.5944762 7.33114286,15.4849524 7.33114286,16.5830476"
								id="Fill-14"
								fill="#212636"
							/>
							<path
								d="M7.33114286,16.5830476 C7.33114286,17.6811429 6.44066667,18.571619 5.34257143,18.571619 C4.24447619,18.571619 3.354,17.6811429 3.354,16.5830476 C3.354,15.4849524 4.24447619,14.5944762 5.34257143,14.5944762 C6.44066667,14.5944762 7.33114286,15.4849524 7.33114286,16.5830476 Z"
								id="Stroke-16"
								stroke={stroke}
							/>
							<path
								d="M4.45285714,7.72457143 C4.45285714,8.82266667 3.56238095,9.71314286 2.46428571,9.71314286 C1.36619048,9.71314286 0.475714286,8.82266667 0.475714286,7.72457143 C0.475714286,6.62647619 1.36619048,5.736 2.46428571,5.736 C3.56238095,5.736 4.45285714,6.62647619 4.45285714,7.72457143"
								id="Fill-18"
								fill="#212636"
							/>
							<path
								d="M4.45285714,7.72457143 C4.45285714,8.82266667 3.56238095,9.71314286 2.46428571,9.71314286 C1.36619048,9.71314286 0.475714286,8.82266667 0.475714286,7.72457143 C0.475714286,6.62647619 1.36619048,5.736 2.46428571,5.736 C3.56238095,5.736 4.45285714,6.62647619 4.45285714,7.72457143 Z"
								id="Stroke-20"
								stroke={stroke}
							/>
							<path
								d="M13.9766667,10.1309524 C13.9766667,12.3271429 12.1966667,14.1080952 9.99952381,14.1080952 C7.80333333,14.1080952 6.02333333,12.3271429 6.02333333,10.1309524 C6.02333333,7.9347619 7.80333333,6.15380952 9.99952381,6.15380952 C12.1966667,6.15380952 13.9766667,7.9347619 13.9766667,10.1309524 Z"
								id="Stroke-22"
								stroke={stroke}
							/>
							<path
								d="M10.115619,10.6059048 C10.8118095,10.6059048 11.3784762,10.0392381 11.3784762,9.34304762 C11.3784762,8.64590476 10.8118095,8.08019048 10.115619,8.08019048 C9.41942857,8.08019048 8.8527619,8.64590476 8.8527619,9.34304762 C8.8527619,10.0392381 9.41942857,10.6059048 10.115619,10.6059048 Z"
								id="Stroke-24"
								stroke={stroke}
							/>
							<path
								d="M7.764,13.6372381 C7.87352381,12.3381905 8.48495238,11.8115238 8.84019048,11.6124762 L8.92685714,11.5639048 L9.01447619,11.6181905 L10.1154286,12.4058095 L11.2173333,11.6181905 L11.3049524,11.5639048 L11.391619,11.6124762 C11.7459048,11.8115238 12.3582857,12.3381905 12.4668571,13.6381905"
								id="Stroke-26"
								stroke={stroke}
							/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default CommunitiesCredo;
