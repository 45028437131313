import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { AppMode } from "@credo/utilities";
import { CredoSwitchProps } from "../../credo-switch";
import { ArrowType, SuggestionConnCardMessages } from "../types";
import SuggestedConnInfo from "./SuggestedConnCardInfo";
import { classNames, SvgIcon } from "../../common";
import { LeftArrowIcon, RightArrowIcon } from "../../assets/icons";

interface SuggestedConnCardProps {
	item: {
		"p.postUuid": string,
		"p.postType": string,
		cardData: any[];
	};
	width: number;
	handleClickUser: (id: string, username: string, mode: string, egousername: string, targetText: string) => void;
	onRemoveCard: (data: any) => void;
	switchProps: CredoSwitchProps;
	messages: SuggestionConnCardMessages;
}

export const SuggestedConnCard = (props: SuggestedConnCardProps) => {
	const {
		item,
		handleClickUser,
		onRemoveCard,
		width = 600,
		switchProps,
		messages,
	} = props;

	const [cards, setCards] = useState<any>(item?.cardData || []);

	useEffect(() => {
		setCards(item.cardData.filter((ele) => ele["p.firstName"]?.trim() && ele["p.lastName"]?.trim()));
	}, [item]);

	const removeCard = (card: any) => {
		const newCards = cards.filter((u: any) => u["p.postUuid"] !== card["p.postUuid"]);
		setCards(newCards);
		if (onRemoveCard) {
			onRemoveCard({
				isConnReqSent: false,
				profId: card["p.dest_prof_id"],
				mode: AppMode.EGO,
			});
		}
	};

	const updateCardItems = (user: any) => {
		const updatedCards = cards.map((u: any) => (u["p.postUuid"] === user["p.postUuid"] ? { ...u, "p.isRequested": true } : u));
		setCards(updatedCards);
		if (onRemoveCard) {
			onRemoveCard({
				isConnReqSent: true,
				profId: user["p.dest_prof_id"],
				mode: AppMode.EGO,
			});
		}
	};

	const renderCard = (card: any, idx: number) => (
		<SuggestedConnInfo
			key={idx}
			card={card}
			handleClickUser={handleClickUser}
			removeCard={() => removeCard(card)}
			switchProps={switchProps}
			messages={messages}
			updateCard={() => updateCardItems(card)}
		/>
	);

	if (cards && cards.length === 0) {
		return null;
	}

	const renderArrow = (direction: ArrowType) => (onClickHandler: () => void, shouldBeEnabled: boolean, label: string) => {
		if (!shouldBeEnabled) {
			return null;
		}
		return (
			<button
				className={classNames(
					"absolute top-0 z-10 h-full p-2 outline-none",
					direction === ArrowType.Previous ? "left-1" : "right-1",
				)}
				type="button"
				onClick={onClickHandler}
			>
				{direction === ArrowType.Previous
					? (
						<SvgIcon
							icon={LeftArrowIcon}
						/>
					) : (
						<SvgIcon
							icon={RightArrowIcon}
						/>
					)}
			</button>
		);
	};

	return (
		<div
			/**
			 * minimum height because when user goes back to home feed the ui shifts little because carousel renders late
			 * */
			style={{ minHeight: "222px", zIndex: 11 }}
			className="flex w-full py-4 justify-center bg-background-tertiary relative"
		>
			<Carousel
				width={width}
				autoPlay={false}
				showStatus={false}
				showIndicators={false}
				showThumbs={false}
				preventMovementUntilSwipeScrollTolerance
				infiniteLoop
				renderArrowNext={renderArrow(ArrowType.Next)}
				renderArrowPrev={renderArrow(ArrowType.Previous)}
			>
				{cards.map((card: any, idx: number) => renderCard(card, idx))}
			</Carousel>
		</div>
	);
};

export default SuggestedConnCard;
