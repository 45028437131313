/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGAttributes } from "react";

const AFSphoneChanged = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--primary)",
	} = props;
	return (
		<svg
			width="101px"
			height="101px"
			viewBox="0 0 101 101"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<g id="Settings---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Settings---Export" transform="translate(-57.000000, -775.000000)">
					<g id="AFSphoneChanged" transform="translate(57.000000, 775.000000)">
						<path d="M21.7339,13.5722 C21.7339,15.7702 19.9529,17.5512 17.7549,17.5512 C15.5579,17.5512 13.7759,15.7702 13.7759,13.5722 C13.7759,11.3752 15.5579,9.5932 17.7549,9.5932 C19.9529,9.5932 21.7339,11.3752 21.7339,13.5722 Z" id="Stroke-1" stroke={stroke} />
						<path d="M32.2549,13.5722 C32.2549,15.7702 30.4739,17.5512 28.2759,17.5512 C26.0789,17.5512 24.2969,15.7702 24.2969,13.5722 C24.2969,11.3752 26.0789,9.5932 28.2759,9.5932 C30.4739,9.5932 32.2549,11.3752 32.2549,13.5722 Z" id="Stroke-3" stroke={stroke} />
						<path d="M21.7339,24.0933 C21.7339,26.2913 19.9529,28.0723 17.7549,28.0723 C15.5579,28.0723 13.7759,26.2913 13.7759,24.0933 C13.7759,21.8953 15.5579,20.1143 17.7549,20.1143 C19.9529,20.1143 21.7339,21.8953 21.7339,24.0933 Z" id="Stroke-5" stroke={stroke} />
						<path d="M32.2811,23.9425 C32.2811,26.1545 30.4881,27.9475 28.2761,27.9475 C26.0651,27.9475 24.2721,26.1545 24.2721,23.9425 C24.2721,21.7305 26.0651,19.9375 28.2761,19.9375 C30.4881,19.9375 32.2811,21.7305 32.2811,23.9425 Z" id="Stroke-7" stroke={stroke} />
						<path d="M42.776,24.0933 C42.776,26.2913 40.995,28.0723 38.797,28.0723 C36.6,28.0723 34.818,26.2913 34.818,24.0933 C34.818,21.8953 36.6,20.1143 38.797,20.1143 C40.995,20.1143 42.776,21.8953 42.776,24.0933 Z" id="Stroke-9" stroke={stroke} />
						<path d="M21.7339,34.6144 C21.7339,36.8114 19.9529,38.5934 17.7549,38.5934 C15.5579,38.5934 13.7759,36.8114 13.7759,34.6144 C13.7759,32.4164 15.5579,30.6354 17.7549,30.6354 C19.9529,30.6354 21.7339,32.4164 21.7339,34.6144 Z" id="Stroke-11" stroke={stroke} />
						<path d="M32.2549,34.6144 C32.2549,36.8114 30.4739,38.5934 28.2759,38.5934 C26.0789,38.5934 24.2969,36.8114 24.2969,34.6144 C24.2969,32.4164 26.0789,30.6354 28.2759,30.6354 C30.4739,30.6354 32.2549,32.4164 32.2549,34.6144 Z" id="Stroke-13" stroke={stroke} />
						<path d="M32.2549,45.1354 C32.2549,47.3334 30.4739,49.1144 28.2759,49.1144 C26.0789,49.1144 24.2969,47.3334 24.2969,45.1354 C24.2969,42.9384 26.0789,41.1564 28.2759,41.1564 C30.4739,41.1564 32.2549,42.9384 32.2549,45.1354 Z" id="Stroke-15" stroke={stroke} />
						<path d="M42.776,34.6144 C42.776,36.8114 40.995,38.5934 38.797,38.5934 C36.6,38.5934 34.818,36.8114 34.818,34.6144 C34.818,32.4164 36.6,30.6354 38.797,30.6354 C40.995,30.6354 42.776,32.4164 42.776,34.6144 Z" id="Stroke-17" stroke={stroke} />
						<path d="M42.776,13.7287 C42.776,15.9267 40.995,17.7077 38.797,17.7077 C36.6,17.7077 34.818,15.9267 34.818,13.7287 C34.818,11.5317 36.6,9.7497 38.797,9.7497 C40.995,9.7497 42.776,11.5317 42.776,13.7287 Z" id="Stroke-19" stroke={stroke} />
						<path d="M87.8481,29.0046 C87.8481,35.3496 82.7041,40.4936 76.3591,40.4936 C70.0141,40.4936 64.8701,35.3496 64.8701,29.0046 C64.8701,22.6596 70.0141,17.5156 76.3591,17.5156 C82.7041,17.5156 87.8481,22.6596 87.8481,29.0046 Z" id="Stroke-21" stroke={stroke} />
						<polygon id="Stroke-23" stroke={stroke} points="75.5432 35.2278 69.9602 30.1978 72.1112 27.8108 75.0322 30.4418 80.3702 23.0638 82.9782 24.9448" />
						<path d="M31.1262,80.0916 C17.5382,78.8066 7.5652,66.7486 8.8502,53.1606" id="Stroke-25" stroke={stroke} strokeLinecap="round" />
						<polyline id="Stroke-27" stroke={stroke} strokeLinecap="round" points="27.1589 76.1244 31.1259 80.0914 27.1589 84.0594" />
						<path d="M36.8792,52.8264 C33.9582,53.9084 30.7982,54.5004 27.5002,54.5004 C12.5882,54.5004 0.5002,42.4114 0.5002,27.5004 C0.5002,12.5884 12.5882,0.5004 27.5002,0.5004 C42.4122,0.5004 54.5002,12.5884 54.5002,27.5004 C54.5002,30.8894 53.8752,34.1334 52.7352,37.1224" id="Stroke-29" stroke={stroke} />
						<path d="M83.5024,37.9871 C93.6494,43.7201 100.5004,54.6071 100.5004,67.0931 C100.5004,85.5431 85.5434,100.5001 67.0934,100.5001 C48.6424,100.5001 33.6864,85.5431 33.6864,67.0931 C33.6864,49.0481 47.9934,34.3441 65.8834,33.7071" id="Stroke-31" stroke={stroke} />
						<path d="M81.0109,39.5089 L82.3199,40.0849 C91.7359,45.4049 98.0929,55.5069 98.0929,67.0939 C98.0929,84.2149 84.2139,98.0939 67.0929,98.0939 C49.9719,98.0939 36.0929,84.2149 36.0929,67.0939 C36.0929,50.3489 49.3699,36.7039 65.9709,36.1139 L67.2149,36.1139" id="Stroke-33" stroke={stroke} />
						<path d="M59.6303,49.0697 C59.6303,51.8797 57.3523,54.1567 54.5433,54.1567 C51.7333,54.1567 49.4563,51.8797 49.4563,49.0697 C49.4563,46.2597 51.7333,43.9827 54.5433,43.9827 C57.3523,43.9827 59.6303,46.2597 59.6303,49.0697 Z" id="Stroke-35" stroke={stroke} />
						<path d="M73.0818,49.0697 C73.0818,51.8797 70.8038,54.1567 67.9948,54.1567 C65.1848,54.1567 62.9078,51.8797 62.9078,49.0697 C62.9078,46.2597 65.1848,43.9827 67.9948,43.9827 C70.8038,43.9827 73.0818,46.2597 73.0818,49.0697 Z" id="Stroke-37" stroke={stroke} />
						<path d="M59.6303,62.5212 C59.6303,65.3312 57.3523,67.6082 54.5433,67.6082 C51.7333,67.6082 49.4563,65.3312 49.4563,62.5212 C49.4563,59.7112 51.7333,57.4342 54.5433,57.4342 C57.3523,57.4342 59.6303,59.7112 59.6303,62.5212 Z" id="Stroke-39" stroke={stroke} />
						<path d="M73.0818,62.5212 C73.0818,65.3312 70.8038,67.6082 67.9948,67.6082 C65.1848,67.6082 62.9078,65.3312 62.9078,62.5212 C62.9078,59.7112 65.1848,57.4342 67.9948,57.4342 C70.8038,57.4342 73.0818,59.7112 73.0818,62.5212 Z" id="Stroke-41" stroke={stroke} />
						<path d="M86.5334,62.5212 C86.5334,65.3312 84.2554,67.6082 81.4464,67.6082 C78.6364,67.6082 76.3594,65.3312 76.3594,62.5212 C76.3594,59.7112 78.6364,57.4342 81.4464,57.4342 C84.2554,57.4342 86.5334,59.7112 86.5334,62.5212 Z" id="Stroke-43" stroke={stroke} />
						<path d="M59.6303,75.9728 C59.6303,78.7828 57.3523,81.0598 54.5433,81.0598 C51.7333,81.0598 49.4563,78.7828 49.4563,75.9728 C49.4563,73.1628 51.7333,70.8858 54.5433,70.8858 C57.3523,70.8858 59.6303,73.1628 59.6303,75.9728 Z" id="Stroke-45" stroke={stroke} />
						<path d="M73.0818,75.9728 C73.0818,78.7828 70.8038,81.0598 67.9948,81.0598 C65.1848,81.0598 62.9078,78.7828 62.9078,75.9728 C62.9078,73.1628 65.1848,70.8858 67.9948,70.8858 C70.8038,70.8858 73.0818,73.1628 73.0818,75.9728 Z" id="Stroke-47" stroke={stroke} />
						<path d="M73.0818,89.4243 C73.0818,92.2343 70.8038,94.5113 67.9948,94.5113 C65.1848,94.5113 62.9078,92.2343 62.9078,89.4243 C62.9078,86.6143 65.1848,84.3373 67.9948,84.3373 C70.8038,84.3373 73.0818,86.6143 73.0818,89.4243 Z" id="Stroke-49" stroke={stroke} />
						<path d="M86.5334,75.9728 C86.5334,78.7828 84.2554,81.0598 81.4464,81.0598 C78.6364,81.0598 76.3594,78.7828 76.3594,75.9728 C76.3594,73.1628 78.6364,70.8858 81.4464,70.8858 C84.2554,70.8858 86.5334,73.1628 86.5334,75.9728 Z" id="Stroke-51" stroke={stroke} />
						<path d="M55.3427,51.8433 L54.3817,51.8433 L54.3817,48.4473 C54.3817,48.2843 54.3847,48.1113 54.3897,47.9283 C54.3947,47.7463 54.4017,47.5753 54.4117,47.4173 C54.3617,47.4683 54.2987,47.5293 54.2247,47.6003 C54.1517,47.6713 54.0707,47.7413 53.9847,47.8063 L53.3737,48.2953 L52.8937,47.6993 L54.5417,46.3953 L55.3427,46.3953 L55.3427,51.8433 Z" id="Fill-53" fill="#00A0FF" />
						<path d="M69.8697,51.8433 L66.1387,51.8433 L66.1387,51.1343 L67.5277,49.7303 C67.8027,49.4493 68.0277,49.2103 68.2037,49.0123 C68.3777,48.8133 68.5077,48.6273 68.5917,48.4513 C68.6767,48.2763 68.7177,48.0863 68.7177,47.8823 C68.7177,47.6333 68.6487,47.4463 68.5077,47.3223 C68.3677,47.1973 68.1777,47.1353 67.9387,47.1353 C67.7107,47.1353 67.4967,47.1823 67.2977,47.2763 C67.1007,47.3703 66.8947,47.5043 66.6807,47.6763 L66.1537,47.0443 C66.3777,46.8503 66.6307,46.6813 66.9137,46.5363 C67.1957,46.3913 67.5557,46.3183 67.9937,46.3183 C68.3437,46.3183 68.6457,46.3813 68.8977,46.5053 C69.1497,46.6303 69.3437,46.8023 69.4807,47.0243 C69.6187,47.2453 69.6867,47.4993 69.6867,47.7843 C69.6867,48.0783 69.6287,48.3493 69.5107,48.5963 C69.3947,48.8433 69.2247,49.0903 69.0037,49.3373 C68.7827,49.5833 68.5197,49.8493 68.2147,50.1333 L67.3527,50.9653 L67.3527,51.0113 L69.8697,51.0113 L69.8697,51.8433 Z" id="Fill-55" fill="#00A0FF" />
						<path d="M54.9308,63.3418 L54.9308,62.1218 C54.9308,61.9788 54.9338,61.8298 54.9388,61.6748 C54.9438,61.5198 54.9498,61.3748 54.9578,61.2398 C54.9648,61.1048 54.9718,61.0028 54.9768,60.9308 L54.9458,60.9308 C54.9008,61.0328 54.8508,61.1328 54.7968,61.2328 C54.7438,61.3318 54.6838,61.4288 54.6188,61.5258 L53.3818,63.3418 L54.9308,63.3418 Z M56.5868,64.1288 L55.8768,64.1288 L55.8768,65.2958 L54.9308,65.2958 L54.9308,64.1288 L52.5038,64.1288 L52.5038,63.4108 L54.9538,59.8318 L55.8768,59.8318 L55.8768,63.3418 L56.5868,63.3418 L56.5868,64.1288 Z" id="Fill-57" fill="#00A0FF" />
						<path d="M68.0315,61.8692 C68.3775,61.8692 68.6825,61.9322 68.9465,62.0602 C69.2105,62.1872 69.4185,62.3712 69.5685,62.6132 C69.7195,62.8542 69.7935,63.1512 69.7935,63.5022 C69.7935,64.0872 69.6115,64.5452 69.2475,64.8752 C68.8845,65.2062 68.3585,65.3722 67.6725,65.3722 C67.3875,65.3722 67.1195,65.3472 66.8675,65.2952 C66.6155,65.2442 66.4005,65.1712 66.2225,65.0742 L66.2225,64.2202 C66.4115,64.3212 66.6365,64.4052 66.8985,64.4722 C67.1595,64.5372 67.4105,64.5702 67.6495,64.5702 C68.0105,64.5702 68.2945,64.4922 68.5005,64.3342 C68.7065,64.1762 68.8085,63.9292 68.8085,63.5942 C68.8085,63.2932 68.7125,63.0632 68.5195,62.9032 C68.3255,62.7432 68.0265,62.6632 67.6185,62.6632 C67.4705,62.6632 67.3165,62.6762 67.1535,62.7012 C66.9905,62.7272 66.8565,62.7522 66.7485,62.7772 L66.3365,62.5482 L66.5425,59.8472 L69.4125,59.8472 L69.4125,60.6872 L67.3905,60.6872 L67.2765,61.9452 C67.3625,61.9302 67.4645,61.9142 67.5845,61.8962 C67.7045,61.8782 67.8525,61.8692 68.0315,61.8692" id="Fill-59" fill="#00A0FF" />
						<path d="M81.5128,64.5864 C81.7718,64.5864 81.9828,64.4984 82.1468,64.3224 C82.3098,64.1474 82.3908,63.8764 82.3908,63.5094 C82.3908,63.2154 82.3198,62.9824 82.1768,62.8114 C82.0338,62.6414 81.8198,62.5564 81.5358,62.5564 C81.3368,62.5564 81.1648,62.5984 81.0208,62.6814 C80.8758,62.7664 80.7628,62.8724 80.6808,63.0024 C80.5998,63.1314 80.5588,63.2654 80.5588,63.4034 C80.5588,63.5914 80.5948,63.7754 80.6658,63.9564 C80.7378,64.1374 80.8428,64.2874 80.9828,64.4064 C81.1218,64.5264 81.2988,64.5864 81.5128,64.5864 M79.5898,62.9764 C79.5898,62.6604 79.6108,62.3504 79.6548,62.0454 C79.6978,61.7394 79.7748,61.4514 79.8878,61.1794 C79.9988,60.9074 80.1548,60.6644 80.3528,60.4534 C80.5518,60.2434 80.8048,60.0774 81.1118,59.9574 C81.4208,59.8384 81.7918,59.7784 82.2298,59.7784 C82.3368,59.7784 82.4588,59.7834 82.5968,59.7944 C82.7328,59.8044 82.8478,59.8194 82.9398,59.8394 L82.9398,60.6324 C82.7418,60.5774 82.5228,60.5494 82.2828,60.5494 C81.8248,60.5494 81.4698,60.6314 81.2158,60.7974 C80.9608,60.9624 80.7828,61.1894 80.6808,61.4764 C80.5798,61.7634 80.5188,62.0854 80.4978,62.4414 L80.5438,62.4414 C80.6508,62.2644 80.8038,62.1124 81.0018,61.9874 C81.2008,61.8634 81.4568,61.8014 81.7718,61.8014 C82.2448,61.8014 82.6248,61.9484 82.9088,62.2424 C83.1948,62.5384 83.3368,62.9584 83.3368,63.5024 C83.3368,64.0874 83.1728,64.5454 82.8478,64.8754 C82.5228,65.2064 82.0828,65.3724 81.5278,65.3724 C81.1668,65.3724 80.8398,65.2854 80.5478,65.1124 C80.2548,64.9404 80.0218,64.6754 79.8498,64.3224 C79.6768,63.9684 79.5898,63.5204 79.5898,62.9764" id="Fill-61" fill="#00A0FF" />
						<polygon id="Fill-63" fill="#00A0FF" points="53.2754 78.7468 55.3954 74.1298 52.6114 74.1298 52.6114 73.2988 56.4264 73.2988 56.4264 73.9478 54.3054 78.7468" />
						<path d="M67.9853,73.9544 C67.7613,73.9544 67.5773,74.0104 67.4323,74.1224 C67.2873,74.2344 67.2153,74.3924 67.2153,74.5954 C67.2153,74.8194 67.2913,74.9984 67.4443,75.1294 C67.5963,75.2624 67.7813,75.3764 68.0003,75.4734 C68.2093,75.3814 68.3883,75.2704 68.5383,75.1374 C68.6883,75.0054 68.7633,74.8244 68.7633,74.5954 C68.7633,74.3924 68.6913,74.2344 68.5463,74.1224 C68.4013,74.0104 68.2143,73.9544 67.9853,73.9544 M67.0313,77.3194 C67.0313,77.5434 67.1103,77.7284 67.2683,77.8734 C67.4263,78.0184 67.6653,78.0914 67.9853,78.0914 C68.3003,78.0914 68.5393,78.0194 68.7023,77.8774 C68.8653,77.7344 68.9463,77.5484 68.9463,77.3194 C68.9463,77.0914 68.8553,76.9004 68.6723,76.7484 C68.4883,76.5954 68.2723,76.4624 68.0233,76.3514 L67.9173,76.2964 C67.6413,76.4244 67.4263,76.5684 67.2683,76.7284 C67.1103,76.8884 67.0313,77.0864 67.0313,77.3194 M67.9933,73.2304 C68.3083,73.2304 68.5933,73.2784 68.8473,73.3744 C69.1023,73.4714 69.3053,73.6174 69.4583,73.8134 C69.6103,74.0094 69.6873,74.2494 69.6873,74.5354 C69.6873,74.8604 69.5903,75.1284 69.3963,75.3394 C69.2033,75.5504 68.9663,75.7274 68.6873,75.8704 C68.8963,75.9764 69.0893,76.0964 69.2673,76.2284 C69.4453,76.3614 69.5893,76.5174 69.6983,76.6974 C69.8073,76.8784 69.8623,77.0914 69.8623,77.3354 C69.8623,77.6354 69.7833,77.8974 69.6253,78.1214 C69.4683,78.3454 69.2513,78.5174 68.9733,78.6404 C68.6963,78.7624 68.3723,78.8234 68.0003,78.8234 C67.3943,78.8234 66.9293,78.6934 66.6043,78.4344 C66.2783,78.1744 66.1163,77.8184 66.1163,77.3654 C66.1163,77.1164 66.1643,76.9004 66.2603,76.7164 C66.3573,76.5344 66.4863,76.3764 66.6503,76.2434 C66.8133,76.1124 66.9933,75.9974 67.1913,75.9004 C66.9423,75.7484 66.7293,75.5634 66.5553,75.3474 C66.3793,75.1314 66.2913,74.8574 66.2913,74.5274 C66.2913,74.2474 66.3693,74.0104 66.5233,73.8174 C66.6793,73.6244 66.8853,73.4784 67.1423,73.3784 C67.3983,73.2794 67.6833,73.2304 67.9933,73.2304" id="Fill-65" fill="#00A0FF" />
						<path d="M67.0923,89.4738 C67.0923,90.1408 67.1573,90.6408 67.2873,90.9778 C67.4163,91.3128 67.6493,91.4808 67.9853,91.4808 C68.3153,91.4808 68.5493,91.3138 68.6833,90.9808 C68.8183,90.6478 68.8853,90.1458 68.8853,89.4738 C68.8853,88.8078 68.8183,88.3058 68.6833,87.9668 C68.5493,87.6288 68.3153,87.4588 67.9853,87.4588 C67.6493,87.4588 67.4163,87.6288 67.2873,87.9668 C67.1573,88.3058 67.0923,88.8078 67.0923,89.4738 M69.8543,89.4738 C69.8543,90.0588 69.7933,90.5598 69.6723,90.9778 C69.5493,91.3948 69.3513,91.7148 69.0763,91.9388 C68.8023,92.1628 68.4383,92.2748 67.9853,92.2748 C67.3443,92.2748 66.8733,92.0268 66.5743,91.5338 C66.2733,91.0408 66.1233,90.3538 66.1233,89.4738 C66.1233,88.8888 66.1843,88.3858 66.3063,87.9668 C66.4283,87.5468 66.6263,87.2258 66.8983,87.0018 C67.1693,86.7778 67.5323,86.6648 67.9853,86.6648 C68.6213,86.6648 69.0923,86.9128 69.3963,87.4058 C69.7023,87.8998 69.8543,88.5888 69.8543,89.4738" id="Fill-67" fill="#00A0FF" />
						<path d="M81.3833,74.0237 C81.1243,74.0237 80.9123,74.1097 80.7493,74.2827 C80.5863,74.4557 80.5053,74.7257 80.5053,75.0917 C80.5053,75.3917 80.5753,75.6267 80.7153,75.7977 C80.8553,75.9687 81.0703,76.0527 81.3603,76.0527 C81.5593,76.0527 81.7323,76.0117 81.8793,75.9277 C82.0263,75.8427 82.1393,75.7357 82.2193,75.6027 C82.2973,75.4707 82.3363,75.3357 82.3363,75.1987 C82.3363,75.0097 82.3013,74.8277 82.2303,74.6497 C82.1583,74.4707 82.0533,74.3217 81.9133,74.2027 C81.7733,74.0827 81.5973,74.0237 81.3833,74.0237 M83.3063,75.6257 C83.3063,75.9367 83.2833,76.2457 83.2373,76.5527 C83.1913,76.8607 83.1133,77.1507 83.0003,77.4227 C82.8893,77.6947 82.7333,77.9377 82.5353,78.1477 C82.3363,78.3587 82.0833,78.5247 81.7763,78.6437 C81.4683,78.7627 81.0963,78.8227 80.6583,78.8227 C80.5563,78.8227 80.4343,78.8177 80.2923,78.8077 C80.1493,78.7977 80.0353,78.7817 79.9493,78.7617 L79.9493,77.9687 C80.1463,78.0247 80.3653,78.0527 80.6053,78.0527 C81.0633,78.0527 81.4183,77.9697 81.6733,77.8047 C81.9273,77.6387 82.1063,77.4137 82.2113,77.1297 C82.3153,76.8447 82.3743,76.5237 82.3913,76.1677 L82.3443,76.1677 C82.2333,76.3407 82.0823,76.4897 81.8943,76.6147 C81.7053,76.7387 81.4413,76.8007 81.1013,76.8007 C80.6383,76.8007 80.2653,76.6537 79.9833,76.3577 C79.7003,76.0637 79.5593,75.6457 79.5593,75.1067 C79.5593,74.5217 79.7223,74.0627 80.0473,73.7297 C80.3723,73.3967 80.8133,73.2297 81.3683,73.2297 C81.7293,73.2297 82.0563,73.3167 82.3483,73.4897 C82.6413,73.6617 82.8743,73.9257 83.0463,74.2787 C83.2193,74.6327 83.3063,75.0817 83.3063,75.6257" id="Fill-69" fill="#00A0FF" />
						<path d="M86.5334,49.2697 C86.5334,52.0797 84.2554,54.3567 81.4464,54.3567 C78.6364,54.3567 76.3594,52.0797 76.3594,49.2697 C76.3594,46.4597 78.6364,44.1827 81.4464,44.1827 C84.2554,44.1827 86.5334,46.4597 86.5334,49.2697 Z" id="Stroke-71" stroke={stroke} />
						<path d="M83.0527,49.5169 C83.2247,49.7459 83.3107,50.0299 83.3107,50.3699 C83.3107,50.8429 83.1367,51.2179 82.7897,51.4929 C82.4427,51.7679 81.9707,51.9049 81.3737,51.9049 C81.0097,51.9049 80.6577,51.8479 80.3217,51.7349 C79.9837,51.6219 79.7057,51.4679 79.4847,51.2729 L79.8167,50.5479 C80.2897,50.9229 80.7957,51.1099 81.3357,51.1099 C81.6807,51.1099 81.9377,51.0429 82.1077,50.9059 C82.2777,50.7699 82.3617,50.5629 82.3617,50.2849 C82.3617,50.0119 82.2757,49.8099 82.1037,49.6799 C81.9307,49.5479 81.6647,49.4829 81.3047,49.4829 L80.5877,49.4829 L80.5877,48.6959 L81.1737,48.6959 C81.8787,48.6959 82.2307,48.4309 82.2307,47.9019 C82.2307,47.6549 82.1547,47.4639 82.0027,47.3299 C81.8517,47.1969 81.6367,47.1299 81.3587,47.1299 C81.1167,47.1299 80.8717,47.1779 80.6217,47.2729 C80.3727,47.3669 80.1297,47.5079 79.8937,47.6929 L79.5607,46.9679 C79.7767,46.7729 80.0547,46.6179 80.3907,46.5049 C80.7277,46.3919 81.0707,46.3349 81.4207,46.3349 C81.9557,46.3349 82.3827,46.4649 82.7007,46.7239 C83.0207,46.9849 83.1797,47.3349 83.1797,47.7779 C83.1747,48.0819 83.0957,48.3449 82.9447,48.5689 C82.7927,48.7929 82.5827,48.9529 82.3157,49.0509 C82.6347,49.1329 82.8797,49.2889 83.0527,49.5169" id="Fill-73" fill="#00A0FF" />
					</g>
				</g>
			</g>
		</svg>

	);
};

export default AFSphoneChanged;
