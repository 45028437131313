import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";

export const Portal = (props: any) => {
	const el = useRef(document.createElement("div"));
	useEffect(() => {
		const portal = document.getElementById("portal");
		if (portal) {
			portal.appendChild(el.current);
			if (props?.cssText) {
				portal.style.cssText = props.cssText;
			}
		}

		return () => {
			if (portal) {
				portal?.removeChild(el.current);
				portal.style.cssText = "z-index: 203";
			}
		};
	}, [props.children]);

	return ReactDOM.createPortal(props.children, el.current);
};

export const PortalDiv = () => <div id="portal" style={{ zIndex: 203 }} />;
