import { strings } from "../../i18n/config";
import { NoOfReportedOptions } from "../../utils/types";
import { getDateTimestampOfPreviousMonths } from "../../utils/utils";

export const JoinModesOptions = [
	{
		title: strings("CommunitySearchPage.credo_only"),
		value: "c",
	},
	{
		title: strings("CommunitySearchPage.ego_only"),
		value: "e",
	},
	{
		title: strings("CommunitySearchPage.ego_or_credo"),
		value: "ec",
	},
];

export const TimestampsOptions = [
	{
		title: strings("NewCommunityPage.24_hours"),
		value: "24",
	},
	{
		title: strings("NewCommunityPage.48_hours"),
		value: "48",
	},
	{
		title: strings("NewCommunityPage.72_hours"),
		value: "72",
	},
];

export const CommunityReportedOptions = [
	{
		title: strings("CommunitySearchPage.not_reported"),
		value: NoOfReportedOptions.ZERO,
		searchQueries: [
			{
				operator: "OR",
				propName: "nb_rep",
				propVal: null,
				condition: " IS NULL ",
			},
		],
	},
	{
		title: strings("CommunitySearchPage.one_to_ten"),
		value: NoOfReportedOptions.ONE_TO_TEN,
		searchQueries: [
			{
				operator: "OR",
				propName: "nb_rep",
				propVal: "2",
				condition: ">=",
			},
			{
				operator: "OR",
				propName: "nb_rep",
				propVal: "10",
				condition: "<=",
			},
		],
	},
	{
		title: strings("CommunitySearchPage.greater_than_ten"),
		value: NoOfReportedOptions.GREATER_THAN_TEN,
		searchQueries: [
			{
				operator: "OR",
				propName: "nb_rep",
				propVal: "10",
				condition: ">=",
			},
		],
	},
];

export const DiscoveryOptions = [
	{
		title: strings("CommunitySearchPage.public"),
		value: "publ",
	},
	{
		title: strings("CommunitySearchPage.private"),
		value: "priv",
	},
	{
		title: strings("CommunitySearchPage.owner_connections"),
		value: "owco",
	},
	{
		title: strings("CommunitySearchPage.participant_connections"),
		value: "paco",
	},
	{
		title: strings("CommunitySearchPage.connections_of_connections"),
		value: "coco",
	},
];

export const SearchColumns = [
	{
		title: strings("CommunitySearchPage.community_id"),
		value: "streamId",
		type: "number",
	},
	{
		title: strings("CommunitySearchPage.community_name"),
		value: "lc_name",
		type: "string",
	},
	{
		title: strings("CommunitySearchPage.description"),
		value: "lc_descr",
		type: "string",
	},
	{
		title: strings("CommunitySearchPage.community_username"),
		value: "struname",
	},
	{
		title: strings("CommunitySearchPage.category"),
		value: "lc_categ",
		type: "string",
	},
];

export const StreamTableHeaders = [
	{
		title: strings("CommunitySearchPage.community_id"),
		value: "streamId",
		isFixedColumn: true,
		isClickable: true,
		hideColumn: true,
		isDefault: true,
	},
	{
		title: strings("CommunitySearchPage.community_name"),
		value: "name",
		hideColumn: true,
		isDefault: true,
	},
	{
		title: strings("CommunitySearchPage.description"),
		value: "descr",
	},
	{
		title: strings("CommunitySearchPage.community_username"),
		value: "struname",
		isDefault: true,
	},
	{
		title: strings("CommunitySearchPage.joinModes"),
		value: "joinModes",
		isDefault: true,
	},
	{
		title: strings("CommunitySearchPage.cover_picture"),
		value: "coverPicRelUrl",
	},
	{
		title: strings("CommunitySearchPage.profile_picture"),
		value: "profilePicRelUrl",
	},
	{
		title: strings("CommunitySearchPage.mode"),
		value: "src_mode",
	},
	{
		title: strings("CommunitySearchPage.type"),
		value: "type",
	},
	{
		title: strings("CommunitySearchPage.category"),
		value: "categ",
	},
	{
		title: strings("CommunitySearchPage.disc"),
		value: "disc",
	},
	{
		title: strings("CommunitySearchPage.ac_alwpos"),
		value: "ac_alwpos",
	},
	{
		title: strings("CommunitySearchPage.ac_joiaut"),
		value: "ac_joiaut",
	},
	{
		title: strings("CommunitySearchPage.ac_joicom"),
		value: "ac_joicom",
	},
	{
		title: strings("CommunitySearchPage.ac_folcom"),
		value: "ac_folcom",
	},
	{
		title: strings("CommunitySearchPage.ps_memsee"),
		value: "ps_memsee",
	},
	{
		title: strings("CommunitySearchPage.ps_folsee"),
		value: "ps_folsee",
	},
	{
		title: strings("CommunitySearchPage.nb_rep"),
		value: "nb_rep",
		showCustomColumnComponent: true,
	},
	{
		title: strings("CommunityReportedPage.reported_ts"),
		value: "reported_ts",
	},
	{
		title: strings("CommunitySearchPage.creat_ts"),
		value: "creat_ts",
		isDefault: true,
	},
	{
		title: strings("CommunitySearchPage.modif_ts"),
		value: "modif_ts",
		isDefault: true,
	},
];

export const SelectedReportedCommunityTableHeaders = [
	{
		title: strings("CommunitySearchPage.community_id"),
		value: "streamId",
		isFixedColumn: true,
		isClickable: true,
		hideColumn: true,
	},
	{
		title: strings("CommunitySearchPage.community_name"),
		value: "name",
		hideColumn: true,
	},
	{
		title: strings("CommunitySearchPage.description"),
		value: "descr",
	},
	{
		title: strings("CommunitySearchPage.community_username"),
		value: "struname",
	},
	{
		title: strings("CommunitySearchPage.mode"),
		value: "src_mode",
	},
	{
		title: strings("CommunitySearchPage.type"),
		value: "type",
	},
	{
		title: strings("CommunitySearchPage.disc"),
		value: "disc",
	},
	{
		title: strings("CommunitySearchPage.nb_rep"),
		value: "nb_rep",
		showCustomColumnComponent: true,
	},
	{
		title: strings("CommunityReportedPage.reported_ts"),
		value: "reported_ts",
	},
];

export const MemberTableColumns = [{
	title: strings("CommunityDetailsScreen.user_id"),
	value: "user_id",
	isClickable: true,
},
{
	title: strings("CommunityDetailsScreen.firstName"),
	value: "firstName",
},
{
	title: strings("CommunityDetailsScreen.lastName"),
	value: "lastName",
},
{
	title: strings("CommunityDetailsScreen.username"),
	value: "username",
},
{
	title: strings("CommunityDetailsScreen.mode"),
	value: "mode",
},
{
	title: strings("CommunityDetailsScreen.role"),
	value: "role",
}];

export const Timeframes = [
	{
		title: strings("CommunityReportedPage.one_month"),
		value: "1",
		searchQueries: [
			{
				operator: "OR",
				propName: "reported_ts",
				propVal: `${getDateTimestampOfPreviousMonths(1)}`,
				condition: ">=",
			},
		],
	},
	{
		title: strings("CommunityReportedPage.three_months"),
		value: "3",
		searchQueries: [
			{
				operator: "OR",
				propName: "reported_ts",
				propVal: `${getDateTimestampOfPreviousMonths(3)}`,
				condition: ">=",
			},
		],
	},
	{
		title: strings("CommunityReportedPage.six_months"),
		value: "6",
		searchQueries: [
			{
				operator: "OR",
				propName: "reported_ts",
				propVal: `${getDateTimestampOfPreviousMonths(6)}`,
				condition: ">=",
			},
		],
	},
	{
		title: strings("CommunityReportedPage.one_year"),
		value: "12",
		searchQueries: [
			{
				operator: "OR",
				propName: "reported_ts",
				propVal: `${getDateTimestampOfPreviousMonths(12)}`,
				condition: ">=",
			},
		],
	},
];

export const CommunityReportedTableHeaders = [
	{
		title: strings("UserSearchPage.user_id"),
		value: "reported_user_id",
		isClickable: true,
	},
	{
		title: strings("UserSearchPage.firstName"),
		value: "firstName",
	},
	{
		title: strings("UserSearchPage.lastName"),
		value: "lastName",
	},
	{
		title: strings("UserSearchPage.username"),
		value: "username",
	},
	{
		title: strings("StreamReportedList.reported_mode"),
		value: "mode",
	},
	{
		title: strings("StreamReportedList.reportReason"),
		value: "reportReason",
	},
	{
		title: strings("StreamReportedList.reportMessage"),
		value: "reportMessage",
	},
	{
		title: strings("StreamReportedList.reported_timestamp"),
		value: "reported_timestamp",
	},
];
