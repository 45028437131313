/* eslint-disable no-plusplus */
import * as React from "react";
import {
	MouseEventHandler, ReactNode, SVGProps, useCallback, useState,
} from "react";
import styles from "./credo-graph-svg.module.css";
import { classNames } from "../common";
import { AdjustDirection, Rectangle, RectangleAdjuster } from "./RectangleAdjuster";

export interface CredoGraphSVGComponentProps extends SVGProps<SVGSVGElement> {
	/**
	 * unique id of the credograph, mainly will be used for longpress
	 * to useSingleDataScale in credo-graph-svg.
	 * */
	id: string;
	/**
	 * [tag][level] array of 0-1 of how filled in a circle needs to be
	 * */
	ratios: number[][];
	/**
	 * [tag][level][subratio] further subdivision of each ratio
	 * */
	// eslint-disable-next-line react/require-default-props
	subratios?: number[][][] | undefined;
	/**
	 * labels to draw over circles when tapped, null will draw none
	 * */
	// eslint-disable-next-line react/require-default-props
	dataLabels?: string[][] | string[][][] | null;
	/**
	 * labels for series
	 * */
	// eslint-disable-next-line react/require-default-props
	seriesLabels?: string[];
	/* how to space the concentric level circles, null will use default % */
	levelAxisRatios: number[];
	/* what to render where the tags are */
	tags: string[];
	/* picture in the middle possibly of the user */
	avatar: ReactNode;
	/**
	 * adjusts the scale so that the control fit to the min (height, width)
	 * */
	// eslint-disable-next-line react/require-default-props
	scaleToFit?: boolean;
	/**
	 * either provide a handler that returns true to enable default handling,
	 * or true to enable default, or false, to disable default handling
	 * */
	// eslint-disable-next-line react/require-default-props
	onTagLabelPress?: ((tag: string) => boolean) | boolean;
	/**
	 * how high to make the totals panel, otherwise it'll be figured out
	 * */
	// eslint-disable-next-line react/require-default-props
	totalsPanelHeight?: number;
	/**
	 * in icon mode, we only draw circles, no avatar, no labels, etc
	 * */
	// eslint-disable-next-line react/require-default-props
	iconMode?: boolean;
	/**
	 * where to start the axis
	 * */
	// eslint-disable-next-line react/require-default-props
	angleStart?: number;
	/**
	 * Aggregated ratings
	 * */
	// eslint-disable-next-line react/require-default-props
	aggRatings?: string[][][];
	/**
	 * Show circle colors based on aggregated ratings of the
	 * user. This should be used to show influence graph
	 * of the user based on the data passed.
	 * */
	// eslint-disable-next-line react/require-default-props
	aggRatingMode?: boolean;
}

declare type CircleDef = {
	id: string,
	cx: number,
	cy: number,
	r: number,
	ratios: number[] | null,
	aggRating: string[] | null,
	maxR: number,
	label: {
		x: number,
		y: number,
		textRef: React.RefObject<SVGTextElement>,
		rectRef: React.RefObject<SVGRectElement>
	}
}
declare type LabelDef = {
	content: any,
	x: number,
	y: number,
};

const DataLabel = {
	w: 15,
	h: 8,
	r: 3,
	clip: {
		x: 1,
		y: 1,
		height: 7,
		width: 14,
		r: 3,
	},
};
const AxisLabel = {
	w: 70,
	h: 16,
	r: 8,
	clip: {
		x: 1,
		y: 1,
		height: 14,
		width: 68,
		r: 7,
	},
};
const CREDOGRPAHSVG_DEFAULT_SIZE = 360;
const r2dp = (n: number, dp: number = 2) => Math.round(n * 10 ** dp) / 10 ** dp;
const degToXY = (ox: number, oy: number, r: number, angle: number) => ({
	x: ox + r2dp(Math.sin((r * angle) / (180 * Math.PI))),
	y: oy + r2dp(Math.cos((r * angle) / (180 * Math.PI))),
});

const CredoGraphSVG = ({
	width = CREDOGRPAHSVG_DEFAULT_SIZE,
	height = CREDOGRPAHSVG_DEFAULT_SIZE,
	ratios,
	subratios = undefined,
	levelAxisRatios = [0.15, 0.25, 0.6],
	dataLabels = null,
	seriesLabels = undefined,
	tags,
	avatar,
	scaleToFit = true,
	onTagLabelPress = true,
	totalsPanelHeight = undefined,
	angleStart = 0,
	iconMode = false,
	children,
	aggRatings,
	aggRatingMode,
	id,
}: CredoGraphSVGComponentProps) => {
	const overlapAvoider = new RectangleAdjuster(
		Rectangle.fromBounds(0, 0, CREDOGRPAHSVG_DEFAULT_SIZE, CREDOGRPAHSVG_DEFAULT_SIZE),
		1, AdjustDirection.Y,
	);
	const noOfAxis = Math.min(8, ratios?.length);
	const circles = new Array<Array<CircleDef>>(noOfAxis);
	const axisLabels = new Array<LabelDef>(noOfAxis);
	const center = { x: 150, y: 150 };
	const angleStep = 360 / noOfAxis;
	let angle = angleStart;

	const avatarAreaR = iconMode ? 20 : 25;
	const plotAreaR = 150 - avatarAreaR;

	const noOfLevels = levelAxisRatios.length;
	// max radiuses of data circles at DoS level, computed by taking the available plot area and
	// multiplying it by the axis ratios (ie how much % taken up by each axis)
	const dataCircleAtDosRadii = levelAxisRatios.map((r) => ((plotAreaR * r) / 2));

	let aggRad = 0; // aggregate radius at dos
	// Degree of Sepration Orbit Radiuses (the grid)
	const dosAxisRadii = dataCircleAtDosRadii.map((r) => {
		const res = avatarAreaR + r + aggRad;
		aggRad += r * 2;
		return res;
	});
	// Degree of Separation Axis Labels
	const dosAxisLabels = dosAxisRadii.map((r, dos) => ({
		...degToXY(center.x, center.y, r, 360 / angleStep),
		r,
		startOffset: 2 * Math.PI * (r) * ((angleStep / 2 + 85) / 360),
		content: `${dos}°`, // IMRPROVE make configurable
	}));

	// prepare the coordinates and sizes of the circles:
	// 1. radii by multiplying the ratios by the appropriate radius
	// 2. coordinates of circles (both placeholders and data)
	for (let axis = 0; axis < noOfAxis; axis++) {
		circles[axis] = new Array<CircleDef>(noOfLevels);

		const axisLabelAnchor = {
			x: 0,
			y: 0,
			width: 67,
			height: 15,
		};
		for (let level = 0; level < noOfLevels; level++) {
			let dataR = 0; // data radius
			let maxR = 0; // max radius
			let maxA = 0; // max area - this is how we scale data
			const levelAxisRadius = dosAxisRadii[level];

			if (ratios && ratios.length > axis && ratios[axis]?.length > level) {
				// limit the maxR to the maximum radius that allows max # of circles without overlapping
				maxR = Math.min(dataCircleAtDosRadii[level], levelAxisRadius * Math.sin(Math.PI / ratios[axis].length));
				// d console.log(`maxR: ${maxR}, levelAxisRadius:${levelAxisRadius} * ${Math.sin(Math.PI / ratios[axis].length)}`);
				maxA = Math.PI * (maxR ** 2);
				// work out the radius from the area given ratio
				dataR = Math.sqrt((ratios[axis][level] * maxA) / Math.PI);
				// d console.log(`dataR at [${axis}][${level}]= Math.sqrt((ratios[axis][level]{${ratios[axis][level]} * maxA{${maxA}) / Math.PI) = ${dataR}`);
			}

			const x = Math.round((center.x + levelAxisRadius * Math.sin((Math.PI * angle) / 180)) * 100) / 100;
			const y = Math.round((center.y + -levelAxisRadius * Math.cos((Math.PI * angle) / 180)) * 100) / 100;
			// d console.log(`[${axis},${level}] : cx=${x}, cy=${y}`);

			// eslint-disable-next-line no-nested-ternary
			const labelOffSetAngle = (angle === 0 || angle === 180)
				// eslint-disable-next-line no-nested-ternary
				? 45 : (angle === 90 || angle === 270)
					? 0 : (angle > 180) ? angle : 360 - angle;

			circles[axis][level] = {
				id: `${axis}-${level}`,
				r: dataR,
				maxR,
				ratios: subratios ? subratios[axis][level] : null,
				cx: x,
				cy: y,
				aggRating: aggRatings && aggRatingMode ? aggRatings[axis][level] : null,
				label: {
					x: (labelOffSetAngle > 180 ? -DataLabel.w : 0)
						+ (Math.round(((dataR) * Math.sin((Math.PI * labelOffSetAngle) / 180)) * 100) / 100),
					y: Math.round(((-dataR) * Math.cos((Math.PI * labelOffSetAngle) / 180)) * 100) / 100,
					textRef: React.createRef(),
					rectRef: React.createRef(),
				},
			};

			// update the axis label anchor
			if (maxR > 0) {
				if (angle === 0 || angle === 180) {
					axisLabelAnchor.x = x - axisLabelAnchor.width / 2;
				} else if (angle < 180) {
					axisLabelAnchor.x = x;
				} else {
					axisLabelAnchor.x = x - axisLabelAnchor.width;
				}
				if ((angle >= 0 && angle <= 90) || (angle >= 270 && angle < 360)) {
					axisLabelAnchor.y = y - maxR - axisLabelAnchor.height;
				} else {
					axisLabelAnchor.y = y + maxR;
				}
				// adjust for positioning off viewport
				axisLabelAnchor.x = Math.min(
					Math.max(-30 + AxisLabel.w * 0.05, axisLabelAnchor.x),
					CREDOGRPAHSVG_DEFAULT_SIZE - 30 - AxisLabel.w * 1.05,
				);
				axisLabelAnchor.y = Math.min(
					Math.max(-30 + AxisLabel.h * 0.1, axisLabelAnchor.y),
					CREDOGRPAHSVG_DEFAULT_SIZE - 30 - AxisLabel.h * 1.1,
				);
			}
		}// end of level loop

		axisLabels[axis] = {
			x: axisLabelAnchor.x,
			y: axisLabelAnchor.y,
			content: tags[axis],
		};
		// add this so the data labels etc can avoid it
		overlapAvoider.add(new Rectangle(
			axisLabelAnchor.x,
			axisLabelAnchor.y,
			AxisLabel.w,
			AxisLabel.h,
		));

		angle += angleStep;
	}

	const renderContainerCircles = () => circles.map((axisC) => axisC.map((c) => (
		<circle id={`container-${c.id}`} key={c.id} className={styles.container} cx={c.cx} cy={c.cy} r={c.maxR} />
	)));

	const aniStep = 100;
	let baseDelay = 0;
	const renderDataLabels = () => circles.map((axisC, axis) => axisC.map((c, dos) => {
		if (!dataLabels) return null;

		// we only take label at series 0 for now
		const dataLabel = dataLabels[axis].length > dos ? dataLabels[axis][dos][0] : "";

		if (dataLabel) {
			const ovalRect = new Rectangle(
				c.cx + c.label.x,
				c.cy + c.label.y,
				DataLabel.w,
				DataLabel.h,
			);

			return (
				<g
					key={`data-label-${axis}-${dos}`}
					className={classNames(styles.dataLabelGroup, dataLabelToggles[axis] ? styles.visible : "")}
				>
					<rect
						ref={circles[axis][dos].label.rectRef}
						className={styles.dataLabel}
						id={`label-${c.id}`}
						// x={c.cx + c.label.x}
						/* eslint-disable-next-line react/jsx-props-no-spreading */
						{...ovalRect}
						rx={DataLabel.r}
					/>
					<text
						ref={circles[axis][dos].label.textRef}
						className={styles.dataLabelText}
						id={`label-text-${c.id}`}
						x={ovalRect.x}
						y={ovalRect.y}
						alignmentBaseline="hanging"
					>
						{dataLabel}
					</text>
				</g>
			);
		} else {
			return null;
		}
	}));

	// this is needed to update the rectangles behind the data labels. i know. svg is great until it comes to basic sh.t
	// but we also need to un-overlap things
	const updateDataLabelRects = useCallback(() => {
		circles.forEach((c) => c.forEach((cal) => {
			if (cal.label.textRef.current != null) {
				if (cal.label.rectRef.current != null) {
					const rect = cal.label.textRef.current.getBBox();
					const labelTextRect = overlapAvoider.adjustRectangleById(`${cal.id}`, {
						x: rect.x,
						y: rect.y + 1.5,
						w: rect.width,
						h: rect.height,
					});
					// cal.label.textRef.current.setAttribute("width", `${labelTextRect.width}px`);
					// cal.label.textRef.current.setAttribute("height", `${labelTextRect.height}px`);
					cal.label.textRef.current.setAttribute("y", `${labelTextRect.y}px`);
					cal.label.textRef.current.setAttribute("x", `${labelTextRect.x}px`);

					cal.label.rectRef.current.setAttribute("width", `${labelTextRect.width + DataLabel.clip.height}px`);
					cal.label.rectRef.current.setAttribute("height", `${labelTextRect.height + DataLabel.clip.y}px`);
					cal.label.rectRef.current.setAttribute("y", `${labelTextRect.y - DataLabel.clip.y}px`);
					cal.label.rectRef.current.setAttribute("x", `${labelTextRect.x - DataLabel.clip.height / 2}`);

					// d console.log("updating rect", cal.label.rectRef.current, `${cal.label.textRef.current.clientWidth}px`);
				}
			}
		}));
	}, [circles]);

	if (!iconMode) {
		React.useEffect(() => {
			updateDataLabelRects();

			const summaryTotalsPanelAutoCollapseTimer = setTimeout(() => setExpandSummaryTotals(false), 3000);

			return () => clearTimeout(summaryTotalsPanelAutoCollapseTimer);
		});
	}

	const renderDataCircles = () => circles.map((axisC, axis) => axisC.map((c, levelIx) => {
		baseDelay += Math.floor(aniStep * (Math.max(c.r, 0.0001) / Math.max(0.0001, c.maxR)));
		const levelDelay = axis * levelIx * 100;
		if (c.ratios) {
			const minAlpha = 0.5;
			const maxAlpha = 0.9;
			const alphaStep = (maxAlpha - minAlpha) / c.ratios.length;
			let cRatio = 1;
			let cOpacity = minAlpha;
			// d console.log("subratios", c.ratios);
			const ratioCount = c.ratios.length;
			let cRatioUsed = 0;
			return (
				<g
					className={styles.dataGroup}
					key={`data-${c.id}`}
					style={{ animationDelay: `${baseDelay + levelDelay}ms` }}
				>
					{/* render sub-ratio circles */
						c.ratios.map((ratio, seriesIx) => {
							// d console.log("circle at ", `axis:${axis},level:${levelIx},cRatio:${cRatio},cRatioUsed:${cRatioUsed}`, c);
							cRatio = Math.max(0, cRatio - cRatioUsed);
							cRatioUsed = ratio;
							cOpacity += alphaStep;
							return (
								<circle
									id={`data-${c.id}-${seriesIx}-${levelIx}`}
									key={`data-${c.id}-${seriesIx}-${levelIx}`}
									className={c.aggRating?.[seriesIx] ? undefined : styles.dataCircle}
									style={{
										opacity: cOpacity,
									}}
									fill={c.aggRating?.[seriesIx]}
									cx={c.cx}
									cy={c.cy}
									r={c.r * cRatio}
								>
									{dataLabels && dataLabels[axis][levelIx].length > seriesIx
									&& dataLabels[axis][levelIx][seriesIx].length > levelIx
										? <title>{dataLabels[axis][levelIx][seriesIx]}</title>
										: null}
								</circle>
							);
						})
					}

					)
				</g>
			);
		} else {
			return (
				<g
					className={styles.dataGroup}
					key={`data-${c.id}`}
					style={{ animationDelay: `${baseDelay + levelDelay}ms` }}
				>
					<circle
						id={`data-${c.id}`}
						key={c.id}
						className={styles.dataCircle}
						cx={c.cx}
						cy={c.cy}
						r={c.r}
					/>
				</g>
			);
		}
	}));

	const [expandSummaryTotals, setExpandSummaryTotals] = useState<boolean>(true);

	const handleMouseDown = useCallback(() => {
		setExpandSummaryTotals(!expandSummaryTotals);
	}, [expandSummaryTotals]);

	// @ts-ignore
	const renderSummaryTotalsPanel = () => {
		const tph = totalsPanelHeight === undefined ? (seriesLabels ? seriesLabels.length : 1) * 12 : totalsPanelHeight;
		return (seriesLabels === undefined || seriesLabels?.length === 0 || !children ? null
			: (
				<g
					key="g-stp"
					onMouseDown={handleMouseDown}
					className="cursor-pointer"
					id="content-totals-panel"
					transform="translate(245,7)"
				>
					<rect
						width="100"
						height="50"
						style={{ "--min-height": "20px", "--target-height": `${8 + tph}px` } as React.CSSProperties}
						className={classNames(styles.summaryTotalsContainer, expandSummaryTotals ? styles.temporaryShow : null)}
						rx={8}
					/>
					<foreignObject
						width={90}
						height={40}
						x={4}
						y={4}
						style={{
							"--min-height": "12px",
							"--target-height": `${tph}px`,
						} as React.CSSProperties}
						className={classNames(styles.summaryTotalsContainer, expandSummaryTotals ? styles.temporaryShow : "")}
					>
						{children}
					</foreignObject>

				</g>
			)
		);
	};

	const onLabelMouseDown = (axis: number): MouseEventHandler => (evt) => {
		evt.stopPropagation();
		toggleLabelForAxis(axis);
	};
	const [dataLabelToggles, setDataLabelToggles] = useState(new Array<boolean>(noOfAxis));
	const toggleLabelForAxis = (axis: number) => {
		if (onTagLabelPress === false) {
			// d console.log("In non-interactive mode for axis", axis);
			return;
		}
		if (onTagLabelPress === true || onTagLabelPress(tags[axis])) {
			const newdata = [...dataLabelToggles];
			newdata[axis] = !(newdata[axis] === true);
			setDataLabelToggles(newdata);
			// d console.log(`toggled for axis:${axis} to ${newdata[axis]}`, dataLabelToggles);
		}
	};
	const renderTagAxisLabels = () => axisLabels.map((axisLabel, index) => (
		<g
			className={styles.labelGroup}
			width={AxisLabel.w + 2}
			height={AxisLabel.h + 2}
			id={`Tag-${index}`}
			key={`tag${index}`}
			transform={`translate(${axisLabel.x}, ${axisLabel.y})`}
		>
			<rect
				className={styles.label}
				x={0}
				y={0}
				width={AxisLabel.w}
				height={AxisLabel.h}
				rx={AxisLabel.r}
				onMouseDown={onLabelMouseDown(index)}
			/>
			<text
				className={styles.labelText}
				x={AxisLabel.h / 2 - 2}
				y={AxisLabel.h * 0.55}
				width={AxisLabel.w}
				onMouseDown={onLabelMouseDown(index)}
			>
				{axisLabel.content}
			</text>
		</g>
	));

	return (
		<svg
			className={styles.credoGraph}
			width={width}
			height={height}
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			viewBox={`0 0 ${CREDOGRPAHSVG_DEFAULT_SIZE} ${CREDOGRPAHSVG_DEFAULT_SIZE}`}
			preserveAspectRatio="xMidYMid meet"
			id={id}
			/* eslint-disable-next-line react/jsx-props-no-spreading */
		>
			<defs>
				<ellipse id="path-1" cx="19.1253644" cy="20" rx="19.1253644" ry="20" />
				<clipPath id="tagClip">
					{/* eslint-disable-next-line react/jsx-props-no-spreading */}
					<rect {...AxisLabel.clip} />
				</clipPath>
				<clipPath id="summaryTotalsContainerClip">
					<rect
						height={60}
						width={90}
						style={{
							"--min-height": "12px", "--target-height": `${(seriesLabels ? seriesLabels.length : 1) * 12}px`,
						} as React.CSSProperties}
						className={classNames(styles.summaryTotalsContainerClip, expandSummaryTotals ? styles.temporaryShow : null)}
					/>
				</clipPath>
				{!iconMode && dosAxisLabels.map((l, dos) => (
					<path /* this is path for the use with the axis orbits text path */
						key={`${id}-dos-axis-${dos}`}
						id={`${id}-dos-axis-${dos}`}
						d={`M ${center.x - l.r} ${center.y} a ${l.r},${l.r} 0 1,1 ${l.r * 2},0 a ${l.r},${l.r} 0 1,1 -${l.r * 2},0`}
					/>
				))}
			</defs>
			<g id="credo-graph" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<rect id="Container" className={styles.back} width="100%" height="100%" rx={iconMode ? "100%" : "10%"} />
				{!iconMode && (
					<g id="Group" transform="translate(160, 160)">
						<mask id="mask-2" fill="white">
							<use xlinkHref="#path-1" />
						</mask>
						<foreignObject width={40} height={40} className={styles.avatar}>{avatar}</foreignObject>
					</g>
				)}
				<g id="Plot" transform="translate(30, 30)">
					{!iconMode && (
						<g id="Grid" className={styles.plotGrid}>
							{dosAxisRadii.map((r, dos) => (
								<g key={`g-grid-axis-${dos}`}>
									<circle id={`grid-axis-${dos}`} key={`grid-axis-${dos}`} cx={center.x} cy={center.y} r={r} />
									<text className={styles.plotGridLabel}>
										<textPath
											className={styles.plotGridLabel}
											xlinkHref={`#${id}-dos-axis-${dos}`}
											startOffset={dosAxisLabels[dos].startOffset}
										>
											<animate
												attributeName="startOffset"
												from="0%"
												to="100%"
												begin="0s"
												dur="60s"
												repeatCount="indefinite"
											/>
											{dosAxisLabels[dos].content}
										</textPath>
									</text>
								</g>
							))}
						</g>
					)}
					<g id="Containers" className={styles.containerGroup}>
						{renderContainerCircles()}
					</g>
					<g id="Data" className={styles.dataPlot}>
						{renderDataCircles()}
					</g>
					{!iconMode && (
						<g id="Data Labels" className={styles.dataLabels}>
							{renderDataLabels()}
						</g>
					)}
					{!iconMode && (
						<g id="Tags">
							{renderTagAxisLabels()}
						</g>
					)}

				</g>
				{!iconMode && renderSummaryTotalsPanel()}
			</g>
		</svg>

	);
};

export default CredoGraphSVG;
