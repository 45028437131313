// eslint-disable-next-line import/prefer-default-export
import React, { useEffect } from "react";
import {
	Navigate, Route, Routes, useLocation,
} from "react-router-dom";
import { useAtom } from "jotai";
import { toast, ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import Auth from "./Auth";
import SideBar from "../gen-comp/SideBar";
import Header from "../gen-comp/Header";
import { getAllowedRoutes, isLoggedIn } from "../utils";
import PrivateRoutes from "./PrivateRoutes";
import { PrivateRoutesConfig } from "../config";
import { userRolesAtom } from "../utils/atoms";
import toggleColorTheme from "../utils/toggleTheme";
import { ThemeOptions } from "../utils/types";
import PageNotFound from "../gen-comp/PageNotFound";
import TwoFactorAuthQRCodeScreen from "../modules/login/TwoFactorAuthQRCodeScreen";
import TwoFactorAuthCodeScreen from "../modules/login/TwoFactorAuthCodeScreen";
import {
	AUTH_2FA_SCREEN, QR_CODE_2FA_SCREEN, AUTH_URL, PAGE_404_URL,
} from "./constants";
import LoginScreen from "../modules/login/LoginScreen";
import { checkInvalidSession } from "../utils/utils";
import "react-toastify/dist/ReactToastify.css";
import classNames from "../utils/classNames";
import useDimensions from "../hooks/useDimensions";
import CfgMgr from "../config/CfgMgr";

function RoutesMain() {
	const allowedRoutes = getAllowedRoutes(PrivateRoutesConfig);
	const [userRoles, setUserRoles] = useAtom(userRolesAtom);
	const location = useLocation();
	const [height] = useDimensions();

	useEffect(() => {
		setUserRoles((localStorage.getItem("roles")) as any);
		toggleColorTheme(ThemeOptions.LIGHT);
		if (isLoggedIn()) {
			checkInvalidSession();
		}
	}, []);

	return (
		<div className={classNames(`flex flex-col sm:h-screen xs:h-[${height}px] h-[${height}px]`)}>
			<Helmet>
				<link
					rel="alternate icon"
					href={`/favicons/${CfgMgr.FAVICON}`}
					id={Math.random().toString()}
					key="favicon"
				/>
			</Helmet>
			{location.pathname !== "/404" && <Header />}
			<div className="flex flex-row bg-neutral-100 content-height-with-navbar">
				{userRoles && location.pathname !== "/404" && <SideBar />}
				<div id="main-content" className="flex w-full flex-col max-h-full overflow-auto">
					<Routes>
						<Route path={AUTH_URL} element={<PrivateRoutes />}>
							{allowedRoutes?.map((routeDetails) => {
								const {
									path, component: Component, children, ...rest
								} = routeDetails;
								return (
									<Route {...rest} key={path} path={`${AUTH_URL}${path}`} element={<Component />}>
										{children?.map((details: any) => (
											<Route
												{...details}
												key={details.path}
												path={`${AUTH_URL}${details.path}`}
												element={<details.component />}
											/>
										))}
									</Route>
								);
							})}
						</Route>
						<Route path="/" element={<Auth />}>
							<Route path="/" element={<LoginScreen />} />
							<Route path={QR_CODE_2FA_SCREEN} element={<TwoFactorAuthQRCodeScreen />} />
							<Route path={AUTH_2FA_SCREEN} element={<TwoFactorAuthCodeScreen />} />
						</Route>
						<Route path="*" element={<Navigate to={PAGE_404_URL} />} />
						<Route path={PAGE_404_URL} element={<PageNotFound />} />
					</Routes>
				</div>
			</div>
			<ToastContainer
				position={toast.POSITION.BOTTOM_LEFT}
				autoClose={4000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable
				pauseOnHover
				toastClassName={() => "bg-white relative flex p-1 min-h-10 m-5 rounded-md justify-between cursor-pointer"}
				bodyClassName={() => "text-sm block p-3"}
			/>
		</div>
	);
}

export default RoutesMain;
