import React, { ReactNode } from "react";
import { AuthorisationCheckOutcome } from "./AuthorisationEngine";
import { AppManager, Manager } from "../utils";

export interface Responder<ResponseT> {
	respond(): ResponseT | null;
}

export enum ResponseTarget {
	AppModal,
	ComponentModal
}

export interface AuthorisationFailResponderProps<ResponderT> {
	responders: { targets: ResponseTarget[], permissionIds: string[], responder: ResponderT }[];
}

/**
 * This manages responders to the authorisation failures, for different targets
 *
 */
export class AuthorisationFailResponderManager<ResponseT, ResponderT extends Responder<ResponseT>>
	extends Manager<AuthorisationFailResponderProps<ResponderT>> {

	/**
	 * Returns the managed singleton
	 *
	 * It's also possible to instantiate directly, see constructor, which maybe useful for testing
	 */
	static instance(): AuthorisationFailResponderManager<any, any> {
		return AppManager.instance().get(AuthorisationFailResponderManager.name) as unknown as AuthorisationFailResponderManager<any, any>;
	}

	// it's basically an index by Type of Target and then Map of Actions to Responders
	// eslint-disable-next-line no-array-constructor
	private responders = new Array<Map<string /* permission id */, Array<ResponderT>>>();

	/**
	 * Generally should not be used direclty, but through the static .instance() method
	 * But useful for unit testing
	 */
	constructor() {
		super();
		// eslint-disable-next-line guard-for-in,no-restricted-syntax
		for (const responseTargetKey in ResponseTarget) {
			this.responders.push(new Map<string, Array<ResponderT>>());
		}
	}

	/**
	 * Knows hot to bulk-register responders
	 *
	 * @param props
	 */
	setProps(props: AuthorisationFailResponderProps<ResponderT>) {
		super.setProps(props);
		if (props.responders) {
			props.responders.map((args) => {
				this.register(args.targets, args.permissionIds, args.responder);
			});
		}
	}

	/**
	 * Registers a responder
	 * @param targets
	 * @param permissionIds
	 * @param responder
	 */
	register(targets: ResponseTarget[], permissionIds: string[], responder: ResponderT): AuthorisationFailResponderManager<ResponseT, ResponderT> {
		// eslint-disable-next-line guard-for-in,no-restricted-syntax
		for (const ti in targets) {
			const lookupMap = this.responders[targets[ti]];
			// eslint-disable-next-line guard-for-in,no-restricted-syntax
			for (const j in permissionIds) {
				let arr = lookupMap.get(permissionIds[j]);
				// eslint-disable-next-line no-array-constructor
				arr = !arr ? new Array<ResponderT>() : arr;
				arr.push(responder);
				lookupMap.set(permissionIds[j], arr);
			}
		}
		return this;
	}

	/**
	 * Unregisters a responder
	 *
	 * @param targets
	 * @param permissionIds
	 * @param responder
	 */
	unregister(targets: ResponseTarget[], permissionIds: string[], responder: ResponderT): AuthorisationFailResponderManager<ResponseT, ResponderT> {
		// eslint-disable-next-line guard-for-in,no-restricted-syntax
		for (const ti in targets) {
			const lookupMap = this.responders[targets[ti]];
			// eslint-disable-next-line guard-for-in,no-restricted-syntax
			for (const j in permissionIds) {
				let arr = lookupMap.get(permissionIds[j]);
				if (arr) {
					arr = arr.filter((r) => r === responder);
					lookupMap.set(permissionIds[j], arr);
				}
			}
		}
		return this;
	}

	/**
	 * Responds
	 * @param t - target, of ResponseTarget enum
	 * @param o - outcome to which to respond
	 */
	respond(t: ResponseTarget, o: AuthorisationCheckOutcome): ResponseT[] {
		// eslint-disable-next-line no-array-constructor
		const res = new Array<ResponseT>();
		const lookupMap = this.responders[t];

		if (!lookupMap) throw new Error(`Unknown response target:${t}`);

		o.onBlocked((p) => {
			const responders = lookupMap.get(p.id);

			if (!responders) return;

			// eslint-disable-next-line no-plusplus
			for (let ri = 0; ri < responders.length; ri++) {
				const rres = responders[ri].respond();
				if (rres) {
					res.push(rres);
					return; // we only need 1 response
				}
			}
		});

		o.onFailed((p) => {
			const responders = lookupMap.get(p.id);

			if (!responders) return true;

			// eslint-disable-next-line no-plusplus
			for (let ri = 0; ri < responders.length; ri++) {
				const rres = responders[ri].respond();
				if (rres) {
					res.push(rres);
					return false; // we only need 1 response
				}

			}
			return true;
		});

		return res;
	}
}

/**
 * A responder that rerturns a react node to render
 */
export class RenderResponder implements Responder<ReactNode> {
	constructor(respond: () => ReactNode) {
		this.respond = respond.bind(this);
	}

	respond(): React.ReactNode | null {
		return null;
	}
}
