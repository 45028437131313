import React, { useMemo } from "react";
import { useMeasure, useWindowSize } from "@credo/utilities";
import { classNames } from "../common";
import { ModalPopup, ModalState } from "../credo-tag";
import { ModalPosition } from "./types";

interface GlassModalProps {
	/**
	 * Content which needs to be displayed in the modal.
	 * Make sure the component is memoized.
	 * */
	children: any;
	/**
	 * Modal content's styles in class names so that we can use
	 * tailwind directly.
	 * */
	glassContentClass?: string;
	/**
	 * Modal content's styles in raw object form.
	 * */
	glassContentStyle?: {
		[key: string]: string | number;
	};
	/**
	 * If we want to render the modal on a certain position
	 * we can pass it here and modal will be rendered directly
	 * in that position.
	 * */
	modalPosition?: ModalPosition;
	/**
	 * Closes the modal
	 * */
	closeModal: () => void;
}

// eslint-disable-next-line import/prefer-default-export
export const GlassModal: React.FC<GlassModalProps> = (props: GlassModalProps) => {
	const {
		children,
		glassContentClass,
		modalPosition,
		glassContentStyle,
		closeModal,
	} = props;

	const { width, height } = useWindowSize();
	const [contentRef, contentDimensions] = useMeasure<HTMLDivElement>();

	const graphPositionMemoized = useMemo(() => {
		if (modalPosition) {
			return modalPosition;
		}

		/**
		 * If modalPosition is not passed the modal must be rendered
		 * in the center of the screen.
		 * */
		if (contentDimensions) {
			if (width < (contentDimensions.width + 32)) {
				return {
					left: 16,
					top: (height - (width + 50)) / 2,
				};
			}

			return {
				left: (width - contentDimensions?.width) / 2,
				top: (height - (contentDimensions?.width + 50)) / 2,
			};
		}

		return {
			left: width / 2,
			top: height / 2,
		};
	}, [modalPosition, width, contentDimensions]);

	return (
		<React.Fragment>
			<div
				className={classNames(
					"fixed inset-0 bg-black bg-opacity-25 opacity-100 z-[203]",
					/**
					 * Added fadeIn effect because first time when modal renders it
					 * does not have any dimensions, so it gives component to calculate
					 * the center of the screen
					 * */
					" visible-fade-in-animation",
				)}
			/>
			<ModalPopup
				style={{
					...graphPositionMemoized,
					position: "fixed",
					zIndex: 203,	// greater than app header and bottom nav menu
				}}
				onModalStateChange={(state) => {
					if (state === ModalState.Closing) closeModal();
				}}
			>
				<div
					ref={contentRef}
					className={classNames(
						"border border-turquoise-400 rounded-3xl overflow-hidden",
						"credo-graph-bg-glass-effect",
						/**
						 * Added fadeIn effect because first time when modal renders it
						 * does not have any dimensions, so it gives component to calculate
						 * the center of the screen
						 * */
						"visible-fade-in-animation",
						glassContentClass,
					)}
					style={glassContentStyle}
				>
					{children}
				</div>
			</ModalPopup>
		</React.Fragment>
	);
};

GlassModal.defaultProps = {
	glassContentClass: "",
	glassContentStyle: {},
	modalPosition: undefined,
};
