import { HintAlignment } from "./types";
import styles from "./hint.module.css";

// eslint-disable-next-line import/prefer-default-export
export class HintStyler {
	baseClass: string | null = null;

	constructor(basClass: string) {
		this.baseClass = basClass;
	}

	getAlignmentClass = (alignment: HintAlignment) => {
		switch (alignment) {
			case HintAlignment.TOP_RIGHT:
				return `${this.baseClass} ${styles.topRight}`;
			case HintAlignment.BOTTOM_LEFT:
				return `${this.baseClass} ${styles.bottomLeft}`;
			case HintAlignment.BOTTOM_RIGHT:
				return `${this.baseClass} ${styles.bottomRight}`;
			case HintAlignment.TOP_LEFT:
			default:
				return `${this.baseClass} ${styles.topLeft}`;
		}
	}
}
