import { DropdownItem } from "../../utils/types";
import { UserModesOptions, UserReportedOptions } from "./constants";

export const getUserRepStatement = (reportedOptions: DropdownItem[], userMode: DropdownItem[]): DropdownItem[] => {
	if (userMode.length > 0 && userMode.length < UserModesOptions.length) {
		return reportedOptions.map((option) => ({
			...option,
			searchQueries: option.searchQueries?.map((query) => ({
				...query,
				propName: `${userMode[0]?.value}_nb_rep`,
			})),
		}));
	}

	if (
		userMode.length === UserModesOptions.length
		&& reportedOptions.length < UserReportedOptions.length
		&& reportedOptions.length > 0
	) {
		return reportedOptions.map((option) => ({
			...option,
			searchQueries: option.searchQueries ? [option.searchQueries?.map((query) => ({
				...query,
				propName: `${userMode[0]?.value}_nb_rep`,
			})), option.searchQueries?.map((query) => ({
				...query,
				propName: `${userMode[1]?.value}_nb_rep`,
			}))].flat() : [],
		}));
	}

	return [];
};

export const getReportedTimeframeStatement = (timeframeFilter: DropdownItem[], userMode: DropdownItem[]): DropdownItem[] => {
	if (userMode.length > 0 && userMode.length < UserModesOptions.length) {
		return timeframeFilter.map((option) => ({
			...option,
			searchQueries: option.searchQueries?.map((query) => ({
				...query,
				propName: `${userMode[0]?.value}_reported_ts`,
			})),
		}));
	}

	if (
		userMode.length === UserModesOptions.length
		&& timeframeFilter.length < UserReportedOptions.length
		&& timeframeFilter.length > 0
	) {
		return timeframeFilter.map((option) => ({
			...option,
			searchQueries: option.searchQueries ? [option.searchQueries?.map((query) => ({
				...query,
				propName: `${userMode[0]?.value}_reported_ts`,
			})), option.searchQueries?.map((query) => ({
				...query,
				propName: `${userMode[1]?.value}_reported_ts`,
			}))].flat() : [],
		}));
	}

	return [];
};
