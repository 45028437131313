import React, { SVGAttributes } from "react";

// eslint-disable-next-line import/prefer-default-export
const StreamJoinIntMode = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--gray-dark)",
	} = props;
	return (
		<svg
			width="22px"
			height="22px"
			viewBox="0 0 22 22"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<g id="Streams---CT---190723" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-icons" transform="translate(-374.000000, -619.000000)" stroke={stroke}>
					<g id="streamJoinIntMode" transform="translate(375.000000, 620.000000)">
						<path d="M7.61904762,19.5238095 L7.61904762,11.2228571 C7.61904762,9.90761905 8.6847619,8.84190476 10,8.84190476 C11.3152381,8.84190476 12.3809524,9.90761905 12.3809524,11.2228571 L12.3809524,19.5238095" id="Stroke-1" strokeLinecap="round" />
						<path d="M10.952381,1.42857143 C10.952381,1.95428571 10.5257143,2.38095238 10,2.38095238 C9.47428571,2.38095238 9.04761905,1.95428571 9.04761905,1.42857143 C9.04761905,0.902857143 9.47428571,0.476190476 10,0.476190476 C10.5257143,0.476190476 10.952381,0.902857143 10.952381,1.42857143 Z" id="Stroke-3" />
						<path d="M6.19019048,3.99761905 C6.19019048,4.52333333 5.76352381,4.95 5.23780952,4.95 C4.71209524,4.95 4.28542857,4.52333333 4.28542857,3.99761905 C4.28542857,3.47190476 4.71209524,3.0452381 5.23780952,3.0452381 C5.76352381,3.0452381 6.19019048,3.47190476 6.19019048,3.99761905 Z" id="Stroke-5" />
						<path d="M15.7145714,3.99761905 C15.7145714,4.52333333 15.2879048,4.95 14.7621905,4.95 C14.2364762,4.95 13.8098095,4.52333333 13.8098095,3.99761905 C13.8098095,3.47190476 14.2364762,3.0452381 14.7621905,3.0452381 C15.2879048,3.0452381 15.7145714,3.47190476 15.7145714,3.99761905 Z" id="Stroke-7" />
						<path d="M2.38095238,9.64628571 C2.38095238,10.172 1.95428571,10.5986667 1.42857143,10.5986667 C0.902857143,10.5986667 0.476190476,10.172 0.476190476,9.64628571 C0.476190476,9.12057143 0.902857143,8.69390476 1.42857143,8.69390476 C1.95428571,8.69390476 2.38095238,9.12057143 2.38095238,9.64628571 Z" id="Stroke-9" />
						<path d="M19.5238095,9.64628571 C19.5238095,10.172 19.0971429,10.5986667 18.5714286,10.5986667 C18.0457143,10.5986667 17.6190476,10.172 17.6190476,9.64628571 C17.6190476,9.12057143 18.0457143,8.69390476 18.5714286,8.69390476 C19.0971429,8.69390476 19.5238095,9.12057143 19.5238095,9.64628571 Z" id="Stroke-11" />
						<path d="M7.534,14.1967619 C6.63209524,13.4739048 6.05495238,12.3624762 6.05495238,11.1167619 C6.05495238,8.93771429 7.82066667,7.17104762 9.99971429,7.17104762 C12.1787619,7.17104762 13.9454286,8.93771429 13.9454286,11.1167619 C13.9454286,12.359619 13.3711429,13.4681905 12.4730476,14.1910476" id="Stroke-13" />
						<path d="M7.61904762,12.238381 C7.61904762,12.238381 8.49809524,13.4821905 10,13.4821905 C11.5019048,13.4821905 12.3809524,12.238381 12.3809524,12.238381" id="Stroke-15" />
						<polyline id="Stroke-17" points="5.23780952 4.95 5.23780952 6.48333333 7.4387619 8.11952381" />
						<line x1="2.38095238" y1="9.64628571" x2="6.34" y2="9.64628571" id="Stroke-19" strokeLinecap="round" strokeDasharray="1,2" />
						<polyline id="Stroke-21" points="14.7621905 4.95 14.7621905 6.48333333 12.5612381 8.11952381" />
						<line x1="17.6190476" y1="9.64628571" x2="13.66" y2="9.64628571" id="Stroke-23" strokeLinecap="round" strokeDasharray="1,2" />
						<line x1="10" y1="2.38095238" x2="10" y2="7.17142857" id="Stroke-25" strokeLinecap="round" strokeDasharray="1,2" />
					</g>
				</g>
			</g>
		</svg>
	);
};

export default StreamJoinIntMode;
