/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGAttributes } from "react";

const SeePost = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--accent)",
	} = props;
	return (
		<svg
			width="22px"
			height="13px"
			viewBox="0 0 22 13"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<g id="Profile---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-icons" transform="translate(-288.000000, -1015.000000)" stroke={stroke}>
					<g id="seePost_ego" transform="translate(288.500000, 1015.000000)">
						<path
							d="M0.5,6.4781 C0.5,6.4781 3.453,0.5001 10.274,0.5001 C17.095,0.5001 20.5,6.4781 20.5,6.4781 L20.5,6.5221 C20.5,6.5221 17.547,12.5001 10.726,12.5001 C3.905,12.5001 0.5,6.5221 0.5,6.5221 L0.5,6.4781 Z"
							id="Stroke-1"
						/>
						<path
							d="M13.5,6.5022 C13.5,8.1592 12.157,9.5022 10.5,9.5022 C8.843,9.5022 7.5,8.1592 7.5,6.5022 C7.5,4.8452 8.843,3.5022 10.5,3.5022 C12.157,3.5022 13.5,4.8452 13.5,6.5022 Z"
							id="Stroke-3"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default SeePost;
