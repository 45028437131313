import * as React from "react"
import {SVGAttributes} from "react";

const SvgComponent = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--gray-dark)" } = props;
	return (
		<svg width={17} height={20} xmlns="http://www.w3.org/2000/svg" {...props}>
			<g stroke={stroke} fill="none" fillRule="evenodd">
				<path d="M14.724 19.5H2.276a.95.95 0 0 1-.949-.849L.505 9.536A.947.947 0 0 1 1.454 8.5h14.092a.947.947 0 0 1 .949 1.036l-.822 9.115a.95.95 0 0 1-.949.85Z" />
				<path d="M2.5 8.5v-2a6 6 0 1 1 12 0v2" />
				<path d="M11.5 8.5v-2c0-1.654-1.346-3-3-3s-3 1.346-3 3v2M10.48 13.093a1.98 1.98 0 1 0-3.96 0c0 .75.422 1.395 1.038 1.731v1.251a.942.942 0 0 0 1.884 0v-1.25a1.97 1.97 0 0 0 1.038-1.732Z" />
			</g>
		</svg>
	)
}

export default SvgComponent
