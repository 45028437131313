/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
	useCallback, useMemo, useRef, useState, useEffect,
} from "react";

// mentions, hashtags and linkify
import createMentionPlugin, { MentionData } from "@draft-js-plugins/mention";
import createHashtagPlugin from "@draft-js-plugins/hashtag";
import createLinkifyPlugin from "@draft-js-plugins/linkify";
import {
	EditorState, ContentState, Modifier,
} from "draft-js";
import Editor from "@draft-js-plugins/editor";
import "draft-js/dist/Draft.css";
import {
	AppMode, cu, dbg, LogMgr, YesNoOptions,
} from "@credo/utilities";
import omit from "lodash/omit";
import { useDropzone } from "react-dropzone";
import { Icons, toast } from "react-toastify";
import imageCompression from "browser-image-compression";
import { LinkifyPluginTheme } from "@draft-js-plugins/linkify/lib/theme";
import commentMentionsStyles from "./CommentMentionsStyles.module.css";
import {
	classNames, Consts, RegEx, SvgIcon,
} from "../../common";
import { ProfilePicture } from "../../profile/profile-picture";
import { ProfileSize } from "../../profile/types";
import { useDebouncedCallback } from "../../hooks/useDebouncedCallback";
import { ClearNewPostTitleIcon, SelectImageIcon, SendCommentIcon } from "../../assets/icons";
import { styleMapForEditor } from "../../Constants";
import ImageCollage from "../post-image/ImageCollage";
import { SnackBarTypeOptions } from "../../snackbars";
import { PostMsgs } from "../types";

const MAX_LENGTH_POST_TEXT = 1500;

const MAX_SIZE = 10; // size in MB

export interface PostCommentInputProps {
	id: string;
	placeHolder: string;
	onChangeText: (text: string) => void;
	getSuggesionsFromServer: (msg: any) => void;
	mentionData: MentionData[];
	containerStyle: string;
	onSendComment: (imgData: any) => void;
	onCommentInputFocus?: () => void;
	disabled?: boolean;
	/**
	 * Focussing on the input field forcefully.
	 * */
	isFocused?: boolean;
	value?: string;
	userDetails: any;
	postMsgs: PostMsgs;
	showDownloadModal: () => void,
}

export const PostCommentInput = ({
	id,
	placeHolder,
	onChangeText,
	mentionData,
	containerStyle,
	getSuggesionsFromServer,
	onSendComment,
	onCommentInputFocus,
	disabled,
	isFocused = false,
	value = "",
	userDetails,
	postMsgs,
	showDownloadModal,
}: PostCommentInputProps) => {
	const [editorInState, setEditorInState] = useState(() => EditorState.createEmpty());
	const [suggestions, setSuggestions] = useState(mentionData);
	const [isSuggestionOpen, setIsSuggestionOpen] = useState(false);
	const [isSendVisible, setIsSendVisible] = useState(false);
	const [photos, setPhotos] = useState<any>([]);
	const [images, setImages] = useState<any>([]);
	const [isCompressionGoingOn, setIsCompressionGoingOn] = useState<boolean>(false);
	// Using this to check if the input field is focused and add event listener for pasting the image in input
	const [isFocusLocal, setIsFocusLocal] = useState<boolean>(false);
	const [tagName, setTagName] = useState("");
	const controller = new AbortController();

	const {
		imageErrorMsg,
		contentSensitiveMsg,
	} = postMsgs;

	const mentionEditorRef = useRef<Editor>(null);

	const { MentionSuggestions, plugins } = useMemo(() => {
		const mentionPlugin = createMentionPlugin({
			entityMutability: "IMMUTABLE",
			theme: commentMentionsStyles,
			mentionPrefix: "@",
			mentionTrigger: "@",
			supportWhitespace: true,
			mentionComponent: (mentionProps) => (
				<span className={mentionProps.className}>
					{mentionProps.children}
				</span>
			),
		});
		const { MentionSuggestions } = mentionPlugin;
		const hashtagPlugin = createHashtagPlugin({ theme: commentMentionsStyles });
		// Even though link is present in the commentMentionStyles module it was throwing link is missing.
		const linkifyPlugin = createLinkifyPlugin({ theme: commentMentionsStyles as unknown as LinkifyPluginTheme });
		const plugins = [mentionPlugin, hashtagPlugin, linkifyPlugin];
		return { plugins, MentionSuggestions };
	}, []);

	useEffect(() => {
		setSuggestions(mentionData);
	}, [mentionData]);

	const showButton = () => {
		setIsSendVisible(true);
	};

	const hideButton = () => {
		setIsSendVisible(false);
	};

	const {
		acceptedFiles,
		fileRejections,
		getRootProps,
		getInputProps,
		isFocused: focused,
		isDragAccept,
		isDragReject,
		open,
	} = useDropzone({
		accept: {
			"image/jpeg": [],
			"image/jpg": [],
			"image/png": [],
			"image/gif": [],
		},
		multiple: false,
		maxFiles: 1,
		noClick: true,
		noKeyboard: true,
	});

	const setCompressedFiles = async (selectedImages: any) => {
		setIsCompressionGoingOn(true);
		hideButton();
		try {
			const compressedFiles = await Promise.all(
				selectedImages.map((file: any) => imageCompression(file, {
					maxSizeMB: MAX_SIZE,
					maxWidthOrHeight: 1500,
					initialQuality: 0.6,
					onProgress: (prog: number) => {
						const imgsData = images.length > 0 ? images : selectedImages;
						const idx = selectedImages?.findIndex((ele: any) => ele.path === file.path);
						if (idx !== -1) {
							imgsData[idx] = {
								...imgsData[idx],
								preview: URL.createObjectURL(file),
								isCompressing: prog !== 100,
								progress: prog,
							};
							setImages([...imgsData]);
						}
					},
					signal: controller.signal,
				})),
			);
			setIsCompressionGoingOn(false);
			if (compressedFiles) {
				setPhotos(compressedFiles.map((img: any) => URL.createObjectURL(img)));
				setImages(compressedFiles.map((file: any) => Object.assign(file, {
					preview: URL.createObjectURL(file),
					isCompressing: false,
					progress: 0,
				})));
			}
			showButton();
		} catch (error) {
			setIsCompressionGoingOn(false);
		}
	};

	useEffect(() => {
		if (acceptedFiles && acceptedFiles.length > 0) {
			if (cu.isSet(cu.getGlobalVar(Consts.sess))) {
				setPhotos(acceptedFiles.slice(0, 1).map((img: any) => URL.createObjectURL(img)));
				setImages(acceptedFiles.slice(0, 1).map((file: any) => Object.assign(file, {
					preview: URL.createObjectURL(file),
					isCompressing: true,
					progress: 0,
				})));

				setCompressedFiles(acceptedFiles.slice(0, 1));
				if (acceptedFiles?.length > 1) {
					toast(() => (
						<div className={classNames("flex flex-col")}>
							<div className={classNames("flex flex-row")}>
								<div className={classNames("flex shrink-0 w-5 mr-2.5")}>
									{Icons[SnackBarTypeOptions.ERROR]({ theme: "light", type: SnackBarTypeOptions.ERROR })}
								</div>
								<div className={classNames("flex flex-row w-11/12")}>
									<p className={classNames("text-sm text-gray-dark")}>
										Sorry! You can`t select more than 1 image.
									</p>
								</div>
							</div>
						</div>
					), {
						position: toast.POSITION.BOTTOM_LEFT,
						autoClose: 4000,
						hideProgressBar: true,
						closeOnClick: true,
						rtl: false,
						pauseOnFocusLoss: false,
						draggable: true,
						pauseOnHover: true,
					});
				}
			} else if (onCommentInputFocus) {
				onCommentInputFocus();
			}
		}
	}, [acceptedFiles]);

	useEffect(() => {
		if (fileRejections && fileRejections.length > 0) {
			if (cu.isSet(cu.getGlobalVar(Consts.sess))) {
				const photosData: any[] = [];
				let showVideoError = false;
				let showInvalidFileTypeError = false;
				fileRejections.map((data: any) => {
					if (data.file && data.errors && data.errors.length > 0 && data.errors[0].code === "too-many-files") {
						photosData.push(data.file);
					}
					if (data.errors && data.errors.length > 0 && data.errors[0].code === "file-invalid-type"
						&& data?.file?.type?.includes("video/")) {
						showVideoError = true;
					}
					if (data.errors && data.errors.length > 0 && data.errors[0].code === "file-invalid-type"
						&& !data?.file?.type?.includes("video/")) {
						showInvalidFileTypeError = true;
					}
					return null;
				});
				if (photosData && photosData.length > 0) {
					setPhotos(photosData.slice(0, 1).map((img: any) => URL.createObjectURL(img)));
					setImages(photosData.slice(0, 1).map((file: any) => Object.assign(file, {
						preview: URL.createObjectURL(file),
						isCompressing: true,
						progress: 0,
					})));
					setCompressedFiles(photosData.slice(0, 1));
				}

				if (showInvalidFileTypeError) {
					toast(() => (
						<div className={classNames("flex flex-col")}>
							<div className={classNames("flex flex-row")}>
								<div className={classNames("flex shrink-0 w-5 mr-2.5")}>
									{Icons[SnackBarTypeOptions.ERROR]({ theme: "light", type: SnackBarTypeOptions.ERROR })}
								</div>
								<div className={classNames("flex flex-row w-11/12")}>
									<p className={classNames("text-sm text-gray-dark")}>
										This file type is not supported. Please upload .jpg or .png
									</p>
								</div>
							</div>
						</div>
					), {
						position: toast.POSITION.BOTTOM_LEFT,
						autoClose: 4000,
						hideProgressBar: true,
						closeOnClick: true,
						rtl: false,
						pauseOnFocusLoss: false,
						draggable: true,
						pauseOnHover: true,
					});
				}

				if (showVideoError) {
					toast(() => (
						<div className={classNames("flex flex-col")}>
							<div className={classNames("flex flex-row")}>
								<div className={classNames("flex shrink-0 w-5 mr-2.5")}>
									{Icons[SnackBarTypeOptions.ERROR]({ theme: "light", type: SnackBarTypeOptions.ERROR })}
								</div>
								<div className={classNames("flex flex-row w-11/12")}>
									<span className={classNames("text-sm text-gray-dark")}>
										We currently don’t support video upload on web.
										<button
											type="button"
											className={classNames("text-primary pr-1")}
											onClick={showDownloadModal}
										>
											Download
										</button>
										our mobile app for this functionality
									</span>
								</div>
							</div>
						</div>
					), {
						position: toast.POSITION.BOTTOM_LEFT,
						autoClose: 4000,
						hideProgressBar: true,
						closeOnClick: true,
						rtl: false,
						pauseOnFocusLoss: false,
						draggable: true,
						pauseOnHover: true,
					});
				}
			} else if (onCommentInputFocus) {
				onCommentInputFocus();
			}
		}
	}, [fileRejections]);

	useEffect(() => {
		const handlePaste = (evt: ClipboardEvent) => {
			const clipboardItems = evt.clipboardData?.items as any;
			const items = [].slice.call(clipboardItems).filter((item: any) => item?.type.indexOf("image") !== -1);
			if (items.length === 0) {
				return;
			}
			setPhotos(items.slice(0, 1).map((img: any) => URL.createObjectURL(img.getAsFile())));
			setImages(items.slice(0, 1).map((file: any) => Object.assign(file.getAsFile(), {
				preview: URL.createObjectURL(file.getAsFile()),
				isCompressing: true,
				progress: 0,
			})));
			setCompressedFiles(items.slice(0, 1).map((img: any) => img.getAsFile()));
		};

		if (cu.isSet(cu.getGlobalVar(Consts.sess)) && isFocusLocal) {
			try {
				document.addEventListener("paste", handlePaste);
			} catch (e) {
				if (dbg) LogMgr.mydbg(`Getting error while pasting images on comment input - Error: ${e}`);
			}
		} else {
			document.removeEventListener("paste", handlePaste);
		}

		return () => {
			document.removeEventListener("paste", handlePaste);
		};
	}, [isFocusLocal]);

	const COLLAGE_LAYOUT = [[1, 1], [2, 1], [1, 2], [2, 2]];

	const clearImageData = () => {
		setImages([]);
		setPhotos([]);
		controller.abort();
	};

	const renderCollageImages = () => {
		if (images && images.length === 0) {
			return null;
		}
		const comment_images = images.map((img: any) => ({
			source: img.preview, isCompressing: img.isCompressing, progress: img.progress,
		}));
		return (
			<div className="relative w-40 p-2">
				<ImageCollage
					caption={commentText || ""}
					photos={comment_images}
					layout={COLLAGE_LAYOUT[comment_images.length - 1]}
					height={comment_images.length === 1 ? ["100%"] : ["100%", "100%"]}
					width="100%"
					isContentRestricted={false}
					imageErrorMsg={imageErrorMsg || ""}
					contentSensitiveMsg={contentSensitiveMsg || ""}
					imgErrorStyle="!bg-background"
				/>
				<button
					type="button"
					className="z-[100] absolute right-1 top-1"
					onClick={clearImageData}
				>
					<ClearNewPostTitleIcon
						stroke="var(--divider)"
						fill="var(--tag-remove)"
						width={20}
						height={20}
						className="bg-tag-remove rounded-full p-1"
					/>
				</button>
			</div>
		);
	};

	const insertText = (text: string) => {
		const e = EditorState.createEmpty();
		const stateWithEntity = e
			.getCurrentContent()
			.createEntity("mention", "IMMUTABLE", {
				mention: {
					id: Math.random(),
					name: text,
				},
			});
		const entityKey = stateWithEntity.getLastCreatedEntityKey();
		const stateWithText = Modifier.insertText(
			stateWithEntity,
			e.getSelection(),
			text,
			undefined,
			entityKey,
		);
		const updatedEditorState = EditorState.moveFocusToEnd(
			EditorState.push(e, stateWithText, "undo"),
		);
		return updatedEditorState;
	};

	useEffect(() => {
		if (value) {
			setEditorInState(insertText(value));
		}
	}, [value]);

	useEffect(() => {
		if (isFocused && !disabled) {
			// we need timeout to get plugins working. This is a library issue
			// ref: https://github.com/draft-js-plugins/draft-js-plugins/issues/800#issuecomment-315950836
			setTimeout(() => mentionEditorRef.current?.focus(), 0);
		}
	}, [isFocused]);

	const onChangeEditorText = (editorState: any) => {
		const contentState = editorState.getCurrentContent();
		const oldContent = editorInState.getCurrentContent();
		if (contentState === oldContent || contentState.getPlainText().length <= MAX_LENGTH_POST_TEXT) {
			setEditorInState(editorState);
		} else {
			const updatedEditorState = EditorState.moveFocusToEnd(
				EditorState.push(
					editorInState,
					ContentState.createFromText(oldContent.getPlainText()),
					"undo",
				),
			);
			setEditorInState(updatedEditorState);
		}
		if (contentState !== oldContent) {
			let typingTimer;
			const text = editorState.getCurrentContent().getPlainText();
			if (onChangeText) {
				onChangeText(text);
			}
			if (((text && /\S/.test(text)) || photos.length > 0) && !isCompressionGoingOn) {
				clearTimeout(typingTimer);
				typingTimer = setTimeout(() => {
					if ((text || photos.length > 0) && !isCompressionGoingOn) {
						showButton();
					} else {
						hideButton();
					}
				}, 1000);
			} else {
				hideButton();
			}
		}
	};

	const onSuggestionOpenChange = useCallback((open: boolean) => {
		if (!open) {
			setSuggestions(mentionData);
		}
		setIsSuggestionOpen(open);
	}, []);

	const onSearchChange = ({ value }: { value: string }) => {
		if (value && value.trim()) {
			const searchTag = value.trim();
			setTagName(searchTag);
			const msgData = {
				what: searchTag,
				s_users: YesNoOptions.YES,
				s_streams: YesNoOptions.NO,
			};
			if (getSuggesionsFromServer) getSuggesionsFromServer(msgData);
		} else {
			setTagName("");
			setSuggestions(mentionData);
			if (getSuggesionsFromServer) getSuggesionsFromServer(null);
		}
	};

	const onSearchChangeDebounce = useDebouncedCallback(({ value }: { value: string }) => {
		onSearchChange({ value });
	}, 500);

	const commentText = editorInState.getCurrentContent().getPlainText();

	const addMention = (item: any) => {
		setTagName("");
	};

	const findWithRegex = (regex: any, contentBlock: any, callback: any) => {
		const text = contentBlock.getText();
		let matchArr; let
			start;
		// eslint-disable-next-line no-cond-assign
		while ((matchArr = regex.exec(text)) !== null) {
			start = matchArr.index;
			callback(start, start + matchArr[0].length);
		}
	};

	const handleBoldText = (contentBlock: any, callback: any) => {
		findWithRegex(RegEx.boldTag, contentBlock, callback);
	};

	const resetEditorState = useDebouncedCallback(() => {
		const newEditorState = EditorState.moveFocusToEnd(
			EditorState.push(
				editorInState,
				ContentState.createFromText(""),
				"undo",
			),
		);
		setEditorInState(newEditorState);
	}, 500);

	const onSendCommentClick = () => {
		if (onSendComment) {
			onSendComment(photos);
		}
		clearImageData();
		resetEditorState();
	};

	const showBoldText = (props: any) => (<span className="font-bold">{props.children}</span>);

	const renderSuggestions = ({
		mention,
		theme,
		searchValue,
		isFocused,
		...parentProps
	}: any) => (
		<div
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...omit(parentProps, ["selectMention"])}
			className="py-3 flex flex-row justify-center rounded-lg items-center px-2 m-1 hover:bg-profile-border cursor-pointer"
		>
			<div className={classNames(theme.mentionSuggestionsEntryContainer)}>
				<div
					data-role-id={`${mention.id}`}
					className={classNames(theme.mentionSuggestionsEntryContainerRight)}
				>
					<button
						type="button"
						className="flex flex-row justify-center items-center z-10"
						onClick={() => { addMention(mention); }}
					>
						<ProfilePicture profilePicUrl={mention.avatar} size={ProfileSize.X_SMALL} />
						<div className={classNames(theme.mentionSuggestionsEntryText, "pl-3 text-center align-middle")}>
							<span className="text-center align-middle text-title-color">{mention.title ?? mention.name}</span>
						</div>
					</button>
				</div>
			</div>
		</div>
	);

	const renderSendButton = () => {
		if (isSendVisible && (editorInState?.getCurrentContent()?.getPlainText() || photos?.length > 0)) {
			return (
				<div
					className="flex cursor-pointer px-2"
					onClick={onSendCommentClick}
					aria-hidden
				>
					<SvgIcon
						icon={SendCommentIcon}
					/>
				</div>
			);
		}
		return null;
	};

	const renderUploadImageIcon = () => (
		<div className="flex justify-center items-center flex-row p-3">
			<div>
				<input
					{...getInputProps({
						id,
						key: id,
						name: id,
					})}
				/>
				<SvgIcon
					icon={SelectImageIcon}
					title="Add Image"
					width={20}
					height={20}
					className="cursor-pointer"
					onClick={cu.isSet(cu.getGlobalVar(Consts.sess)) ? open : () => { }}
				/>
			</div>
		</div>
	);

	const onFocus = () => {
		setIsFocusLocal(true);
		mentionEditorRef.current!.focus();
		if (onCommentInputFocus) {
			onCommentInputFocus();
		}
		if (disabled) {
			setIsFocusLocal(false);
			mentionEditorRef.current!.blur();
		}
	};

	return (
		<div {...getRootProps({
			key: id,
			name: id,
			className: classNames(
				"flex flex-row items-center justify-center dropzone",
			),
		})}
		>
			<ProfilePicture
				profilePicUrl={cu.buildSourceUrlImage(cu.getAppMode() === AppMode.CREDO
					? userDetails?.cprofilePicRelUrl : userDetails?.eprofilePicRelUrl) || ""}
				profId={cu.getAppMode() === AppMode.CREDO ? userDetails?.cprof_id : userDetails?.eprof_id}
				profilePicWrapperStyle="w-12"
			/>
			<div
				className={classNames("flex flex-1 flex-row w-full bg-content-L1 rounded-15 border border-content-L2",
					"font-thin px-2 text-sm items-center overflow-hidden",
					commentText?.length > 0 ? "text-basic" : "text-gray-dark",
					commentMentionsStyles.editor,
					photos.length > 0 ? "!max-h-48" : "",
					commentMentionsStyles.noZoom,
					containerStyle)}
				onClick={onFocus}
				aria-hidden
			>
				<div className="relative flex flex-col w-full h-full">
					<div
						id={id}
						key={id}
						style={{
							flex: 1, width: "100%", backgroundColor: "", position: "relative",
						}}
					>
						{focused || isDragAccept || isDragReject
							? (
								<div className={classNames("absolute w-full h-full rounded-full",
									"flex justify-center items-center z-[1000] bg-modalTransparentBackground")}
								>
									<span className={classNames("text-center align-middle text-md font-medium",
										isDragAccept ? "text-primary" : "text-error")}
									>
										Drop images here
									</span>
								</div>
							)
							: null}
						<Editor
							key={id}
							placeholder={placeHolder}
							editorKey="editor"
							customStyleMap={styleMapForEditor} // For removing pasted text styles
							editorState={editorInState}
							onChange={onChangeEditorText}
							plugins={plugins}
							ref={mentionEditorRef}
							onBlur={() => { setIsFocusLocal(false); }}
							decorators={[{
								strategy: handleBoldText,
								component: showBoldText,
								props: {
									id,
								},
							}]}
						/>
					</div>
					{renderCollageImages()}
				</div>
				<MentionSuggestions
					open={isSuggestionOpen}
					onOpenChange={onSuggestionOpenChange}
					onSearchChange={onSearchChangeDebounce}
					suggestions={suggestions}
					onAddMention={addMention}
					entryComponent={renderSuggestions}
				/>
				{renderUploadImageIcon()}
			</div>
			{renderSendButton()}
		</div>
	);
};

PostCommentInput.defaultProps = {
	onCommentInputFocus: () => { },
	disabled: false,
	isFocused: false,
	value: "",
};
