import React, {
	useCallback, useMemo, useRef,
} from "react";
import {
	BoostActionType, cu, onActionsParam, PostBoostInfo, TopBoosters,
} from "@credo/utilities";
import { CSSTransition } from "react-transition-group";
import {
	CurrentBoost, CurrentRank,
} from "./components";
import { ProfilePicture } from "../../profile/profile-picture";
import { ProfileSize } from "../../profile/types";
import Style from "./BoostTray.module.css";
import {
	CIStyler, classNames, isWideCalc, WEB_SIZE_PROP_TRAY,
} from "../../common";
import { RankDelta } from "../types";

export interface BoostTrayProps {
	/**
	 * consists of currentRanking and prevRanking to determine
	 * and display the current rank of the post in the tray.
	 * */
	ranking: {
		currentRanking: number;
		prevRanking: number;
	};
	/**
	 * Boost level information of post.
	 * */
	totalBoost: {
		/**
		 * Total boost level which this post has in previous snap
		 * */
		prev: number,
		/**
		 * Total boost level which this post has at this time
		 * */
		current: number,
		/**
		 * Boost level of the top post to display the bars in current
		 * boost icon
		 * */
		topPost: number,
	}
	/**
	 * Top users who have boosted this post.
	 *
	 * @see TopBoosters
	 * */
	topBoosters: TopBoosters[];
	/**
	 * If the width of the window is more than certain
	 * limit then we can identify if the UI needs to be
	 * rendered for mobile or desktop
	 * */
	isWide: boolean;
	/**
	 * actions which are taken in the component. Param
	 * will contain the type of action and the message
	 * with it.
	 *
	 * @see onActionsParam
	 * */
	onActions: (param: onActionsParam) => void | Promise<any> | null;
	/**
	 * post boost info
	 *
	 * @see PostBoostInfo
	 * */
	postBoostInfo: PostBoostInfo | undefined;
	/**
	 * String literals which will come from locale and
	 * will be shown to the respective entity
	 * */
	messages: {
		// message on boost submitted in toast
		boostSuccess: string;
	}
	/**
	 * control parts of the component throw authorisation
	 * object boolean keys. We can also add some context
	 * functions in the keys
	 * */
	authorisation: {
		isBoostAllowed: boolean;
	}
	/**
	 * Keep the tray always open
	 * @default false;
	 * */
	alwaysOpen?: boolean;
	/**
	 * Hide the rank section of the component, mainly used
	 * in boost board where the ranks are greater than 10,
	 * so we will not show the ranks which are greater than
	 * 10
	 * @default false;
	 * */
	hideRankOfPost?:boolean;
	estDivPayoutRate: number;
	currentTagScore: number;
}

const TRAY_ANIMATION_DURATION = 100;

/**
 * Boost tray will consist of number of small components.
 * Boost tray will have its own handling for expanding and
 * minimizing.
 *
 * When minimized it will contain:
 * 1. Board ranking
 * 2. Ranking Delta Marker (if current rank is higher or lower than previous)
 * 3. Boost indicator (The actual boost value with graphic to display the boost
 * of current post)
 * 4. Top Boosters (Users who are top boosters - maybe they are routable)
 * */
const BoostTrayComponent: React.FC<BoostTrayProps> = (props: BoostTrayProps) => {
	const {
		ranking,
		totalBoost,
		topBoosters,
		isWide,
		onActions,
		alwaysOpen = false,
		hideRankOfPost = false,
		estDivPayoutRate,
		currentTagScore,
	} = props;

	const trayRef = useRef<HTMLDivElement>(null);

	const rankDelta: RankDelta = useMemo(() => {
		if (ranking.currentRanking === ranking.prevRanking || ranking.prevRanking < 1) return RankDelta.SAME;
		if (ranking.currentRanking < ranking.prevRanking) {
			return RankDelta.POSITIVE;
		} else {
			return RankDelta.NEGATIVE;
		}
	}, [ranking]);

	const unranked = useMemo(() => ranking.currentRanking > 10, [ranking]);
	const color = useMemo(() => CIStyler.csColor(currentTagScore), [currentTagScore]);

	const handleSummaryClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		if (alwaysOpen) return;
		onActions({
			type: BoostActionType.EXPAND,
			message: {
				clientY: event.clientY,
				clientX: event.clientX,
				pageX: event.pageX,
				pageY: event.pageY,
				// @ts-ignore
				trayRect: trayRef.current?.getBoundingClientRect(),
			},
		});
	};

	const renderSummary = useCallback(() => (
		<button
			type="button"
			onClick={handleSummaryClick}
			data-testid="boost-summary-wrapper"
			className={classNames(
				"flex flex-col justify-between items-start",
			)}
			style={{
				width: isWide ? 27.2 : 24,
			}}
		>
			<div
				data-testid="top-section-wrapper"
				className={classNames(
					"flex flex-col justify-start items-center ml-[3px] mt-[3px] pb-px",
					"bg-content-l0-secondary",
				)}
				style={{
					borderTopLeftRadius: isWideCalc(isWide, 9, WEB_SIZE_PROP_TRAY),
					borderTopRightRadius: isWideCalc(isWide, 9, WEB_SIZE_PROP_TRAY),
					borderBottomRightRadius: isWideCalc(isWide, 5, WEB_SIZE_PROP_TRAY),
					borderBottomLeftRadius: isWideCalc(isWide, 5, WEB_SIZE_PROP_TRAY),
					width: isWideCalc(isWide, 18, WEB_SIZE_PROP_TRAY),
				}}
			>
				<div
					data-testid="current-rank-indicator"
				>
					<CurrentRank
						rankDeltaSign={rankDelta}
						currentRank={ranking.currentRanking}
						unranked={unranked}
					/>
				</div>
				<div
					data-testid="current-boost-indicator"
					className="pt-1 px-px"
				>
					<CurrentBoost
						totalBoost={totalBoost}
						score={currentTagScore}
					/>
				</div>
			</div>
			<div
				className={classNames(
					"flex flex-col items-center justify-start relative ml-[3px] px-0.5",
					"bg-content-l0-secondary",
					"w-[18px]",
					"mb-[4px] mobile-compact:mb-[3px]",
				)}
				style={{
					borderTopLeftRadius: isWideCalc(isWide, 5, WEB_SIZE_PROP_TRAY),
					borderTopRightRadius: isWideCalc(isWide, 5, WEB_SIZE_PROP_TRAY),
					borderBottomRightRadius: isWideCalc(isWide, 9, WEB_SIZE_PROP_TRAY),
					borderBottomLeftRadius: isWideCalc(isWide, 9, WEB_SIZE_PROP_TRAY),
					width: isWideCalc(isWide, 18, WEB_SIZE_PROP_TRAY),
					height: isWideCalc(isWide, 13, WEB_SIZE_PROP_TRAY)
						+ (topBoosters.slice(0, 3).length * isWideCalc(isWide, 16, WEB_SIZE_PROP_TRAY))
						- ((topBoosters.slice(0, 3).length - 1) * 4),
				}}
			>
				<span
					className="text-accent-2 font-bold font-pt-mono"
					style={{
						fontSize: isWideCalc(isWide, 8, WEB_SIZE_PROP_TRAY),
					}}
				>
					{topBoosters.length}
				</span>
				{topBoosters.slice(0, 3).map((user: TopBoosters, index) => (
					<div
						key={user.eprof_id}
						className={classNames(
							"leading-3",
							"overflow-hidden",
							"absolute",
						)}
						style={{
							top: isWideCalc(isWide, 12, WEB_SIZE_PROP_TRAY)
								+ (index * isWideCalc(isWide, 16, WEB_SIZE_PROP_TRAY))
								- (index * 4),
							zIndex: topBoosters.slice(0, 3).length - index,
						}}
						title={`${user.firstName ?? ""} ${user.lastName ?? ""}`}
					>
						<ProfilePicture
							profilePicUrl={cu.buildSourceUrlImage(user.eprofilePicRelUrl) ?? ""}
							size={isWide ? ProfileSize.XX_SMALL_WEB : ProfileSize.XX_SMALL}
							score={user.ccs}
						/>
					</div>
				))}
			</div>
		</button>
	), [totalBoost, ranking, topBoosters, isWide, hideRankOfPost, currentTagScore]);

	const renderTrayContent = useCallback(() => <div className="w-60" />, []);

	if (alwaysOpen) {
		return (
			<div
				ref={trayRef}
				className={classNames(
					"flex flex-row h-full max-h-36 mobile-compact:max-h-40",
					Style.trayBg,
					// Style.trayCont,
					// Style.trayContEnterDone,
				)}
			>
				{renderSummary()}
				{renderTrayContent()}
			</div>
		);
	}

	return (
		<CSSTransition
			nodeRef={trayRef}
			in={false}
			timeout={TRAY_ANIMATION_DURATION}
			classNames={{
				enter: Style.trayContEnter,
				enterDone: Style.trayContEnterDone,
				enterActive: Style.trayContEnterActive,
				exit: Style.trayContExit,
				exitDone: Style.trayContExitDone,
			}}
		>
			<div
				ref={trayRef}
				style={{
					backgroundColor: color,
				}}
				className={classNames(
					"flex flex-row h-full max-h-36 mobile-compact:max-h-40",
					unranked ? "bg-dark-main" : "",
					Style.trayCont,
					Style.boostTray,
				)}
			>
				{renderSummary()}
				{renderTrayContent()}
			</div>
		</CSSTransition>
	);
};

const compareFn = (
	nextProps: BoostTrayProps,
	prevProps: BoostTrayProps,
) => nextProps.totalBoost === prevProps.totalBoost
	&& nextProps.isWide === prevProps.isWide
	&& nextProps.alwaysOpen === prevProps.alwaysOpen
	&& nextProps.hideRankOfPost === prevProps.hideRankOfPost
	&& nextProps.estDivPayoutRate === prevProps.estDivPayoutRate
	&& nextProps.currentTagScore === prevProps.currentTagScore
	&& JSON.stringify(nextProps.topBoosters) === JSON.stringify(prevProps.topBoosters)
	&& JSON.stringify(nextProps.ranking) === JSON.stringify(prevProps.ranking)
	&& JSON.stringify(nextProps.postBoostInfo) === JSON.stringify(prevProps.postBoostInfo)
	&& JSON.stringify(nextProps.authorisation) === JSON.stringify(prevProps.authorisation);

// eslint-disable-next-line import/prefer-default-export
export const BoostTray = React.memo(
	BoostTrayComponent,
	compareFn,
);
