/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGAttributes } from "react";

const ProfileNotAccessible = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--accent)",
	} = props;
	return (
		<svg
			width="70px"
			height="72px"
			viewBox="0 0 70 72"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<title>profile_not_accessible</title>
			<g id="Profile---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-icons" transform="translate(-98.000000, -264.000000)" stroke={stroke}>
					<g id="profile_not_accessible" transform="translate(98.000000, 264.000000)">
						<path
							d="M54.1665,70.9798 L35.1285,70.9798 C27.0935,70.9798 20.5795,64.4648 20.5795,56.4298 L20.5795,40.6588 C20.5795,37.2318 23.3565,34.4548 26.7835,34.4548 L62.5115,34.4548 C65.9375,34.4548 68.7165,37.2318 68.7165,40.6588 L68.7165,56.4298 C68.7165,64.4648 62.2015,70.9798 54.1665,70.9798 Z"
							id="Stroke-1"
						/>
						<path
							d="M61.4556,34.4545 L53.5796,34.4545 L53.5796,24.7625 C53.5796,19.8365 49.5726,15.8295 44.6466,15.8295 C39.7226,15.8295 35.7156,19.8365 35.7156,24.7625 L35.7156,34.4545 L27.8396,34.4545 L27.8396,24.7625 C27.8396,15.4945 35.3796,7.9545 44.6466,7.9545 C53.9156,7.9545 61.4556,15.4945 61.4556,24.7625 L61.4556,34.4545 Z"
							id="Stroke-3"
						/>
						<path
							d="M51.0122,50.2943 C51.0122,46.7793 48.1622,43.9303 44.6472,43.9303 C41.1322,43.9303 38.2832,46.7793 38.2832,50.2943 C38.2832,53.2833 40.3452,55.7833 43.1232,56.4683 L43.1232,60.3343 C43.1232,61.1773 43.8062,61.8583 44.6482,61.8583 C45.4902,61.8583 46.1722,61.1773 46.1722,60.3343 L46.1722,56.4683 C48.9492,55.7833 51.0122,53.2823 51.0122,50.2943 Z"
							id="Stroke-5"
						/>
						<path
							d="M40.3315,16.9486 C40.5465,18.1266 40.6585,19.3396 40.6585,20.5796 C40.6585,25.8096 38.6575,30.5746 35.3805,34.1476"
							id="Stroke-7"
							strokeDasharray="2,2"
						/>
						<path
							d="M20.5796,40.6586 C9.4896,40.6586 0.5006,31.6686 0.5006,20.5796 C0.5006,9.4896 9.4896,0.5006 20.5796,0.5006 C27.5106,0.5006 33.6206,4.0116 37.2286,9.3526"
							id="Stroke-9"
							strokeDasharray="2,2"
						/>
						<path
							d="M20.1821,17.8636 C23.4671,17.8636 26.1391,15.1916 26.1391,11.9056 C26.1391,8.6206 23.4671,5.9486 20.1821,5.9486 C16.8981,5.9486 14.2251,8.6206 14.2251,11.9056 C14.2251,15.1916 16.8981,17.8636 20.1821,17.8636 Z"
							id="Stroke-11"
							strokeDasharray="2,2"
						/>
						<path
							d="M22.8442,35.8675 C21.9802,36.0165 21.0902,36.0955 20.1832,36.0955 C15.9312,36.0955 12.0802,34.3815 9.2792,31.6105 L9.2812,31.0625 C9.7872,24.5025 12.6222,21.8465 14.2672,20.8405 L14.6692,20.5945 L15.0742,20.8695 L20.1822,24.8435 L25.2882,20.8695 L25.6942,20.5945 L26.0962,20.8405 C26.6422,21.1745 27.3192,21.6895 27.9982,22.4705"
							id="Stroke-13"
							strokeDasharray="2,2"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default ProfileNotAccessible;
