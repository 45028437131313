import React from "react";
import { classNames, SvgIcon } from "../../../common";
import { VoltsIcon } from "../../../assets/icons";
import { BoostIncrementStatus } from "../../types";

interface BoostButtonProps {
	boostIncrementStatus: BoostIncrementStatus,
	handleIncrement: () => void;
	/**
	 * control parts of the component throw authorisation
	 * object boolean keys. We can also add some context
	 * functions in the keys
	 * */
	authorisation: {
		isBoostAllowed: boolean;
	}
	boostAmount: string;
}

// eslint-disable-next-line import/prefer-default-export
export const BoostButton: React.FC<BoostButtonProps> = (props: BoostButtonProps) => {
	const {
		boostIncrementStatus,
		handleIncrement,
		authorisation,
		boostAmount,
	} = props;
	return (
		<button
			key={boostAmount}
			type="button"
			data-testid="boost-increment-button"
			className={classNames(
				"bg-tag-green w-[58px] !border-dark-main h-6 rounded-[16px]",
				"flex justify-start items-center relative px-1",
				"text-xs text-black font-bold uppercase font-pt-mono",
				boostIncrementStatus === BoostIncrementStatus.PENDING ? "grayscale" : "",
			)}
			onClick={handleIncrement}
			disabled={!authorisation.isBoostAllowed || boostIncrementStatus === BoostIncrementStatus.PENDING}
		>
			<span
				className="w-[30px]"
			>
				{boostAmount}
			</span>
			<SvgIcon
				icon={VoltsIcon}
				className={classNames(
					"absolute",
					"right-0.5",
					"bg-dark-main rounded-full",
				)}
				// @ts-ignore
				borderColor="var(--tag-green)"
				height={20}
				width={20}
			/>
		</button>
	);
};
