/* eslint-disable react/no-array-index-key */
import React, { useState } from "react";
import { classNames } from "../common";
import { CredoBadge } from "../credo-badge";

interface ButtonProps {
	buttonTitle: {
		title: string;
		style?: string;
	};
	onPress?: () => void;
	disabled?: boolean | undefined;
	image?: any;
	badgeValue?: number;
	smallWidget?: any;
	showBadge?: boolean;
	badgeStyle?: string;
	imageStyle?: string;
	buttonStyle?: string;
	buttonChildStyle?: string;
}
interface CredoMultipleButtonsProps {
	minimumWidth?: number;
	containerStyle?: string;
	buttons: Array<ButtonProps>;
	separatorStyle?: string;
}

export default function MultipleButtons({
	separatorStyle,
	minimumWidth,
	containerStyle,
	buttons,
}: CredoMultipleButtonsProps) {
	const [minWidth, setMinWidth] = useState(minimumWidth || 75);
	const [noOfElementInEachRow] = useState(4);
	let maxFontSizeMultiplier = 1;

	if (!buttons) {
		return null;
	}

	const renderMatrix = () => {
		const matrix = [1];

		buttons.forEach((element: any, i: number) => {
			if (i % noOfElementInEachRow === 0) {
				matrix.push(i);
			}
		});

		return matrix.map((m) => {
			const startIndex = m * noOfElementInEachRow;
			const renderButtons = buttons.slice(startIndex, startIndex + noOfElementInEachRow).map((
				button: ButtonProps,
				i: number,
			) => {
				if (!button) {
					return null;
				}
				if (button.buttonTitle && button?.buttonTitle?.title?.length > 8 && !maxFontSizeMultiplier) {
					maxFontSizeMultiplier = 1;
					if (button?.buttonTitle?.title?.length > 8 && button?.buttonTitle?.title?.length < 10) {
						setMinWidth(75);
					} else if (button?.buttonTitle?.title?.length > 10 && button?.buttonTitle?.title?.length < 13) {
						setMinWidth(88);
					} else if (button?.buttonTitle?.title?.length > 13) {
						setMinWidth(95);
					}
				}
				return (
					<div
						key={`${i}_${button?.buttonTitle?.title}`}
						className={classNames("flex flex-row justify-center items-center")}
					>
						<button
							type="button"
							className={classNames(
								`flex justify-center items-center min-h-[40px] py-1 px-2 min-w-[${minWidth}px]`,
								button.buttonStyle,
							)}
							onClick={button.onPress}
							disabled={button.disabled}
						>
							<div
								className={classNames(
									"flex flex-col justify-between items-center px-1.5",
									button.buttonStyle,
								)}
							>
								{button.image || button.smallWidget || button.showBadge
									? (
										<div className={classNames("flex flex-row justify-center items-center")}>
											{button.image && button.showBadge
												? (
													<div className={classNames("flex flex-1 pl-5 items-start")} />
												) : null}
											{button.image
												? (
													<div
														className={classNames(
															"flex flex-1 my-1 items-center justify-center w-5 h-5",
															button?.imageStyle || "",
														)}
													>
														{button.image}
													</div>
												)
												: null}
											{
												button.smallWidget
													? button?.smallWidget
													: null
											}
											{button.showBadge && button?.badgeValue
												? (
													<div
														className={classNames("flex flex-1 justify-center items-center pb-1",
															button.badgeStyle)}
													>
														<CredoBadge
															badgeValue={button?.badgeValue > 99 ? "99+" : button.badgeValue}
															containerStyle="ml-1.5"
														/>
													</div>
												)
												: null}
										</div>
									)
									: null}
								{
									button.buttonTitle
										? (
											<p
												className={classNames("text-xs text-support uppercase leading-2",
													button.buttonTitle.style ? button.buttonTitle.style : "text-primary")}
											>
												{" "}
												{button.buttonTitle.title}
												{" "}
											</p>
										)
										: null
								}
							</div>
						</button>
						{buttons.length - 1 - startIndex - i > 0 && (i + 1) % noOfElementInEachRow !== 0
							? (
								<div className={classNames(separatorStyle, "w-px h-10 bg-background")} />
							) : null}
					</div>
				);
			});

			return (
				<div
					key={m}
					className={classNames("flex flex-row")}
				>
					{renderButtons}
				</div>
			);
		});
	};

	return (
		<div className={classNames(
			"flex flex-col w-full",
			containerStyle || "justify-center items-center",
		)}
		>
			{renderMatrix()}
		</div>
	);
}

MultipleButtons.defaultProps = {
	minimumWidth: 40,
	containerStyle: "",
	separatorStyle: "",
};
