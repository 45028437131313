import * as React from "react"
import {SVGAttributes} from "react";

const RefreshSingleArrow = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const {
		fill = "#0BE0D8",
		stroke = "#0BE0D8",
	} = props;
	return (
		<svg
			viewBox="0 0 15 15"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<defs>
				<path id="refresh-single-icon-a" d="M6.364 10.864a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Z" />
				<mask
					id="refresh-single-icon-b"
					width={9}
					height={9}
					x={0}
					y={0}
					fill="#fff"
					maskContentUnits="userSpaceOnUse"
					maskUnits="objectBoundingBox"
				>
					<use xlinkHref="#refresh-single-icon-a" />
				</mask>
			</defs>
			<g fill="none" fillRule="evenodd">
				<use
					xlinkHref="#refresh-single-icon-a"
					stroke={stroke}
					strokeDasharray="23,8"
					strokeWidth={2}
					mask="url(#refresh-single-icon-b)"
					transform="rotate(-135 8.046 8.818) scale(1.5)"
				/>
				<path fill={fill} d="M13.505 4.823V.581L9.263 4.823z" />
			</g>
		</svg>
	)
}
export default RefreshSingleArrow;
