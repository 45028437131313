import React, {
	useEffect, useState,
} from "react";
import ReactSwitch from "react-switch";
import { cu } from "@credo/utilities";
import { CredoAvatarPlaceholderIcon, CredoTextIcon, EgoAvatarPlaceholderIcon } from "../assets/icons";
import { CredoSwitchDimensions, CredoSwitchSize, UserProfile } from "./types";
import { SvgIcon } from "../common";

export interface CredoSwitchProps {
	/**
	 * Set size fo switch
	 * @default S
	 * */
	size: CredoSwitchSize;
	/**
	 * Set user credibility value
	 * @default 0
	 * */
	uCredibility?: number;
	/**
	 * user data like firstName, lastName and profile pics
	 * @default null
	 * */
	userProfile?: UserProfile | null;
	/**
	 * current mode value
	 * @default true
	 * */
	isCredo: boolean;
	/**
	 * feature on changing mode
	 *
	 * @param {boolean} mode App mode parameter
	 * */
	onModeChange?: (mode: boolean) => void
	/**
	 * Set true for invoking onBeforeValueChange method
	 * @default false
	 * */
	doBeforeValueChange?: boolean;
	/**
	 * feature on before changing the modes
	 * @default void
	 * */
	onBeforeValueChange?: (changeMode: () => void) => void
	/**
	 * Background color of the switch when
	 * switch is active or on, in credo mode.
	 * @default "#212636"
	 * */
	onColor?: string;
	/**
	 * Background color of the switch when
	 * switch is not active or off, in ego
	 * mode.
	 * @default "#C0C9D5"
	 * */
	offColor?: string
	/**
	 * Displays an arc on the left if the sum of
	 * count of request and notifications are more
	 * than 0 in ego, and displays on right side
	 * of the switch when sum of credo requests
	 * and notifications is more than 0.
	 *
	 * @default notificationIndicator: {
	 * 		egoCount: 0,
	 * 		credoCount: 0,
	 * 	},
	 * */
	notificationIndicator?: {
		egoCount: number,
		credoCount: number
	}
	/**
	 * checks whether switch is disabled or not
	 * @default false
	 * */
	disabled?: boolean;
}

export const CredoSwitch = (props: CredoSwitchProps) => {
	const {
		size,
		isCredo,
		uCredibility,
		userProfile,
		doBeforeValueChange,
		onModeChange,
		onBeforeValueChange,
		onColor = "#212636",
		offColor = "#F5F5F5",
		notificationIndicator,
		disabled = false,
	} = props;
	const [isCredoMode, setIsCredoMode] = useState(isCredo);
	const [egoProfilePicCount, setEgoProfilePicCount] = useState(0);
	const [nameInitials, setNameInitials] = useState("");
	const [isError, setIsError] = useState<boolean>(false);

	const width = CredoSwitchDimensions[size];
	const logoSizediff = 0.1 * width;

	const fontStyle = {
		[CredoSwitchSize.SMALL]: "text-xs",
		[CredoSwitchSize.MEDIUM]: "text-sm",
		[CredoSwitchSize.X_LARGE]: "text-xl",
	};

	const fontSize = fontStyle[CredoSwitchSize.SMALL];

	useEffect(() => {
		if (userProfile?.firstName && userProfile?.lastName) {
			setNameInitials(`${userProfile.firstName.substring(0, 1)}.${userProfile.lastName.substring(0, 1)}`);
		} else {
			setNameInitials("");
		}
		setEgoProfilePicCount(userProfile?.profilePictureChangeCount ?? 0);
	}, [userProfile]);

	useEffect(() => {
		setIsCredoMode(isCredo);
	}, [isCredo]);

	const changeMode = () => {
		if (onModeChange) {
			onModeChange(!isCredoMode);
		}
		setIsCredoMode(!isCredoMode);
	};

	const onChange = () => {
		if (!disabled) {
			if (doBeforeValueChange && onBeforeValueChange) {
				onBeforeValueChange(changeMode);
			} else {
				changeMode();
			}
		}
	};

	const getUserNameInitials = () => (
		<div
			className={`flex justify-center items-center ${fontSize} font-sans h-full pr-3px`}
			style={{ color: "#0F0F0F" }}
		>
			{nameInitials}
		</div>
	);

	const getCredoLogo = () => (
		<div className="flex justify-center items-center h-full p-1">
			<CredoTextIcon width={width - logoSizediff} height={width - logoSizediff} />
		</div>
	);

	const renderDefaultUserProfile = () => (
		<SvgIcon
			icon={EgoAvatarPlaceholderIcon}
			style={{ width: width - 2, height: width - 2, borderRadius: width / 2 }}
		/>
	);

	const getEgoProfilePic = () => {
		if (isError) {
			return renderDefaultUserProfile();
		}

		if (userProfile?.eprofilePicRelUrl) {
			return (
				<div className="flex justify-center items-center h-full">
					<img
						loading="lazy"
						className="border border-black"
						onError={(event) => {
							setIsError(true);
						}}
						src={`${cu.buildSourceUrlImage(userProfile.eprofilePicRelUrl)}&${egoProfilePicCount}` ?? ""}
						style={{ width: width - 4, height: width - 4, borderRadius: width / 2 }}
						alt="pic"
					/>
				</div>
			);
		}
		return (
			<div
				className="flex justify-center items-center h-full border border-black"
				style={{ borderRadius: width / 2 }}
			>
				<EgoAvatarPlaceholderIcon width={width - 4} height={width - 4} />
			</div>
		);
	};

	const getCredoProfilePic = () => {
		if (userProfile?.cprofilePicRelUrl) {
			return (
				<div className="flex justify-center items-center h-full">
					<img
						loading="lazy"
						className="border border-black"
						src={cu.buildSourceUrlImage(userProfile?.cprofilePicRelUrl) ?? ""}
						style={{ width: width - 4, height: width - 4, borderRadius: width / 2 }}
						alt="pic"
					/>
				</div>
			);
		}
		return (
			<div
				className="flex justify-center items-center h-full border border-black"
				style={{ borderRadius: width / 2 }}
			>
				<CredoAvatarPlaceholderIcon width={width - 4} height={width - 4} />
			</div>
		);
	};

	return (
		<div
			className="relative inline-flex flex-wrap"
			key={`switch-${size}`}
			id="credo-switch"
		>
			<ReactSwitch
				width={width * 2}
				height={width}
				onChange={onChange}
				checked={isCredoMode}
				onColor={onColor}
				offColor={offColor}
				onHandleColor="#171c2d"
				uncheckedIcon={getUserNameInitials()}
				checkedIcon={getCredoLogo()}
				uncheckedHandleIcon={getEgoProfilePic()}
				checkedHandleIcon={getCredoProfilePic()}
				activeBoxShadow="0 0 0 0 #FFFFFF"
				boxShadow="0 0 0 0 #FFFFFF"
				className="shadow-credo-switch z-10"
				role="switch"
			/>
		</div>
	);
};

CredoSwitch.defaultProps = {
	userProfile: null,
	doBeforeValueChange: false,
	onBeforeValueChange: () => { },
	onModeChange: () => { },
	onColor: "#212636",
	offColor: "#F5F5F5",
	notificationIndicator: {
		egoCount: 0,
		credoCount: 0,
	},
	uCredibility: 0,
	disabled: false,
};
