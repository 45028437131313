export const LANDING_PAGE = "/";
export const LOGIN_PAGE = "/";
export const ENGAGE = "/engage";
export const QR_CODE_2FA_SCREEN = "/2FAQRcode";
export const AUTH_2FA_SCREEN = "/2FAuth";
export const CAMPAIGNS_PAGE = "/campaigns";
export const USERS_PAGE = "/users";
export const POINTS_PAGE = "/points";
export const BADGES_PAGE = "/badges";
export const LEDGERS_PAGE = "/ledgers";
export const OTHERS_PAGE = "/others";
export const PROFILE_PAGE = "/profile";
export const CONTACT_PAGE = "/contact";
export const DASHBOARD_PAGE = "/dashboard";
export const REPORTED_PROBLEMS_PAGE = "/reported_problems";
export const SEARCH_PAGE = "/search";
export const REPORTED_PAGE = "/reported";
export const NEW_COMMUNITY_PAGE = "/new_communities";
export const DISABLED_PAGE = "/disabled";
export const SUSPENDED_USERS_PAGE = "/suspended_users";
export const NOT_REGISTERED_USERS_PAGE = "/not_fully_registered_users";
export const VIRTUAL_USERS_PAGE = "/virtual_users";
export const NEW_USERS_PAGE = "/new_users";
export const COMMUNITIES_PAGE = "/communities";
export const COMMUNITIES_DETAILS_PAGE = "/communities/details";
export const USER_DETAILS_PAGE = "/users/details";
export const POSTS_PAGE = "/posts";
export const POST_DETAILS_PAGE = "/post/details";
export const POST_COMMENTS_PAGE = "/post/comments";
export const COMMENTS_PAGE = "/comments";
export const COMMENT_DETAILS_PAGE = "/comments/details";
export const SETTINGS_PAGE = "/settings";
export const AUTH_URL = "/app";
export const PAGE_404_URL = "/404";
export const SID = "sid";
export const REPORTED_STREAM_ID = "reported_items_1";
// This is the stream id of the internal stream `Reported Item Stream` in which we are creating the post for the reported item.
export const REPORTED_USER_TYPE = "ru"; // postType ru for reported user
export const REPORTED_STREAM_TYPE = "rs"; // postType rs for reported stream
export const REPORTED_POST_TYPE = "rp"; // postType rp for reported post
export const REPORTED_COMMENT_TYPE = "rc"; // postType ru for reported comment
export const REPORTED_PROBLEM_POST_TYPE = "prb"; // postType prb for reported problem
