import { useEffect, useState } from "react";

/**
 * The useMeasure hook uses the resize observer, but whenever
 * you import it in your component, the component was being
 * re-rendered 20+ times, to get the dimensions we can use
 * this hook
 * */
// eslint-disable-next-line import/prefer-default-export
const useMeasureOnResize = (ref: any): DOMRect | undefined => {
	const [rect, set] = useState(undefined);
	const [, setResize] = useState(0);

	useEffect(() => {
		if (ref.current && ref.current.getClientRects() && ref.current.getClientRects()[0]) {
			const canSet = JSON.stringify(rect) !== JSON.stringify(ref.current.getClientRects()[0]);
			if (canSet) {
				if (ref.current.getClientRects()[0]) {
					set(ref.current.getClientRects()[0]);
				}
			}
		}
	});

	useEffect(() => {
		const handleResize = () => {
			setResize(Math.random());
		};

		if (window) {
			window.addEventListener("resize", handleResize);
		}

		return () => {
			if (window) {
				window.removeEventListener("resize", handleResize);
			}
		};
	}, []);

	return rect;
};

export default useMeasureOnResize;
