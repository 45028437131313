export enum HintAlignment {
	TOP_LEFT = "top-left",
	TOP_RIGHT = "top-right",
	BOTTOM_LEFT = "bottom-left",
	BOTTOM_RIGHT = "bottom-right",
}

export interface Anchor {
	locatorProc: string;
	xOffset: number,
	yOffset: number,
	alignment: HintAlignment,
	fixed?: boolean,
	animate: boolean,
}

export enum DisplayModes {
	CREDO = "credo",
	EGO = "ego",
	BOTH = "both",
}

export interface HintPresentation {
	/**
	 * mode in which this hint should be shown
	 * credo mode or ego mode or both
	 * */
	mode: DisplayModes;
	trigger: string;
	/**
	 * Display the component after this much delay
	 * time in ms eg: 3000 for 3 seconds
	 * */
	delay: number
	/**
	 * AutoHide the component after this much time.
	 * time in ms eg: 3000 for 3 seconds
	 * if never then user has to close the hint
	 * */
	autoHide: number | "never";
}

export enum HintActions {
	REMOVE = "r",
	REMOVE_TRIGGER = "remove_trigger",
	SHOW = "show",
	BODY_UPDATED = "body_updated"
}

export interface HintsData {
	id: string;
	anchor: Anchor,
	content: {
		title: string;
		body: string;
		buttonTitle?: string;
		onClickButton?: () => void;
		onCloseHint?: () => void;
	},
	present: HintPresentation,
}

export interface DOMRectExtended extends DOMRect {
	zIndex: string | number;
}

export type HintsDataParsed = Map<HintPresentation["trigger"], HintsData[]>;

interface RemoveEventMessage {
	id: string;
	action: HintActions,
}

interface ShowEventMessage {
	trigger: string;
	action: HintActions,
}

export type HintsEventMessage = RemoveEventMessage & ShowEventMessage
