import React, { SVGAttributes } from "react";

// eslint-disable-next-line no-undef
const ContentSensitive = (props: SVGAttributes<SVGElement>) => {
	const { xlinkTitle } = props;
	return (
		<svg
			width="23px"
			height="21px"
			viewBox="0 0 23 21"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<title>{xlinkTitle}</title>
			<g id="Home-/-Feed---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Home-Feed---Credo---Graphic-Content" transform="translate(-178.000000, -251.000000)" stroke="var(--gray-dark)">
					<g id="image_sensative_content" transform="translate(178.000000, 252.000000)">
						<path d="M0.584795322,9.7477193 C0.584795322,9.7477193 4.59766082,4.2225731 10.902924,4.2225731 C17.2070175,4.2225731 21.6374269,9.7477193 21.6374269,9.7477193 L21.6374269,9.78865497 C21.6374269,9.78865497 17.6245614,15.3138012 11.3192982,15.3138012 C5.01520468,15.3138012 0.584795322,9.78865497 0.584795322,9.78865497 L0.584795322,9.7477193 Z" id="Stroke-1" />
						<path d="M14.5030409,9.77017544 C14.5030409,11.6438596 12.9849123,13.1619883 11.1112281,13.1619883 C9.23754386,13.1619883 7.7194152,11.6438596 7.7194152,9.77017544 C7.7194152,7.89649123 9.23754386,6.37836257 11.1112281,6.37836257 C12.9849123,6.37836257 14.5030409,7.89649123 14.5030409,9.77017544 Z" id="Stroke-3" />
						<line x1="20.4678363" y1="0.413450292" x2="1.75438596" y2="19.1269006" id="Stroke-5" strokeLinecap="round" />
					</g>
				</g>
			</g>
		</svg>
	);
};

export default ContentSensitive;
