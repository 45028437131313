/* eslint-disable import/prefer-default-export */
export const styleMapForEditor = {
	BOLD: {
		fontWeight: "unset",
	},
	UNDERLINE: {
		textDecorationLine: "unset",
	},
	ITALIC: {
		fontStyle: "unset",
	},
	CODE: {
		backgroundColor: "unset",
		fontFamily: "unset",
		fontSize: "unset",
		padding: "unset",
	},
	STRIKETHROUGH: {
		textDecoration: "unset",
	},
	SUPERSCRIPT: {
		verticalAlign: "unset",
	},
	SUBSCRIPT: {
		verticalAlign: "unset",
	},
};
