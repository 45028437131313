import React, { SVGAttributes } from "react";

const Profile = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { stroke = "var(--gray-dark)", fill } = props;
	return (
		<svg width="18px" height="20px" viewBox="0 0 18 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
			<g id="Web-Version" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Web-Version-Mobile---Home-Credo" transform="translate(-21.000000, -337.000000)" stroke={stroke} fill={fill}>
					<g id="pop-up-copy-3" transform="translate(20.000000, 85.000000)">
						<g id="profile-icon" transform="translate(2.000000, 253.000000)">
							<path d="M7.99956743,8 C10.2056883,8 12,6.2053008 12,4 C12,1.7938578 10.2056883,0 7.99956743,0 C5.79431167,0 4,1.7938578 4,4 C4,6.2053008 5.79431167,8 7.99956743,8 Z" id="Stroke-1"/>
							<path d="M0,18.5 L0.038,17.93 C0.408,12.695 2.479,10.575 3.68,9.772 L3.973,9.576 L4.27,9.795 L8,12.967 L11.73,9.795 L12.027,9.576 L12.32,9.772 C13.521,10.575 15.592,12.695 15.962,17.93 L16,18.5 L0,18.5 Z" id="Stroke-3"/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}

export default Profile;
