/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGAttributes } from "react";

const ProfileUnblock = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--primary)",
	} = props;
	return (
		<svg
			width="20px"
			height="20px"
			viewBox="0 0 20 20"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<title>profile_unblock</title>
			<g id="Profile---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-icons" transform="translate(-328.000000, -884.000000)" stroke={stroke}>
					<g id="profile_unblock" transform="translate(329.000000, 885.000000)">
						<circle id="Oval" cx="9" cy="9" r="9" />
						<g
							id="Group-8"
							transform="translate(9.000000, 9.000000) rotate(-45.000000) translate(-9.000000, -9.000000) translate(5.000000, 5.000000)"
							strokeLinecap="round"
						>
							<line x1="0" y1="0" x2="8" y2="8" id="Line-5" />
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default ProfileUnblock;
