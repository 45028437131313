import React, {
	useState, useCallback, useMemo,
} from "react";

import Lightbox from "react-image-lightbox";
import {
	ImagePost,
} from "./ImagePost";
import "react-image-lightbox/style.css";
import { ReadMoreText } from "../ReadMoreText";

const createPhotoIds = (photos: any) => photos.map((data: any, i: number) => ({ ...data, id: i }));

const createLayoutPhotoMaps = (layout: any, photos: any) => {
	const newPhotos = createPhotoIds(photos);
	const newMaps: any = {};
	layout.reduce((accumulator: any, currentValue: any, currentIndex: any) => {
		newMaps[currentIndex] = newPhotos.slice(accumulator, accumulator + currentValue);
		return accumulator + currentValue;
	}, 0);

	return newMaps;
};

interface Photos {
	source: string,
	isCompressing?: boolean,
	progress?: number,
}

interface ImageCollageProps {
	width?: string;
	height?: Array<string>;
	layout: Array<number>;
	photos: Array<Photos>;
	isContentRestricted?: boolean;
	imageErrorMsg: string;
	contentSensitiveMsg: string;
	caption: string;
	showRemoveIcon?: boolean;
	removeImage?: (img: string) => void;
	imgErrorStyle?: string;
}

const ImageCollage: React.FC<ImageCollageProps> = (props) => {
	const {
		width = "800px",
		height = ["250px"],
		layout,
		photos,
		isContentRestricted = false,
		imageErrorMsg,
		caption,
		contentSensitiveMsg,
		showRemoveIcon,
		removeImage,
		imgErrorStyle,
	} = props;
	const [viewerIsOpen, setViewerIsOpen] = useState<boolean>(false);
	const [currentImage, setCurrentImage] = useState<number>(0);

	const imageViewerData = useMemo(() => photos.map((img: Photos) => img.source), [photos]);
	const layoutPhotoMaps = useMemo(() => createLayoutPhotoMaps(layout, photos), [photos]);

	const openLightbox = (img: string) => {
		const imgIndex = imageViewerData.findIndex((item) => item === img);
		setCurrentImage(imgIndex !== -1 ? imgIndex : 0);
		setViewerIsOpen(true);
	};

	const closeLightbox = useCallback(() => {
		setCurrentImage(0);
		setViewerIsOpen(false);
	}, []);

	if (Object.keys(layoutPhotoMaps).length) {
		return (
			// https://github.com/frontend-collective/react-image-lightbox/issues/60 added tabIndex=1
			// eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex,jsx-a11y/tabindex-no-positive
			<div tabIndex={1}>
				<ImagePost
					width={width}
					height={height}
					layout={layout}
					layoutPhotoMaps={layoutPhotoMaps}
					openLightbox={openLightbox}
					isContentRestricted={isContentRestricted}
					imageErrorMsg={imageErrorMsg}
					contentSensitiveMsg={contentSensitiveMsg}
					noOfPhotos={photos?.length ?? 0}
					showRemoveIcon={showRemoveIcon}
					removeImage={removeImage}
					imgErrorStyle={imgErrorStyle}
				/>
				{
					viewerIsOpen && imageViewerData.length > 0
						? (
							<Lightbox
								mainSrc={imageViewerData[currentImage]}
								nextSrc={imageViewerData[(currentImage + 1) % imageViewerData.length]}
								prevSrc={imageViewerData[(currentImage + imageViewerData.length - 1) % imageViewerData.length]}
								onCloseRequest={closeLightbox}
								onMovePrevRequest={() => setCurrentImage((currentImage + imageViewerData.length - 1) % imageViewerData.length)}
								onMoveNextRequest={() => setCurrentImage((currentImage + 1) % imageViewerData.length)}
								imageCaption={
									caption ? (
										<ReadMoreText
											text={caption}
											numberOfLines={7}
											textClasses="text-gray-lighter"
										/>
									) : null
								}
								wrapperClassName="bg-modal"
								reactModalStyle={{ backgroundColor: "var(--bg-modal)" }}
							/>
						) : null
				}
			</div>
		);
	}

	return null;
};

ImageCollage.defaultProps = {
	width: "800px",
	height: ["250px"],
	isContentRestricted: false,
	showRemoveIcon: false,
	removeImage: (img) => { },
	imgErrorStyle: "",
};

export default ImageCollage;
