/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGAttributes } from "react";

const StreamEgoOnly = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--gray-dark)",
	} = props;
	return (
		<svg
			width="21px"
			height="20px"
			viewBox="0 0 21 20"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<g id="Streams---CT---190723" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-icons" transform="translate(-161.000000, -390.000000)" stroke={stroke}>
					<g id="stream_ego_only" transform="translate(161.000000, 390.000000)">
						<path
							d="M13.301,12.2773 C13.301,15.8123 10.435,18.6783 6.9,18.6783 C3.366,18.6783 0.5,15.8123 0.5,12.2773 C0.5,8.7423 3.366,5.8763 6.9,5.8763 C10.435,5.8763 13.301,8.7423 13.301,12.2773 Z"
							id="Stroke-1"
						/>
						<path
							d="M6.8999,12.4123 C8.1149,12.4123 9.1029,11.4243 9.1029,10.2093 C9.1029,8.9943 8.1149,8.0063 6.8999,8.0063 C5.6859,8.0063 4.6979,8.9943 4.6979,10.2093 C4.6979,11.4243 5.6859,12.4123 6.8999,12.4123 Z"
							id="Stroke-3"
						/>
						<path
							d="M3.115,17.1212 C3.291,14.8442 4.276,13.9222 4.847,13.5722 L4.986,13.4872 L5.127,13.5822 L6.9,14.9622 L8.673,13.5822 L8.814,13.4872 L8.953,13.5722 C9.524,13.9222 10.509,14.8452 10.685,17.1222"
							id="Stroke-5"
						/>
						<path
							d="M10.4794,6.9589 C10.6544,5.5569 11.8514,4.4719 13.3014,4.4719 C14.8714,4.4719 16.1444,5.7449 16.1444,7.3149 C16.1444,8.8859 14.8714,10.1589 13.3014,10.1589 C13.1804,10.1589 13.0614,10.1519 12.9444,10.1369"
							id="Stroke-7"
						/>
						<line x1="13.301" y1="2.7422" x2="13.301" y2="0.0002" id="Stroke-9" />
						<line x1="17.8714" y1="7.3126" x2="20.6134" y2="7.3126" id="Stroke-11" />
						<line x1="16.5328" y1="4.0808" x2="18.4718" y2="2.1418" id="Stroke-13" />
						<line x1="16.5328" y1="10.5443" x2="18.4718" y2="12.4833" id="Stroke-15" />
						<line x1="8.1303" y1="2.1418" x2="10.0693" y2="4.0808" id="Stroke-17" />
					</g>
				</g>
			</g>
		</svg>
	);
};

export default StreamEgoOnly;
