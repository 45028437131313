import { YesNoOptions } from "@credo/utilities";
import { makeApiRequest } from "../../api";
import { GET_SUSPENDED_USERS_URL, SUSPEND_USER_URL, UPDATE_USER_DETAILS_URL } from "../../api/requestsUrl";

export interface suspendUserRequestObject {
	user_id: string;
	reason: string;
	days: number;
	firstName: string;
	lastName: string;
	username: string;
}

export async function suspendUserRequest(request: suspendUserRequestObject) {
	try {
		const res = await makeApiRequest({
			method: "post",
			url: SUSPEND_USER_URL,
			body: {
				user_id: request.user_id,
				reason: request.reason,
				days: request.days,
				suspended_user_fName: request.firstName,
				suspended_user_lName: request.lastName,
				suspended_user_uName: request.username,
			},
		});
		return {
			code: 200,
			response: res,
		};
	} catch (err) {
		return {
			code: 500,
			error: err,
		};
	}
}

export async function getSuspendedUsersRequest(user_id?: string | null) {
	try {
		const res = await makeApiRequest({
			method: "get",
			url: user_id ? `${GET_SUSPENDED_USERS_URL}/${user_id}` : `${GET_SUSPENDED_USERS_URL}/all`,
		});
		return {
			code: 200,
			response: res,
		};
	} catch (err) {
		return {
			code: 500,
			error: err,
		};
	}
}
export interface upadteUserDetailsRequestObject {
	user_id: string;
	firstName: string;
	lastName: string;
	egousername: string;
	descr: string;
	resetCoverPic: YesNoOptions;
	resetProfilePic: YesNoOptions;
	isbot: YesNoOptions;
}

export async function upadteUserDetails(request: upadteUserDetailsRequestObject) {
	try {
		const res = await makeApiRequest({
			method: "post",
			url: UPDATE_USER_DETAILS_URL,
			body: {
				user_id: request.user_id,
				firstName: request.firstName,
				lastName: request.lastName,
				egousername: request.egousername,
				descr: request.descr,
				resetCoverPic: request.resetCoverPic,
				resetProfilePic: request.resetProfilePic,
				isbot: request.isbot,
			},
		});
		return {
			code: 200,
			response: res,
		};
	} catch (err) {
		return {
			code: 500,
			error: err,
		};
	}
}
