import React from "react";
import isNil from "lodash/isNil";
import { cu } from "@credo/utilities";
import { ReadMoreText } from "../ReadMoreText";
import { classNames } from "../../common";

const POST_TEXT_LINE_HEIGHT = 24;

export interface PostTextProps {
	item: any;
	isSharedPost?: boolean;
	navigateToMentionedUser?: (data: any) => void;
	navigateToDetails?: () => void;
	showHandIcon?: boolean;
	/**
	 * set true if the component is being called inside
	 * PostCompactView or need a compact version of this
	 * component
	 *
	 * @default false
	 * */
	// eslint-disable-next-line react/require-default-props
	isCompactView?: boolean;
}

export const PostText = (props: PostTextProps) => {
	const {
		item, isSharedPost, navigateToMentionedUser, navigateToDetails, showHandIcon, isCompactView = false,
	} = props;

	const getPostText = () => {
		if (isSharedPost) {
			return !isNil(item["p.shared_postText"]) ? item["p.shared_postText"] : item["p.shared_postTitle"] ?? "";
		}
		return !isNil(item["p.postText"]) ? item["p.postText"] : item["p.postTitle"] ?? "";
	};

	const getPostTextId = () => {
		if (isSharedPost) {
			return item["p.shared_postUuid"];
		}
		return item["p.postUuid"];
	};

	const handleOnClickPost = () => {
		if (window) {
			/**
			 * When user selects any text, user should not be navigated
			 * so to check that the selected string should be 0 only then
			 * we will navigate user to other screen.
			 * */
			const textSelectionLength = window.getSelection()?.toString();
			if (!(cu.isSet(textSelectionLength) && textSelectionLength && textSelectionLength?.length > 0)) {
				if (navigateToDetails) {
					navigateToDetails();
				}
			}
		}
	};

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
		<div
			className={classNames(
				"w-full bg-background",
				isSharedPost || showHandIcon ? "cursor-pointer" : "",
				isSharedPost ? "bg-content-L1 mb-4" : "bg-background pb-4",
				// eslint-disable-next-line no-nested-ternary
				isCompactView ? (isSharedPost ? "mb-1 !px-1.5" : "pb-2 mobile-compact:pb-4") : "",
				isCompactView ? "px-2 mobile-compact:px-4" : "px-4",
			)}
			onClick={handleOnClickPost}
		>
			{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
			{/* <div
				onKeyDown={() => {}}
				onClick={handleClickOnTitle}
			>
				<p className="text-lg font-medium text-title-color whitespace-pre-wrap break-words cursor-pointer">
					{getPostTitle()}
				</p>
			</div> */}
			{cu.isSet(item["p.postText"]) || cu.isSet(item["p.shared_postText"])
				|| cu.isSet(item["p.postTitle"]) || cu.isSet(item["p.shared_postTitle"])
				? (
					<ReadMoreText
						textId={getPostTextId()}
						text={getPostText()}
						numberOfLines={isCompactView ? 2 : 7}
						textLineHeight={POST_TEXT_LINE_HEIGHT}
						navigateToMentionedUser={navigateToMentionedUser}
						textClasses={classNames(
							isCompactView ? "text-basic text-base-15 pt-0" : "text-basic",
						)}
					/>
				) : null}
		</div>
	);
};

PostText.defaultProps = {
	isSharedPost: false,
	showHandIcon: false,
	navigateToMentionedUser: () => { },
	navigateToDetails: () => { },
};
