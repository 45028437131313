import React, { SVGAttributes } from "react";

// eslint-disable-next-line import/prefer-default-export
const StreamJoinRequests = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--gray-dark)",
	} = props;
	return (
		<svg
			width="22px"
			height="21px"
			viewBox="0 0 22 21"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<g id="Streams---CT---190723" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-icons" transform="translate(-374.000000, -536.000000)" stroke={stroke}>
					<g id="streamJoinRequests" transform="translate(375.000000, 537.000000)">
						<g id="Group-8" transform="translate(10.207107, 7.792893) rotate(45.000000) translate(-10.207107, -7.792893) translate(8.207107, 5.292893)" strokeLinecap="round">
							<line x1="-2.27373675e-13" y1="0.792893219" x2="4" y2="4.79289322" id="Line-5" />
							<line x1="-2.27373675e-13" y1="0.792893219" x2="4" y2="4.79289322" id="Line-5" transform="translate(2.000000, 2.792893) scale(-1, 1) translate(-2.000000, -2.792893) " />
						</g>
						<path d="M10.2075998,0 C15.6157931,0 20,3.53400181 20,7.89341397 C20,12.2528261 15.6157931,15.7868279 10.2075998,15.7868279 C9.37504531,15.7868279 8.5667578,15.7030774 7.79499607,15.5454578 C4.30936039,19.0582522 -1.6205281,20.0659858 0.415199532,17.7601814 C1.53222894,16.4949575 2.41033798,15.001205 3.04952666,13.278924 C1.41495757,11.8687029 0.415199532,9.97517015 0.415199532,7.89341397 C0.415199532,3.53400181 4.79940645,0 10.2075998,0 Z" id="Combined-Shape" />
					</g>
				</g>
			</g>
		</svg>
	);
};

export default StreamJoinRequests;
