import * as React from "react"
import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => {
	const { stroke = "var(--primary)" } = props;
	return (<svg
		width={91}
		height={108}
		viewBox="0 0 91 108"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g stroke={stroke} fill="none" fillRule="evenodd">
			<path
				d="M85.582 42.685c2.09-6.412-1.37-13.291-7.728-15.365M89.98 31.608c-.93-8.734-8.706-15.067-17.369-14.145M21.999 32.12v39.488L.499 58.964M.5.5l21.499 12.644V28.16"/>
			<path
				d="m31.578 18.568-2.976 2.976 6.367 6.367H16.5v4.21h18.47l-6.367 6.366 2.976 2.976 11.447-11.448zM63.801 60.76l6.764-23.197c.63-2.16-.137-4.872-2.796-5.648-.865-.252-2.21-.424-3.909.988-.575.479-.98 1.195-1.309 2.325l-2.732 9.37"/>
			<path
				d="m71.126 65.46 6.764-23.198c.63-2.16-.137-4.872-2.796-5.648-.865-.252-2.21-.424-3.909.988-.575.48-.98 1.195-1.309 2.325l-2.732 9.37"/>
			<path
				d="M61.868 104.725c-.799-2.676-1.062-5.499-.763-8.397.661-6.395 3.814-5.849 8.545-8.791 2.947-1.833 5.139-4.82 6.108-8.141l7.888-27.05c.629-2.16-.137-4.872-2.796-5.648-.866-.252-2.212-.424-3.909.988-.576.479-.98 1.195-1.31 2.325l-2.732 9.37M51.548 68.294c5.162 1.92 8.506 7.813 7.506 13.228"/>
			<path
				d="m66.874 67.657-7.086-2.066a5.847 5.847 0 0 1-3.976-7.25l5.62-19.274c.629-2.157-.138-4.87-2.8-5.646-.864-.252-2.211-.424-3.908.988-.57.473-.985 1.21-1.31 2.323l-7.661 26.276c-.511 1.752-3.035 1.625-3.368-.17l-1.271-6.846c-.46-2.473-2.995-4.162-5.536-3.69-2.541.472-4.302 2.958-3.842 5.43l3.23 17.315c.634 3.41 2.83 6.192 5.68 8.045 2.925 1.902-.868 10.432 2.703 21.638 0 0 8.513 5.217 18.519-.005M31.662 58.964h-9.663"/>
			<path d="M.5 58.964V.5h46.692v57.57M47.192 58.964h-5.527"/>
		</g>
	</svg>)
}

export default SvgComponent
