import { makeApiRequest } from "../../api";
import { LOGIN_URL, LOGOUT_URL } from "../../api/requestsUrl";
import { SID } from "../../routes/constants";
import { getCookie } from "../../utils/cookieUtils";

export interface googleLoginRequestObject {
	email: string;
	uuid: string;
	otp?: string;
}

export async function googleLoginRequest(request: googleLoginRequestObject) {
	try {
		const res = await makeApiRequest({
			method: "post",
			url: LOGIN_URL,
			body: {
				email: request.email,
				uuid: request.uuid,
				otp: request.otp,
			},
		});
		return {
			code: 200,
			response: res,
		};
	} catch (err: any) {
		return {
			code: err?.code,
			error: err,
		};
	}
}

export async function logout() {
	const details = localStorage.getItem("userDetails");
	const userDetails = details ? JSON.parse(details || "{}") : null;
	try {
		const res = await makeApiRequest({
			method: "post",
			url: LOGOUT_URL,
			body: {
				email: userDetails?.email,
				sid: getCookie(SID),
				uuid: userDetails?.uuid,
			},
		});
		return {
			code: 200,
			response: res,
		};
	} catch (err) {
		return {
			code: 500,
			error: err,
		};
	}
}
