/* eslint-disable react/require-default-props */
import React, {
	useEffect, useRef, useState,
} from "react";
import { IoMdClose } from "react-icons/io";
import { toast } from "react-toastify";
import {
	CoverPicture, Modal, ProfilePicture, ProfileSize, SnackBarTypeOptions,
} from "@credo/ui-components";
import { cu, YesNoOptions } from "@credo/utilities";
import classNames from "../../../utils/classNames";
import { strings } from "../../../i18n/config";
import Input from "../../../gen-comp/Input";
import { upadteUserDetails } from "../UserRequests";
import { successRETCD } from "../../../api";
import ToastMsg from "../../../gen-comp/ToastMsg";
import ConfirmationModal from "../../../gen-comp/ConfirmationModal";
import "../../../gen-comp/css/table.css";
import Assets from "../../../assets/images/Assets";

interface EditUserDetailsProps {
	isVisible: boolean;
	cancel_button_function: () => void;
	okButtonStyle?: string;
	cancelButtonStyle?: string;
	userDetails: any;
	resetUserDetails: (data: any) => void;
}

export default function EditUserDetailsModal({
	isVisible,
	cancel_button_function,
	okButtonStyle,
	cancelButtonStyle,
	userDetails,
	resetUserDetails,
}: EditUserDetailsProps) {
	const cancelButtonRef = useRef(null);
	const [firstName, setFirstName] = useState(userDetails?.firstName);
	const [lastName, setLastName] = useState(userDetails?.lastName);
	const [egousername, setEgousername] = useState(userDetails?.egousername);
	const [description, setDescription] = useState(userDetails?.edescr);
	const [isDisabled, setIsDisabled] = useState(true);
	const [isResetProfilePic, setIsResetProfilePic] = useState(false);
	const [isResetCoverPic, setIsResetCoverPic] = useState(false);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);

	const containsNumber = (text: string) => {
		const format = /.*[0-9].*/;
		return format.test(text);
	};

	const checkForeignCharInName = (text: string) => {
		const format = /[^0-9a-zA-Z .-]/g;
		return format.test(text);
	};

	const isValidName = (text: string) => {
		const format = /[!@#$%^&*()_+=[\]{};':"\\|,.<>/?]/;
		return format.test(text) || containsNumber(text);
	};

	const validateInput = () => {
		if (
			userDetails?.firstName === firstName
			&& userDetails?.lastName === lastName
			&& userDetails?.egousername === egousername
			&& userDetails?.edescr === description
			&& !isResetProfilePic
			&& !isResetCoverPic
		) {
			setIsDisabled(true);
		} else if (
			!firstName
			|| !lastName
			|| !egousername
			|| isValidName(firstName)
			|| isValidName(lastName)
			|| checkForeignCharInName(firstName)
			|| checkForeignCharInName(lastName)
			|| checkForeignCharInName(egousername)
		) {
			setIsDisabled(true);
		} else {
			setIsDisabled(false);
		}
	};

	const onFirstNameChange = (e: any) => {
		const text = e.target.value.replace(/\s\s+/g, " ").replace(/\u2013+|\u2014+/g, "-").replace(/- -/g, "");
		setFirstName(text);
	};

	const onLastNameChange = (e: any) => {
		const text = e.target.value.replace(/\s\s+/g, " ").replace(/\u2013+|\u2014+/g, "-").replace(/- -/g, "");
		setLastName(text);
	};

	const onUsernameChange = (e: any) => {
		setEgousername(e.target.value);
	};

	const onDescriptionChange = (e: any) => {
		setDescription(e.target.value);
	};

	const resetData = () => {
		setIsDisabled(true);
		setFirstName(userDetails?.firstName);
		setLastName(userDetails?.lastName);
		setEgousername(userDetails?.egousername);
		setDescription(userDetails?.edescr);
		setIsResetCoverPic(false);
		setIsResetProfilePic(false);
	};

	useEffect(() => {
		resetData();
	}, [userDetails]);

	const closeModal = () => {
		resetData();
		if (cancel_button_function) cancel_button_function();
	};

	const getLastNameValidationError = () => {
		if (!lastName) {
			return strings("EditUserDetailsModal.required");
		} if (checkForeignCharInName(lastName)) {
			return strings("EditUserDetailsModal.eng_chars_only");
		} if (isValidName(lastName)) {
			return strings("EditUserDetailsModal.please_enter_valid_name");
		}
		return "";
	};

	const getEgoUsernameValidationError = () => {
		if (!egousername) {
			return strings("EditUserDetailsModal.required");
		} if (checkForeignCharInName(egousername)) {
			return strings("EditUserDetailsModal.eng_chars_only");
		}
		return "";
	};

	const getFirstNameValidationError = () => {
		if (!firstName) {
			return strings("EditUserDetailsModal.required");
		} if (checkForeignCharInName(firstName)) {
			return strings("EditUserDetailsModal.eng_chars_only");
		} if (isValidName(firstName)) {
			return strings("EditUserDetailsModal.please_enter_valid_name");
		}
		return "";
	};

	const closeConfirmationModal = () => {
		setShowConfirmationModal(false);
	};

	const updateUserDetails = async () => {
		const res = await upadteUserDetails({
			user_id: userDetails?.user_id || "",
			firstName: firstName?.trim(),
			lastName: lastName?.trim(),
			egousername: egousername?.trim(),
			descr: description?.trim() || " ",
			isbot: userDetails?.isbot,
			resetCoverPic: isResetCoverPic ? YesNoOptions.YES : YesNoOptions.NO,
			resetProfilePic: isResetProfilePic ? YesNoOptions.YES : YesNoOptions.NO,
		});
		if (res && res.code === 200) {
			const data = res?.response;
			if (data && data.retcd === successRETCD && !data.info) {
				resetUserDetails({
					...userDetails,
					firstName: firstName?.trim(),
					lastName: lastName?.trim(),
					egousername: egousername?.trim(),
					edescr: description?.trim(),
					eprofilePicRelUrl: isResetProfilePic ? "" : userDetails?.eprofilePicRelUrl,
					ecoverPicRelUrl: isResetCoverPic ? "" : userDetails?.ecoverPicRelUrl,
				});
				toast(
					<ToastMsg
						message={strings("EditUserDetailsModal.success_message")}
						showButton
						type={SnackBarTypeOptions.SUCCESS}
					/>,
				);
			} else if (data?.info === "DUPLICATE") {
				toast(
					<ToastMsg
						message={strings("EditUserDetailsModal.duplicate_error_message")}
						showButton
						type={SnackBarTypeOptions.ERROR}
					/>,
				);
			} else {
				toast(
					<ToastMsg
						message={strings("EditUserDetailsModal.error_message")}
						showButton
						type={SnackBarTypeOptions.ERROR}
					/>,
				);
			}
		} else {
			toast(
				<ToastMsg
					message={strings("EditUserDetailsModal.error_message")}
					showButton
					type={SnackBarTypeOptions.ERROR}
				/>,
			);
		}
		closeConfirmationModal();
		closeModal();
	};

	const onSubmit = () => {
		setShowConfirmationModal(true);
	};

	useEffect(() => {
		validateInput();
	}, [firstName, lastName, egousername, description, isResetCoverPic, isResetProfilePic]);

	const renderUserDetailsChanges = () => (
		<div className="flex py-5 w-full flex-col">
			<h5 className="text-sm text-gray-500">{strings("EditUserDetailsModal.changes")}</h5>
			<div className="flex flex-col w-full py-1">
				<div className="flex py-1 flex-row w-full items-start">
					<span className="text-sm text-gray-500 w-1/4 break-all">
						{ }
					</span>
					<span className="text-sm text-gray-500 w-1/3 text-center">
						{strings("EditUserDetailsModal.previous")}
					</span>
					<span className="text-sm text-gray-500 w-1/3 text-center">
						{strings("EditUserDetailsModal.current")}
					</span>
				</div>
			</div>
			<div className="flex flex-col w-full">
				<div className="flex py-1 flex-row w-full items-start">
					<span className="text-sm text-gray-500 w-1/4 break-all">
						{strings("EditUserDetailsModal.firstName")}
					</span>
					<span className="text-sm text-gray-dark w-1/3 break-all text-center pr-1">
						{userDetails?.firstName}
					</span>
					<span className="text-sm text-gray-dark w-1/3 break-all text-center pl-1">
						{firstName}
					</span>
				</div>
				<div className="flex py-1 flex-row w-full items-start">
					<span className="text-sm text-gray-500 w-1/4 break-all">
						{strings("EditUserDetailsModal.lastName")}
					</span>
					<span className="text-sm text-gray-dark w-1/3 break-all text-center pr-1">
						{userDetails?.lastName}
					</span>
					<span className="text-sm text-gray-dark w-1/3 break-all text-center pl-1">
						{lastName}
					</span>
				</div>
				<div className="flex py-1 flex-row w-full items-start">
					<span className="text-sm text-gray-500 w-1/4 break-all">
						{strings("EditUserDetailsModal.egousername")}
					</span>
					<span className="text-sm text-gray-dark w-1/3 break-all text-center pr-1">
						{userDetails?.egousername}
					</span>
					<span className="text-sm text-gray-dark w-1/3 break-all text-center pl-1">
						{egousername}
					</span>
				</div>
				<div className="flex py-1 flex-row w-full items-start">
					<span className="text-sm text-gray-500 w-1/4 break-all">
						{strings("EditUserDetailsModal.description")}
					</span>
					<span className="text-sm text-gray-dark w-1/3 break-all text-center pr-1">
						{userDetails?.edescr}
					</span>
					<span className="text-sm text-gray-dark w-1/3 break-all text-center pl-1">
						{description}
					</span>
				</div>
				<div className="flex py-1 flex-row w-full items-start">
					<span className="text-sm text-gray-500 w-1/4 break-all">
						{strings("EditUserDetailsModal.cover_picture")}
					</span>
					<span className="flex w-1/3 items-center justify-center px-2 w-1/3">
						<CoverPicture
							coverPicUrl={userDetails?.ecoverPicRelUrl
								? (cu.buildSourceUrlImage(userDetails.ecoverPicRelUrl) ?? Assets.UserProfileCoverEgo)
								: Assets.UserProfileCoverEgo}
							coverPicStyle="h-20"
						/>
					</span>
					<span className="flex w-1/3 items-center justify-center px-2 w-1/3">
						<CoverPicture
							coverPicUrl={userDetails?.ecoverPicRelUrl && !isResetCoverPic
								? (cu.buildSourceUrlImage(userDetails.ecoverPicRelUrl) ?? Assets.UserProfileCoverEgo)
								: Assets.UserProfileCoverEgo}
							coverPicStyle="h-20"
						/>
					</span>
				</div>
				<div className="flex py-1 flex-row w-full items-start">
					<span className="text-sm text-gray-500 w-1/4 break-all">
						{strings("EditUserDetailsModal.profile_picture")}
					</span>
					<div className="flex w-1/3 items-center justify-center">
						<ProfilePicture
							profilePicUrl={userDetails?.eprofilePicRelUrl
								? (cu.buildSourceUrlImage(userDetails.eprofilePicRelUrl) || "") : ""}
							profilePicStyle="rounded-full"
							size={ProfileSize.SMALL}
						/>
					</div>
					<span className="flex w-1/3 items-center justify-center">
						<ProfilePicture
							profilePicUrl={userDetails?.eprofilePicRelUrl && !isResetProfilePic
								? (cu.buildSourceUrlImage(userDetails.eprofilePicRelUrl) || "") : ""}
							profilePicStyle="rounded-full"
							size={ProfileSize.SMALL}
						/>
					</span>
				</div>
			</div>
		</div>
	);

	return (
		<div>
			<Modal
				onClose={cancel_button_function}
				show={isVisible}
				overlayClass="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
				hideCloseIcon
				dialogWrapperClassName="!p-0 flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0"
				dialogParentClassName="relative !z-10"
				dialogClassName="!p-0 sm:max-w-lg sm:w-full xs:w-full"
			>
				<div>
					<div className={classNames("modal-header flex flex-shrink-0 items-center",
						"justify-between p-4 border-b border-gray-200 rounded-t-md")}
					>
						<h5 className="text-xl font-medium leading-normal text-gray-800">
							{strings("EditUserDetailsModal.edit_user_details")}
						</h5>
						<IoMdClose onClick={cancel_button_function} size={20} />
					</div>
					<div className="flex flex-col p-5 justify-center">
						<span
							className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
						>
							{strings("EditUserDetailsModal.firstName")}
						</span>
						<div className="inline-block relative w-full">
							<Input
								value={firstName}
								wrapperClassName="mb-1"
								placeholder={strings("EditUserDetailsModal.firstName")}
								onChange={onFirstNameChange}
								error={getFirstNameValidationError()}
								maxLength={40}
							/>
							<span className="text-gray-dark text-sm flex justify-end">
								{`${firstName?.length}/40`}
							</span>
						</div>
						<span
							className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
						>
							{strings("EditUserDetailsModal.lastName")}
						</span>
						<div className="inline-block relative w-full">
							<Input
								value={lastName}
								placeholder={strings("EditUserDetailsModal.lastName")}
								onChange={onLastNameChange}
								wrapperClassName="mb-1"
								maxLength={40}
								error={getLastNameValidationError()}
							/>
							<span className="text-gray-dark text-sm flex justify-end">
								{`${lastName?.length}/40`}
							</span>
						</div>
						<span
							className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
						>
							{strings("EditUserDetailsModal.egousername")}
						</span>
						<div className="">
							<Input
								value={egousername}
								placeholder={strings("EditUserDetailsModal.egousername")}
								onChange={onUsernameChange}
								wrapperClassName="mb-1"
								maxLength={40}
								error={getEgoUsernameValidationError()}
							/>
							<span className="text-gray-dark text-sm flex justify-end">
								{`${egousername?.length}/40`}
							</span>
						</div>
						<span
							className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
						>
							{strings("EditUserDetailsModal.description")}
						</span>
						<div className="inline-block relative w-full">
							<textarea
								value={description}
								maxLength={250}
								className={classNames(
									"w-full bg-white bg-clip-padding h-20 normal-text-style",
									"form-control block px-3 py-1.5 text-sm font-normal text-gray-700",
									"border border-solid border-gray-300 rounded-md transition ease-in-out m-0",
									"focus:text-gray-700 focus:bg-white focus:border-primary focus:outline-none",
								)}
								placeholder={strings("EditUserDetailsModal.description")}
								onChange={onDescriptionChange}
							/>
							<span className="text-gray-dark text-sm flex justify-end">
								{`${description?.length}/250`}
							</span>
						</div>
						<div className="relative w-full flex flex-row items-start">
							<div className="w-full flex flex-row items-start">
								<Input
									type="checkbox"
									value="true"
									className={classNames("px-3")}
									onChange={() => setIsResetProfilePic(!isResetProfilePic)}
								/>
								<span className="text-gray-dark text-sm px-3">
									{strings("EditUserDetailsModal.reset_profile_picture")}
								</span>
							</div>
							<div className="w-full flex flex-row items-start">
								<Input
									type="checkbox"
									value="true"
									className={classNames("px-3")}
									onChange={() => setIsResetCoverPic(!isResetCoverPic)}
								/>
								<span className="text-gray-dark text-sm px-3">
									{strings("EditUserDetailsModal.reset_cover_picture")}
								</span>
							</div>
						</div>
					</div>
					<div
						className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse"
					>
						<div data-testid="ok-button-0">
							<button
								type="button"
								className={classNames("w-full inline-flex justify-center rounded-md border",
									"shadow-sm px-4 py-2 bg-primary text-base hover:bg-blue-400 sm:text-sm",
									"focus:outline-none border-transparent font-medium text-white",
									"disabled:opacity-50 disabled:ring-none",
									"focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto",
									okButtonStyle!)}
								disabled={isDisabled}
								onClick={onSubmit}
							>
								{strings("EditUserDetailsModal.edit")}
							</button>
						</div>
						<div data-testid="cancel-button-0">
							<button
								type="button"
								className={classNames("mt-3 w-full inline-flex justify-center rounded-md border ",
									"px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50",
									"border-gray-300 shadow-sm focus:ring-offset-2",
									"focus:outline-none focus:ring-2 focus:outline-none",
									"focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm ",
									cancelButtonStyle!)}
								onClick={closeModal}
								ref={cancelButtonRef}
							>
								{strings("EditUserDetailsModal.cancel")}
							</button>
						</div>
					</div>
				</div>
			</Modal>
			<ConfirmationModal
				id="update-user-details"
				isVisible={showConfirmationModal}
				title={strings("EditUserDetailsModal.update_user_details")}
				message={strings("EditUserDetailsModal.update_user_message")}
				ok_button_title={strings("EditUserDetailsModal.yes")}
				ok_button_function={updateUserDetails}
				cancel_button_title={strings("EditUserDetailsModal.no")}
				cancel_button_function={closeConfirmationModal}
				onClose={closeConfirmationModal}
				customComponent={renderUserDetailsChanges()}
			/>
		</div>
	);
}
