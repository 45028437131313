import React, { useEffect, useRef, useState } from "react";
import "../../gen-comp/css/table.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { toast } from "react-toastify";
import { isNaN } from "lodash";
import moment from "moment";
import { RefreshIcon } from "@credo/ui-components";
import Hero from "../../gen-comp/Hero";
import Filter from "../../gen-comp/Filter";
import { strings } from "../../i18n/config";
import classNames from "../../utils/classNames";
import DashboardStatCard from "../../gen-comp/DashboardStatCard";
import {
	getDashboardStatsData, currentDate, getDashboardRealTimeData, getEmotionReactionData,
} from "./DashboardRequests";
import { successRETCD } from "../../api";
import { DATE_FORMAT, formatAsNbWithThousandsWithDefault } from "../../utils/utils";
import ToastMsg from "../../gen-comp/ToastMsg";
import { SnackBarTypeOptions } from "../../utils/types";
import { useQuery } from "../../hooks/graphql/useQuery";

const filterData = [
	{
		id: 1,
		title: strings("Dashboard.30_days"),
		value: 30,
	},
	{
		id: 2,
		title: strings("Dashboard.7_days"),
		value: 7,
	},
	{
		id: 3,
		title: strings("Dashboard.24_hours"),
		value: 1,
	},
	{
		id: 4,
		title: strings("Dashboard.all"),
		value: 0,
	},
];

export default function DashboardPage() {
	const wrapperRef = useRef(null);
	const [usersStats, setUsersStats] = useState<any>([]);
	const [filter, setFilter] = useState(filterData);
	const [isLoading, setIsLoading] = useState(true);
	const [timestamp, setTimestamp] = useState(currentDate);
	const [data, setData] = useState<any>(null);
	const [realtimeData, setRealtimeData] = useState<any>({
		activeUserCntTillDate: 0,
		newUserCntTillDate: 0,
		connectRelationsTillDate: 0,
		newStreamsTillDate: 0,
		membersForStreamsTillDate: 0,
		totalRatingByUsrOnPostsTillDate: 0,
		totalRatingUserCntTillDate: 0,
		totalUserRatingOtherUsrTillDate: 0,
		cntOtherUsrRatedTillDate: 0,
	});

	const applyFilter = (items: any) => {
		setFilter(items);
	};

	const calculatePercentage = (value1: number, value2: number) => {
		const difference = (value1 - value2) / Math.max(value1, value2);
		const percentage = difference * 100;
		const perWith2Decimal = Math.round(percentage * 100) / 100;
		if (isNaN(perWith2Decimal) || Math.abs(perWith2Decimal) === Infinity) {
			return 0;
		}
		return perWith2Decimal;
	};

	const getDashboardRealTimeStatsData = async () => {
		try {
			const res = await getDashboardRealTimeData();
			if (res && res.code === 200) {
				const data = res?.response;
				if (data && data.retcd === successRETCD && data?.items && data?.items.length > 0) {
					setRealtimeData(data?.items[0]);
				} else {
					toast(<ToastMsg
						message={strings("Dashboard.no_data_found")}
						showButton
						type={SnackBarTypeOptions.ERROR}
					/>);
				}
			} else {
				toast(<ToastMsg
					message={strings("Dashboard.error_message")}
					showButton
					type={SnackBarTypeOptions.ERROR}
				/>);
			}
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			toast(
				<ToastMsg
					message={strings("Dashboard.error_message")}
					showButton
					type={SnackBarTypeOptions.SUCCESS}
				/>,
			);
		}
	};

	const attributeConstans = {
		usrCntTillDate: "newUserCntTillDate",
		activeUserCnt: "activeUserCntTillDate",
		newStream: "newStreamsTillDate",
		totalRatingByUsrOnPosts: "totalRatingByUsrOnPostsTillDate",
		totalRatingUserCnt: "totalRatingUserCntTillDate",
		totalUserRatingOtherUsr: "totalUserRatingOtherUsrTillDate",
		cntOtherUsrRated: "cntOtherUsrRatedTillDate",
	};

	const getRealTimeDataValueByAttributeName = (keyName: string) => {
		// @ts-ignore
		const attributeName = attributeConstans[keyName as keyof string] as string;
		if (attributeName) {
			return realtimeData[attributeName];
		}
		return "--";
	};

	const formatDashboardData = () => {
		const newData: any = [];
		const currentDayData = data?.dashboard_currDate ? JSON.parse(data?.dashboard_currDate) : null;
		const oneDayData = data?.dashboard_1 ? JSON.parse(data?.dashboard_1) : null;
		const sevenDayData = data?.dashboard_7 ? JSON.parse(data?.dashboard_7) : null;
		const therthyDayData = data?.dashboard_30 ? JSON.parse(data?.dashboard_30) : null;
		if (currentDayData && currentDayData.length > 0) {
			Object.entries(currentDayData[0]).forEach((obj: any, i) => {
				if (obj[0] === "days" || obj[0] === "usrCntTillDate") {
					return;
				}
				newData.push(
					{
						id: i,
						title: strings(`Dashboard.${obj[0]}`),
						data: [{
							period: currentDayData[0].days,
							value: currentDayData[0][obj[0]],
							percentage: oneDayData && oneDayData.length === 3
								? calculatePercentage(currentDayData[0][obj[0]], oneDayData[0][obj[0]])
								: null,
						},
						{
							period: currentDayData[1].days,
							value: currentDayData[1][obj[0]],
							percentage: sevenDayData && sevenDayData.length === 3
								? calculatePercentage(currentDayData[0][obj[0]], sevenDayData[1][obj[0]])
								: null,
						},
						{
							period: currentDayData[2].days,
							value: currentDayData[2][obj[0]],
							percentage: therthyDayData && therthyDayData.length === 3
								? calculatePercentage(currentDayData[0][obj[0]], therthyDayData[2][obj[0]])
								: null,
						},
						{
							period: 0,
							value: getRealTimeDataValueByAttributeName(obj[0]),
							percentage: null,
						}],
					},
				);
			});
		}
		const updatedNewData = [
			...newData,
			{
				id: newData.length,
				title: strings("Dashboard.connectRelationsTillDate"),
				data: [{
					period: 0,
					value: realtimeData.connectRelationsTillDate,
					percentage: null,
				}],
			},
			{
				id: newData.length + 1,
				title: strings("Dashboard.membersForStreamsTillDate"),
				data: [{
					period: 0,
					value: realtimeData.membersForStreamsTillDate,
					percentage: null,
				}],
			},
		];
		setUsersStats(updatedNewData);
	};

	const getDashboardData = async (timestamp?: number) => {
		try {
			const res = await getDashboardStatsData(0);
			if (res && res.code === 200) {
				const data = res?.response;
				if (data && data.retcd === successRETCD) {
					if (data && !data.dashboard_currDate) {
						// Get previous day's data when the current date dashboard data has not been processed yet
						const yesterdayTimestamp = new Date((timestamp || Date.now()) - 864e5).getTime();
						if (Math.ceil(Math.abs((new Date().getTime() - yesterdayTimestamp) / (1000 * 60 * 60 * 24))) === 1) {
							setTimestamp(data?.last_run_dt);
							getDashboardData(yesterdayTimestamp);
							return;
						}
					}
					setTimestamp(data?.last_run_dt);
					setData(data);
				} else {
					toast(<ToastMsg
						message={strings("Dashboard.no_data_found")}
						showButton
						type={SnackBarTypeOptions.ERROR}
					/>);
				}
			} else {
				toast(<ToastMsg
					message={strings("Dashboard.error_message")}
					showButton
					type={SnackBarTypeOptions.ERROR}
				/>);
			}
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			toast(
				<ToastMsg
					message={strings("Dashboard.error_message")}
					showButton
					type={SnackBarTypeOptions.SUCCESS}
				/>,
			);
		}
	};

	const [getEmojiData, { data: emojiData }] = useQuery(getEmotionReactionData(), {
		formatDataKey: "emo",
	});

	useEffect(() => {
		if (emojiData) {
			localStorage.setItem("EmojiData", JSON.stringify(emojiData));
		}
	}, [emojiData]);

	useEffect(() => {
		if (localStorage.getItem("timestamp")) {
			getDashboardData();
			getDashboardRealTimeStatsData();
		}
		if (!localStorage.getItem("EmojiData")) {
			getEmojiData();
		}
	}, []);

	useEffect(() => {
		if (data) {
			formatDashboardData();
		}
	}, [data, realtimeData]);

	return (
		<div ref={wrapperRef}>
			<Hero title={strings("Dashboard.hero_title")} />
			<div className="bg-gray-200 p-5 rounded-lg -mt-52 mx-5 h-auto mb-5">
				<div className="container mx-auto">
					<div className="flex flex-col w-full">
						<div className="flex flex-row w-full items-center justify-between flex-wrap">
							<div className="xs:w-full md:w-1/2">
								<Filter
									id="timefrane-filter"
									data={filterData}
									selectedData={filterData}
									applyFilter={applyFilter}
									filterName={strings("Dashboard.timeframe")}
									showTitle={false}
								/>
							</div>
							{!isLoading ? (
								<div className="xs:w-full md:w-1/3 flex flex-col justify-center items-start md:items-end pt-3 md:pt-1 px-1">
									<button
										type="button"
										className="text-primary flex text-support font-normal justify-center items-center"
										onClick={() => {
											setIsLoading(true);
											getDashboardRealTimeStatsData();
										}}
										title={strings("Dashboard.refresh_button")}
										disabled={isLoading}
									>
										<RefreshIcon className="mr-2" />
										{strings("Dashboard.refresh_button")}
									</button>
									<p className="text-black text-support font-normal whitespace-pre-wrap align-middle pt-2">
										{strings("Dashboard.data_as_of")}
										{" "}
										{moment.utc(new Date(timestamp)).format(DATE_FORMAT)}
									</p>
								</div>
							)
								: null}
						</div>
						<div className={classNames("grid gap-4 grid-cols-1 my-2 sm:grid-cols-1 pt-3 pb-2")}>
							{!isLoading && realtimeData ? (
								<div className={classNames("metric-card bg-white border border-gray-200 rounded-lg p-4")}>
									<p className={classNames("text-heading_2 font-bold spacing-sm text-black uppercase")}>
										{strings("Dashboard.usrCntTillDate")}
										{": "}
										{formatAsNbWithThousandsWithDefault(realtimeData?.newUserCntTillDate, 0)}
									</p>
								</div>
							)
								: null}
						</div>
						{!isLoading
							? (
								<div
									data-testid="dashboard-container"
									className={classNames("grid gap-4 grid-cols-1 my-2",
										usersStats.length === 0 ? "sm:grid-cols-1" : "sm:grid-cols-3")}
								>
									{usersStats && usersStats.length > 0
										? usersStats?.map((item: any) => (
											<div key={item.title}>
												<DashboardStatCard
													item={item}
													filterData={filter}
													containerStyle="h-full"
												/>
											</div>
										))
										: (
											<div
												data-testid="dashboard-no-data-container"
												className="flex bg-white rounded-lg p-4 h-64 item-center justify-center"
											>
												<p className="place-self-center normal-text-style text-center">
													{strings("Table.no_data_message")}
												</p>
											</div>
										)}
								</div>
							)
							: (
								<div
									data-testid="dashboard-skeleton"
									className={classNames("grid gap-4 grid-cols-1 my-2 sm:grid-cols-3 z-0")}
								>
									<SkeletonTheme>
										<Skeleton className={classNames("metric-card border rounded-lg p-4 h-60")} borderRadius={10} />
										<Skeleton className={classNames("metric-card border rounded-lg p-4 h-60")} borderRadius={10} />
										<Skeleton className={classNames("metric-card border rounded-lg p-4 h-60")} borderRadius={10} />
										<Skeleton className={classNames("metric-card border rounded-lg p-4 h-60")} borderRadius={10} />
										<Skeleton className={classNames("metric-card border rounded-lg p-4 h-60")} borderRadius={10} />
										<Skeleton className={classNames("metric-card border rounded-lg p-4 h-60")} borderRadius={10} />
										<Skeleton className={classNames("metric-card border rounded-lg p-4 h-60")} borderRadius={10} />
									</SkeletonTheme>
								</div>
							)}
					</div>
				</div>
			</div>
		</div>
	);
}
