/**
* Simple toggle switch that looks like an IOS toggle
*
* Wraps headless ui switch component
*
*/
import React from "react";
import { Switch } from "@headlessui/react";

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(" ");
}
export type ToggleProps = {
	props?: {},
	checked?: boolean,
	onChange?: (checked: boolean) => void,
}
export const Toggle = ({
	props = {},
	checked = false,
	onChange = (checked) => { },
}: ToggleProps) => (
	<Switch
		{...props}
		checked={checked}
		onChange={onChange}
		className={classNames(
			checked ? "bg-primary" : "bg-gray-darker",
			"relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200",
		)}
	>
		<span className="sr-only">Use setting</span>
		<span
			aria-hidden="true"
			className={classNames(
				checked ? "translate-x-5" : "translate-x-0",
				"pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200",
			)}
		/>
	</Switch>
);

Toggle.defaultProps = {
	props: {},
	checked: false,
	onChange: (checked: boolean) => {},
};
