/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGAttributes } from "react";

const StreamCredoOnly = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--gray-dark)",
	} = props;
	return (
		<svg
			width="21px"
			height="19px"
			viewBox="0 0 21 19"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<g id="Streams---CT---190723" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-icons" transform="translate(-105.000000, -390.000000)" stroke={stroke}>
					<g id="stream_credo_only" transform="translate(105.000000, 390.000000)">
						<path
							d="M13.301,11.8995 C13.301,15.4345 10.435,18.3005 6.9,18.3005 C3.366,18.3005 0.5,15.4345 0.5,11.8995 C0.5,8.3645 3.366,5.4985 6.9,5.4985 C10.435,5.4985 13.301,8.3645 13.301,11.8995 Z"
							id="Stroke-1"
						/>
						<path
							d="M6.8999,12.0345 C8.1149,12.0345 9.1029,11.0465 9.1029,9.8315 C9.1029,8.6165 8.1149,7.6285 6.8999,7.6285 C5.6859,7.6285 4.6979,8.6165 4.6979,9.8315 C4.6979,11.0465 5.6859,12.0345 6.8999,12.0345 Z"
							id="Stroke-3"
						/>
						<path
							d="M3.115,16.7434 C3.291,14.4664 4.276,13.5444 4.847,13.1944 L4.986,13.1094 L5.127,13.2044 L6.9,14.5844 L8.673,13.2044 L8.814,13.1094 L8.953,13.1944 C9.524,13.5444 10.509,14.4674 10.685,16.7444"
							id="Stroke-5"
						/>
						<path
							d="M13.2836,12.3381 C13.4196,12.3471 13.5566,12.3521 13.6946,12.3521 C17.0586,12.3521 19.7676,9.5491 19.6146,6.1521 C19.4776,3.1191 17.0226,0.6551 13.9896,0.5071 C12.6176,0.4401 11.3456,0.8431 10.3126,1.5661 C10.1476,1.6811 10.2456,1.9381 10.4446,1.9101 C10.6206,1.8851 10.7996,1.8711 10.9826,1.8711 C13.4986,1.8711 15.5376,3.9101 15.5376,6.4261 C15.5376,8.1651 14.5636,9.6761 13.1306,10.4441"
							id="Stroke-7"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default StreamCredoOnly;
