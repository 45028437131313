import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { classNames } from "../common";

interface BlockedUsersSkeletonProps {
	wrapperClasses?: string;
	noOfItems?: number;
	itemClass?: string;
	itemWrapperClass?: string;
}

const BlockedUsersSkeleton = (props: BlockedUsersSkeletonProps) => {
	const {
		wrapperClasses, noOfItems, itemWrapperClass, itemClass,
	} = props;
	return (
		<div
			className={classNames(
				"w-full z-0",
				wrapperClasses,
			)}
		>
			<SkeletonTheme
				baseColor="var(--background-skeleton)"
				highlightColor="var(--hightlight-skeleton)"
			>
				{Array.from(Array(noOfItems).keys()).map((i) => (
					<div
						key={i}
						className={classNames(
							"w-full flex flex-col items-center",
							itemWrapperClass,
						)}
					>
						<div
							className={classNames(
								"w-full flex flex-row items-center justify-center p-2",
								itemClass,
							)}
						>
							<div className="w-full flex flex-row items-start justify-center">
								<Skeleton width={40} height={40} circle />
								<div className="w-full flex flex-col ml-5 justify-center items-start mt-2">
									<Skeleton containerClassName="w-1/3" height={20} />
								</div>
							</div>
							<div className="flex flex-row items-center justify-center">
								<Skeleton width={100} height={20} className="rounded-full" />
							</div>
						</div>
						{i < ((noOfItems ?? 0) - 1) ? (
							<div
								className="w-full flex flex-row items-start h-full bg-background-tertiary"
								style={{ height: 1 }}
							/>
						) : null}
					</div>
				))}
			</SkeletonTheme>
		</div>
	);
};

BlockedUsersSkeleton.defaultProps = {
	wrapperClasses: "",
	noOfItems: 5,
	itemClass: "",
	itemWrapperClass: "",
};

export default BlockedUsersSkeleton;
