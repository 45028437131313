import React, {SVGAttributes, useEffect, useState} from "react";

interface CircularProgressProps extends SVGAttributes<SVGElement> {
	percentage: number;
	size: number
	strokeWidth: number;
}

const CircularProgress = (props: CircularProgressProps) => {
	const {
		size, strokeWidth, percentage, color, style,
	} = props;
	const [progress, setProgress] = useState(0);
	useEffect(() => {
		setProgress(percentage);
	}, [percentage]);

	const viewBox = `-2 0 ${size + 3} ${size + 1}`;
	const radius = (size - strokeWidth) / 2;
	const circumference = radius * Math.PI * 2;
	const dash = (progress * circumference) / 100;

	return (
		<svg width={size} height={size} viewBox={viewBox} style={style}>
			{/*<circle*/}
			{/*	fill="none"*/}
			{/*	stroke="black"*/}
			{/*	cx={(size) / 2}*/}
			{/*	cy={(size) / 2}*/}
			{/*	r={radius + 0.5}*/}
			{/*	strokeWidth={`${strokeWidth}px`}*/}
			{/*/>*/}
			<circle
				fill="none"
				stroke="transparent"
				cx={size / 2}
				cy={size / 2}
				r={radius}
				strokeWidth={`${strokeWidth}px`}
			/>
			<circle
				fill="none"
				stroke={color}
				cx={size / 2}
				cy={size / 2}
				r={radius}
				strokeWidth={`${strokeWidth}px`}
				transform={`rotate(-90 ${size / 2} ${size / 2})`}
				// @ts-ignore
				strokeDasharray={[dash, circumference - dash]}
				strokeLinecap="round"
				style={{ transition: "all 0.5s" }}
			/>
		</svg>
	);
};

export default CircularProgress;
