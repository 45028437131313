import * as React from "react";
import {
	createRef, useEffect, useLayoutEffect, useRef,
} from "react";
import { cu, ConfigConst } from "@credo/utilities";
import { classNames, keyId } from "../common";
import styles from "./score-widget.module.css";
import { ConfigureIcon } from "../assets/icons";

export type ScoreWidgetProps = {
	id: string;
	showSettings?: boolean; /* future feature */
	isLoading: boolean;
	score: number | undefined
	scoreStyle?: React.CSSProperties;
	format: (val: number) => string;
	icon: () => React.ReactNode;
	back: (() => React.ReactNode) | undefined;
	settingsIcon: (() => React.ReactNode) | undefined;
	txnValue?: string | undefined;
}
export const ScoreWidget = ({
	id,
	showSettings = false,
	settingsIcon = () => (<ConfigureIcon />),
	isLoading,
	score,
	scoreStyle = undefined,
	format,
	icon,
	back = undefined,
	txnValue,
}: ScoreWidgetProps) => {
	const scoreTextRef = createRef<SVGTextElement>();
	const txnValueRef = useRef<string | undefined>(undefined);

	/** this adjusts the score text container to 'squeeze' the text to fit */
	useLayoutEffect(() => {
		if (!scoreTextRef?.current) return;
		scoreTextRef.current.textLength.baseVal.newValueSpecifiedUnits(
			SVGLength.SVG_LENGTHTYPE_PX,
			Math.min(34, 7 * (scoreTextRef.current.textContent?.length ?? 0)),
		);
	}, [score, scoreStyle]);

	useEffect(() => {
		if (txnValue) {
			txnValueRef.current = txnValue;
		}
	}, [txnValue]);

	return (
		<svg
			width="86px"
			height="31px"
			viewBox="0 0 86 31"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>
				{score}
			</title>
			<defs>
				<circle id={keyId(id, "icon-mask")} cx="10" cy="10" r={10} />
				<mask id={keyId(id, "pill-mask")}>
					<rect x="0" y="0" width="85" height="31" rx="15.5" fill="white" />
				</mask>

				<linearGradient id="scanLightGradient" x1="0%" y1="50%" x2="100%" y2="50%">
					<stop stopColor="#000000" offset="0%" />
					<stop stopColor="#000000" offset="15%" />
					<stop stopColor="#00FFA4" offset="50%" />
					<stop stopColor="#000000" offset="65%" />
					<stop stopColor="#000000" offset="100%" />
				</linearGradient>
			</defs>
			<g>
				<mask>
					<use xlinkHref={`#${keyId(id, "pill-mask")}`} />
				</mask>
				<rect
					id="back"
					className={classNames(
						styles.container,
						"fill-credo-graph-container",
					)}
					x="0"
					y="0"
					width="85"
					height="31"
					rx="15.5"
				/>
				{isLoading && (
					<rect
						id={keyId(id, "scanner")}
						className={styles.scanLight}
						mask={`url(#${keyId(id, "pill-mask")}`}
					/>
				)}
				{!isLoading && back !== undefined && (
					<g transform="translate(27, 1)">
						<foreignObject width={35} height={28}>{back()}</foreignObject>
					</g>
				)}

				{score !== undefined && (
					<text
						id={keyId(id, "score")}
						className={classNames(
							styles.scoreText,
							"fill-credo-graph-score-text",
							isLoading ? styles.loading : styles.loaded,
							// eslint-disable-next-line no-nested-ternary
							txnValue ? "hidden" : txnValueRef.current === undefined ? "" : styles.loadedAfterUpdate,
						)}
						style={scoreStyle}
						textAnchor="end"
					>
						<tspan
							id={keyId(id, "score-tspan")}
							x="61"
							y="19.5"
							width="25"
							lengthAdjust="spacing"
							ref={scoreTextRef}
						>
							{score > ConfigConst.minValueForThousandSteps ? cu.formatNumberWithThousandsSep(score, true) : format(score)}
						</tspan>
					</text>
				)}

				{txnValue !== undefined && (
					<text
						id={keyId(id, "score")}
						className={classNames(
							styles.txn,
							"fill-credo-graph-score-text",
							styles.loaded,
						)}
						style={scoreStyle}
						textAnchor="end"
					>
						<tspan
							id={keyId(id, "score-tspan")}
							x="61"
							y="19.5"
							width="25"
							lengthAdjust="spacing"
							ref={scoreTextRef}
						>
							{txnValue}
						</tspan>
					</text>
				)}
				<g transform="translate(5, 5)">
					<mask fill="white">
						<use xlinkHref={`#${keyId(id, "icon-mask")}`} />
					</mask>
					<circle r={10} fill="#FB7217" cx={10} cy={10} style={{ opacity: "0.1" }} />
					<foreignObject width={28} height={28} transform="translate(-4,-3.5)">{icon()}</foreignObject>
				</g>
				{showSettings && (
					<g transform="translate(62.5, 7.5)">
						{settingsIcon()}
					</g>
				)}
			</g>
		</svg>
	);
};
