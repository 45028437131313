import * as React from "react"
import {SVGAttributes} from "react";

const SvgComponent = (props: SVGAttributes<SVGElement>) => (
	<svg width="18px" height="17px" viewBox="0 0 18 17" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Boost-PL">
				<line x1="0.5" y1="11.5" x2="16.5" y2="11.5" id="Line-5" stroke="#0BE0D8" strokeLinecap="square"></line>
				<rect id="Rectangle" fill="#0FF077" x="1" y="6" width="3" height="4"></rect>
				<rect id="Rectangle" fill="#0FF077" x="13" y="6" width="3" height="4"></rect>
				<rect id="Rectangle" fill="#0FF077" x="5" y="3" width="3" height="7"></rect>
				<rect id="Rectangle" fill="#F03C11" x="9" y="13" width="3" height="3"></rect>
			</g>
		</g>
	</svg>
)
export default SvgComponent
