import {useEffect, useRef} from "react";
import {EvtMgr} from "../utils";

/**
 * Adds and removes the event with its listener when
 * component mounts and unmounts respectively.
 *
 * @param {string} eventName The name of the event to be captured.
 * @param {Function} handler The function/listener which will be
 * executed when the event is fired.
 *
 * @example
 * import { useEvtMgrListener } from "@credo/utilities";
 * // Call this event listener hook in the component in which you
 * // want to execute the listener when event is fired.
 *
 * const handleEvent = (data) => {
 *   // handle the data which is sent while notifying this listener
 * };
 *
 * useEvtMgrListener("eventName", handleEvent);
 *
 * // Wherever you will notify the event like below it will be handled
 * // by the hook above.
 * // EvtMgr.getInstance("eventName").notifyListener(data)
 *
 * @see EvtMgr
 * */
export function useEvtMgrListener(eventName: string, handler: (...args: any[]) => void) {
	const savedHandler = useRef((...args: any[]) => { });

	useEffect(() => {
		savedHandler.current = handler;
	}, [handler]);

	useEffect(() => {
		const eventListener = (...args: any[]) => savedHandler.current(...args);
		EvtMgr.getInstance(eventName).addListener(eventListener);

		return () => {
			EvtMgr.getInstance(eventName).removeListener(eventListener);
		};
	}, [eventName]);
}
