/* eslint-disable import/prefer-default-export */
import { strings } from "../../i18n/config";

export const ReportedProblemsTableHeaders = [
	{
		title: strings("ReportedProblems.id"),
		value: "postUuid",
		isFixedColumn: true,
		isClickable: false,
		hideColumn: true,
	},
	{
		title: strings("ReportedProblems.user_id"),
		value: "user_id",
		isFixedColumn: true,
		isClickable: true,
		hideColumn: true,
	},
	{
		title: strings("ReportedProblems.name"),
		value: "name",
	},
	{
		title: strings("ReportedProblems.email"),
		value: "email",
	},
	{
		title: strings("ReportedProblems.phone"),
		value: "phone",
	},
	{
		title: strings("UserReportedList.reportReason"),
		value: "reportReason",
	},
	{
		title: strings("UserReportedList.reportMessage"),
		value: "reportMessage",
	},
	{
		title: strings("PostReportedList.reported_timestamp"),
		value: "creat_ts",
	},
];
