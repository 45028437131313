import { Outlet } from "react-router-dom";
import Roles from "./Roles";

// Components
import {
	COMMUNITIES_PAGE,
	COMMUNITIES_DETAILS_PAGE,
	DASHBOARD_PAGE,
	POSTS_PAGE,
	PROFILE_PAGE,
	REPORTED_PAGE,
	SEARCH_PAGE,
	USERS_PAGE,
	COMMENTS_PAGE,
	SETTINGS_PAGE,
	USER_DETAILS_PAGE,
	SUSPENDED_USERS_PAGE,
	COMMENT_DETAILS_PAGE,
	POST_DETAILS_PAGE,
	POST_COMMENTS_PAGE,
	NOT_REGISTERED_USERS_PAGE,
	VIRTUAL_USERS_PAGE,
	DISABLED_PAGE,
	REPORTED_PROBLEMS_PAGE,
	NEW_USERS_PAGE,
	NEW_COMMUNITY_PAGE,
} from "../routes/constants";
import DashboardPage from "../modules/dashboard/DashboardPage";
import UserSearchPage from "../modules/users/UserSearchPage";
import SuspendedUsersScreen from "../modules/users/SuspendedUsersScreen";
import UserReportedPage from "../modules/users/UserReportedPage";
import PostSearchPage from "../modules/posts/PostSearchPage";
import PostReportedPage from "../modules/posts/PostReportedPage";
import CommunitySearchPage from "../modules/communities/CommunitySearchPage";
import CommunityReportedPage from "../modules/communities/CommunityReportedPage";
import CommentSearchPage from "../modules/comments/CommentSearchPage";
import CommentReportedPage from "../modules/comments/CommentReportedPage";
import ProfilePage from "../modules/profile/ProfilePage";
import SettingsPage from "../modules/profile/SettingsPage";
import { strings } from "../i18n/config";
import CommunityDetailsScreen from "../modules/communities/CommunityDetailsScreen";
import UserDetailsPage from "../modules/users/UserDetails/UserDetailsPage";
import CommentDetailsScreen from "../modules/comments/CommentDetailsScreen";
import NotFullyRegisteredUsersPage from "../modules/users/NotFullyRegisteredUsersPage";
import PostDetailsScreen from "../modules/posts/PostDetailsPage";
import VirtualUsersPage from "../modules/users/VirtualUsersPage";
import PostCommentsPage from "../modules/posts/PostCommentsPage";
import DisabledPostPage from "../modules/posts/DisabledPostPage";
import DisabledCommentsPage from "../modules/comments/DisabledCommentsPage";
import ReportedProblems from "../modules/problems/ReportedProblems";
import NewUsersPage from "../modules/users/NewUsersPage";
import NewCommunityPage from "../modules/communities/NewCommunityPage";

export default [
	{
		component: DashboardPage,
		path: DASHBOARD_PAGE,
		title: strings("SideBar.dashboard"),
		exact: true,
		permission: [
			Roles.SUPER_ADMIN,
			Roles.ADMIN,
			Roles.USER_MODERATOR,
			Roles.STREAM_MODERATOR,
			Roles.POST_MODERATOR,
			Roles.COMMENT_MODERATOR,
			Roles.CUSTOMER_SUPPORT,
		],
	},
	{
		component: Outlet,
		path: "",
		title: strings("SideBar.users"),
		permission: [
			Roles.SUPER_ADMIN,
			Roles.ADMIN,
			Roles.USER_MODERATOR,
			Roles.STREAM_MODERATOR,
			Roles.POST_MODERATOR,
			Roles.COMMENT_MODERATOR,
		],
		children: [
			{
				component: UserSearchPage,
				path: `${USERS_PAGE}${SEARCH_PAGE}`,
				title: strings("SideBar.search"),
				permission: [
					Roles.SUPER_ADMIN,
					Roles.ADMIN,
					Roles.USER_MODERATOR,
				],
			},
			{
				component: UserReportedPage,
				path: `${USERS_PAGE}${REPORTED_PAGE}`,
				title: strings("SideBar.reported"),
				permission: [
					Roles.SUPER_ADMIN,
					Roles.ADMIN,
					Roles.USER_MODERATOR,
				],
			},
			{
				component: SuspendedUsersScreen,
				path: `${USERS_PAGE}${SUSPENDED_USERS_PAGE}`,
				title: strings("SideBar.suspended_users"),
				permission: [
					Roles.SUPER_ADMIN,
					Roles.ADMIN,
					Roles.USER_MODERATOR,
				],
			},
			{
				component: NotFullyRegisteredUsersPage,
				path: `${USERS_PAGE}${NOT_REGISTERED_USERS_PAGE}`,
				title: strings("SideBar.not_registered_users"),
				permission: [
					Roles.SUPER_ADMIN,
					Roles.ADMIN,
					Roles.USER_MODERATOR,
				],
			},
			{
				component: VirtualUsersPage,
				path: `${USERS_PAGE}${VIRTUAL_USERS_PAGE}`,
				title: strings("SideBar.virtual_users"),
				permission: [
					Roles.SUPER_ADMIN,
					Roles.ADMIN,
					Roles.USER_MODERATOR,
				],
			},
			{
				component: NewUsersPage,
				path: `${USERS_PAGE}${NEW_USERS_PAGE}`,
				title: strings("SideBar.new_users"),
				permission: [
					Roles.SUPER_ADMIN,
					Roles.ADMIN,
					Roles.USER_MODERATOR,
				],
			},
		],
	},
	{
		component: Outlet,
		path: "",
		title: strings("SideBar.communities"),
		permission: [
			Roles.SUPER_ADMIN,
			Roles.ADMIN,
			Roles.USER_MODERATOR,
			Roles.STREAM_MODERATOR,
			Roles.POST_MODERATOR,
			Roles.COMMENT_MODERATOR,
		],
		children: [
			{
				component: CommunitySearchPage,
				path: `${COMMUNITIES_PAGE}${SEARCH_PAGE}`,
				title: strings("SideBar.search"),
				permission: [
					Roles.SUPER_ADMIN,
					Roles.ADMIN,
					Roles.STREAM_MODERATOR,
				],
			},
			{
				component: NewCommunityPage,
				path: `${COMMUNITIES_PAGE}${NEW_COMMUNITY_PAGE}`,
				title: strings("SideBar.new_communities"),
				permission: [
					Roles.SUPER_ADMIN,
					Roles.ADMIN,
					Roles.STREAM_MODERATOR,
				],
			},
			{
				component: CommunityReportedPage,
				path: `${COMMUNITIES_PAGE}${REPORTED_PAGE}`,
				title: strings("SideBar.reported"),
				permission: [
					Roles.SUPER_ADMIN,
					Roles.ADMIN,
					Roles.STREAM_MODERATOR,
				],
			},
		],
	},
	{
		component: Outlet,
		path: "",
		title: strings("SideBar.posts"),
		permission: [
			Roles.SUPER_ADMIN,
			Roles.ADMIN,
			Roles.USER_MODERATOR,
			Roles.STREAM_MODERATOR,
			Roles.POST_MODERATOR,
			Roles.COMMENT_MODERATOR,
		],
		children: [
			{
				component: PostSearchPage,
				path: `${POSTS_PAGE}${SEARCH_PAGE}`,
				title: strings("SideBar.search"),
				permission: [
					Roles.SUPER_ADMIN,
					Roles.ADMIN,
					Roles.POST_MODERATOR,
				],
			},
			{
				component: PostReportedPage,
				path: `${POSTS_PAGE}${REPORTED_PAGE}`,
				title: strings("SideBar.reported"),
				permission: [
					Roles.SUPER_ADMIN,
					Roles.ADMIN,
					Roles.POST_MODERATOR,
				],
			},
			{
				component: DisabledPostPage,
				path: `${POSTS_PAGE}${DISABLED_PAGE}`,
				title: strings("SideBar.disabled"),
				permission: [
					Roles.SUPER_ADMIN,
					Roles.ADMIN,
					Roles.POST_MODERATOR,
				],
			},
		],
	},
	{
		component: Outlet,
		path: "",
		title: strings("SideBar.comments"),
		permission: [
			Roles.SUPER_ADMIN,
			Roles.ADMIN,
			Roles.USER_MODERATOR,
			Roles.STREAM_MODERATOR,
			Roles.POST_MODERATOR,
			Roles.COMMENT_MODERATOR,
		],
		children: [
			{
				component: CommentSearchPage,
				path: `${COMMENTS_PAGE}${SEARCH_PAGE}`,
				title: strings("SideBar.search"),
				permission: [
					Roles.SUPER_ADMIN,
					Roles.ADMIN,
					Roles.COMMENT_MODERATOR,
				],
			},
			{
				component: CommentReportedPage,
				path: `${COMMENTS_PAGE}${REPORTED_PAGE}`,
				title: strings("SideBar.reported"),
				permission: [
					Roles.SUPER_ADMIN,
					Roles.ADMIN,
					Roles.COMMENT_MODERATOR,
				],
			},
			{
				component: DisabledCommentsPage,
				path: `${COMMENTS_PAGE}${DISABLED_PAGE}`,
				title: strings("SideBar.disabled"),
				permission: [
					Roles.SUPER_ADMIN,
					Roles.ADMIN,
					Roles.COMMENT_MODERATOR,
				],
			},
		],
	},
	{
		component: ProfilePage,
		path: PROFILE_PAGE,
		title: strings("SideBar.profile"),
	},
	{
		component: SettingsPage,
		path: SETTINGS_PAGE,
		title: strings("SideBar.settings"),
	},
	{
		component: CommunityDetailsScreen,
		path: COMMUNITIES_DETAILS_PAGE,
		title: strings("SideBar.community_details"),
	},
	{
		component: UserDetailsPage,
		path: USER_DETAILS_PAGE,
		title: strings("SideBar.user_details"),
	},
	{
		component: CommentDetailsScreen,
		path: COMMENT_DETAILS_PAGE,
		title: strings("SideBar.comment_details"),
	},
	{
		component: PostDetailsScreen,
		path: POST_DETAILS_PAGE,
		title: strings("SideBar.post_details"),
	},
	{
		component: PostCommentsPage,
		path: POST_COMMENTS_PAGE,
		title: strings("SideBar.post_details"),
	},
	{
		component: ReportedProblems,
		path: REPORTED_PROBLEMS_PAGE,
		title: strings("SideBar.reported_problems"),
		exact: true,
		permission: [
			Roles.SUPER_ADMIN,
			Roles.ADMIN,
			Roles.USER_MODERATOR,
			Roles.STREAM_MODERATOR,
			Roles.POST_MODERATOR,
			Roles.COMMENT_MODERATOR,
			Roles.CUSTOMER_SUPPORT,
		],
	},
];
