import * as React from "react"
import {SVGAttributes} from "react";

const SvgComponent = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { fill = "var(--gray-dark)" } = props;
	return (
		<svg width={40} height={40} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props} stroke="none">
			<path
				d="m19.838 15.485.062.974-1.03-.12c-3.747-.456-7.022-2.006-9.802-4.607L7.71 10.44l-.35.953c-.741 2.125-.268 4.37 1.277 5.879.824.834.638.953-.783.457-.494-.16-.926-.278-.968-.219-.144.14.35 1.947.742 2.662.535.993 1.627 1.966 2.821 2.542l1.01.457-1.195.02c-1.153 0-1.195.02-1.071.436.412 1.291 2.039 2.662 3.85 3.258l1.277.417-1.112.635a12.007 12.007 0 0 1-5.518 1.47c-.927.02-1.689.1-1.689.159 0 .198 2.512 1.31 3.974 1.748 4.387 1.29 9.597.734 13.51-1.47 2.78-1.57 5.56-4.687 6.857-7.706.7-1.609 1.4-4.548 1.4-5.959 0-.913.062-1.032 1.215-2.125.68-.635 1.318-1.33 1.442-1.529.205-.377.185-.377-.865-.04-1.75.596-1.998.517-1.133-.377.638-.636 1.4-1.788 1.4-2.125 0-.06-.309.04-.659.218-.37.199-1.194.497-1.812.676l-1.112.337-1.009-.655c-.556-.358-1.338-.755-1.75-.874-1.05-.278-2.657-.238-3.604.08-2.574.893-4.201 3.197-4.016 5.72Z"
				fill={fill}
				fillRule="evenodd"
			/>
		</svg>
	)
}

export default SvgComponent
