import moment from "moment";
import { strings } from "../i18n/config";
import { YesNoOptions } from "./types";

const convertDateInDisplayFormat = (startDate: string, endDate: string, isCurrentDate: string) => {
	const startDateMonth = Number(startDate.substring(0, 2)) - 1;
	const startDateYear = startDate.substring(4);
	const endDateMonth = Number(endDate.substring(0, 2)) - 1;
	const endDateYear = endDate.substring(4);
	let date: string;
	if (isCurrentDate === YesNoOptions.YES) {
		date = `${moment.monthsShort(startDateMonth)} ${startDateYear} - ${strings("UserDetailsPage.present")}`;
	} else {
		date = `${moment.monthsShort(startDateMonth)} ${startDateYear} - ${moment.monthsShort(endDateMonth)} ${endDateYear}`;
	}
	return date;
};

export default convertDateInDisplayFormat;
