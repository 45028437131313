import React, { SVGAttributes } from "react";

// eslint-disable-next-line import/prefer-default-export
const StreamExpertise = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--gray-dark)",
	} = props;
	return (
		<svg
			width="18px"
			height="20px"
			viewBox="0 0 18 20"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<g id="Streams---CT---190723" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-icons" transform="translate(-374.000000, -1018.000000)" stroke={stroke}>
					<g id="Streamxpertise" transform="translate(374.000000, 1018.000000)">
						<path d="M17.2479176,6.09663158 C16.7322706,4.05768421 15.3780353,2.46505263 13.5261529,1.51242105 C11.8849765,0.667157895 9.94203529,0.345052632 8.11344706,0.625052632 C5.64215294,1.00294737 3.81674118,2.21347368 2.80874118,4.08505263 C2.12368235,5.35557895 2.14274118,6.504 2.14274118,7.90294737 L2.14274118,8.07347368 C2.14274118,8.30084211 2.08556471,8.52715789 1.99132941,8.73557895 L0.603211765,11.6471579 C0.488858824,11.8924211 0.507917647,12.1945263 0.659329412,12.4218947 C0.8118,12.6492632 1.05956471,12.7997895 1.34438824,12.7997895 L2.10462353,12.7997895 L2.10462353,15.1724211 C2.10462353,16.7997895 2.80238824,17.6018947 4.00732941,17.6018947 L5.31921176,17.6397895 C5.54685882,17.6397895 5.75650588,17.8482105 5.75650588,18.0745263 L5.75650588,19.1145263 C5.75650588,19.3229474 5.92697647,19.4734737 6.11756471,19.4734737 L14.0852118,19.4734737 C14.1995647,19.4734737 14.3139176,19.4166316 14.3901529,19.3229474 C14.4663882,19.2282105 14.4663882,19.0955789 14.4282706,18.9818947 L14.3710941,18.8503158 C13.7432118,17.2997895 13.6479176,15.9387368 14.1233294,14.8608421 C14.5849765,13.7461053 15.6999176,13.0018947 16.3140353,11.9692632 C16.6645059,11.3787368 16.9429765,10.7461053 17.1378,10.0892632 C17.5221529,8.79663158 17.5793294,7.40715789 17.2479176,6.09663158 Z" id="Stroke-1" />
						<path d="M14.1054353,5.80021053 C14.0334353,5.80021053 13.9656706,5.81178947 13.8957882,5.82126316 C14.1202588,5.35389474 14.1922588,4.80231579 13.9709647,4.20547368 C13.7295529,3.55389474 13.1588471,3.04442105 12.4748471,2.89915789 C11.4509647,2.68231579 10.5297882,3.21073684 10.1337882,4.03705263 C10.0999059,4.10968421 10.0035529,4.10968421 9.96861176,4.03705263 C9.63508235,3.34126316 8.87802353,2.73073684 8.0532,2.72436843 C7.61272941,2.72126316 7.04308235,2.86021053 6.70955294,3.14652632 C5.86037647,3.87494737 5.93872941,4.65810526 6.01814118,5.20652632 C6.02555294,5.26126316 5.98002353,5.30442105 5.92602353,5.296 C5.58508235,5.24126316 5.21449412,5.28126316 4.79731765,5.56442105 C4.31343529,5.89284211 3.97884706,6.436 4.00849412,7.01705263 C4.05402353,7.89178947 4.78143529,8.58757895 5.67296471,8.58757895 C6.24367059,8.58757895 6.7752,8.38968421 7.0812,7.97073684 C7.09284706,7.95494737 7.11296471,7.96126316 7.11402353,7.98021053 C7.17967059,9.05705263 8.07331765,9.91178947 9.17237647,9.91178947 C9.8892,9.91178947 10.5139059,9.54547368 10.8844941,8.99389474 C10.9152,8.94757895 10.9797882,8.95915789 10.9956706,9.01178947 C11.1534353,9.52968421 11.6172,9.91178947 12.1889647,9.91178947 C12.8083765,9.91178947 13.3039059,9.46652632 13.4172,8.88547368 C13.4256706,8.84336842 13.4659059,8.82126316 13.5050824,8.83705263 C13.8640235,8.98547368 14.2833176,9.016 14.7312,8.82968421 C15.1674353,8.64757895 15.5136706,8.26863158 15.6354353,7.81389474 C15.9202588,6.75494737 15.1250824,5.80021053 14.1054353,5.80021053 Z" id="Stroke-3" />
						<path d="M12.3109412,7.27768421 C12.3109412,6.63347368 11.7857647,6.11136842 11.1377647,6.11136842 C10.7565882,6.11136842 10.4188235,6.29347368 10.2049412,6.57452632 C10.1752941,6.61347368 10.1223529,6.61873684 10.0863529,6.58505263 C9.77082353,6.29242105 9.34835294,6.11136842 8.88247059,6.11136842 C7.90623529,6.11136842 7.11423529,6.89873684 7.11423529,7.86926316" id="Stroke-5" strokeLinecap="round" />
					</g>
				</g>
			</g>
		</svg>
	);
};

export default StreamExpertise;
