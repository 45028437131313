import React from "react";
import { cu } from "@credo/utilities";
import { ProfilePicture } from "../profile/profile-picture";
import { RewardsNotificationMsgs } from "./types";

interface RewardsNotificationProps {
	postData: any;
	handleClick: () => void;
	messages: RewardsNotificationMsgs;
}

export const RewardsNotification = (props: RewardsNotificationProps) => {
	const {
		postData,
		handleClick,
		messages,
	} = props;

	const renderTime = () => (
		<p className="text-sm text-gray-dark">
			{cu.calculatePostTime(postData["p.creat_ts"])}
			{postData["p.modif_ts"] && postData["p.modif_ts"] !== postData["p.creat_ts"]
				? `${messages.Edited}`
				: null}
		</p>
	);

	const renderRewardsPost = () => {
		if (cu.isSet(postData["p.evt_type"]) && cu.isSet(postData["p.badge_type"])) {
			return (
				<div className="flex flex-row bg-background">
					<div
						className="flex justify-center items-center w-14 rounded-r"
						style={{ backgroundColor: postData["p.badge_post_color"] }}
					>
						<ProfilePicture
							profilePicUrl={cu.buildSourceUrlImage(postData["p.badge_post_icon"]) || ""}
							profilePicStyle="border-none"
							imageStyle={{ width: 34, height: 34 }}
						/>
					</div>
					<div className="flex flex-col py-2.5 ml-2">
						<p className="text-primary text-base">
							<span className="text-base text-basic font-light">
								{messages.CongratsReward}
								{" "}
							</span>
							<span
								onClick={handleClick}
								aria-hidden
								className="cursor-pointer font-medium"
							>
								{postData["p.evt_name"]}
							</span>
							<span className="text-base text-basic font-light">
								{" "}
								{messages.Badge}
							</span>
						</p>
						{renderTime()}
					</div>
				</div>
			);
		}
		return (
			<div className="flex flex-row bg-background">
				<div
					className="flex justify-center items-center w-14 rounded-r"
				>
					<ProfilePicture
						profilePicUrl={cu.buildSourceUrlImage(postData["p.evt_post_icon"]) || ""}
						profilePicStyle="border-none ml-4"
						imageStyle={{ width: 34, height: 34 }}
					/>
				</div>
				<div className="flex flex-col py-2.5 ml-2">
					<p className="text-primary text-base">
						<span className="text-base text-basic font-light">
							{`${messages.Earned} ${postData["p.delta_ep"]} `}
							{postData["p.delta_ep"] === 1
								? messages.Point
								: messages.Points}
							{` ${messages.For} `}
						</span>
						<span
							onClick={handleClick}
							aria-hidden
							className="cursor-pointer font-medium"
						>
							{postData["p.evt_name"]}
						</span>
					</p>
					{renderTime()}
				</div>
			</div>
		);
	};

	return (
		<div className="flex w-full pr-4 justify-start items-center">
			{renderRewardsPost()}
		</div>
	);
};

export default RewardsNotification;
