import { Cookies } from "react-cookie";
import CfgMgr from "../config/CfgMgr";
import { dbg, isSafari, mydbg } from "./utils";

const cookies = new Cookies();

export function setCookie(key: any, value: any) {
	const secure = isSafari ? !(dbg && CfgMgr.CFG_FLAVOR === "DEV" && window.location.hostname === "localhost") : true;
	try {
		cookies.set(key, value, {
			path: "/",
			maxAge: 604800, // 7 Days expiry
			secure,
			sameSite: "strict",
		});
	} catch (error) {
		if (dbg) mydbg(`Got the error while setting cookie  ${key}`, error);
	}
}

export function removeCookie(key: any) {
	return cookies.remove(key, { path: "/" });
}

export function getCookie(key: any) {
	return cookies.get(key);
}

export function deleteAllCookies() {
	const allcookies = document.cookie.split(";");
	for (let i = 0; i < allcookies.length; i += 1) {
		if (allcookies[i] !== "") {
			const cookie = allcookies[i];
			const eqPos = cookie.indexOf("=");
			const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
			removeCookie(name);
		}
	}
}
