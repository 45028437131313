import React, {
	useEffect, useRef, useState,
} from "react";
import { CircularProgressIcon } from ".";

interface CircularProgressBarTimebaseProps {
	percentage: number,
	intervalInSeconds: number,
	// eslint-disable-next-line react/require-default-props
	size?: number,
	// eslint-disable-next-line react/require-default-props
	strokeWidth?: number,
	// eslint-disable-next-line react/require-default-props
	color?: string,
}

// eslint-disable-next-line import/prefer-default-export
const CircularProgressBarTimebase: React.FC<CircularProgressBarTimebaseProps> = (props: CircularProgressBarTimebaseProps) => {
	const {
		percentage,
		intervalInSeconds,
		size = 18,
		strokeWidth = 3,
		color = "black",
	} = props;

	const [remainingState, setRemainingState] = useState(percentage);
	const timerRef = useRef<ReturnType<typeof setInterval> | null>(null);

	useEffect(() => {
		if (timerRef.current) {
			clearInterval(timerRef.current);
		}
		timerRef.current = setInterval(() => {
			setRemainingState((prevState) => {
				if ((prevState + ((1 / intervalInSeconds) * 100)) <= 100) {
					return prevState + ((1 / intervalInSeconds) * 100);
				}
				// @ts-ignore
				clearInterval(timerRef.current);
				return 100;
			});
		}, 1000);

		return () => {
			if (timerRef.current) {
				clearInterval(timerRef.current);
			}
		};
	}, []);

	return (
		<CircularProgressIcon
			percentage={remainingState}
			size={size}
			color={color}
			strokeWidth={strokeWidth}
			style={{
				transform: "scale(-1, 1)",
			}}
		/>
	);
};

export default CircularProgressBarTimebase;
