import { useCallback, useRef } from "react";

interface AuthorTagsHook {
	openAuthorTagsModal: (isHover?: boolean) => void;
}

// eslint-disable-next-line import/prefer-default-export
export const useAuthorTagsHook = ({
	openAuthorTagsModal,
}: AuthorTagsHook) => {
	const hoverTimerRef = useRef<any>(null);
	const showAuthorTagsOnHover = useCallback(() => {
		hoverTimerRef.current = setTimeout(() => {
			openAuthorTagsModal(true);
		}, 500);
	}, [openAuthorTagsModal]);

	const removeTimer = useCallback(() => {
		if (hoverTimerRef.current) {
			clearTimeout(hoverTimerRef.current);
		}
	}, [openAuthorTagsModal]);

	return {
		showAuthorTagsOnHover,
		removeTimer,
	};
};
