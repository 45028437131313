// eslint-disable-next-line import/prefer-default-export
export enum CloseIconSideType {
	RIGHT = "right",
	LEFT = "left",
}

export interface ModalPosition {
	top: number,
	left: number,
	right?: number,
}
