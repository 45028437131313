import {useEffect, useState} from "react";

export const useWindowSize = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const [height, setHeight] = useState(window?.innerHeight);

	const handleResize = () => {
		setHeight(window.innerHeight);
		setWidth(window.innerWidth);
	}

	useEffect(() => {
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		}
	}, []);

	return {
		width,
		height,
	}
}