/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGAttributes } from "react";

const ShareIcon = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--gray-dark)",
	} = props;
	return (
		<svg
			width="18px"
			height="20px"
			viewBox="0 0 18 20"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<title>share_icon</title>
			<g id="Streams---CT---190723" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
				<g id="All-icons" transform="translate(-375.000000, -162.000000)" stroke={stroke}>
					<g id="share_icon" transform="translate(376.000000, 163.000000)">
						<line x1="8" y1="13" x2="8" y2="0.5" id="Line-3" />
						<path
							d="M3,9 L3,6 C3,5.44771525 3.44771525,5 4,5 L12,5 C12.5522847,5 13,5.44771525 13,6 L13,20 C13,20.5522847 12.5522847,21 12,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,17 L3,17"
							id="Rectangle"
							transform="translate(8.000000, 13.000000) rotate(90.000000) translate(-8.000000, -13.000000) "
						/>
						<polyline id="Path-4" points="3.5 4.5 8 0 12.5 4.5" />
					</g>
				</g>
			</g>
		</svg>
	);
};

export default ShareIcon;
