import * as React from "react"
import {SVGAttributes} from "react";

const SvgComponent = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--gray-dark)" } = props;
	return (
		<svg width={18} height={19} viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g stroke={stroke} fill="none" fillRule="evenodd">
				<path
					d="M.5 3.49C.5 1.84 1.765.5 3.324.5h11.352c1.56 0 2.824 1.34 2.824 2.99v12.02c0 1.651-1.264 2.99-2.824 2.99H3.324C1.764 18.5.5 17.161.5 15.51"
					strokeLinecap="round"
				/>
				<path d="M17.419 16.216A7 7 0 0 1 8.439 9.5a7 7 0 0 1 8.978-6.716" />
				<path d="M8.998 11.78s2.346 1.03 6.44 1.03c.747 0 1.436-.034 2.062-.09M9.12 7.087s2.274.849 6.245.849c.778 0 1.49-.033 2.135-.085" />
				<path d="M13.826 2.856s-2.387 7.154 0 13.287" />
				<path
					strokeLinecap="round"
					d="M.5 9.5h5.638M3.658 6.711 6.447 9.5l-2.79 2.789"
				/>
			</g>
		</svg>
	)
}

export default SvgComponent
