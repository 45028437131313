import React, { useMemo, useRef, useState } from "react";
import ProgressBar from "../../progress-bar/ProgressBar";
import { ClearNewPostTitleIcon, ImageErrorIcon } from "../../assets/icons";
import { classNames } from "../../common";
import { Spinner } from "../../spinner";
import RestrictedContentOverlay from "../RestrictedContentOverlay";

interface StyledComponentProps { [key: string]: any; }
export const SC: StyledComponentProps = {};

interface PhotoProps {
	imgSrc: string;
	openLightbox: any;
	isContentRestricted: boolean;
	imageErrorMsg: string;
	contentSensitiveMsg: string;
	showRemoveIcon?: boolean;
	removeImage?: (img: string) => void;
	isCompressing?: boolean;
	progress?: number;
	imgErrorStyle?: string;
	aspectRatio?: string | null;
	isSingleImage?: boolean;
	imageTitle?: string;
}

export const Photo: React.FC<PhotoProps> = (props) => {
	const {
		imgSrc, openLightbox, isContentRestricted, imageErrorMsg, contentSensitiveMsg, imageTitle,
		showRemoveIcon, removeImage, isCompressing, progress, imgErrorStyle, aspectRatio, isSingleImage,
	} = props;
	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(false);
	const [showContentSensitiveOverlay, setShowContentSensitiveOverlay] = useState(isContentRestricted);
	// Not able to set the property using ref so have to use state to update the style of img
	const [currentAspectRatio, setAspectRatio] = useState({
		isLandscape: true,
		ratio: 16 / 9,
		showOriginalRatio: false,
	});

	const aspectRatioMemoized = useMemo(() => {
		if (currentAspectRatio.showOriginalRatio) {
			return String(currentAspectRatio.ratio);
		}
		return currentAspectRatio.isLandscape ? "16/9" : "4/5";
	}, [aspectRatio, currentAspectRatio]);

	const imageRef = useRef<HTMLImageElement>(null);

	const renderImageLoadingAndError = () => {
		if (isLoading && isError) {
			return (
				<div className={classNames("flex flex-col absolute flex-1 w-full h-full items-center justify-center",
					"bg-input-bg rounded-15 ", imgErrorStyle)}
				>
					<ImageErrorIcon xlinkTitle={imageErrorMsg} />
					<span className="text-gray-dark text-xs font-normal pt-1">{imageErrorMsg}</span>
				</div>
			);
		}
		if (!isError && isCompressing) {
			return (
				<div className="flex absolute flex-col z-10 flex-1 w-full h-full bg-modalTransparentBackground items-start justify-end">
					<div className="w-1/2 h-[5px] m-5 rounded-full">
						<ProgressBar percent={progress || 0} isLoading={false} size="medium" />
					</div>
					{showRemoveIcon ? (
						<button
							type="button"
							className="z-100 absolute right-3 top-3"
							onClick={(e) => { e.stopPropagation(); if (removeImage) removeImage(imgSrc ?? ""); }}
						>
							<ClearNewPostTitleIcon
								stroke="var(--tag-remove)"
								fill="#ffffff"
								width={20}
								height={20}
								className="bg-modalTransparentBackground rounded-full p-1"
							/>
						</button>
					)
						: null}
				</div>
			);
		}
		if (isLoading) {
			return (
				<div className="flex absolute flex-1 w-full h-full items-center justify-center bg-input-bg rounded-15">
					<Spinner
						width="30"
						height="30"
						leaf_fill="var(--primary)"
					/>
				</div>
			);
		}
		return null;
	};

	const onImageClick = () => {
		if (!showContentSensitiveOverlay) {
			openLightbox(imgSrc);
		}
	};

	const handleOnLoad = () => {
		const aspectRatioOfLoaded = imageRef.current ? imageRef?.current?.width / imageRef?.current?.height : 1;
		if (imageRef.current
			&& isSingleImage
		) {
			setAspectRatio({
				isLandscape: !(imageRef?.current?.height >= imageRef?.current?.width),
				ratio: aspectRatioOfLoaded,
				showOriginalRatio: aspectRatioOfLoaded > 0.8 && aspectRatioOfLoaded < 1.5,
			});
		}
		setIsLoading(false);
	};

	return (
		<div
			className="flex flex-1 relative cursor-pointer border-2 border-transparent overflow-hidden rounded-15"
			onClick={onImageClick}
			style={{
				// Tailwind class is not working named aspect-video
				aspectRatio: aspectRatio ?? aspectRatioMemoized,
			}}
			aria-hidden
		>
			{isSingleImage && (
				<img
					ref={imageRef}
					loading="lazy"
					src={imgSrc}
					alt="post-img-hidden"
					style={{
						position: "absolute",
						visibility: "hidden",
					}}
					className={classNames(
						"bg-center bg-cover bg-no-repeat w-full object-cover",
						isError ? "p-1" : "",
					)}
					onLoad={handleOnLoad}
					onError={() => setIsError(true)}
				/>
			)}
			<img
				loading="lazy"
				src={imgSrc}
				alt={imageTitle}
				style={showContentSensitiveOverlay ? {
					filter: "blur(40px)",
					aspectRatio: aspectRatio ?? aspectRatioMemoized,
				} : {
					aspectRatio: aspectRatio ?? aspectRatioMemoized,
				}}
				className={classNames(
					"bg-center bg-cover bg-no-repeat w-full object-cover",
					isError ? "p-1" : "",
				)}
				onLoad={() => setIsLoading(false)}
				onError={() => setIsError(true)}
			/>
			{renderImageLoadingAndError()}
			{!isError
				? (
					<RestrictedContentOverlay
						isContentRestricted={showContentSensitiveOverlay}
						title={contentSensitiveMsg}
						buttonTitle="See Image"
						onShowSensitiveContent={() => setShowContentSensitiveOverlay(!showContentSensitiveOverlay)}
					/>
				) : null}
			{showRemoveIcon && (
				<button
					type="button"
					className="z-100 absolute right-3 top-3"
					onClick={(e) => { e.stopPropagation(); if (removeImage) removeImage(imgSrc ?? ""); }}
				>
					<ClearNewPostTitleIcon
						stroke="var(--tag-remove)"
						fill="#ffffff"
						width={20}
						height={20}
						className="bg-modalTransparentBackground rounded-full p-1"
					/>
				</button>
			)}
		</div>
	);
};

Photo.defaultProps = {
	showRemoveIcon: false,
	removeImage: (img) => { },
	isCompressing: false,
	progress: 0,
	imgErrorStyle: "",
	aspectRatio: null,
	isSingleImage: false,
	imageTitle: "",
};

interface RowPhotosProps {
	height: string;
	photos: any;
	openLightbox: any;
	isContentRestricted: boolean;
	imageErrorMsg: string;
	contentSensitiveMsg: string;
	noOfPhotos: number;
	showRemoveIcon?: boolean;
	removeImage?: (img: string) => void;
	imgErrorStyle?: string;
}
const RowPhotos: React.FC<RowPhotosProps> = (props) => {
	const {
		height, photos, openLightbox, isContentRestricted, imageErrorMsg, contentSensitiveMsg, noOfPhotos,
		showRemoveIcon, removeImage, imgErrorStyle,
	} = props;

	return (
		<div
			className={classNames(
				"flex box-border",
				noOfPhotos === 3 ? "flex-col w-1/2" : "",
			)}
		>
			{
				photos.map((data: any, i: number) => (
					<Photo
						// eslint-disable-next-line react/no-array-index-key
						key={i}
						imgSrc={data.source}
						openLightbox={openLightbox}
						isContentRestricted={isContentRestricted}
						imageErrorMsg={imageErrorMsg}
						contentSensitiveMsg={contentSensitiveMsg}
						showRemoveIcon={showRemoveIcon}
						removeImage={removeImage}
						isCompressing={data?.isCompressing || false}
						progress={data?.progress || 0}
						imgErrorStyle={imgErrorStyle}
						isSingleImage={photos.length === 1}
					/>
				))
			}
		</div>
	);
};

RowPhotos.defaultProps = {
	showRemoveIcon: false,
	removeImage: (img) => { },
	imgErrorStyle: "",
};
interface ImagePostProps {
	width: string;
	height: Array<string>;
	layout: Array<number>;
	layoutPhotoMaps: any;
	openLightbox: any;
	isContentRestricted: boolean;
	imageErrorMsg: string;
	contentSensitiveMsg: string;
	noOfPhotos: number;
	showRemoveIcon?: boolean;
	removeImage?: (img: string) => void;
	imgErrorStyle?: string;
}

export const ImagePost: React.FC<ImagePostProps> = React.memo((props) => {
	const {
		width, height, layout, layoutPhotoMaps, openLightbox, isContentRestricted, imageErrorMsg, contentSensitiveMsg, noOfPhotos,
		showRemoveIcon, removeImage, imgErrorStyle,
	} = props;
	return (
		<div
			style={{ width }}
			className={noOfPhotos === 3 ? "flex flex-row" : ""}
		>
			{
				layout.map((data, i) => (
					<RowPhotos
						// eslint-disable-next-line react/no-array-index-key
						key={i}
						height={height[i]}
						photos={layoutPhotoMaps[i]}
						openLightbox={openLightbox}
						isContentRestricted={isContentRestricted}
						imageErrorMsg={imageErrorMsg}
						contentSensitiveMsg={contentSensitiveMsg}
						noOfPhotos={noOfPhotos}
						showRemoveIcon={showRemoveIcon}
						removeImage={removeImage}
						imgErrorStyle={imgErrorStyle}
					/>
				))
			}
		</div>
	);
});

ImagePost.defaultProps = {
	showRemoveIcon: false,
	removeImage: (img) => { },
	imgErrorStyle: "",
};
