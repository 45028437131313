/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import {
	createSearchParams, useNavigate,
} from "react-router-dom";
import Hero from "../../gen-comp/Hero";
import { strings } from "../../i18n/config";
import Filter from "../../gen-comp/Filter";
import Table from "../../gen-comp/table";
import {
	DropdownItem, Modes, TableHeader,
} from "../../utils/types";
import {
	TimestampsOptions, UserTableHeaders,
} from "./constants";
import { getNewUsersQueries } from "./queries";
import { PAGINATION_LIMIT } from "../../utils/constants";
import { DATE_FORMAT } from "../../utils/utils";
import Accordion from "../../gen-comp/Accordion";
import { AUTH_URL, USER_DETAILS_PAGE } from "../../routes/constants";
import { useQuery } from "../../hooks/graphql/useQuery";

export default function NewUsersPage() {
	const navigate = useNavigate();
	const [headers, setHeaders] = useState<TableHeader[]>(UserTableHeaders.filter((e) => e.isDefault));
	const [userData, setUserData] = useState<any>([]);
	const [page, setPage] = useState<number>(1);
	const [changeInPage, setChangeInPage] = useState<boolean>(true);
	const [timestamp, setTimestamps] = useState<DropdownItem[]>(TimestampsOptions.filter((e) => e.value === "24"));

	const wrapperRef = useRef(null);

	const applyFilter = (items: TableHeader[]) => {
		setHeaders(items);
	};

	const applyTimestampFilter = (items: DropdownItem[]) => {
		setTimestamps(items);
	};

	const navigateToUserDetails = (item: any) => {
		navigate({
			pathname: AUTH_URL + USER_DETAILS_PAGE,
			search: `?${createSearchParams({ userId: item?.user_id })}`,
		});
	};

	const variables: any = {
		headers: UserTableHeaders,
		offset: (page - 1) * PAGINATION_LIMIT,
		timestamp,
	};

	const [getNewUsersData, { data, loading }] = useQuery<Array<Object>>(getNewUsersQueries(variables), {
		formatDataKey: "user",
		avoidUpdateWithQuery: true,
	});

	useEffect(() => {
		setPage(1);
		setChangeInPage(true);
	}, [timestamp]);

	useEffect(() => {
		if (changeInPage) {
			getNewUsersData();
		}
	}, [page, changeInPage]);

	useEffect(() => {
		const items: any[] = [];
		data?.forEach((element: any) => {
			items.push({
				...element,
				access_ts: element?.access_ts
					? moment(new Date(element.access_ts)).format(DATE_FORMAT) : strings("UserSearchPage.not_available"),
				uname_change_expiry_ts: element?.uname_change_expiry_ts
					? moment(new Date(element.uname_change_expiry_ts)).format(DATE_FORMAT) : strings("UserSearchPage.not_available"),
				hf_access_ts: element?.hf_access_ts
					? moment(new Date(element.hf_access_ts)).format(DATE_FORMAT) : strings("UserSearchPage.not_available"),
				cmodif_ts: element?.cmodif_ts
					? moment(new Date(element.cmodif_ts)).format(DATE_FORMAT) : strings("UserSearchPage.not_available"),
				emodif_ts: element?.emodif_ts
					? moment(new Date(element.emodif_ts)).format(DATE_FORMAT) : strings("UserSearchPage.not_available"),
				modif_egouname_ts: element?.modif_egouname_ts
					? moment(new Date(element.modif_egouname_ts)).format(DATE_FORMAT) : strings("UserSearchPage.not_available"),
				creat_ts: element?.creat_ts
					? moment(new Date(element.creat_ts)).format(DATE_FORMAT) : strings("UserSearchPage.not_available"),
				modif_ts: element?.modif_ts
					? moment(new Date(element.modif_ts)).format(DATE_FORMAT) : strings("UserSearchPage.not_available"),
				c_reported_ts: element?.c_reported_ts
					? moment(new Date(element.c_reported_ts)).format(DATE_FORMAT) : strings("UserSearchPage.not_available"),
				e_reported_ts: element?.e_reported_ts
					? moment(new Date(element.e_reported_ts)).format(DATE_FORMAT) : strings("UserSearchPage.not_available"),
				status_modif_ts: element?.status_modif_ts
					? moment(new Date(element.status_modif_ts)).format(DATE_FORMAT) : strings("UserSearchPage.not_available"),
			});
		});
		setUserData(items);
		setChangeInPage(false);
	}, [data]);

	const redirectToUserDetailsPageWithMode = (item: any) => {
		navigate({
			pathname: AUTH_URL + USER_DETAILS_PAGE,
			search: `?${createSearchParams({
				userId: item?.user_id,
				mode: item?.mode,
			})}`,
		});
	};

	const renderColumn = (props: { item: any; header: any; }) => {
		const { item, header } = props;
		if (!header || !item) {
			return null;
		}
		return (
			<button
				type="button"
				className="w-full truncate underline text-blue-600 hover:text-blue-800 "
				onClick={() => redirectToUserDetailsPageWithMode({
					...item,
					mode: header.value.includes("e_") ? Modes.EGO : Modes.CREDO,
				})}
			>
				{item[header.value] || ""}
			</button>
		);
	};

	return (
		<div ref={wrapperRef}>
			<Hero title={strings("NewUsersPage.hero_title")} pageHasTable />
			<div data-testid="search-card" className="bg-white p-5 rounded-lg -mt-24 mx-5 flex flex-col mb-5">
				<Accordion
					header={(
						<h5 className="mb-1">
							{strings("UserSearchPage.filter")}
						</h5>
					)}
					content={(
						<div className="grid grid-cols-1 xs:grid-cols-2-max-20 sm:grid-cols-3-max-20 gap-2 lg:gap-4">
							<Filter
								id="column-filters"
								data={UserTableHeaders}
								filterName={strings("Dashboard.table_title")}
								applyFilter={applyFilter}
								selectedData={headers}
								showTitle={false}
								local_storage_key="user-headers"
							/>
							<Filter
								id="user-mode-filter"
								data={TimestampsOptions}
								filterName={strings("NewUsersPage.timestamp")}
								applyFilter={applyTimestampFilter}
								selectedData={timestamp}
								showTitle={false}
								multiSelect={false}
							/>
						</div>
					)}
					defaultOpen
				/>
			</div>
			<div className="bg-white p-5 rounded-lg my-5 mx-5">
				<div data-testid="new-users-data-table" className="my-0">
					<Table
						header={headers}
						data={userData}
						pagination={{
							onPageChange: (num) => {
								setChangeInPage(true);
								setPage(num);
							},
							hidePageNumber: true,
							scrollOnPageChange: true,
							page,
						}}
						onItemClick={navigateToUserDetails}
						showHeader
						showSortIcons
						isLoading={loading}
						CustomColumnComponent={renderColumn}
					/>
				</div>
			</div>
		</div>
	);
}
