import * as React from "react"
import {SVGAttributes} from "react";

const SvgComponent = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { fill = "var(--gray-dark)" } = props;
	return (
		<svg
			width={40}
			height={40}
			viewBox="0 0 40 40"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
			stroke="none"
		>
			<defs>
				<path id="linkedin-a" d="M0 .842h18.934v18.092H0z" />
			</defs>
			<g fill="none" fillRule="evenodd">
				<path
					d="M21.613 18.626v-.075c-.016.025-.037.05-.05.075h.05Z"
					fill={fill}
				/>
				<g transform="matrix(1.5 0 0 1.5 6.5 3.5)">
					<mask id="linkedin-b" fill="#fff">
						<use xlinkHref="#linkedin-a" />
					</mask>
					<path
						d="M18.934 18.934h-4.058v-6.531c0-1.64-.587-2.76-2.056-2.76-1.121 0-1.789.754-2.082 1.484-.107.26-.135.624-.135.989v6.818h-4.06s.055-11.062 0-12.208h4.06v1.73c.54-.83 1.502-2.017 3.658-2.017 2.671 0 4.673 1.745 4.673 5.494v7.001ZM2.27 5.06h-.026C.882 5.06 0 4.122 0 2.95 0 1.752.908.842 2.297.842c1.389 0 2.242.91 2.269 2.108 0 1.172-.88 2.11-2.296 2.11ZM.241 18.934H4.3V6.726H.241v12.208Z"
						fill={fill}
						mask="url(#linkedin-b)"
					/>
				</g>
			</g>
		</svg>
	)
}

export default SvgComponent
