/* eslint-disable react/require-default-props */
import React, { Fragment, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { classNames } from "../common";

interface ConfirmationModalProps {
	/**
	 * When set true modal will be displayed.
	 * */
	isVisible: boolean;
	/**
	 * title for alert
	 * */
	title: string;
	/**
	 * message for alert
	 * */
	message: string;
	/**
	 * title for okay button
	 * */
	ok_button_title?: string;
	/**
	 * Callback executed when the okay button is pressed.
	 * */
	ok_button_function: () => void;
	/**
	 * title for cancel button
	 * */
	cancel_button_title?: string;
	/**
	 * Callback executed when the cancel button is pressed.
	 * */
	cancel_button_function: () => void;
	/**
	 * Callback executed when the close button is pressed.
	 * */
	onClose: () => void;
	/**
	 * CSS style class for okay button
	 * */
	okButtonStyle?: string;
	/**
	 * CSS style class for cancel button
	 * */
	cancelButtonStyle?: string;
}

export default function ConfirmationModal({
	isVisible,
	title,
	message,
	ok_button_title,
	ok_button_function,
	cancel_button_title,
	cancel_button_function,
	onClose,
	okButtonStyle,
	cancelButtonStyle,
}: ConfirmationModalProps) {
	const cancelButtonRef = useRef(null);

	useEffect(() => () => {
		onClose();
	}, []);

	return (
		<div>
			<Transition.Root show={isVisible} as={Fragment}>
				<Dialog
					as="div"
					className="fixed z-[300] w-full h-full"
					initialFocus={cancelButtonRef}
					/**
					* Confirmation modal should not be closed by clicking outside the modal
					* user should click the options from the modal.
					* */
					onClose={() => null}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity bg-modalTransparentBackground" />
					</Transition.Child>
					<div className="fixed z-10 inset-0 overflow-y-auto">
						<div className="flex sm:items-center items-center xs:items-center justify-center min-h-full p-4 text-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel
									className={classNames("fixed bg-background-primary rounded-lg text-left",
										"overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full")}
								>
									<div className="bg-background-primary px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
										<div className="sm:flex sm:items-start">
											<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
												<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-title-color capitalize">
													{title}
												</Dialog.Title>
												<div className="mt-2">
													<p className="text-sm text-title-color whitespace-pre-wrap">
														{message}
													</p>
												</div>
											</div>
										</div>
									</div>
									<div
										className={classNames("px-4 py-3 bg-background-primary",
											"sm:px-6 flex flex-row-reverse xs:justify-center",
											"sm:items-center sm:justify-start items-center justify-center sm:mb-0 mb-5 xs:mb-5")}
									>
										<div data-testid="ok-button-0 p-1">
											{ok_button_title && (
												<button
													type="button"
													className={classNames("w-24 h-7 rounded-full text-xs font-light uppercase",
														"border-primary text-background bg-primary",
														"bg-primary border overflow-hidden text-ellipsis text-background",
														"outline-none",
														okButtonStyle!)}
													onClick={ok_button_function}
												>
													{ok_button_title}
												</button>
											)}
										</div>
										<div data-testid="cancel-button-0" className="sm:mr-5 xs:mr-0 mr-0 p-1">
											{cancel_button_title && (
												<button
													type="button"
													className={classNames("bg-transparent border overflow-hidden text-ellipsis",
														"border-primary text-primary",
														"w-24 h-7 rounded-full text-xs font-light uppercase",
														"outline-none",
														cancelButtonStyle!)}
													onClick={cancel_button_function}
													ref={cancelButtonRef}
												>
													{cancel_button_title}
												</button>
											)}
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</div>
	);
}

ConfirmationModal.defaultProps = {
	okButtonStyle: "",
	cancelButtonStyle: "",
};
