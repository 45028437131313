/* eslint-disable react/no-array-index-key */
import React, { SVGAttributes, useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import { cu } from "@credo/utilities";
import { classNames } from "../common";
import { ContentPanel } from "./ContentPanel";

export interface TabInterface {
	[key: string]: any;
}

/**
 * TODO: Change the data type of tab_categories
 * it should be array of objects instead of object
 * */

// eslint-disable-next-line no-undef
interface CredoTabProps extends SVGAttributes<HTMLOrSVGElement> {
	/**
	 * tab data
	 * @default {}
	 * */
	tab_categories: TabInterface;
	/**
	 * Custom CSS class for tab
	 * @default ""
	 * */
	tabStyle?: string;
	/**
	 * Custom CSS class for tab container
	 * @default ""
	 * */
	tabContainerStyle?: string;
	/**
	 * Custom CSS class for tab header
	 * @default {}
	 * */
	tabHeaderStyle?: {};
	/**
	 * Tab container wrapper css classes
	 * */
	tabContainerWrapperStyle?: string;
	/**
	 * If tab contains any badge component we can pass that
	 * component directly using a key value pair. Where the
	 * key will be the same index as the categories.
	 * */
	badges?: {
		[key: string]: any;
	}
	/**
	 * Capture the index on which the tab is switched
	 * */
	onChangeTab?: (index: number) => void;
	/**
	 * Default active tab which will be set as active on
	 * mount
	 * */
	defaultActiveTab?: number;
	tabHeaderClass?: string;
	/**
	 * When user clicks on tab we can listen and fire any
	 * event for that.
	 * */
	onClickTab?: (index: number) => void;
	/**
	 * incase there is a need to render custom component
	 * instead of a normal label. Pass that component in
	 * that specific index.
	 * */
	customTabs?: Array<React.ReactNode>;
}

export function CredoTab(props: CredoTabProps) {
	const {
		tab_categories,
		tabStyle,
		tabContainerStyle,
		tabHeaderStyle,
		tabContainerWrapperStyle,
		badges,
		defaultActiveTab,
		onChangeTab,
		tabHeaderClass,
		onClickTab,
		customTabs,
	} = props;

	const [activeTab, setActiveTab] = useState<number>(defaultActiveTab || 0);
	/**
	 * To render the tabs only when they are active at least once, we are maintaining
	 * an array for active tabs.
	 * */
	const [mountedContent, setMountedContent] = useState<number[]>([defaultActiveTab || 0]);

	useEffect(() => {
		if (cu.isSet(defaultActiveTab) && defaultActiveTab !== activeTab) {
			// @ts-ignore we are already checking if prop isSet, was giving lint error can be undefined
			setActiveTab(defaultActiveTab);
			// @ts-ignore we are already checking if prop isSet, was giving lint error can be undefined
			setMountedContent([defaultActiveTab]);
		}
	}, [defaultActiveTab]);

	if (!tab_categories) {
		return null;
	}

	const renderBadges = (category: string) => {
		if (badges && Object.keys(badges).length > 0) {
			return badges[category];
		}

		return null;
	};

	const handleChangeTab = (index: number) => {
		setActiveTab(index);

		if (!mountedContent.includes(index)) {
			setMountedContent((prevState) => [
				...prevState,
				index,
			]);
		}

		if (onChangeTab) {
			onChangeTab(index);
		}
	};

	const handleTabOnClick = (index: number) => {
		if (onClickTab) {
			onClickTab(index);
		}
	};

	return (
		<div className="w-full">
			<Tab.Group selectedIndex={activeTab} onChange={handleChangeTab}>
				<Tab.List
					className={classNames(
						"flex flex-row justify-start items-start",
						tabHeaderClass,
					)}
					style={tabHeaderStyle}
				>
					{Object.keys(tab_categories).map((category, idx) => (
						<Tab
							key={idx}
							className={({ selected }) => classNames(
								"w-full py-2.5 text-xs font-medium leading-5 uppercase focus:outline-none focus:ring-offset-0",
								selected
									? "border-b border-primary text-primary"
									: "text-gray-dark",
								"relative",
								tabStyle,
							)}
							onClick={() => handleTabOnClick(idx)}
						>
							<span
								className={classNames(
									"relative",
									tabStyle,
								)}
							>
								{customTabs?.[idx] ?? category}
								{renderBadges(category)}
							</span>
						</Tab>
					))}
				</Tab.List>
				<ContentPanel
					tabContainerWrapperStyle={tabContainerWrapperStyle}
					tab_categories={tab_categories}
					mountedContent={mountedContent}
					tabContainerStyle={tabContainerStyle}
					activeTab={activeTab}
				/>
			</Tab.Group>
		</div>
	);
}

CredoTab.defaultProps = {
	tabStyle: "",
	tabContainerStyle: "",
	tabHeaderStyle: {},
	tabContainerWrapperStyle: "",
	badges: {},
	onChangeTab: () => {},
	defaultActiveTab: 0,
	tabHeaderClass: "",
	onClickTab: () => {},
	customTabs: [],
};
