import React, { useMemo } from "react";
import {
	DeviceDetectMgr,
	PostTag,
} from "@credo/utilities";
import {
	classNames, SvgIcon,
} from "../../common";
import { XIcon } from "../../assets/icons";
import { PostHeaderItem } from "../post-header/PostHeader";
import { ModalPopup, ModalState } from "../../credo-tag";
import { PostHeaderConst } from "../types";
import { PostTags, PostTagsProps } from "../PostTags";
import styles from "../../credo-tag/indicator-html.module.css";
import { AuthorTagsGraphIcon } from "./AuthorTagsGraphIcon";

interface ExtraOptionalPostDataItems {
	commentUuid?: string | null;
	parentCommentUuid?: string | null;
	profId?: string | null;
	profilePicRelUrl?: string | null;
	prof_id?: string | null;
}

interface AuthorTagsModalProps {
	tagData: PostTag[];
	postData: PostHeaderItem & ExtraOptionalPostDataItems;
	profilePicComp: React.ReactNode;
	nameComp: React.ReactNode;
	closeAuthorTagsModal: () => void;
	postTagProps?: PostTagsProps;
}

// eslint-disable-next-line import/prefer-default-export
export const AuthorTagsModal = ({
	tagData,
	postData,
	nameComp,
	profilePicComp,
	closeAuthorTagsModal,
	postTagProps,
}: AuthorTagsModalProps) => {
	const isCommentsAuthorTags = useMemo(() => postData?.commentUuid, [postData]);
	const isSubCommentsAuthorTags = useMemo(() => postData?.parentCommentUuid, [postData]);

	// eslint-disable-next-line no-nested-ternary
	const commentOffset = isCommentsAuthorTags ? 62 : isSubCommentsAuthorTags ? 47 : 32;

	const profIdForGraph = useMemo(() => postData?.prof_id || postData?.profId || postData["p.prof_id"], [postData]);
	const profilePicForGraph = useMemo(
		() => postData?.profilePicRelUrl || (postData["p.shared_profilePicRelUrl"] || postData["p.profilePicRelUrl"]),
		[postData],
	);

	const onPressGraphIcon = () => {
		closeAuthorTagsModal();
	};

	return (
		<ModalPopup
			style={{
				left: 16,
				top: -60,
				zIndex: 203,	// greater than app header and bottom nav menu
			}}
			onModalStateChange={(state) => {
				if (state === ModalState.Closing) closeAuthorTagsModal();
			}}
		>
			<div
				className={classNames(
					styles.authorTagsModal,
					"p-3 rounded-[30px] border border-gray-dark",
					DeviceDetectMgr.isMobile() ? `w-[calc(100vw-${commentOffset}px)]` : "w-[450px]",
				)}
				onMouseLeave={closeAuthorTagsModal}
				style={{
					// @ts-ignore
					"backdrop-filter": "blur(10px)",
					"-webkit-backdrop-filter": "blur(10px)",
					filter: "blur(0)",
					borderRadius: 30,
				}}
			>
				<div className="flex flex-row w-full">
					{profilePicComp}
					<div className="flex flex-row justify-between w-full">
						<div className="flex flex-col ml-1.5">
							{nameComp}
						</div>
						<div
							className={classNames(
								"bg-gray-darker border border-white rounded-full p-1",
								"flex justify-center items-center h-5 w-5 cursor-pointer",
							)}
							onClick={closeAuthorTagsModal}
							aria-hidden
						>
							<SvgIcon
								icon={XIcon}
								height={12}
								width={12}
							/>
						</div>
					</div>
				</div>
				<p className="text-sm text-gray-dark mt-3 font-semibold">
					{PostHeaderConst.TAGS_IN_CONTEXT}
				</p>
				<div className="flex flex-row w-full flex-1 justify-between items-start">
					<div className="flex-4">
						{postTagProps
							&& (
								<PostTags
									{...postTagProps}
									containerStyle="!bg-transparent !px-0 mt-2"
									credoTagId="author-modal/credo-tag"
								/>
							)}
					</div>
					{profIdForGraph && (
						<div className="mt-2">
							<AuthorTagsGraphIcon
								id={postData["p.postUuid"]}
								prof_id={profIdForGraph}
								postUuid={postData["p.postUuid"]}
								profilePicUrl={profilePicForGraph}
								onPressGraphIcon={onPressGraphIcon}
							/>
						</div>
					)}
				</div>
			</div>
		</ModalPopup>
	);
};

AuthorTagsModal.defaultProps = {
	postTagProps: null,
};
