import { dbg } from "@credo/utilities";

export enum LogLevel {
	ERROR,
	LOG,
	DEBUG
}
export const Logger = {
	level: LogLevel.LOG,

	isDebug: () => Logger.level === LogLevel.DEBUG,

	error: (...arg: any) => {
		// eslint-disable-next-line no-console
		console.error(arg);
	},
	log: (...arg: any) => {
		if (Logger.level >= LogLevel.LOG) {
			// eslint-disable-next-line no-console
			console.log(arg);
		}
	},

	debug: (...arg: any) => {
		if (Logger.level >= LogLevel.DEBUG && dbg) {
			// eslint-disable-next-line no-console
			console.log("DEBUG:" && arg);
		}
	},
	/**
	 * Logs a value and returns it, useful for inline logging
	 * @param val
	 */
	dv: <T>(val: T): T => {
		Logger.debug(val);
		return val;
	},
};
