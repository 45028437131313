import React, { SVGAttributes } from "react";

// eslint-disable-next-line import/prefer-default-export
const ReportIssueSuccess = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--primary)" } = props;
	return (
		<svg
			width="53px"
			height="54px"
			viewBox="0 0 53 54"
			version="1.1"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Issue-Reported" transform="translate(-4.000000, -3.000000)" stroke={stroke}>
					<g id="ReportIssueSuccess" transform="translate(4.000000, 3.000000)">
						<polyline id="Stroke-1" points="7.889 38.2439 7.889 0.4999 40.384 0.4999 40.384 20.4999" />
						<polyline id="Stroke-3" points="40.3837 28.8362 40.3837 46.9722 15.2777 46.9722" />
						<line x1="13.1696" y1="30.8183" x2="31.5306" y2="30.8183" id="Stroke-4" />
						<line x1="13.1696" y1="38.2439" x2="34.4956" y2="38.2439" id="Stroke-5" />
						<g id="Group-24" transform="translate(0.000000, 3.522000)">
							<path
								d="M35.7675,30.6708 L30.6825,32.6448 L31.7355,27.2968 L47.3475,8.6448 C48.2795,7.5308 49.9365,7.3838 51.0505,8.3158 L51.0505,8.3158 C52.1635,9.2478 52.3105,10.9058 51.3785,12.0188 L35.7675,30.6708 Z"
								id="Stroke-6"
							/>
							<line x1="31.7358" y1="27.2963" x2="35.7678" y2="30.6703" id="Stroke-8" />
							<line x1="46.3402" y1="9.8477" x2="50.3722" y2="13.2217" id="Stroke-10" />
							<line x1="46.9308" y1="13.2378" x2="34.9528" y2="27.5488" id="Stroke-12" />
							<path
								d="M33.4105,20.2142 L14.8625,20.2142 C13.7875,20.2142 13.1085,19.0602 13.6305,18.1202 L22.9045,1.4272 C23.4415,0.4602 24.8315,0.4602 25.3685,1.4272 L34.6425,18.1202 C35.1645,19.0602 34.4855,20.2142 33.4105,20.2142 Z"
								id="Stroke-14"
							/>
							<polygon id="Stroke-16" points="24.9277 14.1312 23.3447 14.1312 22.6257 6.8852 25.6467 6.8852" />
							<path
								d="M25.2001,16.5817 C25.2001,17.1697 24.7241,17.6457 24.1361,17.6457 C23.5491,17.6457 23.0721,17.1697 23.0721,16.5817 C23.0721,15.9947 23.5491,15.5177 24.1361,15.5177 C24.7241,15.5177 25.2001,15.9947 25.2001,16.5817 Z"
								id="Stroke-18"
							/>
							<path
								d="M15.2781,42.111 C15.2781,46.192 11.9701,49.5 7.8891,49.5 C3.8081,49.5 0.5001,46.192 0.5001,42.111 C0.5001,38.03 3.8081,34.722 7.8891,34.722 C11.9701,34.722 15.2781,38.03 15.2781,42.111 Z"
								id="Stroke-20"
							/>
							<polyline id="Stroke-22" points="5.1545 42.111 7.8895 44.574 11.5495 39.5" />
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default ReportIssueSuccess;
