import { EgoAvatarPlaceholderIcon, SvgIcon } from "@credo/ui-components";
import { cu } from "@credo/utilities";
import React from "react";
import classNames from "../utils/classNames";

interface ProfilePictureProps {
	profilePicUrl: string;
	profilePicStyle?: string;
	profilePicWrapperStyle?: string;
	isUser?: boolean;
}

export default function ProfilePicture(props: ProfilePictureProps) {
	const {
		profilePicUrl, profilePicStyle, profilePicWrapperStyle, isUser,
	} = props;

	const renderDefaultUserProfile = () => (
		<div
			className={classNames(
				"relative -top-16",
				profilePicWrapperStyle!,
			)}
		>
			{isUser
				? (
					<SvgIcon
						icon={EgoAvatarPlaceholderIcon}
						className={classNames("w-28 h-28 border-solid border-white border rounded-lg", profilePicStyle!)}
					/>
				)
				: (
					<img
						src={cu.buildSourceUrlImage("s_pp_dg.png") || ""}
						className={classNames("w-28 h-28 border-solid border-white border rounded-lg", profilePicStyle!)}
						alt=""
					/>
				)}
		</div>
	);

	if (!profilePicUrl) {
		return renderDefaultUserProfile();
	}

	return (
		<div
			className={classNames(
				"relative -top-16",
				profilePicWrapperStyle!,
			)}
		>
			<img
				src={profilePicUrl}
				className={classNames("w-28 h-28 border-solid border-white border rounded-lg", profilePicStyle!)}
				alt=""
			/>
		</div>
	);
}

ProfilePicture.defaultProps = {
	profilePicStyle: "",
	profilePicWrapperStyle: "",
	isUser: true,
};
