import * as React from "react"
import {SVGAttributes} from "react";

const SvgComponent = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { stroke = "var(--accent)" } = props;
	return (
		<svg width={32} height={32} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
			<title>{"searchMessageIcon_ego"}</title>
			<g stroke={stroke} fill="none" fillRule="evenodd">
				<path d="m22.188 18.818 7.252 7.252c1.234 1.234 1.481 2.99.55 3.92-.931.93-2.685.685-3.92-.55l-7.253-7.252" />
				<path d="M24.406 12.703c0 6.463-5.24 11.703-11.703 11.703C6.24 24.406 1 19.166 1 12.703 1 6.24 6.24 1 12.703 1c6.463 0 11.703 5.24 11.703 11.703Z" />
				<path d="M7.657 17.749a5.047 5.047 0 0 1 10.093 0" />
			</g>
		</svg>
	)
}

export default SvgComponent
