import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { classNames } from "../common";

const OtherUserProfileSkeleton = () => (
	<SkeletonTheme
		baseColor="var(--background-skeleton)"
		highlightColor="var(--hightlight-skeleton)"
	>
		<div className="w-full">
			<Skeleton className={classNames("metric-card rounded-lg p-4 h-32 w-full")} />
		</div>
		<div className="flex flex-col w-full justify-center items-center">
			<Skeleton className={classNames("-top-16 rounded-full")} circle width={120} height={120} />
			<Skeleton count={3} width={200} height={15} className={classNames("relative mb-1 -top-12")} />
		</div>
	</SkeletonTheme>
);

export default OtherUserProfileSkeleton;
