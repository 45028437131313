/* eslint-disable react/destructuring-assignment */
import React from "react";
import classNames from "../utils/classNames";
import "./css/Loader.css";

export default function Loader(props: { loading: any; }) {
	return (
		props.loading ? <div className={classNames("cover-spin", "bg-dark-shadow opacity-90")} /> : null
	);
}
