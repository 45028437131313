import React, { SVGAttributes } from "react";

// eslint-disable-next-line import/prefer-default-export
const Pencil = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--pencil-color)" } = props;
	return (
		<svg
			width="22px"
			height="22px"
			viewBox="0 0 22 22"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<g id="Profile---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-icons" transform="translate(-373.000000, -762.000000)" stroke={stroke}>
					<g
						id="pencil_icon"
						transform="translate(384.000000, 773.000000) rotate(45.000000) translate(-384.000000, -773.000000) translate(381.000000, 761.000000)"
					>
						<path
							d="M3,0.5 C3.69035594,0.5 4.31535594,0.779822031 4.76776695,1.23223305 C5.22017797,1.68464406 5.5,2.30964406 5.5,3 L5.5,3 L5.5,16.6968426 L3.4474252,21.0416773 L0.604281454,17.1358456 L0.5,3 C0.5,2.30964406 0.779822031,1.68464406 1.23223305,1.23223305 C1.68464406,0.779822031 2.30964406,0.5 3,0.5 Z"
							id="Rectangle"
						/>
						<line x1="3" y1="5.52168702" x2="3" y2="16.5" id="Line" strokeLinecap="round" />
						<line x1="5" y1="3" x2="1" y2="3" id="Line-Copy" strokeLinecap="round" />
					</g>
				</g>
			</g>
		</svg>
	);
};

export default Pencil;
