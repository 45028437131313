import React from "react";
import { Logo as LogoComponent } from "@credo/ui-components";

interface LogoProps {
	logoClassName?: string;
	height?: number,
	width?: number,
	iconOnly?: boolean,
}

export default function Logo(props: LogoProps) {
	return (
		<div className="flex-shrink-0 items-center text-white flex justify-center py-1.5">
			<LogoComponent {...props} />
		</div>
	);
}

Logo.defaultProps = {
	logoClassName: "",
	height: 100,
	width: 150,
	iconOnly: false,
};
