export enum UCWSize {
  SMALL = "S",
  MEDIUM = "M",
  LARGE = "L",
  X_LARGE = "XL"
}

export enum UCWDimensions {
  S = 28,
  M = 40,
  L = 70,
  XL = 136
}

export enum UCWRadius {
  S = 1,
  M = 1.5,
  L = 3.5,
  XL = 8
}
