import React, { SVGAttributes } from "react";

// eslint-disable-next-line import/prefer-default-export
const CommentDeleted = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--gray-dark)" } = props;
	return (
		<svg
			width="18px"
			height="22px"
			viewBox="0 0 18 22"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<g id="Home-/-Feed---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Home-Feed-comment---Ego---Deleted-Comment" transform="translate(-52.000000, -334.000000)" stroke={stroke}>
					<g id="messages" transform="translate(17.000000, 303.000000)">
						<g id="deleted_comment_icon" transform="translate(44.000000, 41.666667) scale(-1, 1) translate(-44.000000, -41.666667) translate(36.000000, 31.000000)">
							<line x1="7.03173333" y1="10.0938667" x2="7.03173333" y2="18.0938667" id="Stroke-1" strokeLinecap="round" />
							<line x1="15.8546667" y1="9.01271111" x2="0.812" y2="1.3336" id="Stroke-2" strokeLinecap="round" />
							<line x1="3.47617778" y1="10.0938667" x2="4.36506667" y2="18.0938667" id="Stroke-4" strokeLinecap="round" />
							<line x1="10.5872889" y1="10.0938667" x2="9.6984" y2="18.0938667" id="Stroke-5" strokeLinecap="round" />
							<g id="Group-10" transform="translate(0.000000, 0.760622)">
								<path d="M6.16026667,2.80417778 L6.96915556,1.22106667 C7.19226667,0.783733333 7.72826667,0.609511111 8.16471111,0.833511111 L11.3309333,2.4504 C11.7691556,2.67351111 11.9424889,3.20862222 11.7193778,3.64595556 L10.9113778,5.22906667" id="Stroke-6" />
								<path d="M13.6194667,7.11102222 L11.4585778,19.2665778 C11.3830222,19.6905778 11.0141333,19.9999111 10.5839111,19.9999111 L3.4808,19.9999111 C3.04968889,19.9999111 2.6808,19.6905778 2.60524444,19.2665778 L0.444355556,7.11102222" id="Stroke-8" strokeLinecap="round" />
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default CommentDeleted;
