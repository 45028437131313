import { strings } from "../../i18n/config";
import { Modes, NoOfReportedOptions, YesNoOptions } from "../../utils/types";
import { getDateTimestampOfPreviousMonths } from "../../utils/utils";

export const UserTableHeaders = [
	{
		title: strings("UserSearchPage.user_id"),
		value: "user_id",
		isFixedColumn: true,
		isClickable: true,
		hideColumn: true,
		isDefault: true,
	},
	{
		title: strings("UserSearchPage.firstName"),
		value: "firstName",
		hideColumn: true,
		isDefault: true,
	},
	{
		title: strings("UserSearchPage.lastName"),
		value: "lastName",
		hideColumn: true,
		isDefault: true,
	},
	{
		title: strings("UserSearchPage.username"),
		value: "username",
		isDefault: true,
	},
	{
		title: strings("UserSearchPage.egousername"),
		value: "egousername",
		isDefault: true,
	},
	{
		title: strings("UserSearchPage.phone"),
		value: "phone",
		isDefault: true,
	},
	{
		title: strings("UserSearchPage.email_custom"),
		value: "email_custom",
		isDefault: true,
	},
	{
		title: strings("UserSearchPage.email_apple"),
		value: "email_apple",
	},
	{
		title: strings("UserSearchPage.email_twitter"),
		value: "email_twitter",
	},
	{
		title: strings("UserSearchPage.email_linkedin"),
		value: "email_linkedin",
	},
	{
		title: strings("UserSearchPage.email_facebook"),
		value: "email_facebook",
	},
	{
		title: strings("UserSearchPage.email_google"),
		value: "email_google",
	},
	{
		title: strings("UserSearchPage.isbot"),
		value: "isbot",
		isDefault: true,
	},
	{
		title: strings("UserSearchPage.e_nb_rep"),
		value: "e_nb_rep",
		showCustomColumnComponent: true,
	},
	{
		title: strings("UserSearchPage.e_reported_ts"),
		value: "e_reported_ts",
	},
	{
		title: strings("UserSearchPage.c_nb_rep"),
		value: "c_nb_rep",
		showCustomColumnComponent: true,
	},
	{
		title: strings("UserSearchPage.c_reported_ts"),
		value: "c_reported_ts",
	},
	{
		title: strings("UserSearchPage.eprof_id"),
		value: "eprof_id",
	},
	{
		title: strings("UserSearchPage.has_profile_pic"),
		value: "has_profile_pic",
	},
	{
		title: strings("UserSearchPage.ecoverPicRelUrl"),
		value: "ecoverPicRelUrl",
	},
	{
		title: strings("UserSearchPage.modif_egouname_ts"),
		value: "modif_egouname_ts",
	},
	{
		title: strings("UserSearchPage.emodif_ts"),
		value: "emodif_ts",
	},
	{
		title: strings("UserSearchPage.eprofilePicRelUrl"),
		value: "eprofilePicRelUrl",
	},
	{
		title: strings("UserSearchPage.modif_ts"),
		value: "modif_ts",
	},
	{
		title: strings("UserSearchPage.creat_ts"),
		value: "creat_ts",
	},
	{
		title: strings("UserSearchPage.status"),
		value: "status",
	},
	{
		title: strings("UserSearchPage.status_modif_ts"),
		value: "status_modif_ts",
	},
	{
		title: strings("UserSearchPage.hf_access_ts"),
		value: "hf_access_ts",
	},
	{
		title: strings("UserSearchPage.access_ts"),
		value: "access_ts",
		isDefault: true,
	},
	{
		title: strings("UserSearchPage.cprof_id"),
		value: "cprof_id",
	},
	{
		title: strings("UserSearchPage.ccoverPicRelUrl"),
		value: "ccoverPicRelUrl",
	},
	{
		title: strings("UserSearchPage.cmodif_ts"),
		value: "cmodif_ts",
	},
	{
		title: strings("UserSearchPage.cprofilePicRelUrl"),
		value: "cprofilePicRelUrl",
	},
	{
		title: strings("UserSearchPage.nb_of_uname_change"),
		value: "nb_of_uname_change",
	},
	{
		title: strings("UserSearchPage.uname_change_expiry_ts"),
		value: "uname_change_expiry_ts",
	},
	{
		title: strings("UserSearchPage.timezone"),
		value: "timezone",
	},
	{
		title: strings("UserSearchPage.loc"),
		value: "loc",
		helperText: strings("UserSearchPage.loc_helper_text"),
	},
	{
		title: strings("UserSearchPage.ps_e_consee"),
		value: "ps_e_consee",
		helperText: strings("UserSearchPage.ps_e_consee_helper_text"),
	},
	{
		title: strings("UserSearchPage.ps_e_folsee"),
		value: "ps_e_folsee",
	},
	{
		title: strings("UserSearchPage.ps_e_evtfolcom"),
		value: "ps_e_evtfolcom",
	},
	{
		title: strings("UserSearchPage.ps_e_evtfoluse"),
		value: "ps_e_evtfoluse",
	},
	{
		title: strings("UserSearchPage.ps_e_evtusecon"),
		value: "ps_e_evtusecon",
	},
	{
		title: strings("UserSearchPage.ps_e_conyou"),
		value: "ps_e_conyou",
	},
	{
		title: strings("UserSearchPage.ps_e_folyou"),
		value: "ps_e_folyou",
	},
	{
		title: strings("UserSearchPage.ps_e_evtjoicom"),
		value: "ps_e_evtjoicom",
	},
	{
		title: strings("UserSearchPage.ps_e_dirmsg"),
		value: "ps_e_dirmsg",
	},
	{
		title: strings("UserSearchPage.ps_e_provis"),
		value: "ps_e_provis",
	},
	{
		title: strings("UserSearchPage.ps_e_possee"),
		value: "ps_e_possee",
	},
	{
		title: strings("UserSearchPage.ps_e_compos"),
		value: "ps_e_compos",
	},
	{
		title: strings("UserSearchPage.ps_e_comsee"),
		value: "ps_e_comsee",
	},
	{
		title: strings("UserSearchPage.ps_c_evtjoicom"),
		value: "ps_c_evtjoicom",
		helperText: strings("UserSearchPage.ps_c_evtjoicom_helper_text"),
	},
	{
		title: strings("UserSearchPage.ps_c_evtfoluse"),
		value: "ps_c_evtfoluse",
		helperText: strings("UserSearchPage.ps_c_evtfoluse_helper_text"),
	},
	{
		title: strings("UserSearchPage.ps_c_evtfolcom"),
		value: "ps_c_evtfolcom",
	},
	{
		title: strings("UserSearchPage.ps_c_folsee"),
		value: "ps_c_folsee",
	},
	{
		title: strings("UserSearchPage.ps_c_consee"),
		value: "ps_c_consee",
	},
	{
		title: strings("UserSearchPage.ps_c_conyou"),
		value: "ps_c_conyou",
	},
	{
		title: strings("UserSearchPage.ps_c_folyou"),
		value: "ps_c_folyou",
	},
	{
		title: strings("UserSearchPage.ps_c_provis"),
		value: "ps_c_provis",
	},
	{
		title: strings("UserSearchPage.ps_c_evtusecon"),
		value: "ps_c_evtusecon",
	},
	{
		title: strings("UserSearchPage.ps_c_possee"),
		value: "ps_c_possee",
	},
	{
		title: strings("UserSearchPage.ps_c_dirmsg"),
		value: "ps_c_dirmsg",
	},
	{
		title: strings("UserSearchPage.ps_c_comsee"),
		value: "ps_c_comsee",
	},
	{
		title: strings("UserSearchPage.ps_c_compos"),
		value: "ps_c_compos",
	},
	{
		title: strings("UserSearchPage.is_apn_comnewjoireq"),
		value: "is_apn_comnewjoireq",
	},
	{
		title: strings("UserSearchPage.is_apn_compos"),
		value: "is_apn_compos",
	},
	{
		title: strings("UserSearchPage.is_apn_newconreq"),
		value: "is_apn_newconreq",
	},
	{
		title: strings("UserSearchPage.is_apn_men"),
		value: "is_apn_men",
	},
	{
		title: strings("UserSearchPage.is_apn_newfol"),
		value: "is_apn_newfol",
	},
	{
		title: strings("UserSearchPage.is_apn_comnewfolreq"),
		value: "is_apn_comnewfolreq",
	},
	{
		title: strings("UserSearchPage.is_apn_conapp"),
		value: "is_apn_conapp",
	},
	{
		title: strings("UserSearchPage.is_apn_menpos"),
		value: "is_apn_menpos",
	},
	{
		title: strings("UserSearchPage.is_apn_comnewpos"),
		value: "is_apn_comnewpos",
	},
	{
		title: strings("UserSearchPage.is_apn_comjoireqapp"),
		value: "is_apn_comjoireqapp",
	},
	{
		title: strings("UserSearchPage.is_apn_comrep"),
		value: "is_apn_comrep",
	},
	{
		title: strings("UserSearchPage.is_apn_engpts"),
		value: "is_apn_engpts",
	},
	{
		title: strings("UserSearchPage.is_apn_bdgerd"),
		value: "is_apn_bdgerd",
	},
	{
		title: strings("UserSearchPage.is_apn_newmsg"),
		value: "is_apn_newmsg",
	},
	{
		title: strings("UserSearchPage.is_apn_mencom"),
		value: "is_apn_mencom",
	},
	{
		title: strings("UserSearchPage.is_apn_comnewfol"),
		value: "is_apn_comnewfol",
	},
	{
		title: strings("UserSearchPage.nb_vi_total_rating_post_interval"),
		value: "nb_vi_total_rating_post_interval",
	},
	{
		title: strings("UserSearchPage.nb_vi_uniq_rating_post_interval"),
		value: "nb_vi_uniq_rating_post_interval",
	},
	// {
	// 	title: strings("UserSearchPage.q_rating_steps"),
	// 	value: "q_rating_steps",
	// },
	{
		title: strings("UserSearchPage.has_contact_perm"),
		value: "has_contact_perm",
	},
];

export const VirtualUsersTableHeaders = [
	{
		title: strings("UserSearchPage.user_id"),
		value: "user_id",
		isFixedColumn: true,
		isClickable: true,
		hideColumn: true,
	},
	{
		title: strings("UserSearchPage.username"),
		value: "username",
	},
	{
		title: strings("UserSearchPage.phone"),
		value: "phone",
	},
	{
		title: strings("UserSearchPage.creat_ts"),
		value: "creat_ts",
	},
];

export const SelectedReportedUserTableHeaders = [
	{
		title: strings("UserSearchPage.user_id"),
		value: "user_id",
		isFixedColumn: true,
		isClickable: true,
		hideColumn: true,
	},
	{
		title: strings("UserSearchPage.firstName"),
		value: "firstName",
		hideColumn: true,
	},
	{
		title: strings("UserSearchPage.lastName"),
		value: "lastName",
		hideColumn: true,
	},
	{
		title: strings("UserSearchPage.email_custom"),
		value: "email_custom",
	},
	{
		title: strings("UserSearchPage.phone"),
		value: "phone",
	},
	{
		title: strings("UserSearchPage.username"),
		value: "username",
	},
	{
		title: strings("UserSearchPage.egousername"),
		value: "egousername",
	},
	{
		title: strings("UserSearchPage.isbot"),
		value: "isbot",
	},
	{
		title: strings("UserSearchPage.e_nb_rep"),
		value: "e_nb_rep",
		showCustomColumnComponent: true,
	},
	{
		title: strings("UserSearchPage.c_nb_rep"),
		value: "c_nb_rep",
		showCustomColumnComponent: true,
	},
];

export const SearchColumns = [
	{
		title: strings("UserSearchPage.user_id"),
		value: "user_id",
	},
	{
		title: strings("UserSearchPage.firstName"),
		value: "lc_firstName",
	},
	{
		title: strings("UserSearchPage.lastName"),
		value: "lc_lastName",
	},
	{
		title: strings("UserSearchPage.email_custom"),
		value: "email_custom",
	},
	{
		title: strings("UserSearchPage.email_apple"),
		value: "email_apple",
	},
	{
		title: strings("UserSearchPage.email_twitter"),
		value: "email_twitter",
	},
	{
		title: strings("UserSearchPage.email_linkedin"),
		value: "email_linkedin",
	},
	{
		title: strings("UserSearchPage.email_facebook"),
		value: "email_facebook",
	},
	{
		title: strings("UserSearchPage.email_google"),
		value: "email_google",
	},
	{
		title: strings("UserSearchPage.phone"),
		value: "phone",
	},
	{
		title: strings("UserSearchPage.username"),
		value: "lc_username",
	},
	{
		title: strings("UserSearchPage.egousername"),
		value: "egousername",
	},
];

export const SingleUserFields = [
	{
		title: strings("UserSearchPage.user_id"),
		value: "user_id",
	},
	{
		title: strings("UserSearchPage.firstName"),
		value: "firstName",
	},
	{
		title: strings("UserSearchPage.lastName"),
		value: "lastName",
	},
	{
		title: strings("UserSearchPage.email"),
		value: "email_custom",
	},
	{
		title: strings("UserSearchPage.username"),
		value: "username",
	},
	{
		title: strings("UserSearchPage.egousername"),
		value: "egousername",
	},
	{
		title: strings("UserSearchPage.eprofilePicRelUrl"),
		value: "eprofilePicRelUrl",
	},
	{
		title: strings("UserSearchPage.ecoverPicRelUrl"),
		value: "ecoverPicRelUrl",
	},
	{
		title: strings("UserSearchPage.edescr"),
		value: "edescr",
	},
	{
		title: strings("UserSearchPage.cdescr"),
		value: "cdescr",
	},
	{
		title: strings("UserSearchPage.cprofilePicRelUrl"),
		value: "cprofilePicRelUrl",
	},
	{
		title: strings("UserSearchPage.ccoverPicRelUrl"),
		value: "ccoverPicRelUrl",
	},
	{
		title: strings("UserSearchPage.cprof_id"),
		value: "cprof_id",
	},
	{
		title: strings("UserSearchPage.eprof_id"),
		value: "eprof_id",
	},
	{
		title: strings("UserSearchPage.isbot"),
		value: "isbot",
	},
	{
		title: strings("UserSearchPage.e_nb_rep"),
		value: "e_nb_rep",
	},
	{
		title: strings("UserSearchPage.c_nb_rep"),
		value: "c_nb_rep",
	},
];

export const UserReportedOptions = [
	{
		title: strings("UserSearchPage.not_reported"),
		value: NoOfReportedOptions.ZERO,
		searchQueries: [
			{
				operator: "OR",
				propName: "e_nb_rep",
				propVal: null,
				condition: " IS NULL ",
			},
		],
	},
	{
		title: strings("UserSearchPage.one_to_ten"),
		value: NoOfReportedOptions.ONE_TO_TEN,
		searchQueries: [
			{
				operator: "OR",
				propName: "e_nb_rep",
				propVal: "2",
				condition: ">=",
			},
			{
				operator: "AND",
				propName: "e_nb_rep",
				propVal: "10",
				condition: "<=",
			},
		],
	},
	{
		title: strings("UserSearchPage.greater_than_ten"),
		value: NoOfReportedOptions.GREATER_THAN_TEN,
		searchQueries: [
			{
				operator: "OR",
				propName: "e_nb_rep",
				propVal: "10",
				condition: ">=",
			},
		],
	},
];

export const UserModesOptions = [
	{
		title: strings("UserSearchPage.mode_credo"),
		value: Modes.CREDO,
	},
	{
		title: strings("UserSearchPage.mode_ego"),
		value: Modes.EGO,
	},
];

export const TimestampsOptions = [
	{
		title: strings("NewUsersPage.24_hours"),
		value: "24",
	},
	{
		title: strings("NewUsersPage.48_hours"),
		value: "48",
	},
	{
		title: strings("NewUsersPage.72_hours"),
		value: "72",
	},
];

export const SuspendedUserTableHeaders = [
	{
		title: strings("UserSearchPage.user_id"),
		value: "user_id",
		isClickable: true,
		hideColumn: true,
	},
	{
		title: strings("SuspendedUsersScreen.firstname"),
		value: "suspended_user_fname",
	},
	{
		title: strings("SuspendedUsersScreen.lastname"),
		value: "suspended_user_lname",
	},
	{
		title: strings("SuspendedUsersScreen.username"),
		value: "suspended_user_uname",
	},
	{
		title: strings("SuspendedUsersScreen.suspended_reason"),
		value: "suspended_reason",
	},
	{
		title: strings("SuspendedUsersScreen.suspended_date"),
		value: "suspended_date",
	},
	{
		title: strings("SuspendedUsersScreen.time_left"),
		value: "time_left",
	},
];

export const UserCommunitiesTableHeaders = [
	{
		title: strings("UserSearchPage.stream_id"),
		value: "streamId",
		isClickable: true,
	},
	{
		title: strings("UserSearchPage.stream_name"),
		value: "name",
	},
	{
		title: strings("UserSearchPage.mode"),
		value: "mode",
	},
	{
		title: strings("UserSearchPage.is_joined"),
		value: "is_joined",
	},
	{
		title: strings("UserSearchPage.is_followed"),
		value: "is_followed",
	},
	{
		title: strings("UserSearchPage.role"),
		value: "role",
	},
];

export const UserCommRelnOptions = [
	{
		title: strings("UserSearchPage.is_followed"),
		value: "is_followed",
	},
	{
		title: strings("UserSearchPage.is_joined"),
		value: "is_joined",
	},
];

export const UserCommRoleOptions = [
	{
		title: strings("UserDetailsPage.stream_admin"),
		value: "STREAM_ADMIN",
	},
	{
		title: strings("UserDetailsPage.join_stream"),
		value: "JOIN_STREAM",
	},
	{
		title: strings("UserDetailsPage.stream_member"),
		value: "MEMBER",
	},
];

export const BotFilterList = [
	{
		title: strings("UserSearchPage.yes"),
		value: YesNoOptions.YES,
	},
	{
		title: strings("UserSearchPage.no"),
		value: YesNoOptions.NO,
	},
];

export const Timeframes = [
	{
		title: strings("UserSearchPage.one_month"),
		value: "1",
		searchQueries: [
			{
				operator: "OR",
				propName: "e_reported_ts",
				propVal: `${getDateTimestampOfPreviousMonths(1)}`,
				condition: ">=",
			},
		],
	},
	{
		title: strings("UserSearchPage.three_months"),
		value: "3",
		searchQueries: [
			{
				operator: "OR",
				propName: "e_reported_ts",
				propVal: `${getDateTimestampOfPreviousMonths(3)}`,
				condition: ">=",
			},
		],
	},
	{
		title: strings("UserSearchPage.six_months"),
		value: "6",
		searchQueries: [
			{
				operator: "OR",
				propName: "e_reported_ts",
				propVal: `${getDateTimestampOfPreviousMonths(6)}`,
				condition: ">=",
			},
		],
	},
	{
		title: strings("UserSearchPage.one_year"),
		value: "12",
		searchQueries: [
			{
				operator: "OR",
				propName: "e_reported_ts",
				propVal: `${getDateTimestampOfPreviousMonths(12)}`,
				condition: ">=",
			},
		],
	},
];

export const UserReportedTableHeaders = [
	{
		title: strings("UserSearchPage.user_id"),
		value: "reported_user_id",
		isClickable: true,
	},
	{
		title: strings("UserSearchPage.firstName"),
		value: "firstName",
	},
	{
		title: strings("UserSearchPage.lastName"),
		value: "lastName",
	},
	{
		title: strings("UserSearchPage.username"),
		value: "username",
	},
	{
		title: strings("UserReportedList.reported_mode"),
		value: "mode",
	},
	{
		title: strings("UserReportedList.reporting_mode"),
		value: "src_mode",
	},
	{
		title: strings("UserReportedList.reportReason"),
		value: "reportReason",
	},
	{
		title: strings("UserReportedList.reportMessage"),
		value: "reportMessage",
	},
	{
		title: strings("UserReportedList.reported_timestamp"),
		value: "reported_timestamp",
	},
];

export const NotRegisteredUserTableHeaders = [
	{
		title: strings("UserSearchPage.user_id"),
		value: "user_id",
		isFixedColumn: true,
		isClickable: true,
		hideColumn: true,
	},
	{
		title: strings("UserSearchPage.firstName"),
		value: "firstName",
		hideColumn: true,
	},
	{
		title: strings("UserSearchPage.lastName"),
		value: "lastName",
		hideColumn: true,
	},
	{
		title: strings("UserSearchPage.phone"),
		value: "phone",
	},
	{
		title: strings("UserSearchPage.username"),
		value: "username",
	},
	{
		title: strings("UserSearchPage.cprof_id"),
		value: "cprof_id",
	},
	{
		title: strings("UserSearchPage.eprof_id"),
		value: "eprof_id",
	},
	{
		title: strings("UserSearchPage.modif_ts"),
		value: "modif_ts",
	},
	{
		title: strings("UserSearchPage.creat_ts"),
		value: "creat_ts",
	},
];

export const NotRegisteredUserSearchColumns = [
	{
		title: strings("UserSearchPage.user_id"),
		value: "user_id",
	},
	{
		title: strings("UserSearchPage.phone"),
		value: "phone",
	},
	{
		title: strings("UserSearchPage.username"),
		value: "username",
	},
	{
		title: strings("UserSearchPage.cprof_id"),
		value: "cprof_id",
	},
	{
		title: strings("UserSearchPage.eprof_id"),
		value: "eprof_id",
	},
];

export const VirtualUserSearchColumns = [
	{
		title: strings("UserSearchPage.user_id"),
		value: "user_id",
	},
	{
		title: strings("UserSearchPage.phone"),
		value: "phone",
	},
];

export const UserActivitiesFields = [
	{
		value: "postUuid",
	},
	{
		value: "creat_ts",
	},
	{
		value: "postText",
	},
	{
		value: "user_id",
	},
	{
		value: "postType",
	},
	{
		value: "dest_firstName",
	},
	{
		value: "dest_lastName",
	},
	{
		value: "src_mode",
	},
	{
		value: "src_prof_id",
	},
	{
		value: "src_username",
	},
	{
		value: "src_firstName",
	},
	{
		value: "src_lastName",
	},
	{
		value: "src_profilePicRelUrl",
	},
	{
		value: "src_s_name",
	},
	{
		value: "src_streamId",
	},
	{
		value: "src_name",
	},
	{
		value: "evt_postUuid",
	},
	{
		value: "src_postText",
	},
	{
		value: "mode",
	},
	{
		value: "prof_id",
	},
	{
		value: "username",
	},
	{
		value: "firstName",
	},
	{
		value: "lastName",
	},
	{
		value: "name",
	},
	{
		value: "dest_profilePicRelUrl",
	},
	{
		value: "dest_mode",
	},
	{
		value: "dest_lastName",
	},
	{
		value: "dest_firstName",
	},
	{
		value: "dest_username",
	},
	{
		value: "dest_prof_id",
	},
	{
		value: "reqType",
	},
	{
		value: "src_postUuid",
	},
	{
		value: "s_name",
	},
	{
		value: "targetStreamId",
	},
	{
		value: "s_profilePicRelUrl",
	},
	{
		value: "entityId",
	},
	{
		value: "uxpcode",
	},
	{
		value: "rating",
	},
	{
		value: "emo_code",
	},
];
