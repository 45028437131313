import * as React from "react"
import {SVGAttributes} from "react";

const SvgComponent = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--gray-dark)" } = props;
	return (
		<svg width={17} height={21} viewBox="0 0 17 21" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g stroke={stroke} fill="none" fillRule="evenodd">
				<path d="M8.965 17.64c2.74 0 5.001-.271 6.605-.488.751-.1 1.15-.917.766-1.56-.833-1.396-1.933-3.868-1.954-7.226-.017-2.986-2.39-5.725-5.417-5.725-3.027 0-5.4 2.739-5.418 5.725-.02 3.358-1.121 5.83-1.954 7.225-.385.644.015 1.46.766 1.561 1.604.217 3.866.489 6.606.489" />
				<path d="M12.12 17.64c0 1.457-1.413 2.636-3.155 2.636-1.743 0-3.157-1.18-3.157-2.635M7.626 2.745c0-.724.599-1.31 1.338-1.31.74 0 1.34.586 1.34 1.31" />
			</g>
		</svg>
	)
}

export default SvgComponent
