import { isValidPhoneNumber } from "libphonenumber-js";
import { AppMode } from "@credo/utilities";
import getQueryFields from "../../utils/getQueryFields";
import {
	DropdownItem, NoOfReportedOptions, SearchBarElementInterface, TableHeader, YesNoOptions,
} from "../../utils/types";
import { PAGINATION_LIMIT } from "../../utils/constants";
import getSearchQuery from "../../utils/getSearchQuery";
import {
	Timeframes, UserActivitiesFields, UserModesOptions, UserReportedOptions,
} from "./constants";
import { REPORTED_STREAM_ID, REPORTED_USER_TYPE } from "../../routes/constants";
import { StreamTableHeaders } from "../communities/constants";
import { PostTableHeaders } from "../posts/constants";
import { getDateTimestampOfPreviousMonths } from "../../utils/utils";

export interface GetUsersVariables {
	searchBot: boolean;
	isBot: YesNoOptions;
	headers: TableHeader[];
	reportedOptions: DropdownItem[];
	timeframeFilter?: DropdownItem[];
	userMode: DropdownItem[]
	offset: number;
	searchData: SearchBarElementInterface[];
	userId?: string;
	countryFilter?: string;
}

export interface GetSingleUserDetailsVariable {
	userId: string;
	prof_id: string;
	mode: AppMode;
	fields: TableHeader[];
	offset: number;
}

const getReportedFilter = (reportedOptions: DropdownItem[], userMode: DropdownItem[]) => {
	let query: any = [];

	if (reportedOptions.length === UserReportedOptions.length && (userMode.length > 0 && userMode.length < UserModesOptions.length)) {
		return query;
	}

	if (userMode.length > 0 && userMode.length < UserModesOptions.length) {
		reportedOptions.forEach((option) => {
			switch (option.value) {
				case NoOfReportedOptions.ONE_TO_TEN:
					query = [
						...query,
						{
							AND: [
								{ [`${userMode[0].value}_nb_rep_gte`]: 1 },
								{ [`${userMode[0].value}_nb_rep_lte`]: 10 },
							],
						},
					];
					break;
				case NoOfReportedOptions.GREATER_THAN_TEN:
					query = [
						...query,
						{
							[`${userMode[0].value}_nb_rep_gte`]: 10,
						},
					];
					break;
				default: break;
			}
		});
		return query;
	}

	if (
		userMode.length === UserModesOptions.length
		&& reportedOptions.length < UserReportedOptions.length
		&& reportedOptions.length > 0
	) {
		reportedOptions.forEach((option) => {
			switch (option.value) {
				case NoOfReportedOptions.ONE_TO_TEN:
					query = [
						...query,
						{
							AND: [
								{ [`${userMode[0].value}_nb_rep_gte`]: 1 },
								{ [`${userMode[0].value}_nb_rep_lte`]: 10 },
							],
						},
						{
							AND: [
								{ [`${userMode[1].value}_nb_rep_gte`]: 1 },
								{ [`${userMode[1].value}_nb_rep_lte`]: 10 },
							],
						},
					];
					break;
				case NoOfReportedOptions.GREATER_THAN_TEN:
					query = [
						...query,
						{
							[`${userMode[0].value}_nb_rep_gte`]: 10,
						},
						{
							[`${userMode[1].value}_nb_rep_gte`]: 10,
						},
					];
					break;
				default: break;
			}
		});
		return query;
	}

	return query;
};

const getReportedTimeframeFilter = (timeframeFilter: DropdownItem[], userMode: DropdownItem[]) => {
	let query: any = [];

	if (timeframeFilter.length === Timeframes.length && (userMode.length > 0 && userMode.length < UserModesOptions.length)) {
		return query;
	}

	if (userMode.length > 0 && userMode.length < UserModesOptions.length) {
		const maxVal = Math.max(...timeframeFilter.map((item) => parseInt(item.value, 10)));
		query = [
			...query,
			{
				[`${userMode[0].value}_reported_ts_gte`]: getDateTimestampOfPreviousMonths(maxVal),
			},
		];
		return query;
	}

	if (
		userMode.length === UserModesOptions.length
		&& timeframeFilter.length < Timeframes.length
		&& timeframeFilter.length > 0
	) {
		const maxVal = Math.max(...timeframeFilter.map((item) => parseInt(item.value, 10)));
		query = [
			...query,
			{
				[`${userMode[0].value}_reported_ts_gte`]: getDateTimestampOfPreviousMonths(maxVal),
			},
			{
				[`${userMode[1].value}_reported_ts_gte`]: getDateTimestampOfPreviousMonths(maxVal),
			},
		];
		return query;
	}

	return query;
};

export const getUserQueries = (variables: GetUsersVariables) => {
	const {
		searchBot, isBot, headers, offset, searchData, reportedOptions, userMode, userId, countryFilter, timeframeFilter,
	} = variables;

	const searchDataWithoutPhoneInput = userId
		? searchData.filter((element) => !isValidPhoneNumber(element.selectedSearchValue)) : searchData;

	const reportedFilter = getReportedFilter(reportedOptions, userMode);

	const timeframeFilterQuery = timeframeFilter ? getReportedTimeframeFilter(timeframeFilter, userMode) : "";

	const {
		isQueryPresent,
		query: filterQuery,
	} = getSearchQuery(searchDataWithoutPhoneInput, reportedFilter.length > 0 ? reportedFilter : null);

	const filters = filterQuery !== "[]" && filterQuery !== "" ? [filterQuery.replace(/"([^(")"]+)":/g, "$1:")] : [];

	filters.push("{ OR:[{ status: null }, { status: \"\" }] }"); // applied filter for virtual users

	if (countryFilter) {
		filters.push(countryFilter);
	}

	if (timeframeFilterQuery && timeframeFilterQuery?.length > 0) {
		const timeframeQuery = JSON.stringify(timeframeFilterQuery).replace(/"([^(")"]+)":/g, "$1:");
		filters.push(`{ OR: ${timeframeQuery} }`);
	}

	return `query {
			user(
				first: ${PAGINATION_LIMIT},
				offset: ${offset}
				${searchBot ? `, isbot: "${isBot}"` : ""}
				${userId && !isQueryPresent ? `, user_id: "${userId}"` : ""}
				${filters.length > 0 ? `, filter: { AND: [ ${filters} ]} ` : ", "}
        orderBy:[creat_ts_desc]
			) {
				${getQueryFields(headers)},
			}
		}`
		.replaceAll("\t", "")
		.replaceAll("\n", "");
};

export const getVirtualUserQueries = (variables: any) => {
	const {
		headers, offset, searchData, userId,
	} = variables;

	const searchDataWithoutPhoneInput = userId
		? searchData.filter((element: any) => !isValidPhoneNumber(element.selectedSearchValue)) : searchData;

	const {
		isQueryPresent,
		query: filterQuery,
	} = getSearchQuery(searchDataWithoutPhoneInput, []);

	const filters = filterQuery !== "[]" && filterQuery !== "" ? [filterQuery.replace(/"([^(")"]+)":/g, "$1:")] : [];

	return `query {
			user(
				first: ${PAGINATION_LIMIT},
				offset: ${offset},
        status: "vu"
				${userId && !isQueryPresent ? `, user_id: "${userId}"` : ""}
				${filters.length > 0 ? `, filter: { AND: [ ${filters} ]}, ` : ", "}
        orderBy:[creat_ts_desc]
			) {
				${getQueryFields(headers)},
			}
		}`
		.replaceAll("\t", "")
		.replaceAll("\n", "");
};

const getTimestamp = (timestamp: any) => {
	if (timestamp && timestamp.length > 0) {
		const lastTs = parseInt(timestamp[0].value, 10);
		const ts = (Math.round(getDateTimestampOfPreviousMonths(0) / 1000) - (lastTs * 3600)) * 1000;
		return ts;
	}
	return null;
};

export const getNewUsersQueries = (variables: any) => {
	const {
		headers, offset, timestamp,
	} = variables;

	const filters = [];

	filters.push("{ OR:[{ status: null }, { status: \"\" }] }"); // applied filter for virtual users
	const ts = getTimestamp(timestamp);
	if (ts) {
		filters.push(`{ OR:[{ creat_ts_gte: ${ts} }, { status_modif_ts_gte: ${ts} }] }`);
	}

	return `query {
			user(
				first: ${PAGINATION_LIMIT},
				offset: ${offset},
				isbot: "n"
				${filters.length > 0 ? `, filter: { AND: [ ${filters} ]}, ` : ", "}
        orderBy:[creat_ts_desc]
			) {
				${getQueryFields(headers)},
			}
		}`
		.replaceAll("\t", "")
		.replaceAll("\n", "");
};

export const getSingleUserDetails = (variables: GetSingleUserDetailsVariable) => {
	const {
		userId,
		prof_id,
		mode,
		fields,
		offset,
	} = variables;

	let searchByIdQuery = "";
	if (userId) {
		searchByIdQuery = `user_id: "${userId}",`;
	} else if (mode === AppMode.CREDO) {
		searchByIdQuery = `cprof_id: "${prof_id}",`;
	} else {
		searchByIdQuery = `eprof_id: "${prof_id}",`;
	}

	return `query {
			user(
				${searchByIdQuery}
			) {
				${getQueryFields(fields)},
				userStreams(
					categ: "activity",
				){
					streamId,
					name,
					posts(
						orderBy:[creat_ts_desc],
						first: ${PAGINATION_LIMIT},
				  	offset: ${offset},
					){
						${getQueryFields(UserActivitiesFields)},
						pCommentUuid,
						commentUuid,
					}
				},
				xpTag{
					code,
				},
				exp_stream{
					exp_post{
						postUuid,
						exp_position{
							posTitle,
							posRole,
							startDate,
							endDate,
							locDescr,
							locCode,
							posDescr,
							isCrtPos,
							isPosValidated,
						},
						company{
							code,
							name
						}
						industry{
							code,
							name
						}
					}
				},
				qual_stream{
					qual_post{
						postUuid,
						exp_degree{
							posTitle,
							posRole,
							startDate,
							endDate,
							locDescr,
							locCode,
							degreeDescr,
							degreeName,
							isCrtDeg,
							isDegreeValidated,
						},
						school{
							code,
							name
						}
						schoolType{
							code,
							name
						}
					}
				},
				followed_stream_relType{
					mode,
					steamId,
					name,
				}
				joined_stream_relType{
					mode,
					steamId,
					name,
					relType
				}
			}
		}`
		.replaceAll("\t", "")
		.replaceAll("\n", "");
};

export interface GetReportedUsersVariables {
	prof_id_array: Array<string>;
}

export const getReportedUsersData = (variables: GetReportedUsersVariables) => {
	const {
		prof_id_array,
	} = variables;

	const profIds = prof_id_array.filter((e) => e && e !== "");

	if (profIds && profIds.length > 0) {
		return `query {
			stream(
				first: 1,
				streamId: "${REPORTED_STREAM_ID}",
			) {
				${getQueryFields(StreamTableHeaders)},
				posts(
					postType: "${REPORTED_USER_TYPE}",
					filter: { 
						src_prof_id_in: ${JSON.stringify(profIds)}
					}
				)	{
					${getQueryFields(PostTableHeaders)},
					src_prof_id,
					reportReason,
					reported_user_id,
					reportMessage,
				}
			}
		}`
			.replaceAll("\t", "")
			.replaceAll("\n", "");
	}
	return "";
};
