import React from "react";
import ReactDOM from "react-dom/client";
import "@credo/ui-components/dist/index.css";
import "./index.css";
// import "./assets/styles/styles.css";
import { CookiesProvider } from "react-cookie";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement,
);
// TODO: Tailwind transitions do not work in react strict mode
root.render(
	<CookiesProvider>
		<App />
	</CookiesProvider>
	,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
