import React, { SVGAttributes } from "react";

const Home = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { stroke = "var(--gray-dark)", fill } = props;
	return (
		<svg width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
			<g id="Web-Version" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Web-Version---Home-Ego" transform="translate(-30.000000, -186.000000)" stroke={stroke} fill={fill}>
					<g id="pop-up-copy-3" transform="translate(30.000000, 171.000000)">
						<g id="menu_home" transform="translate(0.000000, 15.000000)">
							<path d="M10.4996,0.5 C10.0806,0.5 9.6616,0.659 9.3426,0.978 L0.9776,9.349 C0.3406,9.988 0.3406,11.027 0.9776,11.666 C1.5946,12.283 2.6756,12.283 3.2936,11.666 L11.6576,3.295 C12.2956,2.657 12.2956,1.617 11.6576,0.978 C11.3386,0.659 10.9196,0.5 10.4996,0.5 Z" id="Stroke-1"/>
							<path d="M18.8634,8.871 C18.4444,8.871 18.0254,9.03 17.7064,9.349 L9.3414,17.72 C8.7044,18.359 8.7044,19.398 9.3414,20.037 C9.9584,20.654 11.0394,20.654 11.6574,20.037 L20.0214,11.666 C20.6594,11.028 20.6594,9.988 20.0214,9.349 C19.7024,9.03 19.2834,8.871 18.8634,8.871 Z" id="Stroke-3"/>
							<path d="M14.6815,4.6855 C14.2625,4.6855 13.8435,4.8445 13.5245,5.1635 L5.1595,13.5345 C4.5225,14.1735 4.5225,15.2125 5.1595,15.8515 C5.7765,16.4685 6.8575,16.4685 7.4755,15.8515 L15.8395,7.4805 C16.4775,6.8425 16.4775,5.8025 15.8395,5.1635 C15.5205,4.8445 15.1015,4.6855 14.6815,4.6855 Z" id="Stroke-5"/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}

export default Home;
