import React, { SVGAttributes } from "react";

const checkBoxEmpty = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--gray-dark)" } = props;
	return (
		<svg
			width="14px"
			height="14px"
			viewBox="0 0 14 14"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<g id="Web-Version" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g
					id="Web-Version-Wide---Referral-Code-Sign-up-Email-not-yet-registered"
					transform="translate(-627.000000, -622.000000)"
					stroke={stroke}
				>
					<g id="Group-6" transform="translate(470.000000, 235.000000)">
						<g id="checkBoxEmpty" transform="translate(157.000000, 387.000000)">
							<rect id="Rectangle" x="0.5" y="0.5" width="13" height="13" />
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default checkBoxEmpty;
