import * as React from "react"
import {SVGAttributes} from "react";

const SvgComponent = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { stroke = "var(--gray-dark)" } = props;
	return (
		<svg width={22} height={22} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g stroke={stroke} fill="none" fillRule="evenodd">
				<path d="M21.306 15.588a5.215 5.215 0 1 1-10.43-.001 5.215 5.215 0 0 1 10.43 0Z" />
				<path d="m10.876 15.588-5.85 3.903 1.907-6.77-5.521-4.355 7.026-.28 2.438-6.597 2.437 6.597 7.028.28-2.807 2.214M12.414 11.91l7.354 7.353" />
			</g>
		</svg>
	)
}

export default SvgComponent
