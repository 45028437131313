import * as React from "react";
import {
	CredoTag, CredoTagDataConsumer, CredoTagDataProvider, CredoTagScoreData,
} from "./credo-tag";
import {
	DEFAULT_TAG_MESSAGES, Logger, LogLevel, ScalerSize,
} from "../common";

export function createDataProvider(delay: number = 10000, overrides: {} = {}): CredoTagDataProvider {
	const subMap = new Map<string, Array<CredoTagDataConsumer>>();

	const get = async (_id: string): Promise<CredoTagScoreData> => {
		const data = await (new Promise<CredoTagScoreData>((resolve, _reject) => {
			setTimeout(() => {
				const res: CredoTagScoreData = {
					engagement: Math.random() * 1,
					hasCampaign: Math.random() > 0.5,
					objScore: Math.random() * 1,
					ownInterest: Math.random() > 0.5,
					ownRating: null,
					relevance: Math.random() * 1,
					subjScore: Math.random() * 1,
					subjectivity: Math.random() * 1,

					...overrides,
				};
				// Logger.log("Returning res:", res);

				resolve(res);
			}, delay);
		}));

		// Logger.log("Got data:", data);

		return data;
	};

	const publish = () => {
		subMap.forEach((subs, id) => {
			Logger.debug("Getting data for id", id);
			const data = get(id).then((data) => {
				Logger.debug("Updating subs", id, subs);
				subs.map((sub) => sub(data));
			});
			Logger.debug(data);
		});
	};

	const subscribe = (id: string, onUpdate: CredoTagDataConsumer): void => {
		const subs: Array<CredoTagDataConsumer> = subMap.get(id) ?? [];
		if (subs.find((value) => value === onUpdate)) {
			Logger.debug("already have this sub", id, onUpdate, subs);
		} else {
			subs.push(onUpdate);
			subMap.set(id, subs);
		}
	};

	const unsubscribe = (id: string, onUpdate: CredoTagDataConsumer): void => {
		if (subMap.has(id)) {
			let subs: Array<CredoTagDataConsumer> = subMap.get(id) ?? [];
			subs = subs.filter((value) => value !== onUpdate);

			if (subs.length === 0) {
				subMap.delete(id);
				Logger.debug("removed all subs", id, onUpdate, subs);
			} else {
				subMap.set(id, subs);
				Logger.debug("removed sub", id, onUpdate, subs);
			}
		}
	};

	// setTimeout(publish, delay);
	publish();

	return {
		get,
		subscribe,
		unsubscribe,
	};
}

const dummyRegularUpdatingProvier = createDataProvider(3000);

/**
 * Default size indicator with some random values
 */
export const DefaultSizeCredoTag = () => {
	Logger.level = LogLevel.DEBUG;

	const [rating, setRating] = React.useState<number | null>(null);
	const onRate = (_id: string, rating: number | null) => {
		setRating(rating);
	};
	return (
		<div style={{ width: "200px", height: "200px" }}>
			<div>{rating ? `Own rating:${rating}` : "Click on the tag to set rating"}</div>
			<CredoTag
				size={ScalerSize.S}
				id="123"
				tag="credo"
				onRate={onRate}
				dataProvider={dummyRegularUpdatingProvier}
				isUserOwnPost={false}
				messages={DEFAULT_TAG_MESSAGES}
				isCredoMode
			/>
		</div>
	);
};
/**
 * Default size indicator with some random values
 */
export const SmallCredoTagWithCampaign = () => {
	Logger.level = LogLevel.DEBUG;

	const [rating, setRating] = React.useState<number | null>(null);

	const onRate = (_id: string, rating: number | null) => {
		setRating(rating);
	};
	const campaignIcon = () => (
		<img
			height={18}
			width={18}
			src="https://www.pngfind.com/pngs/m/220-2207946_png-file-svg-white-emoji-icon-png-transparent.png"
			alt="campaignIcon"
		/>
	);

	const dataProvider = createDataProvider(1000, {
		hasCampaign: true,
		campaignIcon,
	});

	return (
		<div style={{ width: "200px", height: "200px" }}>
			<div>{rating ? `Own rating:${rating}` : "Click on the tag to set rating"}</div>
			<CredoTag
				id="123"
				tag="photography"
				onRate={onRate}
				size={ScalerSize.S}
				dataProvider={dataProvider}
				isUserOwnPost={false}
				messages={DEFAULT_TAG_MESSAGES}
				isCredoMode
			/>
		</div>
	);
};
