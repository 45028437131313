import React, { useMemo } from "react";
import {
	AppMode, BoostBoardItem, cu, UserProfile,
} from "@credo/utilities";
import { useAtomValue } from "jotai";
import { isCredoModeAtom } from "@credo/store";
import { PostHeader } from "../post-header/PostHeader";
import {
	CredoTagSource, MoreMenuInterface, PostHeaderScreenType, PostMsgs,
} from "../types";
import { PostText } from "../post-text/PostText";
import PostShared from "../post-shared/PostShared";
import PostImages from "../post-image/PostImages";
import PostVideo from "../post-video/PostVideo";
import PostLinkPreview from "../post-link-preview/PostLinkPreview";
import { PostBar } from "../post-bar/PostBar";
import { PostTags } from "../PostTags";
import { classNames, ScalerSize } from "../../common";
import { TagMessages } from "../../credo-tag";
import { BoostTrayProps } from "../../boost";

interface PostCompactViewProps {
	item: BoostBoardItem;
	onCommentIconClick: () => void;
	// TODO: Change this to actual type
	onEmojiClick: (data: any) => void;
	onSharePostIconClick: () => void;
	isUserOwnPost: boolean;
	onModeChange(mode: boolean): void;
	userProfile: UserProfile | null;
	onTagRate: (data: {
		tag: string,
		rating: number,
		tagSource: CredoTagSource,
	}) => void;
	postMsgs: PostMsgs;
	navigateToUserOrStream: (data: {
		type: PostHeaderScreenType,
		mode: AppMode,
		profId: string,
		userName: string,
		egousername: string,
		meta: {
			title: string,
		},
	}) => void;
	moreMenuList: MoreMenuInterface[];
	showLoginModal: (show: boolean) => void;
	navigateToDetailsScreen: (isShared: boolean) => void;
	hasComments?: boolean;
	showHandIcon?: boolean;
	onClickEmojiTooltip?: () => void;
	onClickMoreMenu?: () => void;
	commentInputVisible?: boolean;
	onPressCredoGraphText: () => void;
	onPressTagInfoBubble: () => void;
	messages: {
		postTagMessages: TagMessages;
		postHeaderTagMessages: TagMessages;
	}
	boostTrayProps: BoostTrayProps;
	// eslint-disable-next-line react/no-unused-prop-types
	wrapperClassName?: string;
}

/**
 * TODO:
 *
 * 1. Video remaining for tablet as well
 * 2. Mobile remaining
 * */

/**
 * if the viewport has width less than 540 the font size should change
 * */

// eslint-disable-next-line import/prefer-default-export
export const PostCompactView: React.FC<PostCompactViewProps> = (props: PostCompactViewProps) => {
	const {
		item,
		onCommentIconClick,
		onEmojiClick,
		onSharePostIconClick,
		isUserOwnPost,
		onModeChange,
		userProfile,
		onTagRate,
		postMsgs,
		navigateToUserOrStream,
		moreMenuList,
		showLoginModal,
		navigateToDetailsScreen,
		hasComments = false,
		showHandIcon = true,
		onClickEmojiTooltip,
		onClickMoreMenu,
		commentInputVisible,
		onPressCredoGraphText,
		onPressTagInfoBubble,
		messages,
		boostTrayProps,
		wrapperClassName,
	} = props;

	const {
		imageErrorMsg,
		contentSensitiveMsg,
		switchToCredoModeToRate,
		cannotRateOwnPostMsg,
		sharedPostDeleted,
	} = postMsgs;

	const isCredoMode = useAtomValue(isCredoModeAtom);

	const bucketRelativePaths = useMemo(() => ([
		item["p.img_bucketRelativeRefPath0"],
		item["p.img_bucketRelativeRefPath1"],
		item["p.img_bucketRelativeRefPath2"],
		item["p.img_bucketRelativeRefPath3"],
	]), [item]);

	const onAuthorPostTagRate = (tag: string, rating: number) => {
		if (onTagRate) {
			onTagRate({
				tag,
				rating,
				tagSource: CredoTagSource.AUTHOR_POST,
			});
		}
	};

	const onPostTagRate = (tag: string, rating: number) => {
		if (onTagRate) {
			onTagRate({
				tag,
				rating,
				tagSource: CredoTagSource.POST,
			});
		}
	};

	const onLinkShareIconPress = () => { };

	const renderPostMedia = () => {
		if (cu.isYes(item["p.is_shared"])) {
			return (
				<PostShared
					postData={item}
					isContentRestricted={item["p.nb_rep"] ? item["p.nb_rep"] > 0 : false}
					onLinkShareIconPress={onLinkShareIconPress}
					navigateToDetailsScreen={navigateToDetailsScreen}
					contentSensitiveMsg={contentSensitiveMsg}
					imageErrorMsg={imageErrorMsg}
					sharedPostDeleted={sharedPostDeleted}
					navigateToUserOrStream={navigateToUserOrStream}
					showHandIcon={showHandIcon}
					isCompactView
				/>
			);
		} else if (
			bucketRelativePaths.some((single) => single)
			|| cu.isSet(item["p.video_bucketRelativeRefPath"])
			|| (cu.isSet(item["p.mediaObj_Link_Url"]?.trim()) && !cu.isSet(bucketRelativePaths[0]))
		) {
			return (
				<div className="w-full">
					{bucketRelativePaths.some((single) => single) && (
						<PostImages
							postData={item}
							sharedPost={false}
							isContentRestricted={item["p.nb_rep"] ? item["p.nb_rep"] > 0 : false}
							contentSensitiveMsg={contentSensitiveMsg}
							imageErrorMsg={imageErrorMsg}
							isCompactView
						/>
					)}
					{cu.isSet(item["p.video_bucketRelativeRefPath"]) && (
						<PostVideo
							postData={item}
							isContentRestricted={item["p.nb_rep"] ? item["p.nb_rep"] > 0 : false}
							sharedPost={false}
							contentSensitiveMsg={contentSensitiveMsg}
							imageErrorMsg={imageErrorMsg}
							isCompactView
						/>
					)}
					{cu.isSet(item["p.mediaObj_Link_Url"]?.trim()) && !cu.isSet(bucketRelativePaths[0]) && (
						<PostLinkPreview
							linkData={item}
							isContentRestricted={item["p.nb_rep"] ? item["p.nb_rep"] > 0 : false}
							sharedPost={false}
							onLinkShareIconPress={onLinkShareIconPress}
							contentSensitiveMsg={contentSensitiveMsg}
							imageErrorMsg={imageErrorMsg}
							isCompactView
						/>
					)}
				</div>
			);
		} else {
			return null;
		}
	};

	const renderLeftSection = () => (
		<div className="flex flex-col">
			<PostHeader
				item={item}
				navigateToDetailsScreen={navigateToDetailsScreen}
				navigateToUserOrStream={navigateToUserOrStream}
				showHandIcon={showHandIcon}
				postTagProps={{
					tags: item.authorTags,
					onRate: onAuthorPostTagRate,
					isUserOwnPost,
					isCredoMode,
					onModeChange,
					userProfile,
					switchToCredoModeToRate,
					cannotRateOwnPostMsg,
					showLoginModal,
					onPressCredoGraphText,
					onPressTagInfoBubble,
					credoTagId: "author-modal/credo-tag",
					messages: messages.postHeaderTagMessages,
				}}
				isCompactView
			/>
			<PostText
				item={item}
				navigateToDetails={() => navigateToDetailsScreen(false)}
				navigateToMentionedUser={navigateToUserOrStream}
				isCompactView
			/>
		</div>
	);

	if (!renderPostMedia()) {
		return (
			<div
				className="flex flex-col w-full justify-between pr-6 mobile-compact:pr-0"
				data-testid={`post-compact-view-${item["p.postUuid"]}`}
			>
				{renderLeftSection()}
				<div className="flex flex-row w-full">
					<div className="flex flex-col w-3/5 mobile-compact:w-full">
						<PostTags
							credoTagId="post/credo_tag"
							tags={item.sortedPostTags}
							onRate={onPostTagRate}
							isUserOwnPost={isUserOwnPost}
							isCredoMode={isCredoMode}
							onModeChange={onModeChange}
							userProfile={userProfile}
							switchToCredoModeToRate={switchToCredoModeToRate}
							cannotRateOwnPostMsg={cannotRateOwnPostMsg}
							showLoginModal={showLoginModal}
							onPressCredoGraphText={onPressCredoGraphText}
							onPressTagInfoBubble={onPressTagInfoBubble}
							tagSize={ScalerSize.S}
							isCompactView
							messages={messages.postTagMessages}
							boostTrayProps={boostTrayProps}
						/>
					</div>
				</div>
				<div className="px-4">
					<PostBar
						onClickMoreMenu={onClickMoreMenu}
						onClickEmojiTooltip={onClickEmojiTooltip}
						postData={item}
						hasComments={hasComments}
						onCommentIconClick={onCommentIconClick}
						onEmojiClick={onEmojiClick}
						onSharePostIconClick={onSharePostIconClick}
						moreMenuList={moreMenuList}
						isCredoMode={isCredoMode}
						showLoginModal={showLoginModal}
						commentInputVisible={commentInputVisible}
						isCompactView
					/>
				</div>
			</div>
		);
	}

	return (
		<div
			className={classNames(
				"pr-6 mobile-compact:pr-0 w-full",
				wrapperClassName,
			)}
		>
			<div
				className="flex flex-row w-full"
				data-testid={`post-compact-view-${item["p.postUuid"]}`}
			>
				<div className="flex flex-col w-3/5">
					{renderLeftSection()}
					<PostTags
						credoTagId="post/credo_tag"
						tags={item.sortedPostTags}
						onRate={onPostTagRate}
						isUserOwnPost={isUserOwnPost}
						isCredoMode={isCredoMode}
						onModeChange={onModeChange}
						userProfile={userProfile}
						switchToCredoModeToRate={switchToCredoModeToRate}
						cannotRateOwnPostMsg={cannotRateOwnPostMsg}
						showLoginModal={showLoginModal}
						onPressCredoGraphText={onPressCredoGraphText}
						onPressTagInfoBubble={onPressTagInfoBubble}
						tagSize={ScalerSize.S}
						messages={messages.postTagMessages}
						boostTrayProps={boostTrayProps}
						isCompactView
					/>
				</div>
				<div
					className={classNames(
						"flex flex-col w-2/5 mobile-compact:min-w-[202px] ",
						"pr-2 mobile-compact:pr-4",
						cu.isSet(item["p.mediaObj_Link_ImgUrl"]) ? "" : "justify-end",
						cu.isSet(item["p.img_bucketRelativeRefPath0"]) ? "justify-between" : "",
					)}
				>
					{renderPostMedia()}
				</div>
			</div>
			<div className="pt-2 px-4">
				<PostBar
					onClickMoreMenu={onClickMoreMenu}
					onClickEmojiTooltip={onClickEmojiTooltip}
					postData={item}
					hasComments={hasComments}
					onCommentIconClick={onCommentIconClick}
					onEmojiClick={onEmojiClick}
					onSharePostIconClick={onSharePostIconClick}
					moreMenuList={moreMenuList}
					isCredoMode={isCredoMode}
					showLoginModal={showLoginModal}
					commentInputVisible={commentInputVisible}
					isCompactView
				/>
			</div>
		</div>
	);
};

PostCompactView.defaultProps = {
	hasComments: false,
	showHandIcon: false,
	onClickEmojiTooltip: () => { },
	onClickMoreMenu: () => { },
	commentInputVisible: false,
};
