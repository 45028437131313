import React, { SVGAttributes } from "react";

const EgoAvatarPlaceholder = (props: SVGAttributes<HTMLOrSVGElement>) => {
	return (
		<svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
			<g id="Home-/-Feed---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-icons" transform="translate(-113.000000, -36.000000)">
					<g id="ego_avatar" transform="translate(113.000000, 36.000000)">
						<path d="M39.9996098,20.0002854 C39.9996098,31.0462854 31.0458336,40.0002854 20.0001097,40.0002854 C8.95438589,40.0002854 -0.000390243902,31.0462854 -0.000390243902,20.0002854 C-0.000390243902,8.95428537 8.95438589,0.000285365854 20.0001097,0.000285365854 C31.0458336,0.000285365854 39.9996098,8.95428537 39.9996098,20.0002854" id="Fill-1" fill="#C0C9D5"></path>
						<path d="M19.998985,18 C22.7557856,18 24.998985,15.7568006 24.998985,13 C24.998985,10.2431994 22.7557856,8 19.998985,8 C17.2431994,8 15,10.2431994 15,13 C15,15.7568006 17.2431994,18 19.998985,18 Z" id="Stroke-1" stroke="#FFFFFF"></path>
						<path d="M28,28.403929 C25.944113,30.6258832 23.1197495,32 20.0014179,32 C16.8811957,32 14.055887,30.6248492 12,28.4018611 C12.3714775,23.1359642 14.4538312,21.0049974 15.6599516,20.1974841 L15.954865,20 L16.2526142,20.2202309 L19.9995274,23.4099604 L23.7464406,20.2202309 L24.0441898,20 L24.3391032,20.1974841 C25.5461688,21.0049974 27.6285225,23.139066 28,28.403929 Z" id="Stroke-3" stroke="#FFFFFF"></path>
						<circle id="Oval" cx="20" cy="20" r="20"></circle>
					</g>
				</g>
			</g>
		</svg>
	);
}

export default EgoAvatarPlaceholder;
