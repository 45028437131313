import {cu, dbg} from "./cu";

let msgIndex = 0;

export class LogMgr {
  static loggedData = [{ key: msgIndex + 1, msg: "sample text log" }];

  /**
   * Logs the argument.
   *
   * @params {any} obj - Data which needs to be logged.
   * */
  static log = (obj: any) => {
    if (msgIndex > 1000) {
      LogMgr.loggedData = [];
      msgIndex = 0;
    }
    const newObj = {
      ...obj,
      key: msgIndex + 1,
    };
    LogMgr.loggedData.unshift(newObj);
  };

  /**
   * Displays the arguments it in formatted console's log
   *
   * @params {any} argument - Arguments passed which needs to be displayed in the log.
   * @return {boolean} Always true
   * */
  static mydbg(...argument: any[]) {
    const callerThis = argument["0"];
    const args = Object.values(argument);
    let where: string;
    where = callerThis && typeof callerThis === "string" ? callerThis : "none";
    if (!where) {
      where = callerThis?.constructor?.name ? callerThis.constructor.name : "none1";
    }
    const callerName = LogMgr.mydbg.name ? LogMgr.mydbg.name : "none1";
    const txt = `DBG: ${where}:${callerName}:`;
    // eslint-disable-next-line no-console
    console.log(txt, ...args);
    LogMgr.log({ msg: `${txt} ${args.join(" ")}` });
    return true;
  }

  /**
   * Displays the arguments in formatted console's log in a formatted error manner
   *
   * @params {any} argument - Arguments passed which needs to be displayed in the log.
   * */
  static myerr(...paramArgs: any) {
    let callerThis = paramArgs['0'];
    delete paramArgs['0'];
    let args = Object.values(paramArgs);
    let where = callerThis.constructor ? callerThis.constructor?.name ? callerThis.constructor?.name : 'none1' : 'none2';
    let txt = `ERR: ${where}`;
    if(dbg) LogMgr.mydbg(this, txt, ...args);
    LogMgr.log({msg:txt + ' ' + args.join(' ')});
  }

  /**
   * Displays the arguments in formatted console's log in a formatted Warning manner
   *
   * @params {any} argument - Arguments passed which needs to be displayed in the log as a warning.
   * */
  static mywarn(...paramArgs: any){
    let callerThis = paramArgs['0'];
    delete paramArgs['0'];
    let args = Object.values(paramArgs);
    let where = callerThis.constructor ? callerThis.constructor.name ? callerThis.constructor.name : 'none1' : 'none2';
    let callerName = LogMgr.mywarn.caller ? LogMgr.mywarn.caller.name ? LogMgr.mywarn.caller.name : 'none1' : 'none2'
    if(dbg) this.mydbg(this,`WARN: ${where}:${callerName}:`, ...args);
  }

  /**
   * Log exception in the console.
   *
   * @params {any} argument - Arguments passed which needs to be displayed in the log.
   * */
  static printException(callerThis: any, txt: string, exc: any) {
    let where = callerThis?.constructor ? callerThis?.constructor?.name ? callerThis?.constructor?.name : 'none1' : 'none2';
    let callerName = LogMgr.printException?.caller ? (LogMgr.printException?.caller?.name ? LogMgr.printException?.caller?.name : 'none1') : 'none2'
    let errTxt = `EXCEPTION: ${where}:${callerName}:`;
    let jsonExc = cu.jsonify(exc)
    if(dbg) LogMgr.mydbg(this, errTxt, txt, " : ", jsonExc, '; msg=', exc.message, '; stack=', exc.stack?exc.stack.substring(0,500):'');
    LogMgr.log({msg:`${errTxt} ${txt} ${jsonExc} ${exc.message}`});
  }

  /**
   * Gets Logged data.
   *
   * @returns {any} Logged data
   * */
  static getData = () => LogMgr.loggedData;

  /**
   * Jsonify the argument and returns the stringify text.
   *
   * @param {any} exc The error text which needs to jsonify.
   * @return {string} The passed json text and msg and stack.
   * */
  static getErrTxt = (exc: any) => {
    return cu.jsonify(exc) + '; msg=' + exc.message + '; stack=' + exc.stack?exc.stack.substring(0,500):'';
  }
}
