import React, { SVGAttributes } from "react";

const Top = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { stroke = "var(--gray-dark)", fill } = props;
	return (
		<svg
			width="20px"
			height="20px"
			viewBox="0 0 20 20"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<title>Top</title>
			<defs>
				<circle id="path-top-1" cx="6" cy="6" r="4.8" />
				<linearGradient x1="8.35442826e-13%" y1="50%" x2="50%" y2="50%" id="linearGradient-top-3" gradientUnits="userSpaceOnUse">
					<stop stopColor="#00FFA5" offset="0%" />
					<stop stopColor="#00FF9E" stopOpacity="0" offset="100%" />
				</linearGradient>
				<linearGradient x1="8.35442826e-13%" y1="50%" x2="50%" y2="50%" id="linearGradient-top-4" gradientUnits="userSpaceOnUse">
					<stop stopColor="#00FFA5" offset="0%" />
					<stop stopColor="#00FF9E" stopOpacity="0" offset="100%" />
				</linearGradient>
				<linearGradient x1="8.35442826e-13%" y1="50%" x2="50%" y2="50%" id="linearGradient-top-5" gradientUnits="userSpaceOnUse">
					<stop stopColor="#00FFA5" offset="0%" />
					<stop stopColor="#00FF9E" stopOpacity="0" offset="100%" />
				</linearGradient>
				<linearGradient x1="25%" y1="50%" x2="4.62130334e-12%" y2="50%" id="linearGradient-top-6" gradientUnits="userSpaceOnUse">
					<stop stopColor="#00FFA5" offset="0%" />
					<stop stopColor="#00FF9E" stopOpacity="0" offset="100%" />
				</linearGradient>
				<linearGradient x1="25%" y1="50%" x2="4.62130334e-12%" y2="50%" id="linearGradient-top-7" gradientUnits="userSpaceOnUse">
					<stop stopColor="#00FFA5" offset="0%" />
					<stop stopColor="#00FF9E" stopOpacity="0" offset="100%" />
				</linearGradient>
				<linearGradient x1="25%" y1="50%" x2="4.62130334e-12%" y2="50%" id="linearGradient-top-8" gradientUnits="userSpaceOnUse">
					<stop stopColor="#00FFA5" offset="0%" />
					<stop stopColor="#00FF9E" stopOpacity="0" offset="100%" />
				</linearGradient>
			</defs>
			<g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Icon---Top---Inactive">
					<rect id="Rectangle" stroke={stroke} x="0.5" y="1.5" width="19" height="2" rx="1" />
					<rect id="Rectangle-top-Copy" stroke={stroke} x="0.5" y="6.5" width="19" height="2" rx="1" />
					<rect id="Rectangle-top-Copy-2" stroke={stroke} x="0.5" y="11.5" width="19" height="2" rx="1" />
					<rect id="Rectangle-top-Copy-3" stroke={stroke} x="0.5" y="16.5" width="19" height="2" rx="1" />
					<g id="CB-Icon-Oval" transform="translate(0.000000, 8.000000)">
						<circle id="Oval" stroke="#212636" strokeWidth="0.6" fill="#96A5B9" cx="6" cy="6" r="5.7" />
						<mask id="mask-top-2" fill="white">
							<use xlinkHref="#path-top-1" />
						</mask>
						<use id="Icon-Back" fill="#212636" xlinkHref="#path-top-1" />
						<g id="CB-Icon-LB" mask="url(#mask-top-2)" strokeLinecap="round">
							<g transform="translate(2.400000, 2.100000)" id="l-bolt">
								<g transform="translate(1.200000, 0.600000)">
									<line x1="2.7" y1="0.3" x2="3.3" y2="0.3" id="Line-4" stroke="url(#linearGradient-top-3)" strokeWidth="0.9" />
									<line x1="1.5" y1="1.5" x2="3.3" y2="1.5" id="Line-4-Copy" stroke="url(#linearGradient-top-4)" strokeWidth="0.9" />
									<line x1="0.3" y1="2.7" x2="4.5" y2="2.7" id="Line-4-Copy-2" stroke="url(#linearGradient-top-5)" strokeWidth="0.9" />
									<line x1="0.3" y1="3.9" x2="4.5" y2="3.9" id="Line-4-Copy-2" stroke="url(#linearGradient-top-6)" strokeWidth="0.9" />
									<line x1="1.5" y1="5.1" x2="3.3" y2="5.1" id="Line-4-Copy-4" stroke="url(#linearGradient-top-7)" strokeWidth="0.9" />
									<line x1="1.5" y1="6.3" x2="2.1" y2="6.3" id="Line-4-Copy-5" stroke="url(#linearGradient-top-8)" strokeWidth="0.9" />
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default Top;
