import React, { useCallback, useState } from "react";
import { cu, useMeasure } from "@credo/utilities";
import { classNames, RegEx } from "../../common";
import RestrictedContentOverlay from "../RestrictedContentOverlay";
import { ImageErrorIcon } from "../../assets/icons";
import { Spinner } from "../../spinner";
import VideoPlayer from "../post-video/VideoPlayer";
import styles from "../ReadMoreText.module.css";

export interface LinkPreviewProps {
	/**
	 * Has link url for the preview
	 * */
	linkUrl: string;
	/**
	 * Has link image to be shown for the preview
	 * */
	imageUrl: string;
	/**
	 * Whether post has senstitve content or not
	 * */
	isContentRestricted: boolean;
	/**
	 * Has link title for the preview
	 * */
	linkTitle: string;
	/**
	 * Has link host name for the preview
	 * */
	linkHostname: string;
	/**
	 * Has link channel name for the preview
	 * */
	linkChannelName: string;
	/**
	 * whethere post is shared or not, used for applying different styling
	 * */
	isSharedPost: boolean;
	/**
	 * Has error msg to be shown if image doesn't load
	 * */
	imageErrorMsg: string;
	/**
	 * Has sensitive content msg
	 * */
	contentSensitiveMsg: string;
	/**
	 * set true if the component is being called inside
	 * PostCompactView or need a compact version of this
	 * component
	 *
	 * @default false
	 * */
	// eslint-disable-next-line react/require-default-props
	isCompactView?: boolean;
}

const LinkPreviewComponent: React.FC<LinkPreviewProps> = (props) => {
	const {
		linkUrl,
		imageUrl,
		isContentRestricted,
		linkTitle,
		linkHostname,
		isSharedPost,
		imageErrorMsg,
		contentSensitiveMsg,
		linkChannelName,
		isCompactView = false,
	} = props;
	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(false);
	const [showContentSensitiveOverlay, setShowContentSensitiveOverlay] = useState(isContentRestricted);
	const [wrapperRef, { width }] = useMeasure();

	if (!cu.isSet(linkUrl)) {
		return null;
	}

	const onPress = () => {
		if (!showContentSensitiveOverlay) {
			window.open(linkUrl, "_blank");
		}
	};

	const renderImageLoadingAndError = () => {
		if (isLoading && isError) {
			return (
				<div className={classNames(
					"flex flex-col absolute flex-1 w-full h-full items-center justify-center bg-input-bg",
					isSharedPost ? "rounded-15" : "rounded-t-15",
				)}
				>
					<ImageErrorIcon xlinkTitle={imageErrorMsg} />
					<span className="text-gray-dark text-xs font-normal pt-1">{imageErrorMsg}</span>
				</div>
			);
		}
		if (isLoading) {
			return (
				<div className="flex absolute flex-1 w-full h-full items-center justify-center bg-input-bg rounded-15">
					<Spinner
						width="30"
						height="30"
						leaf_fill="var(--primary)"
					/>
				</div>
			);
		}
		return null;
	};

	const handleShowSensitiveContent = useCallback(
		() => setShowContentSensitiveOverlay(!showContentSensitiveOverlay),
		[showContentSensitiveOverlay],
	);

	const renderLinkImage = () => {
		if (cu.isYouTubeUrl(linkUrl)) {
			return (
				<VideoPlayer
					source={linkUrl}
					isContentRestricted={showContentSensitiveOverlay}
					contentSensitiveMsg={contentSensitiveMsg}
					imageErrorMsg={imageErrorMsg}
					onShowSensitiveContent={handleShowSensitiveContent}
				/>
			);
		} else if (!!imageUrl && typeof (imageUrl) === "string") {
			return (
				<div
					className={classNames(
						"flex flex-1 relative overflow-hidden rounded-t-[14px] h-full",
						isCompactView && isSharedPost ? "mx-1.5" : "",
						isSharedPost ? "rounded-b-15" : "",
					)}
					style={{
						// Tailwind class is not working named aspect-video
						aspectRatio: "16/9",
					}}
				>
					<img
						loading="lazy"
						src={imageUrl}
						alt="post-img"
						style={showContentSensitiveOverlay
							? { filter: "blur(40px)", aspectRatio: "16/9" }
							: { aspectRatio: "16/9" }}
						className={classNames(
							"bg-center bg-cover bg-no-repeat w-full object-cover",
							isError ? "p-1" : "",
						)}
						onLoad={() => setIsLoading(false)}
						onError={() => setIsError(true)}
					/>
					{renderImageLoadingAndError()}
					{!isError
						? (
							<RestrictedContentOverlay
								isContentRestricted={showContentSensitiveOverlay}
								title={contentSensitiveMsg}
								buttonTitle="See Post"
								onShowSensitiveContent={() => setShowContentSensitiveOverlay(!showContentSensitiveOverlay)}
							/>
						) : null}
				</div>
			);
		}
		return null;
	};

	const getClasses = () => {
		let classes = "";
		if (!imageUrl) {
			classes += "rounded-t-15 ";
		}
		if (cu.isYouTubeUrl(linkUrl) || isSharedPost) {
			if (imageUrl) {
				classes += `bg-transparent ${isCompactView ? "p-1" : "pt-4"}`;
			} else {
				classes += "bg-content-L1 ";
			}
		} else {
			// TODO: Change background color as the design
			classes += `bg-content-L1 ${isCompactView ? "p-1" : "p-4"}`;
		}
		return classes;
	};

	return (
		<div
			// @ts-ignore
			ref={wrapperRef}
			className={classNames(
				"flex flex-1 flex-col w-full cursor-pointer rounded-15",
				cu.isYouTubeUrl(linkUrl) || isSharedPost ? "" : "border border-content-L2",
			)}
			onClick={onPress}
			aria-hidden
		>
			{renderLinkImage()}
			<div
				className={classNames(
					"rounded-b-15",
					isSharedPost && !isCompactView ? "pt-2 " : "",
					imageUrl ? "" : "rounded-t-15",
					getClasses(),
				)}
			>
				{/* eslint-disable-next-line no-nested-ternary */}
				<div className={imageUrl ? "" : isCompactView ? "" : "flex flex-col items-start justify-start"}>
					{(cu.isSet(linkTitle) && !isCompactView)
						? (
							<p
								className={classNames(
									"text-basic text-lg break-words",
									styles.baseClamp,
									styles.readMoreLineClamp2,
								)}
							>
								{linkTitle}
							</p>
						) : null}
					{(cu.isSet(linkHostname) && !isCompactView)
						? (
							<p
								className={classNames(
									"text-gray-dark text-sm break-words",
									styles.baseClamp,
									styles.readMoreLineClamp1,
								)}
							>
								{RegEx.youtubeVideo.test(linkUrl) ? linkChannelName : linkHostname}
							</p>
						) : null}
					{cu.isSet(linkUrl)
						? (
							<p
								className={classNames(
									"text-primary text-sm break-words",
									styles.baseClamp,
									styles.readMoreLineClamp1,
								)}
								style={{
									width: width && !isCompactView ? `${width - 48}px` : "100%",
								}}
								title={linkUrl}
							>
								{linkUrl}
							</p>
						) : null}
				</div>
			</div>
		</div>
	);
};

const compareFn = (
	nextProps: LinkPreviewProps,
	prevprops: LinkPreviewProps,
) => nextProps.isCompactView === prevprops.isCompactView
	&& nextProps.isSharedPost === prevprops.isSharedPost
	&& nextProps.isContentRestricted === prevprops.isContentRestricted
	&& nextProps.imageUrl === prevprops.imageUrl
	&& nextProps.imageErrorMsg === prevprops.imageErrorMsg
	&& nextProps.contentSensitiveMsg === prevprops.contentSensitiveMsg
	&& nextProps.linkChannelName === prevprops.linkChannelName
	&& nextProps.linkHostname === prevprops.linkHostname
	&& nextProps.linkTitle === prevprops.linkTitle
	&& nextProps.linkUrl === prevprops.linkUrl;

const LinkPreview = React.memo(
	LinkPreviewComponent,
	compareFn,
);

export default LinkPreview;
