import React from "react";
import { FeedItem } from "@credo/utilities";

interface PostFlareInterface {
	item: FeedItem;
}

// eslint-disable-next-line import/prefer-default-export
export const PostFlare: React.FC<PostFlareInterface> = (props: PostFlareInterface) => {
	const { item } = props;
	const { postFlare } = item;

	/**
	 * The data comes in string format with an emoji in this manner '[:joy: top rated]'
	 * To remove the '[' and ']' we have to replace it with empty and directly
	 * display the flare.
	 *
	 * This flare must be added to locale.
	 * */
	const flareText = postFlare?.replace(/[\[\]]/g, "");

	return (
		<div className="px-4">
			<span className="text-sm text-gray-dark">
				{flareText}
			</span>
		</div>
	);
};
