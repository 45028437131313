import * as React from "react";
import {
	useEffect, useMemo, useRef, useState,
} from "react";
import * as d3 from "d3";
import { LabelledData, useEvtMgrListener, cu } from "@credo/utilities";
import { VoltsIcon } from "../assets/icons";
import LineChart, { DEFAULT_CHART_DIMENSIONS, LineChartProps } from "../charts/line-chart/line-chart-svg";
import { ScoreWidget } from "./score-widget";
import { Consts, VOLTS_TXN_ANIMATION_TIMER_MS } from "../common";
import { AssetGifs } from "../assets";
import { VoltsTransaction } from "./types";

export type VoltsBalanceWidgetProps = {
	/* either data or a function that returns data that'll be called to get data */
	balanceHistory: LabelledData[] | (() => LabelledData[]);
	/* how frequently to reload balanceHistory, if provided, expect balanceHistory to be a method */
	updateInterval?: number | "never";
	/* provide a custom score, if not provided will be the last entry in balanceHistory */
	score?: number | undefined;
	/* on click handler */
	onMouseDown?: () => void | undefined;
	/* on setting click handler */
	onSettingsClick?: () => void | undefined;
}
const VoltsBalanceWidget = ({
	balanceHistory,
	updateInterval = 10_000,
	score = undefined,
	onMouseDown = () => undefined,
	onSettingsClick = () => undefined,

}: VoltsBalanceWidgetProps) => {
	const voltsIcon = <VoltsIcon key="volts-svg" height={28} width={28} borderColor="var(--primary)" className="bg-dark-main rounded-full" />;
	const [iconState, setIconState] = useState<React.ReactNode>(voltsIcon);
	const [txnValue, setTxnValue] = useState<undefined | string>(undefined);
	// eslint-disable-next-line no-undef
	const timeoutRef = useRef<NodeJS.Timeout | null>(null);
	const renderIcon = () => (
		<button type="button" onMouseDown={thisOnMouseDown}>
			{iconState}
		</button>
	);
	const thisOnMouseDown = () => {
		console.log("Volts balance clicked");
	};

	useEffect(() => () => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
	}, []);

	const handleVoltsTransaction = (
		msg: {
			type: VoltsTransaction,
			value: number
		},
	) => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
		const getTxnData = () => {
			switch (msg.type) {
				case VoltsTransaction.DEPOSIT:
					return {
						icon: (
							<img
								key={Math.random()}
								id={Math.random().toString()}
								className="rounded-full"
								src={AssetGifs.voltsUp}
								alt="volts-up"
							/>
						),
						value: `+${msg.value}`,
					};
				case VoltsTransaction.WITHDRAW:
					return {
						icon: (
							<img
								key={Math.random()}
								id={Math.random().toString()}
								className="rounded-full"
								src={AssetGifs.voltsDown}
								alt="volts-down"
							/>
						),
						value: `-${msg.value}`,
					};
				default: return null;
			}
		};
		setIconState(getTxnData()?.icon);
		setTxnValue(getTxnData()?.value);
		timeoutRef.current = setTimeout(() => {
			setIconState(voltsIcon);
			setTxnValue(undefined);
		}, VOLTS_TXN_ANIMATION_TIMER_MS);
	};

	useEvtMgrListener(Consts.voltsTransaction, handleVoltsTransaction);

	const renderSettings = () => null;

	const [histData, setHistData] = useState({
		data: [] as LabelledData[],
		checksum: 0,
		score: 0,
	});

	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const updater = () => {
			setIsLoading(true);
			const newBalanceHistory = balanceHistory instanceof Function ? balanceHistory() : balanceHistory;

			if (newBalanceHistory.length > 0) {
				setHistData({
					data: newBalanceHistory,
					checksum: d3.sum(newBalanceHistory.map((d) => d.value)),
					score: newBalanceHistory[newBalanceHistory.length - 1].value,
				});
			}
			setIsLoading(false);
		};
		updater();
		const interval = (updateInterval !== "never") ? setInterval(updater, updateInterval) : undefined;

		return () => (interval && clearInterval(interval));
	}, [balanceHistory, updateInterval]);

	const historyChart = useMemo(() => {
		if (histData.checksum === 0) return null;
		// @ts-ignore
		const args: LineChartProps = {
			data: histData.data,
			verticalAxis: "none",
			horizontalAxis: "none",
			chartDimensions: {
				...DEFAULT_CHART_DIMENSIONS,
				marginTop: 1,
				marginBottom: 1,
				marginLeft: 0.1,
				marginRight: 0.1,
				width: 35,
				height: 28,
			},
			containerStyle: {
				backgroundColor: "transparent",
				opacity: "100%",
			},
			plotStyle: {
				fill: "black",
				opacity: "0%",
			},
			lineStyle: {
				stroke: "#FF8C01",
				opacity: "50%",
				strokeWidth: "2px",
				animationDuration: "2s",
			},

			areaGradient: {
				start: {
					stopColor: "limegreen",
					stopOpacity: "1",
					offset: "0",
				},
				end: {
					stopColor: "transparent",
					stopOpacity: ".5",
					offset: "100%",
				},
			},
			showTooltip: false,
		};

		return (
			<LineChart {...args} />
		);
	}, [histData?.checksum]);

	const format = d3.format("d");

	const getScore = React.useCallback(() => {
		const tempScore = score !== undefined ? score : histData.score;
		return cu.convertMSatsToSats(tempScore);
	}, [histData, score]);

	return (
		<ScoreWidget
			id="volts-balance-widget"
			isLoading={isLoading}
			showSettings
			settingsIcon={renderSettings}
			score={getScore()}
			scoreStyle={{
				fill: "#FF8C21",
			}}
			format={format}
			icon={renderIcon}
			back={() => historyChart}
			txnValue={txnValue}
		/>
	);
};

export default VoltsBalanceWidget;
