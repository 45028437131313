/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from "react";
import {
	createSearchParams, useNavigate,
} from "react-router-dom";
import moment from "moment";
import Hero from "../../gen-comp/Hero";
import { strings } from "../../i18n/config";
import Table from "../../gen-comp/table";
import {
	Modes, TableHeader,
} from "../../utils/types";
import { AUTH_URL, USER_DETAILS_PAGE } from "../../routes/constants";
import { ReportedProblemsTableHeaders } from "./constants";
import useOptionalSearchApi from "../../hooks/useOptionalSearchApi";
import { getReportedProblemsData, GetReportedProblemsVariables } from "./queries";
import { PAGINATION_LIMIT } from "../../utils/constants";
import { DATE_FORMAT } from "../../utils/utils";

export default function ReportedProblems() {
	const navigate = useNavigate();
	const [headers] = useState<TableHeader[]>(ReportedProblemsTableHeaders);
	const [page, setPage] = useState<number>(1);
	const [problemsData, setProblemsData] = useState<any>([]);
	const wrapperRef = useRef(null);

	const navigateToUserDetails = (item: any) => {
		navigate({
			pathname: AUTH_URL + USER_DETAILS_PAGE,
			search: `?${createSearchParams({ userId: item?.user_id })}`,
		});
	};

	const variables: GetReportedProblemsVariables = {
		headers: ReportedProblemsTableHeaders,
		offset: (page - 1) * PAGINATION_LIMIT,
	};

	const variablesForSearchAPI = {
		searchData: [],
		otherFilterQuery: "",
	};

	const [getSearchApiData, { data, loading }] = useOptionalSearchApi(getReportedProblemsData(variables), variablesForSearchAPI as any, {
		entity: "Stream",
		page: 1,
		useSearchApi: false,
		formatDataKey: "stream",
	});

	useEffect(() => {
		setPage(1);
		getSearchApiData();
	}, []);

	useEffect(() => {
		const items: any[] = [];
		if (data && data.length > 0 && data[0].posts) {
			const { posts } = data[0];
			posts?.forEach((element: any) => {
				items.push({
					...element,
					creat_ts: element.creat_ts
						? moment(new Date(element.creat_ts)).format(DATE_FORMAT) : strings("PostSearchPage.not_available"),
					login_type: element?.user_id ? strings("ReportedProblems.post_login") : strings("ReportedProblems.pre_login"),
				});
			});
			setProblemsData(items);
		}
	}, [data]);

	const redirectToUserDetailsPageWithMode = (item: any) => {
		navigate({
			pathname: AUTH_URL + USER_DETAILS_PAGE,
			search: `?${createSearchParams({
				userId: item?.user_id,
				mode: item?.mode,
			})}`,
		});
	};

	const renderColumn = (props: { item: any; header: any; }) => {
		const { item, header } = props;
		if (!header || !item) {
			return null;
		}
		return (
			<button
				type="button"
				className="w-full truncate underline text-blue-600 hover:text-blue-800 "
				onClick={() => redirectToUserDetailsPageWithMode({
					...item,
					mode: header.value.includes("e_") ? Modes.EGO : Modes.CREDO,
				})}
			>
				{item[header.value] || ""}
			</button>
		);
	};

	return (
		<div ref={wrapperRef}>
			<Hero title={strings("ReportedProblems.hero_title")} pageHasTable />
			<div className="bg-white p-5 rounded-lg my-5 mx-5 -mt-24">
				<div data-testid="users-data-table" className="my-0">
					<Table
						header={[
							...headers,
							{
								title: strings("ReportedProblems.login_type"),
								value: "login_type",
							}]}
						data={problemsData}
						pagination={{
							onPageChange: setPage,
							hidePageNumber: true,
							scrollOnPageChange: true,
							page,
						}}
						onItemClick={navigateToUserDetails}
						showHeader
						showSortIcons={false}
						isLoading={loading}
						CustomColumnComponent={renderColumn}
					/>
				</div>
			</div>
		</div>
	);
}
