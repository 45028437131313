import React, { useCallback, useEffect, useState } from "react";
import {
	CredoTab, FeedNoPost, Spinner, SpinnerLeafType,
} from "@credo/ui-components";
import InfiniteScroll from "react-infinite-scroll-component";
import { AppMode, PostType } from "@credo/utilities";
import { strings } from "../../../i18n/config";
import Accordion from "../../../gen-comp/Accordion";
import { ActivityStream, UserDetails } from "../../../utils/types";
import UserActivityItem from "./UserActivityItem";
import { useQuery } from "../../../hooks/graphql/useQuery";
import { getSingleUserDetails, GetSingleUserDetailsVariable } from "../queries";
import { PAGINATION_LIMIT } from "../../../utils/constants";
import { SingleUserFields } from "../constants";

interface UserActivitiesProps {
	userId: string;
	mode: AppMode;
	prof_id: string;
}

const UserActivities: React.FC<UserActivitiesProps> = (props: UserActivitiesProps) => {
	const {
		userId,
		mode,
		prof_id,
	} = props;

	const [egoActivityData, setEgoActivityData] = useState<any[]>([]);
	const [credoActivityData, setCredoActivityData] = useState<any[]>([]);
	const [activeIndex, setActiveIndex] = useState(0);
	const [page, setPage] = useState(1);

	const variables: GetSingleUserDetailsVariable = {
		userId: userId || "",
		prof_id: prof_id || "",
		mode: mode as AppMode,
		fields: SingleUserFields,
		offset: (page - 1) * PAGINATION_LIMIT,
	};

	const [getUserDetails, { data, loading }] = useQuery<UserDetails>(getSingleUserDetails(variables), {
		formatDataKey: "user",
		hasSingleData: true,
	});

	useEffect(() => {
		getUserDetails();
	}, []);

	const NoItemsToLoad = () => (
		<div
			className="w-full flex flex-col h-full items-center py-32"
		>
			<FeedNoPost />
			<span className="w-40 py-5 text-sm text-support text-gray-dark text-center">
				{strings("UserActivities.no_activity_message")}
			</span>
		</div>
	);

	const formatUserActivityData = (data: any) => {
		const formattedData: any[] = [];
		if (data) {
			data.forEach((item: any) => {
				let updatedItem = {};
				if (item.postType === PostType.ConnectUser
					|| item.postType === PostType.FollowUser
					|| item.postType === PostType.UnfollowUser
					|| item.postType === PostType.BlockedUser
					|| item.postType === PostType.UnconnectUser
					|| item.postType === PostType.CancelConnectUser) {
					updatedItem = {
						profId: item.prof_id || item.dest_prof_id || item.src_prof_idm,
						username: item.username || item.dest_username || item.src_username,
						firstName: item.firstName ?? item.dest_firstName ?? item.src_firstName,
						lastName: item.lastName || item.dest_lastName || item.src_lastName,
						mode: item.dest_mode ?? item.src_mode ?? item.mode ?? AppMode.EGO,
						profilePicUrl: item.profilePicRelUrl || item.dest_profilePicRelUrl
							|| item.src_profilePicRelUrl,
					};
				} else {
					updatedItem = {
						profId: item.prof_id || item.src_prof_id || item.dest_prof_id,
						username: item.username || item.src_username || item.dest_username,
						firstName: item.firstName || item.src_firstName || item.dest_firstName,
						lastName: item.lastName || item.src_lastName || item.dest_lastName,
						mode: item.src_mode ?? item.dest_mode ?? item.mode ?? AppMode.EGO,
						profilePicUrl: item.profilePicRelUrl || item.src_profilePicRelUrl
							|| item.dest_profilePicRelUrl,
					};
				}
				updatedItem = {
					...updatedItem,
					createTs: item.creat_ts,
					postUuid: item.postUuid,
					srcPostUuid: item.src_postUuid,
					postText: item.postText,
					commentUuid: item.commentUuid,
					uxpCode: item.uxpcode,
					rating: item.rating,
					postType: item.postType,
					reqType: item.reqType,
					streamId: item.targetStreamId || item.src_streamId,
					streamNm: item.s_name || item.name,
					streamPicUrl: item.postType === PostType.NewStream || item.postType === PostType.DeleteStream
						? item.src_profilePicRelUrl : item.s_profilePicRelUrl,
					emoCode: item.emo_code,
				};
				formattedData.push(updatedItem);
			});
		}
		return formattedData;
	};

	useEffect(() => {
		if (data && data?.userStreams) {
			data?.userStreams.forEach((element: any) => {
				if (element && element?.name?.toUpperCase() === ActivityStream.EGO_ACTIVITY_STREAM) {
					if (page === 1) {
						setEgoActivityData(formatUserActivityData(element?.posts));
					} else {
						setEgoActivityData([...egoActivityData, ...formatUserActivityData(element?.posts)]);
					}
				}
				if (element && element?.name?.toUpperCase() === ActivityStream.CREDO_ACTIVITY_STREAM) {
					if (page === 1) {
						setCredoActivityData(formatUserActivityData(element?.posts));
					} else {
						setCredoActivityData([...credoActivityData, ...formatUserActivityData(element?.posts)]);
					}
				}
			});
		}
	}, [data]);

	const handleOnChangeTab = useCallback((index: number) => {
		setActiveIndex(index);
		setPage(1);
	}, [activeIndex]);

	const handleLoadMore = () => {
		setPage(page + 1);
	};

	const renderFooter = () => {
		if (!loading) return null;
		return (
			<div className="flex items-center justify-center py-4">
				<Spinner
					leaf_shape={SpinnerLeafType.CIRCLES}
					width="20px"
					height="20px"
					leaf_fill="var(--primary)"
				/>
			</div>
		);
	};

	return (
		<div
			className="bg-white p-3 rounded-lg mx-5 mb-4 min-h-fit max-h-full max-w-[calc(100%-2.5rem)]"
		>
			<Accordion
				header={(
					<h5 className="mb-0">
						{strings("UserActivities.user_activities")}
					</h5>
				)}
				content={(
					<div className="flex flex-wrap pb-4 px-2">
						<CredoTab
							tab_categories={{
								[strings("UserActivities.ego")]:
									(
										<div
											className="w-full"
											key={strings("UserActivities.ego")}
										>
											{egoActivityData && egoActivityData.length > 0
												? (
													<InfiniteScroll
														dataLength={egoActivityData.length}
														next={handleLoadMore}
														hasMore
														loader={renderFooter()}
														className="max-h-96 overflow-auto"
														height="h-96"
													>
														{egoActivityData?.map((item: any) => (
															<div key={item?.postUuid}>
																<UserActivityItem item={item} />
															</div>
														))}
													</InfiniteScroll>
												)
												: <NoItemsToLoad />}
										</div>
									),
								[strings("UserActivities.credo")]: (
									<div
										className="w-full"
										key={strings("UserActivities.credo")}
									>
										{credoActivityData && credoActivityData.length > 0
											? (
												<InfiniteScroll
													dataLength={credoActivityData.length}
													next={handleLoadMore}
													hasMore
													loader={renderFooter()}
													className="max-h-96 overflow-auto"
													height="h-96"
												>
													{credoActivityData?.map((item: any) => (
														<div key={item?.postUuid}>
															<UserActivityItem item={item} />
														</div>
													))}
												</InfiniteScroll>
											)
											: <NoItemsToLoad />}
									</div>
								),
							}}
							onChangeTab={handleOnChangeTab}
							defaultActiveTab={activeIndex}
						/>
					</div>
				)}
			/>
		</div>
	);
};

export default UserActivities;
