import React, { SVGAttributes } from "react";

// eslint-disable-next-line import/prefer-default-export
const PostShare = (props: SVGAttributes<SVGElement>) => {
	const { fill = "var(--primary)" } = props;
	return (
		<svg
			width="24px"
			height="24px"
			viewBox="0 0 24 24"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<g id="icon-m-open_in_new" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="ios_share_FILL0_wght300_GRAD0_opsz24" transform="translate(12.5, 12.3269) rotate(90) translate(-12.5, -12.3269)translate(5, 2)" fill={fill} fillRule="nonzero">
					<path d="M1.8077,20.65375 C1.30256667,20.65375 0.875,20.478725 0.525,20.128725 C0.175,19.7787417 0,19.3511833 0,18.84605 L0,8.4615 C0,7.95636667 0.175,7.5288 0.525,7.1788 C0.875,6.8288 1.30256667,6.6538 1.8077,6.6538 L4.36535,6.6538 L4.36535,8.153775 L1.8077,8.153775 C1.73076667,8.153775 1.66024167,8.185825 1.596125,8.249925 C1.532025,8.31404167 1.499975,8.38456667 1.499975,8.4615 L1.499975,18.84605 C1.499975,18.9229833 1.532025,18.9935083 1.596125,19.057625 C1.66024167,19.121725 1.73076667,19.153775 1.8077,19.153775 L13.19225,19.153775 C13.2691833,19.153775 13.3397083,19.121725 13.403825,19.057625 C13.467925,18.9935083 13.499975,18.9229833 13.499975,18.84605 L13.499975,8.4615 C13.499975,8.38456667 13.467925,8.31404167 13.403825,8.249925 C13.3397083,8.185825 13.2691833,8.153775 13.19225,8.153775 L10.6346,8.153775 L10.6346,6.6538 L13.19225,6.6538 C13.6973833,6.6538 14.12495,6.8288 14.47495,7.1788 C14.82495,7.5288 14.99995,7.95636667 14.99995,8.4615 L14.99995,18.84605 C14.99995,19.3511833 14.82495,19.77875 14.47495,20.12875 C14.12495,20.47875 13.6973833,20.65375 13.19225,20.65375 L1.8077,20.65375 L1.8077,20.65375 Z M6.75,13.903775 L6.75,2.873025 L4.899975,4.723025 L3.84615,3.6538 L7.499975,7.27604914e-15 L11.1538,3.6538 L10.099975,4.723025 L8.24995,2.873 L8.24995,13.903775 L6.75,13.903775 L6.75,13.903775 Z" id="Shape" />
				</g>
			</g>
		</svg>
	);
};

export default PostShare;
