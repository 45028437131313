import React, { SVGAttributes } from "react";

// eslint-disable-next-line import/prefer-default-export
const QuickShareLink = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--primary)" } = props;
	return (
		<svg
			width="22px"
			height="22px"
			viewBox="0 0 22 22"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<g id="Home-/-Feed---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-icons" transform="translate(-72.000000, -621.000000)" stroke={stroke}>
					<g id="post_sharelink" transform="translate(72.000000, 621.000000)">
						<path d="M12.718,12.7882 L10.055,15.4502 C8.82,16.6852 6.818,16.6852 5.582,15.4502 C4.347,14.2152 4.347,12.2122 5.582,10.9772 L10.055,6.5042 C11.29,5.2692 13.293,5.2692 14.529,6.5042 C15.078,7.0542 15.383,7.7572 15.444,8.4762" id="Stroke-1" />
						<path d="M12.471,4.089 L15.133,1.426 C16.368,0.191 18.371,0.191 19.607,1.426 C20.841,2.662 20.841,4.664 19.607,5.9 L15.133,10.372 C13.898,11.608 11.896,11.608 10.66,10.372 C10.111,9.822 9.805,9.12 9.745,8.401" id="Stroke-3" />
						<path d="M9.2942,0.5157 L4.0732,0.5157 C2.1002,0.5157 0.5002,2.0077 0.5002,3.8487 L0.5002,17.1827 C0.5002,19.0227 2.1002,20.5157 4.0732,20.5157 L16.9262,20.5157 C18.8992,20.5157 20.5002,19.0227 20.5002,17.1827 L20.5002,11.7307" id="Stroke-5" />
					</g>
				</g>
			</g>
		</svg>
	);
};

export default QuickShareLink;
