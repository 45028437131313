/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGAttributes } from "react";

const ShareIcon = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--accent-2)",
	} = props;
	return (
		<svg width="14.6085761px" height="9.59226672px" viewBox="0 0 14.6085761 9.59226672" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
			<g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
				<g id="Sparkline-Icon" transform="translate(0.500000, 0.500044)" stroke={stroke}>
					<polyline points="2.27373675e-13 8.59222192 2.08685359 6.43686713 4.1216762 7.51454452 5.45351926 3.99168038 7.02767836 5.02491884 9.27985421 1.43515635 11.5291642 2.99168038 13.6085313 8.2214059e-17"></polyline>
				</g>
			</g>
		</svg>
	);
};

export default ShareIcon;
