import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { classNames } from "../common";

interface EngageCommunitiesSkeletonSkeletonProps {
	wrapperClasses?: string;
	noOfItems?: number;
	itemClass?: string;
	itemWrapperClass?: string;
}

const EngageCommunitiesSkeleton = (props: EngageCommunitiesSkeletonSkeletonProps) => {
	const {
		wrapperClasses, noOfItems, itemWrapperClass, itemClass,
	} = props;
	return (
		<div
			className={classNames(
				"w-full z-0",
				wrapperClasses,
			)}
		>
			<SkeletonTheme
				baseColor="var(--background-skeleton)"
				highlightColor="var(--hightlight-skeleton)"
			>
				<div className="flex flex-row items-center justify-center py-3">
					<Skeleton containerClassName="w-1/3 rounded-full" height={30} />
				</div>
				<div
					className="w-full flex flex-row items-start h-full bg-background-tertiary"
					style={{ height: 1 }}
				/>
				{Array.from(Array(noOfItems).keys()).map((i) => (
					<div
						key={i}
						className={classNames(
							"w-full flex flex-col items-center",
							itemWrapperClass,
						)}
					>
						<div
							className={classNames(
								"w-full flex flex-row items-center justify-between p-5",
								itemClass,
							)}
						>
							<div className="w-full flex flex-row items-start">
								<Skeleton width={40} height={40} />
								<div className="w-full flex flex-col ml-5">
									<Skeleton containerClassName="w-1/3" height={20} />
									<Skeleton containerClassName="w-1/6" height={15} />
								</div>
							</div>
							<div className="flex flex-row items-center justify-center">
								<Skeleton width={20} height={20} />
							</div>
						</div>
						{i < ((noOfItems ?? 0) - 1) ? (
							<div
								className="w-full flex flex-row items-start h-full bg-background-tertiary"
								style={{ height: 1 }}
							/>
						) : null}
					</div>
				))}
			</SkeletonTheme>
		</div>
	);
};

EngageCommunitiesSkeleton.defaultProps = {
	wrapperClasses: "",
	noOfItems: 5,
	itemClass: "",
	itemWrapperClass: "",
};

export default EngageCommunitiesSkeleton;
