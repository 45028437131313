import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useEffect } from "react";
import {
	CogIcon, MenuIcon, XIcon,
} from "@heroicons/react/outline";
import { Link, useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { useWindowSize } from "@credo/utilities";
import classNames from "../utils/classNames";
import { openSideBarAtom, userRolesAtom, sessionAtom } from "../utils/atoms";
import "./css/Header.css";
import { AUTH_URL, PROFILE_PAGE } from "../routes/constants";
import { isLoggedIn } from "../utils";
import Logo from "./Logo";
import { strings } from "../i18n/config";
import { logout } from "../modules/login/LoginRequests";
import { deleteAllCookies } from "../utils/cookieUtils";
import { signOutAccount } from "../utils/Firebase";

export default function Header() {
	const navigate = useNavigate();
	const [open, setOpen] = useAtom(openSideBarAtom);
	const [sessionData, setSessionData] = useAtom(sessionAtom);
	const [, setUserRoles] = useAtom(userRolesAtom);
	const { width } = useWindowSize();

	const logoutUser = async () => {
		localStorage.removeItem("timestamp");
		await logout();
		setOpen(!open);
		setUserRoles(null);
		setSessionData(null);
		deleteAllCookies();
		localStorage.removeItem("roles");
		localStorage.removeItem("userDetails");
		localStorage.clear();
		signOutAccount();
		navigate("/");
	};

	useEffect(() => {
		const userDetails = localStorage.getItem("userDetails");
		if (userDetails) {
			setSessionData(JSON.parse(userDetails));
		}
	}, [localStorage.getItem("userDetails")]);

	return isLoggedIn() ? (
		<nav data-testid="nav-bar" className="bg-background-dark border-b border-b-divider navbar z-10">
			<div className="mx-auto px-2 sm:px-6 lg:px-8">
				<div className="relative flex items-center justify-between h-16">
					<div className="absolute inset-y-0 left-0 flex items-center md:hidden pr-2">
						{/* Mobile menu button */}
						<div
							className={classNames(
								"inline-flex items-center justify-center p-2 rounded-md text-gray-400",
								"hover:text-white hover:bg-gray-700",
								"focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white",
							)}
							role="button"
							tabIndex={0}
							onClick={() => setOpen(!open)}
							onKeyDown={() => setOpen(!open)}
						>
							<span className="sr-only">{strings("Header.open_main_menu")}</span>
							{open ? (
								<XIcon className="block h-6 w-6" aria-hidden="true" />
							) : (
								<MenuIcon className="block h-6 w-6" aria-hidden="true" />
							)}
						</div>
					</div>
					<div className="flex-1 flex items-start justify-center sm:justify-start pl-0 sm:pl-10 md:pl-0">
						{width < 769 && <Logo height={48} width={48} iconOnly />}
					</div>
					<div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
						<span data-testid="welcome-name" className="text-white align-middle break-normal capitalize hidden sm:block">
							{strings("Header.welcome")}
							{" "}
							{sessionData?.firstname}
							{" "}
							{sessionData?.lastname}
							!
						</span>

						{/* Profile dropdown */}
						<Menu as="div" className="ml-2 relative">
							<div>
								<Menu.Button
									data-testid="header-right-menu"
									className={classNames(
										"p-1 rounded-full text-gray-400 hover:text-white",
									)}
								>
									<span className="sr-only normal-text-style">{strings("Header.settings")}</span>
									<CogIcon className="h-6 w-6" aria-hidden="true" />
								</Menu.Button>
							</div>
							<Transition
								as={Fragment}
								enter="transition ease-out duration-100"
								enterFrom="transform opacity-0 scale-95"
								enterTo="transform opacity-100 scale-100"
								leave="transition ease-in duration-75"
								leaveFrom="transform opacity-100 scale-100"
								leaveTo="transform opacity-0 scale-95"
							>
								<Menu.Items
									className={classNames(
										"origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white",
										"ring-1 ring-black ring-opacity-5 focus:outline-none",
									)}
								>
									<Menu.Item>
										{({ active }) => (
											<Link
												to={`${AUTH_URL}${PROFILE_PAGE}`}
												className={classNames(
													active ? "bg-gray-100" : "",
													"block px-4 py-2 text-sm text-gray-700 normal-text-style",
												)}
											>
												{strings("Header.your_profile")}
											</Link>
										)}
									</Menu.Item>
									{/* <Menu.Item>
										{({ active }) => (
											<Link
												to={`${AUTH_URL}${SETTINGS_PAGE}`}
												className={classNames(
													active ? "bg-gray-100" : "",
													"block px-4 py-2 text-sm text-gray-700 normal-text-style",
												)}
											>
												{strings("Header.settings")}
											</Link>
										)}
									</Menu.Item> */}
									<Menu.Item>
										{({ active }) => (
											// eslint-disable-next-line jsx-a11y/anchor-is-valid
											<Link
												to=""
												onClick={logoutUser}
												className={classNames(
													active ? "bg-gray-100" : "",
													"block px-4 py-2 text-sm text-gray-700 normal-text-style",
												)}
												data-testid="logout-button"
											>
												{strings("Header.logout")}
											</Link>
										)}
									</Menu.Item>
								</Menu.Items>
							</Transition>
						</Menu>
					</div>
				</div>
			</div>
		</nav>
	) : null;
}
