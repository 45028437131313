import React, { SVGAttributes } from "react";

// eslint-disable-next-line no-undef
const ImageError = (props: SVGAttributes<SVGElement>) => {
	const { xlinkTitle } = props;
	return (
		<svg
			width="18px"
			height="20px"
			viewBox="0 0 18 20"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<title>{xlinkTitle}</title>
			<g id="Home-/-Feed---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Feed---Content-Error-multi-image---Ego" transform="translate(-92.000000, -225.000000)" stroke="var(--accent)">
					<g id="Blog-Block" transform="translate(0.000000, 64.000000)">
						<g id="Group-5" transform="translate(16.762017, 130.000000)">
							<g id="image" transform="translate(0.237983, 0.000000)">
								<g id="image_loading_error" transform="translate(76.000000, 32.000000)">
									<polygon id="Stroke-1" points="15.5789474 14.9660571 15.5789474 17.5717714 0.421052632 17.5717714 0.421052632 15.4717714 5.92505263 8.88548571 9.96294737 13.3349143"></polygon>
									<polygon id="Stroke-3" points="15.5789474 5.48365714 15.5789474 0.428228571 0.421052632 0.428228571 0.421052632 10.2296571 5.92505263 3.64337143 9.96294737 8.09365714"></polygon>
									<path d="M8.5568,6.54437143 C8.52564211,6.40037143 8.5088,6.25037143 8.5088,6.09694286 C8.5088,4.91665714 9.47890526,3.96094286 10.6746947,3.96094286 C11.8704842,3.96094286 12.8397474,4.91665714 12.8397474,6.09694286 C12.8397474,6.3498 12.7951158,6.59322857 12.7134316,6.81865714" id="Stroke-5"></path>
									<polyline id="Stroke-7" points="9.96261053 13.3353429 15.5786105 10.7253429 15.5786105 14.9664857"></polyline>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default ImageError;
