import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { cu, YesNoOptions } from "@credo/utilities";
import { ProfilePicture } from "../../profile/profile-picture";
import { classNames } from "../../common";
import { ExploreCommCardMessages } from "../types";
import { SecondaryButton } from "../../button/SecondaryButton";
import { ButtonSize } from "../../button/types";

interface ExploreItem {
	name: string;
	descr: string;
	struname: string;
	streamId: string;
	profilePicRelUrl: string;
	ac_alwpos: string;
	ac_folcom: string;
	ac_joiaut: string;
	ac_joicom: string;
	rank: number,
	nb_posts: number,
	rank_type: string;
	isFollowed?: boolean;
	joinModes: string;
}

interface StreamParams {
	streamId: string,
	struname: string,
	meta: {
		title: string;
	},
}

interface OwnProps {
	card: ExploreItem;
	handleClickStream: (params: StreamParams) => void;
	removeCard: () => void;
	messages: ExploreCommCardMessages;
	updateCard: (isFollowed: boolean) => void;
}

// eslint-disable-next-line import/prefer-default-export
export const ExploreCommunitiesCard: React.FC<OwnProps> = (props: OwnProps) => {
	const {
		card,
		handleClickStream,
		removeCard,
		messages,
		updateCard,
	} = props;

	const [item, setItem] = useState(card);
	const isFollowed = !!item.isFollowed;

	useEffect(() => {
		setItem(card);
	}, [card]);

	const onNamePress = () => {
		handleClickStream({
			streamId: item.streamId,
			struname: item.struname,
			meta: {
				title: item.name,
			},
		});
	};

	const onPressFollowStream = () => {
		updateCard(!isFollowed);
	};

	return (
		<div>
			<div
				className={classNames(
					"px-8 py-1",
					"flex justify-center items-center",
				)}
			>
				<div className="shadow-rating-cards rounded-15 sugg-conn-card w-full relative">
					<div
						className="flex flex-col w-full justify-start bg-background
								rounded-t-15 p-3"
					>
						<div className="flex flex-row items-center">
							<ProfilePicture
								profilePicUrl={cu.buildSourceUrlImage(item.profilePicRelUrl) ?? ""}
								isStream
							/>
							<div className="flex flex-col flex-1 mx-2 w-[70%]">
								<p
									className="text-base text-primary font-medium cursor-pointer break-words text-start w-full"
									onClick={onNamePress}
									aria-hidden
									style={{
										lineClamp: 2,
										maxWidth: "100%",
										display: "-webkit-box",
										WebkitBoxOrient: "vertical",
										WebkitLineClamp: 2,
										overflow: "hidden",
										textOverflow: "ellipsis",
									}}
								>
									{item.name}
								</p>
								<span
									style={{
										lineClamp: 1,
										maxWidth: "100%",
										WebkitBoxOrient: "vertical",
										WebkitLineClamp: 1,
										overflow: "hidden",
										textOverflow: "ellipsis",
									}}
									className="text-sm text-start text-gray-dark"
								>
									{`c/${item.struname}`}
								</span>
							</div>
						</div>
					</div>
					<div
						className="flex px-4 py-10 rounded-b-15 justify-between flex-wrap bg-userRatingOverlayBackground"
					>
						<SecondaryButton
							size={ButtonSize.MEDIUM}
							disabled={false}
							label={messages.Dismiss}
							dimensionsClasses="w-[48%] h-9"
							buttonClassNames="rounded-full justify-center items-center capitalize"
							handleClick={removeCard}
						/>
						<SecondaryButton
							size={ButtonSize.MEDIUM}
							disabled={!(cu.isYes(item?.ac_folcom) && item?.joinModes?.includes(cu.getAppMode()))}
							label={isFollowed ? messages.isFollowed : messages.Follow}
							dimensionsClasses="w-[48%] h-9 !text-background-tertiary"
							buttonClassNames={classNames("rounded-full justify-center items-center capitalize",
								!(cu.isYes(item?.ac_folcom) && item?.joinModes?.includes(cu.getAppMode()))
									? "bg-gray-dark !border-gray-dark" : "!bg-primary")}
							handleClick={onPressFollowStream}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
