import * as React from "react"
import {SVGAttributes} from "react";

const SvgComponent = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { stroke = "var(--accent)" } = props;
	return (
		<svg
			width={101}
			height={101}
			viewBox="0 0 101 101"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<defs>
				<path id="no-content-a" d="M0 101h101V0H0z" />
			</defs>
			<g fill="none" fillRule="evenodd">
				<path
					d="M41.152 76.502a4 4 0 1 1-8 0 4 4 0 0 1 8 0ZM41.152 63.5a4 4 0 1 1-8 0 4 4 0 0 1 8 0ZM29.152 76.502a4 4 0 1 1-8 0 4 4 0 0 1 8 0ZM53.153 76.502a4 4 0 1 1-8 0 4 4 0 0 1 8 0ZM41.152 89.502a4 4 0 1 1-8 0 4 4 0 0 1 8 0ZM29.152 89.502a4 4 0 1 1-8 0 4 4 0 0 1 8 0ZM53.153 89.502a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
					stroke={stroke}
				/>
				<mask id="b" fill="#fff">
					<use xlinkHref="#no-content-a" />
				</mask>
				<path
					stroke={stroke}
					mask="url(#b)"
					d="M6.998 57.461h14v-3.582h-14zM6.998 79.463h10.497v-3.582H6.998zM6.998 90.463h10.497v-3.582H6.998zM17.495 68.462H6.998V64.88h10.497M6.998 46.461h14v-3.582h-14z"
				/>
				<path
					stroke={stroke}
					mask="url(#b)"
					d="M56.81 72.501H70.5v28H56.81V64.924L37.152 47.123 17.494 64.925V100.5H.5V32.327h26.995v23.541M73.993 37.692h8.03l4.884 10.39 4.876-10.39h1.923a6.794 6.794 0 0 0 6.794-6.794V7.294A6.794 6.794 0 0 0 93.706.5H52.917a6.794 6.794 0 0 0-6.794 6.794v3.744"
				/>
				<path
					d="M76.533 27.962c0 10.997-8.916 19.914-19.914 19.914-10.999 0-19.914-8.916-19.914-19.914 0-10.998 8.915-19.915 19.914-19.915 10.998 0 19.914 8.916 19.914 19.915Z"
					stroke={stroke}
					mask="url(#b)"
				/>
				<path
					d="M54.34 36.633c0-.808.216-1.38.65-1.716.432-.337.956-.505 1.572-.505.595 0 1.11.168 1.543.505.431.336.649.908.649 1.716 0 .789-.218 1.355-.65 1.7-.431.348-.947.52-1.542.52-.616 0-1.14-.172-1.572-.52-.434-.345-.65-.911-.65-1.7Zm.548-4.586v-.952c0-1 .189-1.835.564-2.51.373-.671 1.043-1.383 2.005-2.133.96-.73 1.637-1.337 2.032-1.817s.592-1.077.592-1.788c0-.73-.256-1.287-.766-1.673-.508-.384-1.234-.576-2.176-.576-.847 0-1.655.125-2.424.374a16.78 16.78 0 0 0-2.307.952l-1.297-2.71c.884-.481 1.846-.87 2.885-1.17 1.037-.297 2.181-.446 3.431-.446 1.941 0 3.44.467 4.498 1.398 1.058.933 1.588 2.159 1.588 3.678 0 .827-.131 1.534-.39 2.12a5.267 5.267 0 0 1-1.154 1.63 22.252 22.252 0 0 1-1.831 1.586c-.596.46-1.048.856-1.355 1.182-.309.327-.52.648-.636.966-.114.317-.172.706-.172 1.168v.72h-3.087ZM73.16 16.871a3.596 3.596 0 1 1 2.706 5.965M91.45 19.24a3.596 3.596 0 1 1-7.191 0 3.596 3.596 0 0 1 7.192 0Z"
					stroke={stroke}
					mask="url(#b)"
				/>
			</g>
		</svg>
	)
}

export default SvgComponent
