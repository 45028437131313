export interface LoggedInHeader {
	id: number;
	name: string;
	href: string;
	items?: LoggedInHeader[];
	isExternal?: boolean;
	badgeCount?: number;
	icon?: any;
	userData?: SessionObject;
	testId: string;
}

export interface SessionObject {
	_id?: string;
	dvc_tok?: string;
	firstname?: string;
	lastname?: string;
	objNm?: string;
	phoneCountryCode?: string;
	phoneNbValidated?: boolean;
	phoneNumber?: string;
	unVerifiedPhoneNumber?: string;
	sessAuth?: boolean;
	sid?: string;
	user_id?: string;
	email4SignInLinkToEmail?: string;
	permissions?: Array<Object>;
}

export enum ThemeOptions {
	DARK = "dark",
	LIGHT = "light"
}

export enum YesNoOptions {
	YES = "y",
	NO = "n"
}

export enum Modes {
	CREDO = "c",
	EGO = "e"
}

export enum ActivityStream {
	EGO_ACTIVITY_STREAM = "EAS",
	CREDO_ACTIVITY_STREAM = "CAS",
}

export enum PostMadeBy {
	USER = "u",
	BOT = "b",
	CUSTOM_BOT = "c"
}

export enum UserCommunitiesOptions {
	REL_TYPE = "relType",
	ROLE_MEMBER = "MEMBER",
	IS_FOLLOWED = "is_followed",
	IS_JOINED = "is_joined"
}

export enum NoOfReportedOptions {
	ZERO = "0",
	ONE = "1",
	ONE_TO_TEN = "1-10",
	TWO_TO_TEN = "2-10",
	GREATER_THAN_TEN = ">10",
}

export interface TableHeader {
	helperText?: string;
	title: string;
	value: string;
	type?: string;
	isFixedColumn?: boolean,
	isClickable?: boolean,
	showCustomColumnComponent?: boolean;
}
export interface ReportingUserDetails {
	reported_user_id: string;
	firstName: string;
	lastName: string;
	username: string;
	reportMessage: string;
	reportReason: string;
	mode: string;
	src_mode: string;
	reported_timestamp: string;
	src_streamId: string;
}

export interface CommentDetails {
	commentUuid: string;
	firstName: string;
	lastName: string;
	username: string;
	childComments: Array<CommentDetails>;
	parentComment: Array<CommentDetails>;
	profilePicRelUrl: string;
	nb_rep: string;
	user_id: string;
	creat_ts: string;
	isSubComment: string;
	commentText: string;
}

export interface DropdownItem {
	title: string;
	value: string;
	searchQueries?: SearchStatement[];
	type?: string;
}

export interface SearchStatement {
	operator?: string,
	propName: string,
	propVal: string | null,
	condition: string,
	FilterGroupStart?: string;
	FilterGroupEnd?: string;
}

export interface SearchBarElementInterface {
	selectedSearchValue: string;
	selectedValueData: DropdownItem[];
	index: string;
}

export interface UserFollowedStreamsInterface {
	name: string;
	streamId?: string;
	mode: string;
	steamId?: string;
	relType?: string;
}

export interface UserDetails {
	user_id: string;
	firstName: string;
	lastName: string;
	email_custom: string;
	username: string;
	egousername: string;
	eprofilePicRelUrl: string;
	ecoverPicRelUrl: string;
	edescr: string;
	cdescr: string,
	cprofilePicRelUrl: string,
	ccoverPicRelUrl: string,
	isbot: YesNoOptions;
	exp_stream?: {
		exp_post?: UserExperienceInterface[];
	}
	qual_stream?: {
		qual_post?: UserQualificationInterface[];
	}
	xpTag?: ExpertiseTag[];
	isSuspended?: YesNoOptions;
	suspension_reason?: string;
	suspension_end_date?: string;
	time_left?: string;
	followed_stream_relType?: UserFollowedStreamsInterface[];
	joined_stream_relType?: UserFollowedStreamsInterface[];
	cprof_id?: string;
	eprof_id?: string;
	e_nb_rep?: number;
	c_nb_rep?: number;
	userStreams?: any;
}
export interface ExpertiseTag {
	code: string;
}
export interface ExperienceCompany {
	name: string,
	code: string,
}

export interface ExperienceIndustry {
	name: string,
	code: string,
}

export interface UserExperienceInterface {
	postUuid: string;
	company: ExperienceCompany[];
	industry: ExperienceIndustry[]
	exp_position: UserExperiencePosition[]
}

export interface QualificationSchool {
	name: string;
	code: string;
}

export interface QualificationSchoolType {
	name: string;
	code: string;
}

export interface UserQualificationInterface {
	postUuid: string;
	exp_degree: UserQualificationDegree[]
	school: QualificationSchool[]
	schoolType?: QualificationSchoolType[]
}

export interface UserExperiencePosition {
	endDate: string;
	isCrtPos: YesNoOptions;
	isPosValidated: YesNoOptions;
	locCode: string | null;
	locDescr: string;
	posDescr: string;
	posRole: string;
	posTitle: string;
	startDate: string;
}

export interface UserQualificationDegree {
	posTitle: string | null;
	posRole: string | null;
	startDate: string;
	endDate: string;
	locDescr: string;
	locCode: string | null;
	degreeName: string;
	degreeDescr: string;
	isCrtDeg: YesNoOptions;
	isDegreeValidated: YesNoOptions;
}
export interface UserStreamFolAndJoinInterface {
	streamId?: string;
	name: string;
	mode?: string;
	is_joined: string;
	is_followed: string;
	role?: string;
}

export enum SnackBarTypeOptions {
	INFO = "info",
	SUCCESS = "success",
	WARN = "warning",
	ERROR = "error",
}

export enum DisabledPostType {
	POST = "p",
	COMMENT = "c",
}

export enum DatabaseFields {
	USERID = "user_id",
	PROFID = "prof_id",
	STREAMID = "streamId",
	SHARED_PROFID = "shared_prof_id",
	SHARED_POST_STREAMID = "shared_post_streamId",
	MEDIAOBJ_LINK_URL = "mediaObj_Link_Url",
	MEDIAOBJ_LINK_IMGURL = "mediaObj_Link_ImgUrl",
	IMG_BUCKETRELATIVEREFPATH0 = "img_bucketRelativeRefPath0",
	IMG_BUCKETRELATIVEREFPATH1 = "img_bucketRelativeRefPath1",
	IMG_BUCKETRELATIVEREFPATH2 = "img_bucketRelativeRefPath2",
	IMG_BUCKETRELATIVEREFPATH3 = "img_bucketRelativeRefPath3",
	VIDEO_BUCKETRELATIVEREFPATH1 = "video_bucketRelativeRefPath",
	SHARED_POSTUUID = "shared_postUuid",
	POSTUUID = "postUuid",
}

export enum UserActivitiesText {
	Cancelled_Join_Request = "You cancelled your request to join",
	Left_Community = "You left",
	Declined_Connection_Request = "You declined connection request",
	Accepted_Connection_Request = "You accepted",
}
