/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
	useCallback, useMemo, useRef, useState, useEffect,
} from "react";

// mentions, hashtags and linkify
import createMentionPlugin, { MentionData } from "@draft-js-plugins/mention";
import createHashtagPlugin from "@draft-js-plugins/hashtag";
import createLinkifyPlugin from "@draft-js-plugins/linkify";
import {
	EditorState, ContentState, SelectionState,
} from "draft-js";
import Editor from "@draft-js-plugins/editor";
import "draft-js/dist/Draft.css";
import { YesNoOptions } from "@credo/utilities";
import mentionsStyles from "./MentionsStyles.module.css";
import { classNames, RegEx } from "../../common";
import { ProfilePicture } from "../../profile/profile-picture";
import { ProfileSize } from "../../profile/types";
import { useDebouncedCallback } from "../../hooks/useDebouncedCallback";
import { styleMapForEditor } from "../../Constants";

const MAX_LENGTH_POST_TEXT = 1500;

export interface NewPostMentionsProps {
	postTextPlaceHolder: string;
	onChangeText: (text: string) => void;
	getSuggesionsFromServer: (msg: any) => void;
	mentionData: MentionData[];
	containerStyle: string;
	initialText: string;
}

export const NewPostMentions = ({
	postTextPlaceHolder,
	onChangeText,
	initialText = "",
	mentionData,
	containerStyle,
	getSuggesionsFromServer,
}: NewPostMentionsProps) => {
	const [editorInState, setEditorInState] = useState(() => EditorState.createEmpty());
	const [suggestions, setSuggestions] = useState(mentionData);
	const [isSuggestionOpen, setIsSuggestionOpen] = useState(false);
	const [isPostTextFocused, setIsPostTextFocused] = useState(false);
	const [tagName, setTagName] = useState("");

	useEffect(() => {
		const selection = new SelectionState({
			anchorKey: "abc", // key of block
			anchorOffset: 5,
			focusKey: "abc",
			focusOffset: 10, // key of block
			hasFocus: true,
			isBackward: false, // isBackward = (focusOffset < anchorOffset)
		});
		const newSelection = new SelectionState(selection);
		const initialTextState = EditorState.push(
			editorInState,
			ContentState.createFromText(initialText),
			"undo",
		);
		const newEditorState = EditorState.forceSelection(initialTextState, newSelection);
		setEditorInState(newEditorState);
		setTimeout(() => {
			mentionEditorRef.current!.focus();
		}, 500);
	}, [initialText]);

	const mentionEditorRef = useRef<Editor>(null);

	const { MentionSuggestions, plugins } = useMemo(() => {
		const mentionPlugin = createMentionPlugin({
			entityMutability: "IMMUTABLE",
			theme: mentionsStyles,
			mentionPrefix: "@",
			mentionTrigger: "@",
			supportWhitespace: true,
			mentionComponent: (mentionProps) => (
				<span className={mentionProps.className} contentEditable={false}>
					{mentionProps.children}
				</span>
			),
		});
		const { MentionSuggestions } = mentionPlugin;
		const hashtagPlugin = createHashtagPlugin({ theme: mentionsStyles });
		const linkifyPlugin = createLinkifyPlugin({ theme: mentionsStyles });
		const plugins = [mentionPlugin, hashtagPlugin, linkifyPlugin];
		return { plugins, MentionSuggestions };
	}, []);

	useEffect(() => {
		setSuggestions(mentionData);
	}, [mentionData]);

	const onChangeEditorText = useCallback((editorState: any) => {
		const contentState = editorState.getCurrentContent();
		const oldContent = editorInState.getCurrentContent();
		if (contentState === oldContent || contentState.getPlainText().length <= MAX_LENGTH_POST_TEXT) {
			setEditorInState(editorState);
		} else {
			const updatedEditorState = EditorState.moveFocusToEnd(
				EditorState.push(
					editorInState,
					ContentState.createFromText(oldContent.getPlainText()),
					"undo",
				),
			);
			setEditorInState(updatedEditorState);
		}
		if (contentState !== oldContent) {
			if (onChangeText) {
				onChangeText(editorState.getCurrentContent().getPlainText());
			}
		}
	}, [onChangeText]);

	const onSuggestionOpenChange = useCallback((open: boolean) => {
		if (!open) {
			setSuggestions(mentionData);
		}
		setIsSuggestionOpen(open);
	}, []);

	const onSearchChange = ({ value }: { value: string }) => {
		if (value && value.trim()) {
			const searchTag = value.trim();
			setTagName(searchTag);
			const msgData = {
				what: searchTag,
				s_users: YesNoOptions.YES,
				s_streams: YesNoOptions.NO,
			};
			if (getSuggesionsFromServer) getSuggesionsFromServer(msgData);
		} else {
			setTagName("");
			setSuggestions(mentionData);
			if (getSuggesionsFromServer) getSuggesionsFromServer(null);
		}
	};

	const onSearchChangeDebounce = useDebouncedCallback(({ value }: { value: string }) => {
		onSearchChange({ value });
	}, 500);

	const postText = editorInState.getCurrentContent().getPlainText();

	const addMention = (item: any) => {
		setTagName("");
	};

	const findWithRegex = (regex: any, contentBlock: any, callback: any) => {
		const text = contentBlock.getText();
		let matchArr; let
			start;
		// eslint-disable-next-line no-cond-assign
		while ((matchArr = regex.exec(text)) !== null) {
			start = matchArr.index;
			callback(start, start + matchArr[0].length);
		}
	};

	const handleBoldText = (contentBlock: any, callback: any) => {
		findWithRegex(RegEx.boldTag, contentBlock, callback);
	};

	const showBoldText = (props: any) => (<span className="font-bold">{props.children}</span>);

	const renderSuggestions = ({
		mention,
		theme,
		searchValue,
		isFocused,
		...parentProps
	}: any) => (
		<div
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...parentProps}
			className="py-3 flex flex-row justify-center rounded-lg items-center px-2 m-1 hover:bg-profile-border cursor-pointer"
		>
			<div className={classNames(theme.mentionSuggestionsEntryContainer)}>
				<div
					data-role-id={`${mention.id}`}
					className={classNames(theme.mentionSuggestionsEntryContainerRight)}
				>
					<button
						type="button"
						className="flex flex-row justify-center items-center z-10"
						onClick={() => { addMention(mention); }}
					>
						<ProfilePicture profilePicUrl={mention.avatar} size={ProfileSize.X_SMALL} />
						<div className={classNames(theme.mentionSuggestionsEntryText, "pl-3 text-center align-middle")}>
							<span className="text-center align-middle text-title-color">{mention.title ?? mention.name}</span>
						</div>
					</button>
				</div>
			</div>
		</div>
	);

	return (
		<div>
			<div
				className={classNames("bg-background rounded-2xl",
					"rounded-bl-none font-thin px-2 text-sm",
					postText?.length > 0 ? "text-basic" : "text-gray-dark",
					mentionsStyles.noZoom,
					mentionsStyles.editor,
					containerStyle)}
				onClick={() => {
					mentionEditorRef.current!.focus();
				}}
				aria-hidden
			>
				<Editor
					placeholder={postTextPlaceHolder}
					editorKey="editor-new-post"
					customStyleMap={styleMapForEditor} // For removing pasted text styles
					editorState={editorInState}
					onChange={onChangeEditorText}
					plugins={plugins}
					ref={mentionEditorRef}
					onFocus={() => setIsPostTextFocused(true)}
					onBlur={() => setIsPostTextFocused(false)}
					decorators={[{
						strategy: handleBoldText,
						component: showBoldText,
					}]}
				/>
				<MentionSuggestions
					open={isSuggestionOpen}
					onOpenChange={onSuggestionOpenChange}
					onSearchChange={onSearchChangeDebounce}
					suggestions={suggestions}
					onAddMention={addMention}
					entryComponent={renderSuggestions}
				/>
			</div>
			<span className="flex items-end justify-end text-gray-dark text-right text-sm px-2 h-4 my-2">
				{postText && postText?.length > 0 ? `${postText?.length}/${MAX_LENGTH_POST_TEXT}` : " "}
			</span>
		</div>
	);
};
