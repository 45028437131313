import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { classNames } from "../common";

interface BoostLeaderBoardSkeletonProps {
	wrapperClasses?: string;
}

const BoostLeaderBoardSkeleton: React.FC<BoostLeaderBoardSkeletonProps> = (props: BoostLeaderBoardSkeletonProps) => {
	const {
		wrapperClasses,
	} = props;

	return (
		<div
			className={classNames(
				"w-full z-0",
				wrapperClasses,
			)}
		>
			<SkeletonTheme
				baseColor="var(--background-dark-skeleton)"
				highlightColor="var(--hightlight-dark-skeleton)"
			>
				<div className="flex mb-2">
					<Skeleton containerClassName="w-1/3" height={20} />
				</div>
				<div className="grid grid-rows-4 grid-flow-col gap-4 text-white pb-4">
					<div />
					<Skeleton containerClassName="w-full col-span-2" height={20} />
					<Skeleton containerClassName="w-full col-span-2" height={20} />
					<Skeleton containerClassName="w-full col-span-2" height={20} />
					<div />
					<Skeleton containerClassName="w-1/3 mx-auto" height={20} />
					<Skeleton containerClassName="w-1/3 mx-auto" height={20} />
					<Skeleton containerClassName="w-1/3 mx-auto" height={20} />
					<Skeleton containerClassName="w-1/3 mx-auto" height={20} />
					<Skeleton containerClassName="w-1/3 mx-auto" height={20} />
					<Skeleton containerClassName="w-1/3 mx-auto" height={20} />
					<Skeleton containerClassName="w-1/3 mx-auto" height={20} />
					<Skeleton containerClassName="w-1/3 mx-auto" height={20} />
					<Skeleton containerClassName="w-1/3 mx-auto" height={20} />
					<Skeleton containerClassName="w-1/3 mx-auto" height={20} />
					<Skeleton containerClassName="w-1/3 mx-auto" height={20} />
					<Skeleton containerClassName="w-1/3 mx-auto" height={20} />
				</div>
			</SkeletonTheme>
		</div>
	);
};

export default BoostLeaderBoardSkeleton;
