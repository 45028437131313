import React, {
	useCallback, useMemo, useRef, useState,
} from "react";
import { cu, TopBoosters, useClickAway } from "@credo/utilities";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { BoostTrayProps } from "../index";
import {
	classNames, ScalerSize, SvgIcon,
} from "../../common";
import { CredoTag, NullCredoTagScoreData } from "../../credo-tag";
import { InfoCircleIcon } from "../../assets/icons";
import { ButtonSize, SecondaryButton } from "../../button";
import styles from "./boost-content.module.css";
import { BoostStats, CurrentBoost, CurrentRank } from "../boost-tray/components";
import { RankDelta } from "../types";
import { ProfilePicture } from "../../profile/profile-picture";
import { ProfileSize } from "../../profile/types";
import { BoostContentOp, BoostContentOpMessages } from "./components/BoostContentOp";
import { CurrentItem } from "./components/CurrentItem";

interface BoostContentProps {
	messages: {
		boost_this_post: string;
		boost_info: string;
		view_tutorial: string;
		your_boost: string,
		current: string,
		rank: string,
		boost: string,
		boosters: string,
		with_your_sats: string,
		boostStats: {
			pnl: string,
			position: string,
		}
		boostContentOp: BoostContentOpMessages
	}
	tag: string;
	boostTrayProps: BoostTrayProps;
	closeWebModal?: () => void;
}

const BoostContentSkeleton = () => (
	<SkeletonTheme
		baseColor="var(--background-dark-main)"
		highlightColor="#171C2D"
	>
		<div
			className={classNames(
				"pb-4",
				styles.boostContentGrid,
			)}
		>
			<div
				className="flex items-end"
			>
				<Skeleton
					height={24}
					width={60}
					className="!rounded-full"
				/>
			</div>
			<div
				className="flex items-end"
			>
				{[0, 1, 2].map(() => (
					<Skeleton
						height={24}
						width={60}
						className="!rounded-full mr-1"
					/>
				))}
			</div>
			<div
				className="flex flex-col justify-center items-start"
			>
				<div
					className="flex"
				>
					<Skeleton
						height={36}
						width={36}
						circle
						className="mr-1"
					/>
					<Skeleton
						height={48}
						width={32}
						className="rounded-lg"
					/>
				</div>
				<Skeleton
					height={36}
					width={36}
					circle
					className="mt-4"
				/>
			</div>
			<Skeleton
				height={175}
				width={110}
				className="mr-1 !rounded-lg"
			/>
		</div>
	</SkeletonTheme>
);

// eslint-disable-next-line import/prefer-default-export
export const BoostContent: React.FC<BoostContentProps> = (props: BoostContentProps) => {
	const {
		messages,
		tag,
		boostTrayProps,
		closeWebModal,
	} = props;
	const [showInfo, setShowInfo] = useState<boolean>(false);

	const infoRef = useRef<HTMLDivElement | null>(null);

	const closeInfo = useCallback(() => {
		setShowInfo(false);
	}, []);

	useClickAway(
		infoRef,
		closeInfo,
	);

	const {
		ranking,
		totalBoost,
		topBoosters,
		onActions,
		authorisation,
		postBoostInfo,
		estDivPayoutRate,
		currentTagScore,
	} = boostTrayProps;

	const rankDelta: RankDelta = useMemo(() => {
		if (ranking.currentRanking === ranking.prevRanking || ranking.prevRanking < 1) return RankDelta.SAME;
		if (ranking.currentRanking < ranking.prevRanking) {
			return RankDelta.POSITIVE;
		} else {
			return RankDelta.NEGATIVE;
		}
	}, [ranking]);

	const handleIconClick = useCallback(() => {
		setShowInfo((prevState) => !prevState);
	}, []);

	const handleClickViewTutorial = useCallback(() => {

	}, []);

	const renderTagText = useMemo(() => (
		<div
			className={classNames(
				"flex flex-col xs:w-28",
				"justify-end",
			)}
		>
			<p className="text-off-white font-pt-sans text-lg tracking-[0.138462px] font-normal leading-[22px] mb-1">
				{messages.boost_this_post}
			</p>
			<CredoTag
				id={`creed-selector-tag-${tag}`}
				tag={tag}
				size={ScalerSize.S}
				onRate={() => {}}
				dataProvider={NullCredoTagScoreData}
				isUserOwnPost={false}
				isCredoMode={false}
				// @ts-ignore
				messages={null}
				disable
			/>
		</div>
	), [tag]);

	const renderInfo = useMemo(() => (
		<div
			ref={infoRef}
			className={classNames(
				styles.boostInfoText,
				"rounded-lg mt-1 absolute bottom-0 right-0 z-[3]",
				showInfo ? "bg-credo-black md:max-h-[89px]" : "",
			)}
		>
			<SvgIcon
				icon={InfoCircleIcon}
				width={18}
				height={18}
				className={classNames(
					"cursor-pointer",
					showInfo ? "ml-1 mt-1" : "",
				)}
				stroke="var(--boost-tag-accent)"
				onClick={handleIconClick}
			/>
			{showInfo && (
				<React.Fragment>
					<p className="text-off-white text-[11px] leading-[14px] font-pt-sans p-1.5">
						{messages.boost_info}
					</p>
					<div className="w-full flex justify-end items-center px-2 pb-2.5 z-[3]">
						<SecondaryButton
							onClick={handleClickViewTutorial}
							dimensionsClasses="h-[18px] rounded-[16px] text-xs font-light !text-accent !lowercase px-2"
							size={ButtonSize.SMALL}
							label={messages.view_tutorial}
							buttonClassNames="!border-accent"
						/>
					</div>
				</React.Fragment>
			)}
		</div>
	), [messages, showInfo, handleClickViewTutorial]);

	const renderSingleBooster = useCallback((user: TopBoosters, index: number) => (
		<div
			key={user.eprof_id}
			className={classNames(
				"leading-3",
				topBoosters.length - 1 === index ? "w-[27px]" : "",
				"overflow-hidden",
				"relative",
				"z-[1] hover:z-[2]",
			)}
			style={{
				left: -8 * index,
			}}
			title={`${user.firstName ?? ""} ${user.lastName ?? ""}`}
		>
			<ProfilePicture
				profilePicUrl={cu.buildSourceUrlImage(user.eprofilePicRelUrl) ?? ""}
				size={ProfileSize.X_SMALL}
				score={user.ccs}
			/>
		</div>
	), []);

	const renderBoosters = useMemo(() => (
		<div className="flex">
			{topBoosters.slice(0, 5).map((item, index) => renderSingleBooster(item, index))}
		</div>
	), [topBoosters, renderSingleBooster]);

	const renderCurrent = useMemo(() => (
		<div className="flex flex-col mt-2.5">
			<span
				className="text-xs font-bold font-pt-sans text-boost-board-tag mb-2.5"
			>
				{messages.current}
			</span>
			<div className="flex mb-2.5">
				<CurrentItem
					title={messages.rank}
					item={(
						<CurrentRank
							rankDeltaSign={rankDelta}
							currentRank={ranking.currentRanking}
							className="relative -left-0.5 -top-px"
						/>
					)}
					classNames={{
						wrapperClassName: "mr-1 w-9",
					}}
				/>
				<CurrentItem
					title={messages.boost}
					classNames={{
						wrapperClassName: "w-8",
					}}
					item={(
						<CurrentBoost totalBoost={totalBoost} score={currentTagScore} />
					)}
				/>
			</div>
			<CurrentItem
				title={messages.boosters}
				item={renderBoosters}
			/>
		</div>
	), [totalBoost, currentTagScore, ranking, rankDelta]);

	const renderOP = useMemo(() => (
		<div className="flex flex-col justify-end">
			<span
				className="text-off-white font-pt-sans text-lg tracking-[0.138462px] font-normal mb-1"
			>
				{messages.with_your_sats}
			</span>
			<BoostContentOp
				postBoostInfo={postBoostInfo}
				estDivPayoutRate={estDivPayoutRate ?? 0}
				onActions={onActions}
				authorisation={authorisation}
				closeWebModal={closeWebModal}
				messages={messages.boostContentOp}
			/>
		</div>
	), [boostTrayProps, postBoostInfo, estDivPayoutRate]);

	const renderYourBoost = useMemo(() => (
		<div
			className="flex flex-col p-2.5 w-[110px] h-[175px] rounded-lg bg-control-l0-primary"
		>
			<span
				className="text-xs font-bold font-pt-sans text-boost-board-tag mb-2.5"
			>
				{messages.your_boost}
			</span>
			<BoostStats
				postBoostInfo={postBoostInfo}
				isWide={false}
				messages={{
					pnl: messages.boostStats.pnl,
					position: messages.boostStats.position,
				}}
			/>
		</div>
	), [boostTrayProps]);

	if (!postBoostInfo) {
		return <BoostContentSkeleton />;
	}

	return (
		<div
			className={classNames(
				// " h-[260px]",
				"pb-4",
				styles.boostContentGrid,
			)}
		>
			{renderTagText}
			{renderOP}
			{renderCurrent}
			{renderYourBoost}
			{renderInfo}
		</div>
	);
};
