import React, { SVGAttributes } from "react";

// eslint-disable-next-line import/prefer-default-export
const StreamDiscoverySettings = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--gray-dark)",
	} = props;
	return (
		<svg
			width="20px"
			height="20px"
			viewBox="0 0 20 20"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<g id="Streams---CT---190723" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-icons" transform="translate(-375.000000, -578.000000)" stroke={stroke}>
					<g id="streamDiscoverySettings" transform="translate(375.000000, 578.000000)">
						<path d="M19.4736842,10 C19.4736842,15.2326316 15.2326316,19.4736842 10,19.4736842 C4.76736842,19.4736842 0.526315789,15.2326316 0.526315789,10 C0.526315789,4.76736842 4.76736842,0.526315789 10,0.526315789 C15.2326316,0.526315789 19.4736842,4.76736842 19.4736842,10 Z" id="Stroke-1" />
						<path d="M2.42105263,9.98336842 C2.42105263,9.98336842 4.65894737,5.45284211 9.82842105,5.45284211 C14.9978947,5.45284211 17.5789474,9.98336842 17.5789474,9.98336842 L17.5789474,10.0170526 C17.5789474,10.0170526 15.3410526,14.5475789 10.1715789,14.5475789 C5.00210526,14.5475789 2.42105263,10.0170526 2.42105263,10.0170526 L2.42105263,9.98336842 Z" id="Stroke-3" />
						<path d="M12.7814737,10.0018947 C12.7814737,11.5376842 11.5362105,12.7829474 10.0004211,12.7829474 C8.46357895,12.7829474 7.21831579,11.5376842 7.21831579,10.0018947 C7.21831579,8.46610526 8.46357895,7.22084211 10.0004211,7.22084211 C11.5362105,7.22084211 12.7814737,8.46610526 12.7814737,10.0018947 Z" id="Stroke-5" />
						<path d="M5.95221053,13.4222105 C6.26378947,15.8211579 6.89326316,17.8485263 7.71326316,19.1843158" id="Stroke-7" />
						<path d="M7.71305263,0.815894737 C6.912,2.12221053 6.29094737,4.09063158 5.97305263,6.42010526" id="Stroke-9" />
						<path d="M14.0476842,6.57652632 C13.7361053,4.17757895 13.1066316,2.15126316 12.2866316,0.815473684" id="Stroke-11" />
						<path d="M12.2869474,19.1841053 C13.088,17.8777895 13.708,15.9104211 14.0269474,13.5809474" id="Stroke-13" />
					</g>
				</g>
			</g>
		</svg>
	);
};

export default StreamDiscoverySettings;
