/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGAttributes } from "react";

const NotFound = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--accent)",
	} = props;
	return (
		<svg
			width="72px"
			height="72px"
			viewBox="0 0 72 72"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<g id="Streams---CT---190723" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-icons" transform="translate(-224.000000, -1124.000000)" stroke={stroke}>
					<g id="stream_not_found" transform="translate(224.000000, 1124.000000)">
						<path
							d="M63.6546,27.7582 C64.8866,31.1922 65.5586,34.8922 65.5586,38.7502 C65.5586,56.7152 50.9956,71.2792 33.0296,71.2792 C15.0636,71.2792 0.5006,56.7152 0.5006,38.7502 C0.5006,20.7842 15.0636,6.2202 33.0296,6.2202 C36.5306,6.2202 39.9016,6.7742 43.0616,7.7972"
							id="Stroke-1"
							strokeDasharray="2,2"
						/>
						<path
							d="M12.056,32.7299 C15.377,32.7299 18.077,35.4289 18.077,38.7509 C18.077,42.0719 15.377,44.7699 12.056,44.7699 C8.735,44.7699 6.036,42.0719 6.036,38.7509 C6.036,35.4289 8.735,32.7299 12.056,32.7299 Z"
							id="Stroke-3"
							strokeDasharray="2,2"
						/>
						<path
							d="M33.1077,32.7299 C36.4287,32.7299 39.1277,35.4289 39.1277,38.7509 C39.1277,42.0719 36.4287,44.7699 33.1077,44.7699 C29.7867,44.7699 27.0867,42.0719 27.0867,38.7509 C27.0867,35.4289 29.7867,32.7299 33.1077,32.7299 Z"
							id="Stroke-5"
							strokeDasharray="2,2"
						/>
						<path
							d="M44.7171,59.35 C42.3801,52.394 38.5261,49.38 38.5261,49.38 L33.1061,53.651 L27.6901,49.38 C27.6901,49.38 24.4771,52.216 22.5791,56.712"
							id="Stroke-7"
							strokeDasharray="2,2"
						/>
						<path
							d="M23.6663,59.35 C21.3283,52.394 17.4743,49.38 17.4743,49.38 L12.0553,53.651 L6.6393,49.38 C6.6393,49.38 5.2073,50.644 3.6913,52.821"
							id="Stroke-9"
							strokeDasharray="2,2"
						/>
						<path
							d="M16.7132,66.8979 C16.5662,66.3769 16.4862,65.8279 16.4862,65.2589 C16.4862,61.9379 19.1862,59.2399 22.5072,59.2399 C25.8282,59.2399 28.5262,61.9379 28.5262,65.2589 C28.5262,67.4539 27.3492,69.3759 25.5932,70.4269"
							id="Stroke-11"
							strokeDasharray="2,2"
						/>
						<path
							d="M25.7005,7.0473 C27.4805,8.0943 28.6775,10.0293 28.6775,12.2403 C28.6775,15.5623 25.9775,18.2603 22.6565,18.2603 C19.3355,18.2603 16.6375,15.5623 16.6375,12.2403 C16.6375,11.6353 16.7265,11.0503 16.8935,10.4983"
							id="Stroke-13"
							strokeDasharray="2,2"
						/>
						<path
							d="M34.2669,32.8402 C31.9289,25.8842 28.0749,22.8712 28.0749,22.8712 L22.6559,27.1412 L17.2399,22.8712 C17.2399,22.8712 14.0269,25.7072 12.1279,30.2032"
							id="Stroke-15"
							strokeDasharray="2,2"
						/>
						<path
							d="M13.2152,32.8402 C10.8782,25.8842 7.0242,22.8712 7.0242,22.8712 L3.1162,25.9492"
							id="Stroke-17"
							strokeDasharray="2,2"
						/>
						<path
							d="M54.1585,32.7299 C57.4795,32.7299 60.1795,35.4289 60.1795,38.7509 C60.1795,42.0719 57.4795,44.7699 54.1585,44.7699 C50.8375,44.7699 48.1385,42.0719 48.1385,38.7509 C48.1385,35.4289 50.8375,32.7299 54.1585,32.7299 Z"
							id="Stroke-19"
							strokeDasharray="2,2"
						/>
						<path
							d="M62.4495,52.6469 C60.8905,50.4079 59.5765,49.3799 59.5765,49.3799 L54.1575,53.6509 L48.7415,49.3799 C48.7415,49.3799 45.5275,52.2159 43.6305,56.7119"
							id="Stroke-21"
							strokeDasharray="2,2"
						/>
						<path
							d="M40.47,70.4262 C38.714,69.3732 37.538,67.4522 37.538,65.2592 C37.538,61.9382 40.237,59.2392 43.558,59.2392 C46.879,59.2392 49.578,61.9382 49.578,65.2592 C49.578,65.8262 49.499,66.3752 49.353,66.8962"
							id="Stroke-23"
							strokeDasharray="2,2"
						/>
						<path
							d="M41.3665,17.7875 C39.2065,16.8725 37.6875,14.7315 37.6875,12.2405 C37.6875,10.0645 38.8455,8.1565 40.5785,7.0985"
							id="Stroke-25"
							strokeDasharray="2,2"
						/>
						<path
							d="M45.4368,25.7787 L43.7068,27.1407 L38.2908,22.8717 C38.2908,22.8717 35.0778,25.7077 33.1798,30.2037"
							id="Stroke-27"
							strokeDasharray="2,2"
						/>
						<path
							d="M70.5589,15.2631 C70.5589,23.4161 63.9499,30.0261 55.7959,30.0261 C47.6429,30.0261 41.0339,23.4161 41.0339,15.2631 C41.0339,7.1101 47.6429,0.5001 55.7959,0.5001 C63.9499,0.5001 70.5589,7.1101 70.5589,15.2631 Z"
							id="Stroke-29"
						/>
						<polygon id="Stroke-31" points="57.1536 18.9467 54.4386 18.9467 53.2056 6.5177 58.3876 6.5177" />
						<path
							d="M57.6214,23.1498 C57.6214,24.1578 56.8044,24.9738 55.7964,24.9738 C54.7884,24.9738 53.9724,24.1578 53.9724,23.1498 C53.9724,22.1418 54.7884,21.3248 55.7964,21.3248 C56.8044,21.3248 57.6214,22.1418 57.6214,23.1498 Z"
							id="Stroke-33"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default NotFound;
