import React from "react";
import classNames from "../utils/classNames";

interface CoverPictureProps {
	coverPicUrl: string;
	coverPicStyle?: string;
}

export default function CoverPicture(props: CoverPictureProps) {
	const { coverPicUrl, coverPicStyle } = props;

	return (
		<div className="flex flex-wrap justify-center">
			<img
				src={coverPicUrl}
				className={classNames("w-full h-40 object-cover", coverPicStyle!)}
				alt={coverPicUrl}
			/>
		</div>
	);
}

CoverPicture.defaultProps = {
	coverPicStyle: "",
};
