import React, { useState } from "react";

interface ReadMoreProps {
	children: string | null;
	maxLengthOfChar?: number
	className?: string;
	id?: string;
}

const ReadMore = ({
	children, maxLengthOfChar = 150, id, className,
}: ReadMoreProps) => {
	const text = children;
	const [isReadMore, setIsReadMore] = useState(true);
	const toggleReadMore = () => {
		setIsReadMore(!isReadMore);
	};

	if (!text) {
		return null;
	}

	return (
		<p className={className} data-testid={id}>
			{isReadMore ? text?.slice(0, maxLengthOfChar) : text}
			&nbsp;
			{text?.length > maxLengthOfChar && (
				// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
				<span onClick={toggleReadMore} className="text-primary cursor-pointer">
					{isReadMore ? "...see more" : "see less"}
				</span>
			)}
		</p>
	);
};

ReadMore.defaultProps = {
	maxLengthOfChar: 150,
	className: "",
	id: "",
};

export default ReadMore;
