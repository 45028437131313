import * as React from "react"
import {SVGAttributes} from "react";

const X = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const {stroke = "var(--off-white)", fill } = props;
	return (
		<svg width={25} height={25} viewBox="9 9 7 7" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g stroke={fill || stroke} fill="none" fillRule="evenodd">
				<path d="m9.698 9.697 6.06 6.061M15.758 9.697l-6.06 6.061" />
			</g>
		</svg>
	)
}

export default X;
