import React from "react";
import {
	AuthorisationCheckOutcome, AuthorisationEngine, Context, Errors, Permission,
} from "@credo/utilities";

export const NullAuthorisationEngine: AuthorisationEngine = {
	checkAction(_action: string, _onObject: any, _addContext?: Context): AuthorisationCheckOutcome {
		Errors.throwNullObjectUseError(this);
	},
	isActionAllowed(_action: string, _onObject: any, _addContext?: Context, _onResult?: (result: boolean, p: Permission) => void): boolean {
		Errors.throwNullObjectUseError(this);
	},
};
export const NullAuthorisationCheckOutcome: AuthorisationCheckOutcome = {
	blocker(): Permission {
		Errors.throwNullObjectUseError(this);
	},
	failed(): Permission[] {
		Errors.throwNullObjectUseError(this);
	},
	onFailed(_fn: (p: Permission) => boolean): AuthorisationCheckOutcome {
		Errors.throwNullObjectUseError(this);
	},
	result() {
		Errors.throwNullObjectUseError(this);
	},
	onBlocked(_fn: (p: Permission) => void): AuthorisationCheckOutcome {
		Errors.throwNullObjectUseError(this);
	},
	onPermissionChange(_fn: (p: Permission, deleted: boolean) => void): AuthorisationCheckOutcome {
		Errors.throwNullObjectUseError(this);
	},
};

export const AuthorisationEngineContext = React.createContext<AuthorisationEngine>(NullAuthorisationEngine);
export const AuthorisationCheckOutcomeContext = React.createContext<AuthorisationCheckOutcome>(NullAuthorisationCheckOutcome);
