export enum PermissionActions {
	CREATE = "create",
	DELETE = "delete",
	EDIT = "edit",
	RATE = "rate",
	SIGNIN = "signin",
	ACCESS = "access",
	BOOST = "boost",
}

export enum PermissionObjectTypes {
	POST = "post",
	USER = "user",
	APP = "app",
	COMMUNITY = "community",
	FEATURE = "feature",
}
