/**
 * Simple listen/notify system
 */
import { Logger } from "./Logger";

export type Listener<EventClass> = (evt:EventClass, data?:any) => void;

export class Notifier<EventClass extends object> {
	private _wmap:WeakMap<EventClass, Array<Listener<EventClass>>> = new Map();

	constructor() {

	}
	public notify(event:EventClass, data?:any ) {
		let listeners = this._wmap.get(event);
		if(listeners) {
			listeners.forEach((l, ix) => {
				Logger.debug("Notifying:" + l + " about:" + event);
				this._notify(l,event,data);
			});
		}
	}
	private _notify(listener:Listener<EventClass>, event:EventClass, data:any) {
		listener(event, data);
	}

	/**
	 * Adds the listener to the list of those to be notified
	 * @param key
	 * @param listener
	 * @returns listener - for convenience when using inline stuff and the need to remove this later
	 */
	public add(key:EventClass, listener:Listener<EventClass>):Listener<EventClass> {
		let r = this._wmap.get(key);
		if( r == null ) {
			r = new Array<Listener<EventClass>>();
		}
		if(r.includes(listener)) {
			Logger.debug("Updated listener for event:" + key + ", listener:" + listener);
		}else {
			Logger.debug("Added listener for event:" + key + ", listener:" + listener);
		}
		r.push(listener);
		this._wmap.set(key, r);
		return listener;
	}

	/**
	 * Removes the listener from the list of those that need to be notified
	 * @param key
	 * @param listener
	 */
	public remove(key:EventClass, listener:Listener<EventClass>) {
		let r = this._wmap.get(key);
		if (r != null) {
			r = r.filter((fl, index) => fl != listener);
			Logger.debug("Removed listener for event:" + key + ", listener:" + listener);
			this._wmap.set(key, r);
		}
	}

}

export interface Publisher<ClassToProvide> {
	start(consumer: Consumer<ClassToProvide>): void;

	stop(consumer: Consumer<ClassToProvide>): void;
}

export interface Consumer<ClassToConsume> {
	consume(what: ClassToConsume): void;
}

