import React, { SVGAttributes } from "react";

// eslint-disable-next-line import/prefer-default-export
const TagNoRate = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--primary)" } = props;
	return (
		<svg
			width="36px"
			height="31px"
			viewBox="0 0 36 31"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<title>scwNoRate</title>
			<defs>
				<polygon id="path-1" points="0 30.3129 35.77 30.3129 35.77 -0.0001 0 -0.0001" />
			</defs>
			<g id="Credo-Score-Exploration" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Credo-Score-Explore---Credo---Score-indicator-2" transform="translate(-170.000000, -333.000000)">
					<g id="pop-up-bubble" transform="translate(128.000000, 317.000000)">
						<g id="scwNoRate" transform="translate(42.000000, 16.000000)">
							<path
								d="M35.2699,7.5445 C35.2699,11.4355 32.1159,14.5885 28.2259,14.5885 C24.3349,14.5885 21.1809,11.4355 21.1809,7.5445 C21.1809,3.6535 24.3349,0.5005 28.2259,0.5005 C32.1159,0.5005 35.2699,3.6535 35.2699,7.5445 Z"
								id="Stroke-1"
								stroke={stroke}
							/>
							<path
								d="M22.2386,13.3421 L19.1066,13.3421 C18.2236,13.3421 17.3626,12.8781 17.2996,11.9751 L17.2916,11.8691 L17.3916,11.1731 C17.6456,9.4081 17.3846,7.6191 16.6356,5.9991 L16.4606,5.6171 C16.2236,5.1001 15.8166,4.6631 15.2866,4.4461 C14.6656,4.1911 13.9716,4.2331 13.3806,4.5661 C12.6536,4.9761 12.2586,5.7901 12.3066,6.6161 L12.4386,8.8521 C12.4576,9.2031 12.3746,9.5461 12.1996,9.8481 L8.8066,15.7191 C6.8226,18.2101 3.4646,17.7631 2.0006,17.7741 L2.0006,17.7741 C1.1756,17.7741 0.4996,18.4421 0.4996,19.2581 L0.4996,26.2001 C0.4996,27.0161 1.1756,27.6841 2.0006,27.6841 L7.8346,27.6841 L7.8666,27.6841 C9.2156,27.6841 10.5276,27.9551 11.7656,28.4901 L12.9116,28.9841 C14.1826,29.5311 15.5526,29.8131 16.9386,29.8131 L16.9436,29.8131 L21.0646,29.8131"
								id="Stroke-3"
								stroke={stroke}
							/>
							<path
								d="M27.9525,21.8855 L22.0445,21.8855 C20.8645,21.8855 19.9085,20.9295 19.9085,19.7495 L19.9085,19.7495 C19.9085,18.5705 20.8645,17.6135 22.0445,17.6135 L27.9525,17.6135 C29.1325,17.6135 30.0885,18.5705 30.0885,19.7495 L30.0885,19.7495 C30.0885,20.9295 29.1325,21.8855 27.9525,21.8855 Z"
								id="Stroke-5"
								stroke={stroke}
							/>
							<path
								d="M26.3186,29.8129 L21.0436,29.8129 C19.9906,29.8129 19.1366,28.9589 19.1366,27.9059 L19.1366,27.9059 C19.1366,26.8529 19.9906,25.9989 21.0436,25.9989 L26.3186,25.9989 C27.3716,25.9989 28.2256,26.8529 28.2256,27.9059 L28.2256,27.9059 C28.2256,28.9589 27.3716,29.8129 26.3186,29.8129 Z"
								id="Stroke-7"
								stroke={stroke}
							/>
							<path
								d="M26.9333,25.9992 L21.2433,25.9992 C20.1073,25.9992 19.1873,25.0782 19.1873,23.9422 L19.1873,23.9422 C19.1873,22.8062 20.1073,21.8852 21.2433,21.8852 L26.9333,21.8852 C28.0693,21.8852 28.9903,22.8062 28.9903,23.9422 L28.9903,23.9422 C28.9903,25.0782 28.0693,25.9992 26.9333,25.9992 Z"
								id="Stroke-9"
								stroke={stroke}
							/>
							<path
								d="M19.187,23.9423 C19.187,23.9423 16.901,20.8643 11.873,21.8853"
								id="Stroke-11"
								stroke={stroke}
							/>
							<path
								d="M30.1348,14.2838 C30.3648,14.6248 30.4998,15.0358 30.4998,15.4778 L30.4998,15.4778 C30.4998,16.6578 29.5438,17.6138 28.3638,17.6138 L22.4558,17.6138 C21.2758,17.6138 20.3198,16.6578 20.3198,15.4778 L20.3198,15.4778 C20.3198,14.2988 21.2758,13.3418 22.4558,13.3418 L24.2248,13.3418"
								id="Stroke-13"
								stroke={stroke}
							/>
							<mask id="mask-2" fill="none">
								<use xlinkHref="#path-1" />
							</mask>
							<g id="Clip-16" />
							<polygon
								id="Stroke-15"
								stroke={stroke}
								points="27.225 8.3099 29.225 8.3099 29.225 3.3099 27.225 3.3099"
							/>
							<path
								d="M29.3088,11.2269 C29.3088,11.8249 28.8238,12.3099 28.2258,12.3099 C27.6268,12.3099 27.1418,11.8249 27.1418,11.2269 C27.1418,10.6289 27.6268,10.1439 28.2258,10.1439 C28.8238,10.1439 29.3088,10.6289 29.3088,11.2269 Z"
								id="Stroke-17"
								stroke={stroke}
							/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default TagNoRate;
