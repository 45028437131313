import React from "react";
import { CredoSwitchSize } from "../credo-switch/types";
import { UCWDimensions, UCWRadius, UCWSize } from "./types";

export interface UserCredibilityWidgetProps {
	/**
	 * Set true for dark logo for the light background
	 * @default true
	 * */
	isCredo?: boolean;
	/**
	 * Set size for the UCW
	 * @default M
	 * */
	size: UCWSize | CredoSwitchSize;
	/**
	 * Set user credibility value
	 * @default 0
	 * */
	uCredibility: number;
	/**
	 * Set user credibility value
	 * @default ""
	 * */
	backgroundColor?: string;
}

export const UserCredibilityWidget = (props: UserCredibilityWidgetProps) => {
	const {
		uCredibility, isCredo, size, backgroundColor,
	} = props;

	const credoTrackColor = "#212636";
	const egoTrackColor = "#FFFFFF";
	const credoWholeFillColor = "#171C2D";
	const egoViewFillColor = "#F0F1F4";

	const width = UCWDimensions[size];
	const height = UCWDimensions[size];
	const cRadiusReduce = UCWRadius[size];
	const half = width / 3;
	const handlerSize = width / 3;
	const handlerTickness = width / 3 - width / 4;
	const shiftX = width / 5.6;
	const shiftY = width / 6;
	const showThickLines = true;

	const polarToCartesian = (centerX: number, centerY: number, radius: number, angleInDegrees: number) => {
		const angleInRadians = (angleInDegrees - 90) * (Math.PI / 180.0);
		return {
			x: centerX + (radius * Math.cos(angleInRadians)),
			y: centerY + (radius * Math.sin(angleInRadians)),
		};
	};

	const describeArc = (x: number, y: number, radius: number, startAngle: number, endAngle: number) => {
		const start = polarToCartesian(x, y, radius, endAngle);
		const end = polarToCartesian(x, y, radius, startAngle);
		const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
		const d = [
			"M", start.x, start.y,
			"A", radius, radius, 0, largeArcFlag, 0, end.x, end.y,
		].join(" ");
		return d;
	};

	const getFilledPath = () => {
		let tempUCredibility = uCredibility;
		if (uCredibility <= 0) {
			return null;
		}
		if (uCredibility >= 100) {
			tempUCredibility = 100;
		}
		return (
			<g>
				{
					tempUCredibility <= 30
						? (
							<path
								d={describeArc(half - (cRadiusReduce), half - (cRadiusReduce),
									half - (cRadiusReduce), 0, (tempUCredibility) * 2.7)}
								fill="none"
								stroke={"url('#grad0')"}
								strokeWidth={handlerSize / 2.5}
								strokeLinecap="round"
							/>
						)
						: null
				}
				{
					tempUCredibility > 30 && tempUCredibility <= 50
						? (
							<path
								d={describeArc(half - (cRadiusReduce), half - (cRadiusReduce),
									half - (cRadiusReduce), 0, (tempUCredibility) * 2.7)}
								fill="none"
								stroke={"url('#grad30')"}
								strokeWidth={handlerSize / 2.5}
								strokeLinecap="round"
							/>
						)
						: null
				}
				{
					tempUCredibility > 50
						? (
							<path
								d={describeArc(half - (cRadiusReduce), half - (cRadiusReduce),
									half - (cRadiusReduce), 0, (tempUCredibility) * 2.7)}
								fill="none"
								stroke={"url('#grad50')"}
								strokeWidth={handlerSize / 2.5}
								strokeLinecap="round"
							/>
						)
						: null
				}
			</g>
		);
	};

	const getHandlerColor = () => {
		let ratingColor = "rgb(255, 101, 77)";
		if (uCredibility >= 0 && uCredibility < 35) {
			ratingColor = "rgb(255, 101, 77)";
		} else if (uCredibility >= 35 && uCredibility < 40) {
			ratingColor = "rgb(253, 137, 69)";
		} else if (uCredibility >= 40 && uCredibility < 50) {
			ratingColor = "rgb(252, 150, 63)";
		} else if (uCredibility >= 50 && uCredibility < 55) {
			ratingColor = "rgb(250, 205, 50)";
		} else if ((uCredibility >= 55 && uCredibility < 60)) {
			ratingColor = "rgb(90, 204, 193)";
		} else if ((uCredibility >= 60 && uCredibility < 65)) {
			ratingColor = "rgb(58, 201, 208)";
		} else if ((uCredibility >= 65 && uCredibility < 85)) {
			ratingColor = "rgb(9, 204, 229)";
		} else if ((uCredibility >= 85 && uCredibility <= 100) || uCredibility > 100) {
			ratingColor = "rgb(11, 199, 224)";
		}
		return ratingColor;
	};

	const makeItArrayIfItsNot = (input: any) => (Object.prototype.toString.call(input) !== "[object Array]" ? [input] : input);

	// TODO: move to utils
	const isDefined = (x: any) => (typeof x !== "undefined");

	// TODO: move to utils
	const isSet = (x: any) => x != null && isDefined(x) && x !== "";

	const linearInterpolation = (x: number, x0: number, y0: number, x1: number, y1: number) => {
		const a = (y1 - y0) / (x1 - x0);
		const b = -a * x0 + y0;
		return a * x + b;
	};

	const findIntervalBorderIndex = (point: any, intervals: any, useRightBorder: boolean) => {
		// If point is beyond given intervals
		if (point < intervals[0]) {
			return 0;
		}
		if (point > intervals[intervals.length - 1]) {
			return intervals.length - 1;
		}
		// If point is inside interval
		// Start searching on a full range of intervals
		let indexOfNumberToCompare;
		let leftBorderIndex = 0;
		let rightBorderIndex = intervals.length - 1;
		// Reduce searching range till it find an interval point belongs to using binary search
		while (rightBorderIndex - leftBorderIndex !== 1) {
			indexOfNumberToCompare = leftBorderIndex + Math.floor((rightBorderIndex - leftBorderIndex) / 2);
			if (point >= intervals[indexOfNumberToCompare]) {
				leftBorderIndex = indexOfNumberToCompare;
			} else {
				rightBorderIndex = indexOfNumberToCompare;
			}
		}
		return useRightBorder ? rightBorderIndex : leftBorderIndex;
	};

	/**
* Evaluates interpolating line/lines at the set of numbers
* or at a single number for the function y=f(x)
*
* @param {Number|Array} pointsToEvaluate     number or set of numbers
*                                            for which polynomial is calculated
* @param {Array} functionValuesX             set of distinct x values
* @param {Array} functionValuesY             set of distinct y=f(x) values
* @returns {Array}
*/
	const evaluateLinear = (pointsToEvaluate: any, functionValuesX: any, functionValuesY: any) => {
		const results: any[] = [];
		const pointsToEvaluateA = makeItArrayIfItsNot(pointsToEvaluate);
		pointsToEvaluateA.forEach((point: number) => {
			let index = findIntervalBorderIndex(point, functionValuesX, false);
			if (index === functionValuesX.length - 1) {
				index -= 1;
			}
			results.push(linearInterpolation(point, functionValuesX?.[index], functionValuesY?.[index],
				functionValuesX[index + 1], functionValuesY[index + 1]));
		});
		return results;
	};

	const ratingColor = getHandlerColor();
	const handlerTransform = `rotate(${isSet(uCredibility)
		? evaluateLinear(uCredibility, [0, 100], [34, 340])[0] : 0} ${width / 2} ${width / 2})`;

	return (
		<div className="flex-row justify-center align-center">
			<svg
				width={width}
				height={height}
				viewBox={`0 0 ${width} ${height}`}
			>
				<g
					x={shiftX + cRadiusReduce}
					y={shiftY + cRadiusReduce}
					transform={`rotate(135, ${half + cRadiusReduce / 2.7}, ${half + height / 10})`}
				>
					<path
						d={describeArc(half - (cRadiusReduce), half - (cRadiusReduce), half - (cRadiusReduce), 0, 270)}
						stroke={isCredo ? credoTrackColor : egoTrackColor}
						strokeWidth={showThickLines ? handlerSize / 2.7 : handlerSize / 3.6}
						fill="none"
						strokeLinecap="round"
					/>
					{getFilledPath()}
				</g>
				<defs>
					<linearGradient id="grad50" x1="0%" y1="10%" x2="80%" y2="20%" gradientTransform="rotate(110)">
						<stop offset="0%" stopColor="rgb(255, 101, 77)" stopOpacity="1" />
						<stop offset="30%" stopColor="rgb(248, 220, 27)" stopOpacity="1" />
						<stop offset="60%" stopColor="rgb(11, 199, 224)" stopOpacity="1" />
					</linearGradient>
					<linearGradient id="grad30" x1="0%" y1="80%" x2="100%" y2="100%" gradientTransform="rotate(90)">
						<stop offset="15%" stopColor="rgb(255, 101, 77)" stopOpacity="1" />
						<stop offset="85%" stopColor="rgb(248, 220, 27)" stopOpacity="1" />
					</linearGradient>
					<linearGradient id="grad0" x1="20%" y1="80%" x2="100%" y2="0%">
						<stop offset="70%" stopColor="rgb(255, 101, 77)" stopOpacity="1" />
					</linearGradient>
				</defs>
				<g
					x="0"
					y="0"
					transform={handlerTransform}
					style={{
						transform: `${[
							{ translateX: (half + shiftX) },
							{ translateY: half + shiftY },
							{ translateX: 0 },
							{ translateX: -(half + shiftX) },
							{ translateY: -(half + shiftY) },
							{ translateX: 0 },
						]}`,
					}}
					origin={`${half + shiftX}, ${half + shiftY}`}
				>
					<line
						x1={half + shiftX + handlerSize - (cRadiusReduce * 4)}
						y1={half + shiftY}
						x2={half + shiftX + handlerSize + 1}
						y2={half + shiftY}
						stroke={backgroundColor || "var(--background-primary)"}
						strokeWidth={showThickLines ? handlerTickness * 2.5 : handlerTickness * 1.8}
					/>
					<polygon
						points={`${half + shiftY},${half + shiftX - handlerTickness / 1.1}
							${half + shiftY},${half + shiftX + handlerTickness / 1.6}
							${half + shiftY + handlerSize},${half + shiftX + handlerTickness / 1.6}
							${half + shiftY + handlerSize},${half + shiftX - handlerTickness / 1.1}`}
						fill={ratingColor}
					/>
					<circle
						cx={half + shiftX + handlerSize - (cRadiusReduce / 4)}
						cy={half + shiftY}
						r={showThickLines ? handlerTickness / 1.3 : handlerTickness / 2.8}
						fill={ratingColor}
					/>
					<circle
						cx={half + shiftX}
						cy={half + shiftY}
						r={showThickLines ? handlerTickness / 1.3 : handlerTickness / 2.8}
						fill={ratingColor}
					/>
				</g>
			</svg>
		</div>
	);
};

UserCredibilityWidget.defaultProps = {
	isCredo: true,
	backgroundColor: null,
};
