import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { classNames } from "../common";

interface OwnProps {
	wrapperClassName?: string;
	skeletonCount?: number;
	showTabs?: boolean;
	noOfTabs?: number;
}

const HomeRequestSkeleton = ({
	wrapperClassName, skeletonCount, showTabs, noOfTabs,
}: OwnProps) => (
	<div
		className={classNames(
			"w-full",
			wrapperClassName,
		)}
	>
		<SkeletonTheme
			baseColor="var(--background-skeleton)"
			highlightColor="var(--hightlight-skeleton)"
		>
			{showTabs
				? (
					<div className="w-full mt-2 mb-4 flex justify-around">
						{Array.from(Array(noOfTabs)).map((tab) => (
							<Skeleton containerClassName="w-1/6" height={20} key={tab} />
						))}
					</div>
				)
				: null }
			{Array.from(Array(skeletonCount).keys()).map((i) => (
				<div
					key={i}
					className="flex flex-row justify-between items-center p-2"
				>
					<div className="flex flex-row items-center w-full">
						<Skeleton width={40} height={40} circle />
						<div className="flex flex-row ml-3 w-full">
							<div className="flex flex-col w-full">
								<Skeleton count={2} containerClassName="w-2/3" height={20} />
								<Skeleton count={1} containerClassName="w-1/4" height={16} />
							</div>
						</div>
					</div>
				</div>
			))}
		</SkeletonTheme>
	</div>
);

export default HomeRequestSkeleton;

HomeRequestSkeleton.defaultProps = {
	wrapperClassName: "",
	skeletonCount: 3,
	showTabs: false,
	noOfTabs: 3,
};
