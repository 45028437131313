import React, {
	useCallback,
	useMemo, useRef, useState,
} from "react";
import { useClickAway } from "@credo/utilities";
import { classNames, SvgIcon } from "../common";
import { CheckIcon, DownBorderIcon } from "../assets/icons";

export interface DropdownItem {
	id: string;
	value: any;
	description: string;
	disabled?: boolean;
	onClick?: (params?: any) => void;
}

interface DropdownProps {
	classNames?: {
		wrapperClassName?: string;
		selectedItemClassName?: string;
		listClassName?: string;
		listItemClassname?: string;
	};
	selectedItemId: string | null;
	data: DropdownItem[];
	messages: {
		selectItem: string;
	};
	disabled?: boolean;
	onActions: (selectedId: string) => void;
	closeOnClickOutside?: boolean;
}

/**
 * 1. Add actions with some types
 * 5. Add option to have multi select
 * 8. Add animations
 * */

export const Dropdown: React.FC<DropdownProps> = (props: DropdownProps) => {
	const {
		classNames: dropdownClassNames = {
			selectedItemClassName: "",
			wrapperClassName: "",
			listClassName: "",
			listItemClassname: "",
		},
		selectedItemId,
		data,
		messages,
		disabled,
		onActions,
		closeOnClickOutside = true,
	} = props;

	const [showDropdown, setShowDropdown] = useState<boolean>(false);
	const listRef = useRef<HTMLDivElement | null>(null);

	const uniqueId = useMemo(() => Math.random(), []);

	const closeDropdown = useCallback(() => {
		if (closeOnClickOutside) {
			setShowDropdown(false);
		}
	}, [closeOnClickOutside]);

	useClickAway(
		listRef,
		closeDropdown,
	);

	const {
		selectedItemClassName,
		wrapperClassName,
		listClassName,
		listItemClassname,
	} = dropdownClassNames;

	const defaultSelectedItem = {
		id: "default",
		value: messages.selectItem,
		description: messages.selectItem,
	};

	const selectedItem: DropdownItem = useMemo(
		() => data.find((item) => item.id === selectedItemId) ?? defaultSelectedItem,
		[data, selectedItemId],
	);

	const onClickSelectedItem = () => {
		setShowDropdown((prevState) => !prevState);
	};

	const renderSelectedItem = useMemo(() => (
		<button
			type="button"
			onClick={onClickSelectedItem}
			className={classNames(
				"flex justify-between items-center text-[18px] outline-none w-full rounded-[18px]",
				"bg-primary",
				selectedItemClassName,
			)}
			disabled={disabled}
		>
			<span className="truncate">
				{selectedItem.description}
			</span>
			<span
				className={classNames(
					"rounded-full bg-dark-main p-0.5 pl-px w-[18px] h-[18px] flex justify-center items-center",
					"ml-2 shrink-0",
				)}
			>
				<SvgIcon icon={DownBorderIcon} width={12} height={12} stroke="white" />
			</span>
		</button>
	), [selectedItem, disabled]);

	const handleSelectItem = useCallback((id: string) => {
		onActions(id);
		closeDropdown();
	}, []);

	// TODO: Try modal popup here if it works better?
	const renderDropdownList = useMemo(() => (
		<div
			ref={listRef}
			className={classNames(
				"flex flex-col absolute bg-primary drop-shadow-lg rounded-[18px] z-index top-0 left-0",
				listClassName,
			)}
			style={{
				zIndex: 1,
			}}
		>
			{data.map((item, index) => {
				const handleClick = () => {
					if (item.onClick) {
						item.onClick();
					} else {
						handleSelectItem(item.id);
					}
				};
				return (
					<button
						type="button"
						className={classNames(
							"flex justify-between items-center text-lg outline-none bg-transparent px-1.5 pt-1.5",
							index === data.length - 1 ? "pb-1.5" : "",
							listItemClassname,
						)}
						onClick={handleClick}
					>
						<span
							className={classNames(
								"truncate",
								item.onClick ? "text-primary font-bold border-b-primary border-b" : "",
								selectedItemId === item.id ? "font-bold" : "",
							)}
						>
							{item.description}
						</span>
						{selectedItemId === item.id && (
							<span
								className={classNames(
									"rounded-full bg-dark-main p-0.5 w-[18px] h-[18px] flex justify-center items-center",
									"ml-2 shrink-0",
								)}
							>
								<SvgIcon icon={CheckIcon} width={12} height={12} stroke="white" />
							</span>
						)}
					</button>
				);
			})}
		</div>
	), [selectedItemId, data]);

	return (
		<div
			id={`dropdown-${uniqueId}`}
			className={classNames(
				"relative",
				wrapperClassName,
			)}
		>
			{renderSelectedItem}
			{showDropdown && renderDropdownList}
		</div>
	);
};
