import * as React from "react"
import {SVGAttributes} from "react";

const SvgComponent = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--gray-dark)" } = props;
	return (
		<svg width={16} height={20} viewBox="0 0 16 20" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g stroke={stroke} fill="none" fillRule="evenodd">
				<path strokeLinecap="round" d="M.5 1v18" />
				<path d="M.5 3h14l-3 4 3 4H.5" />
			</g>
		</svg>
	)
}

export default SvgComponent
