import React, { useMemo } from "react";
import omit from "lodash/omit";
import { classNames } from "../common";
import { Spinner } from "../spinner";

export interface VoltsWalletButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
	isLoading?: boolean;
}

export const VoltsWalletButton: React.FC<VoltsWalletButtonProps> = (props: VoltsWalletButtonProps) => {
	const {
		children,
		className,
		isLoading,
	} = props;

	const omittedProps = omit(props, ["isLoading", "className"]);

	const renderContent = useMemo(() => {
		if (isLoading) {
			return (
				<div className="flex justify-center items-center w-full">
					<Spinner
						height="24px"
						width="24px"
						leaf_fill="var(--background)"
					/>
				</div>
			);
		}
		return children;
	}, [children, isLoading]);

	return (
		<button
			type="button"
			className={classNames(
				"volts-wallet__button bg-primary border-primary",
				className,
			)}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...omittedProps}
		>
			{renderContent}
		</button>
	);
};

VoltsWalletButton.defaultProps = {
	isLoading: false,
};
