import React, { useMemo } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useWindowSize } from "@credo/utilities";
import { classNames } from "../common";

interface TopFeedSkeletonProps {
	wrapperClasses?: string;
	noOfItems?: number;
	showTabs?: boolean;
}

const TopFeedSkeleton = (props: TopFeedSkeletonProps) => {
	const { wrapperClasses, noOfItems, showTabs = false } = props;
	const { width } = useWindowSize();
	const isMobile = useMemo(() => width < 540, [width]);
	return (
		<div
			className={classNames(
				"w-full z-0",
				wrapperClasses,
			)}
		>
			<SkeletonTheme
				baseColor="var(--background-skeleton)"
				highlightColor="var(--hightlight-skeleton)"
			>
				{showTabs
					? (
						<div className="w-full my-2 flex justify-around">
							<Skeleton containerClassName="w-1/6" height={20} />
							<Skeleton containerClassName="w-1/6" height={20} />
							<Skeleton containerClassName="w-1/6" height={20} />
						</div>
					)
					: null}
				{Array.from(Array(noOfItems).keys()).map((i) => (
					<div key={i} className="w-full flex flex-col">
						<div className="w-full flex flex-row mt-5 px-5">
							<div
								key={i}
								className="w-7/12 flex flex-col items-center"
							>
								<div className="w-full flex flex-row justify-between">
									<div className="w-full flex flex-row items-start">
										<Skeleton width={40} height={40} circle />
										<div className="w-full flex flex-col ml-2">
											<Skeleton containerClassName="w-2/3 sm:w-3/5" height={20} />
											<Skeleton containerClassName="w-2/3 sm:w-3/5" height={15} />
										</div>
									</div>
									<Skeleton containerClassName="w-1/6 sm:w-1/3" height={20} />
								</div>
								<div className="w-full flex flex-row items-start pt-2">
									<Skeleton borderRadius={15} containerClassName="w-full" height={isMobile ? 100 : 150} />
								</div>
								<div
									className="w-full mx-10 flex flex-row items-center justify-between py-2"
								>
									<Skeleton borderRadius={15} containerClassName="w-1/4" height={20} />
									<Skeleton borderRadius={15} containerClassName="w-1/4" height={20} />
									<Skeleton borderRadius={15} containerClassName="w-1/4" height={20} />
								</div>
							</div>
							<div className="flex flex-col w-5/12 ml-4">
								<Skeleton borderRadius={15} containerClassName="w-full" height={isMobile ? 156 : 206} />
							</div>
						</div>
						<div
							className="w-full flex flex-row items-center justify-between pb-2 px-5"
						>
							<div className="flex flex-row w-full">
								<Skeleton borderRadius={15} containerClassName="w-4/12 mr-2.5" height={28} />
								<Skeleton borderRadius={15} containerClassName="w-4/12 rounded-15" height={28} />
							</div>
							<div className="flex flex-row w-full justify-end">
								<Skeleton borderRadius={15} containerClassName="w-4/12 rounded-15 mr-2.5" height={28} />
								<Skeleton borderRadius={15} containerClassName="w-4/12 rounded-15" height={28} />
							</div>
						</div>
						{i < ((noOfItems ?? 0) - 1) ? (
							<div
								className="w-full flex flex-row items-start h-full bg-background-tertiary"
								style={{ height: 10 }}
							/>
						) : null}
					</div>
				))}
			</SkeletonTheme>
		</div>
	);
};

TopFeedSkeleton.defaultProps = {
	wrapperClasses: "",
	noOfItems: 5,
	showTabs: false,
};

export default TopFeedSkeleton;
