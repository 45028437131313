import React, { SVGAttributes } from "react";

// eslint-disable-next-line import/prefer-default-export
const StreamPrivacySetttings = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--gray-dark)",
	} = props;
	return (
		<svg
			width="20px"
			height="20px"
			viewBox="0 0 20 20"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<g id="Streams---CT---190723" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-icons" transform="translate(-325.000000, -621.000000)" stroke={stroke}>
					<g id="streamPrivacySetttings" transform="translate(325.000000, 621.000000)">
						<path
							d="M9.5381,18.5586 C17.2791,15.6796 18.5381,5.0436 18.5381,5.0436 L9.5381,0.5586 L0.5381,5.0436 C0.5381,5.0436 1.7971,15.6796 9.5381,18.5586 Z"
							id="Stroke-1"
						/>
						<path
							d="M11.7803,13.5801 L7.2963,13.5801 C6.6003,13.5801 6.0383,13.0171 6.0383,12.3221 L6.0383,8.8381 C6.0383,8.1431 6.6003,7.5801 7.2963,7.5801 L11.7803,7.5801 C12.4743,7.5801 13.0383,8.1431 13.0383,8.8381 L13.0383,12.3221 C13.0383,13.0171 12.4743,13.5801 11.7803,13.5801 Z"
							id="Stroke-3"
						/>
						<path
							d="M12.0381,8.0469 L12.0381,6.6559 C12.0381,5.2149 10.9191,4.0469 9.5381,4.0469 C8.1571,4.0469 7.0381,5.2149 7.0381,6.6559 L7.0381,8.0469"
							id="Stroke-5"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default StreamPrivacySetttings;
