import React, { SVGAttributes } from "react";

export interface SwitchSVGProps {
	svgProps?: SVGAttributes<SVGElement>;
	containerStyle?: React.CSSProperties;
	pathStyle?: React.CSSProperties;
}

/**
 * Renders a switch (up/down) icon
 * TODO convert to use tailwind theme colors
 * @param props
 * @constructor
 */
const SwitchSVG = ({
	svgProps = {
		width: "15px",
		height: "15px",
		viewBox: "0 0 15 15"
	},
	containerStyle = { fill: "#171C2D" },
	pathStyle = { fill: "#0BE0D8" }
}: SwitchSVGProps) => {
	return (
		<svg {...svgProps} version="1.1" xmlns="http://www.w3.org/2000/svg"
				 xmlnsXlink="http://www.w3.org/1999/xlink">
			<g>
				<rect style={containerStyle} x="0" y="0" width="15" height="15" rx="3"></rect>
				<g id="swap_vert_FILL1_wght400_GRAD0_opsz48" transform="translate(2.000000, 1.000000)">
					<path style={pathStyle}
								d="M2.784375,7.00375 L2.784375,1.86875 L0.721875,3.81875 L0,3.13625 L3.3171875,0 L6.634375,3.13625 L5.9125,3.81875 L3.815625,1.8525 L3.815625,7.00375 L2.784375,7.00375 Z M7.6828125,13 L4.365625,9.8475 L5.0875,9.18125 L7.15,11.13125 L7.15,5.99625 L8.18125,5.99625 L8.18125,11.1475 L10.278125,9.18125 L11,9.86375 L7.6828125,13 Z"
								id="Shape"></path>
				</g>
			</g>
		</svg>
	);
};

export default SwitchSVG;
