import * as React from "react"
import {SVGAttributes} from "react";

const SvgComponent = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--accent)" } = props;
	return (
		<svg width={84} height={102} viewBox="0 0 84 102" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g stroke={stroke} fill="none" fillRule="evenodd">
				<path d="M60.665 56.06h-8.503c-2.398 0-4.736-1.261-4.909-3.711l-.02-.29.271-1.89a24.927 24.927 0 0 0-2.053-14.05l-.475-1.036c-.643-1.403-1.749-2.59-3.188-3.18a5.9 5.9 0 0 0-5.176.328c-1.973 1.113-3.047 3.322-2.915 5.565l.357 6.072a4.848 4.848 0 0 1-.649 2.705l-9.213 15.94c-5.387 6.766-14.505 5.552-18.48 5.58-2.242 0-4.075 1.814-4.075 4.03v18.85c0 2.218 1.833 4.032 4.074 4.032h15.931c3.66 0 7.223.736 10.584 2.189l3.113 1.339a27.661 27.661 0 0 0 10.935 2.25h11.203" />
				<path d="M77.297 67.66H61.254a5.8 5.8 0 0 1-5.799-5.8 5.799 5.799 0 0 1 5.8-5.8h16.042a5.8 5.8 0 0 1 0 11.599Z" />
				<path d="M76.18 79.258H60.137a5.8 5.8 0 0 1-5.798-5.8 5.799 5.799 0 0 1 5.799-5.799H76.18a5.8 5.8 0 0 1 0 11.599ZM71.744 100.784H57.421a5.178 5.178 0 0 1 0-10.356h14.323a5.178 5.178 0 1 1 0 10.356ZM73.413 90.428H57.964a5.584 5.584 0 1 1 0-11.17h15.45a5.585 5.585 0 0 1 0 11.17Z" />
				<path d="M52.379 84.843s-6.208-8.357-19.861-5.585M38.86 21.616V7.513M56.104 38.86h14.103M7.513 38.86h14.103M51.053 26.667l9.972-9.972M16.695 16.695l9.972 9.972M42.367 4.007a3.507 3.507 0 1 1-7.014 0 3.507 3.507 0 0 1 7.014 0ZM7.513 38.86a3.507 3.507 0 1 1-7.013 0 3.507 3.507 0 0 1 7.013 0ZM77.22 38.86a3.507 3.507 0 1 1-7.013 0 3.507 3.507 0 0 1 7.013 0ZM65.985 16.695a3.507 3.507 0 1 1-4.96-4.961 3.507 3.507 0 0 1 4.96 4.96ZM16.695 16.695a3.507 3.507 0 1 1-4.96-4.961 3.507 3.507 0 0 1 4.96 4.96Z" />
			</g>
		</svg>
	)
}

export default SvgComponent
