import { useEffect } from "react";
import useRafState from "./useRafState";

/**
 * Will return the visual height and width of the screen if there is any
 * overlay by system. Mainly will be used for determining the remaining
 * height of the window when a soft keyboard is open on mobile.
 *
 * @returns {width, height} - Width of the visual viewport and height of
 * the visualViewport.
 * */
const useVisualWindowSize = () => {
	const [state, setState] = useRafState<{ width: number; height: number }>({
		// @ts-ignore
		width: window?.visualViewport.width || 0,
		// @ts-ignore
		height: window?.visualViewport.height || 0,
	});

	// eslint-disable-next-line consistent-return
	useEffect(() => {
		if (window) {
			const handler = () => {
				setState({
					// @ts-ignore
					width: window.visualViewport.width,
					// @ts-ignore
					height: window.visualViewport.height,
				});
			};
			// @ts-ignore
			window.visualViewport.addEventListener("resize", handler);

			return () => {
				// @ts-ignore
				window.visualViewport.removeEventListener("resize", handler);
			};
		}
	}, []);

	return state;
};

export default useVisualWindowSize;
