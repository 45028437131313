import axios, {
	AxiosError,
	AxiosPromise,
	AxiosRequestConfig,
	AxiosRequestHeaders,
	AxiosResponse,
} from "axios";
import { v5 as uuidv5, v4 as uuidv4 } from "uuid";
import { logout } from "../modules/login/LoginRequests";
import { SID } from "../routes/constants";
import { dbg, mydbg } from "../utils/utils";
import { deleteAllCookies, getCookie, setCookie } from "../utils/cookieUtils";
import {
	applogicUrl, authUrl, successRETCD, ERROR_CODE, RE_AUTH_CODE,
} from "./config";
import { signOutAccount } from "../utils/Firebase";

export type HTTPMethod = "get" | "post" | "delete" | "put" | "patch";

export interface JsonBody {
	[key: string]: any;
}

export interface RequestOptions {
	apiVersion?: "applogic" | "auth";
	withHeaders?: boolean;
	headers?: AxiosRequestHeaders;
}

export interface Request {
	method: HTTPMethod;
	url: string;
	body?: JsonBody;
}

export interface ApiVariety {
	applogic: string;
	auth: string;
}

const getAPI = () => ({
	auth: authUrl(),
	applogic: applogicUrl(),
});

const buildRequest = (
	request: Request,
	configData?: RequestOptions,
	tokens?: any,
) => {
	const { body, method, url } = request;
	const api = getAPI();

	const token = tokens ? JSON.parse(tokens) : null;
	const authHeader = token
		? {
			Authorization: `Bearer ${token?.access}`,
		}
		: null;

	const contentType = body instanceof FormData
		? "multipart/form-data; boundary=500"
		: "application/json";

	const defaultHeaders = {
		Accept: "application/json",
		"content-type": contentType,
	};

	const apiUrl = api[configData?.apiVersion || "auth"];

	const transformRequestConfig: AxiosRequestConfig = body instanceof FormData
		? {
			transformRequest: (data) => data
			,
		}
		: {};

	const sid = getCookie(SID) && getCookie(SID) !== "undefined" ? getCookie(SID) : null;

	const updatedBody = {
		...body,
		sid, // session id
		replyAddress: uuidv5(uuidv4(), uuidv5.URL), // Added unique request id for each request
	};

	const requestConfig: AxiosRequestConfig = {
		baseURL: apiUrl,
		data: updatedBody,
		headers: { ...configData?.headers, ...defaultHeaders, ...authHeader },
		method,
		url,
	};
	if (method === "get") {
		requestConfig.params = updatedBody;
	}

	return {
		...requestConfig,
		...transformRequestConfig,
	};
};

export const defaultResponse: Partial<AxiosError["response"]> = {
	status: 500,
	data: {
		error: "Server error",
	},
};

export const formatError = (responseError: AxiosError) => {
	const response = responseError?.response?.data
		|| responseError.response
		|| responseError
		|| defaultResponse;
	const errors = response || [];

	return {
		code: responseError?.response?.status || (response as Response)?.status,
		message: (errors as Error)?.message || errors,
		data: responseError?.response?.data,
	};
};

const logoutUser = async () => {
	await logout();
	deleteAllCookies();
	localStorage.removeItem("roles");
	localStorage.removeItem("userDetails");
	localStorage.removeItem("timestamp");
	localStorage.clear();
	signOutAccount();
	window.location.href = `${window.location.origin}/`;
};

export const makeRequest = async (
	request: Request,
	configData?: RequestOptions,
) => {
	const requestConfig = buildRequest(request, configData);

	return new Promise((resolve, reject) => {
		const axiosRequest: AxiosPromise = axios(requestConfig);
		axiosRequest
			.then((response: AxiosResponse) => {
				if (dbg) mydbg(`Got the response for url ${request.url}`, response);
				if (configData?.withHeaders) {
					resolve(response);
				} else {
					resolve(response.data);
				}
				if ((!getCookie(SID) || getCookie(SID) === "undefined") && response?.data?.retcd === successRETCD && response?.data?.sid) {
					setCookie(SID, response?.data?.sid);
				}
			})
			.catch((error: AxiosError) => {
				const errorData = error as any;
				if (dbg) mydbg(`Got the error for url ${request.url}`, error);
				// Handling for re-auth response when session is invalid
				if (errorData?.response?.data?.info === RE_AUTH_CODE && errorData?.response?.data?.retcd === ERROR_CODE) {
					logoutUser();
				}
				reject(formatError(error));
			});
	});
};
