import React, { SVGAttributes } from "react";

// eslint-disable-next-line import/prefer-default-export
const ReportComment = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--primary)" } = props;
	return (
		<svg
			width="102px"
			height="101px"
			viewBox="0 0 102 101"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<title>Report Comment</title>
			<g id="Settings---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Settings---Report-comment" transform="translate(-137.000000, -125.000000)" stroke={stroke}>
					<g id="AFSreportComment_ego" transform="translate(137.000000, 125.000000)">
						<path d="M0.5,32.866 L0.5,59.924 C0.5,60.991 1.365,61.857 2.433,61.857 L33.455,61.857 C34.522,61.857 35.388,60.991 35.388,59.924 L35.388,50.867 L35.438,50.84 L43.02,46.395 L35.388,41.927 L35.388,32.866 C35.388,31.799 34.522,30.933 33.455,30.933 L2.433,30.933 C1.365,30.933 0.5,31.799 0.5,32.866 Z" id="Stroke-1" />
						<path d="M20.9874,46.3949 C20.9874,47.9959 19.6894,49.2939 18.0884,49.2939 C16.4874,49.2939 15.1894,47.9959 15.1894,46.3949 C15.1894,44.7939 16.4874,43.4959 18.0884,43.4959 C19.6894,43.4959 20.9874,44.7939 20.9874,46.3949 Z" id="Stroke-3" />
						<path d="M11.3232,46.3949 C11.3232,47.9959 10.0252,49.2939 8.4242,49.2939 C6.8232,49.2939 5.5252,47.9959 5.5252,46.3949 C5.5252,44.7939 6.8232,43.4959 8.4242,43.4959 C10.0252,43.4959 11.3232,44.7939 11.3232,46.3949 Z" id="Stroke-5" />
						<path d="M30.6515,46.3949 C30.6515,47.9959 29.3535,49.2939 27.7525,49.2939 C26.1515,49.2939 24.8535,47.9959 24.8535,46.3949 C24.8535,44.7939 26.1515,43.4959 27.7525,43.4959 C29.3535,43.4959 30.6515,44.7939 30.6515,46.3949 Z" id="Stroke-7" />
						<path d="M73.2349,77.4182 L63.8619,81.6602 L66.3879,71.6872 L92.9019,40.0082 C94.4849,38.1172 97.3009,37.8672 99.1919,39.4502 L99.1919,39.4502 C101.0829,41.0332 101.3319,43.8482 99.7499,45.7392 L73.2349,77.4182 Z" id="Stroke-9" />
						<line x1="66.3874" y1="71.6869" x2="73.2344" y2="77.4179" id="Stroke-11" />
						<line x1="91.1918" y1="42.0517" x2="98.0388" y2="47.7827" id="Stroke-13" />
						<line x1="92.1949" y1="47.8095" x2="71.8519" y2="72.1145" id="Stroke-15" />
						<polyline id="Stroke-17" points="81.0752 54.1386 81.0752 21.5696 25.8852 21.5696 25.8852 30.9336" />
						<polyline id="Stroke-19" points="25.8853 61.8566 25.8853 100.4996 81.0753 100.4996 81.0753 68.0506" />
						<polyline id="Stroke-21" points="70.8716 13.6566 91.2786 13.6566 84.4996 7.0786 91.2786 0.4996 70.8716 0.4996 70.8716 28.0846" />
						<line x1="43.0199" y1="46.3949" x2="71.0749" y2="46.3949" id="Stroke-23" />
						<line x1="34.854" y1="66.3962" x2="71.075" y2="66.3962" id="Stroke-25" />
						<line x1="34.854" y1="56.3956" x2="71.075" y2="56.3956" id="Stroke-27" />
						<line x1="34.854" y1="76.3968" x2="65.195" y2="76.3968" id="Stroke-29" />
						<line x1="34.854" y1="86.3975" x2="71.075" y2="86.3975" id="Stroke-31" />
					</g>
				</g>
			</g>
		</svg>
	);
};

export default ReportComment;
