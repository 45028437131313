import React, { SVGAttributes } from "react";

// eslint-disable-next-line import/prefer-default-export
const ProfileEdit = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--primary)" } = props;
	return (
		<svg
			width="23px"
			height="18px"
			viewBox="0 0 23 18"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<g id="Profile---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-icons" transform="translate(-102.000000, -1239.000000)" stroke={stroke}>
					<g id="profileEdit_ego" transform="translate(102.000000, 1239.000000)">
						<path
							d="M12.5,6.4999 C12.5,9.8139 9.814,12.4999 6.5,12.4999 C3.186,12.4999 0.5,9.8139 0.5,6.4999 C0.5,3.1859 3.186,0.4999 6.5,0.4999 C9.814,0.4999 12.5,3.1859 12.5,6.4999 Z"
							id="Stroke-1"
						/>
						<path
							d="M6.5023,7.3432 C7.7403,7.3432 8.7483,6.0242 8.7483,4.7862 C8.7483,3.5472 7.7403,2.5402 6.5023,2.5402 C5.2643,2.5402 4.2573,3.5472 4.2573,4.7862 C4.2573,6.0242 5.2643,7.3432 6.5023,7.3432 Z"
							id="Stroke-3"
						/>
						<path
							d="M10.3739,11.0777 C10.0209,9.4247 9.1959,8.6817 8.6809,8.3757 L8.5329,8.2867 L8.3839,8.3857 L6.5019,9.7967 L4.6209,8.3857 L4.4719,8.2867 L4.3229,8.3757 C3.8089,8.6817 2.9829,9.4247 2.6299,11.0807"
							id="Stroke-5"
						/>
						<line x1="17.5285" y1="6.1488" x2="9.7655" y2="13.9118" id="Stroke-7" />
						<line x1="21.0792" y1="6.134" x2="17.5442" y2="2.598" id="Stroke-9" />
						<path
							d="M7.7794,12.3625 C7.7244,12.4175 7.6824,12.4845 7.6594,12.5565 L6.2384,16.8065 C6.2034,16.9095 6.2034,17.0215 6.2374,17.1235 C6.2804,17.2505 6.3694,17.3525 6.4884,17.4125 C6.6094,17.4725 6.7454,17.4805 6.8704,17.4395 L11.1204,16.0175 C11.1934,15.9935 11.2584,15.9535 11.3144,15.8975 L21.0794,6.1335 C21.5514,5.6615 21.8114,5.0335 21.8114,4.3655 C21.8114,3.6985 21.5514,3.0705 21.0794,2.5975 C20.6074,2.1255 19.9794,1.8655 19.3114,1.8655 C18.6434,1.8655 18.0154,2.1255 17.5444,2.5975 L12.5004,7.6415"
							id="Stroke-11"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default ProfileEdit;
