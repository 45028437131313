import React, { useEffect, useState } from "react";
import { createSearchParams } from "react-router-dom";
import moment from "moment";
import { strings } from "../../../i18n/config";
import Accordion from "../../../gen-comp/Accordion";
import Table from "../../../gen-comp/table";
import {
	UserModesOptions,
	UserReportedTableHeaders,
} from "../constants";
import { AUTH_URL, USER_DETAILS_PAGE } from "../../../routes/constants";
import { checkForRCIMsg, DATE_FORMAT } from "../../../utils/utils";
import {
	DropdownItem, Modes, ReportingUserDetails, UserDetails,
} from "../../../utils/types";
import Filter from "../../../gen-comp/Filter";

interface UseReportedProps {
	data: ReportingUserDetails[] | [];
	userData: UserDetails;
	mode: string | null;
}

const UserReportedList: React.FC<UseReportedProps> = (props: UseReportedProps) => {
	const {
		data,
		userData,
		mode,
	} = props;

	const [reportedData, setReportedData] = useState<ReportingUserDetails[]>([]);
	const [userMode, setUserMode] = useState<DropdownItem[]>(UserModesOptions);
	const [filteredData, setFilteredData] = useState<ReportingUserDetails[]>([]);

	const navigateToUserDetails = (item: any) => {
		window.open(`${AUTH_URL}${USER_DETAILS_PAGE}?${createSearchParams({
			userId: item?.reported_user_id,
			isNewTab: "y",
		})}`, "_blank");
	};

	useEffect(() => {
		if (data && data.length > 0) {
			const items: any[] = [];
			data?.forEach((element: any) => {
				items.push({
					reported_user_id: element.reported_user_id,
					firstName: element.firstName ? element.firstName : "--",
					lastName: element.lastName ? element.lastName : "--",
					username: element.username ? element.username : "--",
					reportMessage: checkForRCIMsg(element.reportMessage),
					reportReason: checkForRCIMsg(element.reportReason),
					mode: userData.eprof_id === element.src_prof_id ? Modes.EGO : Modes.CREDO,
					src_mode: element.mode,
					reported_timestamp: element?.creat_ts
						? moment(new Date(element.creat_ts)).format(DATE_FORMAT)
						: strings("UserSearchPage.not_available"),
				});
			});
			setReportedData(items);
			setFilteredData(items);
		}
	}, [data]);

	const applyUserModeFilter = (items: DropdownItem[]) => {
		setUserMode(items);
	};

	useEffect(() => {
		if (reportedData.length > 0) {
			const tempRepoData = [...reportedData];
			const tempDataWithFilter = tempRepoData.filter((item) => {
				const modeValues = userMode.map((e) => e.value).join(",");
				return modeValues.includes(item.mode ?? "");
			});
			setFilteredData(tempDataWithFilter);
		}
	}, [userMode, mode]);

	useEffect(() => {
		if (mode) {
			setUserMode([...UserModesOptions.filter((e) => e.value === mode)]);
		}
	}, [reportedData, mode]);

	return (
		<div
			className="bg-white p-3 rounded-lg mx-5 mb-4 min-h-fit max-h-full max-w-[calc(100%-2.5rem)]"
			data-testid="user-reported-accordion"
		>
			<Accordion
				header={(
					<h5 className="mb-0">
						{strings("UserReportedList.reported_info")}
					</h5>
				)}
				defaultOpen={data && data.length > 0}
				content={(
					<div className="flex flex-wrap pb-4" data-testid="user-details__reported-users__wrapper">
						<div className="grid grid-cols-1 xs:grid-cols-2-max-20 sm:grid-cols-3-max-20 gap-2 lg:gap-4 mb-4">
							<Filter
								id="user-mode-filter"
								data={UserModesOptions}
								filterName={strings("UserReportedList.reported_mode")}
								applyFilter={applyUserModeFilter}
								selectedData={userMode}
								showTitle={false}
							/>
						</div>
						<Table
							header={UserReportedTableHeaders}
							data={filteredData}
							onItemClick={navigateToUserDetails}
							showHeader
							showSortIcons
							isLoading={false}
						/>
					</div>
				)}
			/>
		</div>
	);
};

export default UserReportedList;
