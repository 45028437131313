import React, { useState } from "react";
import { BottomSheet } from "../../bottom-sheet";
import { CreedSelectorContent, CreedSelectorContentProps } from "./creed-selector-content";
import { SvgRatingSelectorProps } from "../svg-rating-selector";

interface CreedSelectorSheetProps extends CreedSelectorContentProps, Omit<SvgRatingSelectorProps, "size"> {
}

// eslint-disable-next-line import/prefer-default-export
export const CreedSelectorSheet: React.FC<CreedSelectorSheetProps> = (props: CreedSelectorSheetProps) => {
	const {
		tag,
		messages,
		rating,
		onCancel,
		onSelected,
		onRatingHover,
		anchor,
		egoInfoProps,
		boostTrayProps,
		tagDataProvider,
		defaultTab,
		closeWebModal,
	} = props;

	const [isOpen, setIsOpen] = useState<boolean>(true);

	const handleClose = () => {
		setIsOpen(false);
		onCancel();
	};

	return (
		<BottomSheet
			isOpen={isOpen}
			onClose={handleClose}
		>
			<div
				className="py-2 pl-2 md:py-6 w-screen md:pl-6"
			>
				<CreedSelectorContent
					tag={tag}
					messages={messages}
					rating={rating ?? 0}
					onCancel={onCancel}
					onSelected={onSelected}
					onRatingHover={onRatingHover}
					anchor={anchor}
					showEgoModal={false}
					egoInfoProps={egoInfoProps}
					boostTrayProps={boostTrayProps}
					tagDataProvider={tagDataProvider}
					defaultTab={defaultTab}
					closeWebModal={closeWebModal}
				/>
			</div>
		</BottomSheet>
	);
};
