import { SEARCH } from "../api/requestsUrl";
import getWhereClause, { GetWhereClauseVariables } from "../utils/getWhereClause";
import { YesNoOptions } from "../utils/types";
import { PAGINATION_LIMIT } from "../utils/constants";
import { useQuery } from "./graphql/useQuery";

interface Options {
	entity: string;
	page?: number;
	useSearchApi?: boolean;
	formatDataKey?: string,
}

interface OptionalSearchApi {
	gqlQuery: string;
	variablesForSearchApi: GetWhereClauseVariables;
	options: Options;
}

const useOptionalSearchApi = (
	gqlQuery: OptionalSearchApi["gqlQuery"],
	variablesForSearchApi: OptionalSearchApi["variablesForSearchApi"],
	options: OptionalSearchApi["options"],
): any => {
	const {
		entity, page, useSearchApi, formatDataKey,
	} = options;
	const whereStatement = JSON.stringify(getWhereClause(variablesForSearchApi));

	const [getSearchApiData, { data: searchApiData, loading: searchApiLoading }] = useQuery("", {
		notQuery: true,
		url: `${SEARCH}`,
		body: {
			fetchSize: PAGINATION_LIMIT,
			entity,
			page,
			orderBy: YesNoOptions.YES,
			where: whereStatement,
		},
		formatDataKey: "n",
		avoidUpdateWithQuery: true,
	});

	const [getCommunitiesUsingQuery, { data, loading }] = useQuery<Array<Object>>(gqlQuery, {
		formatDataKey: formatDataKey || entity.toLowerCase(),
		avoidUpdateWithQuery: true,
	});

	const executeRequest = () => {
		if (useSearchApi && whereStatement.length > 0 && whereStatement !== "[]") {
			getSearchApiData();
		} else {
			getCommunitiesUsingQuery();
		}
	};
	return [executeRequest, {
		data: useSearchApi && whereStatement.length > 0 && whereStatement !== "[]" ? searchApiData : data,
		loading: useSearchApi && whereStatement.length > 0 && whereStatement !== "[]" ? searchApiLoading : loading,
	}];
};

export default useOptionalSearchApi;
