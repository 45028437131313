import React, {
	cloneElement, useEffect, useRef, useState,
} from "react";
import {
	Placement,
	offset,
	flip,
	shift,
	autoUpdate,
	useFloating,
	useInteractions,
	useHover,
	useClick,
	arrow,
	useRole,
	// useDismiss,
} from "@floating-ui/react-dom-interactions";
import { classNames, Portal } from "../common";

interface Props {
	/**
	 * Required Content which needs to be shown inside the tooltip.
	 * */
	// eslint-disable-next-line no-undef
	content: JSX.Element;
	/**
	 * Set the position where the tooltip must be displayed.
	 * @default "bottom"
	 * */
	placement?: Placement;
	/**
	 * Add the element on which when hovered the tooltip must be displayed.
	 * */
	// eslint-disable-next-line no-undef
	children: JSX.Element;
	/**
	 * Set number in ms to delay the displaying the tooltip on hover.
	 * @default 0
	 * */
	delay?: number;
	/**
	 * Set the action on which tooltip will display.
	 * @default "hover"
	 * */
	toolTipAction?: "click" | "hover";
	/**
	 * Set the visiblity of the tooltip.
	 * @default false
	 * */
	visible?: boolean;
	/**
	 * Add an arrow on the tooltip.
	 * @default true
	 * */
	showArrow?: boolean;
}

/**
 * Displays tooltip when hovered on the element which is wrapped by Tooltip.
 * Wrap this component on which we need the tooltip shown on hover.
 * */
const Tooltip = ({
	children,
	content,
	placement,
	delay = 0,
	toolTipAction,
	visible = false,
	showArrow = true,
}: Props) => {
	const [open, setOpen] = useState(visible);
	const arrowEl = useRef(null);
	const {
		x, y, reference, floating, strategy, context, middlewareData, placement: floatingPlacement,
	} = useFloating({
		placement,
		open,
		onOpenChange: (value: boolean) => {
			if (!visible) {
				setOpen(visible);
			} else {
				setOpen(value);
			}
		},
		middleware: [offset(6), flip(), shift({ padding: 8 }), arrow({ element: arrowEl, padding: 8 })],
		whileElementsMounted: autoUpdate,
	});

	const getTooltipAction = () => {
		let actionA = [];
		switch (toolTipAction) {
			case "click":
				actionA = [
					useClick(context),
					useRole(context),
					// Commented for credo graph and widget modals
					// useDismiss(context)
				];
				break;
			default:
				actionA.push(useHover(context, { delay: { open: delay, close: 0 } }));
		}
		return actionA;
	};

	const { getReferenceProps, getFloatingProps } = useInteractions(getTooltipAction());

	useEffect(() => {
		setOpen(visible);
	}, [visible]);

	// @ts-ignore
	const staticSide = {
		top: "bottom",
		right: "left",
		bottom: "top",
		left: "right",
	}[floatingPlacement.split("-")[0]];

	return (
		<React.Fragment>
			{cloneElement(
				children,
				getReferenceProps({ ref: reference, ...children.props }),
			)}
			{open && (
				<div
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...getFloatingProps({
						ref: floating,
						// className: classNames("z-50 w-80 xs:w-max xs:max-w-xs p-3 whitespace-pre-wrap",
						// 	"bg-background-primary rounded-lg shadow-lg text-white text-sm",
						// 	x ? `max-w-[calc(100vw-${2 * x}px)]` : ""),
						style: {
							position: strategy,
							top: y ?? 0,
							left: x ?? 0,
							zIndex: 203, // greater then header z-index i.e 202
						},
					})}
				>
					{content}
					{showArrow
						&& (
							<div
								ref={arrowEl}
								className={classNames(
									"z-0 absolute w-2 h-2",
									"bg-background-primary",
									"arrow-rotate",
								)}
								style={{
									top: `${middlewareData.arrow?.y}px`,
									left: `${middlewareData.arrow?.x}px`,
									// @ts-ignore
									[staticSide]: "-4px",
								}}
							/>
						)}
					<Portal
						cssText="z-index: 49;"
					>
						<div
							className="fixed top-0 left-0 w-screen h-screen z-[49]"
						/>
					</Portal>
				</div>
			)}
		</React.Fragment>
	);
};

Tooltip.defaultProps = {
	placement: "bottom",
	delay: 0,
	toolTipAction: "hover",
	visible: false,
	showArrow: true,
};

export default Tooltip;
