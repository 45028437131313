import { DropdownItem, SearchBarElementInterface } from "./types";

interface ORStatement {
	OR: {
		[key: string]: string | number;
	}[]
}

/**
 * Types must be changed as per required, must make sure nothing else breaks.
 * */
const getSearchQuery = (
	searchData: SearchBarElementInterface[],
	additionalQuery?: ORStatement["OR"],
): { query: string, isQueryPresent: boolean } => {
	// Creating an array of OR's for AND clause
	const singleANDClause = searchData.map((singleSearchBarElement) => {
		/**
		 * Creating a single OR clause object of added fields
		 * */
		const singleORClause = singleSearchBarElement.selectedValueData
			.reduce((newItem, currentItem: DropdownItem) => ({
				...newItem,
				[`${currentItem.value}_contains`]: currentItem?.type === "number"
					? singleSearchBarElement.selectedSearchValue : singleSearchBarElement.selectedSearchValue.toLowerCase(),
			}), {});

		/**
		 * Keeping only those OR clause which have search input value since it gives
		 * error from server when we send empty fields.
		 * */
		if (singleSearchBarElement.selectedSearchValue && singleSearchBarElement.selectedValueData.length > 0) {
			const ORFilter = Object.entries(singleORClause).map((obj) => ({ [obj[0]]: obj[1] }));
			return {
				OR: ORFilter,
			};
		}
		return null;
	})
		/**
		 * Making sure all the elements are there in the array since it gives error
		 * when sent an empty array or null to the server.
		 * */
		.filter((item) => item);

	let finalQueryClause = singleANDClause;

	// Add additional filter query which can be added with the rest of the filter
	if (additionalQuery) {
		finalQueryClause = [singleANDClause, { OR: additionalQuery }].flat();
	}

	const query = `${JSON.stringify(finalQueryClause).replace(/"([^(")"]+)":/g, "$1:")}`;

	return {
		query: query.substring(1, query.length - 1),
		isQueryPresent: finalQueryClause.length > 0,
	};
};

export default getSearchQuery;
