import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { useAtom } from "jotai";
import { intersectionWith, isEqual } from "lodash";
import { LoggedInHeader } from "../utils/types";
import classNames from "../utils/classNames";
import { LOGGED_IN_HEADER } from "../utils/constants";
import useDimensions from "../hooks/useDimensions";
import { openSideBarAtom } from "../utils/atoms";
import "./css/SideBar.css";
import SvgIcon from "./SvgIcon";
import { DASHBOARD_PAGE } from "../routes/constants";
import Logo from "./Logo";

export default function SideBar() {
	const [showDropdown, setShowDropdown] = useState<boolean>(false);
	const [showDropdownId, setShowDropdownId] = useState<string>("");
	const [userRoles, setUserRoles] = useState(JSON.parse(localStorage.getItem("roles") || "{}"));
	const [open, setOpen] = useAtom(openSideBarAtom);
	const [pathname, setPathname] = useState(window.location.pathname?.toLowerCase());
	const [, width] = useDimensions();

	useEffect(() => {
		setUserRoles(JSON.parse(localStorage.getItem("roles") || "{}"));
	}, [localStorage]);

	useEffect(() => {
		setPathname(window.location.pathname);
	}, [window.location.pathname]);

	// TODO: icon rotation when dropdown opens
	const renderSingleItem = (item: LoggedInHeader, parentPage?: string) => {
		if (item?.items) {
			if (intersectionWith(item?.userData?.permissions, [userRoles], isEqual).length === 0) {
				return null;
			}
			return (
				<li key={item?.id} data-testid={item.testId}>
					<button
						type="button"
						className={classNames(
							"flex items-center p-2 w-full text-white font-normal rounded-lg transition duration-75",
							"group hover:bg-gray-700 dark:text-white dark:hover:bg-gray-700",
						)}
						onClick={() => {
							if (showDropdown && showDropdownId !== item.name.toLowerCase()) {
								setShowDropdownId(item.name.toLowerCase());
							} else {
								setShowDropdown(!showDropdown);
								setShowDropdownId(item.name.toLowerCase());
							}
						}}
						aria-controls="dropdown-example"
						data-collapse-toggle="dropdown-example"
					>
						{item?.icon && (
							<SvgIcon
								icon={item?.icon}
								className={classNames(
									"group-hover:text-white dark:group-hover:text-white",
									pathname.includes(item.href?.toLowerCase())
										? "active-text-style group-hover:text-primary dark:group-hover:text-primary"
										: "inactive-text-style",
								)}
							/>
						)}
						<span
							className={classNames(
								"flex-1 ml-3 text-left whitespace-pre-wrap",
								"group-hover:text-white dark:group-hover:text-white",
								pathname.includes(item.href?.toLowerCase())
									? "active-text-style group-hover:text-primary dark:group-hover:text-primary"
									: "inactive-text-style",
							)}
						>
							{item.name}
						</span>
						{showDropdown && showDropdownId === item.name.toLowerCase()
							? (
								<ChevronUpIcon className={classNames(
									"flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75",
									"group-hover:text-white dark:group-hover:text-white",
									pathname.includes(item.href?.toLowerCase())
										? "active-text-style group-hover:text-primary dark:group-hover:text-primary"
										: "inactive-text-style",
								)}
								/>
							)
							: (
								<ChevronDownIcon className={classNames(
									"flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75",
									"group-hover:text-white dark:group-hover:text-white",
									pathname.includes(item.href?.toLowerCase())
										? "active-text-style group-hover:text-primary dark:group-hover:text-primary"
										: "inactive-text-style",
								)}
								/>
							)}
					</button>
					<ul
						id="dropdown-example"
						className={classNames(
							"py-2 space-y-2",
							showDropdown && showDropdownId === item.name.toLowerCase() ? "" : "hidden",
							"sidebar-container",
						)}
					>
						<div className="pl-8">
							{item.items?.map((childItem: LoggedInHeader) => renderSingleItem(childItem, item.href))}
						</div>
					</ul>
				</li>
			);
		}

		const hrefWithParent = parentPage ? `${parentPage}${item.href}` : item.href;
		if (intersectionWith(item?.userData?.permissions, [userRoles], isEqual).length === 0) {
			return null;
		}
		// TODO: Fix link type definition or alt
		return (
			<li key={item.id} data-testid={item.testId}>
				<Link
					onClick={() => {
						setPathname(hrefWithParent);
						if (width < 769) {
							setOpen(false);
						}
					}}
					to={hrefWithParent}
					className={classNames(
						"flex items-center p-2 text-base font-normal rounded-lg",
						"group hover:bg-gray-700 dark:text-white dark:hover:bg-gray-700",
					)}
					data-testid={`${item.testId}-a`}
				>
					{item?.icon && (
						<SvgIcon
							icon={item?.icon}
							className={classNames(
								"group-hover:text-white dark:group-hover:text-white",
								pathname === hrefWithParent?.toLowerCase()
									? "active-text-style group-hover:text-primary dark:group-hover:text-primary"
									: "inactive-text-style",
							)}
						/>
					)}
					<span
						className={classNames(
							"ml-3 flex-1 whitespace-pre-wrap",
							"group-hover:text-white dark:group-hover:text-white",
							pathname === hrefWithParent?.toLowerCase()
								? "active-text-style group-hover:text-primary dark:group-hover:text-primary"
								: "inactive-text-style",
						)}
					>
						{item.name}
					</span>
					{
						item?.badgeCount && (
							<span
								className={classNames(
									"inline-flex justify-center items-center p-3 ml-3 w-3 h-3 text-sm font-medium",
									"text-blue-600 bg-blue-200 rounded-full dark:bg-blue-900 dark:text-blue-200",
									"group-hover:text-white dark:group-hover:text-white",
									pathname === hrefWithParent?.toLowerCase()
										? "active-text-style group-hover:text-primary dark:group-hover:text-primary"
										: "inactive-text-style",
								)}
							>
								{item.badgeCount}
							</span>
						)
					}
				</Link>
			</li>
		);
	};

	const renderSideBar = () => (
		<div className="w-80" data-testid="sidebar-wrapper">
			<div className="overflow-y-auto py-4 px-3 h-full w-full sidebar-container flex flex-col justify-between">
				<ul className="space-y-2">
					{LOGGED_IN_HEADER.map((item: LoggedInHeader) => renderSingleItem(item))}
				</ul>
				<a href={DASHBOARD_PAGE} className="flex items-center justify-center">
					<Logo />
				</a>
			</div>
		</div>
	);

	if (width > 768) {
		return renderSideBar();
	}

	return (
		<>
			<Transition.Root show={open} as={Fragment}>
				<Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={() => { }}>
					<div className="absolute inset-0 overflow-hidden">
						<Transition.Child
							as={Fragment}
							enter="ease-in-out duration-500"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in-out duration-500"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay
								className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
								onClick={() => setOpen(!open)}
							/>
						</Transition.Child>
						<div className="pointer-events-auto fixed inset-y-0 flex">
							<Transition.Child
								as={Fragment}
								enter="transform transition ease-in-out duration-500 sm:duration-700"
								enterFrom="-translate-x-full"
								enterTo="translate-x-0"
								leave="transform transition ease-in-out duration-500 sm:duration-700"
								leaveFrom="translate-x-0"
								leaveTo="-translate-x-full"
							>
								<div
									className="pointer-events-auto relative w-screen flex flex-row pt-16"
								>
									{renderSideBar()}
									<Transition.Child
										as={Fragment}
										enter="ease-in-out duration-500"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="ease-in-out duration-500"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<div
											className="h-full relative w-1/2 flex-1"
											onClick={() => setOpen(false)}
											role="button"
											tabIndex={0}
											onKeyDown={() => setOpen(false)}
										>
											<div className="absolute top-0 right-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4" />
										</div>
									</Transition.Child>
								</div>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
}
