import * as React from "react"
import { SVGAttributes } from "react";

const SvgComponent = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { stroke = "var(--primary)" } = props;
	return (
		<svg
			width={102}
			height={102}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 102 102"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<defs>
				<path id="linkedin-verified-a" d="M1.237.623h14.075v14.906H1.237z" />
			</defs>
			<g fill="none" fillRule="evenodd">
				<path
					d="M93.023 86.566c0 7.758-6.289 14.047-14.047 14.047-7.757 0-14.046-6.29-14.046-14.047 0-7.758 6.29-14.047 14.046-14.047 7.758 0 14.047 6.289 14.047 14.047Z"
					stroke={stroke}
					strokeDasharray="3,4"
				/>
				<path
					stroke={stroke}
					d="m77.98 94.174-6.827-6.149 2.63-2.919 3.572 3.217 6.526-9.02 3.188 2.3zM55.269 24.827c0 13.159-10.668 23.827-23.827 23.827-13.16 0-23.827-10.668-23.827-23.827C7.615 11.668 18.283 1 31.442 1 44.6 1 55.269 11.668 55.269 24.827Z"
				/>
				<path
					d="M52.464 24.827c0 11.61-9.412 21.022-21.022 21.022-11.61 0-21.022-9.412-21.022-21.022 0-11.61 9.412-21.022 21.022-21.022 11.61 0 21.022 9.412 21.022 21.022Z"
					stroke={stroke}
				/>
				<path
					d="M64.93 85.336H3a2 2 0 0 1-2-2v-56a2 2 0 0 1 2-2h4.615M55.269 25.336H99a2 2 0 0 1 2 2v56a2 2 0 0 1-2 2h-5.83"
					stroke={stroke}
				/>
				<path
					stroke={stroke}
					strokeLinecap="round"
					d="M10.42 55.334h82.751M10.42 65.335h82.751M10.42 75.335h60.12"
				/>
				<path stroke={stroke} d="M87.413 75.335h5.758" />
				<path
					d="M20.828 15.804c-.096.168-.27.563-.312.966-.156 1.525.87 2.808 2.27 2.952 1.614.164 3.008-.944 2.98-2.64-.008-.616-.32-1.301-.652-1.675-.485-.547-1.268-1.026-2.385-.88-.85.113-1.564.688-1.9 1.277"
					fill={stroke}
				/>
				<g transform="translate(26.97 20.69)">
					<mask id="linkedin-verified-b" fill="#fff">
						<use xlinkHref="#linkedin-verified-a" />
					</mask>
					<path
						d="M15.287 10.328c0-1.78.103-3.549-.114-5.08C14.738 2.171 13.031.38 9.383.648 7.543.785 6.404 1.76 5.58 2.976c-.018-.643.037-1.361-.029-1.958H1.237v14.476c1.439.047 3.074.047 4.514 0v-5.308c0-1.79-.129-3.652.623-4.684.684-.934 2.699-1.233 3.634-.34.985.942.766 3.147.766 5.194v5.138c1.438.047 3.075.048 4.513 0v-5.166Z"
						fill={stroke}
						mask="url(#linkedin-verified-b)"
					/>
				</g>
				<path
					d="M20.856 21.793c.02 4.787-.037 9.65.029 14.391a71.95 71.95 0 0 0 4.484 0c.066-4.76.01-9.641.029-14.448-1.438-.066-2.999-.009-4.485-.028-.04.008-.032.062-.057.085"
					fill={stroke}
				/>
			</g>
		</svg>
	)
}

export default SvgComponent
