import * as React from "react"
import {SVGAttributes} from "react";

const SvgComponent = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { stroke = "var(--primary)", fill} = props;
	return (
		<svg width={60} height={51} viewBox="0 0 60 51" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g fill="none" fillRule="evenodd">
				<path
					d="M49.958 13.132 34.22 26.445c-2.326 1.97-6.221 1.97-8.548 0L9.937 13.132M10.815 40.061l12.167-15.892"
					stroke={fill || stroke}
				/>
				<path
					d="M9.935 37.868V13.133a3.197 3.197 0 0 1 3.197-3.197h33.63a3.196 3.196 0 0 1 3.195 3.197v24.735a3.195 3.195 0 0 1-3.196 3.196H13.132a3.196 3.196 0 0 1-3.197-3.196ZM49.078 40.061 36.911 24.169"
					stroke={fill || stroke}
				/>
				<path
					d="M49.958 31.628a9.436 9.436 0 1 1-9.436 9.436M9.936 19.372C4.724 19.372.5 15.147.5 9.936S4.724.5 9.936.5c5.211 0 9.436 4.224 9.436 9.435M19.372 41.064a9.436 9.436 0 1 1-9.436-9.436"
					stroke={fill || stroke}
				/>
				<path
					d="m49.077 40.06.001.001h-.001a3.179 3.179 0 0 1-1.616.921v.082h1.043v5.291h2.26v-5.29h1.411l.144-1.927h-1.555v-1.062c0-.462.125-.39.777-.39h.914l-.136-1.912h-1.555c-.308 0-.568.038-.806.095v2c0 .85-.338 1.62-.881 2.191M47.176 9.978a3.099 3.099 0 0 1 .062-.7c.334-1.536 1.766-2.55 3.342-2.368a3.019 3.019 0 0 1 1.644.728l.283.232 1.359-1.455-.303-.257a6.922 6.922 0 0 0-.213-.178c-1.155-.892-2.47-1.235-3.908-1.026-.918.135-1.76.524-2.505 1.156-1.124.955-1.75 2.376-1.753 3.826h1.578c.142 0 .277.023.414.042M49.958 13.132v1.835c.092.005.184.011.274.011.407 0 .805-.049 1.19-.148a5.014 5.014 0 0 0 2.984-2.09 4.822 4.822 0 0 0 .8-2.066c.027-.19.045-.385.064-.578l.068-.79H50.33V11.3h2.56c-.197.396-.46.73-.785.997a2.864 2.864 0 0 1-2.167.652c.004.062.019.122.019.184M6.238 35.654c-.392.052-.72.316-.875.588a1.275 1.275 0 0 0-.144.443c-.07.702.401 1.292 1.044 1.358.743.076 1.383-.434 1.371-1.214a1.301 1.301 0 0 0-.299-.77 1.205 1.205 0 0 0-1.097-.405M10.816 40.06v.001a3.18 3.18 0 0 1-.678-1.105h-1.38v6.658c.661.021 1.413.021 2.075 0v-2.442c0-.823-.059-1.679.287-2.153.115-.158.323-.259.55-.325a3.16 3.16 0 0 1-.854-.634M12.894 41.04c.308.49.25 1.369.25 2.212v2.362c.66.022 1.413.022 2.074 0v-2.376c0-.76.031-1.506-.043-2.174h-2.043c-.082 0-.158-.017-.238-.023M5.402 38.957c-.018.004-.015.028-.025.039.008 2.202-.018 4.439.012 6.618a32.25 32.25 0 0 0 2.063 0c.03-2.188.004-4.433.013-6.644-.662-.031-1.379-.004-2.063-.013"
					fill={fill || stroke}
				/>
				<path d="M49.958 19.372a9.436 9.436 0 1 0-9.436-9.436" stroke={fill || stroke} />
				<path
					d="M13.133 9.936h1.164a6.665 6.665 0 0 0 .187-1.742c.282-.179 1.036-.96 1.1-1.139-.093.037-.893.311-1.261.318.48-.19.942-1.024.96-1.175-.178.176-1.388.547-1.388.547s-1.08-1.234-2.591-.503c-1.548.75-1.182 2.496-1.182 2.496s-2.475.05-4.52-2.305c-.81 1.772.434 2.78.669 2.93-.35 0-.773-.17-.98-.274.067 1.564 1.235 2.072 1.762 2.194-.227.104-.706.085-.998.04.423 1.092 1.195 1.421 2.053 1.514-1.422 1.121-2.807.952-3.25.913a6.447 6.447 0 0 0 5.078.743v-1.36a3.196 3.196 0 0 1 3.197-3.197"
					fill={fill || stroke}
				/>
			</g>
		</svg>
	)
}

export default SvgComponent
