import React, {
	useMemo,
} from "react";
import isNil from "lodash/isNil";
import { cu } from "@credo/utilities";
import ImageCollage from "./ImageCollage";
import { classNames, RegEx } from "../../common";

export interface PostImagesProps {
	postData: any;
	isContentRestricted: boolean
	sharedPost: boolean
	imageErrorMsg: string;
	contentSensitiveMsg: string;
	/**
	 * set true if the component is being called inside
	 * PostCompactView or need a compact version of this
	 * component
	 *
	 * @default false
	 * */
	isCompactView?: boolean;
}

const COLLAGE_LAYOUT = [[1, 1], [2, 1], [1, 2], [2, 2]];

const PostImages: React.FC<PostImagesProps> = (props) => {
	const {
		postData,
		isContentRestricted,
		sharedPost,
		imageErrorMsg,
		contentSensitiveMsg,
		isCompactView = false,
	} = props;

	const nvl = (value: any, defaultValue: any) => (!isNil(value) ? value : defaultValue);

	const images = useMemo(() => {
		const imageData = [
			postData["p.img_bucketRelativeRefPath0"],
			postData["p.img_bucketRelativeRefPath1"],
			postData["p.img_bucketRelativeRefPath2"],
			postData["p.img_bucketRelativeRefPath3"],
		];

		const tempImages: Array<{ source: string }> = [];
		imageData.forEach((img) => {
			if (!isNil(img) && img !== " "
				&& !tempImages.includes(nvl(cu.buildSourceUrlImage(img), ""))) {
				tempImages.push({
					source: RegEx.url.test(img) ? img : nvl(cu.buildSourceUrlImage(img), ""),
				});
			}
		});

		if (tempImages.length !== 0) {
			return tempImages;
		}

		return [];
	}, [postData]);

	if (images && images.length === 0) {
		return null;
	}

	return (
		<div
			className={classNames(
				"w-full",
				isCompactView ? "px-0 pb-0" : "px-4 pb-4",
			)}
		>
			<ImageCollage
				caption={postData["p.postText"]}
				photos={images}
				layout={COLLAGE_LAYOUT[images.length - 1]}
				height={images.length === 1 ? ["100%"] : ["100%", "100%"]}
				width="100%"
				imageErrorMsg={imageErrorMsg}
				isContentRestricted={isContentRestricted}
				contentSensitiveMsg={contentSensitiveMsg}
			/>
		</div>
	);
};

export default PostImages;

PostImages.defaultProps = {
	isCompactView: false,
};
