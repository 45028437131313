import React, { useMemo } from "react";
import { classNames } from "../common";

interface DecimalProps {
	/**
	 * Fixed Decimal value in string
	 * */
	value: string;
	/**
	 * Colors set to the decimal values
	 * */
	colors: {
		// Color set to Decimal places followed by only zeros.
		// '12' in '0.00012'
		highlightColor: string;
		// Color set to Decimal place with only zeros before any other value.
		// '0.000' in '0.00012'
		lowlightColor: string;
	}
	/**
	 * Css style classes for wrapper of the decimals structure
	 * */
	className?: string;
}

// eslint-disable-next-line import/prefer-default-export
export const Decimal: React.FC<DecimalProps> = (props: DecimalProps) => {
	const {
		value,
		colors,
		className,
	} = props;
	const decimalWithOnlyZeros = useMemo(() => {
		let finalString = "";
		// eslint-disable-next-line no-restricted-syntax
		for (const str of value) {
			if (str === "0" || str === ".") {
				finalString += str;
			} else {
				break;
			}
		}

		return finalString;
	}, [value]);

	const valuesToHighlight = useMemo(() => value.replace(decimalWithOnlyZeros, ""), [value]);

	return (
		<span
			className={classNames(
				"flex",
				className,
			)}
		>
			{decimalWithOnlyZeros !== "" && (
				<span
					data-testid="decimal-decimal-with-only-zeros"
					style={{
						color: colors.lowlightColor,
					}}
				>
					{decimalWithOnlyZeros}
				</span>
			)}
			<span
				data-testid="decimal-just-value"
				style={{
					color: colors.highlightColor,
				}}
			>
				{valuesToHighlight}
			</span>
		</span>
	);
};

Decimal.defaultProps = {
	className: "",
};
