import { UpdateStoreMessage } from "./types";
import { EvtMgr } from "@credo/utilities";
import { EventConsts } from "./Consts";

/**
 * Singleton which will help us maintain, update and access
 * atom values with and without re-rendering the component.
 * It will help use access and update the atom outside react
 * component.
 *
 * NOTE: Only atoms which are initialised using `atomWithMgr`
 * will be updated by this class.
 *
 * @see atomWithMgr
 * */
export class StateManagerSingleton {
	private static instance: StateManagerSingleton = new StateManagerSingleton();

	// state store
	private readonly state: {
		[key: string]: any;
	};

	// optional onwrite handlers for state changes
	private onwrite: {
		[key: string]: (val: any) => void;
	};

	constructor() {
		this.state = {};
		this.onwrite = {};
		if (StateManagerSingleton.instance) {
			throw new Error("Error: Instantiation failed: Use StateMgr.getInstance() instead of new.");
		}
		StateManagerSingleton.instance = this;
	}

	public static getInstance(): StateManagerSingleton {
		return StateManagerSingleton.instance;
	}

	/**
	 * Updating the state object in this class without re-rendering
	 * the component. This should be called while updating the atom
	 * so that we will maintain an object which will contain all
	 * the latest values of the atoms.
	 *
	 * @param stateName {string} - name of the atom/state while accessing
	 * the value of the atom/state this key is needed.
	 * @param value {any} - Value of the state which should be updated
	 * against the stateName.
	 * @param notify {boolean:true} - executes onwrite for this state
	 *
	 * @see updateState
	 * */
	public setState = (stateName: string, value: any, notify = true) => {
		this.state[stateName] = value;

		if (notify && this.onwrite[stateName]) this.onwrite[stateName](value);
	};

	/**
	 * Useful for adding a single handler of the onwrite when state is updated
	 * @param stateName
	 * @param onwrite
	 */
	public setStateOnWrite(stateName: string, onwrite: (val: any) => void) {
		this.onwrite[stateName] = onwrite;
	}

	/**
	 * Get all the states. returns the latest updated object which
	 * contains the latest value of the atom.
	 *
	 * @see getState
	 * */
	public getAllStates = () => this.state;

	/**
	 * returns a particular value of the atom which is passed in the
	 * parameter. It will return the latest value of the atom set.
	 *
	 * @param key {string} - key/name of the atom whose value you need
	 * to access
	 *
	 * @see getAllStates
	 * */
	public getState = (key: string) => this.state[key];

	/**
	 * Fires an event which will update the atom value of the atom
	 * passed in the parameter. This will update the atom and re-render
	 * the component since the atom is updated. It will also in parallel
	 * update the state object of this instance.
	 *
	 * @param updateAtomMessage {UpdateStoreMessage} - Object contains
	 * 'atomName' key which should be the atom which needs to be updated
	 * and 'nextValue' the value which needs to be set to that atom.
	 *
	 * @see setState
	 * */
	public updateState = (updateAtomMessage: UpdateStoreMessage) => {
		EvtMgr.getInstance(EventConsts.updateAtomStore).notifyListeners(updateAtomMessage);
	}
}

export const StateMgr = StateManagerSingleton.getInstance();
