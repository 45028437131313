import React, { SVGAttributes } from "react";

const Notification = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { stroke = "var(--gray-dark)", fill } = props;
	return (
		<svg width="18px" height="21px" viewBox="0 0 18 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
			<g id="Web-Version" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Web-Version---Settings-Ego" transform="translate(-249.000000, -285.000000)" stroke={stroke} fill={fill}>
					<g id="pop-up-copy-4" transform="translate(248.000000, 235.000000)">
						<g id="menu_notification" transform="translate(2.000000, 51.000000)">
							<path d="M7.96453333,16.6408696 C10.7040889,16.6408696 12.9663111,16.3685562 14.5698667,16.1522816 C15.3209778,16.051587 15.7200889,15.2346469 15.3360889,14.5910768 C14.5032,13.196237 13.4027556,10.7235267 13.3823111,7.36557916 C13.3645333,4.37976397 10.9912,1.64086957 7.96453333,1.64086957 C4.93786667,1.64086957 2.56453333,4.37976397 2.54675556,7.36557916 C2.52631111,10.7235267 1.42586667,13.196237 0.592977778,14.5910768 C0.208088889,15.2346469 0.608088889,16.051587 1.3592,16.1522816 C2.96275556,16.3685562 5.22497778,16.6408696 7.96453333,16.6408696" id="Stroke-1"/>
							<path d="M11.1204444,16.6408696 C11.1204444,18.0965217 9.70711111,19.2756522 7.96488889,19.2756522 C6.22177778,19.2756522 4.80844444,18.0965217 4.80844444,16.6408696" id="Stroke-3"/>
							<path d="M6.62577778,1.74452174 C6.62577778,1.02104348 7.22488889,0.434956522 7.96444444,0.434956522 C8.704,0.434956522 9.30311111,1.02104348 9.30311111,1.74452174" id="Stroke-5"/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}

export default Notification;
