/* eslint-disable react/require-default-props */
import React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import classNames from "../utils/classNames";
import { usePagination, DOTS } from "./usePagination";
import "./css/pagination.css";
import { strings } from "../i18n/config";

interface pagenationProps {
	onPageChange: any,
	totalCount: number,
	siblingCount?: number,
	currentPage: number,
	pageSize: number,
	lastItemIndex: number,
	firstItemIndex: number,
	hidePageNumber?: boolean;
}

const Pagination = ({
	onPageChange,
	totalCount,
	siblingCount = 1,
	currentPage,
	pageSize,
	lastItemIndex,
	firstItemIndex,
	hidePageNumber,
}: pagenationProps) => {
	const paginationRange = usePagination({
		currentPage,
		totalCount,
		siblingCount,
		pageSize,
	}) || null;

	if (currentPage === 0 || (!hidePageNumber && paginationRange!.length < 2)) {
		return null;
	}

	const onNext = () => {
		onPageChange(currentPage + 1);
	};

	const onPrevious = () => {
		onPageChange(currentPage - 1);
	};

	// eslint-disable-next-line no-nested-ternary
	const lastPage = hidePageNumber
		? paginationRange!.length < 2
			? 	currentPage : currentPage + 1
		: paginationRange![paginationRange!.length - 1] ?? null;

	return (
		<div className={classNames(
			"tableFooter bg-white py-1 flex items-center",
			hidePageNumber ? "justify-center" : "justify-between",
		)}
		>
			<div className={classNames(
				"flex-1 flex justify-between",
				hidePageNumber ? "hidden" : "sm:hidden",
			)}
			>
				<button
					type="button"
					className="relative inline-flex items-center
 					px-4 py-2 border border-gray-300 text-sm normal-text-style
					font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
					disabled={currentPage === 1}
					onClick={onPrevious}
				>
					{strings("Pagination.previous")}
				</button>
				<button
					type="button"
					aria-current="page"
					className={classNames("pagination-item", "selected", "normal-text-style")}
				>
					{currentPage}
				</button>
				<button
					type="button"
					className="ml-3 relative inline-flex items-center px-4
					py-2 border border-gray-300 text-sm font-medium normal-text-style
					rounded-md text-gray-700 bg-white hover:bg-gray-50"
					onClick={onNext}
					// disabled={currentPage === lastPage}
				>
					{strings("Pagination.next")}
				</button>
			</div>
			<div className={classNames(
				"sm:flex-1 sm:flex sm:items-center",
				hidePageNumber ? "sm:justify-end" : "hidden sm:justify-between",
			)}
			>
				{!hidePageNumber && (
					<div>
						<p
							className="text-sm text-gray-700 normal-text-style"
						>
							{strings("Pagination.showing")}
							{" "}
							<span className="font-medium">{firstItemIndex}</span>
							{" "}
							{strings("Pagination.to")}
							{" "}
							<span className="font-medium">{lastItemIndex}</span>
							{" "}
							{strings("Pagination.of")}
							{" "}
							<span className="font-medium">{totalCount}</span>
							{" "}
							{strings("Pagination.results")}
						</p>
					</div>
				)}
				<div>
					<ul
						className={classNames("pagination-container", "pagination-bar")}
					>
						{currentPage !== 1
							? (
								<button
									type="button"
									className={classNames("pagination-item", currentPage === 1 ? "disabled" : "")}
									onClick={onPrevious}
								>
									<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
								</button>
							)
							: null}
						{!hidePageNumber && paginationRange!.map((pageNumber) => {
							if (pageNumber === DOTS) {
								return <li key={pageNumber} className="pagination-item dots">&#8230;</li>;
							}
							return (
								<button
									type="button"
									key={pageNumber}
									className={classNames("pagination-item", pageNumber === currentPage ? "selected" : "")}
									onClick={() => onPageChange(pageNumber)}
								>
									{pageNumber}
								</button>
							);
						})}
						{hidePageNumber && (
							<div className="flex items-center px-1">
								<p
									className="text-sm text-gray-700 normal-text-style"
								>
									{strings("Pagination.showing")}
									{" "}
									<span className="font-medium">{firstItemIndex}</span>
									{" "}
									{strings("Pagination.to")}
									{" "}
									<span className="font-medium">{lastItemIndex}</span>
								</p>
							</div>
						)}
						{currentPage !== lastPage
							? (
								<button
									type="button"
									className={classNames(
										hidePageNumber ? "pl-1.5 pr-0 ml-1 mr-0" : "px-1.5 mx-1 my-auto",
										"pagination-item",
										currentPage === lastPage ? "disabled" : "",
									)}
									onClick={onNext}
								>
									<ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
								</button>
							)
							: null}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default Pagination;
