import React, { useEffect, useRef, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import {
	ConfirmationModal, DEFAULT_TAG_MESSAGES, PostView, SnackBarTypeOptions,
} from "@credo/ui-components";
import { cu, YesNoOptions } from "@credo/utilities";
import { toast } from "react-toastify";
import { useQuery } from "../../hooks/graphql/useQuery";
import { strings } from "../../i18n/config";
import classNames from "../../utils/classNames";
import getQueryFields from "../../utils/getQueryFields";
import { isYes } from "../../utils/utils";
import { DisabledPostTableHeaders, PostTableHeaders } from "./constants";
import "react-loading-skeleton/dist/skeleton.css";
import {
	deletePostQuery, getReportedPostsData, GetReportedPostsVariables, restoreDisabledItem,
} from "./queries";
import { DisabledPostType, ReportingUserDetails } from "../../utils/types";
import PostReportedList from "./PostReportedList";
import {
	AUTH_URL, COMMUNITIES_DETAILS_PAGE, POST_COMMENTS_PAGE, USER_DETAILS_PAGE,
} from "../../routes/constants";
import { StreamTableHeaders } from "../communities/constants";
import { CommentTableHeaders } from "../comments/constants";
import Accordion from "../../gen-comp/Accordion";
import ToastMsg from "../../gen-comp/ToastMsg";
import { successRETCD } from "../../api";

export default function PostDetailsScreen() {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const postUuid = searchParams.get("postUuid");
	const reportedCount = parseInt(searchParams.get("reportedCount") || "0", 10);
	const [postDetails, setPostDetails] = useState<any>({});
	const [confirmationObj, setConfirmationObj] = useState<any>(null);
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	const isNewTab = searchParams.get("isNewTab") ?? "";
	const [postReportedData, setPostReportedData] = useState<[ReportingUserDetails] | []>([]);
	const bottomRef = useRef<any>(null);
	const [height, setHeight] = useState(0);
	const ref = useRef<any>(null);

	const queryString = `query { post(first: 1, postUuid: "${postUuid}")
	  { 
			${getQueryFields(PostTableHeaders)},
			nb_of_co,
			admin_review,
			stream{
				${getQueryFields(StreamTableHeaders)},
			},
			comments{
				${getQueryFields(CommentTableHeaders)},
				childComments{
					${getQueryFields(CommentTableHeaders)},
				}	
			}
		}
	\n}`;

	const navigateToUserDetails = (item: any) => {
		window.open(`${AUTH_URL}${USER_DETAILS_PAGE}?${createSearchParams({
			prof_id: item?.profId,
			mode: item?.mode,
			isNewTab: "y",
		})}`, "_blank");
	};

	const [getPostDetailsUsingQuery, { data, loading }] = useQuery<any[]>(queryString, {
		formatDataKey: "post",
	});

	const [deletePostMutation] = useQuery<Array<Object>>("", {
		formatDataKey: "post",
		isMutation: true,
	});

	const closeConfirmationModal = () => {
		setOpenConfirmationModal(false);
		setConfirmationObj(null);
	};

	const handleDeletePost = async () => {
		await deletePostMutation({
			mutation: deletePostQuery({ postUuid: postUuid || "", fields: DisabledPostTableHeaders }),
		}).then((response: any) => {
			if (response.retcd === successRETCD) {
				toast(
					<ToastMsg
						message={strings("PostSearchPage.delete_success")}
						showButton
						type={SnackBarTypeOptions.SUCCESS}
					/>,
				);
				navigate(-1);
			} else {
				toast(
					<ToastMsg
						message={strings("PostSearchPage.something_went_wrong")}
						showButton
						type={SnackBarTypeOptions.ERROR}
					/>,
				);
			}
		}).catch((error: any) => {
			toast(
				<ToastMsg
					message={strings("PostSearchPage.something_went_wrong", { error })}
					showButton
					type={SnackBarTypeOptions.ERROR}
				/>,
			);
		});
		closeConfirmationModal();
	};

	const restoreDisabledPost = async () => {
		await restoreDisabledItem({
			postUuid: postUuid || "",
			itemType: DisabledPostType.POST,
		}).then((res: any) => {
			if (res?.response?.retcd === successRETCD) {
				toast(
					<ToastMsg
						message={strings("DisabledPostPage.restore_post_success")}
						showButton
						type={SnackBarTypeOptions.SUCCESS}
					/>,
				);
				getPostDetailsUsingQuery();
			} else {
				toast(
					<ToastMsg
						message={strings("PostSearchPage.something_went_wrong")}
						showButton
						type={SnackBarTypeOptions.ERROR}
					/>,
				);
			}
		}).catch((error: any) => {
			toast(
				<ToastMsg
					message={strings("PostSearchPage.something_went_wrong", { error })}
					showButton
					type={SnackBarTypeOptions.ERROR}
				/>,
			);
		});
		closeConfirmationModal();
	};

	const showRestoreConfirmationPopup = () => {
		const title = strings("DisabledPostPage.restore_post");
		const message = `${strings("DisabledPostPage.restore_post_message", { postUuid })}`;
		setOpenConfirmationModal(true);
		setConfirmationObj({
			ok_button_function: () => {
				restoreDisabledPost();
			},
			title,
			message,
		});
	};

	const showDeleteConfirmationPopup = () => {
		const title = strings("PostSearchPage.delete_post");
		const message = `${strings("PostSearchPage.delete_post_message", { postUuid })}`;
		setOpenConfirmationModal(true);
		setConfirmationObj({
			ok_button_function: () => handleDeletePost(),
			title,
			message,
		});
	};

	const goToCommunityDetailScreen = (item: any) => {
		navigate({
			pathname: AUTH_URL + COMMUNITIES_DETAILS_PAGE,
			search: `?${createSearchParams({ communityId: item?.streamId })}`,
		});
	};

	const navigateToUserOrStream = (element: any) => {
		if (element.type === "stream") {
			goToCommunityDetailScreen(element);
		} else {
			navigateToUserDetails(element);
		}
	};

	const goToPostCommentScreen = () => {
		if (data && data.length > 0 && data[0] && data[0]?.nb_of_co > 0) {
			navigate({
				pathname: AUTH_URL + POST_COMMENTS_PAGE,
				search: `?${createSearchParams({ postUuid: postUuid || "" })}`,
			});
		}
	};

	const scrollToBottom = () => {
		if (bottomRef) {
			bottomRef?.current?.scrollIntoView({
				behavior: "smooth", block: "end", inline: "nearest",
			});
		}
	};

	useEffect(() => {
		if (ref) {
			setHeight(ref?.current?.clientHeight || 0);
		}
	});

	useEffect(() => {
		if (data && data.length > 0) {
			const details: any = data[0];
			const postDetails = {
				"p.lastName": details?.lastName,
				"p.shared_postTitle": details?.shared_postTitle,
				"p.shared_edit_ts": details?.shared_edit_ts,
				"p.purpose": details?.purpose,
				"p.shared_post_streamId": details?.shared_post_streamId,
				"p.mediaObj_channel_name": details?.mediaObj_channel_name,
				"p.shared_postUuid": details?.shared_postUuid,
				"p.prof_id": details?.prof_id,
				"p.mode": details?.mode,
				"p.mediaObj_Link_ImgUrl": details?.mediaObj_Link_ImgUrl,
				"p.shared_mode": details?.shared_mode,
				"p.nb_rep": details?.nb_rep,
				"p.shared_username": details?.shared_username,
				"p.is_shared": details?.is_shared,
				"p.mediaObj_Link_Url": cu.isSet(details?.mediaObj_Link_Url) ? details?.mediaObj_Link_Url : null,
				"p.postTitle": details?.postTitle,
				"p.mediaObj_Link_Text": details?.mediaObj_Link_Text,
				"p.shared_postText": details?.shared_postText,
				"p.shared_lastName": details?.shared_lastName,
				"p.img_bucketRelativeRefPath1": cu.isSet(details?.img_bucketRelativeRefPath1) ? details?.img_bucketRelativeRefPath1 : null,
				"p.img_bucketRelativeRefPath0": cu.isSet(details?.img_bucketRelativeRefPath0) ? details?.img_bucketRelativeRefPath0 : null,
				"p.edit_ts": details?.edit_ts,
				"p.mediaObj_Link_Title": details?.mediaObj_Link_Title,
				"p.img_bucketRelativeRefPath3": cu.isSet(details?.img_bucketRelativeRefPath3) ? details?.img_bucketRelativeRefPath3 : null,
				"p.postUuid": details?.postUuid,
				"p.video_bucketRelativeRefPath": cu.isSet(details?.video_bucketRelativeRefPath)
					? details?.video_bucketRelativeRefPath : null,
				"p.img_bucketRelativeRefPath2": cu.isSet(details?.img_bucketRelativeRefPath2) ? details?.img_bucketRelativeRefPath2 : null,
				"p.shared_cnt": details?.shared_cnt,
				"p.shared_firstName": details?.shared_firstName,
				"p.postType": details?.postType,
				"p.created_by": details?.created_by,
				"p.sharedContentRating": details?.sharedContentRating,
				"p.article_date": details?.article_date,
				"p.reported_ts": details?.reported_ts,
				"p.shared_prof_id": details?.shared_prof_id,
				"p.firstName": details?.firstName,
				"p.postText": details?.postText,
				"p.shared_modif_ts": details?.shared_modif_ts,
				"p.shared_creat_ts": details?.shared_creat_ts,
				"p.creat_ts": details?.creat_ts,
				"p.shared_created_by": details?.shared_created_by,
				"p.profilePicRelUrl": details?.profilePicRelUrl,
				"p.shared_profilePicRelUrl": details?.shared_profilePicRelUrl,
				"p.mediaObj_hostname": details?.mediaObj_hostname,
				"p.username": details?.username,
				"p.egousername": details?.egousername,
				"s.streamId": details?.stream && details?.stream?.length > 0 ? details?.stream[0].streamId : null,
				"s.name": details?.stream && details?.stream?.length > 0 ? details?.stream[0].name : null,
				admin_review: details?.admin_review,
				"p.nb_of_co": details?.nb_of_co,
			};
			setPostDetails(postDetails);
		}
	}, [data]);

	const reported_variables: GetReportedPostsVariables = {
		post_id: postUuid || "",
	};

	const [getReportedList, { data: reportedData, loading: isReportedLoading }] = useQuery<[{ posts: [ReportingUserDetails] }]>(
		getReportedPostsData(reported_variables),
		{
			formatDataKey: "stream",
		},
	);

	useEffect(() => {
		if (reportedData && reportedData?.length > 0 && reportedData[0] && reportedData[0]?.posts) {
			setPostReportedData(reportedData[0]?.posts);
		}
		if (reportedCount > 0) {
			scrollToBottom();
		}
	}, [reportedData]);

	useEffect(() => {
		if (reportedCount > 0) {
			scrollToBottom();
		}
	}, [height, postReportedData]);

	useEffect(() => {
		getPostDetailsUsingQuery();
		getReportedList();
	}, []);

	if (loading || (isReportedLoading && reportedCount > 0)) {
		return (
			<div className="w-auto h-full" data-testid="post-details-loading-wrapper">
				<SkeletonTheme>
					<div className="px-3">
						<Skeleton className={classNames("metric-card border rounded-lg p-4 h-36")} />
					</div>
				</SkeletonTheme>
			</div>
		);
	}

	if (!(data && data?.length > 0) && !loading) {
		return (
			<div className="w-full h-full" data-testid="post-details-not-found-wrapper">
				<h5 className="text-center">
					{strings("PostDetailsScreen.post_not_found")}
				</h5>
			</div>
		);
	}

	return (
		<div className="w-full h-full" ref={ref} data-testid="post-details">
			<div className="mx-5">
				<div className="antialiased mx-auto max-w-screen p-5 my-5 rounded">
					<div className="flex flex-row item-center">
						{!isYes(isNewTab)
							? (
								<div className="w-fit bg-gray-200 rounded-xl opacity-90 cursor-pointer mb-10 mr-5">
									<IoIosArrowBack
										className={classNames("flex-shrink-0 w-6 h-6 text-white transition duration-75 p-1")}
										onClick={() => navigate(-1)}
										size={25}
									/>
								</div>
							) : <div className="relative -top-28 left-10 w-fit opacity-80" style={{ height: 24 }} />}
						<h3 className="mb-4 text-lg font-semibold text-gray-900 mt-[-2px]">
							{strings("PostView.post_details")}
							{": "}
							{strings("PostView.post_id")}
							{" - "}
							{postUuid}
						</h3>
					</div>
					<div className="space-y-4">
						<div className="block rounded-lg flex items-center justify-center">
							<div
								className={classNames(
									"block",
									"w-full max-w-screen-sm md:min-w-[40rem]",
									"text-basic",
								)}
								data-testid="post-section"
							>
								<PostView
									item={postDetails}
									onCommentIconClick={goToPostCommentScreen}
									onEmojiClick={() => { }}
									onSharePostIconClick={() => { }}
									onLinkShareIconPress={() => { }}
									postMsgs={{
										imageErrorMsg: strings("PostView.imageErrorMsg"),
										contentSensitiveMsg: strings("PostView.contentSensitiveMsg"),
										cannotRateOwnPostMsg: strings("PostView.cannotRateOwnPostMsg"),
										switchToCredoModeToRate: strings("PostView.switchToCredoModeToRate"),
										sharedPostDeleted: strings("PostView.sharedPostDeleted"),
									}}
									isUserOwnPost={false}
									isCredoMode
									onModeChange={() => { }}
									userProfile={null}
									onTagRate={() => { }}
									moreMenuList={[]}
									navigateToUserOrStream={navigateToUserOrStream}
									showLoginModal={() => { }}
									navigateToDetailsScreen={() => { }}
									hasComments
									showHandIcon={false}
									onPressTagInfoBubble={() => {}}
									onPressCredoGraphText={() => {}}
									messages={{
										postHeaderTagMessages: DEFAULT_TAG_MESSAGES,
										postTagMessages: DEFAULT_TAG_MESSAGES,
									}}
									boostTrayProps={{
										messages: {
											boostSuccess: "Boost successful",
										},
										estDivPayoutRate: 1,
										ranking: {
											prevRanking: 1,
											currentRanking: 2,
										},
										totalBoost: {
											topPost: 0,
											current: 0,
											prev: 0,
										},
										onActions: () => {},
										topBoosters: [],
										alwaysOpen: false,
										postBoostInfo: {
											postUuid: postDetails["p.postUuid"],
											boost_total: 0,
											user_boost: 0,
											extraInfo: {
												prevRanking: 0,
												ranking: 0,
												totalBoost: 0,
												rankScore: 0,
												prevTotalBoost: 0,
												topBoosters: [],
											},
											pnl: {
												net: 0,
												spent: 0,
												earned: 0,
											},
											tag: "tag",
											user_id: postDetails["p.prof_id"],
											estDivPayoutRatio: 0,
											user_balance: 0,
										},
										authorisation: {
											isBoostAllowed: false,
										},
										isWide: false,
										currentTagScore: 0.4,
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				<div
					className="bg-white p-3 rounded-lg m-5 mb-4 min-h-fit max-h-full max-w-[calc(100%-2.5rem)]"
					data-testid="post-actions-accordion"
				>
					<Accordion
						header={(
							<h5 className="mb-1">
								{strings("PostView.disable_post_actions")}
							</h5>
						)}
						content={(
							<div className={classNames("-ml-2")}>
								{postDetails?.admin_review === YesNoOptions.YES
									&& (
										<button
											type="button"
											className={classNames("w-full inline-flex justify-center rounded-md border",
												"shadow-sm px-4 py-2 bg-primary text-base hover:bg-blue-700 mb-5",
												"focus:outline-none border-transparent font-medium text-white ",
												"focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:ml-3 sm:w-auto sm:text-sm")}
											onClick={showRestoreConfirmationPopup}
										>
											{strings("DisabledPostPage.restore")}
										</button>
									)}
								<button
									type="button"
									className={classNames("w-full inline-flex justify-center rounded-md border",
										"shadow-sm px-4 py-2 bg-red-600 text-base hover:bg-red-700 mb-5",
										"focus:outline-none border-transparent font-medium text-white ",
										"focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm")}
									onClick={showDeleteConfirmationPopup}
								>
									{strings("DisabledPostPage.delete")}
								</button>
							</div>
						)}
						defaultOpen
					/>
				</div>
				<PostReportedList
					data={postReportedData}
					reportedCount={reportedCount}
				/>
				<ConfirmationModal
					isVisible={openConfirmationModal}
					onClose={closeConfirmationModal}
					cancel_button_function={closeConfirmationModal}
					ok_button_title={strings("PostSearchPage.yes")}
					cancel_button_title={strings("PostSearchPage.no")}
					ok_button_function={confirmationObj?.ok_button_function}
					title={confirmationObj?.title}
					message={confirmationObj?.message}
					okButtonStyle=""
					cancelButtonStyle=""
				/>
				<div ref={bottomRef} />
			</div>
		</div>
	);
}
