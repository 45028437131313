import * as React from "react"
import {SVGAttributes} from "react";

const SvgComponent = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { stroke = "var(--primary)" } = props;
	return (
		<svg viewBox="0 0 33 33" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
			<g id="glyph-check" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
				<g id="check-Group-4" transform="translate(17.0956, 13.9349) rotate(-315) translate(-17.0956, -13.9349)translate(12.7958, 3.2054)" stroke={stroke} strokeWidth="3">
					<line x1="0.742640687" y1="20.7426407" x2="7.9257115" y2="20.601955" id="check-Stroke-1"></line>
					<line x1="7.8137085" y1="0.943650814" x2="7.8137085" y2="20.7426407" id="check-Stroke-3"></line>
				</g>
			</g>
		</svg>
	)
}

export default SvgComponent
