import React, { cloneElement, useRef, useState } from "react";
import {
	Placement,
	offset,
	flip,
	shift,
	autoUpdate,
	useFloating,
	useInteractions,
	useHover,
	arrow,
} from "@floating-ui/react-dom-interactions";
import classNames from "../utils/classNames";

interface Props {
	// eslint-disable-next-line no-undef
	content: JSX.Element;
	placement?: Placement;
	// eslint-disable-next-line no-undef
	children: JSX.Element;
}

const Tooltip = ({ children, content, placement }: Props) => {
	const [open, setOpen] = useState(false);
	const arrowEl = useRef(null);

	const {
		x, y, reference, floating, strategy, context, middlewareData, placement: floatingPlacement,
	} = useFloating({
		placement,
		open,
		onOpenChange: setOpen,
		middleware: [offset(10), flip(), shift({ padding: 8 }), arrow({ element: arrowEl, padding: 8 })],
		whileElementsMounted: autoUpdate,
	});

	const { getReferenceProps, getFloatingProps } = useInteractions([
		useHover(context),
	]);

	const staticSide = {
		top: "bottom",
		right: "left",
		bottom: "top",
		left: "right",
	}[floatingPlacement.split("-")[0]];

	return (
		<>
			{cloneElement(
				children,
				getReferenceProps({ ref: reference, ...children.props }),
			)}
			{open && (
				<div
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...getFloatingProps({
						ref: floating,
						className: classNames("z-50 w-80 xs:w-max xs:max-w-xs p-3 whitespace-pre-wrap",
							"bg-background-dark rounded-lg shadow-lg text-white text-sm",
							x ? `max-w-[calc(100vw-${2 * x}px)]` : ""),
						style: {
							position: strategy,
							top: y ?? 0,
							left: x ?? 0,
						},
					})}
				>
					{content}
					<div
						ref={arrowEl}
						className={classNames("z-0 absolute w-2 h-2 rotate-45",
							"bg-background-dark")}
						style={{
							top: `${middlewareData.arrow?.y}px`,
							left: `${middlewareData.arrow?.x}px`,
							// @ts-ignore
							[staticSide]: "-4px",
						}}
					/>
				</div>
			)}
		</>
	);
};

Tooltip.defaultProps = {
	placement: "right",
};

export default Tooltip;
