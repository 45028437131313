import * as React from "react"
import {SVGAttributes} from "react";

/**
 * Phone not Registered
 * */
const SvgComponent = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--primary)" } = props;
	return (
		<svg width={113} height={107} viewBox="0 0 113 107" xmlns="http://www.w3.org/2000/svg" {...props}>
			<title>{"accountNotRegistered_ego"}</title>
			<g stroke={stroke} fill="none" fillRule="evenodd">
				<path
					d="M112.5 32c0 17.398-14.102 31.5-31.5 31.5S49.5 49.398 49.5 32 63.602.5 81 .5s31.5 14.102 31.5 31.5Z"
					strokeDasharray="3,3"
				/>
				<path
					d="M80.999 28.5c5.239 0 9.501-4.262 9.501-9.5s-4.262-9.5-9.501-9.5c-5.237 0-9.499 4.262-9.499 9.5s4.262 9.5 9.499 9.5ZM63.504 49.384c.789-10.582 5.209-14.865 7.773-16.486l.628-.398.633.443 7.961 6.41 7.963-6.41.633-.443.626.398c2.565 1.62 6.984 5.904 7.773 16.486l.006.888C93.13 54.737 87.13 57.5 80.501 57.5S67.868 54.737 63.5 50.268l.004-.884Z"/>
				<path
					d="M43.5 19c0 7.179-5.821 13-13 13-7.18 0-13-5.821-13-13s5.82-13 13-13c7.179 0 13 5.821 13 13Z"
					strokeDasharray="3,3"
				/>
				<path
					d="m32.435 97.043-4.55-4.417c-1.329-1.28-3.407-1.275-4.779-.003-1.028.94-2.407 2.189-2.524 2.334-.34.279-.94.062-1.853-.706-1.2-1.006-2.595-2.657-3.939-4.303-1.345-1.646-2.734-3.348-3.432-4.722-.525-.991-.663-1.683-.325-1.962.17-.141 1.59-1.28 2.757-2.052 1.505-1.05 1.92-3.147.931-4.705L11.253 71.1a3.439 3.439 0 0 0-3.963-1.435c-1.55.472-3.828 1.967-4.567 2.623-2.205 1.818-2.808 5.305-1.638 9.803 1.09 4.229 3.645 8.865 7.168 13.178 3.523 4.315 7.6 7.744 11.463 9.64 1.833.912 3.5 1.39 5.043 1.543 1.954.195 3.604-.215 4.846-1.24.793-.653 2.646-2.607 3.458-3.986.76-1.386.515-3.131-.628-4.184ZM33.5 51.5a4 4 0 1 1-8 0 4 4 0 0 1 8 0ZM45.5 51.5a4 4 0 1 1-8 0 4 4 0 0 1 8 0ZM58.5 53.924c-.821.96-2.091 1.576-3.514 1.576-2.478 0-4.486-1.863-4.486-4.164 0-1.724 1.128-3.204 2.738-3.836M33.5 64.5a4 4 0 1 1-8 0 4 4 0 0 1 8 0ZM45.5 64.5a4 4 0 1 1-8 0 4 4 0 0 1 8 0ZM58.5 64.5a4 4 0 1 1-8 0 4 4 0 0 1 8 0ZM33.5 76.5a4 4 0 1 1-8 0 4 4 0 0 1 8 0ZM45.5 76.5a4 4 0 1 1-8 0 4 4 0 0 1 8 0ZM45.5 89.5a4 4 0 1 1-8 0 4 4 0 0 1 8 0ZM58.5 76.5a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"/>
				<path
					d="M73.819 62.5c.447 2.167.68 4.412.68 6.708 0 18.387-15.034 33.292-33.58 33.292-2.908 0-5.73-.366-8.42-1.055M7.5 69.5c.23-18.264 15.287-33 33.826-33 2.819 0 5.556.339 8.174.981M24.5 19h12M30.5 13v12"/>
			</g>
		</svg>
	)
}

export default SvgComponent
