import React, { SVGAttributes } from "react";

// eslint-disable-next-line import/prefer-default-export
const NoEmailRecord = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--primary)" } = props;
	return (
		<svg
			width="50px"
			height="56px"
			viewBox="0 0 50 56"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="No-Email-Record" transform="translate(-5.000000, -3.000000)" stroke={stroke}>
					<g id="NoEmailRecord" transform="translate(5.000000, 3.000000)">
						<line x1="1.1813" y1="54.6048" x2="13.7223" y2="42.2398" id="Stroke-1" />
						<line x1="23.7918" y1="42.2396" x2="36.3328" y2="54.6046" id="Stroke-3" />
						<path d="M20.3084,38.1078 C20.3084,38.9648 19.6134,39.6588 18.7574,39.6588 C17.9004,39.6588 17.2064,38.9648 17.2064,38.1078 C17.2064,37.2508 17.9004,36.5568 18.7574,36.5568 C19.6134,36.5568 20.3084,37.2508 20.3084,38.1078 Z" id="Stroke-5" />
						<path d="M20.3084,32.43 C20.3084,33.287 19.6134,33.981 18.7574,33.981 C17.9004,33.981 17.2064,33.287 17.2064,32.43 C17.2064,31.573 17.9004,30.879 18.7574,30.879 C19.6134,30.879 20.3084,31.573 20.3084,32.43 Z" id="Stroke-7" />
						<path d="M5.0444,27.7255 L0.5004,31.4375 L0.5004,53.9275 C0.5004,54.3015 0.8054,54.6045 1.1814,54.6045 L36.3334,54.6045 C36.7094,54.6045 37.0144,54.3015 37.0144,53.9275 L37.0144,31.4375 L32.4694,27.7255" id="Stroke-9" />
						<path d="M37.0143,31.4379 L23.7923,42.2399 C22.6543,43.1689 20.7053,44.2459 18.7573,44.2459 C16.8083,44.2459 14.8603,43.1689 13.7223,42.2399 L0.5003,31.4379" id="Stroke-11" />
						<polyline id="Stroke-13" points="24.5286 12.9858 5.0446 12.9858 5.0446 35.0208" />
						<line x1="32.4699" y1="35.021" x2="32.4699" y2="24.611" id="Stroke-15" />
						<path d="M21.8192,22.9422 C21.8192,24.6332 20.4482,26.0042 18.7572,26.0042 C17.0662,26.0042 15.6952,24.6332 15.6952,22.9422 C15.6952,21.2512 17.0662,19.8802 18.7572,19.8802 C20.4482,19.8802 21.8192,21.2512 21.8192,22.9422 Z" id="Stroke-17" />
						<path d="M21.8192,22.9422 L21.8022,24.9882 C21.8022,25.5492 22.2572,26.0042 22.8182,26.0042 C23.3792,26.0042 23.8342,25.5492 23.8342,24.9882 L23.8342,22.9422 C23.8342,21.6432 23.3382,20.3432 22.3472,19.3522 C20.3642,17.3702 17.1502,17.3702 15.1672,19.3522 C13.1842,21.3352 13.1842,24.5492 15.1672,26.5322 C16.1582,27.5232 17.4582,28.0192 18.7572,28.0192 C19.7082,28.0192 20.6582,27.7532 21.4882,27.2232" id="Stroke-19" />
						<path d="M49.5,12.9858 C49.5,19.8818 43.91,25.4718 37.014,25.4718 C30.119,25.4718 24.529,19.8818 24.529,12.9858 C24.529,6.0898 30.119,0.4998 37.014,0.4998 C43.91,0.4998 49.5,6.0898 49.5,12.9858 Z" id="Stroke-21" />
						<path d="M35.2922,19.2526 C35.2922,18.6666 35.4492,18.2506 35.7632,18.0066 C36.0772,17.7626 36.4572,17.6406 36.9042,17.6406 C37.3362,17.6406 37.7102,17.7626 38.0242,18.0066 C38.3382,18.2506 38.4942,18.6666 38.4942,19.2526 C38.4942,19.8246 38.3382,20.2356 38.0242,20.4876 C37.7102,20.7386 37.3362,20.8646 36.9042,20.8646 C36.4572,20.8646 36.0772,20.7386 35.7632,20.4876 C35.4492,20.2356 35.2922,19.8246 35.2922,19.2526 Z M35.6902,15.9246 L35.6902,15.2336 C35.6902,14.5086 35.8262,13.9016 36.0982,13.4126 C36.3702,12.9246 36.8552,12.4086 37.5532,11.8636 C38.2502,11.3346 38.7422,10.8936 39.0282,10.5456 C39.3142,10.1966 39.4582,9.7636 39.4582,9.2476 C39.4582,8.7176 39.2722,8.3136 38.9032,8.0336 C38.5332,7.7546 38.0062,7.6156 37.3232,7.6156 C36.7082,7.6156 36.1222,7.7056 35.5642,7.8866 C35.0062,8.0686 34.4482,8.2986 33.8902,8.5776 L32.9482,6.6106 C33.5892,6.2626 34.2872,5.9796 35.0412,5.7626 C35.7952,5.5466 36.6252,5.4386 37.5322,5.4386 C38.9412,5.4386 40.0292,5.7766 40.7972,6.4536 C41.5642,7.1296 41.9482,8.0196 41.9482,9.1226 C41.9482,9.7226 41.8542,10.2356 41.6662,10.6606 C41.4772,11.0866 41.1982,11.4806 40.8282,11.8436 C40.4582,12.2056 40.0152,12.5896 39.4992,12.9946 C39.0672,13.3296 38.7382,13.6156 38.5152,13.8526 C38.2922,14.0896 38.1392,14.3226 38.0552,14.5536 C37.9712,14.7836 37.9292,15.0656 37.9292,15.4006 L37.9292,15.9246 L35.6902,15.9246 Z" id="Stroke-23" />
					</g>
				</g>
			</g>
		</svg>
	);
};

export default NoEmailRecord;
