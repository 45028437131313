/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGAttributes } from "react";

const FeedNoPost = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--accent)",
	} = props;
	return (
		<svg
			width="102px"
			height="102px"
			viewBox="0 0 102 102"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<g id="Login" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Web-Version---QR-Retry-Copy" transform="translate(-524.000000, -165.000000)" stroke={stroke}>
					<g id="feedNoPost" transform="translate(524.000000, 165.000000)">
						<polyline id="Stroke-1" points="48.2619 67.5954 37.3689 100.5974 4.7529 82.0244 17.3129 43.9734" />
						<polyline id="Stroke-3" points="17.3131 43.973 43.0391 37.177 75.6551 55.75" />
						<g id="Group-8" transform="translate(0.000000, 19.334000)">
							<polyline id="Stroke-4" points="25.5083 0.9384 0.9443 6.9244 17.3133 24.6394 43.0393 17.8434 35.4133 8.3034" />
							<polyline id="Stroke-6" points="37.081 35.8947 43.039 17.8427 75.655 36.4157 63.095 74.4677 37.369 81.2637 4.753 62.6907" />
						</g>
						<line x1="30.4794" y1="75.2288" x2="35.4134" y2="60.2798" id="Stroke-9" />
						<g id="Group-32" transform="translate(4.000000, 0.334000)">
							<path
								d="M37.9747,14.5036 L36.6497,16.7976 C35.4987,18.7926 32.9477,19.4766 30.9527,18.3246 L30.9527,18.3246 C28.9577,17.1726 28.2747,14.6216 29.4267,12.6276 L30.7507,10.3326"
								id="Stroke-10"
							/>
							<path
								d="M21.6434,17.766 C20.4914,19.761 22.7924,23.245 26.7824,25.549 C30.7714,27.852 34.9394,28.102 36.0914,26.107 L38.1764,22.495 C37.0244,24.49 32.8574,24.24 28.8674,21.936 C24.8774,19.633 22.5774,16.149 23.7284,14.154 L21.6434,17.766 Z"
								id="Stroke-12"
							/>
							<path
								d="M36.3428,17.2518 C38.1618,19.1308 38.9528,21.1508 38.1768,22.4948 C37.0248,24.4898 32.8568,24.2398 28.8678,21.9368 C24.8778,19.6328 22.5768,16.1488 23.7288,14.1538 C24.5048,12.8098 26.6508,12.4848 29.1878,13.1208"
								id="Stroke-14"
							/>
							<path
								d="M28.9422,5.1242 C27.7902,7.1192 30.0912,10.6032 34.0812,12.9072 C38.0702,15.2102 42.2382,15.4602 43.3902,13.4652 L45.4752,9.8532 C44.3232,11.8482 40.1562,11.5982 36.1662,9.2942 C32.1762,6.9912 29.8762,3.5072 31.0272,1.5122 L28.9422,5.1242 Z"
								id="Stroke-16"
							/>
							<line x1="26.7819" y1="25.5486" x2="23.6539" y2="30.9666" id="Stroke-18" />
							<polyline id="Stroke-20" points="71.655 55.4155 45.93 62.2115 13.313 43.6385" />
							<polyline id="Stroke-22" points="0.7532 81.6908 26.4792 74.8948 59.0952 93.4678" />
							<path
								d="M97.055,22.0904 C97.055,33.9914 87.407,43.6394 75.506,43.6394 C63.605,43.6394 53.958,33.9914 53.958,22.0904 C53.958,10.1894 63.605,0.5414 75.506,0.5414 C87.407,0.5414 97.055,10.1894 97.055,22.0904 Z"
								id="Stroke-24"
							/>
							<path
								d="M73.0408,31.4733 C73.0408,30.5993 73.2748,29.9803 73.7438,29.6163 C74.2118,29.2523 74.7778,29.0703 75.4438,29.0703 C76.0878,29.0703 76.6458,29.2523 77.1138,29.6163 C77.5818,29.9803 77.8158,30.5993 77.8158,31.4733 C77.8158,32.3263 77.5818,32.9393 77.1138,33.3143 C76.6458,33.6893 76.0878,33.8763 75.4438,33.8763 C74.7778,33.8763 74.2118,33.6893 73.7438,33.3143 C73.2748,32.9393 73.0408,32.3263 73.0408,31.4733 Z M73.6338,26.5113 L73.6338,25.4813 C73.6338,24.3993 73.8368,23.4953 74.2428,22.7663 C74.6478,22.0383 75.3718,21.2683 76.4118,20.4563 C77.4518,19.6673 78.1848,19.0103 78.6118,18.4903 C79.0378,17.9713 79.2518,17.3253 79.2518,16.5553 C79.2518,15.7663 78.9758,15.1623 78.4238,14.7463 C77.8728,14.3303 77.0878,14.1223 76.0688,14.1223 C75.1528,14.1223 74.2788,14.2573 73.4468,14.5273 C72.6138,14.7983 71.7828,15.1413 70.9508,15.5573 L69.5458,12.6243 C70.5028,12.1043 71.5428,11.6833 72.6668,11.3593 C73.7898,11.0373 75.0278,10.8763 76.3808,10.8763 C78.4818,10.8763 80.1038,11.3813 81.2488,12.3893 C82.3918,13.3983 82.9648,14.7253 82.9648,16.3693 C82.9648,17.2633 82.8248,18.0283 82.5438,18.6633 C82.2638,19.2973 81.8468,19.8843 81.2948,20.4263 C80.7438,20.9663 80.0838,21.5393 79.3138,22.1423 C78.6688,22.6413 78.1798,23.0683 77.8468,23.4213 C77.5148,23.7753 77.2848,24.1233 77.1608,24.4663 C77.0358,24.8103 76.9728,25.2313 76.9728,25.7303 L76.9728,26.5113 L73.6338,26.5113 Z"
								id="Stroke-26"
							/>
							<polyline id="Stroke-28" points="69.9865 60.4659 44.2615 67.2619 11.6445 48.6889" />
							<path
								d="M45.4753,9.8536 C44.3233,11.8486 40.1553,11.5986 36.1663,9.2946 C32.1763,6.9916 29.8763,3.5066 31.0273,1.5126 C32.1793,-0.4824 36.3473,-0.2324 40.3363,2.0706 C44.3263,4.3746 46.6273,7.8586 45.4753,9.8536 Z"
								id="Stroke-30"
							/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default FeedNoPost;
