import React, { SVGAttributes } from "react";

// eslint-disable-next-line import/prefer-default-export
const SendComment = (props: SVGAttributes<SVGElement>) => {
	const { fill = "var(--send-comment-button)" } = props;
	return (
		<svg
			width="14px"
			height="16px"
			viewBox="0 0 14 16"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<g id="A-Post---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="A-Post---CT" transform="translate(-139.000000, -373.000000)" fill={fill}>
					<path d="M139,373 L153,381 L139,389 L143.928,381 L139,373 Z" id="sendPost" />
				</g>
			</g>
		</svg>
	);
};

export default SendComment;
