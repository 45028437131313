import React, { SVGAttributes } from "react";

// eslint-disable-next-line import/prefer-default-export
const NoComments = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--accent)" } = props;
	return (
		<svg
			width="32px"
			height="24px"
			viewBox="0 0 32 24"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<g id="A-Post---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="A-Post---no-comments" transform="translate(-178.000000, -467.000000)">
					<g id="noComments_icon" transform="translate(179.000000, 468.000000)">
						<path d="M29.8810696,20.2065217 L29.8810696,1.79347826 C29.8810696,1.06747826 29.2918522,0.47826087 28.5658522,0.47826087 L7.45541739,0.47826087 C6.72846087,0.47826087 6.1402,1.06747826 6.1402,1.79347826 L6.1402,7.95634783 L6.10576522,7.97452174 L0.946286957,11 L6.1402,14.0407826 L6.1402,20.2065217 C6.1402,20.9325217 6.72846087,21.5217391 7.45541739,21.5217391 L28.5658522,21.5217391 C29.2918522,21.5217391 29.8810696,20.9325217 29.8810696,20.2065217 Z" id="Stroke-1" stroke={stroke} />
						<path d="M19.3200174,6.77848696 C18.7346261,6.77848696 18.1492348,7.00231304 17.7025391,7.4490087 L13.8353217,11.3162261 C12.9419304,12.2096174 12.9419304,13.6577913 13.8353217,14.5511826 C14.2820174,14.9978783 14.8674087,15.2217043 15.4528,15.2217043 C16.0381913,15.2217043 16.6235826,14.9978783 17.0702783,14.5511826 L20.9374957,10.6839652 C21.830887,9.79057391 21.830887,8.3424 20.9374957,7.4490087 C20.4908,7.00231304 19.9054087,6.77848696 19.3200174,6.77848696 M19.3200174,7.7350087 C19.6758435,7.7350087 20.0096696,7.87370435 20.2612348,8.12526957 C20.7806261,8.64370435 20.7806261,9.48831304 20.2612348,10.0077043 L16.3940174,13.8749217 C16.1424522,14.126487 15.8086261,14.2651826 15.4528,14.2651826 C15.0969739,14.2651826 14.7631478,14.126487 14.5115826,13.8749217 C13.9921913,13.3555304 13.9921913,12.5109217 14.5115826,11.992487 L18.3788,8.12526957 C18.6303652,7.87370435 18.9641913,7.7350087 19.3200174,7.7350087" id="Fill-3" fill="var(--accent)" />
					</g>
				</g>
			</g>
		</svg>
	);
};

export default NoComments;
