import React from "react";
import Hero from "../../gen-comp/Hero";
import { strings } from "../../i18n/config";

export default function SettingsPage() {
	return (
		<div data-testid="setting-container">
			<Hero title={strings("SettingsPage.hero_title")} pageHasTable />
		</div>
	);
}
