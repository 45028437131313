/* eslint-disable react/require-default-props */
/* eslint-disable max-classes-per-file */
import React from "react";
import { CIStyler, classNames, ScalerSize } from "../common";
import styles from "./ExpertiseTag.module.css";

class TagStyler extends CIStyler<any> {
	constructor(base: string) {
		super(`${styles.tag} ${base}`);
	}

	sizeClass = (size?: ScalerSize) => styles[size ? size.toString() : ScalerSize.M];
}

class PillStyler extends TagStyler {
	constructor() {
		super(styles.pill);
	}

	classes(data: ExpertiseTagProps, state: any | undefined, extraClasses: string) {
		return super.classes(data, state, extraClasses);
	}

	dynamicStyles(props: ExpertiseTagProps, _state: any) {
		return {
			...super.dynamicStyles(props),
		};
	}
}

class LabelStyler extends TagStyler {
	constructor() {
		super(styles.label);
	}

	classes(data: ExpertiseTagProps, state: any | undefined, extraClasses: string) {
		return super.classes(data, state, extraClasses);
	}

	dynamicStyles(props: ExpertiseTagProps) {
		return {
			...super.dynamicStyles(props),
		};
	}
}

class IconStyler extends TagStyler {
	constructor() {
		super(styles.icon);
	}

	classes(data: ExpertiseTagProps, state: any | undefined, extraClasses: string) {
		return super.classes(data, state, extraClasses);
	}
}
interface ExpertiseTagProps {
	/**
	 * tag title
	 * @default ""
	 * */
	title: string;
	/**
	 * onPress handler for tag press
	 * @default ""
	 * */
	onPress?: () => void;
	/**
	 * onPressRemoveButton handler for remove icon press
	 * @default ""
	 * */
	onPressRemoveButton?: () => void;
	/**
	 * Custom CSS class for icon
	 * @default ""
	 * */
	iconStyle?: string;
	/**
	 * Custom CSS class for main container
	 * @default ""
	 * */
	containerStyle?: string;
	/**
	 * Custom CSS class for tag container
	 * @default ""
	 * */
	buttonStyle?: string;
	/**
	 * Custom CSS class for tag title
	 * @default ""
	 * */
	titleStyle?: string;
	hasRated?: boolean;
	/**
	 * Tag Size
	 * @default ScalerSize.M
	 * */
	size?: ScalerSize;
	/**
	 * Icon in the right of the text in the tag
	 * */
	iconRight?: React.ReactNode,
	badge?: React.ReactNode,
	/**
	 * Icon in the left of the text in the tag
	 * */
	iconLeft?: React.ReactNode,
	/**
	 * Custom CSS class for icon
	 * @default ""
	 * */
	iconLeftStyle?: string;
	/**
	 * Optional count to show the number beside the name
	 * of the tag. Mainly used in filter news/blast tags
	 * */
	count?: string | number;
}

class ExpertiseTag extends React.Component<ExpertiseTagProps, any> {
	stylers: {
		pill: PillStyler,
		label: LabelStyler,
		icon: IconStyler
	};

	constructor(props: ExpertiseTagProps) {
		super(props);
		this.stylers = {
			pill: new PillStyler(),
			label: new LabelStyler(),
			icon: new IconStyler(),
		};
	}

	render() {
		const {
			title,
			onPress,
			onPressRemoveButton,
			iconStyle,
			buttonStyle,
			titleStyle,
			hasRated,
			size,
			iconRight,
			iconLeft,
			iconLeftStyle,
			containerStyle,
			badge,
			count,
		} = this.props;

		if (!title) {
			return null;
		}

		return (
			<div className={classNames(
				"mr-3 mb-2",
				hasRated ? "border-2 border-tag-green p-px rounded-full" : "",
				containerStyle,
			)}
			>
				<button
					type="button"
					ref={this.stylers.pill.ref()}
					className={this.stylers.pill.classes(
						this.props,
						{},
						classNames(
							"bg-gray-dark rounded-full",
							"min-w-fit",
							!hasRated ? "border border-tag-unrated" : "",
							iconRight ? "!pr-0.5" : "",
							iconLeft ? "!pl-0.5" : "",
							buttonStyle,
						),
					)}
					style={this.stylers.pill.dynamicStyles(this.props, {})}
					onClick={onPress}
				>
					{iconLeft ? (
						<div
							onClick={onPressRemoveButton}
							className={classNames(
								// @ts-ignore
								this.stylers.icon.classes(this.props),
								iconLeftStyle,
								// "absolute",
								"!flex items-center justify-center",
							)}
							style={this.stylers.icon.dynamicStyles(this.props)}
							aria-hidden
						>
							{iconLeft}
						</div>
					) : null}
					<div
						className={this.stylers.label.classes(
							this.props,
							{},
							classNames(
								"break-all relative",
								titleStyle,
							),
						)}
						style={this.stylers.label.dynamicStyles(this.props)}
					>
						{title}
					</div>
					{count && (
						<div
							style={{
								...this.stylers.label.dynamicStyles(this.props),
								textOverflow: "unset",
							}}
							className={this.stylers.label.classes(
								this.props,
								{},
								classNames(
									"break-all relative",
									titleStyle,
								),
							)}
						>
							{count}
						</div>
					)}
					{iconRight ? (
						<div
							onClick={onPressRemoveButton}
							className={classNames(
								// @ts-ignore
								this.stylers.icon.classes(this.props),
								iconStyle,
								// "absolute",
								"!flex items-center justify-center",
							)}
							style={this.stylers.icon.dynamicStyles(this.props)}
							aria-hidden
						>
							{iconRight}
						</div>
					) : null}
				</button>
				{badge}
			</div>
		);
	}
}

export default ExpertiseTag;
