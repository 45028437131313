// eslint-disable-next-line import/prefer-default-export
export enum ProfileSize {
	X_SMALL = "XS",
	XX_SMALL_WEB = "2XS_WEB",
	XX_SMALL = "2XS",
	X_SMALL_SEMI = "XSS",
	X_SMALL_MEDIUM = "XSM",
	SMALL = "S",
	SMALL_MEDIUM = "SM",
	MEDIUM = "M",
	LARGE = "L"
}
