import React, { SVGAttributes } from "react";

const NewPost = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { stroke = "var(--gray-dark)", fill } = props;
	return (
		<svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
			<g id="Web-Version" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Web-Version---Home-Ego" transform="translate(-29.000000, -286.000000)" stroke={stroke} fill={fill}>
					<g id="pop-up-copy-3" transform="translate(30.000000, 171.000000)">
						<path d="M17.83,123.831 L12.169,123.831 L12.169,118.169 C12.169,116.973 11.196,116 10,116 C8.803,116 7.83,116.973 7.83,118.169 L7.83,123.831 L2.169,123.831 C0.974,123.831 0,124.804 0,126 C0,127.196 0.974,128.169 2.169,128.169 L7.83,128.169 L7.83,133.831 C7.83,135.027 8.803,136 10,136 C11.196,136 12.169,135.027 12.169,133.831 L12.169,128.169 L17.83,128.169 C19.027,128.169 20,127.196 20,126 C20,124.804 19.027,123.831 17.83,123.831 Z" id="menu_post"/>
					</g>
				</g>
			</g>
		</svg>
	);
}

export default NewPost;
