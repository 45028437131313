import React, { SVGAttributes } from "react";

// eslint-disable-next-line import/prefer-default-export
const NoBlockedUsers = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--accent)" } = props;
	return (
		<svg
			width="80px"
			height="81px"
			viewBox="0 0 80 81"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<g id="Profile---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Blocked-Users-List" transform="translate(-148.000000, -161.000000)" stroke={stroke}>
					<g id="noBlockedUsers" transform="translate(148.000000, 161.000000)">
						<path
							d="M79.3936,20.414 C79.3936,31.413 70.4776,40.328 59.4786,40.328 C48.4806,40.328 39.5646,31.413 39.5646,20.414 C39.5646,9.416 48.4806,0.5 59.4786,0.5 C70.4776,0.5 79.3936,9.416 79.3936,20.414 Z"
							id="Stroke-1"
						/>
						<path
							d="M57.2002,29.0859 C57.2002,28.2779 57.4162,27.7049 57.8492,27.3689 C58.2822,27.0329 58.8052,26.8639 59.4212,26.8639 C60.0162,26.8639 60.5322,27.0329 60.9642,27.3689 C61.3962,27.7049 61.6132,28.2779 61.6132,29.0859 C61.6132,29.8739 61.3962,30.4399 60.9642,30.7869 C60.5322,31.1329 60.0162,31.3069 59.4212,31.3069 C58.8052,31.3069 58.2822,31.1329 57.8492,30.7869 C57.4162,30.4399 57.2002,29.8739 57.2002,29.0859 Z M57.7492,24.4999 L57.7492,23.5479 C57.7492,22.5479 57.9352,21.7129 58.3112,21.0389 C58.6852,20.3659 59.3542,19.6539 60.3152,18.9039 C61.2762,18.1749 61.9542,17.5679 62.3482,17.0879 C62.7432,16.6069 62.9402,16.0109 62.9402,15.2989 C62.9402,14.5689 62.6842,14.0119 62.1762,13.6269 C61.6662,13.2419 60.9402,13.0499 59.9992,13.0499 C59.1512,13.0499 58.3442,13.1759 57.5752,13.4249 C56.8052,13.6749 56.0372,13.9919 55.2682,14.3759 L53.9712,11.6659 C54.8542,11.1859 55.8162,10.7969 56.8542,10.4969 C57.8922,10.1999 59.0372,10.0509 60.2872,10.0509 C62.2282,10.0509 63.7272,10.5179 64.7852,11.4489 C65.8432,12.3819 66.3722,13.6069 66.3722,15.1269 C66.3722,15.9539 66.2412,16.6599 65.9822,17.2469 C65.7232,17.8329 65.3382,18.3759 64.8282,18.8759 C64.3192,19.3759 63.7092,19.9049 62.9972,20.4619 C62.4012,20.9239 61.9492,21.3179 61.6412,21.6449 C61.3352,21.9719 61.1222,22.2929 61.0082,22.6099 C60.8922,22.9279 60.8342,23.3159 60.8342,23.7779 L60.8342,24.4999 L57.7492,24.4999 Z"
							id="Stroke-3"
						/>
						<path
							d="M68.0225,38.4462 C68.3385,40.2502 68.5005,42.0932 68.5005,43.9642 C68.5005,61.3332 54.3695,75.4642 37.0005,75.4642 C30.0335,75.4642 23.4275,73.2302 17.8975,69.0042 L16.5365,67.9642 L47.8905,36.6092 M44.2185,33.2102 L13.0005,64.4282 L11.9595,63.0662 C7.7335,57.5352 5.5005,50.9292 5.5005,43.9642 C5.5005,26.5942 19.6305,12.4642 37.0005,12.4642 C38.3835,12.4642 39.7535,12.5512 41.1035,12.7262 M43.8285,8.1042 C41.6155,7.6832 39.3335,7.4642 37.0005,7.4642 C16.8745,7.4642 0.5005,23.8382 0.5005,43.9642 C0.5005,64.0902 16.8745,80.4642 37.0005,80.4642 C57.1265,80.4642 73.5005,64.0902 73.5005,43.9642 C73.5005,41.0422 73.1555,38.2002 72.5035,35.4742"
							id="Stroke-5"
						/>
						<path
							d="M39.4922,20.7109 C38.6972,20.5169 37.8662,20.4139 37.0122,20.4139 C31.2302,20.4139 26.5242,25.1199 26.5242,30.9039 C26.5242,35.9529 30.1092,41.2869 34.8662,42.5629"
							id="Stroke-7"
						/>
						<path
							d="M53.9473,60.6347 L53.9413,59.8017 C53.1563,49.8757 48.7533,45.8577 46.1983,44.3357 L45.5743,43.9637 L44.9433,44.3787 L37.0113,50.3267 L35.3893,49.1107 L22.1203,62.3797 C26.2203,65.5287 31.3883,67.4137 37.0143,67.4137 C43.6173,67.4137 49.5943,64.8227 53.9473,60.6347 Z"
							id="Stroke-9"
						/>
						<path
							d="M29.0811,44.3788 L28.4501,43.9638 L27.8261,44.3358 C25.5291,45.7038 21.7451,49.1078 20.4331,56.9958 L31.3491,46.0798 L29.0811,44.3788 Z"
							id="Stroke-11"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default NoBlockedUsers;
