import * as React from "react"
import {SVGAttributes} from "react";

const SvgComponent = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--gray-dark)" } = props;
	return (
		<svg width={20} height={20} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g stroke={stroke} fill="none" fillRule="evenodd">
				<path d="M19.5 14.092a5 5 0 0 1-5 5 5 5 0 1 1 0-10 5 5 0 0 1 5 5Z" />
				<path d="M14.5 10.575v3.517l1.36 1.676" />
				<path d="M10.04 16.352A8 8 0 0 1 .5 8.5a8 8 0 1 1 15.958.818" />
				<path d="M1.14 11.106s2.68 1.177 7.36 1.177c.466 0 .912-.011 1.338-.032M1.28 5.742s2.6.971 7.138.971 7.136-.971 7.136-.971" />
				<path d="M6.657.907s-2.728 8.176 0 15.185M10.782.907s1.345 4.032 1.201 8.859" />
			</g>
		</svg>
	)
}

export default SvgComponent
