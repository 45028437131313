import React, {
	useLayoutEffect, useMemo, useRef, useState,
} from "react";
import { BOOST_MODAL_HEIGHT, BOOST_MODAL_WIDTH, classNames } from "../../common";
import styles from "../indicator-html.module.css";
import { CreedSelectorContent, CreedSelectorContentProps } from "./creed-selector-content";
import { ModalPopup, ModalState } from "../modal-popup";
import { SvgRatingSelectorProps } from "../svg-rating-selector";

interface CreedSelectorModalProps extends CreedSelectorContentProps, Omit<SvgRatingSelectorProps, "size"> {
	pillPosition: {
		x: number,
		y: number,
		elem_x: number,
		elem_y: number,
	};
	modalPosition?: {
		top?: number,
		left?: number,
		right?: number,
		bottom?: number,
	}
	closeWebModal?: () => void;
}

// eslint-disable-next-line import/prefer-default-export
export const CreedSelectorModal: React.FC<CreedSelectorModalProps> = (props: CreedSelectorModalProps) => {
	const {
		tag,
		messages,
		rating,
		onCancel,
		onSelected,
		onRatingHover,
		anchor,
		egoInfoProps,
		boostTrayProps,
		pillPosition,
		tagDataProvider,
		modalPosition: modalPositionProps,
		defaultTab,
		closeWebModal,
	} = props;

	const [popupState, setPopupState] = useState<ModalState>(ModalState.Active);
	const contentRef = useRef<HTMLDivElement | null>(null);
	const [modalDimensions, setModalDimensions] = useState<DOMRect | undefined>(undefined);

	useLayoutEffect(() => {
		setModalDimensions(contentRef.current?.getBoundingClientRect());
	}, [contentRef]);

	const modalPosition = useMemo(() => {
		if (modalPositionProps) return modalPositionProps;
		// There is a delay when using ?? 0 so width will have to be a static
		// since it takes time to calculate the modal width since it is not rendered
		const modalWidth = (modalDimensions?.width ?? BOOST_MODAL_WIDTH);
		/**
		 * BOOST_MODAL_HEIGHT is the height of the modal.
		 * Getting dynamic height of the content is difficult because
		 * boost tab has more height than rating and initially rating
		 * tab is open.
		 * BOOST_MODAL_HEIGHT => we are considering the boost tab's height, so it is
		 * fit perfectly.
		 * We cannot measure height due to animation, the getBoundingClientRect always gives 74
		 * */
		const goesOutOfY = window.innerHeight < pillPosition.elem_y + BOOST_MODAL_HEIGHT;
		const goesOutOfX = window.innerWidth < pillPosition.elem_x + modalWidth;
		const goesOutOfXAndY = goesOutOfX && goesOutOfY;
		if (goesOutOfXAndY) {
			return {
				left: -(pillPosition.elem_x + modalWidth - window.innerWidth + 12), // 12 is padding
				bottom: 32,
			};
		}
		if (goesOutOfY) {
			return {
				bottom: 32,
			};
		}
		if (goesOutOfX) {
			return {
				left: -(pillPosition.elem_x + (modalDimensions?.width ?? 0) - window.innerWidth + 12), // 12 is padding
			};
		}
		return {};
	}, [pillPosition, modalPositionProps, contentRef, modalDimensions]);

	const closeDown = (success: boolean = false) => {
		setPopupState(ModalState.Closing);
		onCancel();
	};

	/**
	 * This is called by the modal, on state change
	 * @param state
	 */
	const onModalStateChange = (state: ModalState) => {
		if (popupState === state) {
			return;
		}

		// Logger.debug("ModalStateChange:" + state);
		setPopupState(state);
	};

	return (
		<ModalPopup
			onModalStateChange={onModalStateChange}
			onClose={closeDown}
			style={modalPosition}
		>
			<div
				ref={contentRef}
				className={classNames(
					styles.selectorBgGlassEffect,
					"rounded-3xl p-4",
				)}
			>
				<CreedSelectorContent
					tag={tag}
					messages={messages}
					rating={rating ?? 0}
					onCancel={onCancel}
					onSelected={onSelected}
					onRatingHover={onRatingHover}
					anchor={anchor}
					showEgoModal={false}
					egoInfoProps={egoInfoProps}
					boostTrayProps={boostTrayProps}
					tagDataProvider={tagDataProvider}
					defaultTab={defaultTab}
					closeWebModal={closeWebModal}
				/>
			</div>
		</ModalPopup>
	);
};
