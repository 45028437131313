/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import { getRedirectResult, onAuthStateChanged } from "firebase/auth";
import packageJson from "../../../package.json";
import {
	auth, signInWithGoogleRedirect, signOutAccount,
} from "../../utils/Firebase";
import { dbg, mydbg } from "../../utils/utils";
import { strings } from "../../i18n/config";
import { googleLoginRequest } from "./LoginRequests";
import { REQ_OTP_AUTH, successRETCD } from "../../api";
import {
	AUTH_2FA_SCREEN, QR_CODE_2FA_SCREEN,
} from "../../routes/constants";
import Loader from "../../gen-comp/Loader";
import Logo from "../../gen-comp/Logo";

export default function LoginScreen() {
	const navigate = useNavigate();
	const [isClicked, setIsClicked] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [loading, setIsLoading] = useState(JSON.parse(localStorage.getItem("loading") || "false"));

	const loginWithGoogle = async () => {
		setErrorMessage("");
		setIsClicked(true);
		localStorage.setItem("loading", "true");
		await signInWithGoogleRedirect();
	};

	const unsubscribe = onAuthStateChanged(auth, (user) => {
		if (user) {
			user?.providerData?.forEach(async (profile: { email: any; }) => {
				if (dbg) mydbg("Got the user from firebase", profile);
				const res = await googleLoginRequest({ email: profile?.email || "", uuid: user?.uid });
				const userDetails = {
					email: profile?.email,
					uuid: user?.uid,
				};
				if (res && res.code === 200) {
					localStorage.setItem("userDetails", JSON.stringify(userDetails));
					if (res.response.retcd === successRETCD && res.response.authURL) {
						localStorage.setItem("authURL", JSON.stringify(res.response.authURL));
						navigate(QR_CODE_2FA_SCREEN);
					} else if (res.response.retcd === REQ_OTP_AUTH) {
						navigate(AUTH_2FA_SCREEN);
					} else {
						setErrorMessage(strings("LoginScreen.unauthorized_user_message"));
					}
				} else if (res?.error?.data?.info === REQ_OTP_AUTH) {
					localStorage.setItem("userDetails", JSON.stringify(userDetails));
					navigate(AUTH_2FA_SCREEN);
				} else {
					setErrorMessage(strings("LoginScreen.unauthorized_user_message"));
				}
				signOutAccount();
				setIsLoading(false);
				localStorage.setItem("loading", "false");
				return null;
			});
			setIsClicked(false);
		}
	});

	useEffect(() => {
		if (getRedirectResult) {
			getRedirectResult(auth)?.then((result) => {
				if (!result) {
					setIsLoading(false);
					localStorage.setItem("loading", "false");
				}
			}).catch(() => {
				setIsLoading(false);
				localStorage.setItem("loading", "false");
			});
		}
		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, []);

	return (
		<>
			<div data-testid="login-with-google" className="bg-background-dark flex flex-col items-center justify-center inset-0 absolute">
				<div className="flex flex-col pb-10 pt-6 px-3 bg-white shadow-xl items-center google-login-container">
					<div className="items-center text-white flex justify-center pb-4">
						<Logo />
					</div>
					<div className="items-center py-1 mt-1 px-10">
						<h1 className="active-text text-primary">{strings("LoginScreen.login_message")}</h1>
					</div>
					<button
						data-testid="login-with-google-button"
						type="button"
						disabled={isClicked || loading}
						onClick={loginWithGoogle}
						className="font-bold shadow-sm py-1 my-4 text-gray-800 flex w-fit px-3 mt-10 hover:bg-grey
                   hover:bg-slate-100 active:bg-slate-50 disabled:border-slate-200
                  disabled:bg-slate-50 disabled:text-slate-500 disabled:shadow-none
                  items-center justify-center transition-all duration-300 ease-in-out shadow"
					>
						<div className="bg-transparent p-2">
							<svg className="w-4" viewBox="0 0 533.5 544.3">
								<path
									d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7
                    63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z"
									fill="#4285f4"
								/>
								<path
									d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4
                      16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z"
									fill="#34a853"
								/>
								<path
									d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z"
									fill="#fbbc04"
								/>
								<path
									d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0
                      169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z"
									fill="#ea4335"
								/>
							</svg>
						</div>
						<span className="ml-1 small-text">
							{strings("LoginScreen.sign_in_with_google")}
						</span>
					</button>
					<div className="p-2 h-5 item-center justify-center">
						<span className="text-sm font-normal text-center text-red-600">
							{errorMessage}
						</span>
					</div>
				</div>
				<span className="absolute items-center bottom-5 xs-text">
					{strings("LoginScreen.credo_version")}
					{" "}
					{packageJson.version}
				</span>
				<Loader loading={loading} />
			</div>
		</>
	);
}
