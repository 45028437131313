import { TagMessages } from "../credo-tag";

export interface RewardsNotificationMsgs {
	CongratsReward: string;
	Badge: string;
	Earned: string;
	For: string;
	Point: string;
	Points: string;
	Edited: string;
}

export interface SharedActivitiesMsgs {
	StartedFollowing: string;
	ConnectedWith: string;
	JustJoined: string;
}

export interface UxpRatingCardMessages {
	SwitchToCredoModeToRate: string;
	RateYourConnExpertise: string;
	Dismiss: string;
	tagMessages: TagMessages;
}

export interface SuggestionConnCardMessages {
	IsOnCredo: string;
	SwitchModeToConnect: string;
	Connect: string;
	Requested: string;
	Dismiss: string;
	SuggestedConnection: string;
}

export interface ExploreCommCardMessages {
	Follow: string;
	isFollowed: string;
	Dismiss: string;
}

export enum ArrowType {
	Next = "next",
	Previous = "previous"
}

export interface TagInfoProps {
	onPressTagInfoBubble: () => void;
	onPressCredoGraphText: () => void;
}
