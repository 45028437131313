import React from "react";
import omit from "lodash/omit";
import classNames from "../utils/classNames";
import "./css/input.css";

interface InputInterface extends React.ComponentProps<"input"> {
	/**
	 * Optional unique id for component good for testing and keeping
	 * things unique at the time of iterable.
	 * @default null
	 * */
	id?: string
	/**
	 * Input wrapper div class
	 * @default: ""
	 * */
	wrapperClassName?: string;
	/**
	 * Error message while validation, if present input field will
	 * have error border and message underneath input field
	 * @default: ""
	 * */
	error?: string;
	/**
	 * Success message while validation, if present input field will
	 * have success border and message underneath input field
	 * @default: ""
	 * */
	success?: string;
	disabled?: boolean;
}

const Input: React.FC<InputInterface> = (props: InputInterface) => {
	const {
		onChange,
		value,
		wrapperClassName,
		error,
		success,
		id,
		placeholder,
		disabled,
	} = props;

	/**
	 * omit the props which we are adding for our custom input
	 * field wrapper and pass the new filtered props to the input
	 * field so that we will not get any errors in the console.
	 * */
	const inputProps = omit(
		props,
		[
			"id",
			"error",
			"inputClassName",
			"wrapperClassName",
			"leftIcon",
			"rightIcon",
			"onChange",
			"value",
			"rows",
			"cols",
			"wrap",
			"textLength",
			"isMultiline",
		],
	);

	return (
		<div
			className={`mb-2 ${wrapperClassName}`}
			id={id}
			data-testid={id}
			key={id}
		>
			<input
				aria-label="input"
				value={value}
				type="search"
				disabled={disabled}
				className={classNames(
					"w-full",
					"form-control block px-3 py-1.5 text-sm font-normal text-gray-700 bg-white bg-clip-padding h-10",
					"border border-solid border-gray-300 rounded-md transition ease-in-out m-0 normal-text-style",
					error ? "border-error" : "",
					success ? "border-green-400" : "",
					"focus:text-gray-700 focus:bg-white focus:border-primary focus:outline-none",
				)}
				placeholder={placeholder}
				onChange={onChange}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...inputProps}
			/>
			{error && (
				<span className="text-error text-sm">
					{error}
				</span>
			)}
			{success && (
				<span className="text-green-400 text-sm">
					{success}
				</span>
			)}
		</div>
	);
};

export default Input;

Input.defaultProps = {
	wrapperClassName: "",
	error: "",
	success: "",
	id: "",
	disabled: false,
};
