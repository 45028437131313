/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGAttributes } from "react";

const EditPost = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--gray-dark)",
	} = props;
	return (
		<svg
			width="19px"
			height="19px"
			viewBox="0 0 19 19"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<title>edit</title>
			<g id="Home-/-Feed---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Home---Export" transform="translate(-82.000000, -133.000000)" stroke={stroke}>
					<g id="edit_icon" transform="translate(83.000000, 133.000000)">
						<path d="M10,0.5 C10.4142136,0.5 10.7892136,0.667893219 11.0606602,0.939339828 C11.3321068,1.21078644 11.5,1.58578644 11.5,2 L11.5,2 L11.5,13.7928932 L10,15.2928932 L8.5,13.7928932 L8.5,2 C8.5,1.58578644 8.66789322,1.21078644 8.93933983,0.939339828 C9.21078644,0.667893219 9.58578644,0.5 10,0.5 Z" id="Rectangle" transform="translate(10.000000, 8.000000) rotate(45.000000) translate(-10.000000, -8.000000) " />
						<path d="M9.5,3 L1,3 C0.44771525,3 -6.76353751e-17,3.44771525 0,4 L0,17 C6.76353751e-17,17.5522847 0.44771525,18 1,18 L14,18 C14.5522847,18 15,17.5522847 15,17 L15,8.5 L15,8.5" id="Path-3" strokeLinecap="round" />
					</g>
				</g>
			</g>
		</svg>
	);
};

export default EditPost;
