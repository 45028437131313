export default class CfgMgr {
	static CFG_FLAVOR = process.env.REACT_APP_CFG_FLAVOR;

	static cfg_ws_dont_use_ssl = process.env.REACT_APP_CFG_ws_dont_use_ssl;

	static CFG_DEBUG_ENABLED = JSON.parse(process.env.REACT_APP_CFG_DEBUG_ENABLED!);

	// firebase javascript sdk
	static cfg_firebase_js_sdk_apiKey = process.env.REACT_APP_cfg_firebase_js_sdk_apiKey;

	static cfg_firebase_js_sdk_authDomain = process.env.REACT_APP_cfg_firebase_js_sdk_authDomain;

	static cfg_firebase_js_sdk_databaseURL = process.env.REACT_APP_cfg_firebase_js_sdk_databaseURL;

	static cfg_firebase_js_sdk_projectId = process.env.REACT_APP_cfg_firebase_js_sdk_projectId;

	static cfg_firebase_js_sdk_storageBucket = process.env.REACT_APP_cfg_firebase_js_sdk_storageBucket;

	static cfg_firebase_js_sdk_messagingSenderId = process.env.REACT_APP_cfg_firebase_js_sdk_messagingSenderId;

	static cfg_firebase_js_sdk_appId = process.env.REACT_APP_cfg_firebase_js_sdk_appId;

	static SERVER_URL = process.env.REACT_APP_SERVER_URL;

	static cfg_imageStorage_path = process.env.REACT_APP_cfg_imageStorage_path;

	static FAVICON = process.env.REACT_APP_favicon;
}
