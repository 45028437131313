import React, { useEffect, useRef, useState } from "react";
import { useMeasure } from "@credo/utilities";
import { classNames } from "../common";

export interface AccordionProps {
	/**
	 * Optional unique id for component good for testing and keeping
	 * things unique at the time of iterable.
	 * @default null
	 * */
	id?: string
	/**
	 * Content of accordion, component or element should be passed
	 * what content should be shown when accordion is open.
	 * */
	content: any;
	/**
	 * Header of accordion. Clickable section with title where the
	 * title of the accordion will be displayed.
	 * */
	header: any;
	/**
	 * Keeping the accordion open by default
	 * @default false
	 * */
	defaultOpen?: boolean;
	/**
	 * disable the accordion control to open or to close.
	 * if you need to keep it open always
	 * @see defaultOpen
	 * */
	disabled?: boolean;
}

const Accordion: React.FC<AccordionProps> = (props: AccordionProps) => {
	const {
		content,
		header,
		defaultOpen,
		id,
		disabled,
	} = props;
	const [open, setOpen] = useState(defaultOpen);
	const contentRef = useRef(null);
	const [contentChildRef, { height }] = useMeasure();

	/**
	 * The list of opened dropdown inside the accordion content was hidden due
	 * to overflow property. Changing it right away after the state change was
	 * hampering the transition. This useEffect will wait for the transition
	 * to be over and then change the overflow property.
	 * */
	useEffect(() => {
		if (open) {
			setTimeout(() => {
				if (contentRef.current) {
					// @ts-ignore
					contentRef.current.style.overflow = "inherit";
					// @ts-ignore
					contentRef.current.style.height = "auto";
				}
			}, 500);
		} else if (contentRef.current) {
			// @ts-ignore
			contentRef.current.style.overflow = "hidden";
		}
	}, [open]);

	useEffect(() => {
		setOpen(defaultOpen);
	}, [defaultOpen]);

	return (
		<div
			id={id}
			data-testid={id}
		>
			<button
				id="accordion-header-button"
				data-testid="accordion-header-button"
				type="button"
				className={classNames(
					"w-full text-base font-normal text-gray-900 rounded-lg transition duration-75",
					"flex items-center justify-between",
					"hover:shadow-lg hover:bg-opacity-40",
				)}
				onClick={() => {
					if (!disabled) {
						setOpen(!open);
					}
				}}
				aria-controls="dropdown-example"
				data-collapse-toggle="dropdown-example"
			>
				{header}
				{open
					? (
						<div />
					)
					: (
						<div />
					)}
			</button>
			<div
				id="accordion-content-wrapper"
				data-testid="accordion-content-wrapper"
				ref={contentRef}
				className={classNames(
					"h-auto",
					"relative overflow-hidden transition-all duration-500",
					open ? "space-y-2 visible py-2" : "invisible",
				)}
				style={{
					maxHeight: open ? `${height}px` : "0",
				}}
			>
				{/* @ts-ignore */}
				<div ref={contentChildRef} id="accordion-content-child">
					{content}
				</div>
			</div>
		</div>
	);
};

Accordion.defaultProps = {
	defaultOpen: false,
	id: undefined,
	disabled: false,
};

export default Accordion;
