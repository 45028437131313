/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGAttributes } from "react";

const RadioButtonSelected = (props: SVGAttributes<SVGElement>) => {
	return (
		<svg
			width="24px"
			height="24px"
			viewBox="0 0 24 24"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<g id="Symbols---CT---190712" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Cells/Editing/Select" transform="translate(-11.000000, -11.000000)">
					<g id="radioButtonSelected_ego" transform="translate(12.000000, 12.000000)">
						<circle id="Circle" stroke="#00A0FF" cx="11" cy="11" r="11" />
						<circle id="Circle" fill="#00A0FF" cx="11" cy="11" r="7" />
					</g>
				</g>
			</g>
		</svg>
	);
};

export default RadioButtonSelected;
