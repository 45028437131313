/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGAttributes } from "react";

const CancelRequest = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--gray-dark)",
	} = props;
	return (
		<svg
			width="20px"
			height="20px"
			viewBox="0 0 20 20"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<title>cancel_request</title>
			<g id="Streams---CT---190723" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-icons" transform="translate(-375.000000, -328.000000)" stroke={stroke}>
					<g id="cancel_request" transform="translate(376.000000, 329.000000)">
						<path
							d="M3.375,5.375 L3.375,4.375 C3.375,3.82271525 3.82271525,3.375 4.375,3.375 L13.625,3.375 C14.1772847,3.375 14.625,3.82271525 14.625,4.375 L14.625,20.375 C14.625,20.9272847 14.1772847,21.375 13.625,21.375 L4.375,21.375 C3.82271525,21.375 3.375,20.9272847 3.375,20.375 L3.375,19.375 L3.375,19.375"
							id="Rectangle"
							strokeLinecap="round"
							transform="translate(9.000000, 12.375000) rotate(90.000000) translate(-9.000000, -12.375000) "
						/>
						<g id="icon-cancel-copy-2" transform="translate(2.000000, 0.000000)">
							<path
								d="M13.5152764,9.56464385 C13.8281441,8.77046447 14,7.90530178 14,7 C14,3.13400675 10.8659932,0 7,0 C3.13400675,0 0,3.13400675 0,7 C0,7.9081318 0.172932059,8.77587315 0.48766181,9.57208969"
								id="Oval"
							/>
							<g id="Group-8" transform="translate(5.000000, 4.888889)" strokeLinecap="round">
								<line x1="0" y1="0.111111111" x2="4" y2="4.11111111" id="Line-5" />
								<line
									x1="0"
									y1="0.111111111"
									x2="4"
									y2="4.11111111"
									id="Line-5"
									transform="translate(2.000000, 2.111111) scale(-1, 1) translate(-2.000000, -2.111111) "
								/>
							</g>
						</g>
						<polyline
							id="Path-4"
							strokeLinecap="round"
							transform="translate(9.000000, 11.250000) scale(1, -1) translate(-9.000000, -11.250000) "
							points="0.140625 14.625 9 7.875 17.859375 14.625"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default CancelRequest;
