import React, { SVGAttributes } from "react";
import omit from "lodash/omit";

interface VoltsIconProps extends SVGAttributes<SVGElement> {
	borderColor?: string;
}

const VoltsIcon = (props: VoltsIconProps) => {
	const {
		borderColor = "#FF8C01",
	} = props;

	const newProps = omit(props, ["borderColor"]);

	return (
		<svg
			width="25px"
			height="25px"
			viewBox="0 0 25 25"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...newProps}
		>
			<defs>
				<linearGradient id="volts-icon-d" x1="50%" x2="50%" y1="0%" y2="100%">
					<stop offset="0%" stopColor="#0F4" />
					<stop offset="38.448%" stopColor="#FFAD00" />
					<stop offset="100%" stopColor="#422200" />
				</linearGradient>
				<circle id="volts-icon-a" cx={12.5} cy={12.5} r={12.5} />
				<path
					id="volts-icon-c"
					d="M11.511 0 1.397 8.802h5.095l-1.688 8.802L14.94 8.802H9.807z"
				/>
			</defs>
			<g fill="none" fillRule="evenodd">
				<mask id="volts-icon-b" fill="#fff">
					<use xlinkHref="#volts-icon-a" />
				</mask>
				<circle
					cx={12.5}
					cy={12.5}
					r={12.188}
					stroke={borderColor}
					strokeWidth={0.625}
				/>
				<g mask="url(#volts-icon-b)">
					<use
						xlinkHref="#volts-icon-c"
						fill="url(#volts-icon-d)"
						stroke="#FF8C01"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={0.625}
						transform="translate(4 4)"
					/>
				</g>
			</g>
		</svg>
	);
};

export default VoltsIcon;
