import React, { SVGAttributes } from "react";
import omit from "lodash/omit";
import { cu } from "@credo/utilities";

// eslint-disable-next-line no-undef
interface IconInterface extends SVGAttributes<HTMLOrSVGElement> {
	/**
	 * Icon component which needs to be displayed.
	 * */
	icon: any;
	/**
	 * Set True to display credo version of the icon
	 * with credo primary color.
	 * @default false
	 * */
	isCredoMode?: boolean;
  /**
   * Set True if the icon color must be filled in the icon.
	 * Remove stroke if set True.
	 * If set True and isCredoMode is false, need to pass the color which needs to be filled
	 * @default false
   * */
  hasFill?: boolean;
  /**
   * Set Color of icon
	 * @default ""
   * */
  color?: string;
	/**
	 * Set title of the icon which will be shown when you hover on the icon.
	 * @default ""
	 * */
	title?: string;
}

// eslint-disable-next-line import/prefer-default-export
export const SvgIcon: React.FC<IconInterface> = (props: IconInterface) => {
	const CREDO_COLOR = "#00A0FF";
	const {
		icon,
		isCredoMode = false,
		hasFill = false,
		color = "",
		title = "",
	} = props;
	if (!icon) return null;
	let svgProps = omit(props, ["icon", "isCredoMode", "hasFill"]);

	if (isCredoMode) {
		if (hasFill) {
			svgProps = {
				...svgProps,
				fill: CREDO_COLOR,
				stroke: "none",
			};
		} else {
			svgProps = {
				...svgProps,
				stroke: CREDO_COLOR,
			};
		}
	} else if (cu.isSet(color)) {
		if (hasFill) {
			svgProps = {
				...svgProps,
				fill: color,
				stroke: "none",
			};
		} else {
			svgProps = {
				...svgProps,
				stroke: color,
			};
		}
	}

	if (cu.isSet(title)) {
		const propsWithoutClassName = omit(svgProps, "className");
		return (
			<div title={title} className={svgProps.className}>
				<props.icon {...propsWithoutClassName} />
			</div>
		);
	}

	return (
		<props.icon {...svgProps} />
	);
};

SvgIcon.defaultProps = {
	isCredoMode: false,
	hasFill: false,
	color: "",
	title: "",
};
