import * as React from "react"
import { SVGAttributes } from "react";

const SvgComponent = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { fill = "var(--volts-wallet)" } = props;
	return (
		<svg viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
			<g id="icon---bitcoin" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<path
					d="M15.7605973,9.93537754 C14.6921056,14.221001 10.3508415,16.8291348 6.06398323,15.7605389 C1.77900015,14.691948 -0.829453703,10.35115 0.239635586,6.06565154 C1.30774977,1.77942813 5.64891386,-0.82908069 9.93427198,0.239385183 C14.2208802,1.30787855 16.8290591,5.64940412 15.7605973,9.93537754 Z"
					id="Path"
					fill={fill}
					fillRule="nonzero"
				/>
				<path
					d="M11.5277173,6.86032161 C11.6869118,5.79587821 10.8762727,5.22360426 9.76775418,4.84190913 L10.1274176,3.39989308 L9.24915124,3.18114443 L8.89926133,4.58506073 C8.66876169,4.52758608 8.43143632,4.47338642 8.19631118,4.41953675 L8.54887387,3.00634552 L7.67145508,2.78759688 L7.3115666,4.22911043 C7.12062119,4.1856632 6.93310114,4.14256347 6.75108168,4.09741375 L6.75205679,4.09288878 L5.54147737,3.79064065 L5.30792741,4.72813484 C5.30792741,4.72813484 5.95924703,4.87730891 5.94544556,4.88658386 C6.30095856,4.9753333 6.36529044,5.21055685 6.35456429,5.39708069 L5.94497051,7.0398955 C5.96949813,7.04612046 6.00125152,7.0551454 6.03628027,7.06907032 C6.00697714,7.06187036 5.9757238,7.05379541 5.94349535,7.04612046 L5.36945898,9.34725619 C5.32587932,9.45515552 5.21564254,9.61725451 4.96706597,9.55575489 C4.97586691,9.56842981 4.32907276,9.39655588 4.32907276,9.39655588 L3.89322617,10.4014496 L5.0356733,10.6863479 C5.24834604,10.73957 5.4566183,10.7954722 5.6614902,10.8477219 L5.29817636,12.3067128 L6.1749701,12.5254615 L6.53475856,11.0823454 C6.77428416,11.147295 7.00683402,11.2073446 7.23440835,11.2637443 L6.87584502,12.7000104 L7.75363886,12.918759 L8.1169527,11.4629681 C9.61394023,11.7461663 10.7395331,11.631892 11.2129837,10.2786504 C11.5948495,9.18855717 11.1941817,8.55991107 10.4067475,8.14951361 C10.9802838,8.01681444 11.412455,7.63961678 11.5277173,6.86032161 L11.5277173,6.86032161 Z M9.52212792,9.67245417 C9.25062389,10.7625224 7.41532769,10.1734261 6.82006406,10.025477 L7.3021656,8.09333646 C7.89720421,8.24178804 9.80510817,8.53573622 9.52212792,9.67245417 L9.52212792,9.67245417 Z M9.79340692,6.84457171 C9.54580545,7.83601556 8.01794212,7.33236868 7.52241414,7.20884195 L7.95946086,5.45640532 C8.45511385,5.57980456 10.051487,5.81040312 9.79340692,6.84457171 Z"
					id="Shape"
					fill="#000000"
					fillRule="nonzero"
				/>
			</g>
		</svg>
	);
}

export default SvgComponent
