import * as React from "react"
import {SVGAttributes} from "react";

const SvgComponent = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { stroke = "#96A5B9" } = props;
	return (
		<svg width={10} height={15} viewBox="0 0 10 15" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="m8.5 13.5-7-5.951 7-6.049"
				stroke={stroke}
				strokeWidth={2}
				fill="none"
				fillRule="evenodd"
				strokeLinecap="round"
			/>
		</svg>
	)
}

export default SvgComponent
