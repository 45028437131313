import { useEffect, useRef } from "react";
import { LogMgr } from "../utils";

/**
 * A hook to check which prop/state is making
 * the component re-render and with what values
 *
 * @param props {object} mostly an object of
 * states which you want to observe
 * @param componentName {string} name of the
 * component to distinguish if there are more
 * components you are comparing
 * */
// eslint-disable-next-line import/prefer-default-export
export function useTraceUpdate(props: any, componentName: string) {
	const prev = useRef(props);
	useEffect(() => {
		const changedProps = Object.entries(props).reduce((ps: any, [k, v]) => {
			if (prev.current[k] !== v) {
				// eslint-disable-next-line no-param-reassign
				ps[k] = [prev.current[k], v];
			}
			return ps;
		}, {});
		if (Object.keys(changedProps).length > 0) {
			LogMgr.mydbg(`${componentName} Changed props:`, JSON.stringify(changedProps));
		}
		prev.current = props;
	});
}
