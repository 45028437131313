import getQueryFields from "../../utils/getQueryFields";
import { REPORTED_PROBLEM_POST_TYPE, REPORTED_STREAM_ID } from "../../routes/constants";
import { PAGINATION_LIMIT } from "../../utils/constants";
import { TableHeader } from "../../utils/types";

export interface GetReportedProblemsVariables {
	headers: TableHeader[];
	offset: number;
}

export const getReportedProblemsData = (variables: GetReportedProblemsVariables) => {
	const {
		offset, headers,
	} = variables;

	return `query {
			stream(
				first: 1,
				streamId: "${REPORTED_STREAM_ID}",
			) {
				posts(
					postType: "${REPORTED_PROBLEM_POST_TYPE}",
					orderBy: [creat_ts_desc],
					first: ${PAGINATION_LIMIT},
					offset: ${offset},
				)	{
					${getQueryFields(headers)},
					src_prof_id,
					evt_postUuid,
					src_streamId,
					reported_user_id,
				}
			}
		}`
		.replaceAll("\t", "")
		.replaceAll("\n", "");
};
