export interface Mixin<Original> {
	original: (of?: Original) => Original;
}

/**
 * Utility class to help with mixin functionality
 *
 */
export const Mixer = {
	/**
	 * Mixins the directly define functions of sources into the target *object*
	 *
	 * For use with objects, not prototypes, that requires
	 * @see Mixer.mixinToPrototype
	 *
	 * @see Mixin.test.ts for details of usage
	 *
	 * @param target
	 * @param sources
	 */
	mixinToObject: <Target extends object>(target: Target, ...sources: object[]): Target => {
		if (!sources) return target;
		// @ts-ignore
		const original: Target = {};

		for (let i in sources) {
			let source = sources[i];
			Mixer.assignEach(
				target,
				source,
				(key, targetVal, sourceVal) => {
					if (typeof targetVal === "function") {
						Object.defineProperty(original, key, {
							enumerable: true,
							configurable: true,
							writable: true,
							value: (targetVal as Function).bind(target)
						});

					}
					return sourceVal;
				}
			);
		}

		let originalFn = () => original;
		// @ts-ignore
		target.original = originalFn.bind(target);

		return target;
	},

	assignEach: <Target extends object>(
		target: Target,
		source: object,
		transform: (key: string, tVal: any, sVal: any) => any /*resulting val*/,
		rebind: boolean = false): Target => {
		let key: string;
		for (key in source) {
			if (Object.hasOwn(source, key)) {
				let source_pd = Object.getOwnPropertyDescriptor(source, key);
				if (!source_pd) continue;
				let source_val = source_pd.value;

				if (typeof source_val !== "function") continue;

				// @ts-ignore
				let target_val = target[key];

				let res_val = transform(key, target_val, source_val);

				if (typeof res_val === "function") {
					Object.defineProperty(target, key, {
						enumerable: true,
						configurable: true,
						writable: true,
						value: rebind ? (res_val as Function).bind(target) : res_val
					});
				}

			}

		}
		return target;
	},
	/**
	 * Assigns all ownProperties from sources to the target
	 * Usage:
	 * to modify a class:
	 *
	 * Mixin.all(MyObject.prototype, <InterfaceContainingOverrides>{ method(){...};})
	 *
	 * obviously this works for objects too
	 *
	 * @param target
	 * @param sources
	 */
	mixinToProtype: <Target extends object>(target: Target, ...sources: object[]): Target => {
		if (!sources) return target;
		// @ts-ignore
		const original: Target = {};

		for (let i in sources) {
			let source = sources[i];
			Mixer.assignEach(
				target,
				source,
				(key, targetVal, sourceVal) => {
					if (typeof targetVal === "function") {
						Object.defineProperty(original, key, {
							enumerable: true,
							configurable: true,
							writable: true,
							value: targetVal
						});

					}
					return sourceVal;
				}
			);
		}

		let originalFn = (of: Target) => {
			if (!of) throw "Mixing into the prototype requires usage of original(this)";

			// @ts-ignore
			if (!of.__bound) {
				// @ts-ignore
				of.__bound = true;
				for (let key in original) {
					if (Object.hasOwn(original, key)) {
						let val = original[key];
						if (typeof val === "function") {
							val.bind(of);
						}
					}
				}
			}
			return original;
		};
		// @ts-ignore
		Object.defineProperty(target, "original", {
			enumerable: true,
			configurable: true,
			writable: true,
			value: originalFn
		});

		return target;
	},
};