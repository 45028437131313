import React, { SVGAttributes } from "react";

// eslint-disable-next-line import/prefer-default-export
const PostComment = (props: SVGAttributes<SVGElement>) => {
	const { fill = "var(--primary)" } = props;
	return (
		<svg
			width="24px"
			height="24px"
			viewBox="0 0 24 24"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<g id="icon-m-comment" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="comment_FILL0_wght300_GRAD0_opsz24" transform="translate(3, 3)" fill={fill} fillRule="nonzero">
					<path d="M3.75,11.24995 L15.24995,11.24995 L15.24995,9.75 L3.75,9.75 L3.75,11.24995 Z M3.75,8.24995 L15.24995,8.24995 L15.24995,6.75 L3.75,6.75 L3.75,8.24995 Z M3.75,5.24995 L15.24995,5.24995 L15.24995,3.75 L3.75,3.75 L3.75,5.24995 Z M18.99995,18.538375 L15.4615,14.99995 L1.8077,14.99995 C1.30256667,14.99995 0.875,14.82495 0.525,14.47495 C0.175,14.12495 7.10542736e-16,13.6973833 7.10542736e-16,13.19225 L7.10542736e-16,1.8077 C7.10542736e-16,1.30256667 0.175,0.875 0.525,0.525 C0.875,0.175 1.30256667,0 1.8077,0 L17.19225,0 C17.6973833,0 18.12495,0.175 18.47495,0.525 C18.82495,0.875 18.99995,1.30256667 18.99995,1.8077 L18.99995,18.538375 Z M1.537475,13.499975 L1.537475,1.499975 L1.537475,13.499975 Z M16.09995,13.499975 L17.499975,14.8846 L17.499975,1.8077 C17.499975,1.73076667 17.467925,1.66024167 17.403825,1.596125 C17.3397083,1.532025 17.2691833,1.499975 17.19225,1.499975 L1.8077,1.499975 C1.73076667,1.499975 1.66024167,1.532025 1.596125,1.596125 C1.532025,1.66024167 1.499975,1.73076667 1.499975,1.8077 L1.499975,13.19225 C1.499975,13.2691833 1.532025,13.3397083 1.596125,13.403825 C1.66024167,13.467925 1.73076667,13.499975 1.8077,13.499975 L16.09995,13.499975 Z" id="Shape" />
				</g>
			</g>
		</svg>
	);
};

export default PostComment;
