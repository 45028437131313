// eslint-disable-next-line import/prefer-default-export
import { WEB_SIZE_PROP } from "./Consts";

export function classNames(...classes: (string | null | undefined)[]) {
	return classes.filter(Boolean).join(" ");
}

export const isWideCalc = (
	isWide:boolean,
	value: number,
	// custom proportion
	customProp?: number,
) => (isWide ? value * (customProp ?? WEB_SIZE_PROP) : value);
