import React from "react";
import { Tab } from "@headlessui/react";
import { classNames } from "../common";
import { TabInterface } from "./CredoTab";

interface TabContentPanelProps {
	tabContainerWrapperStyle?: string;
	tab_categories: TabInterface,
	mountedContent: number[];
	tabContainerStyle?: string;
	activeTab: number;
}

// eslint-disable-next-line import/prefer-default-export
export const ContentPanel: React.FC<TabContentPanelProps> = (props: TabContentPanelProps) => {
	const {
		tabContainerWrapperStyle,
		tab_categories,
		mountedContent,
		tabContainerStyle,
		activeTab,
	} = props;

	return (
		<Tab.Panels
			className={classNames(
				"mt-2",
				tabContainerWrapperStyle,
			)}
		>
			{Object.entries(tab_categories).map((comp, idx) => {
				if (!mountedContent.includes(idx)) return null;
				return (
					<Tab.Panel
						key={idx}
						className={classNames(
							/**
							 * While refreshing on the tab other than the first tab
							 * sets the content as display none even if the tab is active
							 * */
							mountedContent.includes(idx) && activeTab === idx ? "!block" : "!hidden",
							tabContainerStyle,
						)}
						unmount={false}
					>
						<div>
							{comp[1]}
						</div>
					</Tab.Panel>
				);
			})}
		</Tab.Panels>
	);
};

ContentPanel.defaultProps = {
	tabContainerWrapperStyle: "",
	tabContainerStyle: "",
};
