import React from "react";
import _ from "lodash";
import Accordion from "../../../gen-comp/Accordion";
import { strings } from "../../../i18n/config";
import { UserDetails } from "../../../utils/types";

interface CredoProfileProps {
	userProfileData: UserDetails;
}

const UserCredoProfile: React.FC<CredoProfileProps> = (props: CredoProfileProps) => {
	const displayFields = ["username", "cprof_id", "cdescr"];

	const {
		userProfileData,
	} = props;

	const data = _.pickBy(_.pick(userProfileData, displayFields), (value) => value);

	return (
		<div className="bg-white p-3 rounded-lg mx-5 min-h-fit max-h-full xs:w-full lg:w-5/12" data-testid="credo-profile-accordion">
			<Accordion
				header={(
					<h5 className="mb-0">
						{strings("UserDetailsPage.credo_profile")}
					</h5>
				)}
				content={(
					<div className="flex flex-col">
						{Object.keys(data).map((key) => (
							<div className="flex flex-row justify-between pb-2" key={key}>
								<span>
									{strings(`UserSearchPage.${key}`)}
									:&nbsp;
								</span>
								<span>
									{/* @ts-ignore */}
									{data[`${key}`]}
								</span>
							</div>
						))}
					</div>
				)}
				defaultOpen
			/>
		</div>
	);
};

export default UserCredoProfile;
