import * as React from "react"
import {SVGAttributes} from "react";

const SvgComponent = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const {
		stroke = "var(--primary)",
	} = props;
	return (
		<svg
			width={22}
			height={19}
			viewBox="0 0 22 19"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g stroke={stroke} fill="none" fillRule="evenodd" strokeLinecap="round">
				<path d="M5.354 15.328a8.405 8.405 0 0 1-2.472-5.945A8.36 8.36 0 0 1 11.265 1" />
				<path d="m6.298 10.978-.944 4.35L1 14.368M16.247 3.472a8.405 8.405 0 0 1 2.472 5.944 8.36 8.36 0 0 1-8.383 8.383" />
				<path d="m15.303 7.822.944-4.35 4.354.96" />
			</g>
		</svg>
	);
}

export default SvgComponent
