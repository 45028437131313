/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGAttributes } from "react";

const StreamNoNetwork = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--accent)",
	} = props;
	return (
		<svg
			width="75px"
			height="96px"
			viewBox="0 0 75 96"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<g id="Streams---CT---190723" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-icons" transform="translate(-128.000000, -177.000000)" stroke={stroke}>
					<g id="noNetworkStream_ego" transform="translate(128.000000, 177.000000)">
						<path
							d="M62.3761,30.5574 L71.8981,13.1704 L49.0881,0.6784 L44.7001,8.6904 C44.2901,7.5234 43.4691,6.4974 42.2991,5.8564 C39.7801,4.4764 36.6191,5.4004 35.2391,7.9194 C33.8591,10.4394 34.7831,13.6004 37.3021,14.9804 C38.4731,15.6214 39.7791,15.7594 40.9841,15.4774 L36.5961,23.4884 L44.6081,27.8764 C43.4411,28.2874 42.4141,29.1074 41.7741,30.2774 C40.3941,32.7974 41.3181,35.9584 43.8371,37.3374"
							id="Stroke-1"
							strokeDasharray="2,2"
						/>
						<path
							d="M72.1947,44.4689 C72.1947,52.6219 65.5857,59.2319 57.4317,59.2319 C49.2787,59.2319 42.6697,52.6219 42.6697,44.4689 C42.6697,36.3159 49.2787,29.7059 57.4317,29.7059 C65.5857,29.7059 72.1947,36.3159 72.1947,44.4689 Z"
							id="Stroke-3"
						/>
						<polygon id="Stroke-5" points="58.7892 48.1524 56.0742 48.1524 54.8412 35.7234 60.0232 35.7234" />
						<path
							d="M59.2566,52.3557 C59.2566,53.3637 58.4396,54.1807 57.4316,54.1807 C56.4246,54.1807 55.6076,53.3637 55.6076,52.3557 C55.6076,51.3477 56.4246,50.5307 57.4316,50.5307 C58.4396,50.5307 59.2566,51.3477 59.2566,52.3557 Z"
							id="Stroke-7"
						/>
						<path
							d="M25.3848,48.8511 L27.8758,44.3021 C26.6718,44.5841 25.3648,44.4461 24.1948,43.8051 C21.6758,42.4251 20.7518,39.2641 22.1318,36.7441 C23.5108,34.2251 26.6718,33.3011 29.1918,34.6811 C30.3618,35.3221 31.1818,36.3481 31.5928,37.5151 L35.9808,29.5031 L27.3148,24.7581 C28.4818,24.3471 29.5088,23.5261 30.1488,22.3561 C31.5288,19.8371 30.6048,16.6761 28.0858,15.2961 C25.5658,13.9161 22.4048,14.8401 21.0248,17.3601 C20.3848,18.5301 20.2458,19.8371 20.5278,21.0411 L13.1708,17.0121 L0.6788,39.8221 L20.6058,50.7341"
							id="Stroke-9"
							strokeDasharray="2,2"
						/>
						<path
							d="M42.1617,90.1184 C42.1617,92.7024 40.0667,94.7974 37.4827,94.7974 C34.8987,94.7974 32.8037,92.7024 32.8037,90.1184 C32.8037,87.5344 34.8987,85.4394 37.4827,85.4394 C40.0667,85.4394 42.1617,87.5344 42.1617,90.1184 Z"
							id="Stroke-11"
						/>
						<path
							d="M16.3979,78.5437 C24.8879,60.2787 50.0769,60.2777 58.5679,78.5437 C60.1529,81.9537 65.1959,78.9867 63.6179,75.5917 C53.3549,53.5127 21.6119,53.5087 11.3479,75.5917 C9.7709,78.9837 14.8109,81.9577 16.3979,78.5437 Z"
							id="Stroke-13"
						/>
						<path
							d="M53.7927,82.6864 C48.4237,66.7024 26.5417,66.7014 21.1727,82.6864 C19.9717,86.2624 25.6187,87.7964 26.8127,84.2414 C30.3127,73.8204 44.6497,73.8104 48.1527,84.2414 C49.3447,87.7914 54.9957,86.2684 53.7927,82.6864 Z"
							id="Stroke-15"
						/>
						<path
							d="M57.4091,59.262 C62.0051,62.567 65.8321,67.002 68.5371,72.243 C70.2631,75.589 75.3121,72.634 73.5871,69.291 C71.2541,64.769 68.1451,60.769 64.4681,57.449"
							id="Stroke-17"
						/>
						<path
							d="M42.9495,47.3344 C41.1545,47.0834 39.3295,46.9544 37.4825,46.9544 C22.1985,46.9544 8.3395,55.8024 1.3785,69.2914 C-0.3445,72.6304 4.7005,75.5924 6.4285,72.2434 C12.4625,60.5514 24.1575,52.7794 37.4825,52.8034 C40.4465,52.8084 43.3275,53.1924 46.0825,53.9144"
							id="Stroke-19"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default StreamNoNetwork;
