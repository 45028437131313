import * as React from "react"
import {SVGAttributes} from "react";
import omit from "lodash/omit";

interface TopContentInterface extends SVGAttributes<SVGElement> {
	walletColor: string;
}

const SvgComponent = (props: TopContentInterface) => {

	const svgProps = omit(props);

	return (
		<svg
			width="18px"
			height="17px"
			viewBox="0 0 18 17"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...svgProps}
		>
			<defs>
				<circle id="top-content-board-icon-path-1" cx="6" cy="6" r="6"></circle>
				<linearGradient x1="8.35442826e-13%" y1="50%" x2="50%" y2="50%" id="top-content-board-icon-linearGradient-3" gradientUnits="userSpaceOnUse">
					<stop stopColor="#00FFA5" offset="0%"></stop>
					<stop stopColor="#00FF9E" stopOpacity="0" offset="100%"></stop>
				</linearGradient>
				<linearGradient x1="8.35442826e-13%" y1="50%" x2="50%" y2="50%" id="top-content-board-icon-linearGradient-4" gradientUnits="userSpaceOnUse">
					<stop stopColor="#00FFA5" offset="0%"></stop>
					<stop stopColor="#00FF9E" stopOpacity="0" offset="100%"></stop>
				</linearGradient>
				<linearGradient x1="8.35442826e-13%" y1="50%" x2="50%" y2="50%" id="top-content-board-icon-linearGradient-5" gradientUnits="userSpaceOnUse">
					<stop stopColor="#00FFA5" offset="0%"></stop>
					<stop stopColor="#00FF9E" stopOpacity="0" offset="100%"></stop>
				</linearGradient>
				<linearGradient x1="25%" y1="50%" x2="4.62130334e-12%" y2="50%" id="top-content-board-icon-linearGradient-6" gradientUnits="userSpaceOnUse">
					<stop stopColor="#00FFA5" offset="0%"></stop>
					<stop stopColor="#00FF9E" stopOpacity="0" offset="100%"></stop>
				</linearGradient>
				<linearGradient x1="25%" y1="50%" x2="4.62130334e-12%" y2="50%" id="top-content-board-icon-linearGradient-7" gradientUnits="userSpaceOnUse">
					<stop stopColor="#00FFA5" offset="0%"></stop>
					<stop stopColor="#00FF9E" stopOpacity="0" offset="100%"></stop>
				</linearGradient>
				<linearGradient x1="25%" y1="50%" x2="4.62130334e-12%" y2="50%" id="top-content-board-icon-linearGradient-8" gradientUnits="userSpaceOnUse">
					<stop stopColor="#00FFA5" offset="0%"></stop>
					<stop stopColor="#00FF9E" stopOpacity="0" offset="100%"></stop>
				</linearGradient>
			</defs>
			<g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Top-Content-Board">
					{/*<rect fill="#212636" x="0" y="0" width="18" height="17"></rect>*/}
					<rect id="Rectangle" stroke="#0BE0D8" strokeWidth="0.8" fill="#212636" x="0.4" y="1.507526" width="13.2" height="15.2" rx="3.20000005"></rect>
					<rect id="Rectangle" stroke="#0BE0D8" fill="#0BC7E0" x="2.5" y="3.5" width="3" height="2" rx="1"></rect>
					<rect id="Rectangle-Copy" stroke="#0BE0D8" fill="#0BC7E0" x="2.5" y="8" width="3" height="2" rx="1"></rect>
					<rect id="Rectangle-Copy-2" stroke="#0BE0D8" fill="#0BC7E0" x="2.5" y="12.5" width="3" height="2" rx="1"></rect>
					<line x1="7.5" y1="4.5" x2="11.5" y2="4.5" id="Line" stroke="#0BE0D8" strokeLinecap="square"></line>
					<line x1="7.5" y1="8.5" x2="11.5" y2="8.5" id="Line-Copy" stroke="#0BE0D8" strokeLinecap="square"></line>
					<line x1="7.5" y1="13.5" x2="11.5" y2="13.5" id="Line-Copy-2" stroke="#0BE0D8" strokeLinecap="square"></line>
					<g id="Group" transform="translate(5.000000, 3.000000)">
						<g id="Oval">
							<circle
								stroke="#0BE0D8"
								strokeWidth="1.2"
								// @ts-ignore
								strokeLinejoin="square" fill="#212636" fillRule="evenodd" cx="6" cy="6" r="5.4"></circle>
						</g>
						<g id="CB-Icon---Default" transform="translate(2.000000, 2.000000)" strokeLinecap="round" strokeWidth="0.95904">
							<g id="l-bolt" transform="translate(1.333333, 0.615385)">
								<line x1="3" y1="0.307692308" x2="3.66666667" y2="0.307692308" id="Line-4" stroke="url(#top-content-board-icon-linearGradient-3)"></line>
								<line x1="1.66666667" y1="1.53846154" x2="3.66666667" y2="1.53846154" id="Line-4-Copy" stroke="url(#top-content-board-icon-linearGradient-4)"></line>
								<line x1="0.333333333" y1="2.76923077" x2="5" y2="2.76923077" id="Line-4-Copy-2" stroke="url(#top-content-board-icon-linearGradient-5)"></line>
								<line x1="0.333333333" y1="4" x2="5" y2="4" id="Line-4-Copy-2" stroke="url(#top-content-board-icon-linearGradient-6)"></line>
								<line x1="1.66666667" y1="5.23076923" x2="3.66666667" y2="5.23076923" id="Line-4-Copy-4" stroke="url(#top-content-board-icon-linearGradient-7)"></line>
								<line x1="1.66666667" y1="6.46153846" x2="2.33333333" y2="6.46153846" id="Line-4-Copy-5" stroke="url(#top-content-board-icon-linearGradient-8)"></line>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	)
}
export default SvgComponent

