import React, { useCallback, useState } from "react";
import ReactPlayer from "react-player/lazy";
import { classNames } from "../../common";
import RestrictedContentOverlay from "../RestrictedContentOverlay";
import { Spinner } from "../../spinner";
import { ImageErrorIcon } from "../../assets/icons";

interface VideoPlayerProps {
	/**
	 * Sets source URL of the post
	 * */
	source: string;
	/**
	 * Whether Video has senstitve content or not
	 * */
	isContentRestricted: boolean;
	/**
	 * Sets video on mute
	 * @default true
	 * */
	muted?: boolean;
	/**
	 * Has class names to be set to wrapper div
	 * */
	wrapperClassNames?: any;
	/**
	 * Sets video on pause/play
	 * @default false
	 * */
	paused?: boolean;
	/**
	 * Whether to show video controls or not
	 * */
	controls?: boolean;
	/**
	 * Has error msg to be shown if image doesn't load
	 * */
	imageErrorMsg: string;
	/**
	 * Has sensitive content msg
	 * */
	contentSensitiveMsg: string;
	/**
	 * callback executed when use clicks on content sesitive overlay
	 * */
	onShowSensitiveContent?: () => void;
}

const VideoPlayer: React.FC<VideoPlayerProps> = (props) => {
	const {
		source,
		paused,
		muted,
		isContentRestricted,
		contentSensitiveMsg,
		imageErrorMsg,
		wrapperClassNames,
		controls,
		onShowSensitiveContent,
	} = props;
	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(false);
	const [showContentSensitiveOverlay, setShowContentSensitiveOverlay] = useState(isContentRestricted);

	const renderVideoLoadingAndError = () => {
		if (isLoading && isError) {
			return (
				<div className="flex flex-col absolute flex-1 w-full h-full items-center justify-center bg-input-bg rounded-15">
					<ImageErrorIcon xlinkTitle={imageErrorMsg} />
					<span className="text-gray-dark text-xs font-normal pt-1">{imageErrorMsg}</span>
				</div>
			);
		}
		if (isLoading) {
			return (
				<div className="flex absolute flex-1 w-full h-full items-center justify-center bg-input-bg rounded-15">
					<Spinner
						width="30"
						height="30"
						leaf_fill="var(--primary)"
					/>
				</div>
			);
		}
		return null;
	};

	const onPressContentSensitiveOverlay = useCallback(() => {
		if (onShowSensitiveContent) {
			onShowSensitiveContent();
		}
		setShowContentSensitiveOverlay(!showContentSensitiveOverlay);
	}, [onShowSensitiveContent, showContentSensitiveOverlay]);

	return (
		<div className={classNames(
			"flex flex-col relative cursor-pointer overflow-hidden rounded-15",
		)}
		>
			<ReactPlayer
				url={source}
				muted={muted}
				controls={controls}
				playing={paused}
				wrapper={({ children }) => (
					<div
						className={classNames(
							"w-full overflow-hidden rounded-15",
							wrapperClassNames || "",
						)}
						style={showContentSensitiveOverlay ? { filter: "blur(40px)", aspectRatio: "16/9" } : { aspectRatio: "16/9" }}
					>
						{children}
					</div>
				)}
				onReady={() => setIsLoading(false)}
				onError={() => setIsError(true)}
			/>
			{renderVideoLoadingAndError()}
			{!isError
				&& (
					<RestrictedContentOverlay
						isContentRestricted={showContentSensitiveOverlay}
						title={contentSensitiveMsg}
						buttonTitle="See Video"
						onShowSensitiveContent={onPressContentSensitiveOverlay}
					/>
				)}
		</div>
	);
};

VideoPlayer.defaultProps = {
	wrapperClassNames: "",
	controls: true,
	paused: false,
	muted: true,
	onShowSensitiveContent: () => {},
};

export default VideoPlayer;
