import React from "react";
import { classNames } from "../../../common";

interface BalanceItemWrapperProps {
	/**
	 * unique id which will be used for testing and
	 * unique key.
	 * */
	id: string;
	children: any;
	className?: string;
	style?: object;
	title?: string;
}

// eslint-disable-next-line import/prefer-default-export
export const BalanceItemWrapper: React.FC<BalanceItemWrapperProps> = ({
	id,
	children,
	className,
	style = {},
	title,
}: BalanceItemWrapperProps) => (
	<div
		key={id}
		id={id}
		data-testid={id}
		style={{
			width: 84,
			height: 18,
			...style,
		}}
		className={classNames(
			"py-px px-px rounded flex flex-row justify-between items-center",
			// Styles.balanceItem,
			className,
		)}
		title={title}
	>
		{children}
	</div>
);

BalanceItemWrapper.defaultProps = {
	className: "",
	style: {},
	title: undefined,
};
