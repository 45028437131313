/* eslint-disable react/require-default-props */
import React from "react";
import { classNames } from "../common";
import "./ProgrssBarStyles.module.css";

export interface ProgressBarProps {
	percent: number;
	isLoading?: boolean;
	size?: "small" | "medium" | "large";
	showInfo?: boolean;
	progressColor?: string;
	containerStyle?: string
}

const ProgressBar = (props: ProgressBarProps) => {
	const {
		isLoading,
		percent,
		size = "small",
		showInfo = false,
		progressColor,
		containerStyle,
	} = props;

	return (
		<div className={classNames("progress-outer")}>
			<div
				className={classNames(containerStyle, "progress",
					size ? `progress--${size}` : "",
					isLoading ? "progress--loading" : "")}
			>
				<div
					className={classNames(progressColor, "progress-bar rounded-full")}
					style={{ width: `${percent}%` }}
				/>
			</div>

			{!isLoading && showInfo && (
				<span className="progress-info">
					{percent}
					%
				</span>
			)}
		</div>
	);
};

export default ProgressBar;
