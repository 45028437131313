import React, { SVGAttributes } from "react";

// eslint-disable-next-line import/prefer-default-export
const PostComment = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--accent)" } = props;
	return (
		<svg
			width="77px"
			height="81px"
			viewBox="0 0 77 81"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<g id="A-Post---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-Icons" transform="translate(-60.000000, -502.000000)" stroke={stroke}>
					<g id="postNotAccessible_ego" transform="translate(60.000000, 502.000000)">
						<path
							d="M61.6413,80.3179 L42.6033,80.3179 C34.5673,80.3179 28.0533,73.8039 28.0533,65.7689 L28.0533,49.9969 C28.0533,46.5709 30.8313,43.7929 34.2573,43.7929 L69.9863,43.7929 C73.4123,43.7929 76.1903,46.5709 76.1903,49.9969 L76.1903,65.7689 C76.1903,73.8039 69.6763,80.3179 61.6413,80.3179 Z"
							id="Stroke-1"
						/>
						<path
							d="M68.9294,43.7925 L61.0544,43.7925 L61.0544,34.1015 C61.0544,29.1755 57.0474,25.1685 52.1214,25.1685 C47.1964,25.1685 43.1894,29.1755 43.1894,34.1015 L43.1894,43.7925 L35.3144,43.7925 L35.3144,34.1015 C35.3144,24.8325 42.8534,17.2925 52.1214,17.2925 C61.3904,17.2925 68.9294,24.8325 68.9294,34.1015 L68.9294,43.7925 Z"
							id="Stroke-3"
						/>
						<path
							d="M58.487,59.6333 C58.487,56.1173 55.637,53.2683 52.122,53.2683 C48.606,53.2683 45.757,56.1173 45.757,59.6333 C45.757,62.6213 47.82,65.1223 50.597,65.8063 L50.597,69.6733 C50.597,70.5153 51.28,71.1973 52.122,71.1973 C52.964,71.1973 53.646,70.5153 53.646,69.6733 L53.646,65.8063 C56.424,65.1213 58.487,62.6213 58.487,59.6333 Z"
							id="Stroke-5"
						/>
						<path
							d="M28.0534,48.0776 L5.5814,48.0776 C2.7754,48.0776 0.4994,45.8036 0.4994,42.9966 L0.4994,17.3846 C0.4994,14.5776 2.7754,12.3026 5.5814,12.3026"
							id="Stroke-7"
							strokeDasharray="2,2"
						/>
						<path
							d="M29.1862,12.3022 L60.1082,12.3022 C62.9152,12.3022 65.1902,14.5772 65.1902,17.3842 L65.1902,23.5432"
							id="Stroke-9"
							strokeDasharray="2,2"
						/>
						<path
							d="M17.3884,11.8853 C19.5564,11.8853 21.3194,9.5763 21.3194,7.4093 C21.3194,5.2413 19.5564,3.4793 17.3884,3.4793 C15.2224,3.4793 13.4584,5.2413 13.4584,7.4093 C13.4584,9.5763 15.2224,11.8853 17.3884,11.8853 Z"
							id="Stroke-11"
							strokeDasharray="2,2"
						/>
						<path
							d="M11.0456,18.2368 C11.3396,14.5168 12.9896,13.0118 13.9466,12.4418 L14.1806,12.3018 L14.4166,12.4588 L17.3886,14.6868 L20.3596,12.4588 L20.5966,12.3018 L20.8306,12.4418 C21.7876,13.0118 23.4376,14.5168 23.7326,18.2368 L23.7336,18.5488 C22.1036,20.1178 19.8626,21.0888 17.3896,21.0888 C14.9146,21.0888 12.6746,20.1178 11.0436,18.5478 L11.0456,18.2368 Z"
							id="Stroke-13"
							strokeDasharray="2,2"
						/>
						<path
							d="M29.1862,12.3022 C29.1862,5.7832 23.9022,0.4992 17.3842,0.4992 C10.8662,0.4992 5.5822,5.7832 5.5822,12.3022 C5.5822,18.8212 10.8662,24.1052 17.3842,24.1052 C23.9022,24.1052 29.1862,18.8212 29.1862,12.3022 Z"
							id="Stroke-15"
							strokeDasharray="2,2"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default PostComment;
