import React, { SVGAttributes } from "react";

const Activity = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--gray-dark)" } = props;
	return (
		<svg
			width="20px"
			height="16px"
			viewBox="0 0 20 16"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<title>activity_icon</title>
			<g id="Streams---CT---190723" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
				<g id="All-icons" transform="translate(-374.000000, -206.000000)" stroke={stroke}>
					<path
						d="M374,214.133189 L377.057646,214.133189 L380.142092,207.243831 C380.16466,207.193424 380.223818,207.170856 380.274225,207.193423 C380.300623,207.205242 380.320611,207.227912 380.329031,207.255582 L384.425085,220.716266 C384.441163,220.769102 384.497029,220.798901 384.549865,220.782823 C384.579745,220.773731 384.603661,220.7512 384.614517,220.721915 L388.556924,210.087607 C388.576121,210.035822 388.633664,210.009406 388.685448,210.028603 C388.7096,210.037557 388.729366,210.055485 388.740626,210.078652 L390.711356,214.133189 L390.711356,214.133189 L394,214.133189"
						id="activity_icon"
					/>
				</g>
			</g>
		</svg>
	);
};

export default Activity;
