import { SnackBarTypeOptions } from "@credo/ui-components";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
	createSearchParams, useNavigate,
} from "react-router-dom";
import { toast } from "react-toastify";
import { successRETCD } from "../../api";
import ConfirmationModal from "../../gen-comp/ConfirmationModal";
import Hero from "../../gen-comp/Hero";
import Table from "../../gen-comp/table";
import ToastMsg from "../../gen-comp/ToastMsg";
import { useQuery } from "../../hooks/graphql/useQuery";
import useOptionalSearchApi from "../../hooks/useOptionalSearchApi";
import { strings } from "../../i18n/config";
import { AUTH_URL, COMMENT_DETAILS_PAGE, USER_DETAILS_PAGE } from "../../routes/constants";
import { PAGINATION_LIMIT } from "../../utils/constants";
import {
	CommentDetails, DisabledPostType, TableHeader,
} from "../../utils/types";
import { DATE_FORMAT } from "../../utils/utils";
import { restoreDisabledItem } from "../posts/queries";
import {
	DisabledCommentTableHeaders,
} from "./constants";
import {
	deleteCommentQuery, getDisabledCommentsQueries, GetDisabledCommentsVariables,
} from "./queries";

export default function DisabledCommentsPage() {
	const navigate = useNavigate();

	const [headers] = useState<TableHeader[]>(DisabledCommentTableHeaders);
	const [confirmationObj, setConfirmationObj] = useState<any>(null);
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	const [page, setPage] = useState(1);
	const [changeInPage, setChangeInPage] = useState<boolean>(true);
	const [commentsData, setCommentsData] = useState<CommentDetails[]>([]);

	const variables: GetDisabledCommentsVariables = {
		headers: DisabledCommentTableHeaders,
		offset: (page - 1) * PAGINATION_LIMIT,
	};

	const variablesForSearchAPI = {
		searchData: [],
		otherFilterQuery: "",
	};

	const [getSearchApiData, { data, loading }] = useOptionalSearchApi(
		getDisabledCommentsQueries(variables),
		variablesForSearchAPI as any,
		{
			entity: "Comment",
			page,
			useSearchApi: false,
			formatDataKey: "comment",
		},
	);

	useEffect(() => {
		if (changeInPage) {
			getSearchApiData();
		}
	}, [page, changeInPage]);

	useEffect(() => {
		const items: any[] = [];
		data?.forEach((element: any) => {
			items.push({
				...element,
				creat_ts: element?.creat_ts
					? moment(new Date(element.creat_ts)).format(DATE_FORMAT) : strings("CommunitySearchPage.not_available"),
				modif_ts: element?.modif_ts
					? moment(new Date(element.modif_ts)).format(DATE_FORMAT) : strings("CommunitySearchPage.not_available"),
				reported_ts: element?.reported_ts
					? moment(new Date(element.reported_ts)).format(DATE_FORMAT) : strings("CommunitySearchPage.not_available"),
			});
		});
		setCommentsData(items);
		setChangeInPage(false);
	}, [data]);

	const [deleteUserComment] = useQuery<Array<Object>>("", {
		formatDataKey: "comment",
		isMutation: true,
	});

	const closeConfirmationModal = () => {
		setOpenConfirmationModal(false);
		setConfirmationObj(null);
	};

	const deleteComment = (item: any) => {
		deleteUserComment({
			mutation: deleteCommentQuery({ fields: DisabledCommentTableHeaders, commentUuid: item?.commentUuid || null }),
		});
		closeConfirmationModal();
		getSearchApiData();
	};

	const redirectToCommentDetailsPage = (item: any) => {
		if (item?.value === "user_id") {
			window.open(`${AUTH_URL}${USER_DETAILS_PAGE}?${createSearchParams({
				userId: item?.user_id,
				isNewTab: "y",
			})}`, "_blank");
		} else {
			navigate({
				pathname: AUTH_URL + COMMENT_DETAILS_PAGE,
				search: `?${createSearchParams({
					commentUuid: item?.commentUuid,
					reportedCount: item?.nb_rep,
				})}`,
			});
		}
	};

	const restoreDisabledComment = async (item: any) => {
		await restoreDisabledItem({
			commentUuid: item?.commentUuid,
			itemType: DisabledPostType.COMMENT,
		}).then((res: any) => {
			if (res?.response?.retcd === successRETCD) {
				toast(
					<ToastMsg
						message={strings("DisabledCommentsPage.restore_comment_success")}
						showButton
						type={SnackBarTypeOptions.SUCCESS}
					/>,
				);
				getSearchApiData();
			} else {
				toast(
					<ToastMsg
						message={strings("PostSearchPage.something_went_wrong")}
						showButton
						type={SnackBarTypeOptions.ERROR}
					/>,
				);
			}
		}).catch((error) => {
			toast(
				<ToastMsg
					message={strings("PostSearchPage.something_went_wrong", { error })}
					showButton
					type={SnackBarTypeOptions.ERROR}
				/>,
			);
		});
		closeConfirmationModal();
	};

	const showRestoreConfirmationPopup = (item: any) => {
		const title = strings("DisabledCommentsPage.restore_comment");
		const message = `${strings("DisabledCommentsPage.restore_comment_message", { commentUuid: item?.commentUuid })}`;
		setOpenConfirmationModal(true);
		setConfirmationObj({
			ok_button_function: () => {
				restoreDisabledComment(item);
			},
			title,
			message,
		});
	};

	const showDeleteConfirmationPopup = (item: any) => {
		const title = strings("CommentSearchPage.delete_comment");
		const message = `${strings("CommentSearchPage.delete_comment_message", { commentUuid: item?.commentUuid })}`;
		setOpenConfirmationModal(true);
		setConfirmationObj({
			ok_button_function: () => deleteComment(item),
			title,
			message,
		});
	};

	const renderColumn = (props: { item: any; header: any; }) => {
		const { item, header } = props;
		if (!header || !item) {
			return null;
		}
		return (
			<div className="flex flex-row justify-center items-center">
				<button
					type="button"
					className="button"
					onClick={() => showRestoreConfirmationPopup(item)}
				>
					{strings("DisabledCommentsPage.restore")}
				</button>
				<button
					type="button"
					className="button"
					onClick={() => showDeleteConfirmationPopup(item)}
				>
					{strings("DisabledCommentsPage.delete")}
				</button>
			</div>
		);
	};

	return (
		<div>
			<Hero title={strings("DisabledCommentsPage.hero_title")} pageHasTable />
			<div className="bg-white p-5 rounded-lg my-5 mx-5 -mt-24">
				<div data-testid="comment-data-table" className="my-0">
					<Table
						header={[
							...headers,
							{
								title: strings("Table.actions"),
								value: "actions",
								showCustomColumnComponent: true,
							},
						]}
						data={commentsData}
						showHeader
						isLoading={loading}
						showActionButtons={false}
						pagination={{
							onPageChange: (num) => {
								setChangeInPage(true);
								setPage(num);
							},
							hidePageNumber: true,
							scrollOnPageChange: true,
							page,
						}}
						CustomColumnComponent={renderColumn}
						onItemClick={redirectToCommentDetailsPage}
					/>
				</div>
				<ConfirmationModal
					isVisible={openConfirmationModal}
					onClose={closeConfirmationModal}
					cancel_button_function={closeConfirmationModal}
					ok_button_title={strings("PostSearchPage.yes")}
					cancel_button_title={strings("PostSearchPage.no")}
					ok_button_function={confirmationObj?.ok_button_function}
					title={confirmationObj?.title}
					message={confirmationObj?.message}
					okButtonStyle=""
					cancelButtonStyle=""
				/>
			</div>
		</div>
	);
}
