import React, { useEffect, useState } from "react";
import { createSearchParams } from "react-router-dom";
import { strings } from "../../../i18n/config";
import Accordion from "../../../gen-comp/Accordion";
import {
	DropdownItem,
	UserCommunitiesOptions,
	UserDetails,
	UserStreamFolAndJoinInterface,
	YesNoOptions,
} from "../../../utils/types";
import Table from "../../../gen-comp/table";
import {
	UserCommRelnOptions,
	UserCommRoleOptions,
	UserCommunitiesTableHeaders,
	UserModesOptions,
} from "../constants";
import { AUTH_URL, COMMUNITIES_DETAILS_PAGE } from "../../../routes/constants";
import Filter from "../../../gen-comp/Filter";

interface UseCommunitiesProps {
	/**
	 * User details which also contains user's communities data
	 * */
	data: UserDetails;
}

const UserCommunities: React.FC<UseCommunitiesProps> = (props: UseCommunitiesProps) => {
	const {
		data,
	} = props;

	const [communitiesData, setCommunitiesData] = useState<UserStreamFolAndJoinInterface[]>([]);
	const [userCommMode, setUserCommMode] = useState<DropdownItem[]>(UserModesOptions);
	const [userCommReln, setUserCommReln] = useState<DropdownItem[]>(UserCommRelnOptions);
	const [userCommRole, setUserCommRole] = useState<DropdownItem[]>(UserCommRoleOptions);
	const [filteredCommData, setFilteredCommData] = useState<UserStreamFolAndJoinInterface[]>([]);

	const navigateToStream = (item: any) => {
		window.open(`${AUTH_URL}${COMMUNITIES_DETAILS_PAGE}?${createSearchParams({
			communityId: item?.streamId,
			isNewTab: YesNoOptions.YES,
		})}`, "_blank");
	};

	useEffect(() => {
		if (data && ((data.followed_stream_relType && data.followed_stream_relType.length > 0)
			|| (data.joined_stream_relType && data.joined_stream_relType.length > 0))) {
			const followedStreams = data.followed_stream_relType ?? [];
			const joinedStreams = data.joined_stream_relType ?? [];
			const processedStreamIds: any[] = [];
			const folOnlyStreams = followedStreams.filter((folStream) => {
				let isFolStreamJoined = true;
				joinedStreams.forEach((joinedStream) => {
					// TODO: streamID typo at backend
					if (folStream.steamId === joinedStream.steamId) {
						isFolStreamJoined = false;
					}
				});
				return isFolStreamJoined;
			});
			const allStreams = [...joinedStreams, ...folOnlyStreams];
			const tableData: UserStreamFolAndJoinInterface[] = [];
			allStreams.forEach((item) => {
				const stream = {
					// TODO: streamID typo at backend
					streamId: item.steamId,
					name: item.name,
					is_joined: Object.prototype.hasOwnProperty.call(item, UserCommunitiesOptions.REL_TYPE)
						? YesNoOptions.YES : YesNoOptions.NO,
					is_followed: YesNoOptions.YES,
					role: item?.relType ?? UserCommunitiesOptions.ROLE_MEMBER,
					mode: item.mode,
				};
				tableData.push(stream);
				processedStreamIds.push(item.steamId);
			});
			setCommunitiesData(tableData);
			setFilteredCommData(tableData);
		}
	}, []);

	const applyUserCommModeFilter = (items: DropdownItem[]) => {
		setUserCommMode(items);
	};

	const applyUserCommRelnFilter = (items: DropdownItem[]) => {
		setUserCommReln(items);
	};

	const applyUserCommRoleFilter = (items: DropdownItem[]) => {
		setUserCommRole(items);
	};

	useEffect(() => {
		if (communitiesData.length !== 0) {
			const tempCommData = [...communitiesData];
			const tempCommDataWithFilter = tempCommData.filter((item) => {
				const modeValues = userCommMode.map((e) => e.value).join(",");
				const relnValues = userCommReln.map((e) => e.value).join(",");
				const roleValues = userCommRole.map((e) => e.value).join(",");
				return modeValues.includes(item.mode ?? "") && roleValues.includes(item.role ?? "")
					&& ((relnValues.includes(UserCommunitiesOptions.IS_FOLLOWED) && item.is_followed === YesNoOptions.YES)
						|| (relnValues.includes(UserCommunitiesOptions.IS_JOINED) && item.is_joined === YesNoOptions.YES));
			});
			setFilteredCommData(tempCommDataWithFilter);
		}
	}, [userCommMode, userCommReln, userCommRole]);

	if (!communitiesData || communitiesData.length === 0) {
		return null;
	}

	return (
		<div
			className="bg-white p-3 rounded-lg mx-5 mb-4 min-h-fit max-h-full max-w-[calc(100%-2.5rem)]"
			data-testid="user-communities-accordion"
		>
			<Accordion
				header={(
					<h5 className="mb-0">
						{strings("UserDetailsPage.user_communities")}
					</h5>
				)}
				content={(
					<div className="flex flex-wrap pb-4">
						<div className="grid grid-cols-1 xs:grid-cols-2-max-20 sm:grid-cols-3-max-20 gap-2 lg:gap-4 mb-4">
							<Filter
								id="comm-mode-filter"
								data={userCommMode}
								filterName={strings("UserDetailsPage.user_comm_mode")}
								applyFilter={applyUserCommModeFilter}
								selectedData={userCommMode}
								showTitle={false}
							/>
							<Filter
								id="comm-reln-filter"
								data={userCommReln}
								filterName={strings("UserDetailsPage.user_comm_reln")}
								applyFilter={applyUserCommRelnFilter}
								selectedData={userCommReln}
								showTitle={false}
							/>
							<Filter
								id="comm-role-filter"
								data={userCommRole}
								filterName={strings("UserDetailsPage.user_comm_role")}
								applyFilter={applyUserCommRoleFilter}
								selectedData={userCommRole}
								showTitle={false}
							/>
						</div>
						<Table
							header={UserCommunitiesTableHeaders}
							data={filteredCommData}
							onItemClick={navigateToStream}
							showHeader
							showSortIcons
							isLoading={false}
						/>
					</div>
				)}
			/>
		</div>
	);
};

export default UserCommunities;
