import * as React from "react"
import { SVGProps } from "react"
import omit from "lodash/omit";

const CredoHelmet = (props: SVGProps<SVGSVGElement>) => {
	const newProps = omit(props, ["stroke", "fill"]);
	return (
		<svg width="512px" height="512px" viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...newProps}>
			<title>Credo</title>
			<defs>
				<linearGradient id="a" x1="0%" x2="100%" y1="50%" y2="50%">
					<stop offset="18.723%" stopColor="#00FF25" />
					<stop offset="50.934%" stopColor="#00FFF5" />
					<stop offset="84.315%" stopColor="#FF7000" />
				</linearGradient>
			</defs>
			<g fill="none" fillRule="evenodd">
				<path
					fill="#0BE0D8"
					fillRule="nonzero"
					d="M336.108 500.748H500.748v-325.36c0-90.928-73.711-164.64-164.64-164.64h-160.72c-90.928 0-164.64 73.712-164.64 164.64v160.72c0 90.929 73.712 164.64 164.64 164.64h160.72Z"
				/>
				<path
					fill="#0BE0D8"
					fillRule="nonzero"
					d="M175.388 0C78.524 0 0 78.524 0 175.388v160.72c0 96.865 78.524 175.389 175.388 175.389h336.109V175.388C511.497 78.524 432.973 0 336.108 0h-160.72Zm0 21.497h160.72c84.992 0 153.893 68.9 153.893 153.891V490H175.388c-84.142 0-152.511-67.528-153.87-151.347l-.021-2.545v-160.72c0-84.991 68.9-153.891 153.891-153.891Z"
				/>
				<path
					fill="#0E575A"
					d="M62.078 193.036c0-71.307 57.806-129.114 129.114-129.114h129.113c71.308 0 129.114 57.807 129.114 129.114V322.15c0 71.307-57.806 129.113-129.114 129.113H191.192c-71.308 0-129.114-57.806-129.114-129.113V193.036Z"
				/>
				<path
					fill="#10121B"
					fillRule="nonzero"
					d="M314.06 48C397.422 48 465 115.578 465 198.94v116.12C465 398.422 397.422 466 314.06 466H197.94C114.578 466 47 398.422 47 315.06V198.94C47 115.578 114.578 48 197.94 48h116.12Zm0 69.638H197.94c-44.902 0-81.302 36.4-81.302 81.302v116.12c0 44.902 36.4 81.302 81.302 81.302h116.12c44.902 0 81.302-36.4 81.302-81.302V198.94c0-44.902-36.4-81.302-81.302-81.302Z"
				/>
				<g fillRule="nonzero">
					<path
						fill="url(#a)"
						d="M22.676 97.023c0-41.06 33.286-74.347 74.347-74.347H319.67c41.061 0 74.348 33.286 74.348 74.347 0 26.606 0 0 0 0 0 35.574-28.053 74.347-74.348 74.347H97.023c-50.385 0-74.347-39.766-74.347-74.347 0 0 0 21.621 0 0Z"
						transform="translate(47.402 156.88)"
					/>
					<path
						fill="#10121B"
						d="M367.072 156.88c53.584 0 97.023 43.44 97.023 97.024v11.825h-.763l-.017.138c-2.52 19.38-11.053 38.235-24.383 53.195l-.515.574c-17.938 19.816-42.965 31.291-71.345 31.291H144.425c-30.211 0-55.623-11.623-73.248-32.081-13.232-15.36-21.248-34.901-23.268-54.914l-.04-.42-.467.001v-9.609l.013-1.604c.857-52.845 43.961-95.42 97.01-95.42h222.647Zm0 45.353H144.425c-28.537 0-51.672 23.134-51.672 51.671l.004.64c.149 12.374 4.592 24.943 12.374 34.224l.406.477c9 10.447 21.863 16.33 38.888 16.33h222.647c15.36 0 28.25-5.91 37.723-16.375 8.861-9.789 13.949-22.892 13.949-35.296l-.007-.854c-.457-28.144-23.413-50.817-51.665-50.817Z"
					/>
				</g>
			</g>
		</svg>

	)
}

export default CredoHelmet;
