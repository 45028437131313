/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import {
	createSearchParams, useLocation, useNavigate, useSearchParams,
} from "react-router-dom";
import { isValidPhoneNumber } from "libphonenumber-js";
import { usePrevious } from "react-use";
import Hero from "../../gen-comp/Hero";
import { strings } from "../../i18n/config";
import Table from "../../gen-comp/table";
import {
	SearchBarElementInterface, TableHeader, UserDetails,
} from "../../utils/types";
import {
	NotRegisteredUserSearchColumns, NotRegisteredUserTableHeaders, UserTableHeaders,
} from "./constants";
import { DATE_FORMAT } from "../../utils/utils";
import Accordion from "../../gen-comp/Accordion";
import SearchBarContainer from "../../gen-comp/SearchBar/SearchBarContainer";
import { AUTH_URL, USER_DETAILS_PAGE } from "../../routes/constants";
import TooltipHelper from "../../gen-comp/TooltipHelper";
import useDimensions from "../../hooks/useDimensions";
import useOptionalSearchApi from "../../hooks/useOptionalSearchApi";
import { makeApiRequest } from "../../api";
import Filter from "../../gen-comp/Filter";

export default function NotFullyRegisteredUsersPage() {
	// to skip the function call on first mount in useEffect
	const skipMountEffect = useRef<boolean>(true);
	const navigate = useNavigate();
	const location = useLocation();
	const [searchParams] = useSearchParams();
	const searchDataParams = searchParams.get("searchData");
	const headerParams = searchParams.get("headers");

	const [userData, setUserData] = useState<UserDetails[]>([]);
	const [page, setPage] = useState<number>(1);
	const [changeInPage, setChangeInPage] = useState<boolean>(true);
	const [searchData, setSearchData] = useState<SearchBarElementInterface[]>(
		searchDataParams ? JSON.parse(searchDataParams) : [],
	);
	const [headers, setHeaders] = useState<TableHeader[]>(
		headerParams ? JSON.parse(headerParams) : localStorage.getItem("non-registered-user-headers")
			? JSON.parse(localStorage.getItem("non-registered-user-headers") || "") : NotRegisteredUserTableHeaders,
	);
	const [userIdByPhone, setUserIdByPhone] = useState<string>("");
	const [hasPhoneNumber, setHasPhoneNumber] = useState<SearchBarElementInterface | undefined>(undefined);
	const [userIdByPhoneLoading, setUserIdByPhoneLoading] = useState<boolean>(false);
	const [, width] = useDimensions();
	const prevSearchData = usePrevious(searchData);

	const wrapperRef = useRef(null);

	useEffect(() => {
		const paramString = `searchData=${JSON.stringify(searchData).replace("+", "%2B")}`
			+ `&headers=${JSON.stringify(headers)}`;
		if (window) {
			window.history.replaceState(null, "", `${location.pathname}?${paramString}`);
		}
	}, [searchData, headers]);

	useEffect(() => {
		if (searchDataParams) {
			setSearchData(JSON.parse(searchDataParams));
		}
		if (headerParams) {
			setHeaders(JSON.parse(headerParams));
		}
	}, []);

	const updateSearchDropdownItems = (items: SearchBarElementInterface[]) => {
		setSearchData(items);
	};

	const applyFilter = (items: TableHeader[]) => {
		setHeaders(items);
	};

	const navigateToUserDetails = (item: any) => {
		navigate({
			pathname: AUTH_URL + USER_DETAILS_PAGE,
			search: `?${createSearchParams({ userId: item?.user_id })}`,
		});
	};

	useEffect(() => {
		setHasPhoneNumber(searchData.find((element) => isValidPhoneNumber(element.selectedSearchValue)));
	}, [searchData]);

	const getUserByPhone = async () => {
		if (hasPhoneNumber) {
			setUserIdByPhoneLoading(true);
			const result = await makeApiRequest({
				method: "get",
				url: `api/userByPhone/${hasPhoneNumber?.selectedSearchValue}`,
			});

			const userId = await result.user_id;
			setUserIdByPhone(userId);
		} else {
			setUserIdByPhone("");
		}
	};

	const variablesForSearchAPI = {
		searchData: searchData.filter((searchEl) => searchEl.selectedSearchValue),
		otherFilterQuery: {
			name: [
				{
					title: "firstName",
					value: "",
					searchQueries: [
						{
							operator: "OR",
							propName: "firstName",
							propVal: null,
							condition: " IS NULL ",
						},
					],
				},
				{
					title: "lastName",
					value: "",
					searchQueries: [
						{
							operator: "OR",
							propName: "lastName",
							propVal: null,
							condition: " IS NULL ",
						},
					],
				},
			],
			phone: [
				{
					title: "user_id",
					value: "",
					searchQueries: [
						{
							operator: "AND",
							propName: "user_id",
							propVal: userIdByPhone,
							condition: " CONTAINS ",
						},
					],
				},
			],
			status: [
				{
					title: "status",
					value: "",
					searchQueries: [
						{
							operator: "OR",
							propName: "status",
							propVal: null,
							condition: " IS NULL ",
						},
					],
				},
				{
					title: "status",
					value: "",
					searchQueries: [
						{
							operator: "OR",
							propName: "status",
							propVal: "",
							condition: " = ",
						},
					],
				},
			],
		},
	};

	const [getSearchApiData, { data, loading }] = useOptionalSearchApi("", variablesForSearchAPI, {
		entity: "User",
		page,
		useSearchApi: true,
		formatDataKey: "user",
	});

	const makeSearchRequest = async () => {
		await getUserByPhone();
	};

	useEffect(() => {
		if (userIdByPhoneLoading && !loading) {
			setUserIdByPhoneLoading(false);
		}
	}, [loading]);

	useEffect(() => {
		makeSearchRequest();
	}, [hasPhoneNumber]);

	useEffect(() => {
		if (changeInPage) {
			getSearchApiData();
		}
	}, [page, changeInPage]);

	useEffect(() => {
		if (skipMountEffect.current) {
			skipMountEffect.current = false;
		} else {
			let searchChanged = false;
			if (prevSearchData && prevSearchData?.length >= searchData.length) {
				prevSearchData.forEach((singleSearch, index) => {
					if (
						singleSearch.selectedSearchValue !== searchData[index]?.selectedSearchValue
						|| singleSearch.selectedValueData.length !== searchData[index]?.selectedValueData.length
					) {
						searchChanged = true;
					}
				});
			}
			if (searchChanged) {
				setChangeInPage(true);
				setPage(1);
			}
		}
	}, [searchData]);

	useEffect(() => {
		const items: UserDetails[] = [];
		data?.forEach((element: any) => {
			items.push({
				...element,
				creat_ts: element?.creat_ts
					? moment(new Date(element.creat_ts)).format(DATE_FORMAT) : strings("UserSearchPage.not_available"),
				modif_ts: element?.modif_ts
					? moment(new Date(element.modif_ts)).format(DATE_FORMAT) : strings("UserSearchPage.not_available"),
			});
		});
		setUserData(items);
		setChangeInPage(false);
	}, [data]);

	return (
		<div ref={wrapperRef}>
			<Hero title={strings("NotFullyRegisteredUsersPage.hero_title")} pageHasTable />
			<div data-testid="search-card" className="bg-white p-5 rounded-lg -mt-24 mx-5 flex flex-col mb-5">
				<Accordion
					header={(
						<div className="flex flex-row justify-evenly items-center">
							<h5 className="mb-1">
								{strings("UserSearchPage.search")}
							</h5>
							<div className="pl-2">
								<TooltipHelper
									title={strings("UserSearchPage.search_helper_text")}
									placement={width < 769 ? "bottom" : "right"}
								/>
							</div>
						</div>
					)}
					content={(
						<div>
							<SearchBarContainer
								dropdownItems={NotRegisteredUserSearchColumns}
								handleData={updateSearchDropdownItems}
								data={searchData}
							/>
						</div>
					)}
					defaultOpen
				/>
				<Accordion
					header={(
						<h5 className="mb-1">
							{strings("UserSearchPage.filter")}
						</h5>
					)}
					content={(
						<div className="grid grid-cols-1 xs:grid-cols-2-max-20 sm:grid-cols-3-max-20 gap-2 lg:gap-4">
							<Filter
								id="column-filters"
								data={UserTableHeaders}
								filterName={strings("Dashboard.table_title")}
								applyFilter={applyFilter}
								selectedData={headers}
								showTitle={false}
								local_storage_key="non-registered-user-headers"
							/>
						</div>
					)}
					defaultOpen
				/>
			</div>
			<div className="bg-white p-5 rounded-lg my-5 mx-5">
				<div data-testid="users-data-table" className="my-0">
					<Table
						header={headers}
						data={userData}
						pagination={{
							onPageChange: (num) => {
								setChangeInPage(true);
								setPage(num);
							},
							hidePageNumber: true,
							scrollOnPageChange: true,
							page,
						}}
						onItemClick={navigateToUserDetails}
						showHeader
						isLoading={loading || userIdByPhoneLoading}
					/>
				</div>
			</div>
		</div>
	);
}
