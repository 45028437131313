/* eslint-disable react/require-default-props */
import React, { Fragment, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
import classNames from "../utils/classNames";
import Spinner from "./Spinner";

interface statCardProps {
	isVisible: boolean;
	title: string;
	message: string;
	ok_button_title?: string;
	ok_button_function?: () => void;
	cancel_button_title?: string;
	cancel_button_function: () => void;
	iconComponent?: any;
	okButtonStyle?: string;
	cancelButtonStyle?: string;
	onClose: () => void;
	isOkLoading?: boolean;
	okLoadingText?: string;
	id?: string;
	customComponent?: any;
}

export default function ConfirmationModal({
	id,
	isVisible,
	title,
	message,
	ok_button_title,
	ok_button_function,
	cancel_button_title,
	cancel_button_function,
	iconComponent,
	okButtonStyle,
	cancelButtonStyle,
	onClose,
	isOkLoading,
	okLoadingText,
	customComponent,
}: statCardProps) {
	const cancelButtonRef = useRef(null);

	useEffect(() => () => {
		onClose();
	}, []);

	return (
		<div>
			<Transition.Root show={isVisible} as={Fragment}>
				<Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={cancel_button_function}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>
					<div className="fixed z-10 inset-0 overflow-y-auto" data-testid={`${id}__wrapper`}>
						<div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel
									className={classNames("relative bg-white rounded-lg text-left",
										"overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full")}
								>
									<div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4" data-testid={`${id}__modal`}>
										<div className="sm:flex sm:items-start">
											<div className={classNames("mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12",
												"rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10")}
											>
												{iconComponent || <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />}
											</div>
											<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
												<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
													{title}
												</Dialog.Title>
												<div className="mt-2">
													<p className="text-sm text-gray-500">
														{message}
													</p>
													{customComponent}
												</div>
											</div>
										</div>
									</div>
									<div
										className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse"
										data-testid={`${id}__actions`}
									>
										<div data-testid="ok-button-0">
											{ok_button_title && (
												<button
													type="button"
													className={classNames("w-full inline-flex justify-center rounded-md border",
														"shadow-sm px-4 py-2 bg-red-600 text-base hover:bg-red-700 ",
														"focus:outline-none border-transparent font-medium text-white ",
														"focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm",
														okButtonStyle!)}
													onClick={ok_button_function}
												>
													{isOkLoading ? <Spinner text={okLoadingText} /> : ok_button_title}
												</button>
											)}
										</div>
										<div data-testid="cancel-button-0">
											{cancel_button_title && (

												<button
													type="button"
													className={classNames("mt-3 w-full inline-flex justify-center rounded-md border ",
														"px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50",
														"border-gray-300 shadow-sm focus:ring-offset-2",
														"focus:outline-none focus:ring-2 focus:outline-none",
														"focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm ",
														cancelButtonStyle!)}
													onClick={cancel_button_function}
													ref={cancelButtonRef}
												>
													{cancel_button_title}
												</button>
											)}
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</div>
	);
}
