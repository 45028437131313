import * as React from "react";
import { useMemo } from "react";
import { ConfigConst, cu } from "@credo/utilities";
import * as d3 from "d3";
import { CredoGraph } from "./credo-graph";
import styles from "../widgets/score-widget.module.css";
import { classNames } from "../common";
import { CredoGraphData } from "./types";
import { ScoreWidget } from "../widgets/score-widget";

export type CredoGraphWidgetProps = {
	showSettings: boolean; /* future feature */
	// eslint-disable-next-line react/require-default-props
	isLoading: boolean;
	cachedData: CredoGraphData | undefined;
}

const FORMAT_SCORE = (s: number | undefined | null) => ((s || s === 0) ? Math.round(s) : "...");

export const CredoGraphWidget = ({
	showSettings = false,
	isLoading = true,
	cachedData,
}: CredoGraphWidgetProps) => {
	const overAllScore = useMemo(() => {
		if (cachedData?.overallScore || cachedData?.overallScore === 0) {
			return cachedData.overallScore > ConfigConst.minValueForThousandSteps
				? cu.formatNumberWithThousandsSep(cachedData.overallScore) : FORMAT_SCORE(cachedData.overallScore);
		}
		return FORMAT_SCORE(null);
	}, [cachedData]);
	const renderGraphIcon = () => {
		if (isLoading) {
			return null;
		}
		if (!cachedData?.overallScore) {
			return (
				<circle
					className={classNames(styles.iconContainer, isLoading ? styles.loading : styles.loaded)}
					cx="10"
					cy="10"
					r={13}
				/>
			);
		}
		return (
			<React.Fragment>
				<svg width="28px" height="28px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
					<g id="icon-group" transform="translate(1.00000, 1.00000)">
						<circle
							className={classNames(styles.iconContainer, isLoading ? styles.loading : styles.loaded)}
							cx="14"
							cy="14"
							r={14}
							height={28}
							width={28}
						/>
						<CredoGraph
							key={FORMAT_SCORE(isLoading ? null : cachedData.overallScore).toString()}
							data={cachedData as CredoGraphData}
							iconMode
							height={28}
							width={28}
						/>
					</g>
				</svg>
			</React.Fragment>
		);
	};
	const format = d3.format("d");
	return (
		<ScoreWidget
			id="credo-graph-widget"
			isLoading={isLoading}
			showSettings={showSettings}
			settingsIcon={() => null}
			score={overAllScore !== "..." ? Number(overAllScore) : undefined}
			format={format}
			icon={renderGraphIcon}
			back={() => null}
		/>
	);
};
