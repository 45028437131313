/* eslint-disable react/require-default-props */
import React, {
	Fragment, useEffect, useRef, useState,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { IoMdClose } from "react-icons/io";
import classNames from "../../../utils/classNames";
import { strings } from "../../../i18n/config";

interface SuspendedUserSectionProps {
	isVisible: boolean;
	ok_button_function?: (data: any) => void;
	cancel_button_function: () => void;
	okButtonStyle?: string;
	cancelButtonStyle?: string;
}

export default function SuspendUserModal({
	isVisible,
	ok_button_function,
	cancel_button_function,
	okButtonStyle,
	cancelButtonStyle,
}: SuspendedUserSectionProps) {
	const cancelButtonRef = useRef(null);
	const [reason, setReason] = useState("");
	const [period, setPeriod] = useState("");
	const [isDisabled, setIsDisabled] = useState(true);

	const onReasonChange = (e: any) => {
		setReason(e.target.value);
	};

	const onPeriodChange = (e: any) => {
		setPeriod(e.target.value);
	};

	useEffect(() => {
		if (reason && period) {
			setIsDisabled(false);
		} else {
			setIsDisabled(true);
		}
	}, [reason, period]);

	const closeModal = () => {
		setIsDisabled(true);
		setPeriod("");
		setReason("");
		if (cancel_button_function) cancel_button_function();
	};

	const onSubmit = () => {
		if (ok_button_function) ok_button_function({ reason, period });
		closeModal();
	};

	return (
		<div>
			<Transition.Root show={isVisible} as={Fragment}>
				<Dialog as="div" className="relative z-10" onClose={closeModal}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>
					<div className="fixed z-10 inset-0 overflow-y-auto">
						<div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel
									className={classNames("relative bg-white rounded-lg text-left",
										"overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full xs:w-full")}
								>
									<div>
										<div className={classNames("modal-header flex flex-shrink-0 items-center",
											"justify-between p-4 border-b border-gray-200 rounded-t-md")}
										>
											<h5 className="text-xl font-medium leading-normal text-gray-800">
												{strings("SuspendUserModal.suspend_user")}
											</h5>
											<IoMdClose onClick={cancel_button_function} size={20} />
										</div>
										<div className="flex flex-col p-5 justify-center">
											<span
												className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
											>
												{strings("SuspendUserModal.reason")}
											</span>
											<div className="inline-block relative w-full">
												<select
													value={reason}
													className={classNames("form-select form-select-sm appearance-none bg-no-repeat",
														"block w-full px-2 py-2 text-sm font-normal text-gray-700 bg-white bg-clip-padding",
														"border border-solid border-gray-300 rounded transition ease-in-out m-0 mb-5",
														"focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none")}
													aria-label=".form-select-sm example"
													onChange={onReasonChange}
												>
													<option value="">{strings("SuspendUserModal.select_reason")}</option>
													<option>{strings("SuspendUserModal.account_security")}</option>
													<option>{strings("SuspendUserModal.abusive_content")}</option>
													<option>{strings("SuspendUserModal.spam")}</option>
													<option>{strings("SuspendUserModal.imposter")}</option>
													<option>{strings("SuspendUserModal.other")}</option>
												</select>
												<div className={classNames("pointer-events-none absolute inset-y-0 right-0",
													"bottom-4 flex items-center px-2 py-2 text-gray-700")}
												>
													<svg
														className="fill-current h-4 w-4"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 20 20"
													>
														<path
															d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
														/>
													</svg>
												</div>
											</div>
											<span
												className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
											>
												{strings("SuspendUserModal.for_period")}
											</span>
											<div className="inline-block relative w-full">
												<select
													value={period}
													className={classNames("form-select form-select-sm appearance-none bg-no-repeat",
														"block w-full px-2 py-2 text-sm font-normal text-gray-700 bg-white bg-clip-padding",
														"border border-solid border-gray-300 rounded transition ease-in-out m-0 mb-5",
														"focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none")}
													aria-label=".form-select-sm example"
													onChange={onPeriodChange}
												>
													<option value="">{strings("SuspendUserModal.select_period")}</option>
													<option value="7">{strings("SuspendUserModal.7_days")}</option>
													<option value="14">{strings("SuspendUserModal.14_days")}</option>
													<option value="30">{strings("SuspendUserModal.30_days")}</option>
													<option value="0">{strings("SuspendUserModal.forever")}</option>
												</select>
												<div className={classNames("pointer-events-none absolute inset-y-0 right-0",
													"bottom-4 flex items-center p-2 text-gray-700")}
												>
													<svg
														className="fill-current h-4 w-4"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 20 20"
													>
														<path
															d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
														/>
													</svg>
												</div>
											</div>
										</div>
										<div
											className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse"
										>
											<div data-testid="ok-button-0">
												<button
													type="button"
													className={classNames("w-full inline-flex justify-center rounded-md border",
														"shadow-sm px-4 py-2 bg-red-600 text-base hover:bg-red-700 sm:text-sm",
														"focus:outline-none border-transparent font-medium text-white",
														"disabled:opacity-50 disabled:ring-none",
														"focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto",
														okButtonStyle!)}
													disabled={isDisabled}
													onClick={onSubmit}
												>
													{strings("SuspendUserModal.suspend")}
												</button>
											</div>
											<div data-testid="cancel-button-0">
												<button
													type="button"
													className={classNames("mt-3 w-full inline-flex justify-center rounded-md border ",
														"px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50",
														"border-gray-300 shadow-sm focus:ring-offset-2",
														"focus:outline-none focus:ring-2 focus:outline-none",
														"focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm ",
														cancelButtonStyle!)}
													onClick={closeModal}
													ref={cancelButtonRef}
												>
													{strings("SuspendUserModal.cancel")}
												</button>
											</div>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</div>
	);
}
