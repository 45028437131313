import * as React from "react"
import {SVGAttributes} from "react";

const SvgComponent = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const {
		stroke = "var(--primary)",
	} = props
	return (
		<svg width={50} height={48}  viewBox="0 0 50 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
			<g stroke={stroke} fill="none" fillRule="evenodd">
				<path d="m16.36 30.818-2.6-8.001M28.865 33.794h-8.407M35.563 22.817l-2.6 8.001M27.189 13.055l6.809 4.948M15.326 18.002l6.805-4.944M39.662 19.842a3.132 3.132 0 1 1-6.264 0 3.132 3.132 0 0 1 6.264 0ZM27.793 11.219a3.132 3.132 0 1 1-6.263 0 3.132 3.132 0 0 1 6.263 0ZM35.128 33.794a3.132 3.132 0 1 1-6.263 0 3.132 3.132 0 0 1 6.263 0ZM20.458 33.794a3.132 3.132 0 1 1-6.263 0 3.132 3.132 0 0 1 6.263 0ZM15.925 19.842a3.132 3.132 0 1 1-6.263 0 3.132 3.132 0 0 1 6.263 0ZM30.925 23.632a6.263 6.263 0 1 1-12.527 0 6.264 6.264 0 0 1 12.527 0Z" />
				<path d="M24.844 24.38a1.99 1.99 0 0 0 1.989-1.99c0-1.096-.892-1.99-1.99-1.99a1.992 1.992 0 0 0-1.988 1.99 1.99 1.99 0 0 0 1.989 1.99ZM21.14 29.154c.172-2.046 1.136-2.875 1.695-3.189l.136-.076.138.085 1.735 1.24 1.734-1.24.138-.085.137.076c.56.314 1.523 1.144 1.695 3.19" />
				<path d="M30.925 23.632a6.263 6.263 0 1 1-12.527 0 6.264 6.264 0 0 1 12.527 0ZM34.292 3.632a3.132 3.132 0 1 1-6.264 0 3.132 3.132 0 0 1 6.264 0ZM21.295 3.632a3.132 3.132 0 1 1-6.263 0 3.132 3.132 0 0 1 6.263 0ZM34.292 43.632a3.132 3.132 0 1 1-6.264 0 3.132 3.132 0 0 1 6.264 0ZM21.295 43.632a3.132 3.132 0 1 1-6.263 0 3.132 3.132 0 0 1 6.263 0ZM46.659 26.61a3.132 3.132 0 1 1-1.935-5.957 3.132 3.132 0 0 1 1.935 5.957ZM42.642 14.25a3.132 3.132 0 1 1-1.934-5.958 3.132 3.132 0 0 1 1.934 5.957ZM8.617 38.97a3.132 3.132 0 1 1-1.935-5.957 3.132 3.132 0 0 1 1.934 5.958ZM4.6 26.61a3.132 3.132 0 1 1-1.935-5.957A3.132 3.132 0 0 1 4.6 26.61ZM40.707 38.97a3.132 3.132 0 1 1 1.935-5.956 3.132 3.132 0 0 1-1.935 5.957ZM6.681 14.25a3.132 3.132 0 1 1 1.935-5.958 3.132 3.132 0 0 1-1.935 5.957ZM39.145 9.433l-1.618-1.176M35.306 6.643l-1.618-1.175M44.723 20.653l-.618-1.902M43.261 16.151l-.619-1.902M42.642 33.014l.619-1.902M44.106 28.508l.618-1.902M33.691 41.793l1.618-1.176M37.525 39.007l1.618-1.176M21.295 43.631h2M26.029 43.631h2M10.18 37.831l1.618 1.176M14.013 40.616l1.618 1.176M4.6 26.61l.618 1.902M6.063 31.112l.618 1.902M6.681 14.25l-.618 1.901M5.218 18.751 4.6 20.653M15.637 5.467l-1.618 1.176M11.795 8.258l-1.618 1.176M28.029 3.631h-2M23.295 3.631h-2" />
			</g>
		</svg>
	)
}

export default SvgComponent
