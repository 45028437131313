/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGAttributes } from "react";

const ProfileVerified = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--primary)",
	} = props;
	return (
		<svg
			width="11px"
			height="11px"
			viewBox="0 0 11 11"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<title>verifiedSearch_credo</title>
			<g id="Home-/-Feed---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Home---Export" transform="translate(-210.000000, -316.000000)" stroke={stroke}>
					<g id="verifiedSearch_credo" transform="translate(210.000000, 316.000000)">
						<path
							d="M9.88622222,5.2209 C9.88622222,7.79756667 7.79733333,9.88645556 5.22066667,9.88645556 C2.644,9.88645556 0.555111111,7.79756667 0.555111111,5.2209 C0.555111111,2.64423333 2.644,0.555344444 5.22066667,0.555344444 C7.79733333,0.555344444 9.88622222,2.64423333 9.88622222,5.2209 Z"
							id="Stroke-1"
						/>
						<polyline
							id="Stroke-3"
							strokeLinecap="round"
							points="3.49433333 5.2209 5.221 6.77645556 7.53211111 3.57201111"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default ProfileVerified;
