import React, { SVGAttributes } from "react";

const News = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { stroke = "var(--gray-dark)", fill = "var(--gray-dark)" } = props;
	return (
		<svg
			width="20px"
			height="20px"
			viewBox="0 0 20 20"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<title>News</title>
			<g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Icon---News---Inactive" fill={fill} fillRule="nonzero">
					<g id="newspaper_FILL0_wght400_GRAD0_opsz48-Copy" transform="translate(0.000000, 1.000000)">
						<path d="M1.5,18 C1.0875,18 0.734375,17.853125 0.440625,17.559375 C0.146875,17.265625 0,16.9125 0,16.5 L0,0 L1.675,1.675 L3.325,0 L5,1.675 L6.675,0 L8.325,1.675 L10,0 L11.675,1.675 L13.325,0 L15,1.675 L16.675,0 L18.325,1.675 L20,0 L20,16.5 C20,16.9125 19.853125,17.265625 19.559375,17.559375 C19.265625,17.853125 18.9125,18 18.5,18 L1.5,18 Z M1.5,16.5 L9.25,16.5 L9.25,9.5 L1.5,9.5 L1.5,16.5 Z M10.75,16.5 L18.5,16.5 L18.5,13.75 L10.75,13.75 L10.75,16.5 Z M10.75,12.25 L18.5,12.25 L18.5,9.5 L10.75,9.5 L10.75,12.25 Z M1.5,8 L18.5,8 L18.5,5 L1.5,5 L1.5,8 Z" id="Shape" />
					</g>
				</g>
			</g>
		</svg>
	);
};

export default News;
