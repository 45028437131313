import * as React from "react"
import {SVGAttributes} from "react";

const SvgComponent = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { stroke = "var(--gray-dark)" } = props;
	return (
		<svg width={17} height={20} viewBox="0 0 17 20" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g fill="none" fillRule="evenodd">
				<path
					stroke={stroke}
					d="M4.656 3.193h3.563M12.375 10v8.79a.71.71 0 0 1-.71.71H1.21a.71.71 0 0 1-.71-.71V1.21A.71.71 0 0 1 1.21.5h10.455a.71.71 0 0 1 .71.71v1.391M7.328 11.188l-1.781 1.781M7.328 12.969l-1.781-1.781M10.691 11.188 8.91 12.969M10.691 12.969 8.91 11.188M3.963 11.188l-1.781 1.781M3.963 12.969l-1.781-1.781M7.328 7.863 5.547 9.644M7.328 9.644 5.547 7.863M3.963 7.863 2.182 9.644M3.963 9.644 2.182 7.863"
				/>
				<path
					d="M7.328 17.125a.891.891 0 1 1-1.782 0 .891.891 0 0 1 1.782 0"
					fill={stroke}
				/>
				<path
					d="M16.075 6.3a3.7 3.7 0 1 1-7.4 0 3.7 3.7 0 0 1 7.4 0Z"
					stroke={stroke}
				/>
				<path stroke={stroke} d="m11.006 6.3 1.369 1.233 1.833-2.54" />
			</g>
		</svg>
	);
}

export default SvgComponent
