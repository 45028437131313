import React, { SVGAttributes } from "react";

const Settings = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { stroke = "var(--gray-dark)", fill } = props;
	return (
		<svg width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg"
				 xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g transform="translate(-30.000000, -436.000000)" stroke={stroke} fill={fill}>
					<g transform="translate(30.000000, 171.000000)">
						<g transform="translate(0.000000, 265.000000)">
							<polygon id="Stroke-3"
											 points="19.5934737 7.22926316 17.4576842 3.52926316 13.5155789 4.34926316 12.2555789 0.526105263 7.984 0.526105263 6.724 4.34926316 2.78189474 3.52926316 0.646105263 7.22926316 3.32715789 10.2324211 0.646105263 13.2355789 2.78189474 16.9355789 6.724 16.1145263 7.984 19.9387368 12.2555789 19.9387368 13.5166316 16.1145263 17.4576842 16.9355789 19.5934737 13.2355789 16.9124211 10.2324211"/>
							<path
								d="M10.1203158,13.3732105 C11.8618947,13.3732105 13.2782105,11.9568947 13.2782105,10.2153158 C13.2782105,8.47373684 11.8618947,7.05663158 10.1203158,7.05663158 C8.37952632,7.05663158 6.96242105,8.47373684 6.96242105,10.2153158 C6.96242105,11.9568947 8.37952632,13.3732105 10.1203158,13.3732105 Z"
								id="Stroke-1" fill={fill ? "white" : "none"}/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}

export default Settings;
