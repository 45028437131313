import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
	createSearchParams, useLocation, useNavigate, useSearchParams,
} from "react-router-dom";
import { usePrevious } from "react-use";
import Accordion from "../../gen-comp/Accordion";
import ConfirmationModal from "../../gen-comp/ConfirmationModal";
import Filter from "../../gen-comp/Filter";
import Hero from "../../gen-comp/Hero";
import SearchBarContainer from "../../gen-comp/SearchBar/SearchBarContainer";
import Table from "../../gen-comp/table";
import TooltipHelper from "../../gen-comp/TooltipHelper";
import { useQuery } from "../../hooks/graphql/useQuery";
import useDimensions from "../../hooks/useDimensions";
import useOptionalSearchApi from "../../hooks/useOptionalSearchApi";
import { strings } from "../../i18n/config";
import {
	AUTH_URL, COMMENT_DETAILS_PAGE, COMMUNITIES_DETAILS_PAGE, USER_DETAILS_PAGE,
} from "../../routes/constants";
import { PAGINATION_LIMIT } from "../../utils/constants";
import { GetWhereClauseVariables } from "../../utils/getWhereClause";
import {
	CommentDetails,
	DatabaseFields,
	NoOfReportedOptions, SearchBarElementInterface, TableHeader, YesNoOptions,
} from "../../utils/types";
import { DATE_FORMAT } from "../../utils/utils";
import {
	CommentTableHeaders, SearchColumns, CommentReportedOptions, CommentStreamHeaders,
} from "./constants";
import { deleteCommentQuery, getCommentsQueries, GetCommentsVariables } from "./queries";

export default function CommentSearchPage() {
	const location = useLocation();
	const navigate = useNavigate();

	const [searchParams] = useSearchParams();
	const searchDataParams = searchParams.get("searchData");
	const reportedParams = searchParams.get("reported");
	const headerParams = searchParams.get("headers");

	// eslint-disable-next-line no-nested-ternary
	const [headers, setHeaders] = useState<TableHeader[]>(headerParams ? JSON.parse(headerParams)
		: localStorage.getItem("comment-headers")
			? JSON.parse(localStorage.getItem("comment-headers") || "") : [
				...CommentTableHeaders.filter((e) => e.isDefault),
				...CommentStreamHeaders.slice(0, 2),
			]);
	// to skip the function call on first mount in useEffect
	const skipMountEffect = useRef<boolean>(true);
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const [selectedComment, setSelectedComment] = useState<CommentDetails | null>(null);
	const [page, setPage] = useState(1);
	const [changeInPage, setChangeInPage] = useState<boolean>(true);
	const [, width] = useDimensions();
	// const [subCommentFilterList, setSubCommentFilterList] = useState<any>(SubCommFilterList);
	const [commentsData, setCommentsData] = useState<CommentDetails[]>([]);
	const [searchData, setSearchData] = useState<SearchBarElementInterface[]>(
		searchDataParams ? JSON.parse(searchDataParams) : [],
	);
	const [reportedOptions, setReportedOptions] = useState<TableHeader[]>(
		reportedParams ? JSON.parse(reportedParams) : CommentReportedOptions,
	);
	const prevSearchData = usePrevious(searchData);

	useEffect(() => {
		const paramString = `searchData=${JSON.stringify(searchData)}`
			+ `&reported=${JSON.stringify(reportedOptions)}`
			+ `&headers=${JSON.stringify(headers)}`;
		if (window) {
			window.history.replaceState(null, "", `${location.pathname}?${paramString}`);
		}
	}, [reportedOptions, searchData, headers]);

	useEffect(() => {
		if (searchDataParams) {
			setSearchData(JSON.parse(searchDataParams));
		}
		if (headerParams) {
			setHeaders(JSON.parse(headerParams));
		}
		if (reportedParams) {
			setReportedOptions(JSON.parse(reportedParams));
		}
	}, []);

	const closeModal = () => {
		setShowDeleteModal(false);
		setSelectedComment(null);
	};

	const applyReportedFilter = (items: TableHeader[]) => {
		setReportedOptions(items);
	};

	const showConfirmationModal = (item: any) => {
		setSelectedComment(item);
		setShowDeleteModal(true);
	};

	const onCancelButton = () => {
		setShowDeleteModal(false);
	};

	const getNoOfReportedQuery = () => {
		if (reportedOptions.length !== CommentReportedOptions.length && reportedOptions.length !== 0) {
			let query = "";
			if (reportedOptions.length === 2) {
				const filteredArray = CommentReportedOptions.filter((value: TableHeader) => !reportedOptions.includes(value));
				filteredArray.forEach((item: { value: NoOfReportedOptions; }) => {
					if (item.value === NoOfReportedOptions.ZERO) {
						query += " nb_rep_gte:1, ";
					} else if (item.value === NoOfReportedOptions.ONE_TO_TEN) {
						query += " OR: { nb_rep_lte:1, nb_rep_gt:10,}, ";
					} else {
						query += "nb_rep_lte:10, ";
					}
				});
			} else {
				reportedOptions.forEach((item) => {
					if (item.value === NoOfReportedOptions.ZERO) {
						query += " nb_rep_lte:0, ";
					} else if (item.value === NoOfReportedOptions.ONE_TO_TEN) {
						query += "nb_rep_lte:10, nb_rep_gte:1, ";
					} else {
						query += "nb_rep_gte:10, ";
					}
				});
			}
			return `${query}`;
		}
		return "";
	};

	const applyFilter = (items: TableHeader[]) => {
		setHeaders(items);
	};

	const updateSearchDropdownItems = (items: SearchBarElementInterface[]) => {
		setSearchData(items);
	};

	const variables: GetCommentsVariables = {
		headers: CommentTableHeaders,
		searchData,
		otherFilterQuery: getNoOfReportedQuery()
			? `{${getNoOfReportedQuery()}}` : "",
		offset: (page - 1) * PAGINATION_LIMIT,
	};

	const getOtherFilters = () => {
		let finalFilterQuery: GetWhereClauseVariables["otherFilterQuery"] = {};

		if (reportedOptions.length !== CommentReportedOptions.length) {
			finalFilterQuery = {
				...finalFilterQuery,
				reportedOptions,
			};
		}
		return finalFilterQuery;
	};

	const variablesForSearchAPI = {
		searchData: searchData.filter((searchEl) => searchEl.selectedSearchValue),
		otherFilterQuery: getOtherFilters(),
	};

	const useSearchApi = (reportedOptions
		.some((reported) => reported.value === NoOfReportedOptions.ZERO)
		&& reportedOptions.length !== CommentReportedOptions.length);

	const [getSearchApiData, { data, loading }] = useOptionalSearchApi(getCommentsQueries(variables), variablesForSearchAPI, {
		entity: "Comment",
		page,
		useSearchApi,
		formatDataKey: "comment",
	});

	useEffect(() => {
		setPage(1);
		setChangeInPage(true);
	}, [reportedOptions]);

	useEffect(() => {
		if (changeInPage) {
			getSearchApiData();
		}
	}, [page, changeInPage]);

	// const applySubcommentFilter = (items: TableHeader[]) => {
	// 	setSubCommentFilterList(items);
	// };

	useEffect(() => {
		if (skipMountEffect.current) {
			skipMountEffect.current = false;
		} else {
			let searchChanged = false;
			if (prevSearchData?.length === searchData.length) {
				prevSearchData.forEach((singleSearch, index) => {
					if (
						singleSearch.selectedSearchValue !== searchData[index].selectedSearchValue
						|| singleSearch.selectedValueData.length !== searchData[index].selectedValueData.length
					) {
						searchChanged = true;
					}
				});
			}
			if (searchChanged) {
				setChangeInPage(true);
				setPage(1);
			}
		}
	}, [searchData]);

	useEffect(() => {
		const items: any[] = [];
		data?.forEach((element: any) => {
			items.push({
				...element,
				creat_ts: element?.creat_ts
					? moment(new Date(element.creat_ts)).format(DATE_FORMAT) : strings("CommunitySearchPage.not_available"),
				modif_ts: element?.modif_ts
					? moment(new Date(element.modif_ts)).format(DATE_FORMAT) : strings("CommunitySearchPage.not_available"),
				streamId: element?.post && element?.post[0]?.stream && element?.post[0]?.stream[0]?.streamId,
				streamName: element?.post && element?.post[0]?.stream && element?.post[0]?.stream[0]?.name,
				parentComment: element?.parentComment?.length > 0 ? YesNoOptions.YES : YesNoOptions.NO,
				reported_ts: element?.reported_ts
					? moment(new Date(element.reported_ts)).format(DATE_FORMAT) : strings("CommunitySearchPage.not_available"),
			});
		});
		setCommentsData(items);
		setChangeInPage(false);
	}, [data]);

	const [deleteUserComment, { loading: isCommentDeleteLoading }] = useQuery<Array<Object>>("", {
		formatDataKey: "comment",
		isMutation: true,
	});

	const deleteComment = () => {
		deleteUserComment({
			mutation: deleteCommentQuery({ fields: CommentTableHeaders, commentUuid: selectedComment?.commentUuid || null }),
		});
		onCancelButton();
		getSearchApiData();
	};

	const redirectToCommentDetailsPage = (item: any) => {
		navigate({
			pathname: AUTH_URL + COMMENT_DETAILS_PAGE,
			search: `?${createSearchParams({
				commentUuid: item?.commentUuid,
			})}`,
		});
	};

	const redirectToUserDetailsPage = (item: any) => {
		window.open(`${AUTH_URL}${USER_DETAILS_PAGE}?${createSearchParams({
			isNewTab: "y",
			...item,
		})}`, "_blank");
	};

	const onClickColumnItem = (item: any) => {
		switch (item?.value) {
			case DatabaseFields.USERID:
				redirectToUserDetailsPage({ userId: item?.user_id });
				break;
			case DatabaseFields.PROFID:
				redirectToUserDetailsPage({ prof_id: item?.prof_id, mode: item?.mode });
				break;
			case DatabaseFields.STREAMID:
				window.open(`${AUTH_URL}${COMMUNITIES_DETAILS_PAGE}?${createSearchParams({
					communityId: item?.streamId,
					isNewTab: "y",
				})}`, "_blank");
				break;
			default:
				redirectToCommentDetailsPage(item);
				break;
		}
	};

	const redirectToCommentDetailsPageWithReportedCount = (item: any) => {
		navigate({
			pathname: AUTH_URL + COMMENT_DETAILS_PAGE,
			search: `?${createSearchParams({
				commentUuid: item?.commentUuid,
				reportedCount: item?.nb_rep,
			})}`,
		});
	};

	const renderColumn = (props: { item: any; header: any; }) => {
		const { item, header } = props;
		if (!header || !item) {
			return null;
		}
		return (
			<button
				type="button"
				className="w-full truncate underline text-blue-600 hover:text-blue-800 "
				onClick={() => redirectToCommentDetailsPageWithReportedCount(item)}
			>
				{item[header.value] || ""}
			</button>
		);
	};

	const filterHeadersData = [
		...CommentTableHeaders.slice(0, 3),
		CommentStreamHeaders[2],
		...CommentTableHeaders.slice(3),
		...CommentStreamHeaders.slice(0, 2),
	];

	return (
		<div>
			<Hero title={strings("CommentSearchPage.hero_title")} pageHasTable />
			<div data-testid="search-card" className="bg-white p-5 rounded-lg -mt-24 mx-5 flex flex-col mb-5">
				<Accordion
					header={(
						<div className="flex flex-row justify-evenly items-center">
							<h5 className="mb-1">
								{strings("CommunitySearchPage.search")}
							</h5>
							<div className="pl-2">
								<TooltipHelper
									title={strings("UserSearchPage.search_helper_text")}
									placement={width < 769 ? "bottom" : "right"}
								/>
							</div>
						</div>
					)}
					content={(
						<div>
							<SearchBarContainer
								dropdownItems={SearchColumns}
								handleData={updateSearchDropdownItems}
								data={searchData}
							/>
						</div>
					)}
					defaultOpen
				/>
				<Accordion
					header={(
						<h5 className="mb-1">
							{strings("UserSearchPage.filter")}
						</h5>
					)}
					content={(
						<div
							data-testid="comment-filter"
							className="grid grid-cols-1 xs:grid-cols-2-max-20 sm:grid-cols-3-max-20 lg:grid-cols-4-max-20 gap-2 lg:gap-4"
						>
							<Filter
								id="column-filters"
								data={filterHeadersData}
								filterName={strings("CommentSearchPage.table_title")}
								applyFilter={applyFilter}
								selectedData={headers}
								showTitle={false}
								local_storage_key="comment-headers"
							/>
							{/* <Filter
								data={subCommentFilterList}
								filterName={strings("CommentSearchPage.sub_comment")}
								applyFilter={applySubcommentFilter}
								selectedData={subCommentFilterList}
								showTitle={false}
							/> */}
							<Filter
								id="reported-filter"
								data={CommentReportedOptions}
								filterName={strings("CommentSearchPage.no_of_reported")}
								applyFilter={applyReportedFilter}
								selectedData={reportedOptions}
								showTitle={false}
							/>
						</div>
					)}
					defaultOpen
				/>
			</div>
			<div className="bg-white p-5 rounded-lg my-5 mx-5">
				<div data-testid="comment-data-table" className="my-0">
					<Table
						header={headers.sort((a, b) => filterHeadersData.indexOf(a) - filterHeadersData.indexOf(b))}
						data={commentsData}
						showHeader
						isLoading={loading}
						onClickDelete={showConfirmationModal}
						showActionButtons
						pagination={{
							onPageChange: (num) => {
								setChangeInPage(true);
								setPage(num);
							},
							hidePageNumber: true,
							scrollOnPageChange: true,
							page,
						}}
						CustomColumnComponent={renderColumn}
						onItemClick={onClickColumnItem}
					/>
				</div>
				<ConfirmationModal
					id="comment-delete"
					isVisible={showDeleteModal}
					title={strings("CommentSearchPage.delete_comment")}
					message={`${strings("CommentSearchPage.delete_comment_message", { commentUuid: selectedComment?.commentUuid })}`}
					ok_button_title={strings("CommentSearchPage.yes")}
					ok_button_function={deleteComment}
					cancel_button_title={strings("CommentSearchPage.no")}
					cancel_button_function={onCancelButton}
					onClose={closeModal}
					isOkLoading={isCommentDeleteLoading}
					okLoadingText={strings("CommentSearchPage.deleting")}
				/>
			</div>
		</div>
	);
}
