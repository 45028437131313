import React from "react";
import Accordion from "../../../gen-comp/Accordion";
import { strings } from "../../../i18n/config";
import {
	UserDetails, UserQualificationInterface,
} from "../../../utils/types";
import classNames from "../../../utils/classNames";
import convertDateInDisplayFormat from "../../../utils/convertDateInDisplayFormat";
import ReadMore from "../../../gen-comp/ReadMore";
import { isNullOrEmptyArray } from "../../../utils/utils";

interface UserQualificationProps {
	/**
	 * User details which also contains user's education data
	 * */
	data: UserDetails;
}

const UserQualification: React.FC<UserQualificationProps> = (props: UserQualificationProps) => {
	const {
		data,
	} = props;

	// @ts-ignore
	if (!(data?.qual_stream?.qual_post?.length > 0)) {
		return null;
	}

	return (
		<div className="bg-white p-3 rounded-lg mx-5 mb-4 min-h-fit max-h-full" data-testid="user-qualification-accordion">
			<Accordion
				header={(
					<h5 className="mb-0">
						{strings("UserDetailsPage.user_qualification")}
					</h5>
				)}
				content={(
					<div className="flex flex-col pb-2" data-testid="user-qualification-content">
						{data?.qual_stream?.qual_post?.map((userQualification: UserQualificationInterface, index: number) => (
							<div
								className={classNames(
									"flex flex-col mb-3",
									data?.qual_stream?.qual_post && data?.qual_stream?.qual_post?.length - 1 !== index
										? "border-b border-gray-light pb-3" : "",
								)}
								key={userQualification.postUuid}
							>
								<div className="flex flex-col">
									<div className="flex flex-row mb-3 items-center justify-between">
										<div className="flex flex-col">
											{!isNullOrEmptyArray(userQualification.school) && (
												<h5 className="capitalize" data-testid="user-qualification__school-name">
													{userQualification.school[0].name}
												</h5>
											)}
											{!isNullOrEmptyArray(userQualification.exp_degree) && (
												<span
													className="text-gray-dark text-xs capitalize"
													data-testid="user-qualification__degree-name"
												>
													{userQualification.exp_degree[0].degreeName}
												</span>
											)}
										</div>
										{!isNullOrEmptyArray(userQualification.exp_degree) && (
											<span
												className="text-xs text-gray-dark capitalize text-right"
												data-testid="user-qualification__date"
											>
												{convertDateInDisplayFormat(
												// @ts-ignore,
													userQualification.exp_degree[0].startDate,
													userQualification.exp_degree[0].endDate,
													userQualification.exp_degree[0].isCrtDeg,
												)}
											</span>
										)}
									</div>
									{!isNullOrEmptyArray(userQualification.exp_degree) && userQualification.exp_degree[0].degreeDescr && (
										<ReadMore className="font-thin text-sm" id="user-qualification__description">
											{userQualification.exp_degree[0].degreeDescr}
										</ReadMore>
									)}
								</div>
							</div>
						))}
					</div>
				)}
			/>
		</div>
	);
};

export default UserQualification;
