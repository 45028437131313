import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import {
	createSearchParams, useNavigate,
} from "react-router-dom";
import Filter from "../../gen-comp/Filter";
import Hero from "../../gen-comp/Hero";
import Table from "../../gen-comp/table";
import { useQuery } from "../../hooks/graphql/useQuery";
import { strings } from "../../i18n/config";
import { TableHeader } from "../../utils/types";
import { deleteStreamQuery, getNewCommunitiesQueries } from "./queries";
import {
	StreamTableHeaders, TimestampsOptions,
} from "./constants";
import { DATE_FORMAT } from "../../utils/utils";
import { AUTH_URL, COMMUNITIES_DETAILS_PAGE } from "../../routes/constants";
import ConfirmationModal from "../../gen-comp/ConfirmationModal";
import { PAGINATION_LIMIT } from "../../utils/constants";
import Accordion from "../../gen-comp/Accordion";

export default function NewCommunityPage() {
	const navigate = useNavigate();
	const wrapperRef = useRef(null);
	const [headers, setHeaders] = useState<TableHeader[]>(StreamTableHeaders.filter((e) => e.isDefault));
	const [selectedCommunity, setSelectedCommunity] = useState<any>(null);
	const [isVisible, setIsVisible] = useState<boolean>(false);
	const [communitiesData, setCommunitiesData] = useState<any>([]);
	const [page, setPage] = useState(1);
	const [changeInPage, setChangeInPage] = useState<boolean>(true);
	const [timestamp, setTimestamps] = useState<TableHeader[]>(TimestampsOptions.filter((e) => e.value === "24"));

	const applyTimestampFilter = (items: TableHeader[]) => {
		setTimestamps(items);
	};

	const variables: any = {
		headers: StreamTableHeaders,
		offset: (page - 1) * PAGINATION_LIMIT,
		timestamp,
	};
	const [getNewCommunitiesData, { data, loading }] = useQuery<Array<Object>>(getNewCommunitiesQueries(variables), {
		formatDataKey: "stream",
		avoidUpdateWithQuery: true,
	});

	useEffect(() => {
		setPage(1);
		setChangeInPage(true);
	}, [timestamp]);

	useEffect(() => {
		if (changeInPage) {
			getNewCommunitiesData();
		}
	}, [page, changeInPage]);

	useEffect(() => {
		const items: any[] = [];
		data?.forEach((element: any) => {
			items.push({
				...element,
				creat_ts: element?.creat_ts
					? moment(new Date(element.creat_ts)).format(DATE_FORMAT) : strings("CommunitySearchPage.not_available"),
				modif_ts: element?.modif_ts
					? moment(new Date(element.modif_ts)).format(DATE_FORMAT) : strings("CommunitySearchPage.not_available"),
				reported_ts: element?.reported_ts
					? moment(new Date(element.reported_ts)).format(DATE_FORMAT) : strings("CommunitySearchPage.not_available"),
			});
		});
		setCommunitiesData(items);
		setChangeInPage(false);
	}, [data]);

	const applyFilter = (items: TableHeader[]) => {
		setHeaders(items);
	};

	const goToCommunityDetailScreen = (item: any) => {
		navigate({
			pathname: AUTH_URL + COMMUNITIES_DETAILS_PAGE,
			search: `?${createSearchParams({ communityId: item?.streamId })}`,
		});
	};

	const showConfirmationModal = (item: any) => {
		setSelectedCommunity(item);
		setIsVisible(true);
	};

	const closeModal = () => {
		setIsVisible(false);
		setSelectedCommunity(null);
	};

	const onCancelButton = () => {
		setIsVisible(false);
	};

	const [deleteCommunityQuery, { loading: isCommunityDeleteLoading }] = useQuery<Array<Object>>("", {
		formatDataKey: "stream",
		isMutation: true,
	});

	const deleteCommunity = () => {
		deleteCommunityQuery({
			mutation: deleteStreamQuery({ fields: StreamTableHeaders, streamId: selectedCommunity?.streamId }),
		});
		onCancelButton();
		getNewCommunitiesData();
	};

	const goToCommunityDetailScreenWithReportedCount = (item: any) => {
		navigate({
			pathname: AUTH_URL + COMMUNITIES_DETAILS_PAGE,
			search: `?${createSearchParams({ communityId: item?.streamId, reportedCount: item.nb_rep })}`,
		});
	};

	const renderColumn = (props: { item: any; header: any; }) => {
		const { item, header } = props;
		if (!header || !item) {
			return null;
		}
		return (
			<button
				type="button"
				className="w-full truncate underline text-blue-600 hover:text-blue-800 "
				onClick={() => goToCommunityDetailScreenWithReportedCount(item)}
			>
				{item[header.value] || ""}
			</button>
		);
	};

	return (
		<div ref={wrapperRef}>
			<Hero title={strings("NewCommunityPage.hero_title")} pageHasTable />
			<div data-testid="search-card" className="bg-white p-5 rounded-lg -mt-24 mx-5 flex flex-col mb-5">
				<Accordion
					header={(
						<h5 className="mb-1">
							{strings("UserSearchPage.filter")}
						</h5>
					)}
					content={(
						<div
							data-testid="community-filter"
							className="grid grid-cols-1 xs:grid-cols-2-max-20 sm:grid-cols-3-max-20 lg:grid-cols-4-max-20 gap-2 lg:gap-4"
						>
							<Filter
								id="column-filters"
								data={StreamTableHeaders}
								filterName={strings("CommunitySearchPage.table_title")}
								applyFilter={applyFilter}
								selectedData={headers}
								showTitle={false}
								local_storage_key="community-headers"
							/>
							<Filter
								id="timestamp-filter"
								data={TimestampsOptions}
								filterName={strings("NewCommunityPage.timestamp")}
								applyFilter={applyTimestampFilter}
								selectedData={timestamp}
								showTitle={false}
								multiSelect={false}
							/>
						</div>
					)}
					defaultOpen
				/>
			</div>
			<div className="bg-white p-5 rounded-lg my-5 mx-5">
				<div data-testid="new-community-data-table" className="my-0">
					<Table
						header={headers}
						data={communitiesData}
						showHeader
						isLoading={loading}
						onItemClick={goToCommunityDetailScreen}
						onClickDelete={showConfirmationModal}
						showActionButtons
						pagination={{
							onPageChange: (num) => {
								setChangeInPage(true);
								setPage(num);
							},
							hidePageNumber: true,
							scrollOnPageChange: true,
							page,
						}}
						CustomColumnComponent={renderColumn}
					/>
				</div>
				<div data-testid="delete-modal">
					<ConfirmationModal
						id="community-delete"
						isVisible={isVisible}
						title={strings("CommunitySearchPage.delete_community")}
						message={`${strings("CommunitySearchPage.delete_community_message")}\`${selectedCommunity?.name}\`?`}
						ok_button_title={strings("CommunitySearchPage.yes")}
						ok_button_function={deleteCommunity}
						cancel_button_title={strings("CommunitySearchPage.no")}
						cancel_button_function={onCancelButton}
						onClose={closeModal}
						isOkLoading={isCommunityDeleteLoading}
					/>
				</div>
			</div>
		</div>
	);
}
