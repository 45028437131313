import React, { SVGAttributes } from "react";

const CopyComment = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--gray-dark)",
	} = props;
	return (
		<svg
			width="19px"
			height="19px"
			viewBox="0 0 19 19"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<g id="Home-/-Feed---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Home---Export" transform="translate(-86.000000, -539.000000)" stroke={stroke}>
					<g id="MMcopy" transform="translate(86.000000, 539.000000)">
						<path d="M12.9907,18.5 L1.5087,18.5 C0.9517,18.5 0.4997,18.048 0.4997,17.491 L0.4997,6.009 C0.4997,5.452 0.9517,5 1.5087,5 L7.5827,5 C7.8507,5 8.1077,5.107 8.2967,5.296 L13.7047,10.703 C13.8937,10.893 13.9997,11.149 13.9997,11.417 L13.9997,17.491 C13.9997,18.048 13.5477,18.5 12.9907,18.5 Z" id="Stroke-1" />
						<path d="M7.9998,5.0002 L7.9998,9.6192 C7.9998,10.3822 8.6178,11.0002 9.3808,11.0002 L13.9998,11.0002" id="Stroke-3" />
						<path d="M5.0002,5.0002 L5.0002,1.5092 C5.0002,0.9522 5.4522,0.5002 6.0092,0.5002 L12.0832,0.5002 C12.3512,0.5002 12.6072,0.6062 12.7972,0.7952 L18.2042,6.2032 C18.3932,6.3922 18.5002,6.6492 18.5002,6.9172 L18.5002,12.9912 C18.5002,13.5482 18.0482,14.0002 17.4912,14.0002 L14.0002,14.0002" id="Stroke-5" />
						<path d="M12.5,0.5 L12.5,5.119 C12.5,5.882 13.118,6.5 13.881,6.5 L18.5,6.5" id="Stroke-7" />
					</g>
				</g>
			</g>
		</svg>
	);
};

export default CopyComment;
