import React, { useRef } from "react";
import { useMeasureOnResize } from "@credo/utilities";
import { ContentSensitiveIcon } from "../assets/icons";
import { classNames } from "../common";

export interface RestrictedContentOverlayProps {
	isContentRestricted: boolean;
	title: string;
	buttonTitle: string;
	containerStyle?: any;
	onShowSensitiveContent: () => void;
}

const RestrictedContentOverlay: React.FC<RestrictedContentOverlayProps> = (props) => {
	const {
		containerStyle, title, buttonTitle, isContentRestricted, onShowSensitiveContent,
	} = props;

	const divRef = useRef(null);
	const dimensions = useMeasureOnResize(divRef);

	if (isContentRestricted) {
		return (
			<div
				className={classNames(
					"flex absolute flex-1 w-full h-full items-center justify-center rounded-lg z-10",
					containerStyle,
				)}
				ref={divRef}
			>
				<div
					className="flex flex-col items-center"
					onClick={() => onShowSensitiveContent && onShowSensitiveContent()}
					aria-hidden
				>
					<ContentSensitiveIcon xlinkTitle={title} />
					<span
						className={classNames(
							"text-gray-dark font-regular",
							dimensions && dimensions?.width < 125 ? "mt-0 text-4xs" : "mt-1 text-xs ",
						)}
					>
						{title}
					</span>
					<span
						className={classNames(
							"text-primary font-regular",
							dimensions && dimensions?.width < 125 ? "mt-0 text-4xs" : "mt-1 text-xs ",
						)}
					>
						{buttonTitle}
					</span>
				</div>
			</div>
		);
	}
	return null;
};

RestrictedContentOverlay.defaultProps = {
	containerStyle: "",
};

export default RestrictedContentOverlay;
