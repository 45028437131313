/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGAttributes } from "react";

const Post = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--primary)",
	} = props;
	return (
		<svg
			width="15px"
			height="18px"
			viewBox="0 0 15 18"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<g id="Streams---CT---190723" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-icons" transform="translate(-377.000000, -815.000000)" stroke={stroke}>
					<g id="stream_post" transform="translate(378.000000, 816.000000)">
						<g
							id="Group-6"
							transform="translate(6.964286, 5.368421) rotate(30.000000) translate(-6.964286, -5.368421) translate(4.642857, 0.894737)"
						>
							<g id="Group-7" transform="translate(0.464286, 0.000000)">
								<ellipse id="Oval" cx="1.85714286" cy="1.78947368" rx="1.85714286" ry="1.78947368" />
								<path
									d="M0.601229637,2.98245614 L1.75974489,7.38431561 C1.77380166,7.43772527 1.828494,7.46962709 1.88190366,7.45557032 C1.91688211,7.44636443 1.94416317,7.41898785 1.95324675,7.38397744 L3.0952381,2.98245614 L3.0952381,2.98245614"
									id="Path-2"
								/>
							</g>
						</g>
						<path
							d="M6.49228459,3.57894737 L1,3.57894737 C0.44771525,3.57894737 -6.76353751e-17,4.02666262 0,4.57894737 L0,15.1052632 C6.76353751e-17,15.6575479 0.44771525,16.1052632 1,16.1052632 L12,16.1052632 C12.5522847,16.1052632 13,15.6575479 13,15.1052632 L13,4.57894737 C13,4.02666262 12.5522847,3.57894737 12,3.57894737 L10.3295989,3.57894737 L10.3295989,3.57894737"
							id="Path-6"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default Post;
