import React, { SVGAttributes } from "react";

const Dev = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { stroke = "var(--gray-dark)", fill} = props;
	return (
		<svg width="23px" height="20px" viewBox="0 0 23 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
			<g id="Settings---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
				<g id="Settings---Export" transform="translate(-100.000000, -443.000000)" stroke={fill || stroke}>
					<g id="dev_menu" transform="translate(101.000000, 443.000000)">
						<g id="Group-2-Copy">
							<path d="M4.21884749e-15,1.5625 C4.15543933e-15,1.04473305 0.419733047,0.625 0.9375,0.625 L2.75,0.625 C3.30228475,0.625 3.75,1.07271525 3.75,1.625 L3.75,3.375 C3.75,3.92728475 3.30228475,4.375 2.75,4.375 L0.9375,4.375 C0.419733047,4.375 4.28225566e-15,3.95526695 4.21884749e-15,3.4375 L4.21884749e-15,3.4375 L4.21884749e-15,3.4375 L4.21884749e-15,1.5625 Z" id="Rectangle" transform="translate(1.875000, 2.500000) rotate(90.000000) translate(-1.875000, -2.500000) "/>
						</g>
						<g id="Group-2-Copy-3" transform="translate(0.000000, 7.500000)">
							<path d="M4.21884749e-15,1.5625 C4.15543933e-15,1.04473305 0.419733047,0.625 0.9375,0.625 L2.75,0.625 C3.30228475,0.625 3.75,1.07271525 3.75,1.625 L3.75,3.375 C3.75,3.92728475 3.30228475,4.375 2.75,4.375 L0.9375,4.375 C0.419733047,4.375 4.28225566e-15,3.95526695 4.21884749e-15,3.4375 L4.21884749e-15,3.4375 L4.21884749e-15,3.4375 L4.21884749e-15,1.5625 Z" id="Rectangle" transform="translate(1.875000, 2.500000) rotate(90.000000) translate(-1.875000, -2.500000) "/>
						</g>
						<g id="Group-2-Copy-4" transform="translate(0.000000, 15.000000)">
							<path d="M4.21884749e-15,1.5625 C4.15543933e-15,1.04473305 0.419733047,0.625 0.9375,0.625 L2.75,0.625 C3.30228475,0.625 3.75,1.07271525 3.75,1.625 L3.75,3.375 C3.75,3.92728475 3.30228475,4.375 2.75,4.375 L0.9375,4.375 C0.419733047,4.375 4.28225566e-15,3.95526695 4.21884749e-15,3.4375 L4.21884749e-15,3.4375 L4.21884749e-15,3.4375 L4.21884749e-15,1.5625 Z" id="Rectangle" transform="translate(1.875000, 2.500000) rotate(90.000000) translate(-1.875000, -2.500000) "/>
						</g>
						<line x1="7.5" y1="2.5" x2="19.8024571" y2="2.5" id="Line"/>
						<line x1="7.5" y1="10" x2="19.8024571" y2="10" id="Line-Copy"/>
						<line x1="7.5" y1="17.5" x2="19.8024571" y2="17.5" id="Line-Copy-2"/>
					</g>
				</g>
			</g>
		</svg>
	);
}

export default Dev;
