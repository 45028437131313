import * as React from "react"
import { SVGAttributes } from "react";

const SvgComponent = (props: SVGAttributes<HTMLOrSVGElement>) => (
	<svg width={40} height={40} viewBox="8 9 23 23" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g fill="none" fillRule="evenodd">
			<path
				d="M13.39 20.723c0-.68.114-1.334.316-1.946l-3.534-2.698a10.48 10.48 0 0 0-1.077 4.644c0 1.67.388 3.245 1.076 4.642l3.532-2.704c-.2-.61-.312-1.26-.312-1.938"
				fill="#FBBC05"
			/>
			<path
				d="M19.595 14.528c1.48 0 2.816.524 3.866 1.382l3.055-3.05c-1.861-1.62-4.248-2.622-6.92-2.622-4.15 0-7.716 2.373-9.424 5.84l3.534 2.699a6.188 6.188 0 0 1 5.89-4.25"
				fill="#EA4335"
			/>
			<path
				d="M19.595 26.92a6.188 6.188 0 0 1-5.89-4.25l-3.533 2.698a10.468 10.468 0 0 0 9.423 5.84c2.562 0 5.007-.909 6.842-2.613l-3.355-2.593c-.946.596-2.138.917-3.487.917"
				fill="#34A853"
			/>
			<path
				d="M29.618 20.723c0-.62-.095-1.286-.238-1.906h-9.785v4.051h5.632c-.281 1.381-1.048 2.443-2.145 3.134l3.355 2.593c1.927-1.789 3.181-4.454 3.181-7.872"
				fill="#4285F4"
			/>
		</g>
	</svg>
)

export default SvgComponent
