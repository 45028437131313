import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { classNames } from "../common";

interface BoardInfoUpdateSkeletonProps {
	wrapperClasses?: string;
}

// eslint-disable-next-line import/prefer-default-export
const BoardInfoUpdateSkeleton: React.FC<BoardInfoUpdateSkeletonProps> = (props: BoardInfoUpdateSkeletonProps) => {
	const {
		wrapperClasses,
	} = props;

	return (
		<div
			className={classNames(
				"w-full z-0",
				wrapperClasses,
			)}
		>
			<SkeletonTheme
				baseColor="var(--background-skeleton)"
				highlightColor="var(--hightlight-skeleton)"
			>
				<div className="flex flex-row justify-between w-full">
					<div className="flex flex-col w-full">
						<Skeleton containerClassName="w-1/6 h-2.5 mb-2" height={10} />
						<Skeleton containerClassName="w-full h-2.5" height={20} />
					</div>
					<div className="flex flex-col w-full ml-4">
						<Skeleton containerClassName="w-1/6 h-2.5 mb-2" height={10} />
						<Skeleton containerClassName="w-full h-2.5" height={20} />
					</div>
				</div>
			</SkeletonTheme>
		</div>
	);
};

BoardInfoUpdateSkeleton.defaultProps = {
	wrapperClasses: "",
};

export default BoardInfoUpdateSkeleton;
