/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGAttributes } from "react";

const StreamCredoOnlyLarge = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--primary)",
	} = props;
	return (
		<svg
			width="67px"
			height="60px"
			viewBox="0 0 67 60"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<g id="Streams---CT---190723" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="All-icons" transform="translate(-690.000000, -367.000000)" stroke={stroke}>
					<g id="stream_credo_only_large" transform="translate(690.000000, 367.000000)">
						<path
							d="M32.4231,22.5469 L12.4191,22.5469 C11.1431,22.5469 10.1091,23.5809 10.1091,24.8569 L10.1091,35.6789 L10.0491,35.7119 L0.9891,41.0239 L10.1091,46.3629 L10.1091,57.1909 C10.1091,58.4659 11.1431,59.4999 12.4191,59.4999 L49.4901,59.4999 C50.7661,59.4999 51.8001,58.4659 51.8001,57.1909 L51.8001,27.7089"
							id="Stroke-1"
						/>
						<path
							d="M53.5517,21.8818 C53.5517,27.7238 48.8157,32.4598 42.9737,32.4598 C37.1317,32.4598 32.3957,27.7238 32.3957,21.8818 C32.3957,16.0398 37.1317,11.3038 42.9737,11.3038 C48.8157,11.3038 53.5517,16.0398 53.5517,21.8818 Z"
							id="Stroke-3"
						/>
						<path
							d="M42.9776,20.8917 C44.8386,20.8917 46.3526,18.9097 46.3526,17.0477 C46.3526,15.1867 44.8386,13.6727 42.9776,13.6727 C41.1176,13.6727 39.6036,15.1867 39.6036,17.0477 C39.6036,18.9097 41.1176,20.8917 42.9776,20.8917 Z"
							id="Stroke-5"
						/>
						<path
							d="M37.0764,27.4022 C37.3504,23.9422 38.8854,22.5422 39.7754,22.0112 L39.9934,21.8822 L40.2134,22.0262 L42.9774,24.1002 L45.7424,22.0262 L45.9624,21.8822 L46.1794,22.0112 C47.0704,22.5422 48.6044,23.9422 48.8784,27.4022 L48.8804,27.6922 C47.3634,29.1532 45.2794,30.0562 42.9784,30.0562 C40.6764,30.0562 38.5924,29.1522 37.0744,27.6912 L37.0764,27.4022 Z"
							id="Stroke-7"
						/>
						<path
							d="M33.3219,41.7119 C33.3219,43.3489 31.9949,44.6759 30.3579,44.6759 C28.7199,44.6759 27.3929,43.3489 27.3929,41.7119 C27.3929,40.0749 28.7199,38.7469 30.3579,38.7469 C31.9949,38.7469 33.3219,40.0749 33.3219,41.7119 Z"
							id="Stroke-9"
						/>
						<path
							d="M23.4397,41.7119 C23.4397,43.3489 22.1127,44.6759 20.4757,44.6759 C18.8377,44.6759 17.5107,43.3489 17.5107,41.7119 C17.5107,40.0749 18.8377,38.7469 20.4757,38.7469 C22.1127,38.7469 23.4397,40.0749 23.4397,41.7119 Z"
							id="Stroke-11"
						/>
						<path
							d="M43.2042,41.7119 C43.2042,43.3489 41.8772,44.6759 40.2402,44.6759 C38.6022,44.6759 37.2752,43.3489 37.2752,41.7119 C37.2752,40.0749 38.6022,38.7469 40.2402,38.7469 C41.8772,38.7469 43.2042,40.0749 43.2042,41.7119 Z"
							id="Stroke-13"
						/>
						<path
							d="M53.4381,23.4141 C53.7011,23.4321 53.9661,23.4411 54.2331,23.4411 C60.7441,23.4411 65.9881,18.0161 65.6921,11.4401 C65.4271,5.5701 60.6741,0.8011 54.8051,0.5141 C52.1491,0.3841 49.6871,1.1651 47.6861,2.5641 C47.3681,2.7861 47.5581,3.2841 47.9421,3.2301 C48.2831,3.1811 48.6301,3.1541 48.9851,3.1541 C53.8541,3.1541 57.8011,7.1011 57.8011,11.9711 C57.8011,15.3371 55.9151,18.2621 53.1421,19.7471"
							id="Stroke-15"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default StreamCredoOnlyLarge;
