import { DropdownItem, SearchBarElementInterface, SearchStatement } from "./types";

export interface GetWhereClauseVariables {
	searchData: SearchBarElementInterface[];
	otherFilterQuery: {
		[key: string]: DropdownItem[];
	}
}

const getWhereClause = (variables: GetWhereClauseVariables) => {
	const { searchData, otherFilterQuery } = variables;

	const singleAndClauseForSearchData = searchData
		.map((singleSearchBarElement) => singleSearchBarElement.selectedValueData.map((field: DropdownItem, fieldIndex) => {
			let statement: SearchStatement = {
				operator: field.searchQueries ? field.searchQueries[0].operator : "OR",
				propName: field.searchQueries ? field.searchQueries[0].propName : field.value,
				// eslint-disable-next-line no-nested-ternary
				propVal: field.searchQueries ? field.searchQueries[0].propVal
					? field.searchQueries[0].propVal
					: `(?i).*${singleSearchBarElement.selectedSearchValue}.*`
					: singleSearchBarElement.selectedSearchValue,
				condition: field.searchQueries ? field.searchQueries[0].condition : " CONTAINS ",
			};

			if (fieldIndex === 0) {
				statement = {
					...statement,
					operator: "AND",
					FilterGroupStart: "Y",
				};
			}
			if (fieldIndex === (singleSearchBarElement.selectedValueData.length - 1)) {
				statement = {
					...statement,
					FilterGroupEnd: "Y",
				};
			}

			if (singleSearchBarElement.selectedValueData.length === 1) {
				delete statement.FilterGroupStart;
				delete statement.FilterGroupEnd;
			}

			return statement;
		}));

	const otherFiltersClause = Object
		.keys(otherFilterQuery)
		.map((otherFilterKey) => otherFilterQuery[otherFilterKey]
			.map((otherFilterValue, filterValueIndex) => {
				const searchQueries = otherFilterValue.searchQueries && otherFilterValue.searchQueries?.length > 0
					? otherFilterValue.searchQueries : [];

				if (searchQueries.length > 0) {
					return searchQueries.map((searchQuery, searchQueryIndex) => {
						let searchQueryStatement: SearchStatement = searchQuery;
						if (filterValueIndex === 0 && searchQueryIndex === 0) {
							searchQueryStatement = {
								...searchQueryStatement,
								operator: "AND",
								FilterGroupStart: "Y",
							};
						}

						if (
							filterValueIndex === (otherFilterQuery[otherFilterKey].length - 1)
							&& searchQueryIndex === (searchQueries.length - 1)
						) {
							searchQueryStatement = {
								...searchQueryStatement,
								FilterGroupEnd: "Y",
							};
						}

						if (searchQueries.length === 1 && otherFilterQuery[otherFilterKey].length === 1) {
							delete searchQueryStatement.FilterGroupStart;
							delete searchQueryStatement.FilterGroupEnd;
						}

						return searchQueryStatement;
					});
				}

				let statement: SearchStatement = {
					operator: "OR",
					propName: otherFilterKey,
					propVal: otherFilterValue.value,
					condition: "=",
				};

				if (filterValueIndex === 0) {
					statement = {
						...statement,
						operator: "AND",
						FilterGroupStart: "Y",
					};
				}

				if (filterValueIndex === (otherFilterQuery[otherFilterKey].length - 1)) {
					statement = {
						...statement,
						FilterGroupEnd: "Y",
					};
				}

				if (otherFilterQuery[otherFilterKey].length === 1) {
					delete statement.FilterGroupStart;
					delete statement.FilterGroupEnd;
				}

				return statement;
			}));

	return [...singleAndClauseForSearchData, ...otherFiltersClause]
		.flat()
		.flat()
		.map((statement, statementIndex) => {
			const updatedStatement = statement;
			if (statementIndex === 0) {
				delete updatedStatement.operator;
			}
			return updatedStatement;
		});
};

export default getWhereClause;
