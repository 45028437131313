import { AxiosRequestHeaders, AxiosResponse } from "axios";
import { HTTPMethod, JsonBody, makeRequest } from "./requestBuilder";

export * from "./types";
export * from "./config";

export interface RequestOptions {
	apiVersion?: "applogic" | "auth";
	withHeaders?: boolean;
	headers?: AxiosRequestHeaders;
}
export interface RequestConfig {
	url: string;
	body?: RequestBody;
	method: HTTPMethod | "get";
}

export type RequestBody = JsonBody | FormData;

export type RequestMethod = (request: RequestConfig, config?: RequestOptions) => Promise<AxiosResponse["data"]>;

export const makeApiRequest: RequestMethod = async (request: RequestConfig, config?: RequestOptions) => makeRequest(request, config);
