import React, { lazy, Suspense } from "react";

interface Opts {
	fallback: React.ReactNode;
}
type Unpromisify<T> = T extends Promise<infer P> ? P : never;

// eslint-disable-next-line import/prefer-default-export
export const LazyLoad = <
	T extends Promise<any>,
	U extends React.ComponentType<any>,
	>(
		importFunc: () => T,
		selectorFunc?: (s: Unpromisify<T>) => U,
		opts: Opts = { fallback: null },
	) => {
	let lazyFactory: () => Promise<{ default: U }> = importFunc;

	if (selectorFunc) {
		// a function to retry loading a chunk to avoid chunk load error for out of date code
		lazyFactory = () => new Promise<{ default: U }>((resolve, reject) => {
			// check if the window has already been refreshed
			const hasRefreshed = JSON.parse(
				window.sessionStorage.getItem("retry-lazy-refreshed") || "false",
			);
				// try to import the component
			importFunc().then((component) => {
				window.sessionStorage.setItem("retry-lazy-refreshed", "false"); // success so reset the refresh
				resolve({ default: selectorFunc(component) });
				// eslint-disable-next-line consistent-return
			}).catch((error) => {
				if (!hasRefreshed) { // not been refreshed yet
					window.sessionStorage.setItem("retry-lazy-refreshed", "true"); // we are now going to refresh
					return window.location.reload(); // refresh the page
				}
				reject(error); // Default error behaviour as already tried refresh
			});
		});
	}

	const LazyComponent = lazy(lazyFactory);

	// eslint-disable-next-line no-undef
	return (props: React.ComponentProps<U>): JSX.Element => (
		<Suspense fallback={opts.fallback!}>
			<LazyComponent {...props} />
		</Suspense>
	);
};
