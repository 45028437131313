import React, { SVGAttributes } from "react";

// eslint-disable-next-line import/prefer-default-export
const RequestAccept = (props: SVGAttributes<SVGElement>) => {
	const { stroke = "var(--primary)" } = props;
	return (
		<svg
			width="28px"
			height="28px"
			viewBox="0 0 28 28"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<g id="Web-Version" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Web-Version---Settings-Ego" transform="translate(-1143.000000, -233.000000)" stroke={stroke}>
					<g id="Group" transform="translate(830.000000, 227.000000)">
						<g id="request_accept" transform="translate(314.000000, 7.000000)">
							<circle id="Oval" cx="13" cy="13" r="13" />
							<polyline id="Path-2" strokeLinecap="round" points="5.77777778 13 10.8427415 18.7777778 20.2222222 7.22222222" />
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default RequestAccept;
