import React, { useEffect, useRef, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { toast } from "react-toastify";
import { cu } from "@credo/utilities";
import Accordion from "../../gen-comp/Accordion";
import CoverPicture from "../../gen-comp/CoverPicture";
import ProfilePicture from "../../gen-comp/ProfilePicture";
import Table from "../../gen-comp/table";
import { useQuery } from "../../hooks/graphql/useQuery";
import { strings } from "../../i18n/config";
import classNames from "../../utils/classNames";
import { buildSourceUrlImage, isYes } from "../../utils/utils";
import { MemberTableColumns } from "./constants";
import "react-loading-skeleton/dist/skeleton.css";
import Assets from "../../assets/images/Assets";
import { AUTH_URL, USER_DETAILS_PAGE } from "../../routes/constants";
import ConfirmationModal from "../../gen-comp/ConfirmationModal";
import { successRETCD } from "../../api";
import ToastMsg from "../../gen-comp/ToastMsg";
import { getCommunityDetails, getReportedCommunityData, GetReportedStreamVariables } from "./queries";
import { ReportingUserDetails, SnackBarTypeOptions } from "../../utils/types";
import StreamReportedList from "./StreamReportedList";

export default function CommunityDetailsScreen() {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const communityId = searchParams.get("communityId");
	const reportedCount = parseInt(searchParams.get("reportedCount") || "0", 10);
	const [communityDetails, setCommunityDetails] = useState<any>(null);
	const [adminData, setAdminData] = useState<any>([]);
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const isNewTab = searchParams.get("isNewTab") ?? "";
	const [streamReportedData, setStreamReportedData] = useState<[ReportingUserDetails] | []>([]);
	const bottomRef = useRef<any>(null);
	const [height, setHeight] = useState(0);
	const ref = useRef<any>(null);

	const [getCommunitiesUsingQuery, { data, loading }] = useQuery<Array<Object>>(getCommunityDetails({ communityId: communityId || "" }), {
		formatDataKey: "stream",
	});

	const getCommunityDetailsById = () => {
		getCommunitiesUsingQuery();
	};

	const scrollToBottom = () => {
		if (bottomRef) {
			bottomRef?.current?.scrollIntoView({
				behavior: "smooth", block: "end", inline: "nearest",
			});
		}
	};

	useEffect(() => {
		if (ref) {
			setHeight(ref?.current?.clientHeight || 0);
		}
	});

	const navigateToUserDetails = (item: any) => {
		window.open(`${AUTH_URL}${USER_DETAILS_PAGE}?${createSearchParams({
			userId: item?.user_id,
			isNewTab: "y",
		})}`, "_blank");
	};

	useEffect(() => {
		const commData: any[] | undefined = data;
		if (commData && commData.length > 0) {
			setCommunityDetails(commData[0]);
			const adminData: any[] = [];
			const superAdminData = { ...commData[0]?.suAdmin[0], role: strings("Roles.super_admin") };
			commData[0]?.adminUsers?.forEach((e: any) => {
				const idx = adminData.findIndex((ele) => ele.user_id === e.user_id);
				adminData.push({
					...e,
					role: superAdminData.user_id === e.user_id && idx === -1 ? strings("Roles.super_admin") : strings("Roles.admin"),
					// eslint-disable-next-line no-nested-ternary
					mode: superAdminData.user_id === e.user_id && idx === -1
						? commData[0].suAdminMode
						// eslint-disable-next-line no-nested-ternary
						: superAdminData.user_id === e.user_id && idx !== -1
							? commData[0].suAdminMode === "e" ? "c" : "e"
							: idx === -1 ? "e" : "c",
				});
			});
			setAdminData(adminData);
		}
	}, [data]);

	const showConfirmationModal = () => {
		setShowDeleteModal(true);
	};

	const closeModal = () => {
		setShowDeleteModal(false);
	};

	const onCancelButton = () => {
		setShowDeleteModal(false);
	};

	const deleteQueryString = `mutation { deleteStream( streamId: "${communityDetails?.streamId}" ){name,struname}\n}`;

	const [deleteCommunityQuery, { loading: communityDeleteLoading }] = useQuery<Array<Object>>("", {
		formatDataKey: "stream",
		isMutation: true,
	});

	const deleteCommunity = async () => {
		await deleteCommunityQuery({
			mutation: deleteQueryString,
		}).then((response) => {
			if (response.retcd === successRETCD) {
				toast(
					<ToastMsg
						message={strings("CommunityDetailsScreen.community_deleted")}
						showButton
						type={SnackBarTypeOptions.SUCCESS}
					/>,
				);
			} else {
				toast(
					<ToastMsg
						message={strings("CommunityDetailsScreen.something_went_wrong")}
						showButton
						type={SnackBarTypeOptions.ERROR}
					/>,
				);
			}
		}).catch((error) => {
			toast(
				<ToastMsg
					message={strings("CommunityDetailsScreen.something_went_wrong")}
					showButton
					type={SnackBarTypeOptions.ERROR}
				/>,
				error,
			);
		});
		onCancelButton();
		navigate(-1);
	};

	const reported_variables: GetReportedStreamVariables = {
		community_id: communityId || "",
	};

	const [getReportedList, { data: reportedData, loading: isReportedLoading }] = useQuery<[{ posts: [ReportingUserDetails] }]>(
		getReportedCommunityData(reported_variables),
		{
			formatDataKey: "stream",
		},
	);

	useEffect(() => {
		if (reportedData && reportedData?.length > 0 && reportedData[0] && reportedData[0]?.posts) {
			setStreamReportedData(reportedData[0]?.posts);
		}
	}, [reportedData]);

	useEffect(() => {
		if (reportedCount > 0) {
			setTimeout(() => { scrollToBottom(); }, 500);
			// waiting for accordion transition
		}
	}, [height]);

	useEffect(() => {
		getCommunityDetailsById();
		getReportedList();
	}, []);

	if (loading || (isReportedLoading && reportedCount > 0)) {
		return (
			<div className="w-auto h-full" data-testid="community-details-loading-wrapper">
				<SkeletonTheme>
					<div className="px-3">
						<Skeleton className={classNames("metric-card border rounded-lg p-4 h-36")} />
					</div>
					<div className="flex flex-row w-full">
						<Skeleton className={classNames("left-10 -top-10")} borderRadius={10} width={120} height={120} />
						<Skeleton count={3} width={200} height={20} className={classNames("left-12 relative my-1")} />
					</div>
					<div className="px-3">
						<Skeleton className={classNames("metric-card border rounded-lg p-4 h-40")} borderRadius={10} />
					</div>
				</SkeletonTheme>
			</div>
		);
	}

	if (!(data && data?.length > 0) && !loading) {
		return (
			<div className="w-full h-full" data-testid="community-details-not-found-wrapper">
				<CoverPicture coverPicUrl={Assets.StreamDefaultCover} />
				<div className="relative -top-28 left-10 w-fit bg-white rounded-xl opacity-80 cursor-pointer">
					<IoIosArrowBack
						className={classNames("flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 inactive-text-style p-1")}
						onClick={() => navigate(-1)}
						size={25}
					/>
				</div>
				<div className="flex flex-row w-full">
					<ProfilePicture
						profilePicUrl={Assets.StreamDefaultProfile}
						profilePicWrapperStyle="left-10"
						isUser={false}
					/>
					<div className="relative -top-6 left-12 py-5 px-1 w-3/6">
						<h5 className="text-center">
							{strings("CommunityDetailsScreen.community_not_found")}
						</h5>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="w-full h-full" ref={ref} data-testid="community-details">
			<CoverPicture coverPicUrl={buildSourceUrlImage(communityDetails?.coverPicRelUrl) || ""} />
			{!isYes(isNewTab)
				? (
					<div className="relative -top-28 left-10 w-fit bg-white rounded-xl opacity-80 cursor-pointer">
						<IoIosArrowBack
							className={classNames("flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 inactive-text-style p-1")}
							onClick={() => navigate(-1)}
							size={25}
						/>
					</div>
				) : <div className="relative -top-28 left-10 w-fit opacity-80" style={{ height: 24 }} />}
			<div className="flex flex-col xs:flex-row w-full mr-5 justify-between">
				<div className="flex w-full">
					<ProfilePicture
						profilePicUrl={communityDetails?.profilePicRelUrl
							? (cu.buildSourceUrlImage(communityDetails?.profilePicRelUrl) || "") : ""}
						profilePicWrapperStyle="left-10"
						isUser={false}
					/>
					<div className="relative -top-6 left-12 py-1 px-1 w-3/6">
						<p
							className="text-black font-bold text-heading_2 whitespace-pre-wrap break-words"
							data-testid="community-name"
						>
							{communityDetails?.name}
						</p>
						<p
							className="text-sm whitespace-pre-wrap break-words"
							data-testid="community-username"
						>
							{communityDetails?.struname
								? `@${communityDetails?.struname}`
								: strings("CommunityDetailsScreen.not_available")}
						</p>
						<p
							className="text-gray-dark text-sm whitespace-pre-wrap break-words"
							data-testid="community-description"
						>
							{communityDetails?.descr ? communityDetails?.descr : strings("CommunityDetailsScreen.not_available")}
						</p>
					</div>
				</div>
				<div className="mr-0 mb-4 px-8 xs:mr-10 xs:p-0 xs:mb-0">
					<button
						type="button"
						className={classNames("w-full inline-flex justify-center rounded-md border",
							"shadow-sm px-4 py-2 bg-red-600 text-base hover:bg-red-700 ",
							"focus:outline-none border-transparent font-medium text-white ",
							"focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm")}
						onClick={showConfirmationModal}
					>
						Delete
					</button>
				</div>
			</div>
			<div
				className="bg-white p-3 rounded-lg mx-5 min-h-fit max-h-full"
				data-testid="admin-section"
			>
				<Accordion
					header={(
						<h5 className="mb-0">
							{strings("CommunityDetailsScreen.member_list")}
						</h5>
					)}
					content={(
						<div>
							<Table
								header={MemberTableColumns}
								data={adminData}
								showHeader
								isLoading={loading}
								onItemClick={navigateToUserDetails}
							/>
						</div>
					)}
					defaultOpen={reportedCount === 0}
				/>
			</div>
			<div className="pb-2">
				<StreamReportedList
					data={streamReportedData}
					reportedCount={reportedCount}
				/>
			</div>
			<div ref={bottomRef} />
			<ConfirmationModal
				isVisible={showDeleteModal}
				title={strings("CommunityDetailsScreen.delete_community")}
				message={strings("CommunityDetailsScreen.delete_community_message", { name: communityDetails?.name })}
				ok_button_title={strings("CommunityDetailsScreen.yes")}
				ok_button_function={deleteCommunity}
				cancel_button_title={strings("CommunityDetailsScreen.no")}
				cancel_button_function={onCancelButton}
				onClose={closeModal}
				isOkLoading={communityDeleteLoading}
				okLoadingText={strings("CommunityDetailsScreen.deleting_community")}
			/>
		</div>
	);
}
