import { makeApiRequest } from "../../api";
import { DASHBOARD_REALTIME_DATA_URL, DASHBOARD_STATS_URL } from "../../api/requestsUrl";

export const currentDate = new Date();

export async function getDashboardStatsData(timestamp?: number) {
	try {
		const res = await makeApiRequest({
			method: "get",
			url: `${DASHBOARD_STATS_URL}/${timestamp || new Date().getTime()}`,
		});
		return {
			code: 200,
			response: res,
		};
	} catch (err) {
		return {
			code: 500,
			error: err,
		};
	}
}

export async function getDashboardRealTimeData() {
	try {
		const res = await makeApiRequest({
			method: "get",
			url: DASHBOARD_REALTIME_DATA_URL,
		});
		return {
			code: 200,
			response: res,
		};
	} catch (err) {
		return {
			code: 500,
			error: err,
		};
	}
}

export const getEmotionReactionData = () => `query {
	emo {
		code,
		value,
		emoji,
	}
}`
	.replaceAll("\t", "")
	.replaceAll("\n", "");
