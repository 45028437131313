import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translation from "./en.json";

export const resources = {
	en: {
		translation,
	},
} as const;

i18n.use(initReactI18next).init({
	lng: "en",
	interpolation: {
		escapeValue: false, // not needed for react as it escapes by default
	},
	resources,
	fallbackLng: "en",
});

export function strings(name: any, params = {}) {
	return i18n.t(name, params);
}

export default i18n;
