import React, { useCallback, useState } from "react";
import { CredoGraphData } from "./types";
import { Modal } from "../modal";

interface CredoGraphDataProps {
	data: CredoGraphData;
}

// eslint-disable-next-line import/prefer-default-export
export const CredoGraphDataComponent: React.FC<CredoGraphDataProps> = (props: CredoGraphDataProps) => {
	const {
		data,
	} = props;
	const [showData, setShowData] = useState<boolean>(false);

	const handleOnClickShowData = useCallback(() => {
		setShowData((prevState) => !prevState);
	}, []);

	return (
		<div className="absolute bottom-6 left-5">
			<button
				type="button"
				className="outline-none rounded-full bg-orange-500 text-white px-2 text-xs"
				onClick={handleOnClickShowData}
			>
				Show data
			</button>
			{showData && (
				<Modal
					show={showData}
					onClose={handleOnClickShowData}
					dialogClassName="!max-w-full"
				>
					<code>
						{JSON.stringify(data)}
					</code>
				</Modal>
			)}
		</div>
	);
};
