import {cu, dbg} from "./cu";
import { LogMgr } from "./LogMgr";

interface EventObject {
  [key: string]: any
}

/**
 * Event manager to fire and listen to the events which can be fired from the app or server.
 * We can listen to the server and fire the dedicated event at that instance.
 * */
export class EvtMgr {
  evtListenersHm: EventObject = {}
  evtName: string = "";
  static instances: EventObject = {};

  constructor(evtName: string) {
    this.evtName = evtName;
    this.evtListenersHm[evtName] = [];
  }

  /**
   * Get instance of the eventManager for the eventName or creates one
   * if not present.
   *
   * @param {string} evtName - Event Name which needs to be listened.
   * @return Instance of the passed event name.
   * */
  static getInstance(evtName: string){
    if(!cu.isSet(EvtMgr.instances[evtName])){
      EvtMgr.instances[evtName] = new EvtMgr(evtName);
    }
    return EvtMgr.instances[evtName];
  }

  /**
   * Add the Handler for the event instance, so whenever that event
   * is fired this function will be executed. If same handler is already
   * added does nothing with that function, if not the handler will be
   * added in the Array of handlers.
   *
   * @param {Function} listener - Handler which needs to be executed or handled when
   * the mentioned event(this.evtName) is fired(takes place).
   * @see EvtMgr.getInstance
   * */
  addListener(listener: Function) {
    // check if the handler is already added, we should not add same handler twice
    // ( but we can add a diff handler for same event )
    let found = this.evtListenersHm[this.evtName].find((elem: Function) => {
      return elem === listener;
    })

    if(found){
      if (dbg) LogMgr.mydbg(this, `handler already added for evt ${this.evtName}; doing nothing`);
      return;
    }
    this.evtListenersHm[this.evtName].push(listener);

    if (dbg) LogMgr.mydbg(this, `added handler for evt ${this.evtName}`);
  }

  /**
   * Removes the listener/function from the event.
   *
   * @param {Function} listener - The function which needs to be removed.
   * @see EvtMgr.addListener
   * */
  removeListener(listener: Function){
    cu.deleteElemFromArray(listener, this.evtListenersHm[this.evtName]);
    if(this.evtListenersHm[this.evtName].length === 0){
      // also delete entry in hash if no items in the array
      delete this.evtListenersHm[this.evtName];
      delete EvtMgr.instances[this.evtName];
    }
    if (dbg) LogMgr.mydbg(this, `removed handler for evt ${this.evtName}`);
  }

  /**
   * Removes all the listener/function associated to that event.
   *
   * @see EvtMgr.addListener
   * @see EvtMgr.removeListener
   * */
  removeAllListeners(){
    this.evtListenersHm[this.evtName] = [];
    delete this.evtListenersHm[this.evtName];
    delete EvtMgr.instances[this.evtName];
    if (dbg) LogMgr.mydbg(this, `removed all handler for evt ${this.evtName}`);
  }

  /**
   * Takes a parameter which can be passed to the listener and notifies
   * that event has been fired and executes all the listeners attached
   * to that event.
   *
   * @param {any} obj - The data which needs to be passed to the listener when the event is fired.
   * @see EvtMgr.addListener
   * @see EvtMgr.removeListener
   * */
  notifyListeners(obj: any){
    if (dbg) LogMgr.mydbg(this,'in notifyListeners for ', this.evtName, this.evtListenersHm[this.evtName]);
    this.evtListenersHm[this.evtName].forEach((listener: Function) => {
      if (dbg) LogMgr.mydbg(this,'calling listener for ' + this.evtName);
      try{
        listener(obj, this.evtName);
      } catch(err) {
        LogMgr.printException(this,'while notifying listenr', err);
      }
    });
  }

}

