import React, { SVGAttributes } from "react";

const HeaderFilter = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { fill = "#121C26", stroke = "#8CD4FF" } = props;
	return (
		<svg
			width="15px"
			height="15px"
			viewBox="0 0 15 15"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<title>Filter</title>
			<g id="Icon---Switches" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<rect id="Rectangle" fill={fill} x="0" y="0" width="15" height="15" rx="3" />
				<line x1="3" y1="4.5" x2="12" y2="4.5" id="Line" stroke={stroke} strokeLinecap="round" />
				<line x1="3" y1="7.5" x2="12" y2="7.5" id="Line-2" stroke={stroke} strokeLinecap="round" />
				<line x1="3" y1="10.5" x2="12" y2="10.5" id="Line-3" stroke={stroke} strokeLinecap="round" />
				<rect id="Rectangle" stroke="#212636" strokeWidth="0.5" fill="#0BE0D8" x="3.75" y="2.75" width="1.5" height="3.5" rx="0.75" />
				<rect id="Rectangle" stroke="#212636" strokeWidth="0.5" fill="#0BE0D8" x="5.75" y="8.75" width="1.5" height="3.5" rx="0.75" />
				<rect id="Rectangle" stroke="#212636" strokeWidth="0.5" fill="#0BE0D8" x="9.75" y="5.75" width="1.5" height="3.5" rx="0.75" />
			</g>
		</svg>
	);
};

export default HeaderFilter;
