import React, { useEffect } from "react";
import { IoMdAddCircleOutline, IoMdRemoveCircleOutline } from "react-icons/io";
import { v4 } from "uuid";
import SearchBar from "./Searchbar";
import classNames from "../../utils/classNames";
import { DropdownItem, SearchBarElementInterface } from "../../utils/types";

interface SearchBarContainerProps {
	/**
	 * Array of dropdown items which can be selected.
	 * */
	dropdownItems: DropdownItem[];
	/**
	 * Function to change the data of all the search bars and dropdown items.
	 * Accepts array of object of index, searchElementValue and searchElementData
	 * */
	handleData: (elements: SearchBarElementInterface[]) => void;
	/**
	 * Resultant data of array of object of index, searchElementValue and searchElementData.
	 * Sent as empty array initially.
	 * */
	data: SearchBarElementInterface[];
}

const SearchBarContainer: React.FC<SearchBarContainerProps> = (props: SearchBarContainerProps) => {
	const {
		dropdownItems,
		handleData,
		data,
	} = props;

	useEffect(() => {
		handleData([{
			selectedSearchValue: "",
			selectedValueData: dropdownItems,
			index: "0",
		}]);
	}, []);

	const handleAddSearch = () => {
		const elementIndex = v4();
		if (data.length < dropdownItems.length) {
			handleData([...data, {
				selectedSearchValue: "",
				selectedValueData: [],
				index: elementIndex,
			}]);
		}
	};

	const handleRemoveSearch = (index: string) => {
		const updatedElements = data.filter((singleElement: SearchBarElementInterface) => singleElement.index !== index);
		handleData(updatedElements);
	};

	const handleChangeSearchValue = (index: string, value: string) => {
		const updatedElement = data.map((singleElement: SearchBarElementInterface) => {
			if (index === singleElement.index) {
				return {
					...singleElement,
					selectedSearchValue: value,
				};
			}
			return singleElement;
		});
		handleData(updatedElement);
	};

	const handleChangeDropdownItems = (index: string, value: DropdownItem[]) => {
		const updatedDropdownItems = data.map((singleElement: SearchBarElementInterface) => {
			if (index === singleElement.index) {
				return {
					...singleElement,
					selectedValueData: value,
				};
			}
			return singleElement;
		});
		handleData(updatedDropdownItems);
	};

	const renderContainer = (data: SearchBarElementInterface) => {
		const {
			index,
			selectedValueData,
			selectedSearchValue,
		} = data;

		return (
			<div className="flex w-full justify-start mb-4" key={`search-container-${index}`}>
				<SearchBar
					index={index}
					handleChangeSearchValue={handleChangeSearchValue}
					handleChangeDropdownItems={handleChangeDropdownItems}
					data={dropdownItems}
					initialSelectedData={selectedValueData}
					initialSearchData={selectedSearchValue}
				/>
				<div className="pt-1 pl-3">
					<button
						onClick={() => handleRemoveSearch(index)}
						type="button"
						className={classNames(
							"font-bold shadow-sm text-gray-800 flex w-fit p-2",
							"h-8",
							"hover:bg-slate-100",
							"disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-500 disabled:shadow-none",
							"items-center justify-center transition-all duration-300 ease-in-out shadow",
							"active:bg-slate-50",
							"rounded rounded-lg",
						)}
					>
						<IoMdRemoveCircleOutline size={20} />
					</button>
				</div>
			</div>
		);
	};

	return (
		<div className="flex flex-col">
			<div className="flex w-full justify-start mb-4">
				<SearchBar
					index="0"
					handleChangeSearchValue={handleChangeSearchValue}
					handleChangeDropdownItems={handleChangeDropdownItems}
					data={dropdownItems}
					initialSelectedData={dropdownItems}
					initialSearchData={data[0]?.selectedSearchValue}
				/>
				<div className="pt-1 pl-3">
					<button
						onClick={handleAddSearch}
						type="button"
						className={classNames(
							"font-bold shadow-sm text-gray-800 flex w-fit p-2",
							"h-8",
							"hover:bg-slate-100",
							"disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-500 disabled:shadow-none",
							"items-center justify-center transition-all duration-300 ease-in-out shadow",
							"active:bg-slate-50",
							"rounded rounded-lg",
						)}
					>
						<IoMdAddCircleOutline size={20} />
					</button>
				</div>
			</div>
			{data.map((singleElement: SearchBarElementInterface) => singleElement.index !== "0" && renderContainer(singleElement))}
		</div>
	);
};

export default SearchBarContainer;
