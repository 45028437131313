import * as React from "react";
import { SVGAttributes } from "react";

/**
 * Renders the config icon
 *
 * TODO convert to use tailwind theme colors
 * @param props
 * @constructor
 */
const ConfigureIcon = (props: SVGAttributes<SVGElement>) => {
	return (
		<svg {...props}>
			<g>
				<rect fill="#121C26" x="0" y="0" width="15" height="15" rx="3"/>
				<line x1="3" y1="4.5" x2="12" y2="4.5" id="Line" stroke="#8CD4FF" strokeLinecap="round"/>
				<line x1="3" y1="7.5" x2="12" y2="7.5" id="Line-2" stroke="#8CD4FF" strokeLinecap="round"/>
				<line x1="3" y1="10.5" x2="12" y2="10.5" id="Line-3" stroke="#8CD4FF" strokeLinecap="round"/>
				<rect
					id="Rectangle"
					stroke="#212636"
					strokeWidth="0.5"
					fill="#0BE0D8"
					x="3.75"
					y="2.75"
					width="1.5"
					height="3.5"
					rx="0.75"
				/>
				<rect
					id="Rectangle"
					stroke="#212636"
					strokeWidth="0.5"
					fill="#0BE0D8"
					x="5.75"
					y="8.75"
					width="1.5"
					height="3.5"
					rx="0.75"
				/>
				<rect
					id="Rectangle"
					stroke="#212636"
					strokeWidth="0.5"
					fill="#0BE0D8"
					x="9.75"
					y="5.75"
					width="1.5"
					height="3.5"
					rx="0.75"
				/>
			</g>

		</svg>
	);
};
export default ConfigureIcon;
