/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGAttributes } from "react";

const RadioButtonSuccess = (props: SVGAttributes<SVGElement>) => {
	const {
		stroke = "var(--primary)",
	} = props;
	return (
		<svg
			width="102px"
			height="101px"
			viewBox="0 0 102 101"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<g id="Settings---CT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Settings---Report-Post" transform="translate(-138.000000, -125.000000)" stroke={stroke}>
					<g id="AFSreportPost_ego" transform="translate(138.000000, 125.000000)">
						<path d="M73.2349,77.4182 L63.8619,81.6602 L66.3879,71.6872 L92.9019,40.0082 C94.4849,38.1172 97.3009,37.8672 99.1919,39.4502 L99.1919,39.4502 C101.0829,41.0332 101.3319,43.8482 99.7499,45.7392 L73.2349,77.4182 Z" id="Stroke-1" />
						<line x1="66.3874" y1="71.6869" x2="73.2344" y2="77.4179" id="Stroke-3" />
						<line x1="91.1918" y1="42.0517" x2="98.0388" y2="47.7827" id="Stroke-5" />
						<line x1="92.1949" y1="47.8095" x2="71.8519" y2="72.1145" id="Stroke-7" />
						<polyline id="Stroke-9" points="81.0752 54.1386 81.0752 21.5696 45.5002 21.5696" />
						<polyline id="Stroke-11" points="25.8853 59.2921 25.8853 100.5001 81.0753 100.5001 81.0753 68.0511" />
						<polyline id="Stroke-13" points="70.8716 13.6566 91.2786 13.6566 84.4996 7.0786 91.2786 0.4996 70.8716 0.4996 70.8716 28.0846"></polyline>
						<line x1="45.5" y1="46.3949" x2="71.075" y2="46.3949" id="Stroke-15" />
						<line x1="34.854" y1="66.3962" x2="71.075" y2="66.3962" id="Stroke-17" />
						<line x1="45.5" y1="56.3956" x2="71.075" y2="56.3956" id="Stroke-19" />
						<line x1="34.854" y1="76.3968" x2="65.195" y2="76.3968" id="Stroke-21" />
						<line x1="34.854" y1="86.3975" x2="71.075" y2="86.3975" id="Stroke-23" />
						<path d="M44.4537,59.2921 L1.5467,59.2921 C0.9687,59.2921 0.4997,58.8241 0.4997,58.2461 L0.4997,15.3381 C0.4997,14.7601 0.9687,14.2921 1.5467,14.2921 L44.4537,14.2921 C45.0317,14.2921 45.4997,14.7601 45.4997,15.3381 L45.4997,58.2461 C45.4997,58.8241 45.0317,59.2921 44.4537,59.2921 Z" id="Stroke-25" />
						<path d="M39.8122,53.5517 L6.1882,53.5517 C5.8082,53.5517 5.5002,53.2437 5.5002,52.8637 L5.5002,29.2397 C5.5002,28.8597 5.8082,28.5517 6.1882,28.5517 L39.8122,28.5517 C40.1922,28.5517 40.5002,28.8597 40.5002,29.2397 L40.5002,52.8637 C40.5002,53.2437 40.1922,53.5517 39.8122,53.5517 Z" id="Stroke-27" />
						<path d="M12.5316,21.5697 C12.5316,23.5117 10.9576,25.0857 9.0156,25.0857 C7.0736,25.0857 5.4996,23.5117 5.4996,21.5697 C5.4996,19.6277 7.0736,18.0537 9.0156,18.0537 C10.9576,18.0537 12.5316,19.6277 12.5316,21.5697 Z" id="Stroke-29" />
						<line x1="15.4774" y1="19.5696" x2="26.4274" y2="19.5696" id="Stroke-31" />
						<line x1="15.4774" y1="23.5699" x2="26.4274" y2="23.5699" id="Stroke-33" />
					</g>
				</g>
			</g>
		</svg>
	);
};

export default RadioButtonSuccess;
