import React, { useCallback } from "react";
import {
	cu, PostTag,
} from "@credo/utilities";
import {
	classNames, ScalerSize,
} from "../../common";
import { CredoTag, TagMessages } from "../../credo-tag";

export interface AuthorTagsProps {
	postData: any;
	messages: {
		tagMessages: TagMessages;
	}
}

export const AuthorTags = ({
	postData,
	messages,
}: AuthorTagsProps) => {
	const onRatingBlocked = useCallback(() => {
		// TODO: open modal
	}, []);

	const onRate = useCallback(() => {}, []);

	const renderCredoTag = (item: PostTag, index: number) => {
		const dataProvider = {
			engagement: item?.engagement ?? -1,
			hasCampaign: item?.has_campaign ?? false,
			objScore: item?.g_credo_score ?? -1,
			ownInterest: item?.is_interest ?? false,
			ownRating: -1,
			relevance: -1,
			subjScore: item?.s_credo_score ?? -1,
			subjectivity: item?.subjectivity ?? 0.5,
		};

		return (
			<div
				className={classNames(
					"flex pb-2",
					index !== postData?.authorTags?.length - 1 ? "pr-0.5" : null,
					"pointer-events-none",
				)}
				key={index}
			>
				<CredoTag
					id={index.toString()}
					tag={item.tag}
					size={ScalerSize.XXS}
					onRatingDisabled={onRatingBlocked}
					onRate={onRate}
					dataProvider={dataProvider}
					messages={messages.tagMessages}
					disable
				/>
			</div>
		);
	};

	if (cu.isSet(postData?.authorTags) && postData?.authorTags?.length > 0) {
		return (
			<div
				id="post/author_tags"
				className="flex flex-row flex-wrap bg-transparent"
			>
				{postData?.authorTags.map((tag: PostTag, i: number) => renderCredoTag(tag, i))}
			</div>
		);
	}
	return null;
};
