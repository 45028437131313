import React, {
	useCallback, useMemo, useRef, useState,
} from "react";
import {
	BoostActionStatus,
	BoostActionType,
	BoostActionUpdateResponse,
	dbg, EvtMgr,
	LogMgr,
	cu,
	onActionsParam, PostBoostInfo, useWindowSize,
} from "@credo/utilities";
import {
	classNames, EventConst, isPromise, SIDE_NAV_MIN_SCREEN_WIDTH, SvgIcon,
} from "../../../common";
import { BoostIncrementStatus } from "../../types";
import { BoostButton } from "./BoostButton";
import { VoltsWalletButton } from "../../../button";
import { ModalPopup } from "../../../credo-tag";
import styles from "../boost-content.module.css";
import {
	BoostedPostIcon, CheckIcon, FilterCrossIcon, InfoCircleIcon, VoltsIcon, WalletIcon, XIcon,
} from "../../../assets/icons";

export interface BoostContentOpMessages {
	boosting_the_post: string;
	taking_longer: string;
	confirm_your_boost: string;
	cancel: string;
	confirm: string;
	boost_amount: string;
	your_new_boost: string;
	est_boost_div: string;
	est_boost_info_heading: string;
	est_boost_info_content: string;
}

interface BoostContentOpProps {
	/**
	 * actions which are taken in the component. Param
	 * will contain the type of action and the message
	 * with it.
	 *
	 * @see onActionsParam
	 * */
	onActions: (param: onActionsParam) => void | Promise<any> | null;
	/**
	 * control parts of the component throw authorisation
	 * object boolean keys. We can also add some context
	 * functions in the keys
	 * */
	authorisation: {
		isBoostAllowed: boolean;
	}
	postBoostInfo?: PostBoostInfo;
	estDivPayoutRate: number;
	messages: BoostContentOpMessages,
	closeWebModal?: () => void;
}

enum BoostConfModalContentType {
	CONFIRMATION = "conf",
	LOADING = "loading",
}

const DELAYED_TEXT_TIMER = 5000;
const AUTO_CLOSE_TIME = DELAYED_TEXT_TIMER + 3000;

// eslint-disable-next-line import/prefer-default-export
export const BoostContentOp: React.FC<BoostContentOpProps> = (props: BoostContentOpProps) => {
	const {
		onActions,
		authorisation,
		estDivPayoutRate,
		messages,
		postBoostInfo,
		closeWebModal,
	} = props;

	const [boostIncrementStatus, setBoostIncrementStatus] = useState<BoostIncrementStatus>(BoostIncrementStatus.INITIAL);
	const [currentModalContent, setCurrentModalContent] = useState<BoostConfModalContentType>(BoostConfModalContentType.CONFIRMATION);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [currentIncrementAmount, setCurrentIncrementAmount] = useState<number>(-1);
	const [showDelayedText, setDelayedText] = useState<boolean>(false);
	const [showInfo, setShowInfo] = useState<boolean>(false);
	const showDelayedTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
	const closeModalTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

	const { width } = useWindowSize();

	const closeConfirmModal = useCallback(() => {
		setShowModal(false);
		setCurrentModalContent(BoostConfModalContentType.CONFIRMATION);
		setBoostIncrementStatus(BoostIncrementStatus.INITIAL);
		setDelayedText(false);
		if (closeModalTimerRef.current) {
			clearTimeout(closeModalTimerRef.current);
		}
		if (showDelayedTimerRef.current) {
			clearTimeout(showDelayedTimerRef.current);
		}
	}, []);

	const handleIncrement100 = useCallback(() => {
		setShowModal(true);
		setCurrentIncrementAmount(100);
	}, []);

	const handleIncrement1K = useCallback(() => {
		setShowModal(true);
		setCurrentIncrementAmount(1000);
	}, []);

	const handleIncrement10K = useCallback(() => {
		setShowModal(true);
		setCurrentIncrementAmount(10000);
	}, []);

	const handleIncrement = (increment: number) => {
		setCurrentModalContent(BoostConfModalContentType.LOADING);
		setBoostIncrementStatus(BoostIncrementStatus.PENDING);

		showDelayedTimerRef.current = setTimeout(() => {
			setDelayedText(true);
		}, DELAYED_TEXT_TIMER);
		closeModalTimerRef.current = setTimeout(() => {
			setShowModal(false);
			onActions({
				type: BoostActionType.CLOSE_MODAL,
				message: null,
			});
			closeWebModal?.();
			EvtMgr
				.getInstance(`${EventConst.boostPostActionUpdate}-${postBoostInfo?.postUuid}`)
				.notifyListeners();
		}, AUTO_CLOSE_TIME);

		const result = onActions({
			type: BoostActionType.INCREMENT,
			message: {
				value: cu.convertSatsToMsats(increment),
			},
		});

		if (result && isPromise(result)) {
			result
				.then((response: BoostActionUpdateResponse) => {
					if (dbg) {
						LogMgr.mydbg("BoostOp.handleIncrement got response from the server", response);
					}
					if (response?.boostStatus === BoostActionStatus.SUCCESS) {
						setBoostIncrementStatus(BoostIncrementStatus.SUCCESS);
					} else {
						setBoostIncrementStatus(BoostIncrementStatus.ERROR);
					}
					onActions({
						type: BoostActionType.CLOSE_MODAL,
						message: null,
					});
					closeWebModal?.();
					closeConfirmModal();
				})
				.catch((error) => {
					if (dbg) {
						LogMgr.mydbg("BoostOp.handleIncrement got error from the server", error);
					}
					setBoostIncrementStatus(BoostIncrementStatus.ERROR);
					closeConfirmModal();
				});
		}
	};

	const toggleInfo = useCallback(() => {
		setShowInfo((prevState: boolean) => !prevState);
	}, []);

	const renderEstInfo = useMemo(() => (
		<div className="relative ml-1">
			<SvgIcon
				icon={InfoCircleIcon}
				stroke="var(--accent-2)"
				width={17}
				height={17}
				onClick={toggleInfo}
			/>
			{showInfo && (
				<div
					className={classNames(
						"w-[224px] bg-accent rounded-md absolute p-1 pr-2 -left-28",
						"flex flex-col",
					)}
				>
					<SvgIcon
						icon={FilterCrossIcon}
						width={12}
						height={12}
						className="absolute right-1"
						// @ts-ignore
						lineCrossStroke="var(--accent-2)"
						onClick={toggleInfo}
					/>
					<span className="font-bold text-[10px] text-black font-pt-sans">
						{messages.est_boost_info_heading}
					</span>
					<span className="text-[10px] text-black">
						{messages.est_boost_info_content}
					</span>
				</div>
			)}
		</div>
	), [showInfo]);

	const renderConfirmationContent = useMemo(() => {
		const estDiv = Math.ceil(currentIncrementAmount * estDivPayoutRate);
		const newBoost = currentIncrementAmount - estDiv;
		// Using this instead of ref to calculate and set the
		// font size because all three values size should
		// be consistent and same as the currentIncrementAmount
		const fontSizeMoreThan5Digits = currentIncrementAmount.toString().length > 5 ? "!text-[13px]" : "";
		return (
			(
				<div className="flex flex-col justify-center items-center px-[13px] py-4">
					<span className="mb-5 text-white text-[15px]">
						{messages.confirm_your_boost}
					</span>
					<div className="w-full">
						<div
							className={classNames(
								"rounded-full bg-credo-black flex justify-start items-center",
								"px-1 py-0.5 pr-2",
								"border-volts-wallet border-2",
							)}
							title={`${currentIncrementAmount}`}
						>
							<SvgIcon
								icon={VoltsIcon}
								height={60}
								width={60}
								className="flex-shrink-0"
							/>
							<div
								className={classNames(
									// " ml-1.5",
									styles.boostConfirmationWidgetGrid,
								)}
							>
								<span
									className="wallet-confirm-modal-text"
								>
									{messages.boost_amount}
								</span>
								<span />
								<div
									className="wallet-confirm-modal-text"
								>
									{messages.est_boost_div}
									{renderEstInfo}
								</div>
								<span className="wallet-confirm-modal-values">
									-
								</span>
								<span
									className="wallet-confirm-modal-text"
								>
									{messages.your_new_boost}
								</span>
								<span className="wallet-confirm-modal-values">
									=
								</span>
							</div>
							<div className="flex justify-end w-full ml-2.5 mr-5">
								<div
									className="flex flex-col"
								>
									<span
										data-testid="withdraw-confirmation-amount__total-to-receive"
										className={classNames(
											"wallet-confirm-modal-values mb-px text-base",
											fontSizeMoreThan5Digits,
										)}
									>
										{currentIncrementAmount.toLocaleString()}
									</span>
									<span
										data-testid="withdraw-confirmation-amount__fees"
										className={classNames(
											"wallet-confirm-modal-values mb-px text-base",
											fontSizeMoreThan5Digits,
										)}
									>
										{estDiv.toLocaleString()}
									</span>
									<span
										data-testid="withdraw-confirmation-amount__total-withdrawn"
										className={classNames(
											"wallet-confirm-modal-values !text-tag-green text-base",
											fontSizeMoreThan5Digits,
										)}
									>
										{newBoost.toLocaleString()}
									</span>
								</div>
							</div>
						</div>
					</div>
					<div className="flex justify-between mt-5">
						<VoltsWalletButton
							onClick={closeConfirmModal}
							disabled={boostIncrementStatus === BoostIncrementStatus.PENDING}
							className="!bg-light-secondary !border-light-secondary mr-0.5"
						>
							<span>
								{messages.cancel}
							</span>
							<span
								className={classNames(
									"volts-wallet__button-icon",
									"ml-2.5",
								)}
							>
								<SvgIcon icon={XIcon} width={8} height={8} stroke="white" />
							</span>
						</VoltsWalletButton>
						<VoltsWalletButton
							onClick={() => handleIncrement(currentIncrementAmount)}
							isLoading={boostIncrementStatus === BoostIncrementStatus.PENDING}
							disabled={
								currentIncrementAmount <= 0 || boostIncrementStatus === BoostIncrementStatus.PENDING
								|| boostIncrementStatus === BoostIncrementStatus.ERROR
							}
						>
							<span>
								{messages.confirm}
							</span>
							<span
								className={classNames(
									"volts-wallet__button-icon",
								)}
							>
								<SvgIcon icon={CheckIcon} width={12} height={12} stroke="white" />
							</span>
						</VoltsWalletButton>
					</div>
				</div>
			)
		);
	}, [estDivPayoutRate, currentIncrementAmount, renderEstInfo]);

	const renderLoadingContent = useMemo(() => (
		<div className="flex flex-col justify-center items-center px-8 min-h-[180px]">
			<div className="flex">
				<SvgIcon icon={WalletIcon} height={52} width={52} />
				<div
					className={classNames(
						"w-28 flex justify-between items-center px-2",
					)}
				>
					{[0, 1, 2, 3].map((item, index) => (
						<div
							key={index}
							className={classNames(
								styles.boostLoading,
							)}
							style={{
								animationDelay: `${index * 200}ms`,
							}}
						/>
					))}
				</div>
				<SvgIcon icon={BoostedPostIcon} height={52} width={52} />
			</div>
			<span
				className={classNames(
					"font-pt-sans text-xs text-off-white",
					showDelayedText ? "mt-3" : "mt-5",
				)}
			>
				{messages.boosting_the_post}
			</span>
			{showDelayedText && (
				<span className="font-pt-sans text-xs text-off-white mt-3">
					{messages.taking_longer}
				</span>
			)}
		</div>
	), [showDelayedText]);

	const renderModal = useMemo(() => {
		const getContent = () => {
			switch (currentModalContent) {
				case BoostConfModalContentType.CONFIRMATION:
					return renderConfirmationContent;
				case BoostConfModalContentType.LOADING:
					return renderLoadingContent;
				default: return null;
			}
		};

		const left = width < SIDE_NAV_MIN_SCREEN_WIDTH ? (width - 296) / 2 : 20;

		if (showModal) {
			return (
				<ModalPopup
					style={{
						width: 296,
						height: 180,
						left,
						top: 30,
					}}
					onClose={closeConfirmModal}
				>
					<div className="rounded-[16px] bg-control-l0-secondary">
						{getContent()}
					</div>
				</ModalPopup>
			);
		}
		return null;
	}, [showModal, renderConfirmationContent, currentModalContent, renderLoadingContent, width]);

	return (
		<div className="flex w-[190px] justify-between">
			<BoostButton
				boostIncrementStatus={boostIncrementStatus}
				handleIncrement={handleIncrement100}
				authorisation={authorisation}
				boostAmount="+100"
			/>
			<BoostButton
				boostIncrementStatus={boostIncrementStatus}
				handleIncrement={handleIncrement1K}
				authorisation={authorisation}
				boostAmount="+1K"
			/>
			<BoostButton
				boostIncrementStatus={boostIncrementStatus}
				handleIncrement={handleIncrement10K}
				authorisation={authorisation}
				boostAmount="+10K"
			/>
			{renderModal}
		</div>
	);
};
