/**
 * Simple factory pattern with generic types and some helpers
 */
export interface Factory<T> {
	create(params?: any): T;
}

export type Type = string | object;

export interface FactoryRegistrar {
	register<T>(forType: Type, factory: Factory<T>): Factory<T>;

	get<T>(forType: Type): Factory<T>;
}

/**
 * You can register factories for types and
 */
class FactoryRegistrarImpl implements FactoryRegistrar {
	private map = new Map<Type, Factory<any>>();

	constructor() {
	}

	register<T>(forType: Type, factory: Factory<T>): Factory<T> {
		this.map.set(forType, factory);
		return factory;
	}

	get<T>(forType: Type): Factory<T> {
		return this.map.get(forType) as Factory<T>;
	}
}

export const FactoryRegistrarSingleton: FactoryRegistrar = new FactoryRegistrarImpl();
