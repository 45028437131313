/* eslint-disable react/require-default-props */
import React from "react";
import { Icons } from "react-toastify";
import { strings } from "../i18n/config";
import classNames from "../utils/classNames";
import { SnackBarTypeOptions } from "../utils/types";

interface ToastMsgProps {
	message: string;
	onClickButton?: () => void;
	showButton?: boolean;
	buttonTitle?: string;
	containerStyle?: string;
	textStyle?: string;
	buttonStyle?: string;
	type?: SnackBarTypeOptions;
}

const ToastMsg = ({
	onClickButton,
	showButton,
	buttonTitle,
	message,
	containerStyle,
	textStyle,
	buttonStyle,
	type,
}: ToastMsgProps) => (
	<div className={classNames("flex flex-col", containerStyle!)}>
		<div className={classNames("flex flex-row", containerStyle!)}>
			<div className={classNames("flex shrink-0 w-5 mr-2.5")}>
				{Icons[type || SnackBarTypeOptions.INFO]({ theme: "light", type: type || SnackBarTypeOptions.INFO })}
			</div>
			<div className={classNames("flex flex-row w-11/12")}>
				<p className={classNames("text-sm text-gray-dark", textStyle!)}>{message}</p>
			</div>
		</div>
		{
			showButton
				? (
					<button
						type="button"
						className={classNames("flex flex-row flex-start support-text-primary pt-2 ml-6 pl-1", buttonStyle!)}
						onClick={onClickButton}
					>
						{buttonTitle || strings("ToastMsg.reload_page")}
					</button>
				)
				: null
		}
	</div>
);

ToastMsg.defaultProps = {
	onClickButton: () => window.location.reload(),
	containerStyle: "",
	textStyle: "",
	buttonStyle: "",
	type: SnackBarTypeOptions.INFO,
};

export default ToastMsg;
