import { DropdownItem, NoOfReportedOptions, TableHeader } from "../../utils/types";
import { PostMadeByFilterList, PostReportedOptions } from "./constants";

export const getSearchQuery = (searchFields: TableHeader[], search: string) => {
	if (searchFields.length > 0 && search?.trim()) {
		let query = "";
		searchFields.forEach((item) => {
			if (item?.type === "string") {
				query += `lc_${item.value}_contains : "${search?.toLowerCase().trim()}", `;
			} else {
				query += `${item.value}_contains : "${search?.trim()}", `;
			}
		});
		return query;
	}
	return "";
};

export const getNoOfRepPosts = (reportedOptions: TableHeader[]) => {
	if (reportedOptions.length !== 0) {
		let query = "";
		if (reportedOptions.length === 2) {
			query += "nb_rep_gte: 1";
		} else {
			reportedOptions.forEach((item) => {
				switch (item.value) {
					case NoOfReportedOptions.ONE_TO_TEN:
						query += "nb_rep_lte:10, nb_rep_gte:1, ";
						break;
					case NoOfReportedOptions.GREATER_THAN_TEN:
						query += "nb_rep_gte:10";
						break;
					default:
						query += " nb_rep_lte:1, ";
						break;
				}
			});
		}
		return `${query}`;
	}
	return "";
};

export const getSomeFilters = (modeFilterList: TableHeader[], sharedFilterList: any) => {
	let query = "";
	if (modeFilterList?.length === 1) {
		query += ` mode: "${modeFilterList[0]?.value}", `;
	}
	if (sharedFilterList?.length === 1) {
		query += ` is_shared: "${sharedFilterList[0]?.value}", `;
	}
	return query;
};

export const getPostMadeQuery = (postMadeFilterList: TableHeader[]) => {
	if (postMadeFilterList.length !== PostMadeByFilterList.length && postMadeFilterList.length !== 0) {
		let query = " created_by_in: [";
		postMadeFilterList.forEach((item) => {
			query += `"${item.value}",`;
		});
		return `${query}],`;
	}
	return "";
};

export const getReportedTimeframeStatement = (timeframeFilter: DropdownItem[]): DropdownItem[] => {
	if (timeframeFilter.length < PostReportedOptions.length && timeframeFilter.length > 0
	) {
		return timeframeFilter.map((option) => ({
			...option,
			searchQueries: option.searchQueries ? [option.searchQueries?.map((query) => ({
				...query,
				propName: "reported_ts",
			}))].flat() : [],
		}));
	}
	return [];
};
